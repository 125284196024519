import { useState, useEffect, useRef } from 'react';
import { Paper, TextField, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useFormik, Form, FormikProvider, FieldArray } from "formik";
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import CustomField from 'ui-component/formik/CustomField';
import {AddBank,FetchBankAccountDetails,UpdateBankAccountForm ,FetchAcountTypes} from 'api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'views/Loading';
import { useParams, useNavigate } from 'react-router';
import Card from 'ui-component/cards/Card';
import Select from 'ui-component/SelectField';


const AddBankAccount = () => {
  const theme = useTheme();
  const { id: bank_id } = useParams()
  const isEditMode = !!bank_id
  const navigate = useNavigate()
  const { isLoading: isBankAccountDetailLoading, data: BankAccountData, status: BankAccountDataStatus } = FetchBankAccountDetails(bank_id, 'edit')
  const { mutateAsync: updateBankAccount, isLoading: isLoadingUpdateBankAccount, status: updateBankAccountStatus, error: updateBankAccountError } = UpdateBankAccountForm()
  const { mutateAsync: uploadBank, status: uploadBankAccountStatus, error: uploadBankAccountError } = AddBank();
  const { isLoading: isAcountTypesLoading, data: acountTypesData } = FetchAcountTypes({})
  const initialValues = {
    user_type:'realtime',
    bank_name:'',
    branch_name:'',
    ifsc_code:'',
    micr_code:'',
    account_holder_name:'',
    bank_account_number:'',
    accounts: [
    {
      receiving_bank_name: '',
      receiving_bank_swift_code: '',
      receiver_correspondent_bank: '',
      receiver_correspondent_bank_swift_code: '',
      bank_clearing_code: '',
    }
  ],
  account_type_id:'',
}

  const formik = useFormik({
    initialValues: (!isBankAccountDetailLoading && isEditMode) ? BankAccountData : initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
       if(isEditMode){
            const editValues ={
              user_type: 'realtime',
              bank_name: values.bank_name,
              bank_account_number: values.bank_account_number,
              ifsc_code: values.ifsc_code,
              branch_name: values.branch_name,
              micr_code: values.micr_code,
              account_holder_name:values.account_holder_name,
              accounts: [
                {
                  receiving_bank_name: values.accounts[0].receiving_bank_name,
                  receiving_bank_swift_code: values.accounts[0].receiving_bank_swift_code,
                  receiver_correspondent_bank: values.accounts[0].receiver_correspondent_bank,
                  receiver_correspondent_bank_swift_code: values.accounts[0].receiver_correspondent_bank_swift_code,
                  bank_clearing_code: values.accounts[0].bank_clearing_code,
                }
              ],
              account_type_id:values.account_type_id

            }
            // delete values._id;
            //     delete values.status;
            //     delete values.createdAt;
            //     delete values.updatedAt;
            //     delete values.__v;
            //     delete values.banks;
            //     delete values.account_type_value;
         updateBankAccount({ payload: editValues, id: bank_id })

        }else{
          
         uploadBank({ payload: values })

        }
    }
  });
  


  useEffect(() => {
    if (uploadBankAccountStatus == 'success') {
      formik.resetForm()
      toast.success('Bank Account Added!', {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: () => {
          navigate('/master')
        }
      });
    }
    if (uploadBankAccountStatus == 'error') {
      toast.error(uploadBankAccountError?.response?.data?.message, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    }
  }, [uploadBankAccountStatus])
  
  
  useEffect(() => {
    if (isEditMode) {
    if (updateBankAccountStatus == 'success') {
        toast.success('Bank Account Update!', {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
                navigate('/master')
            }
        });
    }
    if (updateBankAccountStatus == 'error') {
        toast.error(updateBankAccountError?.response?.data?.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

    }
  }
}, [updateBankAccountStatus])

  if ((isBankAccountDetailLoading && isEditMode)) {
    return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
  }

  return (
    <div style={{ display: "flex", flexDirection: 'column', height: '100%' }}>
      <ToastContainer />
      <BreadcrumbHeader title={isEditMode ? "Edit Bank Account" : "Add Bank Account"} shouldGoBack />
      <Box>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px' }}>
            <Box>
              <Card title="Add Bank Account">
                <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="account_holder_name" label="Account Holder Name" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="bank_name" label="Account Name" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <Select
                        onChange={(event) => {
                          formik.setFieldValue("account_type_id", event.target.value)
                        }}
                        label="Bank Account Type"
                        value={formik.values.account_type_id}
                        options={acountTypesData}
                      />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="bank_account_number" label="Account Number" 
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          paddingLeft: 0,
                          backgroundColor: '#fff'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="micr_code" label="MICR CODE"  />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="ifsc_code" label="IFSC CODE"  />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="branch_name" label="Branch Address"  />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomField name="accounts[0].receiving_bank_name" label="Receiving Bank Name" 
                    // defaultValue={formik.values.accounts[0].recieving_bank_name}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="accounts[0].receiving_bank_swift_code" label="Receiving Bank Swift Code" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="accounts[0].receiver_correspondent_bank" label="Receiver's Correspondance Bank"  />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="accounts[0].receiver_correspondent_bank_swift_code" label="Receiver's Correspondance Bank Swift Code" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="accounts[0].bank_clearing_code" label="Bank Clearing Code"/>
                  </Grid>
                  
                </Grid>

              </Card>

              {/* <Card title="Add Recieving Bank">
                <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                  <Grid item xs={6}>
                    <CustomField name="recieving_bank_name" label="Recieving Bank Name" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="recieving_bank_swift_code" label="Recieving Bank Swift Code" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="correspondance_bank" label="Reciever's Correspondance Bank"  />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="correspondance_bank_swift_code" label="Reciever's Correspondance Bank Swift Code" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomField name="bank_clearing_code" label="Bank Clearing Code"/>
                  </Grid>

                </Grid>

              </Card> */}
            </Box>
            <Grid item xs={12} mt={2}>
              <button className="button-79" type="submit">Submit</button>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </div>
    
  );
}

export default AddBankAccount;
