import React, { useState, lazy, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTheme, styled, alpha } from '@mui/material/styles';
import { Paper, Box, Grid, TextField, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik, Form, FieldArray, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import Clickable from 'utils/Clickable';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import filterIcon from '../../assets/images/icons/filter.png';
import filterFillIcon from '../../assets/images/icons/filter_fill.png';
import TransactionFilterPopup from './Popups/TransactionFilterPopup';

import {
    FetchAllInvestors,
    FetchDealDetail,
    FetchTransactions,
    UploadTransaction,
    DeleteTransaction,
    ApprovedInvestors,
    ExportDealTransaction,
    GetPaymentMode,
    FetchAllPaymentModesTypes,
    FetchTransferUnits,
    FetchLeadInvestorsListByDealID
} from '../../api';
import AsyncTableListing from 'ui-component/TransactionTable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Modal from 'ui-component/Modal';
import Skeleton from '@mui/material/Skeleton';
import TransactionPopup from './TransactionPopup';
import ConfirmTransactionPopup from './ConfirmTransactionPopup';
import DeleteTransactionPopup from './DeleteTransactionPopup';
import UnitClassChangePopup from '../deal/Popups/UnitClassChangePopup';
import TransferTranchPopup from './TransferTranchPopup';
import { Tooltip, Icon } from '@mui/material';
import { IconEdit } from '@tabler/icons';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Lightbox from 'react-awesome-lightbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoAddCircleSharp } from 'react-icons/io5';
import { BsCheck2All } from 'react-icons/bs';
import CustomTextField from 'ui-component/TextField';
import CustomButton from 'ui-component/CustomButton';
import CustomAutoComplete from 'ui-component/formik/CustomAutoComplete';
import Checkbox from '@mui/material/Checkbox';
import MaterialModal from '@mui/material/Modal';
import { AddQuestionApi, FetchPaymentModesTypes, EditQuestionApi } from '../../api';
import Autocomplete from '@mui/material/Autocomplete';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import Loadable from 'ui-component/Loadable';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import DealTranchePopup from './DealTranchePopup';
import Cookies from 'js-cookie';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Download, KeyboardArrowDown, SyncAlt, TransferWithinAStation } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '@mui/material/Menu';
import CallForMoneyMailPopup from './CallForMoneyMailPopup';
import SelectField from 'ui-component/SelectField';
import AddTransactionsPopup from './Popups/AddTransactionsPopup';

function calculateTotal(numbers) {
    const total = numbers.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue == undefined || isNaN(currentValue) ? 0 : currentValue );
    }, 0);
    return  isNaN(total) ? 0 : total;
}

const stats = [
    {
        label: 'Startup Name',
        type: 'company_name'
    },
    {
        label: 'Deal ID',
        type: 'random_deal_id'
    }
    // {
    //   label: 'Current Round',
    //   type: 'current_round'
    // },
    // {
    //   label: 'Deal Type',
    //   type: 'deal_type'
    // },
    // {
    //   label: 'Stage',
    //   type: 'stage_value'
    // },
    // {
    //   label: 'Confirmed Amount',
    //   type: 'investment_amount'
    // },
    // {
    //   type: 'remaining',
    //   label: 'Remaining',
    // },
];
const columns = [
    // {
    //     label: 'Checkbox',
    //     minWidth: 80,
    //     align: 'center',
    //     type: 'checkbox'
    // },
    {
        label: 'LP Code',
        minWidth: 100,
        align: 'center',
        type: 'lp_code'
    },
    {
        label: 'Name',
        minWidth: 160,
        // minHeight:300,
        align: 'left',
        type: 'investor_name',
        link: true,
        fixed: true,
        fixed_row_index: 2,
        fixed_column_index: 3
    },
    {
        label: 'KYC Status',
        minWidth: 210,
        align: 'center',
        type: 'kycStatus',
        status: 'true'
    },
    {
        label: 'Lead Investor Names',
        minWidth: 210,
        align: 'center',
        type: 'lead_investor_names',
        status: 'true'
    },
    {
        label: 'Partner',
        minWidth: 150,
        align: 'left',
        type: 'investor_partner'
    },
    {
        label: 'Unit Class',
        minWidth: 150,
        align: 'left',
        type: 'unit_class_name'
    },

    {
        label: 'Syndicate(Partner)',
        minWidth: 150,
        align: 'left',
        type: 'syndicate_partner_name'
    },
    {
        label: 'Bank Account Type',
        minWidth: 180,
        align: 'left',
        type: 'account_type_name'
    },
    {
        label: 'Interest Amount',
        minWidth: 150,
        align: 'left',
        type: 'interested_amount_comma',
        price_tooltip: true,
        tooltip_amount: 'interested_amount'
    },
    {
        label: 'Received Amount',
        minWidth: 150,
        align: 'left',
        type: 'ref_received_amount',
        // price_tooltip: true,
        price_tooltip_color: true,
        tooltip_amount: 'ref_received_amount',
        highlight: true
    },
    // {
    //     label: 'Allocated Amount',
    //     minWidth: 180,
    //     align: 'left',
    //     type: 'ref_allocated_amount',
    //     // price_tooltip: true,
    //     price_tooltip_color: true,
    //     tooltip_amount: 'allocated_amount',
    //     highlight: true
    // },
    {
        label: 'Remaining Amount',
        minWidth: 180,
        align: 'left',
        type: 'ref_remaining_amount',
        // price_tooltip: true,
        price_tooltip_color: true,
        tooltip_amount: 'remaining_amount',
        highlight: true
    },
    {
        label: 'Invested Amount',
        minWidth: 150,
        align: 'left',
        type: 'amount_comma',
        // price_tooltip: true,
        price_tooltip_color: true,
        tooltip_amount: 'amount',
        highlight: true
    },
    {
        label: 'Interest Date',
        minWidth: 230,
        align: 'left',
        type: 'investment_interest_date_ref'
    },
    {
        label: 'Received Date',
        minWidth: 230,
        align: 'left',
        type: 'funds_transferred_date_ref'
    }
    // {
    //     label: 'Actions',
    //     minWidth: 130,
    //     align: 'right',
    //     type: 'actions'
    // }
];

const columns2 = [

   
    {
        label: 'Transferred to',
        minWidth: 150,
        align: 'left',
        type: 'receive_investor_name',
        // render: (rowData) => `${rowData.recived_lp_code ? `(${rowData.recived_lp_code})` : ''} ${rowData.receive_investor_name}`
    },

    {
        label: 'Transferred from',
        minWidth: 150,
        align: 'left',
        type: 'send_investor_name'
        // render: (rowData) => `${rowData.send_lp_code ? `(${rowData.send_lp_code})` : ''} ${rowData.send_investor_name}`

    },
   
    {
        label: 'Transfer Unit',
        minWidth: 80,
        align: 'center',
        type: 'transfer_unit'
    },
    {
        label: 'Created Date',
        minWidth: 150,
        align: 'left',
        type: 'createdAt'
    },
    {
        label: 'Document',
        minWidth: 100,
        align: 'center',
        type: 'actions'
    },
   
]

const useStyles = makeStyles({
    customTable: {
        '& td.MuiTableCell-root': { padding: '12px 6px 12px 16px' }
    },
    stat: {
        '& p': {
            fontSize: '16px',
            fontWeight: 600
        }
    }
});
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const Transactions = ({
    setHeader,
    dealsModule,
    transactionsInvestorsData,
    refetchTransactions,
    isTransactionsLoading,
    refetchTranches,
    deal_status,
    fees
    // lead_investorList
}) => {
    // console.log(fees,"------------");
    const theme = useTheme();
    const classes = useStyles();
    const { id: dealId } = useParams();
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [openTransactionFilterPopup , setOpenTransactionFilterPopup] = useState(false);
    const [openAddTransactionPopup, setOpenAddTransactionPopup] = useState(false);
    const [openMail, setOpenMail] = useState(false);
    const [testMail, setTestMail] = useState(null);
    const [activeRow, setActiveRow] = useState({ transactionId: '', lp_code: '' });
    const [activeRow2, setActiveRow2] = useState({ transactionId: '' });
    const [activeRow3, setActiveRow3] = useState({});
    const [activeRow4, setActiveRow4] = useState({});
    const [activeRow5, setActiveRow5] = useState({});
    const [activeRow6, setActiveRow6] = useState({});
    const [search, setSearch] = useState('');
    const [searchTransferTransaction, setSearchTransferTransaction] = useState('');
    const [searchStatusTransferTransaction, setsearchStatusTransferTransaction ] = useState('');
    const [resetTransferTransaction, setResetTransferTransaction ] = useState('');
    
    
    const [sort, setSort] = useState(-1);
    const [limit, setLimit] = useState(20);
    const [value, setValue] = useState(null);
    const [isExportLoading, setIsExportLoading] = useState(false);

    const checkedValues = [];
    const [setCheckValues, setCheckedValues] = useState([]);
    const [CheckValuesCount, setCheckedValuesCount] = useState([]);
    const [ref_amount_total, setRefTotal] = useState({});
    const [investor_invest_value, setInvestorInvestValue] = useState([]);

    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    // const { isLoading: isTransactionsLoading, isRefetching: isTransactionsRefetching, data: transactionsInvestorsData, refetch: refetchTransactions, status: TransactionStatus } = FetchTransactions(dealId);
    const { isLoading: isDealDetailLoading, isRefetching: isDealDetailRefetching, data: dealDetailData } = FetchDealDetail(dealId);
    // const { isLoading: isInvestorsLoading, isRefetching: isInvestorsRefetching, data: InvestorsData, refetch: refetchInvestorData } = FetchAllInvestors("settings");
    const { mutateAsync: deleteTransaction, status: deleteTransactionStatus, error: deleteTransactionError } = DeleteTransaction();
    const [transactions, setTransactions] = useState(transactionsInvestorsData?.investors);
    const [copyTransactions, setCopyTransactions] = useState(transactionsInvestorsData?.investors);

    const {
        isLoading: isApprovedInvestorsLoading,
        isRefetching: isApprovedInvestorsRefetching,
        data: ApprovedInvestorsData,
        refetch: refetchApprovedInvestorsData
    } = ApprovedInvestors({});
    const{
        isLoading: isTransferUnitLoading,
        data: TransferUnitsListData,
        refetch : refetchTransferUnitData,
    } =FetchTransferUnits(dealId, searchTransferTransaction);
    // console.log("TransferUnitsListData" ,TransferUnitsListData)
    const {
        isLoading: isLeadInvestorListLoading,
        isRefetching: isLeadInvestorListRefetching,
        data: LeadInvestorListData,
        refetch: refetchLeadInvestorListData
    } = FetchLeadInvestorsListByDealID(dealId);
    const excel_report = 'excel_report';
    const {
        refetch: refetchExportDealTransaction,
        isLoading: isExportDealTransactionLoading,
        isRefetching: isExportDealTransactionRefetching,
        status: exportDealTransactionStatus
    } = ExportDealTransaction(dealId, excel_report, setCheckValues);
    const { isLoading: isPaymentModeLoading, data: PaymentModeList } = GetPaymentMode();
    const {
        isLoading: isCompletedDealsLoading,
        isRefetching: isCompletedDealsRefetching,
        data: completedDealsData,
        refetch: refetchListing,
        status: dealStatus
    } = FetchAllPaymentModesTypes();
    const paymentModes = Array.isArray(completedDealsData) ? completedDealsData : [];
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [managementfeeColumns, setManagementFeeColumns] = useState([]);
    const [placementfeeColumns, setPlacementFeeColumns] = useState([]);
    const [operatratingfeeColumns, setOperatingFeeColumns] = useState([]);
    const [drawAmountColumns, setdrawAmountColumns] = useState([]);
    const [fundUtilizationColumns, setfundUtilizationColumns] = useState([]);
    const [actionColumns, setActionColumns] = useState([]);
    const [checkColumns, setcheckColumnsColumns] = useState([]);
    const [check_investor_values, setcheckInvestorColumns] = useState([]);
    // const [feesLabel, setfeesLabel] = useState([]);
    const [venturePartnerColumns, setVenturePartnerColumns] = useState([]);
    const [partners, setPartners] = useState([]);
    const [partner, setPartner] = useState('');
    const [syndicateValues, setSyndicateValues] = useState([]);
    const [syndicate, setSyndicate] = useState('');
    useEffect(() => {
        // const fees_label=[]
        // if(fees && fees.management_fee ){
        if (fees && fees.management_fee) {
            setManagementFeeColumns([
                {
                    label: 'Management Fees(%)',
                    // minWidth: 180,
                    align: 'left',
                    type: 'managementfees'
                }
            ]);
        } else {
            setManagementFeeColumns([]);
        }
        if (fees && fees.operating_fee) {
            setPlacementFeeColumns([
                {
                    label: 'Operating Fees(%)',
                    // minWidth: 180,
                    align: 'left',
                    type: 'operatingfees'
                }
            ]);
        } else {
            setPlacementFeeColumns([]);
        }
        if (fees && fees.distribution_fee) {
            setOperatingFeeColumns([
                {
                    label: 'Distribution Fees(%)',
                    // minWidth: 180,
                    align: 'left',
                    type: 'placementfees'
                }
            ]);
        } else {
            setOperatingFeeColumns([]);
        }
        if (fees && (fees.management_fee || fees.distribution_fee || fees.operating_fee)) {
            setdrawAmountColumns([
                {
                    label: ['DrawDown Amount'],
                    minWidth: 180,
                    align: 'left',
                    type: 'DrawAmount'
                }
            ]);
            setfundUtilizationColumns([
                {
                    label: 'Fund Utilization Amount',
                    minWidth: 200,
                    align: 'left',
                    type: 'fundutilization'
                }
            ]);
        } else {
            setdrawAmountColumns([]);
            setfundUtilizationColumns([]);
        }
        // }
        // if (transactionsInvestorsData && transactionsInvestorsData?.investors) {
        //   const investors_fee = transactionsInvestorsData?.investors;

        //    for (let i = 0; i < investors_fee.length; i++) {
        //      const investor_fee = investors_fee[i];
        //      if (investor_fee.fees_management) {
        //       hasFeesColumn = true;
        //       break; // Exit the loop as soon as a matching condition is found
        //      }
        //    }
        //  }
        // if (hasManagementFeesColumn) {
        //   setFeeColumns([
        //     {
        //       label: fees_label,
        //       minWidth: 180,
        //       align: 'left',
        //       type: 'fees'
        //     },
        //     {
        //       label: [''],
        //       minWidth: 180,
        //       align: 'left',
        //       type: 'DrawAmount'
        //     },
        //   ]);
        // }
    }, [transactionsInvestorsData]);

    useEffect(() => {
        // Check if deal status is 'completed' and add the dynamic column accordingly
        // const hasUnitAllocatedColumn = dealDetailData?.deal_status === 'completed';
        // const hasUnitAllocatedColumn = transactionsInvestorsData?.investors.some((investor) => Number(transactionsInvestorsData?.investors.unit_alloted) > 0);
        let hasUnitAllocatedColumn = false;

        if (transactionsInvestorsData && transactionsInvestorsData?.investors) {
            const investors_unit = transactionsInvestorsData?.investors;

            for (let i = 0; i < investors_unit.length; i++) {
                const investor_unit = investors_unit[i];
                if (Number(investor_unit?.unit_alloted) > 0) {
                    hasUnitAllocatedColumn = true;
                    break; // Exit the loop as soon as a matching condition is found
                }
            }
        }

        if (hasUnitAllocatedColumn) {
            // If 'Unit Allocated' column is required, add it to the dynamic columns
            setDynamicColumns([
                {
                    label: 'Unit Allocated',
                    minWidth: 150,
                    align: 'left',
                    type: 'unit_alloted'
                }
            ]);
        } else {
            // If 'Unit Allocated' column is not required, reset the dynamic columns
            setDynamicColumns([]);
        }
    }, [dealDetailData]);
    useEffect(() => {
        if (dealDetailData.deal_status !== 'cancelled') {
            setActionColumns([
                {
                    label: 'Actions',
                    minWidth: 130,
                    align: 'right',
                    type: 'actions'
                }
            ]);
        }
    }, [dealDetailData]);
    useEffect(() => {
        if (dealDetailData.deal_status !== 'cancelled') {
            setcheckColumnsColumns([
                {
                    label: 'Checkbox',
                    minWidth: 80,
                    align: 'center',
                    type: 'checkbox'
                }
            ]);
        }
    }, [dealDetailData]);

    useEffect(() => {
        // Check if deal status is 'completed' and add the dynamic column accordingly
        // const hasUnitAllocatedColumn = dealDetailData?.deal_status === 'completed';
        // const hasUnitAllocatedColumn = transactionsInvestorsData?.investors.some((investor) => Number(transactionsInvestorsData?.investors.unit_alloted) > 0);
        let hasVenturePartnerColumn = false;

        if (transactionsInvestorsData && transactionsInvestorsData?.investors) {
            const investors_unit = transactionsInvestorsData?.investors;

            for (let i = 0; i < investors_unit.length; i++) {
                const investor_unit = investors_unit[i];
                if (investor_unit?.investor_venture_partner) {
                    hasVenturePartnerColumn = true;
                    break;
                }
            }
        }

        if (hasVenturePartnerColumn) {
            setVenturePartnerColumns([
                {
                    label: 'Venture Partner',
                    minWidth: 150,
                    align: 'left',
                    type: 'investor_venture_partner'
                }
            ]);
        } else {
            setVenturePartnerColumns([]);
        }
    }, [dealDetailData]);
    const filteredStaticColumns = columns.filter((column) => column.type !== 'actions');
    // Combine static columns with dynamic columns and insert the "Actions" column back
    const combinedColumns = [
        ...checkColumns,
        ...filteredStaticColumns.slice(0, 3),
        ...venturePartnerColumns,
        ...filteredStaticColumns.slice(3),
        ...dynamicColumns,
        ...managementfeeColumns,
        ...placementfeeColumns,
        ...operatratingfeeColumns,
        ...drawAmountColumns,
        ...fundUtilizationColumns,
        ...actionColumns
        // ...columns.filter((column) => column.type === 'actions')
    ];
    useEffect(() => {
        setTransactions(transactionsInvestorsData?.investors);
        setCopyTransactions(transactionsInvestorsData?.investors);
    }, [transactionsInvestorsData]);

    useEffect(() => {
        if (deleteTransactionStatus == 'success') {
            refetchTransactions();
            toast.success('Transaction Deleted', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            refetchApprovedInvestorsData();
        } else if (deleteTransactionStatus == 'error') {
            toast.error(deleteTransactionError.message, {
                position: 'top-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [deleteTransactionStatus]);

    const filterRows = (searchedVal, selectedPartner, selectedSyndicate) => {
        const newRows = transactionsInvestorsData?.investors.filter((investor) => {
            return (
                Object.values(investor).some((field) => {
                    return String(field).toLowerCase().includes(searchedVal.toLowerCase().trim());
                }) &&
                (selectedPartner ? investor.investor_partner === selectedPartner : true) &&
                (selectedSyndicate ? investor.syndicate_partner_name === selectedSyndicate : true)
            );
        });

        setTransactions(newRows);
    };
    // const formik = useFormik({
    //     initialValues: {
    //         investors: [
    //             {
    //                 investor_id: '',
    //                 unit_class_id: '',
    //                 lead_investor_id: '',
    //                 startup_deal_id: dealId,
    //                 investment_interest_amount: '',
    //                 funds_transferred_amount: '',
    //                 investment_interest_date: null,
    //                 funds_transferred_date: null,
    //                 is_unallocated: false,
    //                 // utr_number: '',
    //                 remark: '',
    //                 payments: {
    //                     payment_mode: '',
    //                     utr_number: ''
    //                 }
    //             }
    //         ]
    //     },
    //     // validationSchema: validationSchema,
    //     onSubmit: (values) => {
    //         const payload = values.investors.map((investor) => {
    //             const {
    //                 investment_interest_date,
    //                 funds_transferred_date,
    //                 investment_interest_amount,
    //                 funds_transferred_amount,
    //                 ...newValues
    //             } = investor;
    //             const payload = {
    //                 ...newValues,
    //                 unit_class_id: investor.unit_class_id,
    //                 investor_id: investor.investor_id._id,
    //                 lead_investor_id: [investor.lead_investor_id._id],
    //                 is_unallocated: investor.is_unallocated ? 'Yes' : 'No'
    //             };
    //             if (investor.investment_interest_date) {
    //                 payload['investment_interest_date'] = investor.investment_interest_date;
    //             } else {
    //                 payload['investment_interest_date'] = '';
    //             }
    //             if (investor.funds_transferred_date) {
    //                 payload['funds_transferred_date'] = investor.funds_transferred_date;
    //             }
    //             if (investor.investment_interest_amount) {
    //                 payload['investment_interest_amount'] = investor.investment_interest_amount;
    //             } else {
    //                 payload['investment_interest_amount'] = '';
    //             }
    //             if (investor.funds_transferred_amount) {
    //                 payload['funds_transferred_amount'] = investor.funds_transferred_amount;
    //             }
    //             if (typeof values.payments == 'object' && investor.payments?.value) {
    //                 payload['payments[payment_mode]'] = investor.payment_mode?.value;
    //             } else {
    //                 if (typeof values.payments == 'string') {
    //                     payload['payments[payment_mode]'] = values.payment_mode;
    //                 }
    //             }
    //             return payload;
    //         });
    //         uploadTransactions(payload, {
    //             onSuccess: () => refetchApprovedInvestorsData()
    //         });
    //     }
    // });
    const handleOpen = (row) => {
        setActiveRow({ transactionId: row._id, lp_code: row.lp_code });
        setOpen(true);
    };
    const handleOpen2 = (row) => {
        setActiveRow2({ ...row, transactionId: row._id });
        setOpen2(true);
    };
     const openTransferTranch = (row) => {
        setActiveRow6({...row, transactionId: row._id })
        setOpen6(true); 
    };

    const handleOpen3 = (row) => {
        setActiveRow3(row);
        setOpen3(true);
    };

    const handleUnitClassOpen = (row) => {
        setActiveRow5(row);
        setOpen5(true);
    };
    const handleAddTransactionPopupOpen = () => {
        // setActiveRow5(row);
        setOpenAddTransactionPopup(true);
    };

    const downloadTransferUnitDocuments = ({ row }) => {
        setTimeout(() => {
            if (row?.url) {
                const link = document.createElement('a');
                link.href = row?.url;
                link.target = '_blank';
                link.setAttribute('download', 'filename.pdf'); 
                link.click();
            } else {
                console.error('Data is missing in the response:', row);
            }
            // setIsExportLoading(false);
        }, 1000);
    }
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const handleClose5 = () => {
        setOpen5(false);
    };
    const handleClose6 = () => {
        setOpen6(false);
    };
    const handleAddTransactionPopupClose = () => {
        setOpenAddTransactionPopup(false);
    };

    const [openAction, setopenAction] = useState(false);
    const handleCloseAction = () => {
        setAnchorEl(null);
        setOpen2(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openanchor = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDeleteTransaction = () => {
        deleteTransaction(
            { transaction_arr: [activeRow3._id] },
            {
                onSuccess: () => {
                    setOpen3(false);
                }
            }
        );
    };

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const openModel = (row) => {
        const checkboxes = document.getElementsByClassName('ElementCheck2');
        let checkedCount = 0;
        let totalAmount = 0;
        let investor_obj = [];
        let investor_obj_values = [];

        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedValues.push(checkboxes[i].value);
                const checked_investorId = checkboxes[i].value;
                const checked_investor = transactions.find((row) => row._id === checked_investorId);
                investor_obj.push(checked_investor);
                investor_obj_values.push(checked_investor.remaining_amount);

                if (checked_investor) {
                    // Ensure the "amount" field is a valid number
                    const amount = parseFloat(checked_investor.amount);

                    if (!isNaN(amount)) {
                        totalAmount += amount; // Add the amount to the total
                    }
                }
            }
        }
        setCheckedValues(checkedValues);
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedCount++;
            }
        }

        if (checkedCount > 0) {
            setOpen4(true);
        } else {
            setOpen4(false);
            toast.error('Select Investor to Create Tranch', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
        setCheckedValuesCount(checkedCount);
        setRefTotal(totalAmount);
        setcheckInvestorColumns(investor_obj);
        setInvestorInvestValue(investor_obj_values);
    };

    const openModelNew = (row) => {
        const checkboxes = document.getElementsByClassName('ElementCheck2');
        let checkedCount = 0;
        let totalAmount = 0;
        let investor_obj = [];
        let investor_obj_values = [];

        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedValues.push(checkboxes[i].value);
                const checked_investorId = checkboxes[i].value;
                const checked_investor = transactions.find((row) => row._id === checked_investorId);
                investor_obj.push(checked_investor);
                investor_obj_values.push(checked_investor.remaining_amount);

                if (checked_investor) {
                    // Ensure the "amount" field is a valid number
                    const amount = parseFloat(checked_investor.amount);

                    if (!isNaN(amount)) {
                        totalAmount += amount; // Add the amount to the total
                    }
                }
            }
        }
        setCheckedValues(checkedValues);
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedCount++;
            }
        }
        setCheckedValuesCount(checkedCount);
        setRefTotal(totalAmount);
        setcheckInvestorColumns(investor_obj);
        setInvestorInvestValue(investor_obj_values);
        setActiveRow5({});
    };

    const openModelMail = (row) => {
        const checkboxes = document.getElementsByClassName('ElementCheck2');
        let checkedCount = 0;
        let totalAmount = 0;
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedValues.push(checkboxes[i].value);
                const checked_investorId = checkboxes[i].value;
                const checked_investor = transactions.find((row) => row._id === checked_investorId);

                if (checked_investor) {
                    // Ensure the "amount" field is a valid number
                    const amount = parseFloat(checked_investor.amount);

                    if (!isNaN(amount)) {
                        totalAmount += amount; // Add the amount to the total
                    }
                }
            }
        }
        setCheckedValues(checkedValues);
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedCount++;
            }
        }

        if (checkedCount > 0) {
            setOpenMail(true);
        } else {
            setOpenMail(false);
            toast.error('Select Investor to Send Email', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
        setCheckedValuesCount(checkedCount);
        setRefTotal(totalAmount);
    };
   
    
    const handleClose4 = () => setOpen4(false);
    const handleCloseMail = () => setOpenMail(false);
    //   TransactionFilterPopup handles
    const handleOpenTransactionFilterPopup = ()=>{
        setOpenTransactionFilterPopup(true)
    }
    const handleCLoseTransactionFilterPopup = ()=>{
        setOpenTransactionFilterPopup(false)
    }
    const TransferUnitAction = ({ row }) => {
        // const { mutateAsync: updateStartupStatus } = UpdateStartupStatus(row._id);
        return (
            <div style={{ display: 'flex', justifyContent: 'center', columnGap: '12px' }}>
                <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                    downloadTransferUnitDocuments({ row });
                }}>
                    <Tooltip title="Download">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Download stroke={1.5} size="1.4rem" />
                        </div>
                    </Tooltip>
                </Clickable>
            </div>
        );
    }
    const TransactionsActions = ({ row }) => {
        const theme = useTheme();
        const navigate = useNavigate();
        return (
            
            <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '8px' }}>
                {row.remark && (
                    <Clickable style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={`Remark: ${row.remark}`}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CommentOutlinedIcon stroke={1.5} size="1.2rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
                {row.status == 'pending' && parseFloat(row.received_amount) > 0 && (
                    <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOpen2(row)}>
                        <Tooltip title="Confirm">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BsCheck2All size={20} />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
                {/* {row.status!=="confirmed" && (
          <button 
            className="button-79" 
            type="button"
            style={{padding: '0.1rem 0.6rem', display:'flex', alignItems:'center', minWidth: '50px', height: '27px', fontSize: '14px'}}
            // style={{height:'40px'}}
            // color={[theme.palette.error.dark, theme.palette.error.main]} 
            // size='small' 
            onClick={()=>navigate(`/deals/investedInvestors/${row.id}`)}
          >Reject
          </button>)} */}
                {/* <Clickable style={{display: 'flex', alignItems:'center'}} onClick={()=>console.log(row)}><IconMailForward stroke={1.5} size="1.4rem"/></Clickable>
                 */}


                {row.document_url && (
                    <Clickable
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                            setActiveRow4(row);
                            setIsImageModalOpen(true);
                        }}
                    >
                        <Tooltip title="Screenshot">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <RemoveRedEyeIcon />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
                
                <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOpen(row)}>
                    <Tooltip title="Edit">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconEdit stroke={1.5} size="1.4rem" />
                        </div>
                    </Tooltip>
                </Clickable>
                {row.unit_alloted > 0 &&  dealDetailData.deal_status === 'completed' &&
                 <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => openTransferTranch(row)}>
                        <Tooltip title="Transfer Units">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SyncAlt stroke={1.5} size="1.4rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                    }
                {row.interested_amount > 0 && row.interested_amount != '' && (
                    <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOpen3(row)}>
                        <Tooltip title="Delete">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DeleteIcon stroke={1.5} size="1.4rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
               
            </div>
        );
    };
    const UnitClassChangeActions = ({ row }) => {
        return (
            <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleUnitClassOpen(row)}>
                <Tooltip title="Change Unit Class">
                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: 900 }}>{row.unit_class_name}</div>
                </Tooltip>
            </Clickable>
        );
    };

    const options_data = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' }
        // Add more options as needed
    ];

    // const [is_trans_added, setTransAdded] = useState(transactions.is_trans_added);
    // function convertToCSV(objArray) {
    //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    //   var str = '';

    //   for (var i = 0; i < array.length; i++) {
    //     var line = '';
    //     for (var index in array[i]) {
    //       if (line != '') line += ','

    //       line += array[i][index];
    //     }

    //     str += line + '\r\n';
    //   }

    //   return str;
    // }

    // function exportCSVFile(headers, items, fileTitle) {

    //   if (headers) {
    //     items.unshift(headers);
    //   }

    //   // Convert Object to JSON
    //   var jsonObject = JSON.stringify(items);

    //   var csv = convertToCSV(jsonObject);

    //   var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    //   var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    //   if (navigator.msSaveBlob) { // IE 10+
    //     navigator.msSaveBlob(blob, exportedFilenmae);
    //   } else {
    //     var link = document.createElement("a");
    //     if (link.download !== undefined) { // feature detection
    //       // Browsers that support HTML5 download attribute
    //       var url = URL.createObjectURL(blob);
    //       link.setAttribute("href", url);
    //       link.setAttribute("download", exportedFilenmae);
    //       link.style.visibility = 'hidden';
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     }
    //   }
    // }

    // function download() {
    //   var headers = {
    //     lp_code: 'LP Code',
    //     investorName: "Investor Name",
    //     interestAmount: "Interest Amount",
    //     interestDate: "Interest Date",
    //     fundReceivedAmount: "Fund Received Amount",
    //     fundReceivedDate: "Fund Received date",
    //     created: "Created",
    //   };

    //   let itemsNotFormatted = transactionsInvestorsData.investors.map(investor => ({
    //     lp_code: investor.lp_code || " ",
    //     investorName: investor.investor_name || " ",
    //     interestAmount: investor.ref_interested_amount.slice(1) || " ",
    //     interestDate: investor.investment_interest_date_ref || " ",
    //     fundReceivedAmount: investor?.ref_amount ? (investor.ref_amount.length > 1 ? investor.ref_amount.slice(1) : investor.ref_amount) : " ",
    //     fundReceivedDate: investor.funds_transferred_date_ref || " ",
    //     created: investor.created || " ",
    //   }))

    //   var itemsFormatted = [];

    //   // format the data
    //   itemsNotFormatted.forEach((item) => {
    //     itemsFormatted.push({
    //       lp_code: item.lp_code,
    //       investorName: item.investorName,
    //       interestAmount: item.interestAmount,
    //       interestDate: item.interestDate,
    //       fundReceivedAmount: item.fundReceivedAmount,
    //       fundReceivedDate: item.fundReceivedDate,
    //       created: item.created,
    //     });
    //   });

    //   const today = new Date();
    //   const yyyy = today.getFullYear();
    //   let mm = today.getMonth() + 1; // Months start at 0!
    //   let dd = today.getDate();

    //   if (dd < 10) dd = '0' + dd;
    //   if (mm < 10) mm = '0' + mm;

    //   const formattedToday = dd + '/' + mm + '/' + yyyy

    //   var fileTitle = `${dealDetailData.random_deal_id} (${formattedToday})`; // or 'my-unique-title'

    //   exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    // }

    function downloadDealTransaction() {
        setIsExportLoading(true);
        const checkboxes = document.getElementsByClassName('ElementCheck2');
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkedValues.push(checkboxes[i].value);
            }
        }
        setCheckedValues(checkedValues);
        setTimeout(() => {
            refetchExportDealTransaction().then(({ data }) => {
                if ({ data }) {
                    const link = document.createElement('a');
                    link.href = data.url;
                    link.target = '_blank';
                    link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
                    link.click();
                } else {
                    console.error('Data is missing in the response:', data);
                }
            });
            setIsExportLoading(false);
        }, 1000);
    }
    const InvestorLink = ({ row, children }) => {
        return (
            <a
                href={`/investors/viewInvestor/${row.investor_id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: '#616161', fontWeight: 700 }}
            >
                {children}
            </a>
        );
    };
   
    return dealsModule != undefined && dealsModule?.deal_investors == true ? (
        <Box height={'100%'}>
            {isDealDetailLoading && isDealDetailLoading ? (
                <Skeleton animation="wave" variant="rounded" width={'100%'} height={60} sx={{ marginBottom: '10px' }} />
            ) : setHeader ? (
                ''
            ) : (
                <BreadcrumbHeader
                    title={
                        TransactionStatus == 'success'
                            ? `${dealDetailData.company_name} (${transactionsInvestorsData.count})`
                            : 'Transactions'
                    }
                    shouldGoBack
                    breadcrumbTitle={dealDetailData.company_name}
                />
            )}
            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <Modal open={openAddTransactionPopup} onClose={handleAddTransactionPopupClose} closeIcon={true}>
                    <AddTransactionsPopup
                        dealId={dealId}
                        dealDetailData={dealDetailData}
                        LeadInvestorListData={LeadInvestorListData}
                        ApprovedInvestorsData={ApprovedInvestorsData}
                        dealsModule={dealsModule}
                        handleAddTransactionPopupClose={handleAddTransactionPopupClose}
                        refetchTransactions={refetchTransactions}
                        refetchApprovedInvestorsData={refetchApprovedInvestorsData}
                    />
                </Modal>
                <Modal open={open} onClose={handleClose} closeIcon={true}>
                    <TransactionPopup
                        dealId={dealId}
                        activeRow={activeRow}
                        open={open}
                        // LeadInvestorListData={LeadInvestorListData}
                        // isLeadInvestorListLoading={isLeadInvestorListLoading}
                        handleClose={handleClose}
                        dealsModule={dealsModule}
                        dealDetailData={dealDetailData}
                        refetchTransactions={refetchTransactions}
                        refetchApprovedInvestorsData={refetchApprovedInvestorsData}
                    />
                </Modal>
                <Modal open={open2} onClose={handleClose2} closeIcon={true}>
                    <ConfirmTransactionPopup
                        dealId={dealId}
                        activeRow={activeRow2}
                        handleClose={handleClose2}
                        refetchTransactions={refetchTransactions}
                    />
                </Modal>
                <MaterialModal open={open3} onClose={handleClose3}>
                    <Box
                        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '700px' }}
                        className="delete-transaction-modal"
                    >
                        <DeleteTransactionPopup
                            dealId={dealId}
                            activeRow={activeRow3}
                            handleClose={handleClose3}
                            refetchTransactions={refetchTransactions}
                            handleConfirm={handleDeleteTransaction}
                        />
                    </Box>
                </MaterialModal>
                <Modal open={open5} onClose={handleClose5} closeIcon={true}>
                    <UnitClassChangePopup
                        dealId={dealId}
                        activeRow={activeRow5}
                        check_investor_values={check_investor_values}
                        handleClose={handleClose5}
                        refetchTransactions={refetchTransactions}
                        unitClassData={dealDetailData?.unit_class}
                    />
                </Modal>
                <Modal open={open6} onClose={handleClose6} closeIcon={true}>
                    <TransferTranchPopup
                        check_investor_values={check_investor_values}
                        activeRow={activeRow6}
                        handleClose={handleClose6}
                        refetchTransactions={refetchTransactions}
                        refetchTransferUnitData={refetchTransferUnitData}
                        ApprovedInvestorsData={ApprovedInvestorsData}
                        scheme_name={dealDetailData?.scheme_name}
                        a

                    />
                </Modal>
              {/* TransactionFilterPopup */}
                <Modal open={openTransactionFilterPopup} onClose={handleCLoseTransactionFilterPopup}  closeIcon={true} style={{ width: '600px' }}>
               <TransactionFilterPopup handleClose={handleCLoseTransactionFilterPopup} partner={partner} setPartner={setPartner} syndicate={syndicate} setSyndicate={setSyndicate} filterRows={filterRows} search={search} copyTransactions={copyTransactions} setCopyTransactions={setCopyTransactions} setTransactions={setTransactions} transactionsInvestorsData={transactionsInvestorsData} />
               </Modal>
               
                <Modal open={openMail} onClose={handleCloseMail} closeIcon={true}>
                    <CallForMoneyMailPopup
                        handleClose={handleCloseMail}
                        dealId={dealId}
                        mailType={testMail ? 'test' : 'manual'}
                        // deal_completed_date={dealDetailData?.deal_flow_date_format?.deal_completed_date_format}
                        // scemeName={dealDetailData?.scheme_name}
                        setCheckValues={setCheckValues}
                        refetchTransactions={refetchTransactions}
                        refetchTranches={refetchTranches}
                        // CheckValuesCount={CheckValuesCount}
                        ref_amount_total={ref_amount_total}
                    />
                </Modal>
                {isImageModalOpen && (
                    <Lightbox
                        // images={[{ url: `${row.document_url}`, title: `${row.document[0]?.file_name }`}]}
                        image={activeRow4.document_url}
                        title={activeRow4.document[0]?.file_name}
                        onClose={() => setIsImageModalOpen(false)}
                    />
                )}

                {open4 && (
                    <DealTranchePopup
                        handleClose={handleClose4}
                        dealId={dealId}
                        deal_status={dealDetailData?.deal_status}
                        deal_completed_date={dealDetailData?.deal_flow_date_format?.deal_completed_date_format}
                        scemeName={dealDetailData?.scheme_name}
                        setCheckValues={setCheckValues}
                        refetchTransactions={refetchTransactions}
                        refetchTranches={refetchTranches}
                        CheckValuesCount={CheckValuesCount}
                        ref_amount_total={ref_amount_total}
                        check_investor_values={check_investor_values}
                        investor_invest_value={investor_invest_value}
                    />
                )}

                {!open4 ? (
                    isTransactionsLoading ? (
                        <Skeleton animation="wave" variant="rounded" width={'100%'} height={343} />
                    ) : (
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: 3,
                                padding: '16px 16px 0px 16px',
                                marginTop: '30px',
                                paddingBottom: '24px'
                            }}
                        >
                            <Grid container direction="row" columnSpacing={2} rowSpacing={2} mb={1} sx={{display:"flex"}}>
                                <Grid item sm={3} xs={6}>
                                    <CustomTextField
                                        label="Search By LP, Name, VP,etc."
                                        placeholder={'Search'}
                                        value={search}
                                        autoComplete="off"
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                // alert(e.target.value);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                            filterRows(e.target.value, partner, syndicate);
                                        }}
                                        size="small"
                                        sx={{
                                            '& input': {
                                                background: theme.palette.background.paper
                                                // background: '#f9f9fc8e'
                                            },
                                            '& input:valid + fieldset': {
                                                borderColor: theme.palette.secondary[200],
                                                borderWidth: 0.5
                                            },
                                            '& input:valid:hover + fieldset': {
                                                borderColor: '#4050b5',
                                                borderWidth: 1
                                            },
                                            '& input:valid:focus + fieldset': {
                                                borderColor: '#4050b5',
                                                padding: '4px !important',
                                                borderWidth: 1,
                                                borderLeftWidth: 6
                                            }
                                        }}
                                    />
                                </Grid>
                                {(
                                    <Grid item sm={3} xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center',gap: '0.5rem' }}>
                                        {(search.trim()) && (<CustomButton
                                            size="medium"
                                            sx={{ height: '40px' }}
                                            color={
                                                search !== '' || partner !== '' || syndicate !== ''
                                                    ? [theme.palette.error.dark, theme.palette.error.main]
                                                    : [theme.palette.secondary[800], theme.palette.secondary.main]
                                            }
                                            onClick={() => {
                                                filterRows('', partner, syndicate);
                                                setSearch('');
                                            }}
                                            fullWidth
                                        >
                                            Reset
                                        </CustomButton>)}
                                        <IconButton onClick={()=> handleOpenTransactionFilterPopup()}  sx={{ display: "flex", justifyContent: 'center'   }}>
                                    <Tooltip title="Filter">
                                        {partner == '' && syndicate ==''? (
                                            <img src={filterIcon} alt="Filter" style={{ width: '24px', height: '24px' }} />
                                        ) : (
                                            <img src={filterFillIcon} alt="Filter" style={{ width: '24px', height: '24px' }} />
                                        )}
                                    </Tooltip>
                                </IconButton>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ display: 'flex',  alignItems: 'center', gap: '0.5rem' , marginLeft:"auto" , justifyContent:"flex-end"}}
                                >
                                    {dealDetailData.deal_status !== 'completed' && dealDetailData.deal_status !== 'cancelled' && (
                                        <CustomButton
                                            onClick={() => {
                                                handleAddTransactionPopupOpen();
                                                handleCloseAction();
                                            }}
                                        >
                                            Add Transaction
                                        </CustomButton>
                                    )}
                                    <Button
                                        id="demo-customized-button"
                                        aria-controls={openanchor ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openanchor ? 'true' : undefined}
                                        variant="contained"
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDown />}
                                        className="action-btn "
                                    >
                                        {/* <IconPlus /> */}
                                        &nbsp;&nbsp; Actions
                                    </Button>
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'demo-customized-button'
                                        }}
                                        anchorEl={anchorEl}
                                        open={openanchor}
                                        onClose={handleCloseAction}
                                    >
                                        {/* Additional Menu Items */}
                                        {/* {dealDetailData.deal_status !== 'completed' && dealDetailData.deal_status !== 'cancelled' &&<MenuItem onClick={() => { handleAddTransactionPopupOpen(); handleCloseAction(); }}>
                                            <ListItemIcon>
                                                <div style={{ marginRight: '10px' }} />
                                                Add Transaction
                                            </ListItemIcon>
                                        </MenuItem>} */}
                                        {/* {InvestorsModule?.export_master_data && ( */}
                                        {Cookies.get('is_super_admin') == 'true' && dealDetailData.deal_status !== 'cancelled' && (
                                            <MenuItem
                                                onClick={() => {
                                                    openModel();
                                                    handleCloseAction();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <div style={{ marginRight: '10px' }} />
                                                    Create Tranch
                                                </ListItemIcon>
                                            </MenuItem>
                                        )}
                                        {/* {InvestorsModule?.export_transaction && ( */}
                                        <MenuItem onClick={downloadDealTransaction}>
                                            <ListItemIcon>
                                                {/* {isExportLoading ? (
                    <CircularProgress size={20} color="inherit" />
                ) : (
                    <FileDownloadIcon />
                )} */}
                                                <div style={{ marginRight: '10px' }} />
                                                Export Investors
                                            </ListItemIcon>
                                        </MenuItem>
                                        {dealDetailData?.deal_flow[0]?.value?.includes('call_for_money') && (
                                            <MenuItem
                                                onClick={() => {
                                                    setTestMail(false);
                                                    openModelMail();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <div style={{ marginRight: '10px' }} />
                                                    Send Call for Money Email
                                                </ListItemIcon>
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() => {
                                                setTestMail(true);
                                                openModelMail();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <div style={{ marginRight: '10px' }} />
                                                Send Test Email
                                            </ListItemIcon>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                openModelNew();
                                                setOpen5(true);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <div style={{ marginRight: '10px' }} />
                                                Update Unit Class
                                            </ListItemIcon>
                                        </MenuItem>
                                        {/* {dealDetailData.deal_status === 'completed' &&(
                                        <MenuItem
                                            onClick={() => {
                                                // setOpen6(true);
                                                openTransferTranch();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <div style={{ marginRight: '10px' }} />
                                                Transfer Units
                                            </ListItemIcon>
                                        </MenuItem>
                                       ) } */}
                                        {/* )} */}
                                    </StyledMenu>
                                </Grid>
                            </Grid>
                            {transactions && (
                                <AsyncTableListing
                                    // title="Transactions"
                                    columns={combinedColumns}
                                    // columns={columns}
                                    data={transactions}
                                    actions={TransactionsActions}
                                    // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(transactionsInvestorsData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
                                    page={page}
                                    setPage={setPage}
                                    pagination={false}
                                    cutomLink={InvestorLink}
                                    footerSummary={
                                        <Box className="table-bottom-block" sx={{ display: 'flex', columnGap: '20px' }}>
                                            <p style={{ marginBottom: '10px' }}>
                                                <b>Total Investors:</b> {transactions.length}
                                            </p>

                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(transactions?.map((transaction) => transaction?.amount))
                                                            : transactionsInvestorsData?.realTotalAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Invested):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalAmount}
                                                </Tooltip>
                                            </p>
                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(
                                                                  transactions?.map((transaction) => transaction?.interested_amount)
                                                              )
                                                            : transactionsInvestorsData?.realTotalInterestedAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Interested):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.interested_amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalInterestedAmount}
                                                </Tooltip>
                                            </p>
                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(
                                                                  transactions?.map((transaction) => transaction?.received_amount)
                                                              )
                                                            : transactionsInvestorsData?.realTotalReceivedAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Received):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.received_amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalReceivedAmount}
                                                </Tooltip>
                                            </p>
                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(
                                                                  transactions?.map((transaction) => transaction?.remaining_amount)
                                                              )
                                                            : transactionsInvestorsData?.realTotalRemainingAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Remaining):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.remaining_amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalRemainingAmount}
                                                </Tooltip>
                                            </p>
                                        </Box>
                                    }
                                    dealStatus={dealDetailData?.deal_status}
                                    unitAction={UnitClassChangeActions}
                                    // is_trans_added={is_trans_added}
                                    // setTransAdded={setTransAdded}
                                />
                            )}
                        </Paper>
                    )
                ) : (
                    <></>
                )}
            </Box>
           {dealDetailData?.deal_status === 'completed' && TransferUnitsListData && TransferUnitsListData.length > 0 &&
            <Paper
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: 3,
                                padding: '16px 16px 0px 16px',
                                marginTop: '30px',
                                paddingBottom: '24px'
                            }}
                        >
                             <div className='md-title mb-4 mt-4'>Transferred Units</div>
                            <Grid container direction="row" columnSpacing={2} rowSpacing={2} mb={1} sx={{display:"flex"}}>
                                <Grid item sm={3} xs={6}>
                                    <CustomTextField
                                        label="Search"
                                        placeholder={'Search'}
                                        value={searchTransferTransaction}
                                        autoComplete="off"
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                // alert(e.target.value);
                                                refetchTransferUnitData()
                                                setsearchStatusTransferTransaction(true)
                                                
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchTransferTransaction(e.target.value);
                                            setsearchStatusTransferTransaction(false)
                                         
                                        }}
                                        size="small"
                                        sx={{
                                            '& input': {
                                                background: theme.palette.background.paper
                                                // background: '#f9f9fc8e'
                                            },
                                            '& input:valid + fieldset': {
                                                borderColor: theme.palette.secondary[200],
                                                borderWidth: 0.5
                                            },
                                            '& input:valid:hover + fieldset': {
                                                borderColor: '#4050b5',
                                                borderWidth: 1
                                            },
                                            '& input:valid:focus + fieldset': {
                                                borderColor: '#4050b5',
                                                padding: '4px !important',
                                                borderWidth: 1,
                                                borderLeftWidth: 6
                                            }
                                        }}
                                    />
                                </Grid>
                                {(
                                    <Grid item sm={3} xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center',gap: '0.5rem' }}>
                                        <CustomButton
                                            size="medium"
                                            sx={{ height: '40px' }}
                                            color={(searchStatusTransferTransaction && searchTransferTransaction !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                                            onClick={() => {
                                               if(searchStatusTransferTransaction && searchTransferTransaction !== ''){
                                                setSearchTransferTransaction('');
                                                setResetTransferTransaction(!resetTransferTransaction)
                                               }
                                               else{
                                                refetchTransferUnitData()
                                               }
                                               setsearchStatusTransferTransaction((oldVal) => !oldVal)
                                            }}
                                            fullWidth
                                        
                                        >
                                        {(searchStatusTransferTransaction && searchTransferTransaction !== "" ) ? "Reset" : "Search"}
                                        </CustomButton>
                                    </Grid>
                                )}
                                
                            </Grid>
                            

                            {TransferUnitsListData && !isTransferUnitLoading &&  (
                                <AsyncTableListing
                                    columns={columns2}
                                    data={TransferUnitsListData}
                                    actions={TransferUnitAction}
                                    page={page}
                                    setPage={setPage}
                                    pagination={false}
                                    cutomLink={InvestorLink}
                                    footerSummary={
                                        <Box className="table-bottom-block" sx={{ display: 'flex', columnGap: '20px' }}>
                                            <p style={{ marginBottom: '10px' }}>
                                                <b>Total Investors:</b> {transactions.length}
                                            </p>

                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(transactions?.map((transaction) => transaction?.amount))
                                                            : transactionsInvestorsData?.realTotalAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Invested):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalAmount}
                                                </Tooltip>
                                            </p>
                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(
                                                                  transactions?.map((transaction) => transaction?.interested_amount)
                                                              )
                                                            : transactionsInvestorsData?.realTotalInterestedAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Interested):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.interested_amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalInterestedAmount}
                                                </Tooltip>
                                            </p>
                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(
                                                                  transactions?.map((transaction) => transaction?.received_amount)
                                                              )
                                                            : transactionsInvestorsData?.realTotalReceivedAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Received):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.received_amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalReceivedAmount}
                                                </Tooltip>
                                            </p>
                                            <p style={{ marginBottom: '50px' }}>
                                                <Tooltip
                                                    title={convertToIndianCurrencyWords(
                                                        search !== '' || partner !== '' || syndicate !== ''
                                                            ? calculateTotal(
                                                                  transactions?.map((transaction) => transaction?.remaining_amount)
                                                              )
                                                            : transactionsInvestorsData?.realTotalRemainingAmount
                                                    )}
                                                >
                                                    <b>Total Amount (Remaining):</b>{' '}
                                                    {search !== '' || partner !== '' || syndicate !== ''
                                                        ? calculateTotal(
                                                              transactions?.map((transaction) => transaction?.remaining_amount)
                                                          ).toLocaleString('en-IN', {
                                                              style: 'currency',
                                                              currency: 'INR',
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 0
                                                          })
                                                        : transactionsInvestorsData?.totalRemainingAmount}
                                                </Tooltip>
                                            </p>
                                        </Box>
                                    }
                                    dealStatus={dealDetailData?.deal_status}
                                    unitAction={UnitClassChangeActions}
                                    // is_trans_added={is_trans_added}
                                    // setTransAdded={setTransAdded}
                                />
                            )}
            </Paper>
        }
        </Box>
    
    ) : (
        <Error404 />
    );
};

export default Transactions;
