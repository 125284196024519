// assets
import { IconJumpRope } from '@tabler/icons';

// constant
const icons = {
    IconJumpRope
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const resource = {
    id: 'resource1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'resource11',
            title: 'Resources',
            type: 'collapse',
            icon: icons.IconJumpRope,

            children: [
                {
                    id: 'resource111',
                    title: 'Articles',
                    type: 'item',
                    url: `/resources/articles`,
                    // target: true,
                    breadcrumbs: false
                },
                {
                    id: 'resource112',
                    title: 'Videos',
                    type: 'item',
                    url: `/resources/videos`,
                    // target: true,
                    breadcrumbs: false
                },
                {
                    id: 'resource113',
                    title: 'FAQs',
                    type: 'item',
                    url: `/resources/faq`,
                    // target: true,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default resource;
