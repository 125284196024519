import { Grid, Pagination, TextField } from "@mui/material"
import { Box } from "@mui/system";
import AsyncTableListing from "ui-component/AsyncTableListing";
import CustomButton from "ui-component/CustomButton"


const Videos=()=>{
  const [page, setPage] = useState(1)
    const columns = [
        {
          label: 'Article ID', 
          minWidth: 100,
          align: 'left',
          type: '_id'
        },
        {
          label: 'Name', 
          minWidth: 170,
          align: 'left',
          type: 'name',
          image: false
        },
        {
          label: 'Description',
          minWidth: 170,
          align: 'left',
          type: 'description'
        },
        {
          label: 'Action',
          minWidth: 150,
          align: 'left',
          type: 'action'
        }
    ];
    
    var data = [{
        _id:"1234567",
        name:"John",
        description:"Demo task",
        action:"active"
    }]

return <>
<Grid container direction="row" columnSpacing={1} rowSpacing={2} mb={1}>

<Grid item xs={5}>
          <TextField 
            label="Search" 
            onChange={(event)=> {
            //   setSearch(event.target.value)
            //   setSearchStatus(false)
            }} 
          />
        </Grid>
        <Grid item xs={6} sx={{display: 'flex', justifyContent:'flex-end'}}>
          <CustomButton
           size="large">Add Videos</CustomButton>
        </Grid>
      </Grid>

             {/* {isInvestorLoading ? <h1>Loading...</h1>:  */}
            <AsyncTableListing 
            title="Article" 
            columns = {columns}
            data={data}
            // actions={InvestorActions}
            // pagination={()=>(<Box
            //      style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={12} count={20}/></Box>)}
            page={page}
            setPage={setPage}
            count={10}
            limit={10}
            />
            {/* }  */}
      </>
}
export default Videos