import React from 'react'
import { useFormik, Form, FieldArray, FormikProvider, Field, FastField } from "formik";
import { Paper, TextField, Grid, Button, Box, Stack, Switch } from '@mui/material';
import Modal from 'ui-component/Modal';
import CustomField from 'ui-component/formik/CustomField';
import Card from 'ui-component/cards/Card';
import Divider from '@mui/material/Divider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useState } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5';
import { useTheme } from '@emotion/react';
import { AiFillCloseCircle } from 'react-icons/ai';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { useLocation, useParams, useNavigate } from 'react-router';
import CustomValuePop from 'ui-component/CustomValuePop';
import { AddMISReportAPI, FetchSelectedMISReport } from 'api/Startup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { Tooltip, Icon } from '@mui/material';
import Loader from 'ui-component/Loader';

const inputProps = {
    min: 0,
    onKeyDown: (event) => {
      // Prevent the minus sign (-) from being entered
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    onKeyPress: (event) => {
      // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    inputMode: 'numeric',
    pattern: '[0-9.]*',
    onKeyPress:(event) => {
        // Get the input value
          const inputValue = event.key;
        
          // Allow only numeric characters (0-9) and certain control keys
          const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab','.'];
        
          // Check if the pressed key is in the allowedKeys array
          if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
          }
    },
    onInput: (event) => {
      // Get the input value
      const inputValue = event.target.value;
  
      // Remove non-numeric characters using a regular expression
      const numericValue = inputValue.replace(/[^0-9.]/g, '');
  
      // Update the input field value with the filtered numeric value
      event.target.value = numericValue;
    },
    onKeyDown: (event) => {
      // Prevent the input of minus sign (key code 189)
      if (event.keyCode === 189 || event.key === "-") {
        event.preventDefault();
      }
    },
  }

const initialValues = {
    misreport_month_year: moment().format('YYYY-MM'),
    gmv: {
        gmv_amount: 0,
        cancellation_amount: null,
        cancellation_percentage: 0,
        discount: null,
        discount_percentage: 0,
        returns: null,
        returns_percentage: 0,
        gmv_details:[]
    },
    net_revenue: null,
    cost_of_goods_sold: null,
    gross_margin: 0,
    contribution_margin: {
        packaging: null,
        marketplace_commissions: null,
        offline_commissions: null,
        logistics_charges: null,
        payment_gateway_charges: null,
        other_op_variable_costs: null,
        marketing_spends: null,
        promotion_cost_on_marketplaces: null,
        affiliate_spends:null,
        other_marketing_cost: null,
        agency_expenses: null,
        salary_excluding_founders: null,
        founders_salary: null,
        research_development: null,
        rent: null,
        warehouse_expenses: null,
        electricity: null,
        it_expenses: null,
        travelling_expenses: null,
        other_op_expenses: null,
        contribution_margin_details: []
    }
    ,
    ebitda: null,
    ebitda_percentage: 0,
    interest_payments: null,
    ebtda: null,
    ebtda_percentage: 0,
    cash_flow_enable: false,
    cash_flow: {
        direct_revenue_collection_bank : null,
        direct_revenue_collection_cash : null,
        investments : null,
        borrowings : null,
        any_other_receipt: null, 
        total_money_in: null,
        total_money_in_details: [],

        salaries_to_employees_net_pay: null,
        tech_expenses: null,
        purchase_n_general_n_admin_expenses : null,
        interest_expense: null,
        tax_paid_gst: null,
        tax_paid_tds: null,
        other_expenses: null,
        provident_fund: null,
        esi: null,
        city_operations: null,
        payment_gateway_charges: null,
        marketing_expenses: null,
        total_money_out: null,
        total_money_out_details: [],

        bank_balance_opening: null,
        total_of_deposits: null,
        total_of_withdrawls: null,
        bank_balance_closing: null,
        net_burn_for_the_month: null,

    }
}

const AddMISReport = () => {
    const { id: startupId } = useParams();
    const navigate = useNavigate()
    const search = useLocation().search;
    const selectedDate = new URLSearchParams(search).get('date')

    const theme = useTheme();
    // const {id:currentReportID,} = useParams()
    const isEditMode = (selectedDate !== undefined && selectedDate !== null) ?  true : false;
    
    const [openCustomValue,setOpenCustomValue] = useState(false);
    const [currentObject,setCurrentObject] = useState(null);
    const { mutateAsync: uploadMISReport, status: MISReportStatus, error: MISReportError } = AddMISReportAPI(startupId);
    
    React.useEffect(() => {
        if(MISReportStatus == 'success'){
            toast.success('MIS Report Updated!', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored"
               });
            setTimeout(()=> navigate(`/startups/startup-misreport/${startupId}`),2000)   
            }
            else if (MISReportStatus == 'error') {
                toast.error(MISReportError.response.data.message, {
                   position: "top-left",
                   autoClose: 3000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: false,
                   draggable: true,
                   progress: undefined,
                   theme: "colored",
                  });
               }
               
            },[MISReportStatus,MISReportError])
            
        const [selectedMISDate,setSelectedMISDate] = React.useState(moment().format('YYYY-MM')); 
    const { isLoading: isSelectedMISLoading, isRefetching: isSelectedMISRefetching, data: selectedMISReport } = FetchSelectedMISReport(startupId,selectedDate)
   //  console.log(selectedDate,selectedMISReport,"selectedMISReportselectedMISReportselectedMISReportselectedMISReportselectedMISReport")
    
    
    const handleDateChange = (newValue) => { 
       formik.setFieldValue('misreport_month_year',moment(newValue).format('YYYY-MM'));
       // setSelectedMISDate(moment(newValue).format('YYYY-MM'))
      }
      
      const formik = useFormik({
         // initialValues: !isSelectedMISLoading &&  selectedMISReport.data  != undefined &&  selectedMISReport.data.misReport  ? selectedMISReport.data.misReport  :  initialValues,
         initialValues: (selectedDate !== null && !isSelectedMISLoading )?selectedMISReport?.misReport : initialValues,
         validateOnChange: false,
         validateOnBlur: true,
         enableReinitialize: true,
         onSubmit: (values) => {
            //PnL_Income Statement
            let gmv_details_total = values?.gmv?.gmv_details?.reduce((accumulator, item) => accumulator + Number(item?.value), 0);
            let contribution_margin_details_total = values?.contribution_margin?.contribution_margin_details?.reduce((accumulator, item) => accumulator + Number(item?.value), 0);
            let net_revenue = Number(values?.gmv?.gmv_amount) - (Number(values.gmv.cancellation_amount) + Number(values.gmv.discount) + Number(values.gmv.returns) 
            // + Number(gmv_details_total)
            );
            let contribution_margin1 = net_revenue - Number(values.cost_of_goods_sold);
            let gross_margin = (contribution_margin1 / net_revenue)*100;
            let contribution_margin2 =  contribution_margin1 - (Number(values.contribution_margin.packaging) + Number(values.contribution_margin.marketplace_commissions) + Number(values.contribution_margin.offline_commissions) + Number(values.contribution_margin.logistics_charges) + Number(values.contribution_margin.payment_gateway_charges) + Number(values.contribution_margin.other_op_variable_costs)
            //  + contribution_margin_details_total
            );
            let contribution_margin1_percentage = (contribution_margin2/net_revenue)*100;
            let contribution_margin3 =  contribution_margin2 - (Number(values.contribution_margin.marketing_spends) + Number(values.contribution_margin.promotion_cost_on_marketplaces) + Number(values.contribution_margin.affiliate_spends) + Number(values.contribution_margin.agency_expenses) 
            // + contribution_margin_details_total
            );
            let contribution_margin2_percentage = (contribution_margin3/net_revenue)*100;
            let ebitda_value = contribution_margin3 - (Number(values.contribution_margin.agency_expenses) + Number(values.contribution_margin.salary_excluding_founders)  + Number(values.contribution_margin.founders_salary) + Number(values.contribution_margin.research_development) + Number(values.contribution_margin.rent) + Number(values.contribution_margin.warehouse_expenses) + Number(values.contribution_margin.electricity) + Number(values.contribution_margin.it_expenses) + Number(values.contribution_margin.travelling_expenses) + Number(values.contribution_margin.other_op_expenses));
            let ebitda_percentage = (ebitda_value/net_revenue)*100;
            let ebtda_value = ebitda_value - Number(values.interest_payments)
            let ebtda_percentage = (ebtda_value/net_revenue)*100;
            // console.log(values, ":values");
            // console.log(net_revenue,':net_revenue');
            // console.log(contribution_margin1, ':contribution_margin1');
            // console.log(gross_margin, ':gross_margin');
            // console.log(contribution_margin2, ':contribution_margin2');
            // console.log(contribution_margin1_percentage, ':contribution_margin1_percentage');
            // console.log(contribution_margin3, ':contribution_margin3');
            // console.log(contribution_margin2_percentage, ':contribution_margin2_percentage');
            // console.log(ebitda_value, ':ebitda_value');
            // console.log(ebitda_percentage, ':ebitda_percentage');
            // console.log(ebtda_value, ':ebtda_value');
            // console.log(ebtda_percentage, ':ebta_percentage');
            // Cash Flow Statement 
            // console.log('Cashflow')
            let total_money_in_value = Number(values.cash_flow.direct_revenue_collection_bank) + Number(values.cash_flow.direct_revenue_collection_cash) + Number(values.cash_flow.investments) + Number(values.cash_flow.borrowings) + Number(values.cash_flow.any_other_receipt); 
            let total_money_out_value = Number(values.cash_flow.salaries_to_employees_net_pay) + Number(values.cash_flow.tech_expenses) + Number(values.cash_flow.purchase_n_general_n_admin_expenses) + Number(values.cash_flow.interest_expense) + Number(values.cash_flow.tax_paid_gst) + Number(values.cash_flow.tax_paid_tds) + Number(values.cash_flow.other_expenses) + Number(values.cash_flow.provident_fund) + Number(values.cash_flow.esi) + Number(values.cash_flow.city_operations) + Number(values.cash_flow.payment_gateway_charges) + Number(values.cash_flow.marketing_expenses); 
            // console.log(total_money_in_value, ':total_money_in_value');
            // console.log(total_money_out_value, ':total_money_out_value');
            let bank_balance_closing_value = Number(values.cash_flow.bank_balance_opening) + total_money_in_value  - total_money_out_value;
            // console.log(bank_balance_closing_value, ':bank_balance_closing_value');
            let net_burn_for_the_month_value = Number(bank_balance_closing_value) - Number(values.cash_flow.bank_balance_opening);
            // console.log(net_burn_for_the_month_value, ':net_burn_for_the_month_value');
            
            let payload = {'misReport' :{
                misreport_month_year: moment(values.misreport_month_year).format('YYYY-MM') ,
                gmv: {
                    gmv_amount: values.gmv.gmv_amount,
                    cancellation_amount: values.gmv.cancellation_amount,
                    cancellation_percentage: values.gmv.cancellation_percentage,
                    discount: values.gmv.discount,
                    discount_percentage: values.gmv.discount_percentage,
                    returns: values.gmv.returns,
                    returns_percentage: values.gmv.returns_percentage,
                    gmv_details:values.gmv.gmv_details
                  },
                  net_revenue: net_revenue,
                  cost_of_goods_sold: values.cost_of_goods_sold,
                  gross_margin: gross_margin,
                  contribution_margin: {
                     packaging: values.contribution_margin.packaging,
                     marketplace_commissions: values.contribution_margin.marketplace_commissions,
                     offline_commissions: values.contribution_margin.offline_commissions,
                     logistics_charges: values.contribution_margin.logistics_charges,
                     payment_gateway_charges: values.contribution_margin.payment_gateway_charges,
                     other_op_variable_costs: values.contribution_margin.other_op_variable_costs,
                     marketing_spends: values.contribution_margin.marketing_spends,
                    promotion_cost_on_marketplaces: values.contribution_margin.promotion_cost_on_marketplaces,
                    affiliate_spends:values.contribution_margin.affiliate_spends,
                    other_marketing_cost: values.contribution_margin.other_marketing_cost,
                    agency_expenses: values.contribution_margin.agency_expenses,
                    salary_excluding_founders: values.contribution_margin.salary_excluding_founders,
                    founders_salary: values.contribution_margin.founders_salary,
                    research_development: values.contribution_margin.research_development,
                    rent: values.contribution_margin.rent,
                    warehouse_expenses: values.contribution_margin.warehouse_expenses,
                    electricity: values.contribution_margin.electricity,
                    it_expenses: values.contribution_margin.it_expenses,
                    travelling_expenses: values.contribution_margin.travelling_expenses,
                    other_op_expenses: values.contribution_margin.other_op_expenses,
                    contribution_margin_details: values.contribution_margin.contribution_margin_details
                  },
                  ebitda: ebitda_value,
                  ebitda_percentage: ebitda_percentage,
                  interest_payments: values.interest_payments,
                  ebtda: ebtda_value,
                  ebtda_percentage: ebtda_percentage,
                  cash_flow_enable: values.cash_flow_enable,
                  cash_flow: {
                     direct_revenue_collection_bank : values.cash_flow.direct_revenue_collection_bank,
                     direct_revenue_collection_cash : values.cash_flow.direct_revenue_collection_cash,
                     investments : values.cash_flow.investments,
                     borrowings : values.cash_flow.borrowings,
                     any_other_receipt: values.cash_flow.any_other_receipt, 
                     total_money_in: total_money_in_value,
                     total_money_in_details: values.cash_flow.total_money_in_details,
                    
                     salaries_to_employees_net_pay: values.cash_flow.salaries_to_employees_net_pay,
                     tech_expenses: values.cash_flow.tech_expenses,
                     purchase_n_general_n_admin_expenses : values.cash_flow.purchase_n_general_n_admin_expenses,
                     interest_expense: values.cash_flow.interest_expense,
                     tax_paid_gst: values.cash_flow.tax_paid_gst,
                     tax_paid_tds: values.cash_flow.tax_paid_tds,
                     other_expenses: values.cash_flow.other_expenses,
                     provident_fund: values.cash_flow.provident_fund,
                     esi: values.cash_flow.esi,
                     city_operations: values.cash_flow.city_operations,
                     payment_gateway_charges: values.cash_flow.payment_gateway_charges,
                     marketing_expenses: values.cash_flow.marketing_expenses,
                    total_money_out: total_money_out_value,
                    total_money_out_details: values.cash_flow.total_money_out_details,
                    
                    bank_balance_opening: values.cash_flow.bank_balance_opening,
                    total_of_deposits: total_money_in_value,
                    total_of_withdrawls: total_money_out_value,
                    bank_balance_closing: bank_balance_closing_value,
                    net_burn_for_the_month: net_burn_for_the_month_value,
                  },
                contribution_margin1: contribution_margin1,
                contribution_margin1_percentage: contribution_margin1_percentage,
                contribution_margin2: contribution_margin2,
                contribution_margin2_percentage: contribution_margin2_percentage,
                contribution_margin3: contribution_margin3
               }}
            
               // console.log(payload,"Payload")
               
               uploadMISReport({ payload: payload, id: startupId },{
                onSuccess: () => {
                   formik.resetForm()
                  }
               })
            }
    })
    const [cashFlow,setCashFlow] = useState(formik.values?.cash_flow_enable);
    
    // const [customValue,setCustomValue] = useState('');
    const gmv_details = [...formik.values.gmv.gmv_details];
    const contribution_margin_details = [...formik.values.contribution_margin.contribution_margin_details];
    const total_money_in_details = [...formik.values.cash_flow.total_money_in_details];
    const total_money_out_details = [...formik.values.cash_flow.total_money_out_details];
    
    
    function handleCustomValue (value) {
            if( currentObject ==='gmv' ){ formik.values.gmv.gmv_details.push({key: value , value: ''})};
            if( currentObject ==='contribution_margin_details' ){ formik.values.contribution_margin.contribution_margin_details.push({key: value , value: ''})};
            if( currentObject ==='total_money_in_details' ){ formik.values.cash_flow.total_money_in_details.push({key: value , value: ''})};
            if( currentObject ==='total_money_out_details' ){ formik.values.cash_flow.total_money_out_details.push({key: value , value: ''})};
         }
         
    const handleClosePopup = () => setOpenCustomValue(false)

    // React.useEffect(() => {
    //     console.log(selectedMISDate,"selected_date")
    //     console.log(selectedMISReport,"selectedMISReportselectedMISReportselectedMISReport")
    // },[selectedMISDate])
  return (
    <>
    {/* {isSelectedMISLoading ? (
                <Loader />
            ) : ( */}
    <div>
    <ToastContainer />
    <Modal sx={{display: 'grid', placeItems: 'center', placeContent: 'center'}} open={openCustomValue} style={{width: '30vw'}} disableScrollLock={true}  onClose={handleClosePopup} closeIcon={true}>
        <CustomValuePop handleCustomValue={handleCustomValue} setOpenCustomValue={handleClosePopup} />
        <Box sx={{ position: 'absolute', top: '0px', right: '0px', padding: '15px', cursor: 'pointer' }} onClick={() => setOpenCustomValue(false)} className='cs-close'>
                    <AiFillCloseCircle size={25}  />
        </Box>
    </Modal>
    <BreadcrumbHeader title={isEditMode ? "Edit MIS Report" : "Add MIS Report"} shouldGoBack />
        <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
                <Card title='PNL Income Statement'>
                    <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} sm={4} md={3}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    views={['year', 'month']}
                                    label="Select Date"
                                    size="small"
                                    value={formik.values.misreport_month_year || moment()}
                                    disableFuture={true}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputFormat="MM/YYYY"
                                    autoComplete="off"
                                    disabled={isEditMode}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Divider sx={{marginTop: 2}} />
                    <h3>GMV Information</h3>
                    <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.gmv.gmv_amount
                         )}
                         >
                            <CustomField inputProps={inputProps} name="gmv.gmv_amount" label="GMV (Excluding GST)"  onChange={(e) => {formik.setFieldValue('gmv.gmv_amount',e.target.value);formik.setFieldValue('cash_flow.direct_revenue_collection_bank',e.target.value)}}
                            // onChange={(e)=> {formik.setFieldValue('gmv.cancellation_percentage', ( formik.values.gmv.cancellation_amount/ e.target.value)* 100);formik.setFieldValue('gmv.discount_percentage', ( formik.values.gmv.discount/ e.target.value)* 100);formik.setFieldValue('gmv.returns_percentage', ( formik.values.gmv.returns/ e.target.value)* 100)}} 
                             />
                         </Tooltip>
                        </Grid>
                        <Grid className='mis-inputs-gmv' item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.gmv.cancellation_amount
                         )}
                         >
                            <CustomField inputProps={inputProps} name="gmv.cancellation_amount" label="Cancellation Amount"  onChange={(e)=> {formik.setFieldValue('gmv.cancellation_amount',e.target.value);if(formik.values.gmv.gmv_amount !== null || formik.values.gmv.gmv_amount !== '') {formik.setFieldValue('gmv.cancellation_percentage', (e.target.value / formik.values.gmv.gmv_amount)* 100)}}}/>
                         </Tooltip>
                            <Grid className='percentage-display'>
                                <span>{(formik.values.gmv.cancellation_percentage === Infinity || formik.values.gmv.cancellation_percentage === NaN || formik.values.gmv.gmv_amount === 0) ? '0.00': formik.values.gmv.cancellation_percentage !== null?formik.values.gmv.cancellation_percentage.toFixed(2) : formik.values.gmv.cancellation_percentage}%</span>
                            </Grid>
                        </Grid>
                        <Grid className='mis-inputs-gmv' item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.gmv.discount
                         )}
                         >
                            <CustomField inputProps={inputProps} name="gmv.discount" label="Discount" onChange={(e)=> {formik.setFieldValue('gmv.discount',e.target.value);if(formik.values.gmv.gmv_amount !== null || formik.values.gmv.gmv_amount !== ''){formik.setFieldValue('gmv.discount_percentage', (e.target.value / formik.values.gmv.gmv_amount)* 100)}}}  />
                         </Tooltip>
                            <Grid className='percentage-display'>
                                <span>{(formik.values.gmv.discount_percentage === Infinity || formik.values.gmv.discount_percentage === NaN || formik.values.gmv.gmv_amount === 0) ? '0.00' : formik.values.gmv.discount_percentage !== null ?formik.values.gmv.discount_percentage.toFixed(2) : formik.values.gmv.discount_percentage}%</span>
                            </Grid>
                        </Grid>
                        <Grid className='mis-inputs-gmv' item xs={12} sm={4} md={3} onChange={(e)=> {formik.setFieldValue('gmv.returns',e.target.value);if(formik.values.gmv.gmv_amount !== null || formik.values.gmv.gmv_amount !== ''){formik.setFieldValue('gmv.returns_percentage', (e.target.value / formik.values.gmv.gmv_amount)* 100)}}}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.gmv.returns
                         )}
                         >
                            <CustomField inputProps={inputProps} name="gmv.returns" label="Returns"   />
                            </Tooltip>
                            <Grid className='percentage-display'>
                                <span>{(formik.values.gmv.returns_percentage === Infinity || formik.values.gmv.returns_percentage === NaN || formik.values.gmv.gmv_amount === 0) ? '0.00' : formik.values.gmv.returns_percentage !== null ? formik.values.gmv.returns_percentage.toFixed(2) : formik.values.gmv.returns_percentage}%</span>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cost_of_goods_sold
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cost_of_goods_sold" label="Cost of Goods Sold"   />
                         </Tooltip>
                        </Grid>

                        {formik.values?.gmv?.gmv_details && formik.values?.gmv?.gmv_details?.length > 0 ? formik.values?.gmv?.gmv_details.map((field, index) => {
                            return <Grid item xs={12} sm={4} md={3}>
                            <Stack flexDirection={'row'} alignItems={'center'} columnGap={1}>
                            <Grid item xs={10}>
                            <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.gmv.gmv_details[index].value
                         )}
                         >
                                <CustomField inputProps={inputProps} name={`gmv.gmv_details.${index}.value`} label={field.key} />
                         </Tooltip>
                            </Grid>
                            <Grid display={'flex'} item xs={2}>
                            <button
                                className="button-79"
                                type="button"
                                style={{
                                  backgroundColor: 'transparent',
                                  padding: '0px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '50%'
                                }}
                                onClick={() => {gmv_details.splice(index,1); formik.setFieldValue(`gmv.gmv_details`, gmv_details)}}
                              >
                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                              </button> 
                                <button
                                    className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                    onClick={() => {setCurrentObject('gmv');setOpenCustomValue(true)}}
                                >
                                    <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                                </button>
                            </Grid>
                            </Stack>
                        </Grid>
                        }) : <Grid item xs={12} sm={4} md={2} display={'flex'} alignItems={'center'}>
                              <button
                                className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                onClick={() => {setCurrentObject('gmv');setOpenCustomValue(true)}}
                              >
                                <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                              </button>
                            </Grid>}
                        
                    </Grid>
                    <Divider sx={{marginTop: 2}} />
                    <h3>Contribution Margin Information</h3>
                    <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.packaging
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.packaging" label="Packaging"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.marketplace_commissions
                         )}
                         >

                            <CustomField inputProps={inputProps} name="contribution_margin.marketplace_commissions" label="Marketplace Commissions"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.offline_commissions
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.offline_commissions" label="Offline Commissions"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.logistics_charges
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.logistics_charges" label="Logistics Charges"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.payment_gateway_charges
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.payment_gateway_charges" label="Payment Gateway Charges"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.other_op_variable_costs
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.other_op_variable_costs" label="Other Operating Variable Costs"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.marketing_spends
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.marketing_spends" label="Marketing Spends"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.promotion_cost_on_marketplaces
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.promotion_cost_on_marketplaces" label="Promotion Cost on Marketplace" onChange={(e) => {formik.setFieldValue('contribution_margin.promotion_cost_on_marketplaces',e.target.value);formik.setFieldValue('cash_flow.marketing_expenses',e.target.value)}}  />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.affiliate_spends
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.affiliate_spends" label="Affiliate Spends"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.other_marketing_cost
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.other_marketing_cost" label="Other Marketing Cost"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.agency_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.agency_expenses" label="Agency Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.salary_excluding_founders
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.salary_excluding_founders" label="Salary Excluding Founders" onChange={(e) => {formik.setFieldValue('contribution_margin.salary_excluding_founders',e.target.value);formik.setFieldValue('cash_flow.salaries_to_employees_net_pay',e.target.value)}}  />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.founders_salary
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.founders_salary" label="Founders Salary"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.research_development
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.research_development" label="Research Development"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.rent
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.rent" label="Rent"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.warehouse_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.warehouse_expenses" label="Warehouse Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.electricity
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.electricity" label="Electricity"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.it_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.it_expenses" label="IT Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.travelling_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.travelling_expenses" label="Travelling Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.other_op_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="contribution_margin.other_op_expenses" label="Other Operating Expenses"   />
                         </Tooltip>
                        </Grid>
                        {formik.values?.contribution_margin?.contribution_margin_details && formik.values?.contribution_margin?.contribution_margin_details?.length > 0 ? formik.values?.contribution_margin?.contribution_margin_details?.map((field, index) => {
                            return <Grid item xs={12} sm={4} md={3}>
                            <Stack flexDirection={'row'} alignItems={'center'} columnGap={1}>
                            <Grid item xs={10}>
                            <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.contribution_margin.contribution_margin_details[index].value
                         )}
                         >
                                <CustomField inputProps={inputProps} name={`contribution_margin.contribution_margin_details.${index}.value`} label={field.key} />
                         </Tooltip>
                            </Grid>
                            <Grid display={'flex'} item xs={2}>
                            <button
                                className="button-79"
                                type="button"
                                style={{
                                  backgroundColor: 'transparent',
                                  padding: '0px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '50%'
                                }}
                                onClick={() => {contribution_margin_details.splice(index,1); formik.setFieldValue(`contribution_margin.contribution_margin_details`, contribution_margin_details)}}
                              >
                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                              </button> 
                                <button
                                    className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                    onClick={() => {setCurrentObject('contribution_margin_details');setOpenCustomValue(true)}}
                                >
                                    <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                                </button>
                            </Grid>
                            </Stack>
                        </Grid>
                        }) : <Grid item xs={12} sm={4} md={2} display={'flex'} alignItems={'center'}>
                              <button
                                className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                onClick={() => {setCurrentObject('contribution_margin_details');setOpenCustomValue(true)}}
                              >
                                <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                              </button>
                            </Grid>}

                    </Grid>
                    <Divider sx={{marginTop: 2}} />
                    <h3>EBTDA</h3>
                            <Grid item xs={12} sm={4} md={3}>
                            <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.interest_payments
                         )}
                         >
                                <CustomField inputProps={inputProps} name="interest_payments" label="Interest Payments"  onChange={(e) => {formik.setFieldValue('interest_payments',e.target.value);formik.setFieldValue('cash_flow.interest_expense',e.target.value)}}  />
                         </Tooltip>
                            </Grid>
                    <Grid item mt={1} display={'flex'} alignItems={'center'} xs={12} sm={4} md={3}>
                        Cash Flow
                            <Switch title='Cash Flow' defaultChecked={formik.values?.cash_flow_enable} value={cashFlow || formik.values?.cash_flow_enable} onChange={()=> {setCashFlow(!cashFlow); formik.setFieldValue('cash_flow_enable',!cashFlow)}} />
                            
                        </Grid>
                </Card>
                {(cashFlow || formik.values?.cash_flow_enable) && <Card title='Cash Flow' >
                        <h3>Total Money In</h3>
                <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.direct_revenue_collection_bank
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.direct_revenue_collection_bank" label="Direct Revenue Collection (Bank)" disabled={true} focused={cashFlow || formik.values?.cash_flow_enable}   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.direct_revenue_collection_cash
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.direct_revenue_collection_cash" label="Direct Revenue Collection (Cash)"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.investments
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.investments" label="Investments"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.borrowings
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.borrowings" label="Borrowings"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.any_other_receipt
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.any_other_receipt" label="Any Other Receipt"   />
                         </Tooltip>
                        </Grid>
                        {/* //// total_money_in_details Field Array */}
                        {formik.values?.cash_flow?.total_money_in_details && formik.values?.cash_flow?.total_money_in_details?.length > 0 ? formik.values?.cash_flow?.total_money_in_details?.map((field, index) => {
                            return <Grid item xs={12} sm={4} md={3}>
                            <Stack flexDirection={'row'} alignItems={'center'} columnGap={1}>
                            <Grid item xs={10}>
                            <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.total_money_in_details[index].value
                         )}
                         >
                                <CustomField inputProps={inputProps} name={`cash_flow.total_money_in_details.${index}.value`} label={field.key} />
                         </Tooltip>
                            </Grid>
                            <Grid display={'flex'} item xs={2}>
                            <button
                                className="button-79"
                                type="button"
                                style={{
                                  backgroundColor: 'transparent',
                                  padding: '0px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '50%'
                                }}
                                onClick={() => {total_money_in_details.splice(index,1); formik.setFieldValue(`cash_flow.total_money_in_details`, total_money_in_details)}}
                              >
                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                              </button> 
                                <button
                                    className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                    onClick={() => {setCurrentObject('total_money_in_details');setOpenCustomValue(true)}}
                                >
                                    <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                                </button>
                            </Grid>
                            </Stack>
                        </Grid>
                        }) : <Grid item xs={12} sm={4} md={2} display={'flex'} alignItems={'center'}>
                              <button
                                className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                onClick={() => {setCurrentObject('total_money_in_details');setOpenCustomValue(true)}}
                              >
                                <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                              </button>
                            </Grid>}
                         </Grid>
                        <Divider sx={{marginTop: 2}} />
                        <h3>Total Money Out</h3>
                         <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.salaries_to_employees_net_pay
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.salaries_to_employees_net_pay" label="Salaries to Employees (Net Pay)"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.tech_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.tech_expenses" label="Tech Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.purchase_n_general_n_admin_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.purchase_n_general_n_admin_expenses" label="Purchase & General & Admin Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.interest_expense
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.interest_expense" label="Interest Expense"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.tax_paid_gst
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.tax_paid_gst" label="Tax Paid (GST)"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.tax_paid_tds
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.tax_paid_tds" label="Tax Paid (TDS)"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.other_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.other_expenses" label="Other Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.provident_fund
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.provident_fund" label="Provident Fund"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.esi
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.esi" label="ESI"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.city_operations
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.city_operations" label="City Operations"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.payment_gateway_charges
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.payment_gateway_charges" label="Payment Gateway Charges"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.marketing_expenses
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.marketing_expenses" label="Marketing Expenses"   />
                         </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.bank_balance_opening
                         )}
                         >
                            <CustomField inputProps={inputProps} name="cash_flow.bank_balance_opening" label="Bank Balance (Opening)"   />
                         </Tooltip>
                        </Grid>

                        {/* ////total_money_out_details Field Array */}
                        {formik.values?.cash_flow?.total_money_out_details && formik.values?.cash_flow?.total_money_out_details?.length > 0 ? formik.values?.cash_flow?.total_money_out_details?.map((field, index) => {
                            return <Grid item xs={12} sm={4} md={3}>
                            <Stack flexDirection={'row'} alignItems={'center'} columnGap={1}>
                            <Grid item xs={10}>
                            <Tooltip
                             title={convertToIndianCurrencyWords(
                            formik.values.cash_flow.total_money_out_details[index].value
                         )}
                         >
                                <CustomField inputProps={inputProps} name={`cash_flow.total_money_out_details.${index}.value`} label={field.key} />
                         </Tooltip>
                            </Grid>
                            <Grid display={'flex'} item xs={2}>
                            <button
                                className="button-79"
                                type="button"
                                style={{
                                  backgroundColor: 'transparent',
                                  padding: '0px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '50%'
                                }}
                                onClick={() => {total_money_out_details.splice(index,1); formik.setFieldValue(`cash_flow.total_money_out_details`, total_money_in_details)}}
                              >
                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                              </button> 
                                <button
                                    className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                    onClick={() => {setCurrentObject('total_money_out_details');setOpenCustomValue(true)}}
                                >
                                    <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                                </button>
                            </Grid>
                            </Stack>
                        </Grid>
                        }) : <Grid item xs={12} sm={4} md={2} display={'flex'} alignItems={'center'}>
                              <button
                                className="button-79" type="button" style={{ backgroundColor: 'transparent',padding: '0px',display: 'flex',alignItems: 'center',borderRadius: '50%'}}
                                onClick={() => {setCurrentObject('total_money_out_details');setOpenCustomValue(true)}}
                              >
                                <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                              </button>
                            </Grid>}
                    </Grid>
                </Card>}
                <button className="button-79" type="submit">Submit</button>
            </Form>
        </FormikProvider>
    </div>

    </>
  )}
export default AddMISReport