import React, { createContext, useContext, useState ,useEffect} from 'react';
import Cookies from 'js-cookie';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const storedPermissions = Cookies.get("permission");
    if (storedPermissions) {
      setPermissions(JSON.parse(storedPermissions));
    }
  }, []);


  useEffect(() => {
    Cookies.set('permission',JSON.stringify(permissions));
  }, [permissions]);


  return (
    <PermissionsContext.Provider value={{ permissions, setPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};