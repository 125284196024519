import React, { useState } from 'react';
// import Card from '../../../components/Card';
// import Loading from '../../../components/Loading';
// import AsyncTableListing from '../../../components/AsyncTableListing';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
// import Clickable from '../../../utils/Clickable';
import { IconEdit } from '@tabler/icons';
import { DescriptionOutlined } from '@mui/icons-material';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Clickable from 'utils/Clickable';
import PrivateDocumentPopup from 'views/startup/popups/PrivateDocumentsPopup';
import ModalComponent from 'ui-component/Modal';
// import ModalComponent from '../../../components/Modal';

const columns = [
  {
    label: 'Name',
    minWidth: 200,
    align: 'center',
    type: 'title'
  },
  {
    label: 'From Date',
    minWidth: 200,
    align: 'center',
    type: 'from_date_format'
  },
  {
    label: 'To Date',
    minWidth: 200,
    align: 'center',
    type: 'to_date_format'
  },
  {
    label: 'Created On',
    minWidth: 200,
    align: 'center',
    type: 'uploaded_date_format'
  },
  {
    label: 'Actions',
    minWidth: 60,
    align: 'center',
    type: 'actions'
  },
];

const MISReports = ({data}) => {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(-1);
  const [limit, setLimit] = useState(20);
//   const { isLoading: isReportListingLoading, data: ReportListingData } = FetchReportListing();
//   const { mutateAsync: downloadDocument } = MSIDownloadDocument();
  const [documentOpen, setDocumentOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  
  const handleDocumentOpen = (row) => {
    setCurrentRow(row);
    setDocumentOpen(true);
  };
  
  const documentHandleClose = () => setDocumentOpen(false);
  
  const ReportAction = ({ row }) => {
    const navigate = useNavigate();
    return (
      <div style={{ display: 'flex', justifyContent: 'left', columnGap: "6px" }}>
        <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleDocumentOpen(row)}>
          <Tooltip title="Documents">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DescriptionOutlined />
            </div>
          </Tooltip>
        </Clickable>
      </div>
    );
  };
  console.log(data,"data")

  
  return (
    <>
      <ModalComponent open={documentOpen} onClose={documentHandleClose} closeIcon={true} style={{ width: '600px' }} disableScrollLock={true}>
       <PrivateDocumentPopup data={currentRow?.documents} />
      </ModalComponent>
      {/* <Card style={{ paddingLeft: '16px', paddingRight: '16px' }} title={"Reports"} backTo={"/dashboard"}> */}
      <div className='custom-card card'>
        <div className='card-body'>
          <div className='custom-table'>
            {/* {isReportListingLoading ? <Loading />
              :  */}
              <AsyncTableListing
                title=""
                columns={columns}
                // data={ReportListingData}
                data={data}
                actions={ReportAction}
                page={page}
                setPage={setPage}
                count={data}
                sort={sort}
                setSort={setSort}
                limit={limit}
                setLimit={setLimit}
                pagination={false}
              />
              {/* } */}
          </div>
        </div>
        </div>
      {/* </Card> */}
    </>
  );
};

export default MISReports;
