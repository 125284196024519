import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchAllRealtimeBanks = (mode="") => {
  return useQuery(
    ['fetch-all-realtime-bank'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/bank-account/realtime`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode=="options"){
        return data.data.data.map((bank)=>({label:bank.bank_name, value: bank._id}))
      }
      return data.data.data
    },
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllBanks = () => {
  return useQuery(
    ['fetch-all-banks'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/setting/banks`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((banks)=>{
      return ({label: banks.name, value: banks['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllCountries = () => {
  return useQuery(
    ['fetch-all-countries'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/setting/countries`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((country)=>{
      return ({label: country.name, value:country['id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllStates = (id) => {

  return useQuery(
    ['fetch-all-states',id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/setting/states/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((state)=>{
      return ({label: state.name, value:state['id']})
    }),
    enabled: !!id,
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllCities = (id) => {

  return useQuery(
    ['fetch-all-cities',id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/setting/cities/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((city)=>{
      return ({label: city.name, value:city['id']})
    }),
    enabled: !!id,
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}