import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form,FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import { ConfirmTransaction } from '../../api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = yup.object({ 
    amount: yup
    .string('Enter Min Investment Amount')
    .required('Min Investment Amount is required')
    .trim(),
});


const ConfirmTransactionPopup = ({dealId, handleClose, activeRow, refetchTransactions}) => {
    // console.log(activeRow);
    const theme = useTheme();
    const { mutateAsync: uploadTransaction } = ConfirmTransaction();
    
    const initialValues = {
      received_amount: activeRow.received_amount || ''
    }

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    validateOnChange:false,
    validateOnBlur:true,
    // enableReinitialize:true,
    onSubmit: (values) => {
      const payload = {received_amount: values.received_amount, investor_id: activeRow.investor_id, startup_deal_id: dealId, confirmationID: "STATIC_CONFIRMATION_ID", status: "confirmed"}
      uploadTransaction(payload, {
        onSuccess:()=>{
          refetchTransactions()
          handleClose()
          toast.success('Transaction Confirmed', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
          },1000)
        },
          onError: (err)=>{
            toast.error(JSON.parse(err.request.response).message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            },1000)
          }
      })
    },
  });
  return (
    <div>
      <Paper sx={{backgroundColor: theme.palette.background.paper, boxShadow: 3, padding:'10px', flex:1}}>
          <FormikProvider value={formik}>
            <Form  onSubmit={formik.handleSubmit} style={{paddingTop:'10px', paddingBottom:'15px', margin:10}}>
              <h3 style={{textAlign:'center',}}>Confirm Transaction</h3>
              {/* {activeRow.transactionId && <p style={{ margin: 0, marginBottom: '30px' }}>Transaction ID: <b>{activeRow.transactionId}</b></p>} */}
              <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12}>
                  <p><b>Investor Name: </b>{activeRow.investor_name}</p>
                  {activeRow.transactionId && <p><b>Transaction ID: </b>{activeRow.transcationID}</p>}
                </Grid>
                <Grid item xs={12}>
                  <CustomField name="received_amount" label="Funds Received Amount"/>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <button className="button-79" type="submit">Submit</button>
              </Grid>
          </Form>
        </FormikProvider>
      </Paper>
    </div>
  );
}

export default ConfirmTransactionPopup;
