import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const CustomButton = ({
  // color=[false, false],
  loading=false,
  color = ['#283593', false],
  size = "large", variant = "contained", sx, children, ...props }) => {
  const customization = useSelector((state) => state.customization);
  const ColorButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: color[0] || theme.palette.secondary[800],
    borderRadius: `${customization.borderRadius}px`,
    '&:hover': {
      backgroundColor: color[1] || theme.palette.secondary.main,
    },
  }));
  const theme = useTheme()

  return (
    <button
      className="button-79"
      style={{ backgroundColor: loading ? theme.palette.grey[500]: color[0], display: 'flex', alignItems: 'center', ...sx }}
      {...props}
    >
      {loading&& <><i className="fa fa-circle-o-notch fa-spin"></i>&nbsp; </>}
      {children}
    </button>
  );
}
// <ColorButton variant={variant} size={size} {...props}>{children}</ColorButton>
CustomButton.propTypes = {
  color: PropTypes.array,
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};
export default CustomButton;
