import React, { useState,useEffect} from 'react';
import { Paper, Box,Typography, Switch, FormControlLabel, Checkbox} from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import Grid from '@mui/material/Grid';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import { FetchOnboarding,FetchAllTemplateMenuStartup,ExportDeals,StartupDocumentChecklist,FetchAllStartup } from 'api';
import Loading from 'views/Loading';
import Card from 'ui-component/cards/Card';
import SelectField from 'ui-component/SelectField';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, InputLabel, MenuItem, Select, Chip, ListItemText } from '@mui/material';

import moment from 'moment';
// import { AiFillCloseCircle } from 'react-icons/ai';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const mode = ""
const Period = [
    { value: '', label: 'Select Option' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quaterly' },
    { value: 'onetime', label: 'One-Time' },
  ];
import { CheckCircle } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    customSelectBox: {
        '& div#demo-simple-select':{ padding: '10px 14px !important'}
    }
});

const StartupChecklistReport = () => {
    const classes = useStyles();
  const excel_report = "excel_report";
  const [title, setTitle] = useState('Startup Document Checklist ');
  const theme = useTheme();
  const [stage, setStage] = useState('');
  // const [period, setPeriod] = useState('');
  const [from_date, setFromDate] = useState(moment().subtract(1, 'year').format('YYYY-MM-DD'));
  const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [searchStatus, setSearchStatus] = useState(false);
  const [reset, setreset] = useState(false)
  const [investorData, setInvestorData] = useState([]);
  const [uniqueStartupNames, setUniqueStartupNames] = useState([]);
  const [selectedValues, setSelectedValues] = useState(['All Startups']);
  const [selectedChecklistValues, setSelectedChecklistValues] = useState('');
  const [selectedChecklistType, setSelectedChecklistType] = useState('');

//   const { isLoading: isStageLoading, isRefetching: isStageRefetching, data: stages } = ExportDeals();
  const { isLoading: isStartupOptionsLoading, data: stages } = FetchAllStartup("options");
  const { mutateAsync: fetchOnbardingInvestor, isLoading: isInvestorLoading, isRefetching: isSyndicateRefetching ,status: targetFeesStatus, error: targetFeesError} = StartupDocumentChecklist()
  const { isLoading: templateMenuLoading, data: templateMenuData,  isRefetching: isStartupRefetching ,status: templateMenuStatus } = FetchAllTemplateMenuStartup("deal")
    const [isPending,setIsPending] = useState(true);
    const [isCompleted,setIsCompleted] = useState(false);

    const handlePendingChange = () => {
        isCompleted && setIsPending(!isPending);
        setSearchStatus(false)
    }
    const handleCompletedChange = () => {
        isPending && setIsCompleted(!isCompleted);
        setSearchStatus(false)
    }
  // const { isLoading: isStageLoading, isRefetching: isStageRefetching, data: stages } = ExportDeals();
  const handleChange = (event) => {
    const hasAllDeals = event.target.value.includes('All Startups');
    if (hasAllDeals) {
        event.target.value = event.target.value.filter((item) => item === 'All Startups');
    }
    setSearchStatus(false)
    setSelectedValues(event.target.value);

  };

  const handleChecklistChange = (event) => {

    const targetValue = event.target.value;
    const targetObject = templateMenuData?.data.find((item) => item._id === targetValue);
    setSearchStatus(false)
    setSelectedChecklistValues(event.target.value);
    
    targetObject && targetObject.checklist_period !== undefined ? setSelectedChecklistType(targetObject.checklist_period) : setSelectedChecklistType("monthly");
};


    useEffect(() => {
      
        if (targetFeesStatus == 'error') {
          setTimeout(()=>{
          toast.error(targetFeesError.response.data.message, {
            position: "top-left",
            // autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }, 400)
        }
      
    }, [targetFeesStatus, targetFeesError])

    useEffect(() => {
      if (investorData?.data?.length  > 0 ) {
        setTitle(`Startup Document Checklist`)
      } else {
        setTitle(`Startup Document Checklist`)
      }
    }, [investorData])
    const captureAndCopyScreenshot = () => {
      const tableElement = document.getElementById('target'); // Replace 'your-table-id' with the ID of your table element
      html2canvas(tableElement).then(canvas => {
        canvas.toBlob(blob => {
          navigator.clipboard.write([
            new ClipboardItem({ [blob.type]: blob }),
          ]);
          toast.success('Screenshot copied!', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      });
    };
    useEffect(() => {
        if (searchStatus) {
         fetchOnbardingInvestor(
          {
            startup_deal_id: selectedValues.join(','),
            checklist_master_id: selectedChecklistValues,
            checklist_type: selectedChecklistType,
            api_type: 'startup',
            from_date: from_date,
            to_date: to_date,
            task_status: (isPending && isCompleted ? '' : isCompleted ? 'Completed': 'pending')
          }
          )
        .then(({ data }) => {
          setInvestorData(data)
          setUniqueStartupNames([...new Set(data?.data?.map(partner => partner?.name))])
        })
        // console.log(investorData,"iDDD")
    }
      }, [searchStatus,reset])
    const handleDateChange = (newValue) => { 
        setFromDate(moment(newValue).format('YYYY-MM-DD'));
        setSearchStatus(false);
    }
    const handleToDateChange = (newValue) => { 
        setToDate(moment(newValue).format('YYYY-MM-DD'));
        setSearchStatus(false);
      }
    // console.log(investorData,"kkkk")
    templateMenuData?.selectedData?.push({label: "MIS Report", value: "mis_report"});
    return(
        <>
        <ToastContainer />
           <Box sx={{ width: '100%' }}>
             <BreadcrumbHeader title={title} />
           </Box>  
          <Card style={{ paddingLeft: '16px', paddingRight: '16px' }}> 
            <Grid container direction="row" columnSpacing={3} rowSpacing={2} mb={2} alignItems={'center'}>
            <Grid item sm={4} xs={4}>
            <SelectField
                            name="checklist_master_id"
                            label="Select Checklist Type"
                            options={templateMenuData?.selectedData}
                            value={selectedChecklistValues}
                            onChange={handleChecklistChange}

                            // onChange={(evt) => {
                            //     setSelected(evt.target.value);
                            // }}
                        />
                                    </Grid>

            {!isStartupOptionsLoading &&<Grid item sm={4} xs={4}>

                    <FormControl fullWidth>
                            <InputLabel  id="demo-simple-select-label">Startups</InputLabel>
                            <Select
                                multiple
                                className={`${classes.customSelectBox}`}
                                value={selectedValues}
                                onChange={handleChange}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Startups"
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={
                                                    value == 'All Startups'
                                                        ? 'All Startups'
                                                        : stages.find((option) => option.value === value)?.label
                                                }
                                                // onDelete={() => handleRemoveChip(value)}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                <MenuItem key="All Startups" value="All Startups">
                                    All Startups
                                </MenuItem>

                                {stages &&
                                    stages.map((option, key) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {/* <Checkbox checked={field.value.includes(option.value)} /> */}
                                            <ListItemText primary={option.label} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>



            </Grid>}
            {selectedChecklistType !== 'monthly' && <Grid item sm={4} xs={4}>
                    <FormControlLabel
          value="Pending"
          control={<Checkbox
            checked={isPending}
            defaultChecked
          onChange={handlePendingChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
          label="Pending"
          labelPlacement="Pending"
        />
        <FormControlLabel
          value="Completed"
          control={<Checkbox
            checked={isCompleted}
          onChange={handleCompletedChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
          label="Completed"
          labelPlacement="Completed"
        />
                    </Grid>}

            
            {selectedChecklistType === 'monthly' && (
                        <>
                            <Grid item sm={4} xs={4}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        label="From Date"
                                        size="small"
                                        value={from_date}
                                        disableFuture={true}
                                        onChange={handleDateChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputFormat="MM/YYYY"
                                        autoComplete="off"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        label="To Date"
                                        size="small"
                                        value={to_date}
                                        disableFuture={true}
                                        onChange={handleToDateChange}
                                        // onChange={handleOnboardedFromDateChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputFormat="MM/YYYY"
                                        autoComplete="off"
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </>
                    )}
      
           
                      <Grid item sm={1} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <button
                            className="button-79"
                            style={{
                                padding: '0.4rem 1rem',
                                display: 'flex',
                                alignItems: 'center',
                                height: '40px',
                                background: searchStatus && selectedChecklistValues && selectedChecklistType ? '#c62828' : '#283593'
                            }}
                            type="button"
                            onClick={() => {
                                if (searchStatus && selectedChecklistType !== '' && selectedChecklistValues !== '') {
                                    setStage('');
                                    setIsPending(true);
                                setIsCompleted(false);
                                    setSelectedChecklistType(null)
                                    setSelectedChecklistValues(null)
                                    setSelectedValues(['All Startups'])

                                    // setPeriod('');
                                    // setFromDate(null);
                                    // setToDate(null);
                                    setreset(!reset);
                                }
                                setSearchStatus((oldVal) => !oldVal);
                            }}
                            fullWidth
                            // sx={{height:'100%'}}
                        >
                            {searchStatus && selectedChecklistValues !== '' && selectedChecklistType !== '' ? 'Reset' : 'Search'}
                        </button>

                    </Grid>
            </Grid>            
            <>
                    <div style={{borderRadius: '8px'}} className="custom-table table-responsive lp-summary-table investor-onboarding-table" id="target">
                        {selectedChecklistType == 'monthly' && (
                            <table className="w-100 table add-border" >
                                {investorData?.data?.length > 0  && targetFeesStatus == 'success' && (
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: 200 }}>Document Checklist</th>
                                            {investorData?.headers && investorData?.headers.length > 0 && investorData?.headers.map((header, index) => (
                                                <th>{header}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                )}
                                <tbody>
                                    {investorData?.data?.length > 0 && investorData?.headers && investorData?.headers.length > 0  && targetFeesStatus == 'success' ? (
                                        <>
                                            {uniqueStartupNames?.map((partnername, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            {' '}
                                                            <td className="text-left" colSpan={'100%'}>
                                                                <Typography variant="h4">{partnername}</Typography>
                                                            </td>
                                                        </tr>

                                                        {investorData?.data
                                                            ?.filter((partner) => partner?.name === partnername)
                                                            .map((partner, partnerIndex) => (
                                                                <tr key={partnerIndex}>
                                                                    <td> {partner?.task_name}</td>
                                                                    {Object.entries(partner?.dateArr).map(([date, value], dateIndex) => (
                                                                        <td key={dateIndex}>
                                                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                                                {value ? (
                                                                                    <CheckIcon style={{ color: 'green' }} />
                                                                                ) : (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    ) : (
                                      <tr>
                                      <td className="text-center" colSpan={5}>
                                          <Typography variant="h4">Apply Suitable Filters</Typography>
                                      </td>
                                  </tr>
                                    )}
                                    {/* {investorData?.data?.length > 0 && searchStatus && stage  && targetFeesStatus=='success' &&  (
                <tr >
                 <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>Total</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetAmountPercentage}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetAmount}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalAchievedTargetAmount}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalRemainingAmount}</td>
                </tr>
              )} */}
                                </tbody>
                            </table>
                        )}
                        {(selectedChecklistType == 'onetime'  || selectedChecklistType == 'quarterly') &&
                            (selectedValues.length == 1 && !selectedValues.includes('All Deals')) &&
                            investorData.data &&
                            investorData.data.length > 0 &&
                            investorData.data[0] &&
                            investorData.data[0].checkListMaster &&
                            investorData.data[0].checkListMaster.length > 0 &&
                            investorData.data[0].checkListMaster[0].tasks.length > 0 && (
                                <table className="w-100 table add-border">
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th className="text-start text-nowrap">Task Name</th>
                                            <th className="text-start text-nowrap">Task Description</th>
                                            <th className="text-center text-nowrap">Required</th>
                                            <th className="text-center text-nowrap">ETA Date</th>
                                            {/* <th className='text-center text-nowrap'>Document Date</th> */}
                                            <th className="text-center text-nowrap">Completed Date</th>
                                            <th className="text-center text-nowrap">Updated</th>
                                            <th className="text-center text-nowrap">Updated By</th>
                                            <th className="text-center text-nowrap">Status</th>
                                            {/* <th className='text-center text-nowrap'>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {investorData.data[0].checkListMaster[0].tasks &&
                                        investorData?.data[0]?.checkListMaster[0].tasks ? (
                                            investorData?.data[0].checkListMaster[0]?.tasks.map((task, index) => (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {task.task_status == 'Completed' ? (
                                                                <div style={{ color: '#60cb5e',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'not_required' ? (
                                                                <div style={{ color: '#60cb5e',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'pending' ? (
                                                                <div style={{ color: '#616161' ,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'inProgress' ? (
                                                                <div style={{ color: '#d4a72d' ,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            
                                                        {task.task_status == "in_review" ? <div style={{color: '#CC5500',display:'flex',alignItems:'center',justifyContent:'center'}}><CheckCircle/></div> : ""}
                                                       {task.task_status == "ready_for_approve" ? <div style={{color: '#A0522D',display:'flex',alignItems:'center',justifyContent:'center'}}><CheckCircle/></div> : ""}
                                                                       
                                                            {!task.task_status || task.task_status == 'undefined' ? (
                                                                <div style={{ color: '#616161' ,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                        <td className="text-start">{task.task_name}</td>
                                                        <td className="text-start">{task.task_description}</td>
                                                        <td className="text-center">{task.required ? 'Yes' : 'No'}</td>
                                                        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                                                            {task.eta_date_format}
                                                        </td>
                                                        {/* <td className='text-center'style={{whiteSpace:"nowrap"}} >{task.document_date}</td> */}
                                                        <td className="text-center">
                                                            {task.task_status == 'Completed' ? task.completed_date_format : ''}
                                                        </td>
                                                        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                                                            {task.updatedAt != '' ? task.updatedAt : ''}
                                                        </td>
                                                        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                                                            {task.last_updated_by != '' ? task.last_updated_by : ''}
                                                        </td>
                                                        <td className="text-center">
                                                            {' '}
                                                            {task.task_status == 'Completed' ? (
                                                                <Chip
                                                                    size="small"
                                                                    label="Completed"
                                                                    sx={{
                                                                        backgroundColor: '#ebfaeb',
                                                                        color: '#60cb5e',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#60cb5e'
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'not_required' ? (
                                                                <Chip
                                                                    size="small"
                                                                    label="Not Required"
                                                                    sx={{
                                                                        backgroundColor: '#ebfaeb',
                                                                        color: '#60cb5e',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#60cb5e'
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}

{task.task_status == "in_review" ? <Chip
                                                                    size="small"
                                                                    label="In Review"
                                                                    sx={{
                                                                        backgroundColor: '#efefef',
                                                                        color: '#CC5500',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700,
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#d4a72d'
                                                                    }}
                                                                /> : ""}
                                                                {task.task_status == "ready_for_approve" ? <Chip
                                                                    size="small"
                                                                    label="Ready For Approve"
                                                                    sx={{
                                                                        backgroundColor: '#efefef',
                                                                        color: '#A0522D',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700,
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#d4a72d'
                                                                    }}
                                                                /> : ""}

                                                            {task.task_status == 'pending' ? (
                                                                <Chip
                                                                    size="small"
                                                                    label="Pending"
                                                                    sx={{
                                                                        backgroundColor: '#efefef',
                                                                        color: '#616161',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#d4a72d'
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'inProgress' ? (
                                                                <Chip
                                                                    size="small"
                                                                    label="In Progress"
                                                                    sx={{
                                                                        backgroundColor: '#fef8e8',
                                                                        color: '#d4a72d',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#39739d'
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {!task.task_status || task.task_status == 'undefined' ? (
                                                                <Chip
                                                                    size="small"
                                                                    label="Pending"
                                                                    sx={{
                                                                        backgroundColor: '#efefef',
                                                                        color: '#616161',
                                                                        border: 'none',
                                                                        minWidth: '100px',
                                                                        fontSize: '12px',
                                                                        borderRadius: '4px',
                                                                        fontWeight: 700
                                                                        // borderWidth: '0.5px',
                                                                        // borderStyle: 'solid',
                                                                        // borderColor: '#d4a72d'
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                    </tbody>
                                </table>
                            )}

                        {(selectedChecklistType == 'onetime' || selectedChecklistType == 'quarterly') &&
                            (selectedValues.length > 1 || selectedValues.includes('All Startups')) &&
                            investorData.data && 
                            investorData.data.length > 0 &&
                            investorData?.data.map((task_data, index) => (
                                <>  {task_data.checkListMaster && task_data.checkListMaster.length > 0 && task_data.checkListMaster[0].tasks&&  <table className="w-100 table add-border">
                                                <thead>
                                                    <tr>
                                                        <th style={{position:'sticky',left:0, minWidth: 150, maxWidth: 200}}>Scheme Name</th>
                                                        {task_data.checkListMaster && task_data.checkListMaster.length > 0 && task_data.checkListMaster[0].tasks ? (
                                                          task_data.checkListMaster[0].tasks.map((task, index) => (
                                                            <>
                                                            
                                                            <th className="text-start text-nowrap"> Task {index + 1}  
                                                               <Tooltip title={task.task_name}>
                                                                <InfoOutlinedIcon style={{ cursor: 'pointer', marginLeft: 5,fontSize:'small' }} />
                                                              </Tooltip>
                                                            </th>
                                                            </>
                                                         )) ): <></>}
                                                  
                                                        
                                                        {/* <th className="text-start text-nowrap">Task Description</th> */}
                                                        {/* <th className="text-center text-nowrap">Required</th>
                                                        <th className="text-center text-nowrap">ETA Date</th>
                                                        <th className="text-center text-nowrap">Completed Date</th>
                                                        <th className="text-center text-nowrap">Updated</th>
                                                        <th className="text-center text-nowrap">Updated By</th>
                                                        <th className="text-center text-nowrap">Status</th>
                                                    */}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                <td className="text-start" style={{position:'sticky',left:0, minWidth: 150, maxWidth: 200}}>{task_data.name}</td>

                                                {task_data.checkListMaster && task_data.checkListMaster.length > 0 && task_data.checkListMaster[0].tasks ? (
                                                          task_data.checkListMaster[0].tasks.map((task, index) => (
                                                            <>
                                                        <td>
                                                        <Tooltip title={!task.task_status || task.task_status == 'undefined' ? 'Pending': task.task_status == 'pending' ? 'Pending' : task.task_status == 'inProgress' ? 'In Progress' : task.task_status == 'in_review' ? 'In Review' : task.task_status == 'ready_for_approve' ? 'Ready for Approve' : task.task_status == 'not_required' ? 'Not Required' : task.task_status  }>
                                                            {task.task_status == 'Completed' ? (
                                                                <div style={{ color: '#60cb5e',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'not_required' ? (
                                                                <div style={{ color: '#60cb5e',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {task.task_status == 'pending' ? (
                                                                <div style={{ color: '#616161' ,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                                    {task.task_status == "in_review" ? <div style={{color: '#CC5500',display:'flex',alignItems:'center',justifyContent:'center'}}><CheckCircle/></div> : ""}
                                                       {task.task_status == "ready_for_approve" ? <div style={{color: '#A0522D',display:'flex',alignItems:'center',justifyContent:'center'}}><CheckCircle/></div> : ""}
                                                                    
                                                            {task.task_status == 'inProgress' ? (
                                                                <div style={{ color: '#d4a72d',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {!task.task_status || task.task_status == 'undefined' ? (
                                                                <div style={{ color: '#616161',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                                    <CheckCircle />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                             </Tooltip>
                                                        </td>
                                                        </>
                                                         )) ): <></>}
                                                </ tr>
                                                <tr style={{border: 'none !important'}}>
                                                  &nbsp;&nbsp;
                                                </tr>
                                                  </tbody>
                                </table>}</>
                            ))}

                                  {selectedChecklistType == "" && targetFeesStatus != 'success' && <table className="w-100 table add-border" >
                                            <tbody>
                                            <tr>
                                      <td className="text-center" colSpan={5}>
                                          <Typography variant="h4">Apply Suitable Filters</Typography>
                                      </td>
                                  </tr>
                                        </tbody>
                                  </table>}


                    </div>
                </>
         </Card>
    </>
  )
}
export default StartupChecklistReport