import { Paper, Box, Stack, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CustomButton from 'ui-component/CustomButton';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/SelectField';
import { useState } from 'react';


const TransactionFilterPopup = ({
    handleClose,
    partner,
    setPartner,
    filterRows,
    syndicate,
    setSyndicate,
    search,
    copyTransactions,
    setCopyTransaction,
    setTransactions,
    transactionsInvestorsData
}) => {
    const theme = useTheme();
   const [selectedPartner , setSelectedPartner] = useState(partner);
   const [selectedSyndicate , setSelectedSyndicate] = useState(syndicate);

    return (
        <>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxHeight: '90vh'
                }}
            >
                 <h3 style={{ textAlign: 'center' }}>Filters</h3>
                <Grid container direction="row" columnSpacing={3} rowSpacing={2}>
                    <Grid item sm={6} xs={6}>
                        <SelectField
                            size="medium"
                            onChange={(event) => {
                                setSelectedPartner(event.target.value);
                            }}
                            label="Partners"
                            value={selectedPartner}
                            options={[...new Set(copyTransactions?.map((item) => item.investor_partner))].map((currentPartner) => {
                                return { label: currentPartner, value: currentPartner };
                            })}
                            placeholder="Select Partner"
                        />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <SelectField
                            size="medium"
                            onChange={(event) => {
                                setSelectedSyndicate(event.target.value);
                            }}
                            label="Syndicate (Partner)"
                            value={selectedSyndicate}
                            options={[...new Set(copyTransactions?.map((item) => item.syndicate_partner_name))].map((currentSyndicate) => {
                                return { label: currentSyndicate, value: currentSyndicate };
                            })}
                            placeholder="Select Syndicate Partner"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Stack direction="row" spacing={2}>
                        <CustomButton
                            type="submit"
                            onClick={() => {
                                filterRows(search,selectedPartner, selectedSyndicate);
                                setPartner(selectedPartner)
                                setSyndicate(selectedSyndicate)
                                handleClose();
                            }}
                        >
                            Filter
                        </CustomButton>
                        <CustomButton
                            color={[theme.palette.error.dark, theme.palette.error.main]}
                            onClick={() => {
                                filterRows(search, '', '');
                                setPartner('');
                                setSyndicate('');
                                setSelectedPartner('');
                                setSelectedSyndicate('')
                            }}
                        >
                            Reset
                        </CustomButton>
                    </Stack>
                </Grid>
            </Paper>
        </>
    );
};

export default TransactionFilterPopup;
