
import React, { useEffect,useState,lazy } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Box, Paper } from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import { makeStyles } from '@mui/styles';
import { FetchVenturePartners,ExportVenturePartners } from 'api';
import CustomButton from 'ui-component/CustomButton';
import Cookies from 'js-cookie';
import Loadable from 'ui-component/Loadable';
import Loading from 'views/Loading';

const useStyles = makeStyles({
    customTable: {
        '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
        '& .MuiTableCell-head': {
            color: 'white',
            backgroundColor: '#0000FF'
        }
    }
});
const VPInvestmentDetails = () => {
    const [title, setTitle] = useState('VP Investment Details');
    const classes = useStyles();
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [reportModule, setReportModule] = useState(null); // Define the state
  const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    const excel_report = "excel_report";
    const { isLoading: isDashDataLoading, data: dashData } = FetchVenturePartners();
    const { refetch: refetchExportVenturePartners, isLoading: isExportVenturePartners,isRefetching: isExportVenturePartnersRefetching } = ExportVenturePartners(excel_report);
    // console.log(dashData,"DA");
    useEffect(() => {
        const permission_values = localStorage.getItem('permission');
        const permissions = permission_values != undefined ?  JSON.parse(permission_values) : [];
        // console.log(permissions,"say");
        const module = permissions.find(
          (module) => module.module_name === 'reports' && module.permisson === true
        );
        setReportModule(module); // Set the value in the state
      }, []);
    function handleSearch() {
        if (dashData && searchQuery !== null && searchQuery !== undefined) {
            const filteredData = dashData.filter((partner) =>
                (partner.investor_name && partner.investor_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (partner.representative_name && partner.representative_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (partner.venture_partner_name && partner.venture_partner_name.toLowerCase().includes(searchQuery.toLowerCase()))
            );
            return filteredData;
        }
        return [];
    }

    // if (isDashDataLoading) {
    //     return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
    //   }
    
    function download() {
        setIsExportLoading(true);
        refetchExportVenturePartners()
          .then(({ data }) => {
            setIsExportLoading(false);
      
            const link = document.createElement('a');
            link.href = data.url;
            link.target = '_blank';   
            link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
            link.click();
          })
      } 
    
    return (
        (reportModule != undefined  && reportModule.vp_investment == true) ?
        <>
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={title} />
            </Box>
            <div className='custom-menu-block view-investor-block'>
                <div className='d-flex mb-3'>
                    <div>
                        <input type='text' className='form-control' placeholder='Search'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                         />
                    </div>
                    <div className='ms-3'>
                        <button className='button-79' type='button' onClick={handleSearch}>Search</button>
                    </div>
                </div>
                <div className='custom-table vp-investment-dtl-table'>
                <Paper sx={{ width: '100%' }}>
                    <TableContainer sx={{ maxHeight: "calc(100vh - 300px)",borderRadius: '8px' }}>
                        {/* , border: 1, borderColor: 'rgba(224, 224, 224, 1)' */}
                        <Table className={`${classes.customTable} add-border remove-border-collapse`} aria-label="customized table" stickyHeader >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        '& .MuiTableCell-sizeMedium': {
                                            padding: '10px 16px 10px 16px' // <-- arbitrary value
                                        }
                                    }}
                                >
                                    <TableCell colSpan={10} className='text-center'  style={{ top: 1, borderBottom: "1px solid rgb(213 213 213 / 30%)"}}>Venture Partner Investment Details</TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '& .MuiTableCell-sizeMedium': {
                                            padding: '10px 16px 10px 16px' // <-- arbitrary value
                                        },
                                        position: 'relative',
                                        top: '-3px'
                                    }}
                                >
                                    <TableCell style={{ top: 52}}>LP Code</TableCell>
                                    <TableCell style={{ top: 52}}>Investor Name</TableCell>
                                    <TableCell style={{ top: 52}}>Representative</TableCell>
                                    <TableCell style={{ top: 52}}>Scheme Name</TableCell>
                                    <TableCell style={{ top: 52}}>Investment Date</TableCell>
                                    <TableCell style={{ top: 52}}>Partners</TableCell>
                                    <TableCell style={{ top: 52}}>Venture Partner</TableCell>
                                    <TableCell style={{ top: 52}}>Amount</TableCell>
                                    <TableCell style={{ top: 52}}>VP Fee %</TableCell>
                                    <TableCell style={{ top: 52}}>Fees Payable</TableCell>
                                </TableRow>
                            </TableHead>
                            {searchQuery.trim() === '' ? (
                               <TableBody>
                                {dashData?(
                                    dashData.map((partner, index) => (
                                <tr key={index}>
                                    <td>{partner.lp_code}</td>
                                    <td>{partner.investor_name}</td>
                                    <td>{partner.representative_name}</td>
                                    <td>{partner.scheme_name}</td>
                                    <td>{partner.invested_date}</td>
                                    <td>{partner.partner_name}</td>
                                    <td>{partner.venture_partner_name}</td>
                                    <td>{partner.investment_amount}</td>
                                    <td>{partner.vp_fees_percentage_final}</td>
                                    <td>{partner.payable_fees}</td>
                                </tr>
                                ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" align="center">
                                            
                                             <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
                                        
                                        </td>
                                    </tr>
                                )}
                                 {/* <tr>
                                    <td>LP-213</td>
                                    <td>Kushal Tandon</td>
                                    <td>Kushal</td>
                                    <td>setitD</td>
                                    <td>07-Aug-2023 12:03PM</td>
                                    <td>Radhika Malpani</td>
                                    <td>Radhika Malpani</td>
                                    <td>1000</td>
                                    <td>2%</td>
                                    <td>15000</td>
                                </tr> */}
                                
                               </TableBody>
                            ) : (
                             <TableBody>
                                {handleSearch().length > 0 ? (
                            handleSearch().map((partner, index) => (
                                <TableRow key={index}>
                                    <TableCell>{partner.lp_code}</TableCell>
                                    <TableCell>{partner.investor_name}</TableCell>
                                    <TableCell>{partner.representative_name}</TableCell>
                                    <TableCell>{partner.scheme_name}</TableCell>
                                    <TableCell>{partner.invested_date}</TableCell>
                                    <TableCell>{partner.partner_name}</TableCell>
                                    <TableCell>{partner.venture_partner_name}</TableCell>
                                    <TableCell>{partner.investment_amount}</TableCell>
                                    <TableCell>{partner.vp_fees_percentage_final}</TableCell>
                                    <TableCell>{partner.payable_fees}</TableCell>
                                </TableRow>
                            ))
                                ) : (
                                <TableRow>
                                 <TableCell colSpan={10}>No matching records found.</TableCell>
                                </TableRow>
                                )}
                             </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    </Paper>
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right',marginTop: '10px' }}>
                       <CustomButton
                         onClick={download}
                         size="medium"
                         loading={isExportLoading}
                         sx={{ height: '40px', marginRight: '10px' }} 
                        >
                         Export As Excel
                        </CustomButton>
                    </div>
                    <table className='w-100'>
                        <thead>

                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
        :    <Error404 />
    )
}

export default VPInvestmentDetails