// assets
import { IconNews } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const master = {
    id: 'article1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'article11',
            title: 'Articles',
            type: 'item',
            url: `/resources/articles`,
            icon: IconNews,
            breadcrumbs: false
        }
    ]
};

export default master;
