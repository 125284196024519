import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    background: theme.palette.background.paper,
    // background: '#f9f9fc8e'
  },
  '& input:valid + fieldset': {
    borderColor: theme.palette.secondary[200],
    borderWidth: 2,
  },
  '& input:valid:hover + fieldset': {
    borderColor: '#4050b5',
    borderWidth: 2,
  },
  '& input:valid:focus + fieldset': {
    borderColor: '#4050b5',
    borderLeftWidth: 6,
    padding: '4px !important', 
  },
}));

function TextFieldComponent({...props}) {
  return (
    <CustomTextField fullWidth {...props}/>
  )
}

export default TextFieldComponent