import { Box, Grid, Paper, Tooltip } from '@mui/material'
import Card from 'ui-component/cards/Card'
import { UpdatePassword } from 'api'
import { Form, FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader'
import CustomButton from 'ui-component/CustomButton'
import CustomField from 'ui-component/formik/CustomField'
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie'
import Avatar from 'ui-component/extended/Avatar'

const ChangePassword = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    let profile_image = Cookies.get('profile_image');
    let email = (Cookies.get('email'));
    let user_name = (Cookies.get('name'));

    const { mutateAsync: updatePassword, status: UpdatePasswordStatus, error: UpdatePasswordError } = UpdatePassword()

    const formik = useFormik({
        initialValues: {
            old_pin: '',
            pin: '',
            confirm_pin: ''
        },
        onSubmit: (values) => {
            // console.log(values, 'Change Password')
            updatePassword({ ...values }, {
                onSuccess: (succ) => {
                    toast.success(JSON.parse(succ.request.response).message, {
                        position: "top-left",
                        // autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }, 5000);
                    setTimeout(() => {
                        Cookies.remove('jwt', { path: '/' });
                        Cookies.remove('name', { path: '/' });
                        Cookies.remove('email', { path: '/' });
                        Cookies.remove('investor-search', { path: '/' });
                        Cookies.remove('deal-index', { path: '/' });
                        Cookies.remove('investor-index', { path: '/' });
                        Cookies.remove('master-index', { path: '/' });
                        Cookies.remove('user_id', { path: '/' });
                        Cookies.remove('slug', { path: '/' });
                        Cookies.remove('profile_image', { path: '/' });
                        Cookies.remove('user_role', { path: '/' });
                        localStorage.removeItem('permission');
                        // Cookies.remove('permission', { path: '/' });
                        Cookies.remove('permission_id', { path: '/' });
                        Cookies.remove('syndicate_id', { path: '/' });
                        // window.location.reload();
                        navigate('/login');
                        // setOpen(false);
                        queryClient.clear();
                    }, 2000)
                },
                onError: (err) => {
                    setTimeout(() => {
                        toast.error(JSON.parse(err.request.response).message, {
                            position: "top-left",
                            // autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        }, 2000);
                    })
                }
            })
        }
    })
    return (
        <div><ToastContainer />
            <BreadcrumbHeader title={'Change Password'} shouldGoBack />
            <Card title={'Change Password'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px' }}>
                        <Grid xs={12} item container gap={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} xs={12} sm={6} gap={2}>
                                <Tooltip title={user_name}>{profile_image && profile_image?.length > 0 ? <img src={profile_image} style={{
                                    borderRadius: '50%', maxWidth: '3rem'
                                }} alt={profile_image} /> : <Avatar />} </Tooltip>
                                <span>{email}</span>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <CustomField type='password' required name={'old_pin'} label={'Current Password'} />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <CustomField type='password' required name={'pin'} label={'New Password'} />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <CustomField type='password' required name={'confirm_pin'} label={'Confirm Password'} />
                            </Grid>
                            <Grid xs={12} sm={6} width='100%' display={'flex'} flexDirection={'row-reverse'} >
                                <CustomButton type='submit'>Update</CustomButton>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Card>
        </div>
    )
}

export default ChangePassword