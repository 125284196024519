import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Cookies from 'js-cookie';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    var routes = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined)?MainRoutes: AuthenticationRoutes; 
    return useRoutes([routes])
}
