// assets
import { IconFile, IconPresentationAnalytics } from '@tabler/icons';

// constant
const icons = { IconPresentationAnalytics };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const UploadDocument = {
    id: 'UploadDocument1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'UploadDocument11',
            title: 'Documents',
            type: 'item',
            url: `/upload-documents`,
            icon: IconFile,
            breadcrumbs: false
        }
    ]
};

export default UploadDocument;
