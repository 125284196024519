import { Box } from '@mui/material';
import { AttachExcelReportAPI, FetchAllExcelMasterTemplate, FetchExcelSelectedMasterReport, FetchSelectedExcelReport } from 'api';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import SelectField from 'ui-component/SelectField';

const initialValues = {
    excel_report_id: '',
    excel_report: []
    // checklist_period:""
};

function incrementString(input) {
    // Extract non-numeric and numeric parts
    const nonNumericPart = input.replace(/\d+/, '');
    const numericPart = input.match(/\d+/);

    // If there is a numeric part, increment it; otherwise, default to 1
    const newNumericPart = numericPart ? (parseInt(numericPart[0]) + 1).toString() : '1';

    // Concatenate the non-numeric and incremented numeric parts
    const result = nonNumericPart + newNumericPart;

    return result;
}

const StartupAttachExcelReport = ({reportMasterData}) => {
    const { id: currentStartupId } = useParams();
    const [selected, setSelected] = useState(null);
    const [fromik_values, formikValues] = useState(initialValues);
    // const [selected_template_id, setTemplateId] = useState(null);
    const { mutateAsync: attachExcelReport, status: attachExcelReportStatus, error: attachExcelReportError } = AttachExcelReportAPI();
    const {
        mutateAsync: fetchSelectedExcelReport,
        isLoading: isfetchSelectedExcelReportLoading,
        status: fetchSelectedExcelReportStatus,
        error: fetchSelectedExcelReportError
    } = FetchSelectedExcelReport();
    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const updatedTasks = values?.excel_report?.map((sheet, index) => (
                {
                    ...sheet,
                    celldata:sheet?.celldata?.map(cell => ({
                        ...cell,
                        v: { ...cell?.v, lo: (cell?.v?.m != null && cell?.v?.m != 0 && cell?.v?.m != "") ? 1 : 0 }
                    }))
                }
            ));
            values.excel_report = updatedTasks;
            attachExcelReport({ payload: { ...values }, id: currentStartupId });
            // console.log({ ...values }, '{...values}',  currentStartupId, "currentIds");
        }
    });
    const {
        isLoading: templateExcelReportsLoading,
        data: templateExcelReportsData,
        status: templateExcelReportsStatus
    } = FetchAllExcelMasterTemplate(1, '', 1);

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    useEffect(() => {
        if(selected != null){fetchSelectedExcelReport({
            selected: selected
        }).then(({data})=> {
            // console.log(data,"datatatat")
            formik.setFieldValue('excel_report_id', selected);
            formik.setFieldValue('excel_report', data?.data?.sheet_data);
        })}
        else if(reportMasterData?.length > 0 && (reportMasterData[0]?.excel_report_id != null || reportMasterData[0]?.excel_report_id != undefined)) {
            fetchSelectedExcelReport({
                selected: reportMasterData[0]?.excel_report_id
            }).then(({data})=> {
                // console.log(data,"datatatat")
                formik.setFieldValue('excel_report_id', reportMasterData[0]?.excel_report_id);
                formik.setFieldValue('excel_report', data?.data?.sheet_data);
            })
        }
        // [selected,isfetchSelectedExcelReportLoading,reportMasterData])
    }, [])

    useEffect(() => {
        if (attachExcelReportStatus == 'success') {
            formik.resetForm();
            toast.success('Report Attached', {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    // navigate('/startups');
                }
            });
        }
        if (attachExcelReportStatus == 'error') {
            toast.error(attachExcelReportError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [attachExcelReportStatus]);
    // console.log(fromik_values, "fromik_values", templateExcelReportsData)
    // let selectOptions = ;
    // console.log(reportMasterData[0]?.excel_report_id,"reportMasterData")
    return (

        <>
            <ToastContainer />
            {/* <BreadcrumbHeader title={"Attach Checklist"} shouldGoBack /> */}
            <div style={{ marginTop: '25px' }}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
                        <div className="row mt-2">
                            <div className="col-sm-6 col-12">
                                <SelectField
                                    name="excel_report_id"
                                    label="Select Excel Report"
                                    options={templateExcelReportsData?.data.map((report) => {
                                        return ({ label: report.excel_report_name, value: report._id })
                                    })}
                                    defaultValue={reportMasterData?.length > 0 ? reportMasterData[0]?.excel_report_id : null}
                                    onChange={(evt) => {
                                        setSelected(evt.target.value);
                                    }}
                                    sx={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-sm-3 col-12">
                                <button type="submit" className="button-79 text-center theme-btn h-50">
                                    Attach Report
                                </button>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </>
    )
}

export default StartupAttachExcelReport