import  { useState, useRef, useEffect } from 'react';
import { TextField, Paper, Box, Button, Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ZoomInMapOutlined } from '@mui/icons-material';

import { useFormik, Form, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';

import { Workbook } from '@fortune-sheet/react';
import '@fortune-sheet/react/dist/index.css';
import { ZoomOutMapOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const ExcelSheet = ({ data, setCurrentData, selectedReportData, isEditMode,workbookRef }) => {
 

    const [isFullScreen, setIsFullScreen] = useState(false);
    const theme = useTheme();
    const CustomTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px',
                    borderRightWidth: '0px'
                }
            }
        }
    })(TextField);

    const handleWorkbookChange = (newData) => {
        setCurrentData(newData);
    };

    const toogleFullScreen = () => {
        const element = document.getElementById('sheet');
        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement;

        if (fullscreenElement) {
            document.exitFullscreen();
            //   setIsFullScreen(false);
        } else {
            element.requestFullscreen();
            //   setIsFullScreen(true);
        }
    }
        // Logic to lock the cell

        const handleLockCell = () => {
            // Ensure workbookRef and current exist
            if (workbookRef && workbookRef.current) {
              // Access the getSelection function from the workbookRef    
              const getFlattenRange = workbookRef.current.getFlattenRange();
              // Iterate over each object in data1
              getFlattenRange && getFlattenRange.length > 0 && getFlattenRange.forEach(item1 => {

                            workbookRef.current.setCellFormat( item1.r ,item1.c,"lo",1);
                         
                    });
    
                    
            }
          };
    
    //   Logic to unlock the cell

    const handleUnLockCell = () => {
        // Ensure workbookRef and current exist
          // Ensure workbookRef and current exist
          if (workbookRef && workbookRef.current) {
            // Access the getSelection function from the workbookRef    
            const getFlattenRange = workbookRef.current.getFlattenRange();
            // Iterate over each object in data1
            getFlattenRange && getFlattenRange.length > 0 && getFlattenRange.forEach(item1 => {

                          workbookRef.current.setCellFormat( item1.r ,item1.c,"lo",0);
                       
                  });
  
                  
          }
      };

    useEffect(() => {
        const fullscreenChangeHandler = () => {
            setIsFullScreen(!!(document.fullscreenElement || document.webkitFullscreenElement));
        };

        document.addEventListener('fullscreenchange', fullscreenChangeHandler);
        document.addEventListener('webkitfullscreenchange', fullscreenChangeHandler);

        return () => {
            document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
            document.removeEventListener('webkitfullscreenchange', fullscreenChangeHandler);
        };
    }, []);


    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', width: '100%' }}>

                <div
                    style={{
                        width: isFullScreen ? '100vw' : '100%',
                        height: '100vh',
                        overflow: 'auto',
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'col',
                        paddingRight: 10,

                    }}
                    id='sheet'
                >
                    <Workbook data={isEditMode ? selectedReportData?.data?.sheet_data : data}
                      
                        customToolbarItems={[
                            {
                                key: "zoom",
                                tooltip: isFullScreen ? 'Close Full Screen' : 'Full Screen',
                                icon: isFullScreen ? <ZoomInMapOutlined /> : <ZoomOutMapOutlined />,
                                onClick: () => { toogleFullScreen() }
                            },
                            {
                                key: "Lock",
                                tooltip: 'Lock-Cell',
                                icon: <LockIcon/>,
                                onClick: handleLockCell,
                            },
                            {
                                key: "UnLock",
                                tooltip:"UnLock-Cell" ,
                                icon: <LockOpenIcon/>,
                                onClick: handleUnLockCell,
                            },
                        ]}

                        ref={workbookRef} 

                        onChange={handleWorkbookChange} />
                </div>
            </Paper>
        </>
    );
};

export default ExcelSheet;