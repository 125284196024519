import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';


export const FetchAllArticles = ({page, search, sort=1, limit=20}) => {
    return useQuery(
      ['article-listing', page, sort, limit], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/article?page=${page}&v_no=1&searchText=${search}${sort!=0 ? `&sort=${sort}`:""}&limit=${limit}`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwt')}`,
            'Content-Type': 'application/json'
          },
        }) 
    },{
      select: (data)=>({data: data.data.data, count: data.data.count}),
      refetchOnWindowFocus:false,
      retry: (failureCount, error)=>{
        return RetryOnError(failureCount, error)
      },
      onError: (err)=>{
        return verifyToken(err)
      },
    })
  }

export const FetchArticleDetails = (id, mode) => {

  return useQuery(
    ['article-details', id, mode], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/article/${id}?v_no=1`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode==='edit'){
        const savedData = data.data.data[0]
        const savedValues = {
          short_heading: savedData.short_heading || '',
          heading: savedData.heading || '',
          description: savedData.description || '',
          type: savedData.type || '',
          is_featured: savedData.is_featured || '',
          publish_date: savedData.publish_date || null,
          thumbnail: {name: savedData.thumbnail_name || '', url: savedData.thumbnail_url || ''},
          image: {name: savedData.image_name || '', url: savedData.imageUrl || ''},
          video_url: savedData.video_url || '',
          lead_investor_id: (savedData?.lead_investor_id && savedData?.lead_investor_id.length && savedData?.lead_investor_id[0])  || "",
          syndicate_id: (savedData?.syndicate_id && savedData?.syndicate_id.length && savedData?.syndicate_id[0]) || ""
        }
        return savedValues
      }
      return data.data.data[0]
    },
    refetchOnWindowFocus:false,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AddArticle = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/article`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'multipart/form-data'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const UpdateArticle = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/article/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const DeleteTransaction = () => {
  return useMutation((id) => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/admin/article/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: id
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}