import Clickable from 'utils/Clickable';
import { Box, Rating } from '@mui/material';
import React, {lazy} from 'react';
import Loadable from 'ui-component/Loadable';


const DealView = ({ dealDetailData,dealsModule }) => {
    // console.log(dealDetailData,"D")
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    return (
        (dealsModule != undefined  && dealsModule?.view == true) ? 
        <>
            <div className='custom-card card mb-3'>
                <div className='card-header'>
                 Deal Information
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Deal ID</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.random_deal_id}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Syndicates</div>
                                {/* <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.syndicates?.syndicate_name}</div> */}
                                <div className="v-value font-13 fw-600 ps-2">
                                
                                  {dealDetailData?.syndicates.length > 0 && dealDetailData?.syndicates?.map((syndicate, index) => (
                                    <React.Fragment key={index}>
                                      {syndicate?.syndicate_name}
                                     {index !== dealDetailData.syndicates.length - 1 && ", "}
                                    </React.Fragment>
                                   ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Scheme Name</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.scheme_name}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Startup</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.startups?.company_name}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Syndicate Partner</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.deal_partner_name}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Deal Flow</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.deal_flow_status}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Round Name</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.round_name}</div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-6 mt-2 mb-1">
                                                <div className='d-flex align-items-center'>
                                                    <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Deal Status</div>
                                                    <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.deal_status_view}</div>
                                                </div>
                                            </div> */}
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Bank Account</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.bank_accounts?.bank_name}</div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Scheme Letter</div>
                                <div className="v-value font-13 fw-600 ps-2">
                                    <Clickable onClick={() => window.open(dealDetailData.scheme_letter[0], 'blank')} style={{ textDecoration: 'none' }}>
                                        <span className="pdficon"></span>
                                    </Clickable>

                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-4 col-6 mt-2 mb-1">
                           <div className='d-flex align-items-center'>
                              <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Scheme Letter</div>
                              <div className="v-value font-13 fw-600 ps-2">
                                {dealDetailData.scheme_letter && dealDetailData.scheme_letter.length > 0 ? (
                                  <Clickable onClick={() => window.open(dealDetailData.scheme_letter[0], 'blank')} style={{ textDecoration: 'none' }}>
                                    <span className="pdficon"></span>
                                  </Clickable>
                                ) : (
                                  <span className="text-danger">Not available</span>
                                )}
                              </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Min Investment Amount(INR)</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.min_investment_amount}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Max Investment Amount(INR)</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.max_investment_amount}</div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Internal Notes</div>
                                <div className="v-value font-13 fw-600 ps-2 pre-wrap">{dealDetailData?.fund_checklist}</div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-block align-items-center'>
                                <div className="v-label text-gray font-13 fw-600">Funding Checklist</div>
                                <div className="v-value font-13 fw-600 font-12">{dealDetailData?.fund_checklist}</div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Completed At</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.deal_completed_date_format}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Created At</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.created_date}</div>
                            </div>
                        </div> */}
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Show in App</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.show_in_app}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Meeting Scheduled URL </div>
                                {dealDetailData.meetings[0]?.url ? (
                                    <a className="v-value font-13 fw-600 ps-2" href={dealDetailData?.meetings[0]?.url} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                            Click here
                                    </a>
                                ) : (
                                    <div className="v-value font-13 fw-600 ps-2"><span className="text-danger">Not available</span></div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Pitch Recording</div>
                                {dealDetailData.meetings[0]?.pitch_recording ? (
                                    <a className="v-value font-13 fw-600 ps-2" href={dealDetailData?.meetings[0]?.pitch_recording} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                      Play Video
                                   </a>
                                ) : (
                                    <div className="v-value font-13 fw-600 ps-2"><span className="text-danger">Not available</span></div>
                            )}
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Fund Byte Amount</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.fund_bite_amount}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">SHA Signing Date</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.sha_signing_date_format}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Syndicate Fees(INR)</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.syndicate_fees}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Type of Security</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.nature_investment?.nature_investment_name}</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Price</div>
                                <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.nature_investment?.price}</div>
                            </div>
                        </div>
                        <div className="col-12 mt-2 mb-1">
                            <div className='d-block align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Internal Notes</div>
                                <div className="v-value font-13 fw-600 pre-wrap">{dealDetailData?.fund_checklist}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6'>
                    <div className='custom-card card mb-3'>
                        <div className='card-header'>
                            Startup Showcase
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                              
                                <div className="col-12 mt-2 mb-1">
                                    <div className='d-flex align-items-center'>
                                        <div className="v-label text-gray w-40 mb-0 font-13 fw-600">URL</div>
                                      
                                        <a className="v-value font-13 fw-600 ps-2" href={dealDetailData?.meetings[0]?.url} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                            Click here
                                        </a>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 mb-1">
                                    <div className='d-flex align-items-center'>
                                        <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Pitch Recording</div>
                                    
                                        <a className="v-value font-13 fw-600 ps-2" href={dealDetailData?.meetings[0]?.pitch_recording} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                            Play Video
                                        </a>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='row'>

                <div className='col-md-6'>
                    <div className='custom-card card mb-3'>
                        <div className='card-header'>
                            Deal Details
                        </div>
                        <div className='card-body'>

                            <div className='row'>
                                {dealDetailData?.deal_details?.map((item, index) => (
                                    <>
                                        <div className="col-md-6 col-6 mt-2 mb-1">
                                            <div className='d-flex align-items-center'>
                                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">{item.key}</div>
                                                <div className="v-value font-13 fw-600 ps-2">{item.value}</div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='custom-card card mb-3'>
                        <div className='card-header'>
                            Fund Manager Analysis
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                {dealDetailData?.real_time_analysis?.map((item, index) => (
                                    <>
                                        <div className="col-md-6 col-6 mt-1 mb-1">
                                            <div className='d-flex align-items-center'>
                                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">{item.name}</div>
                                                <div className="v-value font-13 fw-600 ps-2">
                                                    <Rating
                                                        name="RealTimeAnalysis"
                                                        value={item.rating}
                                                        onChange={(event, newValue) => {
                                                            setAnalysis((oldAnalysis) => {
                                                                const newAnalysis = oldAnalysis.map((obj) => {
                                                                    if (obj.value == stage.value) {
                                                                        return { ...obj, rating: newValue }
                                                                    }
                                                                    return obj
                                                                })
                                                                return newAnalysis
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        :    <Error404 />
    )
}
export default DealView;