import React, { useState, useEffect } from 'react';
import { TextField, Box, Pagination, Paper, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { IconFile, IconFolderPlus, IconGridDots, IconList, IconPlus } from '@tabler/icons';
import Modal from 'ui-component/Modal';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import NewFolderCreate from './NewFolderCreate';
import UploadDocumentBox from './UploadDocumentBox';
import UploadDocumentPope from './UploadDocumentPope';
import UploadDocumentHeader from './UploadDocumentHeader';
import NewFileUpload from './NewFileUpload';
import { FetchDocument, AddDocument, DownloadFile } from 'api';
import { useTheme } from '@mui/material/styles';
import Loading from 'views/Loading';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ElevatorSharp, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconArrowBack, IconArrowBackUp, IconArrowBadgeLeft } from '@tabler/icons';
import CustomField from 'ui-component/formik/CustomField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CustomButton from 'ui-component/CustomButton';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import IconButton from '@mui/material/IconButton';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoAddCircleSharp } from 'react-icons/io5';
const UploadMenuPopUp = ({ setOpenDocument, setDoc,formik }) => {
    const [search, setSearch] = useState('');
    const [stage, setStage] = useState('');
    const [status, setStatus] = useState('');
    const [searchStatus, setSearchStatus] = useState(false);
    const [page, setPage] = useState(1);
    const [reset, setreset] = useState(false);
    const theme = useTheme();
    const [sort, setSort] = useState(-1);
    const [limit, setLimit] = useState(20);
    const [title, setTitle] = useState('Documents');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [parent_id, setParentID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [lastParent, setLastParent] = useState('');
    const [selectedDoc, setSelectedDoc] = useState([]);
    const {
        isLoading: isDocumentLoading,
        isRefetching: isDocumenteRefetching,
        data: documentData,
        refetch: refetchListing
    } = FetchDocument({ page, search: search.trim(), sort, limit, parent_id });
    function handleSearch() {
        if (documentData && documentData.documents && searchQuery !== null && searchQuery !== undefined) {
            const filteredData = documentData.documents.filter(
                (partner) =>
                    (partner.name && partner.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (partner.tagData && partner.tagData.toLowerCase().includes(searchQuery.toLowerCase()))
            );
            return filteredData;
        }
        return [];
    }
    const totalCount = documentData?.count || 0; // Total count of completed deals
    const startCount = (page - 1) * limit + 1; // Starting count of completed deals on the current page
    const endCount = Math.min(page * limit, totalCount);
    const {
        mutateAsync: downloadFileData,
        refetch: refetchDocuments,
        isLoading: isDocumentssLoading,
        isRefetching: isDocoumetsRefetching
    } = DownloadFile();

    useEffect(() => {
        refetchListing();
    }, [parent_id]);

    const [open2, setOpen2] = useState(false);
    const openFolderCreateModal = () => {
        setOpen2(true);
        setAnchorEl(null);
    };
    const [listViewType, setListViewType] = useState(false);
    const gridView = () => {
        setListViewType(true);
    };
    const listView = () => {
        setListViewType(false);
    };

    // File Upload modal
    const [open3, setOpen3] = useState(false);
    const openFileUploadModal = () => {
        setOpen3(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen2(false);
        setOpen3(false);
        if (searchStatus && search !== '') {
            setParentID('');
        }
        refetchListing();
    };

    const handleItemClick = (item) => {
        // console.log('handleItemClick', item);
        setParentID(item._id);
        setSearch('');
        setStage('');
        // setStatus('')
        setreset(!reset);
    };

    useEffect(() => {
        // console.log('handleFileClick', selectedDoc);
    }, [selectedDoc]);

    const handleFileClick = (item) => {
        const isItemAlreadySelected = selectedDoc.some((doc) => doc._id === item._id);

        if (isItemAlreadySelected) {
            setSelectedDoc(selectedDoc.filter((doc) => doc._id !== item._id));
        } else {
            setSelectedDoc([...selectedDoc, item]);
        }
    };

    const handlePreviousClick = (item) => {
        if (item.parent_id != undefined) {
            setParentID(item._id);
        } else {
            setParentID(item._id);
        }
    };

    const handleLastClick = () => {
        setParentID('');
    };

    const handleAddInput = () => {
        const inputs = [...formik.values.file_url, ''];
        formik.setFieldValue('file_url', inputs);
    };

    const handleRemoveInput = (index) => {
        const inputs = [...formik.values.file_url];
        inputs.splice(index, 1);
        formik.setFieldValue('file_url', inputs);
    };


    if (isDocumentLoading) {
        return (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loading />
            </div>
        );
    }

    const CustomTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px',
                    borderRightWidth: '0px'
                }
            }
        }
    })(TextField);


    return (
        <>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
                style={{ overflow: 'auto' }}
            >
                <div class="nav nav-tabs custom-nav-tabs" id="nav-tab" role="tablist">
                <button
                        class="nav-link active"
                        id="nav-alotdocument-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-alotdocument"
                        type="button"
                        role="tab"
                        aria-controls="nav-alotdocument"
                        aria-selected="false"
                    >
                        Attach Documents
                    </button>
                    <button
                        class="nav-link "
                        id="upload-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#upload"
                        type="button"
                        role="tab"
                        aria-controls="upload"
                        aria-selected="true"
                    >
                        Upload File From System
                    </button>
                    {/* <button
                        class="nav-link"
                        id="link-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#link"
                        type="button"
                        role="tab"
                        aria-controls="link"
                        aria-selected="false"
                    >
                        Add Links
                    </button> */}
                   
                </div>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane p-3 fade " id="upload" role="tabpanel" aria-labelledby="nav-add-tab-investor">
                        <div className='mnh-100vh d-flex align-items-center'>
                            <div className='w-100'>
                            <div className="d-flex">
                                <CustomTextField value={formik.values.document?.name} fullWidth disabled label="Upload File" />
                                {/* </Clickable> */}
                                <Button
                                    // ref={fileRef}
                                    variant="contained"
                                    component="label"
                                    disableElevation
                                    style={{ borderRadius: '0px 4px 4px 0px', height: '51px', whiteSpace: 'nowrap' }}
                                >
                                    Upload file 
                                    <input
                                        hidden
                                        accept="application/pdf, image/*"
                                        type="file"
                                        id="doc"
                                        multiple
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                formik.setFieldValue('document', Array.from(e.target.files));
                                            } else {
                                                formik.setFieldValue('document', null);
                                            }
                                        }}
                                    />
                                </Button>

                            
                            </div>
                            {formik.values?.document?.length > 0 ? formik.values?.document?.length + " File Selected" : ""}
                            <div className='mt-5 text-center'>
                            <CustomButton
                                size="medium"
                                sx={{ height: '40px', display: 'inline-flex', marginTop: '16px', marginRigth: '10px' }}
                                color={theme.palette.secondary.main}
                                onClick={() => {
                                    setOpenDocument(false);
                                }}
                                fullWidth
                            >
                                Insert
                            </CustomButton>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane mnh-100vh p-3 fade" id="link" role="tabpanel" aria-labelledby="nav-alotunit-tab">
                    {Array.isArray(formik.values.file_url) &&
                            formik.values.file_url.map((input, index) => (
                                <div key={index}>
                                    <div className="mb-3 d-flex">
                                        <CustomField name={`file_url[${index}]`} label="Add File URL" />
                                        {index > 0 && (
                                            <button
                                                className="button-79"
                                                type="button"
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    padding: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderRadius: '50%'
                                                }}
                                                onClick={() => handleRemoveInput(index)}
                                            >
                                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                                            </button>
                                        )}
                                        <button
                                            className="button-79"
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                padding: '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: '50%'
                                            }}
                                            onClick={handleAddInput}
                                        >
                                            <IoAddCircleSharp size={22} color={theme.palette.success.main} />
                                        </button>
                                    </div>
                                </div>
                            ))}

                            <CustomButton
                                size="medium"
                                sx={{ height: '40px', display: 'flex', marginTop: '16px', marginRigth: '10px' }}
                                color={theme.palette.secondary.main}
                                onClick={() => {
                                    setOpenDocument(false);
                                }}
                                fullWidth
                            >
                                Insert
                            </CustomButton>
                    </div>
                    <div class="tab-pane mnh-100vh p-3 fade show active" id="nav-alotdocument" role="tabpanel" aria-labelledby="nav-alotdocument-tab">
                        <div className="custom-menu-block box-shadow-none">
                        <div className="d-flex mt-3">
                            <div className="w-30">
                                <TextField
                                    label="Search"
                                    value={search}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            // alert(e.target.value);
                                            refetchListing();
                                            if (search) {
                                                setSearchStatus(true);
                                            }
                                        }
                                    }}
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                        setSearchStatus(false);
                                    }}
                                    size="small"
                                    sx={{
                                        '& input': {
                                            background: theme.palette.background.paper
                                            // background: '#f9f9fc8e'
                                        },
                                        '& input:valid + fieldset': {
                                            borderColor: theme.palette.secondary[200],
                                            borderWidth: 0.5
                                        },
                                        '& input:valid:hover + fieldset': {
                                            borderColor: '#4050b5',
                                            borderWidth: 1
                                        },
                                        '& input:valid:focus + fieldset': {
                                            borderColor: '#4050b5',
                                            padding: '4px !important',
                                            borderWidth: 1,
                                            borderLeftWidth: 6
                                        }
                                    }}
                                />
                            </div>
                            <div className="ms-3">
                                <CustomButton
                                    size="medium"
                                    sx={{ height: '40px' }}
                                    color={
                                        searchStatus && search !== ''
                                            ? [theme.palette.error.dark, theme.palette.error.main]
                                            : [theme.palette.secondary[800], theme.palette.secondary.main]
                                    }
                                    onClick={() => {
                                        if (searchStatus && search !== '') {
                                            setSearch('');
                                            setStage('');
                                            // setStatus('')
                                            setreset(!reset);
                                        } else {
                                            refetchListing();
                                            setParentID('');
                                        }
                                        setSearchStatus((oldVal) => !oldVal);
                                    }}
                                    fullWidth
                                >
                                    {searchStatus && search !== '' ? 'Reset' : 'Search'}
                                </CustomButton>
                            </div>
                            <div className="ml-auto">
                                <div className="view-type ms-3">
                                    {!listViewType ? (
                                        <button className="transparent-btn" type="button" onClick={gridView}>
                                            <IconGridDots />
                                        </button>
                                    ) : (
                                        <button className="transparent-btn" type="button" onClick={listView}>
                                            <IconList />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="cs-border"></div>
                        <div className="sm-title text-black cd-breadcrumb mt-3 ">
                            {documentData.parent_data.length > 0 && (
                                <button className="ud-link cursor-pointer" onClick={(e) => handleLastClick()}>
                                    <KeyboardArrowRight />
                                    Documents
                                </button>
                            )}
                            {documentData.parent_data?.map((item, pos) => {
                                return (
                                    <button className="ud-link" onClick={(e) => handlePreviousClick(item)}>
                                        <KeyboardArrowRight />
                                        {item.name}
                                    </button>
                                );
                            })}
                            {/* <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> Drive 1</Link>  */}

                            {/* <Link to="/upload-documents" className='ud-link dot-link'>...</Link> 
                            <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> Drive 4</Link> 
                            <span><KeyboardArrowRight/> Folder Name </span>  */}
                        </div>
                        {isDocumentLoading ? (
                            <Loading />
                        ) : (
                            <div className={`upload-document-list mt-3 ${listViewType ? 'grid-view' : 'list-view'}`}>
                                {!listViewType && (
                                    <div className="upload-document-row ud-header-row d-flex">
                                        <div className="col">
                                            Name
                                            <IconButton
                                                onClick={() => {
                                                    setSort((oldSort) => {
                                                        if (oldSort == 1) return -1;
                                                        else return 1;
                                                    });
                                                    setPage(1);
                                                }}
                                                style={{ position: 'relative', top: '-2px' }}
                                            >
                                                {sort == -1 && <SouthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                                {/* descending sorting */}
                                                {sort == 1 && <NorthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                                {/* assending sorting */}
                                                {/* {sort==0 && <NorthIcon style={{fontSize: '14px', color: '#999'}} />}  */} {/* No sorting */}
                                            </IconButton>
                                        </div>
                                        <div className="col">Owner</div>
                                        <div className="col">Tags</div>
                                        <div className="col">Type</div>
                                        <div className="col">Uploaded At</div>
                                    </div>
                                )}
                                {searchQuery.trim() === '' && documentData?.documents.length > 0 ? (
                                    documentData?.documents.map((item) => (
                                        <UploadDocumentPope
                                            listViewType={listViewType}
                                            item={item}
                                            handleItemClick={handleItemClick}
                                            handleFileClick={handleFileClick}
                                        />
                                    ))
                                ) : handleSearch().length > 0 ? (
                                    // Render search results when there is a search query and results are found
                                    handleSearch().map((result, index) => (
                                        <UploadDocumentPope
                                            listViewType={listViewType}
                                            item={result}
                                            handleItemClick={handleItemClick}
                                            handleFileClick={handleFileClick}
                                            key={index}
                                        />
                                    ))
                                ) : (
                                    <>
                                        <div className="w-100 text-center no-record-col">
                                            <div className="w-100 text-center no-record-box">
                                                <div className="no-search-results">No records found.</div>
                                                {/* <div className="mt-2 mb-5 text-center">
                                                    <button type="button" className="button-79 upload-btn" onClick={openFolderCreateModal}>
                                                        <IconFolderPlus /> &nbsp;&nbsp;New Folder
                                                    </button>
                                                    <button type="button" className="button-79 ms-2 upload-btn" onClick={openFileUploadModal}>
                                                        <IconFile /> &nbsp;&nbsp;File Upload
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        <div className="d-flex justify-content-between">
                            {/* ssss */}
                            <div>&nbsp;</div>
                            <Box
                                style={{
                                    display: 'flex',
                                    marginTop: '16px',
                                    marginLeft: '10px'
                                }}
                            >
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={limit}
                                    label="Row per page"
                                    size="small"
                                    onChange={(event) => {
                                        setLimit(event.target.value);
                                        setPage(1);
                                    }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>

                                <Pagination page={page} count={Math.ceil(documentData.count / limit)} onChange={(e, value) => setPage(value)} />
                            </Box>
                        </div>

                        <Typography>{`Showing ${startCount} to ${endCount} of ${totalCount} Deals`}</Typography>
                        </div>
                        <CustomButton
                                size="medium"
                                sx={{ height: '40px', display: 'flex', marginTop: '16px', marginRigth: '10px' }}
                                color={theme.palette.secondary.main}
                                onClick={() => {
                                    setDoc(selectedDoc);
                                    setOpenDocument(false);
                                }}
                                fullWidth
                            >
                                Attach & Insert
                            </CustomButton>
                    </div>
                </div>

             
            </Paper>
        </>
    );
};

export default UploadMenuPopUp;
