import { useState, lazy, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Rating } from '@mui/material';
import React from 'react';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
// import Transactions from './Transactions';
import StartupSettings from './StartupSettings';
// import UnitStatus from './UnitStatus';
// import ExampleFile from './ExampleFile';

import StartupTemplate from './StartupTemplate';
import SelectedStartupView from './SelectedStartupView';
import { FetchDealDetail, FetchTransactions, FetchTranches, FatchSelectedStartUp, GenerateEngagementLetterDocument } from '../../api';
import { useParams, useNavigate } from 'react-router';
import Loading from 'views/Loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { DescriptionOutlined, Download, Facebook, Instagram, Language, LinkedIn, Twitter, VideoCall, VideoCameraBack } from '@mui/icons-material';
import StartupDocumentPopup from './StartupDocumentPopup';
import Modal from 'ui-component/Modal';
import { toast, ToastContainer } from 'react-toastify';
import Clickable from 'utils/Clickable';
import { Tooltip, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { AiFillHome, AiFillCaretRight } from 'react-icons/ai';
import ModeIcon from '@mui/icons-material/Mode';
import Loadable from 'ui-component/Loadable';
import Cookies from 'js-cookie';
import ViewMISReport from './ViewMISReport';
import DynamicReport from 'views/master/reports/DynamicReport';
import Reports from './excel_report/Reports';
// import DealDocument from './DealDocument';

export default function ViewStartup({ view_type }) {
    const { id } = useParams();
    const isEditMode = !!id;
    const {
        isLoading: isDealDetailLoading,
        isRefetching: isDealDetailRefetching,
        data: dealDetailData,
        status: dealDetailStatus,
        refetch: refetchListing
    } = FatchSelectedStartUp(id);
    const { mutateAsync: generateEngagementDocuments, status: enagagementDocsStatus, error: enagagementDocsError, isLoading: enagagementDocsLoading, data: engagementData } = GenerateEngagementLetterDocument();
    const [dealsModule, setDealModule] = useState(null);
    const [isExporting, setIsExportLoading] = useState(false);
    const [startupModule, setStartupModule] = useState(null);
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    const theme = useTheme();
    const [documentOpen, setDocumentOpen] = useState(false);
    const handleDocumentOpen = () => {
        setDocumentOpen(true);
    };
    useEffect(() => {
        const permission_values = localStorage.getItem('permission');
        const permissions = permission_values != undefined ? JSON.parse(permission_values) : [];
        // console.log(permissions,"permissions")
        const module = permissions.find((module) => module.module_name === 'deals' && module.permisson === true);
        const startModule = permissions.find((module) => module.module_name === 'startup' && module.permisson === true);

        setDealModule(module); // Set the value in the state
        setStartupModule(startModule); // Set the value in the state
    }, []);
    const navigate = useNavigate();
    console.log(dealDetailData,"    dealDetailData?.checkListMaster    "        )

    const documentHandleClose = () => setDocumentOpen(false);

    const generateEngagementLetter = () => {
        setIsExportLoading(true)
        generateEngagementDocuments({ id }, {
            onSuccess: (succ) => {
                // console.log(engagementData?.data?.data[0]?.url,"engagementData")

                const pdfLink = document.createElement('a');
                const docxLink = document.createElement('a');
                pdfLink.href = succ?.data?.data[0]?.url;
                docxLink.href = succ?.data?.data[0]?.dcoxUrl;
                pdfLink.target = '_blank';
                docxLink.target = '_blank';
                pdfLink.setAttribute('download', 'filename.pdf'); // Specify the desired filename here
                docxLink.setAttribute('download', 'filename.docx'); // Specify the desired filename here
                pdfLink.click();
                docxLink.click();
                setIsExportLoading(false);
                refetchListing()
                setTimeout(() => {
                    toast.success(JSON.parse(succ.request.response).message, {
                        position: "top-left",
                        // autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }, 2000);
                })
            },
            onError: (err) => {
                setIsExportLoading(false);
                setTimeout(() => {
                    toast.error(JSON.parse(err.request.response).message, {
                        position: "top-left",
                        // autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }, 2000);
                })
            }
        })

    }
    return (
        <>
            <Modal open={documentOpen} onClose={documentHandleClose} closeIcon={true} style={{ width: '600px' }} disableScrollLock={true}>
                <StartupDocumentPopup data={dealDetailData?.startup_documents} />
            </Modal>
            <ToastContainer />
            {isDealDetailLoading ? (
                <Loading />
            ) : (
                <>
                    <BreadcrumbHeader title="View Startup" shouldGoBack />
                    {/* <div className="custom-card card mb-3">
                        <div className="card-body px-2 py-2 text-center">
                            
                        </div>
                    </div> */}
                    <div className="custom-menu-block view-investor-block">
                        <div className="deal-head-flex d-flex align-items-center mb-4">
                            <Clickable
                                className="circle-box"
                            // onClick={() => navigate(`/startups/editStartup/${dealDetailData?.startups._id}`)}
                            >
                                {dealDetailData?.image &&
                                    <img src={dealDetailData?.image} alt=""

                                    />}
                            </Clickable>
                            <div className="dhf-right">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Clickable
                                        className="xs-title font-16"
                                    // onClick={() => navigate(`/startups/editStartup/${dealDetailData?.startups._id}`)}
                                    >
                                        {dealDetailData?.company_name}
                                        {/* -{' '} */}
                                        {/* <span className="dhf-company-name">{dealDetailData?.company_name}</span> */}
                                    </Clickable>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            {/* <div
                                                className="v-value font-13 fw-600 me-3 deal-status-value"
                                                style={{
                                                    color: dealDetailData?.deal_status_view == 'In Process' ? '#d4a72d' : '#60cb5e',
                                                    backgroundColor:
                                                        dealDetailData?.deal_status_view === 'In Process' ? '#fef8e8' : '#ebfaeb',
                                                    border: 'none',
                                                    minWidth: '100px',
                                                    fontSize: '12px',
                                                    borderRadius: '4px',
                                                    fontWeight: 700,
                                                    borderWidth: '0.5px',
                                                    borderStyle: 'solid',
                                                    borderColor: dealDetailData?.deal_status_view === 'In Process' ? '#d4a72d' : '#60cb5e'
                                                }}
                                            >
                                                {dealDetailData?.deal_status_view}
                                            </div> */}

                                            {dealDetailData?.linkedln_page_url &&
                                                <a href={dealDetailData?.linkedln_page_url} target="_blank" rel="noopener noreferrer"
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="LinkedIn">
                                                        <LinkedIn />
                                                    </Tooltip>
                                                </a>
                                            }
                                            {dealDetailData?.twitter_page_url &&
                                                <a href={dealDetailData?.twitter_page_url} target="_blank" rel="noopener noreferrer"
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Twitter">
                                                        <Twitter />
                                                    </Tooltip>
                                                </a>
                                            }
                                            {dealDetailData?.facebook_page_url &&
                                                <a href={dealDetailData?.facebook_page_url} target="_blank" rel="noopener noreferrer"
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Facebook">
                                                        <Facebook />
                                                    </Tooltip>
                                                </a>
                                            }
                                            {dealDetailData?.instagram_page_url &&
                                                <a href={dealDetailData?.instagram_page_url} target="_blank" rel="noopener noreferrer"
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Instagram">
                                                        <Instagram />
                                                    </Tooltip>
                                                </a>
                                            }
                                            {dealDetailData?.videos.length > 0 ?
                                                dealDetailData?.videos?.map((videos, index) => (
                                                    <a href={videos?.url} target="_blank" rel="noopener noreferrer"
                                                        className="text-decoration-none icon text-center me-3"
                                                    >
                                                        <Tooltip title={videos?.heading}>
                                                            <VideoCameraBack />
                                                        </Tooltip>
                                                    </a>
                                                )
                                                ) : ""}
                                            <Clickable
                                                onClick={() => window.open(dealDetailData?.website_url, 'blank')}
                                                className="text-decoration-none icon text-center me-3"
                                            >
                                                <Tooltip title="Website">
                                                    <Language />
                                                </Tooltip>
                                            </Clickable>
                                            {Cookies?.get("is_super_admin") == true || Cookies?.get("is_super_admin") == "true" && <Clickable
                                                onClick={() => { generateEngagementLetter() }}
                                                className="text-decoration-none icon text-center me-3"
                                            >
                                                <Tooltip title="Generate Engagement Letter">
                                                    {isExporting ? <CircularProgress sx={{ color: "inherit", marginInline: 'auto' }} size={'1.4rem'} /> : <Download />}
                                                </Tooltip>
                                            </Clickable>}
                                            {dealDetailData?.startup_documents && dealDetailData?.startup_documents.length > 0 && (
                                                <Tooltip title="Documents">
                                                    <button
                                                        type="button"
                                                        className="text-decoration-none  icon text-center me-3"
                                                        onClick={handleDocumentOpen}
                                                    >
                                                        <DescriptionOutlined />
                                                    </button>
                                                </Tooltip>
                                            )}
                                            {/* {dealsModule?.edit && ( */}
                                            <Tooltip title="Edit">
                                                <div className="cursor-pointer">
                                                    <ModeIcon sx={{ color: '#777' }} onClick={() => navigate(`/startups/editStartup/${dealDetailData?._id}`)} />
                                                </div>
                                            </Tooltip>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ul className="nav nav-tabs custom-nav-tabs dh-tabs" id="myTab" role="tablist">
                                        {/* {dealsModule?.view && ( */}
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={
                                                    view_type != 'setting' && view_type != 'misreport'
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                                onClick={() => navigate(`/startups/view-startup/${id}`)}
                                                id="DealDetails-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#DealDetails"
                                                type="button"
                                                role="tab"
                                                aria-controls="DealDetails"
                                                aria-selected="false"
                                            >
                                                Startup Details
                                            </button>
                                        </li>
                                        {/* )} */}

                                        {dealDetailData?.checkListMaster && dealDetailData?.checkListMaster.length > 0 ? (
                                            dealDetailData?.checkListMaster.map((template, index) => (
                                                <>
                                                    {/* {dealsModule?.deal_checklist && ( */}
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            id={'TemplateA-tab' + index}
                                                            data-bs-toggle="tab"
                                                            data-bs-target={'#TemplateA' + index}
                                                            type="button"
                                                            role="tab"
                                                            aria-controls={'TemplateA' + index}
                                                            aria-selected="false"
                                                        >
                                                            {template.template_name}
                                                        </button>
                                                    </li>
                                                    {/* )} */}
                                                </>
                                            ))
                                        ) : (
                                            <></>
                                        )}

                                        {/* {startupModule?.master_report === true && dealDetailData?.reportMaster && dealDetailData?.reportMaster.length > 0 ? (
                                            dealDetailData?.reportMaster.map((report, index) => (
                                                <>
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className="nav-link"
                                                                id={'ReportA-tab' + index}
                                                                data-bs-toggle="tab"
                                                                data-bs-target={'#ReportA' + index}
                                                                type="button"
                                                                role="tab"
                                                                aria-controls={'ReportA' + index}
                                                                aria-selected="false"
                                                            >
                                                                {report.report_name}
                                                            </button>
                                                        </li>
                                                    
                                                </>
                                            ))
                                        ) : (
                                            <></>
                                        )} */}

                                        {/* {dealsModule?.deal_investors && (
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={view_type == 'transaction' ? 'nav-link active' : 'nav-link'}
                                                    id="Transactions-tab"
                                                    onClick={() => navigate(`/deals/transactions/${id}`)}
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#Transactions"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="Transactions"
                                                    aria-selected="true"
                                                >
                                                    Investors
                                                </button>
                                            </li>
                                        )}
                                        {dealsModule?.deal_nav && (
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="Setting-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#Unit"
                                                    type="button"
                                                    role="tab"
                                                    onClick={() => navigate(`/deals/investments/${id}`)}
                                                    aria-controls="Unit"
                                                    aria-selected="true"
                                                >
                                                    Investments
                                                </button>
                                            </li>
                                        )} */}
                                        {startupModule?.settings && <li className="nav-item" role="presentation">
                                            <button
                                                className={view_type == 'setting' ? 'nav-link active' : 'nav-link'}
                                                id="Setting-tab"
                                                onClick={() => navigate(`/startups/startup-settings/${id}`)}
                                                data-bs-toggle="tab"
                                                data-bs-target="#Setting"
                                                type="button"
                                                role="tab"
                                                aria-controls="Setting"
                                                aria-selected="true"
                                            >
                                                Settings
                                            </button>
                                        </li>}
                                        {startupModule?.mis_report === true && dealDetailData?.misDocuments != undefined && dealDetailData?.misDocuments?.length > 0 && 
                                    
                                         <li className="nav-item" role="presentation">
                                            <button
                                                className={view_type == 'misreport' ? 'nav-link active' : 'nav-link'}
                                                id="MISReport-tab"
                                                // onClick={() => navigate(`/startups/startup-misreport/${id}`)}
                                                data-bs-toggle="tab"
                                                data-bs-target="#MISReport"
                                                type="button"
                                                role="tab"
                                                aria-controls="MISReport"
                                                aria-selected="true"
                                            >
                                                Reports
                                            </button>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            <div
                                className={
                                    view_type != 'setting' && view_type != 'misreport'
                                        ? 'tab-pane fade show active'
                                        : 'tab-pane fade'
                                }
                                id="DealDetails"
                                role="tabpanel"
                                aria-labelledby="DealDetails-tab"
                            >
                                <SelectedStartupView dealDetailData={dealDetailData} />
                            </div>
                            {dealDetailData?.checkListMaster && dealDetailData?.checkListMaster.length > 0 ? (
                                dealDetailData?.checkListMaster.map((template, index) => (
                                    <>
                                        <div
                                            className="tab-pane fade"
                                            id={'TemplateA' + index}
                                            role="tabpanel"
                                            aria-labelledby={'TemplateA-tab' + index}
                                        >
                                            <StartupTemplate
                                                tamplate_data={template}
                                                refetchListing={refetchListing}
                                                dealId={id}
                                            // dealsModule={dealsModule}
                                            />
                                        </div>
                                    </>
                                ))
                            ) : (
                                <></>
                            )}

                            {/* {startupModule?.master_report === true && dealDetailData?.reportMaster && dealDetailData?.reportMaster.length > 0 ? (
                                dealDetailData?.reportMaster.map((report, index) => {
                                    return(
                                    <>
                                        <div
                                            className="tab-pane fade"
                                            id={'ReportA' + index}
                                            role="tabpanel"
                                            aria-labelledby={'ReportA-tab' + index}
                                        >
                                            <DynamicReport report={report}/>
                                        </div>
                                    </>
                                
                                )})
                            ) : (
                                <></>
                            )} */}
                            {/* <div
                                className={view_type == 'transaction' ? ' tab-pane fade show active' : 'tab-pane fade '}
                                id="Transactions"
                                role="tabpanel"
                                aria-labelledby="Transactions-tab"
                            >
                                <Transactions
                                    setHeader={true}
                                    dealsModule={dealsModule}
                                    transactionsInvestorsData={transactionsInvestorsData}
                                    refetchTransactions={refetchTransactions}
                                    isTransactionsLoading={isTransactionsLoading}
                                    refetchTranches={refetchTranches}
                                    fees={dealDetailData.fees_management}
                                />
                            </div>
                            <div
                                className={view_type == 'investments' ? ' tab-pane fade show active' : 'tab-pane fade '}
                                id="Unit"
                                role="tabpanel"
                                aria-labelledby="Ubit-tab"
                            >
                                <ExampleFile
                                    deal_completed_date={dealDetailData?.deal_flow_date_format?.deal_completed_date_format}
                                    deal_status={dealDetailData?.deal_status}
                                    deal_current_value={dealDetailData?.current_unit_value}
                                    refetchListing={refetchListing}
                                    dealsModule={dealsModule}
                                    isTranchesLoading={isTranchesLoading}
                                    tranchesInvestorsData={tranchesInvestorsData}
                                    refetchTranches={refetchTranches}
                                    TranchesStatus={TranchesStatus}
                                    refetchTransactions={refetchTransactions}
                                />
                            </div> */}
                            {startupModule?.settings && <div
                                className={view_type == 'setting' ? ' tab-pane fade show active' : 'tab-pane fade '}
                                id="Setting"
                                role="tabpanel"
                                aria-labelledby="Setting-tab"
                            >
                                <StartupSettings permissionReport={startupModule?.master_report} checklistData={dealDetailData?.checkListMaster} reportMasterData={dealDetailData?.excelReportMaster} />
                            </div>}
                            {startupModule?.mis_report === true && dealDetailData?.misDocuments != undefined && dealDetailData?.misDocuments?.length > 0 &&
                    
                             <div
                                className={view_type == 'misreport' ? 'tab-pane fade show active' : 'tab-pane fade'}
                                id="MISReport"
                                role="tabpanel"
                                aria-labelledby="MISReport-tab"
                            >
                                <Reports misData={dealDetailData?.misDocuments != undefined && dealDetailData?.misDocuments?.length > 0 ? dealDetailData?.misDocuments : []}/>
                            </div>}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
