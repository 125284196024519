import React,{useState,lazy,useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Tab, Tabs} from '@mui/material';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { useTheme } from '@mui/material/styles';
import VpFees from './VpFees';
import TargetFees from './TargetFees';
// import CancelledDeals from './CancelledDeals'

import Card from 'ui-component/cards/Card';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function FeesManagement() {
  const [value, setValue] = React.useState(parseInt(Cookies.get('fees-index'))||1);
  const [title, setTitle] = useState('')
  const navigate = useNavigate()
  const theme = useTheme();
  const [dealsModule, setDealModule] = useState(null); // Define the state
  const handleChange = (event, newValue) => {
    setValue(newValue);
    Cookies.set('fees-index', newValue, { path: '/'})
  };
  // const { permissions } = usePermissions();
//   const Error404 = Loadable(lazy(() => import('views/error/Error404')));

//   useEffect(() => {
//     const permission_values = Cookies.get("permission");
//     const permissions = permission_values != undefined ?  JSON.parse(permission_values) : [];
//     const module = permissions.find(
//       (module) => module.module_name === 'deals' && module.permisson === true
//     );

//     setDealModule(module); // Set the value in the state
//   }, []);


  return (
//   (dealsModule != undefined  && dealsModule.permisson == true) ? 
    <Box>
      {/* <Box sx={{ width: '100%' }}>
        <BreadcrumbHeader title={title} />
      </Box> */}
      {/* <Card sx={{backgroundColor: theme.palette.background.paper, boxShadow: 3}}> */}
      
      <div className='custom-card card mb-3'>
        <div className='card-body'>
          <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Tabs value={value} onChange={handleChange}   textColor="secondary" indicatorColor="secondary" style={{flex: 1}}  variant="scrollable"
               allowScrollButtonsMobile className='custom-deal-tabs'>
              <Tab label="VP Fees" {...a11yProps(0)} />
              {/* <Tab label="Live Deals" {...a11yProps(1)} /> */}
              <Tab
                label={" Partner Target Fees"}
                {...a11yProps(1)}/>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} sx={{ padding:'0', paddingTop: '0px', paddingBottom: '0px'}}>
            <VpFees  />
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ padding:'0', paddingTop: '0px', paddingBottom: '0px'}}>
            <TargetFees/>
          </TabPanel>
        </div>
      </div>
      </Box>
    // :    <Error404 />

  );
}