import { useTheme } from '@emotion/react';
import { Download, Mail, MarkEmailRead, Markunread, MarkunreadOutlined, PublishedWithChanges, PublishedWithChangesOutlined, RemoveRedEye } from '@mui/icons-material';
import { Box, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import { FetchAllInvoices, SendInvoiceAttachedEmail } from 'api';
import React from 'react'
import { useNavigate } from 'react-router';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader'
import CustomButton from 'ui-component/CustomButton';
import Clickable from 'utils/Clickable';
import Loading from 'views/Loading';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { ToastContainer, toast } from 'react-toastify';
import { IconMailFast } from '@tabler/icons'
import Cookies from 'js-cookie';
import ModalComponent from 'ui-component/Modal';
import SendPartnerEmailPopup from '../popups/SendPartnerEmailPopup';
import SelectField from 'ui-component/SelectField';
import InvoiceStatusUpdatePopup from './InvoiceStatusUpdatePopup';

const columns = [
    {
        label: 'Scheme Name',
        minWidth: 170,
        align: 'left',
        type: `scheme_name`,
        fixed: true,
        fixed_row_index: 0,
        fixed_column_index: 1,
    },
    {
        label: 'Startup Name',
        minWidth: 170,
        align: 'left',
        type: 'startup_name',
    },
    {
        label: 'Invoice Number',
        minWidth: 150,
        align: 'left',
        type: 'invoice_no',
    },
    {
        label: 'Billing To',
        minWidth: 100,
        align: 'left',
        type: 'billing_to',
    },
    {
        label: 'Status',
        minWidth: 200,
        align: 'center',
        type: 'startup_status',
    },
    {
        label: 'Created by',
        minWidth: 140,
        align: 'center',
        type: 'created_by'
    },
    {
        label: 'Invoice Date',
        minWidth: 140,
        align: 'center',
        type: 'invoice_date'
    },
    {
        label: 'Created At',
        minWidth: 140,
        align: 'center',
        type: 'invoice_date'
    },
    {
        label: 'Actions',
        minWidth: 140,
        align: 'center',
        type: 'actions'
    },
]

const AllInvoices = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [search, setSearch] = React.useState('')
    // const [type, setType] = React.useState('')
    const [searchStatus, setSearchStatus] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [billingTo, setBillingTo] = React.useState('');
    const [reset, setreset] = React.useState(false)
    const [sort, setSort] = React.useState(-1);
    const [limit, setLimit] = React.useState(20);
    const [openEmailModal, setOpenEmailModal] = React.useState(false)
    const [openStatusModal, setOpenStatusModal] = React.useState(false)
    const [currentRow, setCurrentRow] = React.useState(null)
    const {
        refetch: refetchListing,
        isLoading: isAllInvoicesLoading,
        isRefetching: isAllInvoicesRefetching,
        status: allInvoicesStatus,
        data: allInvoicesData
    } = FetchAllInvoices({ page, sort, limit, search: search.trim(), billing_to: billingTo });

    const { mutateAsync: sendInvoiceEmail, isLoading: isLoadingSendInvoiceEmail, status: sendInvoiceEmailStatus, error: sendInvoiceEmailError } = SendInvoiceAttachedEmail();

    const downloadInvoice = ({ row }) => {
        setTimeout(() => {
            if (row?.pdfURL) {
                const link = document.createElement('a');
                link.href = row?.pdfURL;
                link.target = '_blank';
                link.setAttribute('download', 'filename.pdf'); // Specify the desired filename here
                link.click();
            } else {
                console.error('Data is missing in the response:', row);
            }
            // setIsExportLoading(false);
        }, 1000);
    }

    const handleChangeBilling = (event) => {
        setBillingTo(event.target.value);
        // setIsDocumentation(false);
        // setInvoice(null)
    };

    const handleToggleEmailModal = ({ row }) => {
        if (openEmailModal) {
            setOpenEmailModal(false);
            setCurrentRow(null);
        }
        else {
            setOpenEmailModal(true);
            setCurrentRow(row);
        }
    }

    const handleUpdateModalToggle = ({ row }) => {
        if (openStatusModal) {
            setOpenStatusModal(false);
            setCurrentRow(null);
        }
        else {
            setOpenStatusModal(true);
            setCurrentRow(row);
        }
        // console.log(row,"row")
    }

    const sendEmailHandle = (activeRow, isTestEmail, test_emails) => {
        // console.log(activeRow, "row")
        sendInvoiceEmail({
            "invoice_id": activeRow?._id,
            "is_partner_email": true,
            "is_email_sent": isTestEmail,
            "test_emails": test_emails
        }, {
            onSuccess: (succ) => {
                setOpenEmailModal(false);
                setCurrentRow(null);
                toast.success(
                    JSON.parse(succ.request.response).message,
                    {
                        position: 'top-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    },
                    5000
                );
            },
            onError: (err) => {
                setTimeout(() => {
                    toast.error(
                        JSON.parse(err.request.response).message,
                        {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        },
                        5000
                    );
                });
            }
        })
    }
    // console.log(allInvoicesData, "allInvoicesData")

    const StartupActions = ({ row }) => {
        // const { mutateAsync: updateStartupStatus } = UpdateStartupStatus(row._id);
        return (
            <div style={{ display: 'flex', justifyContent: 'center', columnGap: '12px' }}>
                <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                    downloadInvoice({ row });
                }}>
                    <Tooltip title="Download">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Download stroke={1.5} size="1.4rem" />
                        </div>
                    </Tooltip>
                </Clickable>
                <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                    handleUpdateModalToggle({ row });
                }}>
                    <Tooltip title="Update Status">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PublishedWithChangesOutlined stroke={1.5} size="1.4rem" />
                        </div>
                    </Tooltip>
                </Clickable>
                {/* {startupModule?.edit && <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate(`/startups/editStartup/${row._id}`)}><Tooltip title="Edit"><div style={{ display: 'flex', alignItems: 'center' }}><IconEdit stroke={1.5} size="1.4rem" /></div></Tooltip></Clickable>} */}
                <Clickable
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                        handleToggleEmailModal({ row })
                    }}
                >
                    <Tooltip title="Send Invoice Email">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {currentRow?._id == row?._id && openEmailModal ? <MarkEmailRead stroke={1.5} size="1.4rem" /> : <MarkunreadOutlined stroke={1.5} size="1.4rem" />}
                        </div>
                    </Tooltip>
                </Clickable>
            </div>
        );
    }

    React.useEffect(() => {
        refetchListing()
    }, [reset])

    return (
        <Box>
            <ToastContainer />
            <ModalComponent style={{ width: '500px', maxWidth: '96%' }} open={openEmailModal} onClose={handleToggleEmailModal} closeIcon={true}>
                <SendPartnerEmailPopup title={"Send Invoice Email"} content={'Are you sure you want to send this invoice to partner?'} emailArr={true} activeRow={currentRow} handleConfirm={sendEmailHandle} handleClose={handleToggleEmailModal} sendTestEmail={true} loading={isLoadingSendInvoiceEmail} />
            </ModalComponent>
            <ModalComponent open={openStatusModal} onClose={handleUpdateModalToggle} closeIcon={true} style={{maxWidth: 700}}>
                <InvoiceStatusUpdatePopup
                    handleClose={handleUpdateModalToggle}
                    currentRow={currentRow}
                    refetchListing={refetchListing}
                />
            </ModalComponent>
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={`Invoices ${allInvoicesData != undefined && !isAllInvoicesLoading ? "(" + allInvoicesData?.count + ")" : ''}`} />
            </Box>
            {/* <Card sx={{backgroundColor: theme.palette.background.paper, boxShadow: 3}}> */}

            <div className='custom-card card mb-3'>
                <div className='card-body'>
                    <Grid container xs={12} mb={2}>
                        <Grid item sm={7} xs={8} display={'flex'} gap={1}>
                            <TextField
                                label="Search"
                                value={search}
                                autoComplete='off'
                                fullWidth
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        // alert(e.target.value);
                                        refetchListing()
                                        if (search) {
                                            setSearchStatus(true)
                                        }
                                    }
                                }}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                    setSearchStatus(false)
                                }}
                                size="small"
                                sx={{
                                    '& input': {
                                        background: theme.palette.background.paper,
                                        // background: '#f9f9fc8e'
                                    },
                                    '& input:valid + fieldset': {
                                        borderColor: theme.palette.secondary[200],
                                        borderWidth: 0.5,
                                    },
                                    '& input:valid:hover + fieldset': {
                                        borderColor: '#4050b5',
                                        borderWidth: 1,
                                    },
                                    '& input:valid:focus + fieldset': {
                                        borderColor: '#4050b5',
                                        padding: '4px !important',
                                        borderWidth: 1,
                                        borderLeftWidth: 6,
                                    },
                                }}
                            />
                            {/* <Grid item sm={3} xs={4}> */}
                            <FormControl
                                sx={{
                                    '&  > label#demo-simple-select-label': {
                                        top: billingTo == '' ? -5 : 0,
                                        "&.Mui-focused": {
                                            top: 0
                                        }
                                    },
                                    "& > div > div#demo-simple-select": {
                                        padding: billingTo == '' ? "10px 14px" : "7px 14px"
                                    }
                                }}
                                // className={`${classes.customFormControl}`} 
                                fullWidth>
                                <InputLabel id="demo-simple-select-label">Invoice Billed To</InputLabel>
                                <Select
                                    // multiple
                                    // className={`${classes.customSelectBox}`}
                                    value={billingTo}
                                    onChange={handleChangeBilling}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Invoice Billed To"
                                    defaultValue={''}
                                    size='small'
                                >
                                    <MenuItem key={'startup'} value={'startup'}
                                    >

                                        <ListItemText primary={"Startup"} />
                                    </MenuItem>
                                    <MenuItem key={'fund'} value={'fund'}
                                    >

                                        <ListItemText primary={"Fund"} />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {/* </Grid> */}
                            <CustomButton
                                size="medium"
                                sx={{ height: '40px' }}
                                color={(searchStatus && (search !== '' || billingTo !== '')) ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                                onClick={() => {
                                    if (searchStatus && (search !== '' || billingTo !== '')) {
                                        setSearch('')
                                        setBillingTo('')
                                        setreset(!reset)
                                    } else {
                                        refetchListing()
                                    }
                                    setSearchStatus((oldVal) => !oldVal)

                                }}
                                fullWidth
                            >
                                {(searchStatus && (search !== '' || billingTo !== '')) ? "Reset" : "Search"}
                            </CustomButton>
                        </Grid>
                        <Grid item marginLeft="auto">
                            <CustomButton onClick={() => navigate("/invoices/create-invoice")} >Create Invoice</CustomButton>
                        </Grid>
                    </Grid>
                    {isAllInvoicesLoading ? <Loading />
                        : <AsyncTableListing
                            title=""
                            // titleContainer={<CustomButton size="medium" onClick={()=>navigate(`/startups/addStartup`)} sx={{height:'50px'}}>Add startup</CustomButton>}
                            columns={columns}
                            data={allInvoicesData.data}
                            actions={StartupActions}
                            // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(startupsData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
                            page={page}
                            setPage={setPage}
                            count={allInvoicesData.count}
                            // limit={allInvoicesData.limit}
                            // cutomLink={InvestorLink}
                            sort={sort}
                            setSort={setSort}
                            limit={limit}
                            setLimit={setLimit}
                        />}
                </div>
            </div>
        </Box>
    )
}

export default AllInvoices