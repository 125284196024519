import { useTheme } from '@emotion/react'
import { ContentCopy, FileCopyRounded, RemoveRedEye } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Grid, Tab, TextField, Tooltip } from '@mui/material'
import { IconFileCheck } from '@tabler/icons'
import { FetchAllFeedbacksListing } from 'api/Startup'
import React from 'react'
import { useNavigate } from 'react-router'
import AsyncTableListing from 'ui-component/AsyncTableListing'
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader'
import CustomButton from 'ui-component/CustomButton'
import Modal from 'ui-component/Modal'
import Card from 'ui-component/cards/Card'
import Clickable from 'utils/Clickable'
import Loading from 'views/Loading'
import ViewFeedbackResponse from './ViewFeedbackResponse'
import { Link } from 'react-router-dom'

const columns = [
    {
        label: 'User Name',
        minWidth: 150,
        align: 'left',
        type: 'admin_partner_name',
        trim: 60,
        fixed: true,
        fixed_row_index: 0,
        fixed_column_index: 1,
    },
    {
        label: 'Startup Name',
        minWidth: 200,
        align: 'left',
        type: 'company_name',
        trim: 60,
        link: true,
    },
    // {
    //     label: 'Form Name',
    //     minWidth: 200,
    //     align: 'left',
    //     type: 'feedback_name',
    //     trim: 60,
    // },
    // {
    //     label: 'Status',
    //     minWidth: 200,
    //     align: 'left',
    //     type: 'status',
    //     trim: 60,
    // },
    {
        label: 'Created Date',
        minWidth: 150,
        align: 'left',
        type: 'created_date'
    },
    // {
    //     label: 'Submitted Date',
    //     minWidth: 150,
    //     align: 'left',
    //     type: 'status_updated_date'
    // },
   
    {
        label: 'Actions',
        minWidth: 160,
        align: 'right',
        type: 'actions'
    }
];


const CompletedColumns = [
    {
        label: 'User Name',
        minWidth: 150,
        align: 'left',
        type: 'admin_partner_name',
        trim: 60,
        fixed: true,
        fixed_row_index: 0,
        fixed_column_index: 1,
    },
    {
        label: 'Startup Name',
        minWidth: 200,
        align: 'left',
        type: 'company_name',
        trim: 60,
        link: true,
    },
    // {
    //     label: 'Form Name',
    //     minWidth: 200,
    //     align: 'left',
    //     type: 'feedback_name',
    //     trim: 60,
    // },
    // {
    //     label: 'Status',
    //     minWidth: 200,
    //     align: 'left',
    //     type: 'status',
    //     trim: 60,
    // },
    // {
    //     label: 'Created Date',
    //     minWidth: 150,
    //     align: 'left',
    //     type: 'created_date'
    // },
    {
        label: 'Submitted Date',
        minWidth: 150,
        align: 'left',
        type: 'status_updated_date'
    },
   
    {
        label: 'Actions',
        minWidth: 160,
        align: 'right',
        type: 'actions'
    }
];


const AllColumns = [
    {
        label: 'User Name',
        minWidth: 150,
        align: 'left',
        type: 'admin_partner_name',
        trim: 60,
        fixed: true,
        fixed_row_index: 0,
        fixed_column_index: 1,
    },
    {
        label: 'Startup Name',
        minWidth: 200,
        align: 'left',
        type: 'company_name',
        trim: 60,
        link: true,
    },
    // {
    //     label: 'Form Name',
    //     minWidth: 200,
    //     align: 'left',
    //     type: 'feedback_name',
    //     trim: 60,
    // },
    // {
    //     label: 'Status',
    //     minWidth: 200,
    //     align: 'left',
    //     type: 'status',
    //     trim: 60,
    // },
    {
        label: 'Created Date',
        minWidth: 150,
        align: 'left',
        type: 'created_date'
    },
    {
        label: 'Submitted Date',
        minWidth: 150,
        align: 'left',
        type: 'status_updated_date'
    },
   
    {
        label: 'Actions',
        minWidth: 160,
        align: 'right',
        type: 'actions'
    }
];


const AllFeedbacksListing = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchStatus, setSearchStatus] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState(-1);
    const [reset, setreset] = React.useState(false)
    const [status, setStatus] = React.useState("")
    const [CopyIcon, setCopyIcon] = React.useState(null);
    const { isLoading: isAllFeedbacksLoading, data: allFeedbacksData, isRefetching: isAllFeedbacksRefetching, status: allFeedbacksStatus, refetch: refetchListing } = FetchAllFeedbacksListing({ page, search: search.trim(), status, sort, limit });
    // console.log(allFeedbacksData, "allFeedbacksData")
    const handleChange = (event, newValue) => {
        setStatus(newValue);
    };

    const InvestorLink = ({ row, children }) => {
        return <Link to={`/investors/viewInvestor/${row._id}`} style={{ textDecoration: "none", color: '#616161', fontWeight: 700 }}>
            {children}
        </Link>
    }
    const StartupLink = ({ row, children, column }) => {
        const isInvestor = column?.type == "admin_partner_name"
        
        // console.log(column,isInvestor,"column")
        switch (isInvestor) {
            case true: return <span style={{ textDecoration: "none", color: '#616161', fontWeight: 700 }}>
                {children}
            </span>
            default: return <Clickable style={{ display: 'flex', alignItems: 'right' }} onClick={() => {
                row?.status != "Pending" ?  handleOpenViewPopup({ row }) : handleClick(row);
            }}> <Link to="#" style={{ textDecoration: "none", color: '#616161', fontWeight: 700 }}>
                {children}
            </Link></Clickable>
        }
    }


    const [openViewPopup, setOpenViewPopup] = React.useState(false);
    const [currentID, setCurrentID] = React.useState(null);
    const [currentRow, setCurrentRow] = React.useState(null);

    const handleCloseViewPopup = () => {
        setOpenViewPopup(false);
        setCurrentID(null)
        setCurrentRow(null)
    }
    const handleOpenViewPopup = ({ row }) => {
        setOpenViewPopup(true);
        setCurrentID(row?.partnerNotificationSentId)
        setCurrentRow(row)
    }

    const handleClick = (row) => {
        // Cookies.set("startup_feeback",true);
        // console.log(row.master_startup_id,row.feedback_master_id)
        navigate(`/feedbacks/${row.master_startup_id}?feedback=${row.feedback_master_id}`)
    };

    const feedbacksActions = ({ row }) => {
        return <div style={{ display: 'flex', justifyContent: 'right', columnGap: "12px" }}>
            {row?.status != "Pending" && <Clickable style={{ display: 'flex', alignItems: 'right' }} onClick={() => {
                handleOpenViewPopup({ row });
            }}>
                <Tooltip title="View">
                    <div style={{ display: 'flex', alignItems: 'right' }}>
                        <RemoveRedEye stroke={1.5} size="1.4rem" />
                    </div>
                </Tooltip>
            </Clickable>}
          {row?.status != "Completed"  &&   <Clickable style={{ display: 'flex', alignItems: 'right' }} onClick={() => {
                handleClick(row);
            }}>
                <Tooltip title="Submit Feedback">
                    <div style={{ display: 'flex', alignItems: 'right' }}>
                        <IconFileCheck stroke={1.5} size="1.4rem" />
                    </div>
                </Tooltip>
            </Clickable>}
            {/* {row.share_link && row.share_link != "" && <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => { setCopyIcon(row.partnerNotificationSentId); navigator.clipboard.writeText(row.share_link) }}>
                <Tooltip title="Copy Link">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {CopyIcon == row.partnerNotificationSentId ? <FileCopyRounded stroke={1.5} size="1.4rem" /> : <ContentCopy stroke={1.5} size="1.4rem" />}
                    </div>
                </Tooltip>
            </Clickable>} */}
        </div>
    }

    React.useEffect(() => {
        refetchListing();
    }, [reset]);
    const uniqueEmails = [...new Set(allFeedbacksData?.data?.map(item => item?.admin_email))];

    // Map unique emails to their corresponding feedback data
    const groupedFeedbacks = uniqueEmails.map(email => {
        return {
            admin_email: email,
            items: allFeedbacksData?.data?.filter(item => item?.admin_email === email)
        }
    }
    );
    // console.log(groupedFeedbacks, "groupedFeedbacks")
    return (
        <Box>
            <Modal onClose={handleCloseViewPopup} open={openViewPopup} style={{ maxWidth: 700, maxHeight: "32rem", overflow: 'hidden', borderRadius: 2 }} closeIcon={true}>
                <ViewFeedbackResponse partner_notification_sent_id={currentID} handleClose={handleCloseViewPopup} />
            </Modal>
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={`${status == "" ? "All" : status} Feedbacks ${allFeedbacksData != undefined && !isAllFeedbacksLoading ? "(" + allFeedbacksData?.count + ")" : ''}`} shouldGoBack={false} />
            </Box>
            <Card>
                <TabContext value={status}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList textColor='secondary' indicatorColor='secondary' onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Pending Feedbacks" value="Pending" />
                            <Tab label="Completed Feedbacks" value="Completed" />
                            <Tab label="All Feedbacks" value="" />
                        </TabList>
                    </Box>
                </TabContext>
                <Grid container direction="row" columnSpacing={1} rowSpacing={2} mt={0}>
                    <Grid item sm={4} xs={8}>
                        <TextField
                            label="Search"
                            value={search}
                            fullWidth
                            autoComplete='off'
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    refetchListing()
                                    if (search) {
                                        setSearchStatus(true)
                                    }
                                }
                            }}
                            onChange={(event) => {
                                setSearch(event.target.value)
                                setSearchStatus(false)
                            }}
                            size="small"
                            sx={{
                                '& input': {
                                    background: theme.palette.background.paper,
                                },
                                '& input:valid + fieldset': {
                                    borderColor: theme.palette.secondary[200],
                                    borderWidth: 0.5,
                                },
                                '& input:valid:hover + fieldset': {
                                    borderColor: '#4050b5',
                                    borderWidth: 1,
                                },
                                '& input:valid:focus + fieldset': {
                                    borderColor: '#4050b5',
                                    padding: '4px !important',
                                    borderWidth: 1,
                                    borderLeftWidth: 6,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} className='search-btn'>
                        <CustomButton
                            size="medium"
                            sx={{ height: '40px', textAlign: 'center', display: 'block' }}
                            color={(searchStatus && search !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                            onClick={() => {
                                if (searchStatus && search !== '') {
                                    setSearch('')
                                    setreset(!reset)
                                } else {
                                    refetchListing()
                                }
                                setSearchStatus((oldVal) => !oldVal)

                            }}
                            fullWidth
                        >
                            {(searchStatus && search !== '') ? "Reset" : "Search"}
                        </CustomButton>
                    </Grid>
                    {/* <Grid item sm={7} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <CustomButton size="medium" onClick={() => navigate(`/feedbacks`)} sx={{ height: '40px' }}>Submit Feedback</CustomButton>
                    </Grid> */}
                </Grid>

                <Box mt={1}>
                    {isAllFeedbacksLoading || isAllFeedbacksRefetching ? <Loading style={{ height: '400px' }} />
                        : <AsyncTableListing
                            title=""
                            columns={status == "" ? AllColumns : status == "Completed"  ? CompletedColumns : columns }
                            data={allFeedbacksData !== undefined && allFeedbacksData.data}
                            actions={feedbacksActions}
                            page={page}
                            setPage={setPage}
                            count={allFeedbacksData !== undefined && allFeedbacksData.count}
                            sort={sort}
                            setSort={setSort}
                            limit={limit}
                            setLimit={setLimit}
                            cutomLink={StartupLink}
                        />
                    }
                </Box>
            </Card>
        </Box>
    )
}

export default AllFeedbacksListing