import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchFeedback = ({page, search, sort=1, limit=20}) => {
  return useQuery(
    ['feedback-listing', page, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/feedback?page=${page}&searchText=${search}&sort=${sort}&limit=${limit}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
        },
      }) 
  },{
    select: (data)=>({investors: data.data.data, count: data.data.count, limit:data.data.limit}),
    refetchOnWindowFocus:false,
    refetchOnMount:true,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FeedbackDetail = ({id}) => {
  return useQuery(
    ['feedback-detail', id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-chat?feedback_id=${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data,
    refetchOnWindowFocus:false,
    refetchOnMount:true,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const SendFeedback = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-chat`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  })  
}

export const UpdateStatus = () => {
  return useMutation(({status, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/feedback/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: {
        status: status ? "OPEN" : "CLOSED"
      }
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  })  
}