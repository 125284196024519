// assets
import { IconPresentationAnalytics } from '@tabler/icons';

// constant
const icons = { IconPresentationAnalytics };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const portfolio = {
    id: 'portfolio1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'portfolio11',
            title: 'Portfolios',
            type: 'item',
            url: `/portfolio`,
            icon: icons.IconPresentationAnalytics,
            breadcrumbs: false
        }
    ]
};

export default portfolio;
