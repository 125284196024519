import React from 'react';
import { useField } from 'formik';
import { Chip, FormControl, InputLabel, Select, MenuItem, FormHelperText, ListItemText, Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';

const ChipMultiSelect = ({ name, label, options, ...rest }) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    const { value } = event.target;
    helpers.setValue(value);
  };

  const handleRemoveChip = (chipValue) => {
    const newValue = field.value.filter((value) => value !== chipValue);
    helpers.setValue(newValue);
  };

  return (
    <FormControl fullWidth error={meta.touched && meta.error}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        multiple
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        value={field.value}
        onChange={handleChange}
        inputProps={{ name, id: name }}
        renderValue={(selected) => (
          <div>
            {selected?.map((value) => (
              <Chip
                key={value}
                label={options.find((option) => option.value === value)?.label}
                // onDelete={() => handleRemoveChip(value)}
              />
            ))}
          </div>
        )}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {/* <Checkbox checked={field.value.includes(option.value)} /> */}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{meta.touched && meta.error?.length > 50 && 'This Field is Required'}</FormHelperText>
    </FormControl>
  );
};

export default ChipMultiSelect;

// import * as React from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';
// import Box from '@mui/material/Box';
// import Chip from '@mui/material/Chip';


// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const CustomSelect = withStyles((theme) => ({
//   root: {
//     '& .MuiSelect-select.MuiSelect-outlined': {
//       backgroundColor: "#fff",
//       borderRadius: '10px'
//     },
//     '& label.Mui-focused': {
//       color: theme.palette.secondary[200],
//       borderWidth: 1,
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: theme.palette.secondary[200],
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: theme.palette.secondary[200],
//         borderWidth: 0.5,
//       },
//       '&:hover fieldset': {
//         borderColor: theme.palette.secondary[800],
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: theme.palette.secondary[800],
//         borderLeftWidth: 6,
//         padding: '4px !important',
//         borderWidth: 1,
//       },
//       '&.MuiInputBase-root.MuiAutocomplete-inputRoot': {
//         background: '#ffffff',
//         color: '#000000'
//       }
//     },
//   },
//   input: {
//     background: '#ffffff',
//     '&::placeholder': {
//       // fontStyle: 'italic',
//       color: 'black',
//     },
//   },
// }))(Select);

// export default function MultipleSelect({
//   label,
//   name,
//   value: inputValue,
//   options = [],
//   placeholder = "Search",
//   ...props
// }) {
//   const { setFieldValue } = useFormikContext();
//   const [field, meta] = useField(name);

//   const [active, setActive] = useState(false)

//   const handleChange = (event, values) => {
//     const clicked = values[values.length - 1].value
//     if (inputValue.includes(clicked)) {
//       const newVal = inputValue.filter((val) => val != clicked)
//       setFieldValue(name, newVal);
//     } else {
//       setFieldValue(name, [...inputValue, clicked]);
//     }
//   };

//   const configSelect = {
//     ...field,
//     select: true,
//     variant: 'outlined',
//     fullWidth: true,
//     // onChange: handleChange
//   };
//   const properities = {}

//   if (meta && meta.touched && meta.error) {
//     properities.error = true;
//     properities.helperText = meta.error;
//   }

//   const renderTags = (value, getTagProps, ownerState) => {
//     // return inputValue.map((option, index) => (
//     //   <span key={option.value}>
//     //     {option.label}
//     //     {index < value.length - 1 ? ', ' : ''}
//     //   </span>
//     // ));
//     return ""
//   };

//   return (
//     <>
//       <CustomComplete
//         multiple
//         options={options}
//         getOptionLabel={(option) => (
//           <FormControlLabel
//             control={<Checkbox checked={inputValue.includes(option.value)} />}
//             label={option.label}
//           />
//         )}
//         isOptionEqualToValue={(option, value) => {
//           return option.value == value
//         }}
//         disableClearable
//         onChange={handleChange}
//         renderTags={renderTags}
//         renderInput={(params) => {
//           return <TextField
//             {...params}
//             variant="outlined"
//             label={active ? label : inputValue && inputValue.length ? inputValue.length : label}
//             placeholder={inputValue && inputValue.length ? inputValue.length : placeholder}
//             onBlur={() => setActive(false)}
//             onFocus={() => setActive(true)}
//           />
//         }}
//       />
//       <p style={{ margin: '3px 14px 0px 14px', fontSize: '0.75rem', color: '#f44336' }}>{configSelect?.helperText && `${label} is required`}</p>
//     </>
//   );
// }