import { TextField } from '@mui/material';
import { useField } from 'formik';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';

const ValidationTextField = styled(TextField)(({ theme }) => ({
    '& input': {
        background: theme.palette.background.paper
        // background: '#f9f9fc8e'
    },
    '& input:valid + fieldset': {
        borderColor: theme.palette.secondary[200],
        borderWidth: 0.5
    },
    '& input:valid:hover + fieldset': {
        borderColor: '#4050b5',
        borderWidth: 1
    },
    '& input:valid:focus + fieldset': {
        borderColor: '#4050b5',
        padding: '4px !important',
        borderWidth: 1,
        borderLeftWidth: 6
    }
}));
const TextfieldWrapper = ({ name, label, compulsory,focused, message = '' , ...otherProps }) => {

    // const [isFocused, setisFocusedprops] = useState(isFocusedprops);

    const [field, mata] = useField(name);
    const configTextfield = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        autoComplete: 'off',
    //   onFocus: () => setisFocusedprops(true),
    //     autoFocus: isFocused,
    };

    if (mata && mata.touched && mata.error) {
        // configTextfield.error = true;
        configTextfield.helperText = mata.error;
        configTextfield.helperText = <span style={{ color: '#ef5350' }}>{mata.error}</span>;
    }

    return (
        <>
            <ValidationTextField label={compulsory ? `${label}*` : label} focused={focused} {...configTextfield} />
            {message && message}
        </>
    );
};
TextfieldWrapper.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    compulsory: PropTypes.bool
};
export default TextfieldWrapper;
