import PropTypes from 'prop-types';


const Clickable = ({
  children, 
  style,
  onClick=()=>console.log("Clicked"),
  className=''
  }) => {

  const handleClick = () => { console.log("object"); }
  
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      handleClick();
    }
  }

  return (
    <div
      role={'button'}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      style={{...style, cursor: 'pointer'}}
      className={className}
    >
      {children}
    </div>
  )
}
Clickable.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
};
export default Clickable