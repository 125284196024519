import {useState, useRef} from 'react'
import { Paper, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form,FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from "react-icons/im";
import { ChangeKycStatus, FetchInvestors, FetchInvestorStatusLogs } from 'api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';

const validationSchema = yup.object({ 
    // min_investment_amount: yup
    // .string('Enter Min Investment Amount')
    // .required('Min Investment Amount is required')
    // .trim(),
    // max_investment_amount: yup
    // .string('Enter Max Investment Amount')
    // .required('Max Investment Amount is required')
    // .trim(),
    // closing_date: yup
    // .string('Enter Closing Date')
    // .required('Closing Date is required')
    // .trim(),
    // pitch_recording: yup
    // .string('Enter Pitch Recording')
    // .required('Pitch Recording is required')
    // .trim(),
    comment: yup
    .string('Comment is Recording')
    .required('Comment is required')
    .trim(),
});
const columns = [
  {
    label: 'Date', 
    minWidth: 0,
    align: 'left',
    type: 'date'
  },
  {
    label: 'Description', 
    minWidth: 0,
    align: 'left',
    type: 'description'
  },
  {
    label: 'KYC Status', 
    minWidth: 0,
    align: 'left',
    type: 'kyc_status',
    clickable: false
  },
  {
    label: 'Updated By', 
    minWidth: 0,
    align: 'left',
    type: 'updated_by'
  },
];
const ApproveKycPopup = ({ handleClose, KycOptions, investorId, setSearchStatus, setSearch, refetchListing}) => {
  const [logPage, setLogPage] = useState(1)
  const theme = useTheme();
  const { mutateAsync } = ChangeKycStatus()
  const { data:logs, isLoading:isLogsLoading } = FetchInvestorStatusLogs(investorId)

  const formik = useFormik({
    initialValues: {
      masterId: '',
      comment: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        master_activity_id : values.masterId,
        values:[
            {
                description: values.comment
            }
        ]
      }
      mutateAsync({payload , id: investorId}, {
        onSuccess:()=>{
          setSearch('')
          setSearchStatus(false)
          setTimeout(()=>{
            refetchListing()
          }, 200)
          handleClose()
          toast.success('KYC status Updated', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
          })
        },
          onError: (err)=>{
            toast.error(JSON.parse(err.request.response).message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            })
          }
      })
    },
  });
  // if(isLogsLoading){
  //   return <h1>Loading...</h1>
  // }
  return (
    <div>
      
      <Paper sx={{backgroundColor: theme.palette.background.paper, boxShadow: 3, padding:'10px', flex:1, overflowY: "auto", maxHeight: "90vh" }}>
          <FormikProvider value={formik}>
            <Form  onSubmit={formik.handleSubmit} style={{paddingTop:'10px', paddingBottom:'15px', margin:10}}>
            <h3 style={{textAlign:'center'}}>Change KYC Status</h3>
              <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12}>
                  <SelectField
                    name="masterId"
                    label="KYC Status"
                    options={KycOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextArea name="comment" label="Comment" multiline={true} minRows={4} sx={{borderWidth:2}} />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <CustomButton type="submit">
                  Submit
                </CustomButton>
              </Grid>
          </Form>
        </FormikProvider>
        {isLogsLoading ? <Loading />:<AsyncTableListing 
        // title="Logs" 
        columns = {columns}
        // actions={CallForMoneyActions}
        data={logs}
        // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(callForMoneyData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
        page={logPage}
        setPage={setLogPage}
        count={logs.length}
        limit={5}
        tableStyle={{minWidth: 200}}
        />}
      </Paper>
    </div>
  );
}

export default ApproveKycPopup;
