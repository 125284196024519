// assets
import { IconFileDollar, IconHomeDollar, IconReport, IconSettings } from '@tabler/icons';
import { IconCheckupList } from '@tabler/icons';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
// constant
const icons = { IconCheckupList, IconSettings};

// ==============================|| MASTER MENU ITEMS ||============================== //

const master = {
    id: 'master1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'master11',
            title: 'Settings',
            type: 'collapse',
            icon: icons.IconSettings,
            breadcrumbs: false,
            children: 
            [
                // {
                //     id: 'master111',
                //     title: 'Lists',
                //     type: 'item',
                //     url: `/master`,
                //     icon: icons.IconSettings,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'master112',
                //     title: 'Checklists',
                //     type: 'item',
                //     url: `/checklist`,
                //     icon: icons.IconCheckupList,
                //     breadcrumbs: false
                // }
                // ,
                // {
                //     id: 'fees11',
                //     title: 'Fees',
                //     type: 'item',
                //     url: `/fees`,
                //     icon: IconFileDollar,
                //     breadcrumbs: false
                // }
                // ,{
                //     id: 'master113',
                //     title: 'Reports',
                //     type: 'item',
                //     url: `/reports`,
                //     icon: IconReport,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'master113',
                //     title: 'Excel Reports',
                //     type: 'item',
                //     url: `/excel_reports`,
                //     icon: IconReport,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default master;
