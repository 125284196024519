import { useTheme } from '@emotion/react';
import { Cancel, Check, Close, Delete, Edit, Refresh, Save } from '@mui/icons-material';
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Tooltip,
    Box,
    TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { useFormik, Form, FieldArray, FormikProvider, Field, FastField } from 'formik';
import React from 'react';
import { useState, useRef, useEffect } from 'react';
import CustomButton from 'ui-component/CustomButton';
import { StyledTableCell, StyledTableRow } from 'ui-component/TableStyles';
import CustomField from 'ui-component/formik/CustomField';
import Card from 'ui-component/cards/Card';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { AddExcelMasterReportAPI, EditExcelMasterReportAPI, FetchExcelSelectedMasterReport } from 'api';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'views/Loading';
import ExcelSheet from './ExcelSheet';
import sample_data from './sample_data.ts';

const CreateExcelReportMaster = () => {
    const useStyles = makeStyles({
        customTable: {
            '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
            '& .MuiTableCell-head': {
                color: 'white',
                backgroundColor: '#0000FF'
            }
        }
    });
    const classes = useStyles();
    const { id: selectedID } = useParams();
    // console.log(selectedID,"selectedID")
    let isEditMode = !!selectedID;
    // const navigate = useNavigate()
    const [validateType, setValidateType] = useState(false);
    const [dataColumnValues, setDataColumnValues] = useState({
        cell_id: '',
        cell_name: '',
        column_name: '',
        column_type: '',
        formula: '',
        is_editmode: false
    });
    const [columns, setColumns] = useState([]);

    const initialValues = {
        excel_report_name: '',
        excel_report_description: '',
        excel_report_type: '',
        sheet_data: []
    };
    const workbookRef = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();

    // const [numberOfrows, setNumberOfRows] = useState(0);

    const {
        isLoading: isSelectedReportLoading,
        data: selectedReportData,
        isRefetching: isSelectedReportRefetching,
        status: selectedReportStatus,
        refetch: refetchListing
    } = FetchExcelSelectedMasterReport(selectedID);
    const { mutateAsync: uploadExcelMasterReport, status: MasterExcelReportStatus, error: MasterExcelReportError } = AddExcelMasterReportAPI();
    const { mutateAsync: updatedMasterReport, status: updatedMasterReportStatus, error: updatedMasterReportError } = EditExcelMasterReportAPI();

    React.useEffect(() => {
        if (MasterExcelReportStatus == 'success' || updatedMasterReportStatus == 'success') {
            toast.success( isEditMode ? "Report Update": "Report Created" ,{
                    position: 'top-left',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                      onClose: () => {
                      navigate('/reports')
                }
                });
        } else if (MasterExcelReportStatus == 'error' || updatedMasterReportStatus == 'error') {
            toast.error(updatedMasterReportError.response.data.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [MasterExcelReportStatus, updatedMasterReportStatus, updatedMasterReportError, MasterExcelReportError]);
    const [currentData, setCurrentData] = useState([sample_data]);


    // React.useEffect(() => {
    //     if(selectedReportData?.data.sheet_data.length > 0 && !isSelectedReportLoading){
    //         setCurrentData( selectedReportData?.data.sheet_data)
    //         console.log( selectedReportData?.data.sheet_data)
    //     }
    // }, [selectedReportData]);


    const formik = useFormik({
        initialValues: selectedID !== undefined && !isSelectedReportLoading ? selectedReportData?.data : initialValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            // console.log(values,currentData, 'values');
            const selection = workbookRef.current.getAllSheets();
            let operations = [];
            let whole_data  = [];
            selection.forEach(function(c_val,c){
                c_val.data.forEach(function(val,i) {  
                    val.forEach(function(j_val,j) {  
                        j_val != null &&  j_val.m != ""  &&  operations.push({
                            r: i,
                            c: j,
                            v: j_val,
                        })
                    });
                    }); 
                    const { data, ...rest  } =  currentData[c];
                    rest.celldata = operations
                    whole_data[c] = rest
                    operations = [];
            })

            let payload = {
                excel_report_name: values.excel_report_name,
                excel_report_description: values.excel_report_description,
                excel_report_type: values.excel_report_type,
                sheet_data: whole_data 
            };
           

            {
                isEditMode
                    ? updatedMasterReport(
                          { payload: payload, id: selectedID },
                         
                      )
                    : uploadExcelMasterReport(
                          { payload },
                         
                      );
            }
        }
    });
   
    return (
        <div>
            <ToastContainer />
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={isEditMode ? 'Edit Master Report' : 'Create Master Report'} shouldGoBack={true} />
            </Box>
            <Card>
                {isSelectedReportLoading && isEditMode ? (
                    <Loading />
                ) : (
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack item container xs={12} rowGap={2}>
                                <Grid alignItems={'center'} xs={12} gap={2} item container>
                                    <Grid item xs={12} md={5.8}>
                                        <CustomField
                                            label={'Report Name'}
                                            name={'excel_report_name'}
                                            value={formik.values.excel_report_name}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5.8}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" required>
                                                Report Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Report Type"
                                                value={formik.values.excel_report_type}
                                                // displayEmpty={true}
                                                // required
                                                onChange={(e) => {
                                                    formik.setFieldValue('excel_report_type', e.target.value);
                                                }}
                                            >
                                                <MenuItem value={'Deals'}>Deals</MenuItem>
                                                <MenuItem value={'Startups'}>Startups</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <CustomField
                                            multiline
                                            label={'Report Description'}
                                            name={'excel_report_description'}
                                            value={formik.values.excel_report_description}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={2}>
                                <CustomButton onClick={() => setNumberOfRows((prev) => prev + 1)}>Add rows</CustomButton>
                            </Grid> */}
                                </Grid>

                                <Grid xs={12} item container>
                                    <ExcelSheet data={currentData} currentData={currentData} setCurrentData={setCurrentData} selectedReportData={selectedReportData} isEditMode={isEditMode} workbookRef={workbookRef}></ExcelSheet>
                                </Grid>
                                <Grid item display={'flex'} flexDirection={'row-reverse'} container columnGap={3} sx={12}>
                                    <Grid item>
                                        <CustomButton type="submit">Save</CustomButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Form>
                    </FormikProvider>
                )}
            </Card>
        </div>
    );
};

export default CreateExcelReportMaster;
