import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';
import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTheme } from 'victory';

const MISBarGraph = ({ legend, data, string }) => {
    const theme = useTheme();
    return (
        <Grid item xs={12} sm={5.6} md={5.6} className="bar-graph" border={'1px solid'} paddingBottom={'2rem'} marginLeft={2} marginTop={4}>
            <h4 style={{ margin: 0, textAlign: 'center' }}>{legend}</h4>
            <VictoryChart theme={VictoryTheme.grayscale} domainPadding={{ x: 10 }} width={487.5}>
                <VictoryAxis dependentAxis domain={string === '%' ? { y: [0, 100] } : {  }} tickFormat={(t) =>`${t?.toLocaleString('en-IN', {maximumFractionDigits: 2, style: 'currency', currency: 'INR'}).slice(1).slice(0, -3)}`} />
                <VictoryBar className="bargrphhhh" labelComponent={<VictoryLabel dx={10} />} labels={({ datum }) => `${string === '%'? datum.y?.toFixed() : datum.y?.toLocaleString('en-IN', { maximumFractionDigits: 2, style: 'currency', currency: 'INR' }).slice(1).slice(0, -3)}${string}`} alignment="start" data={data} barWidth={15} padding={{ left: 20, right: 60 }} style={{ data: { fill: theme.palette.secondary.main, fontFamily: 'inherit' }, labels: { fontFamily: 'inherit', fontSize: '0.2rem' }}} />
                <VictoryAxis tickLabelComponent={<VictoryLabel dx={-20} dy={0} angle={'-45'}/>} />
            </VictoryChart>
        </Grid>
    );
};

export default MISBarGraph;
