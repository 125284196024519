import { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Switch } from '@mui/material';
import CustomField from 'ui-component/formik/CustomField';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Cancel } from '@mui/icons-material';
import { AddMasterTemplate, FetchMasterTemplateDetails, UpdateMasterTemplateForm } from '../../api';
import { useParams, useNavigate } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import Loading from 'views/Loading';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import SelectField from 'ui-component/formik/SelectField';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';

const Type = [
    { value: '', label: 'Select Option' },
    { value: 'deal', label: 'For Deal' },
    { value: 'startup', label: 'For Startup' }
];
const Period = [
    { value: '', label: 'Select Option' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quaterly' },
    { value: 'onetime', label: 'One-Time' }
];

const ChecklistMaster = () => {
    const { id: master_id } = useParams();
    const isEditMode = !!master_id;
    const docRef = useRef();
    const navigate = useNavigate();

    const {
        isLoading: ismasterTemplateLoading,
        data: masterTemplateData,
        status: masterTemplateStatus
    } = FetchMasterTemplateDetails(master_id, 'edit');
    const { mutateAsync: uploadMasterTemplate, status: uploadMasterTemplateStatus, error: uploadMasterTemplateError } = AddMasterTemplate();
    const {
        mutateAsync: UpdateMasterTemplate,
        isLoading: isLoadingUpdateUpdateMasterTemplate,
        status: UpdateMasterTemplateStatus,
        error: UpdateMasterTemplateError
    } = UpdateMasterTemplateForm();
    const initialValues = {
        template_name: '',
        tasks: [
            { required: false, task_name: 'MoU and Lead Investor Agreement', task_description: '', display_order: 1 },
            { required: false, task_name: 'Trustee Noting Letter', task_description: '', display_order: 2 },
            { required: false, task_name: 'Engagement letter', task_description: '', display_order: 3 },
        ],
        checklist_type: '',
        checklist_period: ''
    };

    const formik = useFormik({
        initialValues: !ismasterTemplateLoading && isEditMode ? masterTemplateData : initialValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const updatedTasks = values.tasks.map((task, index) => ({
                ...task,
                display_order: index + 1
            }));
            values.tasks = updatedTasks;

            if (isEditMode) {
                delete values._id;
                delete values.updated_by;
                delete values.createdAt;
                delete values.updatedAt;
                delete values.created;
                delete values.updated;

                UpdateMasterTemplate({ payload: values, id: master_id });
            } else {
                uploadMasterTemplate({ payload: values });
            }
        }
    });
    const routes = [{ path: '/', breadcrumb: '' }];
    const theme = useTheme();
    const breadcrumbs = useBreadcrumbs(routes, {
        excludePaths: ['/deals/confirmationList']
    });

    useEffect(() => {
        if (uploadMasterTemplateStatus == 'success') {
            formik.resetForm();
            toast.success('Template Create!', {
                position: 'top-left',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    navigate('/checklist');
                }
            });
        }
        if (uploadMasterTemplateStatus == 'error') {
            toast.error(uploadMasterTemplateError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [uploadMasterTemplateStatus]);

    useEffect(() => {
        if (UpdateMasterTemplateStatus == 'success') {
            toast.success('Template Update!', {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    navigate('/checklist');
                }
            });
        }
        if (UpdateMasterTemplateStatus == 'error') {
            toast.error(UpdateMasterTemplateError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [UpdateMasterTemplateStatus]);

    if (ismasterTemplateLoading && isEditMode) {
        return (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loading />
            </div>
        );
    }
    return (
        <>
            <BreadcrumbHeader title={isEditMode ? 'Edit Checklist' : 'Add Checklist'} shouldGoBack />
            <ToastContainer />
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="custom-menu-block view-investor-block">
                        <div className="row mt-4">
                            <div className="col-12">
                                <CustomField name="template_name" label="Checklist Template Name" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6">
                                <SelectField name="checklist_type" label="Checklist Type" options={Type} />
                            </div>
                            <div className="col-6">
                                <SelectField name="checklist_period" label="Checklist Period" options={Period} />
                            </div>
                        </div>
                        <div className="position-relative mt-4">
                            <FieldArray
                                name="tasks"
                                render={(arrayHelpers) => {
                                    docRef.current = arrayHelpers;
                                    return (
                                        <DragDropContext
                                            onDragEnd={(result) => {
                                                if (!result.destination) {
                                                    return  toast.error('please drop inside the list',{
                                                        position: 'top-left',
                                                        autoClose: 1000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: false,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: 'colored',
                                                    })
                                                }
                                                const items = [...formik.values.tasks];
                                                const [reorderedItem] = items.splice(result.source.index, 1);
                                                items.splice(result.destination.index, 0, reorderedItem);
                                                formik.setFieldValue('tasks', items);
                                            }}
                                        >
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {formik.values.tasks &&
                                                            formik.values.tasks.length > 0 &&
                                                            formik.values.tasks.map((_, index) => (
                                                                <Draggable
                                                                    key={`task-${index}`}
                                                                    draggableId={`task-${index}`}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            isDragging={snapshot.isDragging}
                                                                        >
                                                                            <div className={`custom-card card mb-3 checklist-master-card pt-2 ${snapshot.isDragging ? 'background shadow-sm dragging' : 'bg-white shadow-none'}`}>
                                                                                {/* Card content */}
                                                                                <div className="cs-close close-pos-right">
                                                                                    <Cancel onClick={() => arrayHelpers.remove(index)} />
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-md-1 mb-3">
                                                                                            <div>Task No.{index + 1}</div>
                                                                                        </div>
                                                                                        <div className="col-md-4 mb-3">
                                                                                            <CustomField
                                                                                                name={`tasks.${index}.task_name`}
                                                                                                label="Task Name"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-4 mb-3">
                                                                                            <CustomField
                                                                                                name={`tasks.${index}.task_description`}
                                                                                                label="Task Description"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-2 mb-3 pt-1">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <div className="me-2">Required</div>
                                                                                                <div className="custom-switch">
                                                                                                    <Switch
                                                                                                        name={`tasks.${index}.required`}
                                                                                                        checked={
                                                                                                            formik.values.tasks[index]
                                                                                                                .required
                                                                                                        }
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.checked) {
                                                                                                                formik.setFieldValue(
                                                                                                                    `tasks.${index}.required`,
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                            } else {
                                                                                                                formik.setFieldValue(
                                                                                                                    `tasks.${index}.required`,
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'controlled'
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <Tooltip title="Enabling this means the task depends on completing prior tasks. It ensures a step-by-step completion in the checklist.">
                                                                                                    <InfoOutlinedIcon
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                            marginLeft: 5,
                                                                                                            fontSize: 'large'
                                                                                                        }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div  className="col-md-1 mb-3 mt-2" >
                                                                                            <div
                                                                                                className="drag-btns"
                                                                                                {...provided.dragHandleProps}
                                                                                            >
                                                                                                 <Tooltip title="By tap on this you can drag and drop the task anywhere in the list according to you">
                                                                                                <MenuIcon />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>

                                                                                        <input
                                                                                            type="hidden"
                                                                                            name={`tasks.${index}.display_order`}
                                                                                            value={index + 1}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    );
                                }}
                            />
                        </div>
                        <div className="row mt-4">
                            <div className="col-6">
                                <button type="submit" className="button-79 text-center theme-btn">
                                    Submit
                                </button>
                            </div>
                            <div className="col-6 text-end">
                                <button
                                    type="button"
                                    onClick={() =>
                                        docRef.current.push({
                                            required: false,
                                            task_name: '',
                                            task_description: '',
                                            display_order: formik.values.tasks.length + 1
                                        })
                                    }
                                    className="button-79 text-center btn-success"
                                >
                                    Add More +
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
        </>
    );
};

export default ChecklistMaster;
