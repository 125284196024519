import { useState, useRef } from 'react';
import { Paper, Skeleton, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from 'react-icons/im';
import { UpdateDealFlowStatus, FetchDealStatusLogs, FetchInvoiceStatusLogs, FetchAllInvoiceStatus, FetchAllStatusForInvoice, UpdateInvoiceStatus } from 'api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

const validationSchema = yup.object({
    // min_investment_amount: yup
    // .string('Enter Min Investment Amount')
    // .required('Min Investment Amount is required')
    // .trim(),
    // max_investment_amount: yup
    // .string('Enter Max Investment Amount')
    // .required('Max Investment Amount is required')
    // .trim(),
    // closing_date: yup
    // .string('Enter Closing Date')
    // .required('Closing Date is required')
    // .trim(),
    // pitch_recording: yup
    // .string('Enter Pitch Recording')
    // .required('Pitch Recording is required')
    // .trim(),
    comment: yup.string('Comment Recording').required('Comment is required').trim()
});
const columns = [
    {
        label: 'Status',
        minWidth: 0,
        align: 'left',
        type: 'stage_name'
    },
    {
        label: 'Amount Received',
        minWidth: 0,
        align: 'center',
        type: 'fund_amount'
    },
    {
        label: 'Comment',
        minWidth: 0,
        align: 'left',
        type: 'comment'
    },
    {
        label: 'Updated At',
        minWidth: 0,
        align: 'left',
        type: 'created_date'
    },
    {
        label: 'Updated  By',
        minWidth: 0,
        align: 'left',
        type: 'admin_name'
    }
    // ,
    // {
    //     label: 'Type',
    //     minWidth: 0,
    //     align: 'left',
    //     type: 'type'
    // }
];
const InvoiceStatusUpdatePopup = ({
    handleClose,
    currentRow,
    refetchListing
}) => {
    const [logPage, setLogPage] = useState(1);
    const theme = useTheme();
    const [set_mail, setIsMail] = useState(false);
    const [isTestMail, setIsTestMail] = useState(false);
    const scheme_name = currentRow["scheme_name"]

    // console.log('isTestMail', isTestMail);

    const {
        mutateAsync: UpdateMasterTemplate,
        isLoading: isLoadingUpdateDealFlowTemplate,
        status: UpdateDealStatus,
        error: UpdateDealFlowError
    } = UpdateInvoiceStatus();
    const { data: logs, isLoading: isLogsLoading, refetch: refetchLogs } = FetchInvoiceStatusLogs(currentRow["_id"]);
    const { data: status, isLoading: isStatusLoading } = FetchAllStatusForInvoice();
    const isCompletedMode = false;

    const formik = useFormik({
        initialValues: {
            invoice_id: currentRow["_id"],
            master_invoice_stage_id: "",
            comment: "",
            fund_amount: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const payload = {
                "invoice_id": values?.invoice_id,
                "master_invoice_stage_id": values?.master_invoice_stage_id,
                "comment": values?.comment,
                "fund_amount": parseFloat(values?.fund_amount)
            };
            // console.log(payload, "payload")
            UpdateMasterTemplate(
                payload
            );
        }
    });

    React.useEffect(() => {
        if (UpdateDealStatus == "success") {
            toast.success('Invoice Status Updated', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    setTimeout(() => {
                        refetchLogs();
                        refetchListing();
                        handleClose({ row: currentRow });
                    }, 200);
                }
            });
        }
        else if (UpdateDealStatus == "error") {
            // console.log(, "message");
            toast.error(UpdateDealFlowError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [UpdateDealStatus, UpdateDealFlowError])
    // if(isLogsLoading){
    //   return <h1>Loading...</h1>
    // }

    // console.log(status, "logs")
    return (
        <div>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
            >
                {isStatusLoading ? <Loading /> : <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10 }}>
                        <h3 style={{ textAlign: 'center' }}>Change Invoice Status of {scheme_name}</h3>
                        <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                            <Grid item xs={12}>
                                <SelectField name="master_invoice_stage_id" label="Status" options={status} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextArea name="comment" label="Comment" multiline={true} minRows={1} sx={{ borderWidth: 2 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomField name="fund_amount" inputProps={{
                                    min: 0,
                                    onKeyDown: (event) => {
                                        // Prevent the minus sign (-) from being entered
                                        if (event.key === '-') {
                                            event.preventDefault();
                                        }
                                    },
                                    onKeyPress: (event) => {
                                        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                        if (event.key === '-') {
                                            event.preventDefault();
                                        }
                                    },
                                    inputMode: 'numeric',
                                    pattern: '[0-9.]*',
                                    onKeyPress: (event) => {
                                        // Get the input value
                                        const inputValue = event.key;

                                        // Allow only numeric characters (0-9) and certain control keys
                                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];

                                        // Check if the pressed key is in the allowedKeys array
                                        if (!allowedKeys.includes(inputValue)) {
                                            // If not, prevent the key from being entered
                                            event.preventDefault();
                                        }
                                    },
                                    onInput: (event) => {
                                        // Get the input value
                                        const inputValue = event.target.value;

                                        // Remove non-numeric characters using a regular expression
                                        const numericValue = inputValue.replace(/[^0-9.]/g, '');

                                        // Update the input field value with the filtered numeric value
                                        event.target.value = numericValue;
                                    },
                                    onKeyDown: (event) => {
                                        // Prevent the input of minus sign (key code 189)
                                        if (event.keyCode === 189 || event.key === "-") {
                                            event.preventDefault();
                                        }
                                    },
                                    onChange: (event) => {
                                        formik.setFieldValue("fund_amount", event.target.value);
                                    }

                                }} label="Amount Received" sx={{ borderWidth: 2 }} />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} gap={4} mt={2}>
                            <Grid item xs={1}>
                                {isCompletedMode ? (
                                    <>
                                        <CustomButton type="submit" disabled>
                                            Submit
                                        </CustomButton>
                                        <p style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                                            Note: Deal completed. Stage update not allowed.
                                        </p>
                                    </>
                                ) : (
                                    <CustomButton type="submit">Submit</CustomButton>
                                )}
                            </Grid>

                            <Grid item xs={10}>
                                {set_mail && <CustomButton onClick={() => setIsTestMail(true)}>Send Test Email</CustomButton>}
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>}
                {isLogsLoading ? (
                    <Loading />
                ) : (
                    <AsyncTableListing
                        // title="Logs"
                        columns={columns}
                        // actions={CallForMoneyActions}
                        data={logs}
                        // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(callForMoneyData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
                        page={logPage}
                        setPage={setLogPage}
                        count={logs.length}
                        limit={5}
                        pagination={false}
                        tableStyle={{ minWidth: 200 }}
                    />
                )}
            </Paper>
        </div>
    );
};

export default InvoiceStatusUpdatePopup;
