import { useState, useEffect,useRef } from 'react';
import { Paper, Skeleton, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from 'react-icons/im';
import {UpdateDealMultipleUnitClassStatus, UpdateDealUnitClassStatus } from 'api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Checkbox from '@mui/material/Checkbox';

const validationSchema = yup.object({
    // min_investment_amount: yup
    // .string('Enter Min Investment Amount')
    // .required('Min Investment Amount is required')
    // .trim(),
    // max_investment_amount: yup
    // .string('Enter Max Investment Amount')
    // .required('Max Investment Amount is required')
    // .trim(),
    // closing_date: yup
    // .string('Enter Closing Date')
    // .required('Closing Date is required')
    // .trim(),
    // pitch_recording: yup
    // .string('Enter Pitch Recording')
    // .required('Pitch Recording is required')
    // .trim(),
    comment: yup.string('Comment Recording').required('Comment is required').trim()
});
const columns = [
    {
        label: 'Stage',
        minWidth: 0,
        align: 'left',
        type: 'stage_name'
    },
    {
        label: 'Comment',
        minWidth: 0,
        align: 'left',
        type: 'comment'
    },
    {
        label: 'Updated At',
        minWidth: 0,
        align: 'left',
        type: 'created_date'
    },
    {
        label: 'Updated  By',
        minWidth: 0,
        align: 'left',
        type: 'admin_name'
    },

    {
        label: 'Type',
        minWidth: 0,
        align: 'left',
        type: 'type'
    }
];

const UnitClassChangePopup = ({
    dealId,
    activeRow,
    check_investor_values,
    handleClose,
    refetchTransactions,
    unitClassData,
}) => {
    const [logPage, setLogPage] = useState(1);
    const theme = useTheme();
    const [unit_class_obj, setUnitClassObject] = useState([]);
    const {
        mutateAsync: UpdateMasterTemplate,
        isLoading: isLoadingUpdateDealFlowTemplate,
        status: UpdateDealStatus,
        error: UpdateDealFlowError
    } = UpdateDealUnitClassStatus();
    const {
        mutateAsync: UpdateMultipleUnitClassTemplate,
        isLoading: isLoadingUpdateMultipleUnitClassTemplate,
        status: UpdateMultipleUnitClassStatus,
        error: UpdateMultipleUnitClassError
    } = UpdateDealMultipleUnitClassStatus();

    useEffect(() => {
       let formatted_data = [];
       formatted_data = unitClassData?.map((state)=>{
            return ({label: state?.label, value:state?.unit_class_id})
          })
          setUnitClassObject(formatted_data);
    }, [unitClassData]);
    // const [isTestMail, setIsTestMail] = useState(false);

    // console.log(check_investor_values?.map(item => item?._id),"check_investor_values")
 

    const formik = useFormik({
        initialValues: {
            unit_class_id:null,
        },
        onSubmit: (values) => {
            const payload = {
                unit_class_id: values?.deal_flow_id,
                id: activeRow?._id,
                transaction_ids: check_investor_values?.map(item => item?._id)
            };

            if(activeRow._id != undefined){

            UpdateMasterTemplate(
                { payload: {unit_class_id: payload?.unit_class_id}, id: payload?.id },
                {
                    onSuccess: () => {

                        setTimeout(() => {
                            refetchTransactions();
                        }, 200);
                        handleClose();
                        toast.success('Unit Class Updated', {
                            position: 'top-left',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                    },
                    onError: (err) => {
                        // console.log(JSON.parse(err.request.response).message);
                        toast.error(JSON.parse(err.request.response).message, {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                    }
                }
            );}

            if(check_investor_values?.length > 0){
                UpdateMultipleUnitClassTemplate(
                    { payload: {
                        unit_class_id: payload?.unit_class_id,
                        transaction_ids: payload?.transaction_ids
                    } },
                    {
                        onSuccess: () => {
    
                            setTimeout(() => {
                                refetchTransactions();
                            }, 200);
                            handleClose();
                            toast.success('Updated Unit Class', {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored'
                            });
                        },
                        onError: (err) => {
                            // console.log(JSON.parse(err.request.response).message);
                            toast.error(JSON.parse(err.request.response).message, {
                                position: 'top-left',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored'
                            });
                        }
                    }
                );
            }
        }
    });

    // if(isLogsLoading){
    //   return <h1>Loading...</h1>
    // }
    return (
        <div>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10 }}>
                        <h3 style={{ textAlign: 'center' }}>Change Unit Class {activeRow.investor_name && 'of'} {activeRow.investor_name}</h3>
                        <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                            <Grid item xs={12}>
                                <SelectField name="deal_flow_id" label="Unit Class" options={unit_class_obj} />
                            </Grid>

                            {/* <Grid item xs={12}>
                                <CustomTextArea name="comment" label="Comment" multiline={true} minRows={1} sx={{ borderWidth: 2 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Checkbox
                                    id="guardian"
                                    checked={set_mail}
                                    onChange={(e) => {
                                        setIsMail(e.target.checked);
                                        setIsTestMail(false);
                                    }}
                                />
                                <label htmlFor="guardian" variant="h5" style={{ cursor: 'pointer' }}>
                                    Do you want to send mail notification ?
                                </label>
                            </Grid> */}
                        </Grid>

                        <Grid item container xs={12} gap={4} mt={2}>
                            <Grid item xs={1}>
                                    <CustomButton type="submit">Submit</CustomButton>
                            </Grid>

                        </Grid>
                    </Form>
                </FormikProvider>
            
            </Paper>
        </div>
    );
};

export default UnitClassChangePopup;
