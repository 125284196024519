import React, { useState,useEffect} from 'react';
import { Paper, Box,Typography, Button} from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import Grid from '@mui/material/Grid';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import {DealsMonthlyDocument } from 'api';
import Loading from 'views/Loading';
import Card from 'ui-component/cards/Card';
import SelectField from 'ui-component/SelectField';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import UpdateTemplateMonthlyChecklist from './UpdateTemplateMonthlyChecklist';
import Modal from 'ui-component/Modal';
import Clickable from 'utils/Clickable';



const MonthlyDealTemplate = ({id,dealId,refetchListing}) => {
   const to_date = moment().format('YYYY-MM-DD');
   const from_date = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
   const [open, setOpen] = useState(false);
   const [taskId, setTaskId] = useState('')
   const [month_task, setTaskMonth] = useState('')

    const { data: investorDataChecklist, isLoading: isInvestorLoading, refetch: isSyndicateRefetching,status: targetFeesStatus, error: targetFeesError } = DealsMonthlyDocument(id.checklist_master_id,dealId,from_date,to_date);
    const handleClose = () => setOpen(false);
    const handleOpen = (id,date) => {
      setTaskId(id.task_id)
      setTaskMonth(date)
      setOpen(true)
  }
    const captureAndCopyScreenshot = () => {
      const tableElement = document.getElementById('target'); // Replace 'your-table-id' with the ID of your table element
      html2canvas(tableElement).then(canvas => {
        canvas.toBlob(blob => {
          navigator.clipboard.write([
            new ClipboardItem({ [blob.type]: blob }),
          ]);
          toast.success('Screenshot copied!', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      });
    };
    
    // console.log(id.checklist_master_id,investorDataChecklist,"punitpunitpunitpunitpunitpunitpunitpunitpunitpunitpunitpunitpunitpunitpunit")

    return(
        <>
        <ToastContainer />
        <Modal open={open} onClose={handleClose} closeIcon={true} style={{ width: '900px' }} disableScrollLock={true}>
                <UpdateTemplateMonthlyChecklist date={month_task} taskId={taskId} setOpen={setOpen} refetchListing={refetchListing} isSyndicateRefetching={isSyndicateRefetching} />
            </Modal>
           {/* <Box sx={{ width: '100%' }}>
             <BreadcrumbHeader title={title} />
           </Box>   */}
          <Card style={{ paddingLeft: '16px', paddingRight: '16px' }}>             
           <>
            <div className='custom-table table-responsive lp-summary-table investor-onboarding-table'>
              <table className='w-100 table add-border'id="target">
                {investorDataChecklist?.data?.length > 0  && (
                   <thead>
                   <tr>
                   <th style={{minWidth: 150}}>Task Name</th>
                   {investorDataChecklist?.headers.map((header, index) => (
                    <th>{header}</th>
                    ))}
                   </tr>
                  </thead>
                )}
              <tbody>
            {investorDataChecklist?.data?.length > 0  ? (
            investorDataChecklist?.data.map((partner, partnerIndex) => (
                <tr key={partnerIndex}>
                 <td>{partner?.task_name}</td>
                 {Object.entries(partner?.dateArr).map(([date, value], dateIndex) => (
                  <td style={{padding : 0}} title={`ETA: ${partner?.eta_date.split('-').map((item,index) => {if(index === 1) {return date.slice(0,3)  } else {  return item}
                  } ).join('-')}`} key={dateIndex}>
                    {value ? 
                    <Button sx={{width: '100%', minHeight: 50,borderRadius: 0}} onClick={() => handleOpen(partner,date)}>
                      <CheckIcon style={{color:'green'}}/>
                    </Button>
                     :
                     <Button sx={{width: '100%', minHeight: 50, borderRadius: 0}} onClick={() => handleOpen(partner,date)}> 
                     <CloseIcon style={{color:'red'}}/>
                     </Button>
                     }
                     </td>
                     
                 ))}
                </tr>
              ))
              
            ):(
              <tr>
               <td className='text-center' colSpan={5}><Typography variant="h4">Select</Typography></td>
              </tr>
             )}
              {/* {investorData?.data?.length > 0 && searchStatus && stage  && targetFeesStatus=='success' &&  (
                <tr >
                 <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>Total</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetAmountPercentage}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetAmount}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalAchievedTargetAmount}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalRemainingAmount}</td>
                </tr>
              )} */}
            </tbody>
              </table>
            </div>  
            {/* <div className='text-end'>
            {investorDataChecklist?.data?.length > 0  && (
              <CustomButton style={{marginTop:"20px"}}
                onClick={captureAndCopyScreenshot}
               size="medium"
               sx={{ height: '40px' }} 
              >
               Copy As Image
              </CustomButton>
            )}
            </div> */}
           </>
         </Card>
    </>
  )
}
export default MonthlyDealTemplate