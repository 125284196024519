import { useState, useEffect, useRef, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Paper, TextField, Grid, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import { useFormik, Form, FieldArray, FormikProvider, Field, FastField } from "formik";
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import SelectField from 'ui-component/formik/SelectField';
import { IoMdRemove, IoMdAdd } from "react-icons/io";
import CustomButton from 'ui-component/CustomButton';
import { FetchStages, FetchStatus, FetchSector, AddArticle, UploadStartupDocs, FetchArticleDetails, UpdateArticle, FetchReferralList, FetchSyndicatesInvestorDetails, FetchAllSyndicatesOptions  } from 'api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import Clickable from 'utils/Clickable';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { useNavigate, useParams } from "react-router-dom";
import Link from '@mui/material/Link';
import AutoCompleteTextField from 'ui-component/formik/AutoCompleteTextField'
import { countryCode } from 'assets/data'
// import 'assets/scss/custom.css';
import Card from 'ui-component/cards/Card';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import CustomMultiSelect from '../../../ui-component/formik/CustomMultiSelect'
import Cookies from 'js-cookie';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '10px 0 0 10px',
        borderRightWidth: '0px'
      },
    },
  },
})(TextField);

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  description: yup
    .string('Enter description')
    .required('Description is required')
    .trim(),
});

const AddStartupComponent = () => {
  const theme = useTheme();
  const thumbnailFileRef = useRef(null);
  const imageFileRef = useRef(null);
  const { id: ArticleId } = useParams()
  // console.log(ArticleId)
  const isEditMode = !!ArticleId
  const navigate = useNavigate()

  const { mutateAsync: uploadArticle, status: articleStatus, error: articleError, isLoading: isAddArticleLoading } = AddArticle();
  const { mutateAsync: updateArticle, status: updateArticleStatus, error: updateArticleError, isLoading: isUpdateArticleLoading } = UpdateArticle();
  const { isLoading: isArticleDetailsLoading, isRefetching: isArticleDetailsRefetching, data: articleData } = FetchArticleDetails(ArticleId, 'edit')
  const { isLoading: isVenturePartnerListLoading, data: venturePartnerList } = FetchAllSyndicatesOptions()
  // console.log("articleData", articleData);
  const initialValues = {
    short_heading: '',
    heading: '',
    description: '',
    type: '',
    is_featured: 'No',
    publish_date: null,
    thumbnail: null,
    image: null,
    video_url: "",
    syndicate_id: "",
    lead_investor_id: ''
  }

  const formik = useFormik({
    initialValues: (isEditMode && !isArticleDetailsLoading) ? articleData : initialValues,
    // validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      // values.lead_investor_id.forEach(id => {
      //   if(values.lead_investor_id.length===1){
      //     formData.append("lead_investor_id[]", id)
      //   }else{
      //     formData.append("lead_investor_id", id)
      //   }
      // });

      if (isEditMode) {
        // console.log("values", values);
        formData.append("short_heading", values.short_heading);
        formData.append("heading", values.heading);
        formData.append("description", values.description);
        formData.append("type", values.type);
        formData.append("is_featured", values.is_featured);
        formData.append("publish_date", values.publish_date);
        formData.append("video_url", values.video_url);
        formData.append("display_order", "100");
        if(values.syndicate_id){
          formData.append("syndicate_id[]", values.syndicate_id);
        }
        if(values.lead_investor_id){
          formData.append("lead_investor_id[]", values.lead_investor_id);
        }

        if (values.image?.size) {
          formData.append("image", values.image);
        }

        if (values.thumbnail?.size) {
          formData.append("thumbnail", values.thumbnail);
        }

        updateArticle({ payload: formData, id: ArticleId })
      }
      else {
        formData.append("short_heading", values.short_heading);
        formData.append("heading", values.heading);
        formData.append("description", values.description);
        formData.append("type", values.type);
        formData.append("is_featured", values.is_featured);
        formData.append("publish_date", values.publish_date);
        formData.append("thumbnail", values.thumbnail);
        values.image && formData.append("image", values.image);
        formData.append("video_url", values.video_url);
        formData.append("display_order", "100")
        if(values.syndicate_id){
          formData.append("syndicate_id", values.syndicate_id);
        }
        if(values.lead_investor_id){
          formData.append("lead_investor_id[]", values.lead_investor_id);
        }
        uploadArticle(formData)
      }
    },
  });

  const debouncedValidate = useMemo(
    () => debounce(formik.validateForm, 500),
    [formik.validateForm],
  );

  // const { data: investorslist, isLoading: isStateLoading } = FetchSyndicatesInvestorDetails(formik?.values?.syndicate_id || "")


  useEffect(
    () => {
      debouncedValidate(formik.values);
    },
    [formik.values, debouncedValidate],
  );

  useEffect(() => {

    if (isEditMode) {
      if (updateArticleStatus == 'error') {
        toast.error(updateArticleError.response.data.message, {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      else if (updateArticleStatus == 'success') {
        toast.success('Article Updated!', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            navigate('/resources/articles')
          }
        });
      }
    } else {
      if (articleStatus == 'error') {
        toast.error(articleError.response.data.message, {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      else if (articleStatus == 'success') {
        formik.resetForm()
        toast.success('Startup Added!', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            navigate('/resources/articles')
          }
        });
      }
    }
  }, [articleStatus, updateArticleStatus])

  if ((isArticleDetailsLoading && isEditMode)
  //  || isVenturePartnerListLoading 
  ) {
    return <h1>Loading...</h1>
  }

  return (
    <div style={{ display: "flex", flexDirection: 'column', height: '100%' }}>
      <ToastContainer />
      <BreadcrumbHeader title={isEditMode ? "Edit Article" : "Add Article"} shouldGoBack />
      <div sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', flex: 1 }}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px'}}>
             {Cookies.get("is_super_admin") == "true" && !isVenturePartnerListLoading && <Card title="Syndicates">
                <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                  <Grid item md={6} xs={12}>
                    <SelectField
                      label="Syndicate"
                      name="syndicate_id"
                      options={venturePartnerList}
                    // onChange={handleSyndicateChange}
                    />

                  </Grid>
                  {/* <Grid item xs={6}>
                    <SelectField
                      name="lead_investor_id"
                      label="Lead Investor"
                      // disabled={!!states}
                      options={!!investorslist ? investorslist : []}
                    />
                  </Grid> */}
                </Grid>
              </Card>}
            <Card title="Article Information">
              <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12}>
                  <CustomField name="short_heading" label="Short Title" />
                </Grid>
                <Grid item xs={12}>
                  <CustomField name="heading" label="Long Title" />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextArea name="description" label="Description" multiline={true} minRows={4} sx={{ borderWidth: 2 }} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <SelectField
                    name="type"
                    label="Category"
                    options={[{ label: "Article", value: "article" }, { label: "Portfolio", value: "portfolio" }]}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Publish Date"
                      value={formik.values.publish_date}
                      onChange={(newValue) => formik.setFieldValue('publish_date', moment(newValue).format('YYYY-MM-DD'))}
                      renderInput={(params) => <TextField
                        {...params}
                        error={Boolean(formik.touched.publish_date && formik.errors.publish_date)}
                        helperText={formik.touched.publish_date && formik.errors.publish_date}
                        fullWidth
                        name="publish_date"
                      />}
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Clickable onClick={() => imageFileRef.current.click()} style={{ flex: 1 }}>
                      <CustomTextField value={formik.values.thumbnail ? formik.values.thumbnail.name : ''} fullWidth disabled label="Thumbnail" />
                    </Clickable>
                    <Button
                      ref={imageFileRef}
                      variant="contained"
                      component="label"
                      disableElevation
                      style={{ borderRadius: '0px 10px 10px 0px', height: '51px' }}
                    >
                      Upload
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        id="doc"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            formik.setFieldValue("thumbnail", e.target.files[0])
                          }
                          else {
                            formik.setFieldValue("thumbnail", null)
                          }
                        }}
                      />
                    </Button>
                  </Grid>
                  {isEditMode && <Grid item xs={12}>
                    <Link
                      href={formik.values.thumbnail?.url}
                      target="_blank"
                      underline="none"
                      sx={{ fontSize: "10px", marginTop: "2px", marginBottom: "14px", marginLeft: '5px', display: 'block' }}
                    >
                      {formik.values.thumbnail?.url && formik.values.thumbnail?.name}
                    </Link>
                  </Grid>}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Clickable onClick={() => thumbnailFileRef.current.click()} style={{ flex: 1 }}>
                      <CustomTextField value={formik.values.image ? formik.values.image.name : ''} fullWidth disabled label="Image" />
                    </Clickable>
                    <Button
                      ref={thumbnailFileRef}
                      variant="contained"
                      component="label"
                      disableElevation
                      style={{ borderRadius: '0px 10px 10px 0px', height: '51px' }}
                    >
                      Upload
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        id="doc"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            formik.setFieldValue("image", e.target.files[0])
                          }
                          else {
                            formik.setFieldValue("image", null)
                          }
                        }}
                      />
                    </Button>
                  </Grid>
                  {isEditMode && <Grid item xs={12}>
                    <Link
                      href={formik.values.image?.url}
                      target="_blank"
                      underline="none"
                      sx={{ fontSize: "10px", marginTop: "2px", marginBottom: "14px", marginLeft: '5px', display: 'block' }}
                    >
                      {formik.values.image?.url && formik.values.image?.name}
                    </Link>
                  </Grid>}
                </Grid>
                <Grid item xs={12}>
                  <CustomField name="video_url" label="Video URL" />
                </Grid>
                {/* {<Grid item xs={6}>
                  <CustomMultiSelect
                    label="Lead Investors"
                    name="lead_investor_id"
                    options={venturePartnerList}
                  />
                </Grid>} */}
                <Grid item xs={6}>
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Is Featured</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formik.values.is_featured}
                      onChange={(event) => formik.setFieldValue("is_featured", event.target.value)}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="no" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Stack direction="row" spacing={2}>
                  <CustomButton type="submit" loading={isAddArticleLoading || isUpdateArticleLoading}>Submit</CustomButton>
                </Stack>
              </Grid>
            </Card>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}

export default AddStartupComponent;