import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { verifyToken, RetryOnError } from 'utils/VerifyToken';


export const FetchDashboardData = (dasboard_data = "", mode = "") => {
  return useQuery(['dashboard-listing' + dasboard_data],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/admins?type=${dasboard_data}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      // if(mode=="options"){
      //   return data.data.data.map((dealflow)=>({label: dealflow.values[dealflow.values.length-1].name, value: dealflow._id}))
      // }
      return data.data.data
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const FetchDashboardDealsData = (mode = "") => {
  return useQuery(['dashboard-listing-deals-data'],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/deals`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      // if(mode=="options"){
      //   return data.data.data.map((dealflow)=>({label: dealflow.values[dealflow.values.length-1].name, value: dealflow._id}))
      // }
      // ({data: data.data.data, total: data.data.count, limit:data.data.limit})
      return data.data
    },
    refetchOnWindowFocus: true,
    // staleTime: Infinity,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchDashboardFundData = (mode = "") => {
  return useQuery(['dashboard-Fundlisting'],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/syndicate/list?field=syndicate_name&sort=1`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      // if(mode=="options"){
      //   return data.data.data.map((dealflow)=>({label: dealflow.values[dealflow.values.length-1].name, value: dealflow._id}))
      // }
      return data.data.data
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


// export const ExportDashboardBankReconcillationData = () => {
//   return useQuery(['dashboard-fetchDashboard-bank-reconcillation-data'], 
//     async() => {
//       return await axios({
//         method: 'get',
//         url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/syndicate-bank-details?type=excel_report`,
//         headers: { 
//           'Authorization': `Bearer ${Cookies.get('jwt')}`
//         },
//       }) 
//   }, {
//     select: (data)=>{
//       // if(mode=="options"){
//       //   return data.data.data.map((dealflow)=>({label: dealflow.values[dealflow.values.length-1].name, value: dealflow._id}))
//       // }
//       return data.data
//     },
//     refetchOnWindowFocus: true,
//      retry: (failureCount, error)=>{
//       return RetryOnError(failureCount, error)
//     },
//     onError: (err)=>{
//       return verifyToken(err)
//     },
//   })
// }

export const ExportDashboardBankReconcillationData = () => {
  return useMutation(
    (payload) => {
      // console.log(payload, 'payloadpayloadpayloadpayload');
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/syndicate-bank-details?type=excel_report`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`
        }
      });
    },
    {
      select: (data) => {
        return data.data
      },
      refetchOnWindowFocus: true,
      retry: 0,
      onError: (err) => {
        // Handle errors or perform actions on error if needed
        console.error('Error generating documents', err);
        throw err; // Re-throw the error for further handling if necessary
      }
    }
  );
};

export const FetchNotificationsData = () => {
  return useQuery(['FetchNotificationsData-FetchNotificationsData'],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/notification?page=${1}&read_status=No`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      // if(mode=="options"){
      //   return data.data.data.map((dealflow)=>({label: dealflow.values[dealflow.values.length-1].name, value: dealflow._id}))
      // }
      return data.data.data
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}