import { useTheme } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Box, Pagination, Typography, Stack } from '@mui/material';
import { StyledTableCell, StyledTableRow } from 'ui-component/TableStyles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Clickable from 'utils/Clickable';
import { Tooltip } from '@mui/material';
import Cookies from 'js-cookie';
import { convertToIndianCurrencyWords } from './conversionUtils';
import { Drafts, Mail } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles({
    customTable: {
        '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
        '& .MuiTableCell-head': {
            color: 'white',
            backgroundColor: '#0000FF'
        }
    }
});

const AsyncTableListing = ({
    title = '',
    titleContainer,
    columns,
    data,
    actions: Actions,
    InvestmentActions: InvestmentActions,
    cutomLink: CustomLink,
    page,
    setPage,
    count,
    tableStyle = { minWidth: 700 },
    footerSummary,
    unitAction:UnitAction,
    sort,
    setSort,
    limit,
    setLimit,
    pagination = true
}) => {
    // console.log(data,"0000000000000000000")
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const classes = useStyles();
    const checkAllRef = useRef(false);
    // console.log(titleContainer)
    const handleChange = () => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = checkAllRef.current.checked;
        });
    };
    // console.log(data, 'Trannsactionbwiuebiwrgvbirbvb');
    return (
        <Paper>
            {title && (
                <Box
                    sx={{
                        paddingLeft: '8px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <h2 style={{ margin: 0, paddingBottom: '8px' }}>{title}</h2>
                    {titleContainer && titleContainer}
                </Box>
            )}
            <TableContainer sx={{ borderRadius: `${customization.borderRadius}px`, maxHeight: 500 }}>
                <PerfectScrollbar style={{height: '100%',maxHeight: 500, overflowX: 'hidden'}}>
                <Table sx={tableStyle} className={`${classes.customTable} deal-table `} aria-label="customized table" stickyHeader>
                    <TableHead className="stickyTableHead">
                        <TableRow
                            sx={{
                                '& .MuiTableCell-sizeMedium': {
                                    padding: '10px 16px 10px 16px' // <-- arbitrary value
                                }
                            }}
                        >
                            {columns.map((column, index) => (
                                <StyledTableCell
                                    key={index}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        position: index === column.fixed_row_index ? 'sticky' : 'relative',
                                        left: index === column.fixed_row_index ? 0 : 'auto',
                                        zIndex: index === column.fixed_row_index ? 2 : 'auto',
                                        backgroundColor: '#0000FF'
                                    }}
                                    padding={'normal'}
                                >
                                    {column.label != 'Checkbox' ? (
                                        Array.isArray(column.label) ? (
                                            <div>
                                                {column.label.map((label, labelIndex) => (
                                                    <span key={labelIndex}>
                                                        {label} <br />
                                                    </span>
                                                ))}
                                            </div>
                                        ) : (
                                            column.label
                                        )
                                    ) : (
                                        <div>
                                            {Cookies.get('is_super_admin') == 'true' && (
                                                <input
                                                    type="checkbox"
                                                    name="primery_checked"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    ref={checkAllRef}
                                                    className="cursor-pointer"
                                                />
                                            )}
                                            {/* <span ><br/ >{`LP Code	`}</span> */}
                                        </div>
                                    )}
                                    {column.sort && (
                                        <IconButton
                                            onClick={() => {
                                                setSort((oldSort) => {
                                                    if (oldSort == 1) return -1;
                                                    else return 1;
                                                });
                                                setPage(1);
                                            }}
                                            style={{ position: 'relative', top: '-2px' }}
                                        >
                                            {sort == -1 && <SouthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                            {/* descending sorting */}
                                            {sort == 1 && <NorthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                            {/* assending sorting */}
                                            {/* {sort==0 && <NorthIcon style={{fontSize: '14px', color: '#999'}} />}  */} {/* No sorting */}
                                        </IconButton>
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <StyledTableRow key={row['_id']}>
                                {Object.keys(columns).map((_, index) => {
                                    if (columns[index].fixed) {
                                        const isStickyColumn = columns[index].fixed_column_index;
                                        return (
                                            <StyledTableCell
                                                key={index}
                                                align={columns[index].align}
                                                style={{
                                                    position: isStickyColumn ? 'sticky' : 'static',
                                                    left: isStickyColumn ? 0 : 'auto',
                                                    zIndex: isStickyColumn ? 0 : 'auto'
                                                }}
                                            >
                                                {row[columns[index].type]}
                                            </StyledTableCell>
                                        );
                                    }
                                    else if (columns[index]?.type == 'transaction_type'){
                                        return <StyledTableCell key={index} align="center">
                                            {typeof (row[columns[index].type]) == 'string' && (row[columns[index].type])?.toLowerCase()?.includes('cr') ? 'Deposit' : (row[columns[index].type])?.toLowerCase()?.includes('invest') ? "Invest": 'Refund' }
                                        </StyledTableCell>
                                    }
                                    else if (columns[index]?.type == 'description'){
                                        return <StyledTableCell key={index} align="left">
                                            <Tooltip title={row[columns[index]?.type]}>
                                                {row[columns[index]?.type] != undefined && row[columns[index]?.type]?.length > 55 ? (row[columns[index]?.type])?.slice(0, 55) + '...' : (row[columns[index]?.type])}
                                            </Tooltip>
                                        </StyledTableCell>
                                    }
                                    else if (Actions && columns[index].type == 'actions') {
                                        return (
                                            <StyledTableCell key={index} align="right">
                                                <Actions row={row} />
                                            </StyledTableCell>
                                        );
                                    }else if (columns[index].type == 'documents') {
                                        return (
                                            <StyledTableCell key={index} align="right">
                                                <InvestmentActions row={row} />
                                            </StyledTableCell>
                                        );
                                    }
                                    else if (Array.isArray(columns[index].type)) {
                                        return (
                                            <React.Fragment>
                                                {/* <StyledTableRow key={index}> */}
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    {row[columns[index].type[0]]} <br /> {row[columns[index].type[1]]}
                                                </StyledTableCell>
                                                {/* </StyledTableRow> */}
                                                {/* <StyledTableRow key={`row-${row['_id']}-2`}>
                                             <StyledTableCell key={`cell-${index}-0`} align={columns[index].align} style={{border: 'none'}}>
                                              {row[columns[index].type[1]]}
                                            </StyledTableCell>
                                            </StyledTableRow> */}
                                            </React.Fragment>
                                        );
                                    } else if (columns[index].type == 'checkbox') {
                                        if (!(row.received_amount > 0 && (row.remaining_amount == 0 || row.remaining_amount == null))) {
                                            return (
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    <Stack alignItems={'center'}>
                                                        {Cookies.get('is_super_admin') == 'true' && (
                                                            <input
                                                                type="checkbox"
                                                                name="checked"
                                                                color="primary"
                                                                className="ElementCheck2 cursor-pointer"
                                                                value={row._id}
                                                            />
                                                        )}
                                                        {/* <br /> {row.lp_code} */}
                                                        {row.is_call_email_sent == 'Yes' && (
                                                            <Tooltip sx={{ cursor: 'pointer' }} title={`Call for Money Email Sent on ${row.last_email_sent_format}`}>
                                                                <Drafts width={2} height={2} />
                                                            </Tooltip>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                            );
                                        } else {
                                            return (
                                                //         <StyledTableCell key={index} align={columns[index].align}>
                                                //         <input
                                                //               type="checkbox"
                                                //               name="checked"
                                                //               color="primary"
                                                //               className='ElementCheck2'
                                                //               value={row._id}
                                                //               disabled={row.is_trans_added === "Yes"}
                                                //               checked={row.is_trans_added === "Yes"}
                                                //           />

                                                //   </StyledTableCell>
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    -
                                                </StyledTableCell>
                                            );
                                        }
                                    } else if (columns[index].type == 'managementfees') {
                                        return (
                                            <StyledTableCell key={index} align={columns[index].align}>
                                                {row.fees_management?.management_fee?.final_fee
                                                    ? row.fees_management?.management_fee?.final_fee
                                                    : '-'}
                                            </StyledTableCell>
                                        );
                                    } else if (columns[index].type == 'placementfees') {
                                        return (
                                            <StyledTableCell key={index} align={columns[index].align}>
                                                {row.fees_management?.distribution_fee?.final_fee
                                                    ? row.fees_management?.distribution_fee?.final_fee
                                                    : '-'}
                                            </StyledTableCell>
                                        );
                                    } else if (columns[index].type == 'operatingfees') {
                                        return (
                                            <StyledTableCell key={index} align={columns[index].align}>
                                                {row.fees_management?.operating_fee?.final_fee
                                                    ? row.fees_management?.operating_fee?.final_fee
                                                    : '-'}
                                            </StyledTableCell>
                                        );
                                    } else if (columns[index].type == 'unit_class_name') {
                                        return (
                                            <StyledTableCell key={index} align={columns[index].align}>
                                               <UnitAction row={row} />
                                            </StyledTableCell>
                                        );
                                    } 
                                    else if (columns[index].type == 'lead_investor_names') {
                                        return (
                                            <StyledTableCell key={index} align={columns[index].align}>
                                               {row[columns[index].type]}
                                            </StyledTableCell>
                                        );
                                    } 
                                     else if (columns[index].type == 'DrawAmount') {
                                        return (
                                            <Tooltip
                                                title={convertToIndianCurrencyWords(
                                                    row.fees_management?.drawdown_amount_ref
                                                        ? row.fees_management?.drawdown_amount_ref
                                                        : '-'
                                                )}
                                            >
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    {row.fees_management?.drawdown_amount_ref
                                                        ? row.fees_management?.drawdown_amount_ref
                                                        : '-'}
                                                </StyledTableCell>
                                            </Tooltip>
                                        );
                                    } else if (columns[index].type == 'fundutilization') {
                                        return (
                                            <Tooltip
                                                title={convertToIndianCurrencyWords(
                                                    row.fees_management?.fund_utilization ? row.fees_management?.fund_utilization : '-'
                                                )}
                                            >
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    {row.fees_management?.fund_utilization ? row.fees_management?.fund_utilization : '-'}
                                                </StyledTableCell>
                                            </Tooltip>
                                        );
                                    } else {
                                        if (columns[index].link) {
                                            if (columns[index].trim) {
                                                return (
                                                    <StyledTableCell key={index} align={columns[index].align}>
                                                        <CustomLink row={row}>
                                                            {row[columns[index].type].length > columns[index].trim
                                                                ? `${row[columns[index].type].substring(0, columns[index].trim - 3)}...`
                                                                : row[columns[index].type]}
                                                        </CustomLink>
                                                    </StyledTableCell>
                                                );
                                            }
                                            return (
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    <CustomLink row={row}>{row[columns[index].type]}</CustomLink>
                                                </StyledTableCell>
                                            );
                                        } else if (columns[index].status) {
                                            let chipInfo = { label: 'Pending', color: '#d4a72d', backgroundColor: '#fef8e8' };
                                            if (row[columns[index].type] == 'Approved') {
                                                chipInfo = { label: 'Approved', color: '#60cb5e', backgroundColor: '#ebfaeb' };
                                            } else if (row[columns[index].type] == 'KYC In Review') {
                                                chipInfo = { label: 'In Review', color: '#f68345', backgroundColor: '#f8dac6' };
                                            } else if (row[columns[index].type] == 'KYC Correction') {
                                                chipInfo = { label: 'Correction', color: '#f46363', backgroundColor: '#fdebeb' };
                                            } else if (row[columns[index].type] == 'KYC Details Submitted') {
                                                chipInfo = { label: 'Submitted', color: '#39739d', backgroundColor: '#e1ecf4' };
                                            }
                                            return (
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    {/* <CustomLink row={row}>{row.color_code}</CustomLink> */}
                                                    <Chip
                                                        size="small"
                                                        label={chipInfo.label}
                                                        sx={{
                                                            backgroundColor: chipInfo.backgroundColor,
                                                            color: chipInfo.color,
                                                            border: 'none',
                                                            minWidth: '100px',
                                                            fontSize: '12px',
                                                            borderRadius: '4px',
                                                            fontWeight: 700,
                                                            borderWidth: '0.5px',
                                                            borderStyle: 'solid',
                                                            borderColor: chipInfo.color
                                                        }}
                                                    />
                                                </StyledTableCell>
                                            );
                                        } else if (columns[index].tooltip == true) {
                                            if (columns[index].trim) {
                                                return (
                                                    <StyledTableCell key={index} align={columns[index].align}>
                                                        {row[columns[index].type].length > columns[index].trim
                                                            ? `${row[columns[index].type].substring(
                                                                  0,
                                                                  parseInt(columns[index].trim) - 3
                                                              )}...`
                                                            : row[columns[index].type]}
                                                    </StyledTableCell>
                                                );
                                            }
                                            return (
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    {row[columns[index].type]}
                                                </StyledTableCell>
                                            );
                                        }
                                        if (columns[index].trim) {
                                            if (row[columns[index].type]) {
                                                return (
                                                    <StyledTableCell key={index} align={columns[index].align}>
                                                        {row[columns[index].type].length > columns[index].trim
                                                            ? `${row[columns[index].type].substring(
                                                                  0,
                                                                  parseInt(columns[index].trim) - 3
                                                              )}...`
                                                            : row[columns[index].type]}
                                                    </StyledTableCell>
                                                );
                                            }
                                        } else if (columns[index].price_tooltip) {
                                            return (
                                                <StyledTableCell key={index} align={columns[index].align}>
                                                    <Tooltip
                                                        title={convertToIndianCurrencyWords(
                                                            row[columns[index].tooltip_amount]?.$numberDecimal ||
                                                                row[columns[index].tooltip_amount]
                                                        )}
                                                    >
                                                        {row[columns[index].type]}
                                                    </Tooltip>
                                                </StyledTableCell>
                                            );
                                        }

                                        if (columns[index].highlight == true && columns[index].price_tooltip_color) {
                                            // return (
                                            //     <StyledTableCell
                                            //         key={index}
                                            //         align={columns[index].align}
                                            //         style={
                                            //             row.is_unallocated == 'Yes' ? { fontWeight: 'bold', color: 'rgb(248 144 97)' } : {}
                                            //         }
                                            //     >
                                            //         {row[columns[index].type]}
                                            //     </StyledTableCell>
                                            // );
                                            return (
                                                <StyledTableCell
                                                    key={index}
                                                    align={columns[index].align}
                                                    // style={
                                                    //     row.is_unallocated == 'Yes' ? { fontWeight: 'bold', color: 'rgb(248 144 97)' } : {}
                                                    // }
                                                >
                                                    <Tooltip
                                                        title={convertToIndianCurrencyWords(
                                                            row[columns[index].tooltip_amount]?.$numberDecimal ||
                                                                row[columns[index].tooltip_amount]
                                                        )}
                                                    >
                                                        {row[columns[index].type]}
                                                    </Tooltip>
                                                </StyledTableCell>
                                            );
                                        }

                                        return (
                                            <StyledTableCell key={index} align={columns[index].align}>
                                                {row[columns[index].type]}
                                            </StyledTableCell>
                                        );
                                    }
                                })}
                            </StyledTableRow>
                        ))}
                        {data.length < 1 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
                                    <Typography variant="h4">No Records Found!</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
                </PerfectScrollbar>
            </TableContainer>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: !!footerSummary ? 'space-between' : 'flex-end',
                    marginTop: '16px',
                    marginLeft: '10px'
                }}
            >
                {!!footerSummary && footerSummary}
                {pagination && (
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={limit}
                        label="Row per page"
                        size="small"
                        onChange={(event) => {
                            setLimit(event.target.value);
                            setPage(1);
                        }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                )}
                {pagination && <Pagination page={page} count={Math.ceil(count / limit)} onChange={(e, value) => setPage(value)} />}
            </Box>
        </Paper>
    );
};
AsyncTableListing.propTypes = {
    title: PropTypes.node,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    actions: PropTypes.func
};
export default AsyncTableListing;
