import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import InvestorReducer from './InvestorReducer';
import permissionsReducer from './permissionsReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    investor: InvestorReducer,
    permissions: permissionsReducer,

});

export default reducer;
