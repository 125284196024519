import { useState, useEffect } from 'react';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { IconEdit} from '@tabler/icons';
import Card from 'ui-component/cards/Card'
import { FetchAllMasterTemplate } from 'api';
import { useNavigate, useParams } from "react-router-dom";
import Loading from 'views/Loading';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Clickable from 'utils/Clickable';
import { Grid, Box, Tooltip } from '@mui/material';
import TextField from 'ui-component/TextField';
import { useTheme } from '@mui/material/styles';
import CustomButton from 'ui-component/CustomButton';

const columns = [
 
    {
      label: 'Template Name',
      minWidth: 200,
      align: 'left',
      type: 'template_name',
      trim: 60,
    },
    {
      label: 'Created At',
      minWidth: 90,
      align: 'left',
      type: 'created'
    },
    {
      label: 'Updated At',
      minWidth: 90,
      align: 'left',
      type: 'updated'
    },
    {
      label: 'Created By',
      minWidth: 90,
      align: 'left',
      type: 'created_by'
    },
    {
      label: 'Updated By',
      minWidth: 90,
      align: 'left',
      type: 'updated_by'
    },
    {
      label: 'Actions',
      minWidth: 160,
      align: 'right',
      type: 'actions'
    },
  ];

    export default function  ChecklistListing(){

      const [search, setSearch] = useState('');
      const [stage, setStage] = useState('');
      const [searchStatus, setSearchStatus] = useState(false);
      const [page, setPage] = useState(1);
      const [reset, setreset] = useState(false)
      const navigate = useNavigate();
      const [sort, setSort] = useState(-1);
      const [limit, setLimit] = useState(20);
    const theme = useTheme();
    
    const { isLoading: isAllMasterTemplateLoading, isRefetching: isAllMasterTemplateRefetching, data: AllMasterTemplate, refetch: refetchListing } = FetchAllMasterTemplate({ page, search: search.trim(), sort, limit })
      // console.log(AllMasterTemplate,"AllMasterTemplateAllMasterTemplateAllMasterTemplateAllMasterTemplate")

    useEffect(() => {
      refetchListing()
    }, [reset])


    const StartupActions = ({ row }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'right', columnGap: "12px" }}>    
            <Clickable style={{ display: 'flex', alignItems: 'right' }} onClick={() => navigate(`/checklist/checklist-edit/${row._id}`)}>
              <Tooltip title="Edit">
                <div style={{ display: 'flex', alignItems: 'right' }}>
                  <IconEdit stroke={1.5} size="1.4rem" />
                </div>
              </Tooltip>
            </Clickable>
          </div>
        );
      }
    return (
        <> 
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title="Master Checklists" shouldGoBack={false} />
            </Box>
            <Card>
            <Grid container direction="row" columnSpacing={1} rowSpacing={2} mb={1}>
                <Grid item sm={3} xs={8}>
            <TextField
              label="Search"
              value={search}
              autoComplete='off'
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  refetchListing()
                  if (search) {
                    setSearchStatus(true)
                  }
                }
              }}
              onChange={(event) => {
                setSearch(event.target.value)
                setSearchStatus(false)
              }}
              size="small"
              sx={{
                '& input': {
                  background: theme.palette.background.paper,
                },
                '& input:valid + fieldset': {
                  borderColor: theme.palette.secondary[200],
                  borderWidth: 0.5,
                },
                '& input:valid:hover + fieldset': {
                  borderColor: '#4050b5',
                  borderWidth: 1,
                },
                '& input:valid:focus + fieldset': {
                  borderColor: '#4050b5',
                  padding: '4px !important',
                  borderWidth: 1,
                  borderLeftWidth: 6,
                },
              }}
            />
                </Grid>
                <Grid item sm={2} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} className='search-btn'>
                    <CustomButton
                    size="medium"
                    sx={{ height: '40px', textAlign:'center', display:'block' }}
                    color={(searchStatus && search !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                    onClick={() => {
                        if (searchStatus && search !== '') {
                        setSearch('')
                        setStage('')
                        setreset(!reset)
                        } else {
                        refetchListing()
                        }
                        setSearchStatus((oldVal) => !oldVal)

                    }}
                    fullWidth
                    >
                    {(searchStatus && search !== '') ? "Reset" : "Search"}
                    </CustomButton>
                </Grid>
                <Grid item sm={7} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <CustomButton size="medium" onClick={() => navigate(`/checklist/checklist-create`)} sx={{ height: '40px' }}>Add Checklist</CustomButton>
                </Grid>
                </Grid>
                    {isAllMasterTemplateLoading ? <Loading />
                    : <AsyncTableListing
                        title=""
                        columns={columns}
                        data={AllMasterTemplate.data}
                        actions={StartupActions}
                        page={page}
                        setPage={setPage}
                        count={AllMasterTemplate.count}
                        sort={sort}
                        setSort={setSort}
                        limit={limit}
                        setLimit={setLimit}
                        
                    />}
            </Card>
        </>
    )
}

