export const startupData = [
  {
      "id": "1",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "2",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "3",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "4",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "5",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "6",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "7",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "8",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "9",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "10",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  },
  {
      "id": "11",
      "company": "Realtime Pvt Ltd",
      "founder": "Pranay Mathur",
      "email": "Pranay.Mathur@wedigtech.com",
      "mobile": "9571692695",
      "stage": "Pre-launch",
      "status": "Approved"
  }
]

export const showcaseData = [
    {
        "id": "1",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "2",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "3",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "4",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "5",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "6",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "7",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "8",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "9",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "10",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
    {
        "id": "11",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "currentRound": "5 Cr",
        "stage": "Pre-launch",
        "status": "Approved"
    },
  ]
  export const bankDetailsData = [
    {
        "bank_id": "1",
        "bank_name": "Axis Bank",
        "branch": "Mansarovar",
        "account_number": "1223445676",
        "ifsc": "MAN08965",
        "account_holder": "Realtime",
        "mobile_number": "9571692695",
        "startup": "Single",
        // "status":"Active"
    },
    {
        "bank_id": "1",
        "bank_name": "HDFC",
        "branch": "Mansarovar",
        "account_number": "1223445676",
        "ifsc": "MAN08965",
        "account_holder": "Realtime",
        "mobile_number": "9571692695",
        "startup": "Multiple",
        // "status":"Active"
    },
    {
        "bank_id": "1",
        "bank_name": "IDBI",
        "branch": "Mansarovar",
        "account_number": "1223445676",
        "ifsc": "MAN08965",
        "account_holder": "Realtime",
        "mobile_number": "9571692695",
        "startup": "Multiple",
        // "status":"In Active"
    },
    {
        "bank_id": "1",
        "bank_name": "ICICI",
        "branch": "Mansarovar",
        "account_number": "1223445676",
        "ifsc": "MAN08965",
        "account_holder": "Realtime",
        "mobile_number": "9571692695",
        "startup": "Multiple",
        // "status":"Active"
    },
  ]
export const CompletedDeals = [
    {
        "id": "1",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "2",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "3",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "4",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "5",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "6",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "7",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "8",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "9",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "10",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
    {
        "id": "11",
        "company": "Realtime Pvt Ltd",
        "founder": "Pranay Mathur",
        "dealType": "Pre-Seed",
        "investedRound": "5 Cr",
        "stage": "Pre-launch",
        "investorCount": 3
    },
  ]

export const investedInvestor = {
    stats : [
        // {
        //     type: 'startup_id',
        //     label: 'Startup ID',
        // },
        // {
        //     type: 'current_round',
        //     label: 'Current Round',
        // },
        {
            type: 'company_name',
            label: 'Startup Name',
        },
        {
            type: 'random_deal_id',
            label: 'Deal ID',
        },
        // {
        //     type: 'deal_status',
        //     label: 'Status',
        // },
        // {
        //     type: 'stage',
        //     label: 'Stage',
        // }
    ],
    data: {
        startup_id: 1,
        current_round: "5 Cr",
        company_name: "Realtime Pvt Ltd",
        deal_type: "Pre-Seed",
        deal_status: "Under review",
        stage: "Pre-launch",
        rows: [
            {
                id: 141,
                investorName: "John Doe",
                amount: "1 Cr"
            },
            {
                id: 534,
                investorName: "Tommy Alkins",
                amount: "1 Cr"
            },
            {
                id: 171,
                investorName: "Bob Thingum",
                amount: "1 Cr"
            },
            {
                id: 857,
                investorName: "Jane Alice",
                amount: "2 Cr"
            },
            {
                id: 754,
                investorName: "Jennifer Alice",
                amount: "2 Cr"
            },
        ]
    },
    columns : [
        {
            label: 'Investor ID', 
            minWidth: 130,
            align: 'left',
        },
        {
            label: 'Investor Name', 
            minWidth: 130,
            align: 'left',
        },
        {
            label: 'Want to Invest', 
            minWidth: 130,
            align: 'left',
        },
    ]
}

// export const countryCode = [
//     {
//       label: "+7 840",
//       value: "+7 840"
//     },
//     {
//       label: "+93",
//       value: "+93"
//     },
//     {
//       label: "+355",
//       value: "+355"
//     },
//     {
//       label: "+213",
//       value: "+213"
//     },
//     {
//       label: "+1 684",
//       value: "+1 684"
//     },
//     {
//       label: "+376",
//       value: "+376"
//     },
//     {
//       label: "+244",
//       value: "+244"
//     },
//     {
//       label: "+1 264",
//       value: "+1 264"
//     },
//     {
//       label: "+1 268",
//       value: "+1 268"
//     },
//     {
//       label: "+54",
//       value: "+54"
//     },
//     {
//       label: "+374",
//       value: "+374"
//     },
//     {
//       label: "+297",
//       value: "+297"
//     },
//     {
//       label: "+247",
//       value: "+247"
//     },
//     {
//       label: "+61",
//       value: "+61"
//     },
//     {
//       label: "+672",
//       value: "+672"
//     },
//     {
//       label: "+43",
//       value: "+43"
//     },
//     {
//       label: "+994",
//       value: "+994"
//     },
//     {
//       label: "+1 242",
//       value: "+1 242"
//     },
//     {
//       label: "+973",
//       value: "+973"
//     },
//     {
//       label: "+880",
//       value: "+880"
//     },
//     {
//       label: "+1 246",
//       value: "+1 246"
//     },
//     {
//       label: "+1 268",
//       value: "+1 268"
//     },
//     {
//       label: "+375",
//       value: "+375"
//     },
//     {
//       label: "+32",
//       value: "+32"
//     },
//     {
//       label: "+501",
//       value: "+501"
//     },
//     {
//       label: "+229",
//       value: "+229"
//     },
//     {
//       label: "+1 441",
//       value: "+1 441"
//     },
//     {
//       label: "+975",
//       value: "+975"
//     },
//     {
//       label: "+591",
//       value: "+591"
//     },
//     {
//       label: "+387",
//       value: "+387"
//     },
//     {
//       label: "+267",
//       value: "+267"
//     },
//     {
//       label: "+55",
//       value: "+55"
//     },
//     {
//       label: "+246",
//       value: "+246"
//     },
//     {
//       label: "+1 284",
//       value: "+1 284"
//     },
//     {
//       label: "+673",
//       value: "+673"
//     },
//     {
//       label: "+359",
//       value: "+359"
//     },
//     {
//       label: "+226",
//       value: "+226"
//     },
//     {
//       label: "+257",
//       value: "+257"
//     },
//     {
//       label: "+855",
//       value: "+855"
//     },
//     {
//       label: "+237",
//       value: "+237"
//     },
//     {
//       label: "+1",
//       value: "+1"
//     },
//     {
//       label: "+238",
//       value: "+238"
//     },
//     {
//       label: "+ 345",
//       value: "+ 345"
//     },
//     {
//       label: "+236",
//       value: "+236"
//     },
//     {
//       label: "+235",
//       value: "+235"
//     },
//     {
//       label: "+56",
//       value: "+56"
//     },
//     {
//       label: "+86",
//       value: "+86"
//     },
//     {
//       label: "+61",
//       value: "+61"
//     },
//     {
//       label: "+61",
//       value: "+61"
//     },
//     {
//       label: "+57",
//       value: "+57"
//     },
//     {
//       label: "+269",
//       value: "+269"
//     },
//     {
//       label: "+242",
//       value: "+242"
//     },
//     {
//       label: "+243",
//       value: "+243"
//     },
//     {
//       label: "+682",
//       value: "+682"
//     },
//     {
//       label: "+506",
//       value: "+506"
//     },
//     {
//       label: "+385",
//       value: "+385"
//     },
//     {
//       label: "+53",
//       value: "+53"
//     },
//     {
//       label: "+599",
//       value: "+599"
//     },
//     {
//       label: "+537",
//       value: "+537"
//     },
//     {
//       label: "+420",
//       value: "+420"
//     },
//     {
//       label: "+45",
//       value: "+45"
//     },
//     {
//       label: "+246",
//       value: "+246"
//     },
//     {
//       label: "+253",
//       value: "+253"
//     },
//     {
//       label: "+1 767",
//       value: "+1 767"
//     },
//     {
//       label: "+1 809",
//       value: "+1 809"
//     },
//     {
//       label: "+670",
//       value: "+670"
//     },
//     {
//       label: "+56",
//       value: "+56"
//     },
//     {
//       label: "+593",
//       value: "+593"
//     },
//     {
//       label: "+20",
//       value: "+20"
//     },
//     {
//       label: "+503",
//       value: "+503"
//     },
//     {
//       label: "+240",
//       value: "+240"
//     },
//     {
//       label: "+291",
//       value: "+291"
//     },
//     {
//       label: "+372",
//       value: "+372"
//     },
//     {
//       label: "+251",
//       value: "+251"
//     },
//     {
//       label: "+500",
//       value: "+500"
//     },
//     {
//       label: "+298",
//       value: "+298"
//     },
//     {
//       label: "+679",
//       value: "+679"
//     },
//     {
//       label: "+358",
//       value: "+358"
//     },
//     {
//       label: "+33",
//       value: "+33"
//     },
//     {
//       label: "+596",
//       value: "+596"
//     },
//     {
//       label: "+594",
//       value: "+594"
//     },
//     {
//       label: "+689",
//       value: "+689"
//     },
//     {
//       label: "+241",
//       value: "+241"
//     },
//     {
//       label: "+220",
//       value: "+220"
//     },
//     {
//       label: "+995",
//       value: "+995"
//     },
//     {
//       label: "+49",
//       value: "+49"
//     },
//     {
//       label: "+233",
//       value: "+233"
//     },
//     {
//       label: "+350",
//       value: "+350"
//     },
//     {
//       label: "+30",
//       value: "+30"
//     },
//     {
//       label: "+299",
//       value: "+299"
//     },
//     {
//       label: "+1 473",
//       value: "+1 473"
//     },
//     {
//       label: "+590",
//       value: "+590"
//     },
//     {
//       label: "+1 671",
//       value: "+1 671"
//     },
//     {
//       label: "+502",
//       value: "+502"
//     },
//     {
//       label: "+224",
//       value: "+224"
//     },
//     {
//       label: "+245",
//       value: "+245"
//     },
//     {
//       label: "+595",
//       value: "+595"
//     },
//     {
//       label: "+509",
//       value: "+509"
//     },
//     {
//       label: "+504",
//       value: "+504"
//     },
//     {
//       label: "+852",
//       value: "+852"
//     },
//     {
//       label: "+36",
//       value: "+36"
//     },
//     {
//       label: "+354",
//       value: "+354"
//     },
//     {
//       label: "+91",
//       value: "+91"
//     },
//     {
//       label: "+62",
//       value: "+62"
//     },
//     {
//       label: "+98",
//       value: "+98"
//     },
//     {
//       label: "+964",
//       value: "+964"
//     },
//     {
//       label: "+353",
//       value: "+353"
//     },
//     {
//       label: "+972",
//       value: "+972"
//     },
//     {
//       label: "+39",
//       value: "+39"
//     },
//     {
//       label: "+225",
//       value: "+225"
//     },
//     {
//       label: "+1 876",
//       value: "+1 876"
//     },
//     {
//       label: "+81",
//       value: "+81"
//     },
//     {
//       label: "+962",
//       value: "+962"
//     },
//     {
//       label: "+7 7",
//       value: "+7 7"
//     },
//     {
//       label: "+254",
//       value: "+254"
//     },
//     {
//       label: "+686",
//       value: "+686"
//     },
//     {
//       label: "+965",
//       value: "+965"
//     },
//     {
//       label: "+996",
//       value: "+996"
//     },
//     {
//       label: "+856",
//       value: "+856"
//     },
//     {
//       label: "+371",
//       value: "+371"
//     },
//     {
//       label: "+961",
//       value: "+961"
//     },
//     {
//       label: "+266",
//       value: "+266"
//     },
//     {
//       label: "+231",
//       value: "+231"
//     },
//     {
//       label: "+218",
//       value: "+218"
//     },
//     {
//       label: "+423",
//       value: "+423"
//     },
//     {
//       label: "+370",
//       value: "+370"
//     },
//     {
//       label: "+352",
//       value: "+352"
//     },
//     {
//       label: "+853",
//       value: "+853"
//     },
//     {
//       label: "+389",
//       value: "+389"
//     },
//     {
//       label: "+261",
//       value: "+261"
//     },
//     {
//       label: "+265",
//       value: "+265"
//     },
//     {
//       label: "+60",
//       value: "+60"
//     },
//     {
//       label: "+960",
//       value: "+960"
//     },
//     {
//       label: "+223",
//       value: "+223"
//     },
//     {
//       label: "+356",
//       value: "+356"
//     },
//     {
//       label: "+692",
//       value: "+692"
//     },
//     {
//       label: "+596",
//       value: "+596"
//     },
//     {
//       label: "+222",
//       value: "+222"
//     },
//     {
//       label: "+230",
//       value: "+230"
//     },
//     {
//       label: "+262",
//       value: "+262"
//     },
//     {
//       label: "+52",
//       value: "+52"
//     },
//     {
//       label: "+691",
//       value: "+691"
//     },
//     {
//       label: "+1 808",
//       value: "+1 808"
//     },
//     {
//       label: "+373",
//       value: "+373"
//     },
//     {
//       label: "+377",
//       value: "+377"
//     },
//     {
//       label: "+976",
//       value: "+976"
//     },
//     {
//       label: "+382",
//       value: "+382"
//     },
//     {
//       label: "+1664",
//       value: "+1664"
//     },
//     {
//       label: "+212",
//       value: "+212"
//     },
//     {
//       label: "+95",
//       value: "+95"
//     },
//     {
//       label: "+264",
//       value: "+264"
//     },
//     {
//       label: "+674",
//       value: "+674"
//     },
//     {
//       label: "+977",
//       value: "+977"
//     },
//     {
//       label: "+31",
//       value: "+31"
//     },
//     {
//       label: "+599",
//       value: "+599"
//     },
//     {
//       label: "+1 869",
//       value: "+1 869"
//     },
//     {
//       label: "+687",
//       value: "+687"
//     },
//     {
//       label: "+64",
//       value: "+64"
//     },
//     {
//       label: "+505",
//       value: "+505"
//     },
//     {
//       label: "+227",
//       value: "+227"
//     },
//     {
//       label: "+234",
//       value: "+234"
//     },
//     {
//       label: "+683",
//       value: "+683"
//     },
//     {
//       label: "+672",
//       value: "+672"
//     },
//     {
//       label: "+850",
//       value: "+850"
//     },
//     {
//       label: "+1 670",
//       value: "+1 670"
//     },
//     {
//       label: "+47",
//       value: "+47"
//     },
//     {
//       label: "+968",
//       value: "+968"
//     },
//     {
//       label: "+92",
//       value: "+92"
//     },
//     {
//       label: "+680",
//       value: "+680"
//     },
//     {
//       label: "+970",
//       value: "+970"
//     },
//     {
//       label: "+507",
//       value: "+507"
//     },
//     {
//       label: "+675",
//       value: "+675"
//     },
//     {
//       label: "+595",
//       value: "+595"
//     },
//     {
//       label: "+51",
//       value: "+51"
//     },
//     {
//       label: "+63",
//       value: "+63"
//     },
//     {
//       label: "+48",
//       value: "+48"
//     },
//     {
//       label: "+351",
//       value: "+351"
//     },
//     {
//       label: "+1 787",
//       value: "+1 787"
//     },
//     {
//       label: "+974",
//       value: "+974"
//     },
//     {
//       label: "+262",
//       value: "+262"
//     },
//     {
//       label: "+40",
//       value: "+40"
//     },
//     {
//       label: "+7",
//       value: "+7"
//     },
//     {
//       label: "+250",
//       value: "+250"
//     },
//     {
//       label: "+685",
//       value: "+685"
//     },
//     {
//       label: "+378",
//       value: "+378"
//     },
//     {
//       label: "+966",
//       value: "+966"
//     },
//     {
//       label: "+221",
//       value: "+221"
//     },
//     {
//       label: "+381",
//       value: "+381"
//     },
//     {
//       label: "+248",
//       value: "+248"
//     },
//     {
//       label: "+232",
//       value: "+232"
//     },
//     {
//       label: "+65",
//       value: "+65"
//     },
//     {
//       label: "+421",
//       value: "+421"
//     },
//     {
//       label: "+386",
//       value: "+386"
//     },
//     {
//       label: "+677",
//       value: "+677"
//     },
//     {
//       label: "+27",
//       value: "+27"
//     },
//     {
//       label: "+500",
//       value: "+500"
//     },
//     {
//       label: "+82",
//       value: "+82"
//     },
//     {
//       label: "+34",
//       value: "+34"
//     },
//     {
//       label: "+94",
//       value: "+94"
//     },
//     {
//       label: "+249",
//       value: "+249"
//     },
//     {
//       label: "+597",
//       value: "+597"
//     },
//     {
//       label: "+268",
//       value: "+268"
//     },
//     {
//       label: "+46",
//       value: "+46"
//     },
//     {
//       label: "+41",
//       value: "+41"
//     },
//     {
//       label: "+963",
//       value: "+963"
//     },
//     {
//       label: "+886",
//       value: "+886"
//     },
//     {
//       label: "+992",
//       value: "+992"
//     },
//     {
//       label: "+255",
//       value: "+255"
//     },
//     {
//       label: "+66",
//       value: "+66"
//     },
//     {
//       label: "+670",
//       value: "+670"
//     },
//     {
//       label: "+228",
//       value: "+228"
//     },
//     {
//       label: "+690",
//       value: "+690"
//     },
//     {
//       label: "+676",
//       value: "+676"
//     },
//     {
//       label: "+1 868",
//       value: "+1 868"
//     },
//     {
//       label: "+216",
//       value: "+216"
//     },
//     {
//       label: "+90",
//       value: "+90"
//     },
//     {
//       label: "+993",
//       value: "+993"
//     },
//     {
//       label: "+1 649",
//       value: "+1 649"
//     },
//     {
//       label: "+688",
//       value: "+688"
//     },
//     {
//       label: "+1 340",
//       value: "+1 340"
//     },
//     {
//       label: "+256",
//       value: "+256"
//     },
//     {
//       label: "+380",
//       value: "+380"
//     },
//     {
//       label: "+971",
//       value: "+971"
//     },
//     {
//       label: "+44",
//       value: "+44"
//     },
//     {
//       label: "+1",
//       value: "+1"
//     },
//     {
//       label: "+598",
//       value: "+598"
//     },
//     {
//       label: "+998",
//       value: "+998"
//     },
//     {
//       label: "+678",
//       value: "+678"
//     },
//     {
//       label: "+58",
//       value: "+58"
//     },
//     {
//       label: "+84",
//       value: "+84"
//     },
//     {
//       label: "+1 808",
//       value: "+1 808"
//     },
//     {
//       label: "+681",
//       value: "+681"
//     },
//     {
//       label: "+967",
//       value: "+967"
//     },
//     {
//       label: "+260",
//       value: "+260"
//     },
//     {
//       label: "+255",
//       value: "+255"
//     },
//     {
//       label: "+263",
//       value: "+263"
//     }
//   ]


  export const countryCode =[

    {
        label: "+7 840",
        value: "+7 840"
     },
    {
      label: "+93",
      value: "+93"
    },
    {
      label: "+355",
      value: "+355"
    },
    {
      label: "+213",
      value: "+213"
    },
    {
      label: "+1 684",
      value: "+1 684"
    },
    {
      label: "+376",
      value: "+376"
    },
    {
      label: "+244",
      value: "+244"
    },
    {
      label: "+1 264",
      value: "+1 264"
    },
    {
      label: "+1 268",
      value: "+1 268"
    },
    {
      label: "+54",
      value: "+54"
    },
    {
      label: "+374",
      value: "+374"
    },
    {
      label: "+297",
      value: "+297"
    },
    {
      label: "+247",
      value: "+247"
    },
    {
      label: "+61",
      value: "+61"
    },
    {
      label: "+672",
      value: "+672"
    },
    {
      label: "+43",
      value: "+43"
    },
    {
      label: "+994",
      value: "+994"
    },
    {
      label: "+1 242",
      value: "+1 242"
    },
    {
      label: "+973",
      value: "+973"
    },
    {
      label: "+880",
      value: "+880"
    },
    {
      label: "+1 246",
      value: "+1 246"
    },
    {
      label: "+375",
      value: "+375"
    },
    {
      label: "+32",
      value: "+32"
    },
    {
      label: "+501",
      value: "+501"
    },
    {
      label: "+229",
      value: "+229"
    },
    {
      label: "+1 441",
      value: "+1 441"
    },
    {
      label: "+975",
      value: "+975"
    },
    {
      label: "+591",
      value: "+591"
    },
    {
      label: "+387",
      value: "+387"
    },
    {
      label: "+267",
      value: "+267"
    },
    {
      label: "+55",
      value: "+55"
    },
    {
      label: "+246",
      value: "+246"
    },
    {
      label: "+1 284",
      value: "+1 284"
    },
    {
      label: "+673",
      value: "+673"
    },
    {
      label: "+359",
      value: "+359"
    },
    {
      label: "+226",
      value: "+226"
    },
    {
      label: "+257",
      value: "+257"
    },
    {
      label: "+855",
      value: "+855"
    },
    {
      label: "+237",
      value: "+237"
    },
    {
      label: "+1",
      value: "+1"
    },
    {
      label: "+238",
      value: "+238"
    },
    {
      label: "+ 345",
      value: "+ 345"
    },
    {
      label: "+236",
      value: "+236"
    },
    {
      label: "+235",
      value: "+235"
    },
    {
      label: "+56",
      value: "+56"
    },
    {
      label: "+86",
      value: "+86"
    },
    {
      label: "+57",
      value: "+57"
    },
    {
      label: "+269",
      value: "+269"
    },
    {
      label: "+242",
      value: "+242"
    },
    {
      label: "+243",
      value: "+243"
    },
    {
      label: "+682",
      value: "+682"
    },
    {
      label: "+506",
      value: "+506"
    },
    {
      label: "+385",
      value: "+385"
    },
    {
      label: "+53",
      value: "+53"
    },
    {
      label: "+599",
      value: "+599"
    },
    {
      label: "+537",
      value: "+537"
    },
    {
      label: "+420",
      value: "+420"
    },
    {
      label: "+45",
      value: "+45"
    },
    {
      label: "+253",
      value: "+253"
    },
    {
      label: "+1 767",
      value: "+1 767"
    },
    {
      label: "+1 809",
      value: "+1 809"
    },
    {
      label: "+670",
      value: "+670"
    },
    {
      label: "+593",
      value: "+593"
    },
    {
      label: "+20",
      value: "+20"
    },
    {
      label: "+503",
      value: "+503"
    },
    {
      label: "+240",
      value: "+240"
    },
    {
      label: "+291",
      value: "+291"
    },
    {
      label: "+372",
      value: "+372"
    },
    {
      label: "+251",
      value: "+251"
    },
    {
      label: "+500",
      value: "+500"
    },
    {
      label: "+298",
      value: "+298"
    },
    {
      label: "+679",
      value: "+679"
    },
    {
      label: "+358",
      value: "+358"
    },
    {
      label: "+33",
      value: "+33"
    },
    {
      label: "+596",
      value: "+596"
    },
    {
      label: "+594",
      value: "+594"
    },
    {
      label: "+689",
      value: "+689"
    },
    {
      label: "+241",
      value: "+241"
    },
    {
      label: "+220",
      value: "+220"
    },
    {
      label: "+995",
      value: "+995"
    },
    {
      label: "+49",
      value: "+49"
    },
    {
      label: "+233",
      value: "+233"
    },
    {
      label: "+350",
      value: "+350"
    },
    {
      label: "+30",
      value: "+30"
    },
    {
      label: "+299",
      value: "+299"
    },
    {
      label: "+1 473",
      value: "+1 473"
    },
    {
      label: "+590",
      value: "+590"
    },
    {
      label: "+1 671",
      value: "+1 671"
    },
    {
      label: "+502",
      value: "+502"
    },
    {
      label: "+224",
      value: "+224"
    },
    {
      label: "+245",
      value: "+245"
    },
    {
      label: "+595",
      value: "+595"
    },
    {
      label: "+509",
      value: "+509"
    },
    {
      label: "+504",
      value: "+504"
    },
    {
      label: "+852",
      value: "+852"
    },
    {
      label: "+36",
      value: "+36"
    },
    {
      label: "+354",
      value: "+354"
    },
    {
      label: "+91",
      value: "+91"
    },
    {
      label: "+62",
      value: "+62"
    },
    {
      label: "+98",
      value: "+98"
    },
    {
      label: "+964",
      value: "+964"
    },
    {
      label: "+353",
      value: "+353"
    },
    {
      label: "+972",
      value: "+972"
    },
    {
      label: "+39",
      value: "+39"
    },
    {
      label: "+225",
      value: "+225"
    },
    {
      label: "+1 876",
      value: "+1 876"
    },
    {
      label: "+81",
      value: "+81"
    },
    {
      label: "+962",
      value: "+962"
    },
    {
      label: "+7 7",
      value: "+7 7"
    },
    {
      label: "+254",
      value: "+254"
    },
    {
      label: "+686",
      value: "+686"
    },
    {
      label: "+965",
      value: "+965"
    },
    {
      label: "+996",
      value: "+996"
    },
    {
      label: "+856",
      value: "+856"
    },
    {
      label: "+371",
      value: "+371"
    },
    {
      label: "+961",
      value: "+961"
    },
    {
      label: "+266",
      value: "+266"
    },
    {
      label: "+231",
      value: "+231"
    },
    {
      label: "+218",
      value: "+218"
    },
    {
      label: "+423",
      value: "+423"
    },
    {
      label: "+370",
      value: "+370"
    },
    {
      label: "+352",
      value: "+352"
    },
    {
      label: "+853",
      value: "+853"
    },
    {
      label: "+389",
      value: "+389"
    },
    {
      label: "+261",
      value: "+261"
    },
    {
      label: "+265",
      value: "+265"
    },
    {
      label: "+60",
      value: "+60"
    },
    {
      label: "+960",
      value: "+960"
    },
    {
      label: "+223",
      value: "+223"
    },
    {
      label: "+356",
      value: "+356"
    },
    {
      label: "+692",
      value: "+692"
    },
    {
      label: "+222",
      value: "+222"
    },
    {
      label: "+230",
      value: "+230"
    },
    {
      label: "+262",
      value: "+262"
    },
    {
      label: "+52",
      value: "+52"
    },
    {
      label: "+691",
      value: "+691"
    },
    {
      label: "+1 808",
      value: "+1 808"
    },
    {
      label: "+373",
      value: "+373"
    },
    {
      label: "+377",
      value: "+377"
    },
    {
      label: "+976",
      value: "+976"
    },
    {
      label: "+382",
      value: "+382"
    },
    {
      label: "+1664",
      value: "+1664"
    },
    {
      label: "+212",
      value: "+212"
    },
    {
      label: "+95",
      value: "+95"
    },
    {
      label: "+264",
      value: "+264"
    },
    {
      label: "+674",
      value: "+674"
    },
    {
      label: "+977",
      value: "+977"
    },
    {
      label: "+31",
      value: "+31"
    },
    {
      label: "+1 869",
      value: "+1 869"
    },
    {
      label: "+687",
      value: "+687"
    },
    {
      label: "+64",
      value: "+64"
    },
    {
      label: "+505",
      value: "+505"
    },
    {
      label: "+227",
      value: "+227"
    },
    {
      label: "+234",
      value: "+234"
    },
    {
      label: "+683",
      value: "+683"
    },
    {
      label: "+850",
      value: "+850"
    },
    {
      label: "+1 670",
      value: "+1 670"
    },
    {
      label: "+47",
      value: "+47"
    },
    {
      label: "+968",
      value: "+968"
    },
    {
      label: "+92",
      value: "+92"
    },
    {
      label: "+680",
      value: "+680"
    },
    {
      label: "+970",
      value: "+970"
    },
    {
      label: "+507",
      value: "+507"
    },
    {
      label: "+675",
      value: "+675"
    },
    {
      label: "+51",
      value: "+51"
    },
    {
      label: "+63",
      value: "+63"
    },
    {
      label: "+48",
      value: "+48"
    },
    {
      label: "+351",
      value: "+351"
    },
    {
      label: "+1 787",
      value: "+1 787"
    },
    {
      label: "+974",
      value: "+974"
    },
    {
      label: "+40",
      value: "+40"
    },
    {
      label: "+7",
      value: "+7"
    },
    {
      label: "+250",
      value: "+250"
    },
    {
      label: "+685",
      value: "+685"
    },
    {
      label: "+378",
      value: "+378"
    },
    {
      label: "+966",
      value: "+966"
    },
    {
      label: "+221",
      value: "+221"
    },
    {
      label: "+381",
      value: "+381"
    },
    {
      label: "+248",
      value: "+248"
    },
    {
      label: "+232",
      value: "+232"
    },
    {
      label: "+65",
      value: "+65"
    },
    {
      label: "+421",
      value: "+421"
    },
    {
      label: "+386",
      value: "+386"
    },
    {
      label: "+677",
      value: "+677"
    },
    {
      label: "+27",
      value: "+27"
    },
    {
      label: "+82",
      value: "+82"
    },
    {
      label: "+34",
      value: "+34"
    },
    {
      label: "+94",
      value: "+94"
    },
    {
      label: "+249",
      value: "+249"
    },
    {
      label: "+597",
      value: "+597"
    },
    {
      label: "+268",
      value: "+268"
    },
    {
      label: "+46",
      value: "+46"
    },
    {
      label: "+41",
      value: "+41"
    },
    {
      label: "+963",
      value: "+963"
    },
    {
      label: "+886",
      value: "+886"
    },
    {
      label: "+992",
      value: "+992"
    },
    {
      label: "+255",
      value: "+255"
    },
    {
      label: "+66",
      value: "+66"
    },
    {
      label: "+228",
      value: "+228"
    },
    {
      label: "+690",
      value: "+690"
    },
    {
      label: "+676",
      value: "+676"
    },
    {
      label: "+1 868",
      value: "+1 868"
    },
    {
      label: "+216",
      value: "+216"
    },
    {
      label: "+90",
      value: "+90"
    },
    {
      label: "+993",
      value: "+993"
    },
    {
      label: "+1 649",
      value: "+1 649"
    },
    {
      label: "+688",
      value: "+688"
    },
    {
      label: "+1 340",
      value: "+1 340"
    },
    {
      label: "+256",
      value: "+256"
    },
    {
      label: "+380",
      value: "+380"
    },
    {
      label: "+971",
      value: "+971"
    },
    {
      label: "+44",
      value: "+44"
    },
    {
      label: "+598",
      value: "+598"
    },
    {
      label: "+998",
      value: "+998"
    },
    {
      label: "+678",
      value: "+678"
    },
    {
      label: "+58",
      value: "+58"
    },
    {
      label: "+84",
      value: "+84"
    },
    {
      label: "+681",
      value: "+681"
    },
    {
      label: "+967",
      value: "+967"
    },
    {
      label: "+260",
      value: "+260"
    },
    {
      label: "+263",
      value: "+263"
    }
  ]
  































































































































































































































































































































































































































































































































































