import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AsyncTableListing from 'ui-component/AsyncTableListing';
import { FetchInvestors, ExportInvestors, ExportTransaction, FetchAllKycStatus, ExportKratos } from '../../api';
import { useTheme } from '@mui/material/styles';
import { IconEdit } from '@tabler/icons';
import Clickable from 'utils/Clickable';
import { Box, Tooltip } from '@mui/material';
import ListingFilters from './ListingFilters';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ApproveKycPopup from './kyc/ApproveKycPopup'
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PartnerPopup from './kyc/PartnerPopup'
import { useSelector } from 'react-redux';
import Loading from 'views/Loading';
import Modal from 'ui-component/Modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import Card from 'ui-component/cards/Card';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
const columns = [

  {
    label: 'Name',
    minWidth: 250,
    align: 'left',
    type: 'name',
    image: true,
    link: true,
    fixed: true,
    fixed_row_index: 0,
    fixed_column_index: 1
  },
  {
    label: 'LP Code',
    minWidth: 100,
    align: 'left',
    type: 'lp_code',
  },
  {
    label: 'Email',
    minWidth: 300,
    align: 'left',
    type: 'email'
  },
  {
    label: 'Mobile',
    minWidth: 150,
    align: 'left',
    type: 'mobile_number'
  },
  {
    label: 'Syndicate Name',
    minWidth: 150,
    align: 'left',
    type: 'syndicate_name'
  },
  {
    label: 'Partner',
    minWidth: 150,
    align: 'center',
    type: 'referral_partner'
  },

  {
    label: 'KYC Status',
    minWidth: 210,
    align: 'center',
    type: 'kyc_status',
    status: 'true',
    clickable: false
  },
  {
    label: 'Registered',
    minWidth: 200,
    align: 'left',
    type: 'createdAt',
    sort: true,
  },
  {
    label: 'Onboarded',
    minWidth: 170,
    align: 'center',
    type: 'on_board_date'
  },
  {
    label: 'Actions',
    minWidth: 150,
    align: 'right',
    type: 'actions'
  },
];
const mode = ''

export default function LeadInvestorListing() {
  const initialSearch = Cookies.get('search') || '';
  const Error404 = Loadable(lazy(() => import('views/error/Error404')));
  const initialsearchStatus = Cookies.get('searchStatus') || false;
  const theme = useTheme();
  const [search, setSearch] = useState(initialSearch);
  const [InvestorsModule, setInvestorsModule] = useState(null);
  const [searchStatus, setSearchStatus] = useState(initialsearchStatus);
  const [page, setPage] = useState(1);
  const [reset, setreset] = useState(false)
  const [investorId, setInvestorId] = useState('')
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState(-1);
  const [limit, setLimit] = useState(20);
  const [isLoader, setIsLoading] = useState(false);
  const [isTransactionLoader, setIsTransactionLoading] = useState(false);
  const [isKratosLoader, setIsKratosLoading] = useState(false);
  const [queryStringValue, setQueryStringValue] = useState('');
  const investor = useSelector((state) => state.investor["lead_investors"]);

  useEffect(() => {
    const permission_values = localStorage.getItem('permission');
    const permissions = permission_values != undefined ? JSON.parse(permission_values) : [];
    const module = permissions.find(
      (module) => module.module_name === 'Investors' && module.permisson === true
    );
    setInvestorsModule(module); // Set the value in the state
    Cookies.set('investor-index', 7, { path: '/' })
  }, []);

  let params = {}
  if (investor.master_user_type_id) {
    params["master_user_type_id"] = investor.master_user_type_id
  }
  if (investor.partner._id) {
    params["investor_partner_id"] = investor.partner._id
  }
  if (investor.venturePartner._id) {
    params["investor_venture_partner_id"] = investor.venturePartner._id
  }
  if (investor.representative._id) {
    params["investor_representative_id"] = investor.representative._id
  }
  if (investor.syndicate_id.length > 0) {
    params["syndicate_id"] = investor.syndicate_id
  }
  if (investor.tags) {
    params["tags"] = investor.tags
  }
  if (investor.from_on_board_date) {
    params["from_on_board_date"] = investor.from_on_board_date
  }
  if (investor.to_on_board_date) {
    params["to_on_board_date"] = investor.to_on_board_date
  }
  if (investor.gender) {
    params["gender"] = investor.gender
  }
  if (investor.user_individual_type) {
    params["user_individual_type"] = investor.user_individual_type
  }
  // if(investor.individual_type){
  //   params["individual_type"] =investor.individual_type
  // }
  if (investor.account_type_id?.length > 0) {
    params["account_type_id"] = investor.account_type_id
  }
  if (investor.country_cityzenship_id?.length > 0) {
    params["country_cityzenship_id"] = investor.country_cityzenship_id
  }
  if (investor.from_created_date) {
    params["from_created_date"] = investor.from_created_date
  }
  if (investor.to_created_date) {
    params["to_created_date"] = investor.to_created_date
  }
  const queryString = Object.keys(params).length > 0 ? '&' + new URLSearchParams(params).toString() : ''
  useEffect(() => {
    // Update the queryString state whenever it changes
    setQueryStringValue(queryString);
  }, [queryString]);
  const handleOpen = (id) => {
    setInvestorId(id)
    setOpen(true)
  }
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = (id) => {
    setInvestorId(id)
    setOpen2(true)
  }
  const handleClose2 = () => setOpen2(false);
  useEffect(() => {
    Cookies.set('search', search);
    Cookies.set('searchStatus', searchStatus);
  }, [search]);
  const InvestorActions = ({ row }) => {
    const navigate = useNavigate();
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '6px' }}>
        {InvestorsModule?.view_investment && <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => navigate(`/investors/viewInvestor/${row._id}`)}
        >
          <Tooltip title="View">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <VisibilityIcon size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>}
        {InvestorsModule?.kyc_status_change && <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => handleOpen(row._id)}
        >
          <Tooltip title="KYC status">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <HowToRegIcon size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>}
        {InvestorsModule?.edit && <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => navigate(`/investors/editInvestor/${row._id}`)}
        >
          <Tooltip title="Edit">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconEdit stroke={1.5} size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>}
        {InvestorsModule?.setting_update && <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => handleOpen2(row._id)}
        >
          <Tooltip title="Settings">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SettingsIcon stroke={1.5} size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>}
        {/* {row.status=="active" ? <Clickable 
        style={{display: 'flex', alignItems:'center'}} 
        onClick={()=>{
          updateStartupStatus({id: row._id, status:'inactive'}, {
            onSuccess:()=>refetchListing() refetchExportInvestors()
          })
        }}
      >
        <Tooltip title="Soft Delete">
          <div style={{display: 'flex', alignItems:'center'}}>
            <DeleteIcon stroke={1.5} size="1.4rem" color='error'/>
          </div>
        </Tooltip>
      </Clickable>:<Clickable 
        style={{display: 'flex', alignItems:'center'}} 
        onClick={()=>{
          updateStartupStatus({id: row._id, status:'active'}, {
            onSuccess:()=>refetchListing() refetchExportInvestors()
          })
        }}
      >
        <Tooltip title="Soft Delete">
          <div style={{display: 'flex', alignItems:'center'}}>
            <SettingsBackupRestoreIcon stroke={1.5} size="1.4rem" color='primary'/>
          </div>
        </Tooltip>
      </Clickable>} */}
      </div>
    );
  }

  const InvestorLink = ({ row, children }) => {
    return <Link to={`/investors/viewInvestor/${row._id}`} style={{ textDecoration: "none", color: '#616161', fontWeight: 700 }}>
      {children}
    </Link>
  }

  const { isLoading: isInvestorLoading, isRefetching: isInvestorRefetching, data: investorData, refetch: refetchListing, status: investorStatus } = FetchInvestors(page, search, mode, sort, limit, queryString + "&is_lead_investor=Yes")
  const { refetch: refetchExportInvestors, isLoading: isExportInvestorLoading, isRefetching: isExportInvestorRefetching } = ExportInvestors(page, search, mode, sort, limit, queryString + "&is_lead_investor=Yes")
  const { refetch: refetchExportTransaction, isLoading: isExportTransactionLoading, isRefetching: isExportTransactionRefetching, status: exportTransactionStatus } = ExportTransaction(page, search, mode, sort, limit, queryString + '&is_lead_investor=Yes')
  const { isLoading: isKycLoading, isRefetching: isKycRefetching, data: KycOptions, refetch: refetchKyc } = FetchAllKycStatus('options')

  function download() {
    setIsLoading(true);
    refetchExportInvestors().then(({ data }) => {
      window.open(data, "_blank")
      setIsLoading(false);

    });
  }
  function downloadtrasnsaction() {
    setIsTransactionLoading(true);
    refetchExportTransaction().then(({ data }) => {
      window.open(data, "_blank")
      setIsTransactionLoading(false);
    });
  }

  useEffect(() => {
    refetchListing()
  }, [reset])
  // useEffect(() => {
  //   if (investorStatus == 'success') {
  //     setTitle(`All Investors (${investorData.count})`)
  //   } else {
  //     setTitle(`All Investors`)
  //   }
  // }, [investorData])
  const totalCount = investorData?.count || 0; // Total count of completed deals
  const startCount = (page - 1) * limit + 1; // Starting count of completed deals on the current page
  const endCount = Math.min(page * limit, totalCount);

  return (
    <>{
      InvestorsModule != undefined && InvestorsModule.permisson == true ? <>
        <Box sx={{ width: '100%' }}>
          <BreadcrumbHeader title={`Lead Investors ${investorData != undefined ? "(" + investorData.count + ")" : ''}`} />
        </Box>
        <Card sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3 }}>
          <ToastContainer />
          {!isKycLoading && (
            <Modal open={open} onClose={handleClose} closeIcon={true}>
              <ApproveKycPopup handleClose={handleClose} investorId={investorId} KycOptions={KycOptions} setSearchStatus={setSearchStatus} setSearch={setSearch} refetchListing={refetchListing} />
            </Modal>
          )}
          <Modal open={open2} onClose={handleClose2} closeIcon={true} style={{ width: '900px' }}>
            <PartnerPopup handleClose={handleClose2} investorId={investorId} setSearchStatus={setSearchStatus} setSearch={setSearch} refetchListing={refetchListing} />
          </Modal>
          <ListingFilters
            search={search}
            setSearch={setSearch}
            refetchListing={refetchListing}
            handleExport={download}
            handleTransactionExport={downloadtrasnsaction}
            searchStatus={searchStatus}
            setSearchStatus={setSearchStatus}
            isExportLoading={isExportInvestorRefetching}
            // isExportKratosLoading={isKratosLoader}
            reset={reset}
            setreset={setreset}
            page={page}
            setPage={setPage}
            isLoader={isLoader}
            sort={sort}
            limit={limit}
            mode={mode}
            isTransactionLoader={isTransactionLoader}
            InvestorsModule={InvestorsModule}
            queryString={queryStringValue}
          // filter={false}
          />
          {isInvestorLoading ? <Loading /> : <AsyncTableListing
            columns={columns}
            data={investorData.investors}
            actions={InvestorActions}
            page={page}
            setPage={setPage}
            count={investorData.count}
            cutomLink={InvestorLink}
            sort={sort}
            setSort={setSort}
            limit={limit}
            setLimit={setLimit}
            footerSummary={<Box><Typography>{`Showing ${startCount} to ${endCount} of ${totalCount} Investors`}</Typography></Box>}
          />}

        </Card> </> : <Error404 />
    }
    </>
  );
}