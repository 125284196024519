import { useTheme } from '@emotion/react'
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { FetchUserFeedbackDetails, SubmitFeedbackForm } from 'api'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import Cookies from 'js-cookie'
import React from 'react'
import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader'
import CustomButton from 'ui-component/CustomButton'
import Card from 'ui-component/cards/Card'
import CustomField from 'ui-component/formik/CustomField'
import Loading from 'views/Loading'
import LinkIcon from '@mui/icons-material/Link';
// import Error404 from 'views/error/Error404'

const SubmitFeedback = () => {
    const { id: master_startup_id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    // let useHistory = window.history;
    let feedback_master_id = new URLSearchParams(window.location.search).get("feedback");
    // console.log(feedback_master_id,"feedbackSelected")
    const [formikValues, setFormikValues] = React.useState(
        {
            "master_startup_id": master_startup_id,
            "feedback_master_id": feedback_master_id,
            "partner_notification_sent_id": "",
            "template_name": null,
            "questions": [],
            "pitch_deck": [],
            "submitted_by": "",
            "submitted_date": "",
            "admin_partner_id": ""
        }
    )
    const {
        mutateAsync: fetchUserFeedbackDetails,
        isLoading: isUserFeedbackLoading,
        isRefetching: isUserFeedbackRefetching,
        status: userFeedbackLoadingStatus,
        error: userFeedbackLoadingError
    } = FetchUserFeedbackDetails();

    const { mutateAsync: submitFeedback, status: submitFeedbackFormStatus, error: submitFeedbackFormError } = SubmitFeedbackForm();

    React.useEffect(() => {
        if (submitFeedbackFormStatus == 'success') {
            formik.resetForm()
            toast.success('Feedback Form Submitted', {
                position: "top-left",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                onClose: () => {
                    // if (useHistory?.length > 0) {
                    //     navigate(-1);
                    // }
                    if (Cookies.get('feedback_link') == true || Cookies.get('feedback_link') == "true") {
                        navigate('/feedbacks')
                        Cookies.remove('feedback_link');
                        // console.log("feedback_link")
                    }
                    else if (Cookies.get('startup_feeback') == true || Cookies.get('startup_feeback') == "true") {
                        navigate(`/applicationListing/feedbacksView/${formikValues?.master_startup_id}`)
                        Cookies.remove('startup_feeback');
                        // console.log("startup_feeback")
                    }
                    else {
                        // console.log("all")
                        navigate('/feedbacks');
                    }
                    // refetchListing();
                }
            });
        }
        if (submitFeedbackFormStatus == 'error') {
            toast.error(submitFeedbackFormError?.response?.data?.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        }
    }, [submitFeedbackFormStatus])

    React.useEffect(() => {
        console.log(feedback_master_id,"feedback_master_id",master_startup_id,"master_startup_id")
        fetchUserFeedbackDetails({
            "feedback_master_id": feedback_master_id,
            "master_startup_id": master_startup_id
        }).then(({ data }) => {
            // console.log(data?.data,"data")
            setFormikValues({
                ...data?.data,
                "master_startup_id": data?.data?.master_startup_id,
                "feedback_master_id": data?.data?.feedback_master_id,
                "partner_notification_sent_id": data?.data?.partner_notification_sent_id,
                template_name: data?.data?.template_name, questions: data?.data?.questions?.map(item => {
                    return {
                        ...item,
                        answer: item?.answer != undefined ? item?.answer : [""]
                    }
                })
            })
        }).catch((err) => {
            console.log("err", err)
        });
    }, [master_startup_id,feedback_master_id])

    const formik = useFormik({
        initialValues: formikValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payload = {
                "master_startup_id": master_startup_id,
                "feedback_master_id": feedback_master_id,
                "partner_notification_sent_id": values?.partner_notification_sent_id,
                "questionAnswers": values?.questions?.map(item => {
                    if (item?.required && item?.answer?.length < 2 && item?.answer[0] == "") {
                        return ({
                            "question_id": item?._id
                        })
                    }
                    else {
                        return ({
                            "question_id": item?._id,
                            "answer": item?.answer
                        })
                    }
                })
            }
            // console.log(payload, "values", values)
            submitFeedback(payload)
        }
    })

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const handleCheckboxChange = (event, questionIndex, answerIndex) => {
        const { value, checked } = event.target;
        // formik.values.que((prev) =>
        //   checked ? [...prev, value] : prev.filter((v) => v !== value)
        // );
        formik.setFieldValue(`questions.${questionIndex}.answer`, checked ? [...formik.values.questions[questionIndex].answer, value] : formik.values.questions[questionIndex].answer?.filter((v) => v !== value))
    };

    const renderInputs = (type, currentIndex) => {
        switch (type) {
            case 'single_select':
                return (
                    <Grid container mt={2} >
                        <RadioGroup
                            style={{ width: '100%' }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={formik.values.questions[currentIndex]?.answer[0]}
                            onChange={(e) => {
                                formik.setFieldValue(`questions.${currentIndex}.answer.${0}`, e.target.value)
                            }}
                        >{
                                formik.values.questions[currentIndex].options &&
                                formik.values.questions[currentIndex].options.length > 0 &&
                                formik.values.questions[currentIndex].options.map((currentItem, index) => (<>
                                    <FormControlLabel required={formik.values.questions[currentIndex]?.required} className='form-label' sx={{
                                        "&.form-label > .MuiStack-root > .MuiFormControlLabel-asterisk ": {
                                            display: 'none'
                                        }
                                    }} value={currentItem} control={<Radio />} label={currentItem} />
                                    {/* <CustomField name={`questions.${currentIndex}.options.${index}`} label={`Option ${index + 1}`} value={formik.values.questions[currentIndex]?.options[index]} onChange={(e) => {
                                    formik.setFieldValue(`questions.${currentIndex}.options.${index}`, e.target.value)
                                }} /> */}
                                </>))
                            }
                        </RadioGroup>
                    </Grid>)

            case 'single_line_text':
                return <>
                    <TextField fullWidth sx={{ marginBlock: 3 }} required={formik.values.questions[currentIndex]?.required} name={`questions.${currentIndex}.answer.${0}`} value={formik.values.questions[currentIndex]?.answer[0]} onChange={(e) => {
                        formik.setFieldValue(`questions.${currentIndex}.answer.${0}`, e.target.value)
                    }} label={'Answer'} placeholder="Your Answer" />
                </>


            case 'multi_select':
                return (
                    <Grid container mt={2} >
                        <FormGroup>
                            {formik.values.questions[currentIndex].options &&
                                formik.values.questions[currentIndex].options.length > 0 &&
                                formik.values.questions[currentIndex].options.map((option, index) => (
                                    <FormControlLabel
                                        required={formik.values.questions[currentIndex]?.required}
                                        key={option}
                                        control={
                                            <Checkbox
                                                value={option}
                                                defaultChecked={formik.values.questions[currentIndex].answer?.includes(option)}
                                                onChange={(e) => handleCheckboxChange(e, currentIndex, index)}
                                            />
                                        }
                                        label={option}
                                    />
                                ))}
                        </FormGroup>
                    </Grid>)

            case 'multi_line_text':
                return <>
                    <TextField fullWidth minRows={5} required={formik.values.questions[currentIndex]?.required} sx={{ marginBlock: 3 }} value={formik.values.questions[currentIndex]?.answer[0]} name={`questions.${currentIndex}.answer.${0}`} onChange={(e) => {
                        formik.setFieldValue(`questions.${currentIndex}.answer.${0}`, e.target.value)
                    }} label={'Answer'} placeholder="Your Answer" multiline />
                </>

            default:
                return <></>
        }
    }
    let hasPitchRecord = formik.values?.pitch_deck?.length > 0 && formik.values?.pitch_deck?.find(item => item?.name == "Pitch Deck")?.url;
    let pitchRecord = formik.values?.pitch_deck?.find(item => item?.name == "Pitch Deck")?.url;
    let hasPitchVideo = formik.values?.startup_detail && formik.values?.startup_detail?.videos?.length > 0;
    let pitchVideo = formik.values?.startup_detail?.videos[0]?.url;

    // console.log(formik?.values, "Values")
    return (
        <>
            {/* {userFeedbackLoadingStatus == "error" ? <div>
                <Card style={{ width: '100%',height: '70vh', padding: '20px', textAlign: 'center' }}>
                   
                        <Typography variant="h4" color="error" gutterBottom>
                            Access Denied
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            You do not have the necessary permissions to access this page.
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Please check your credentials or contact support if you believe this is an error.
                        </Typography>
                        <CustomButton variant="contained" color="primary" onClick={() => {}} style={{ marginTop: '20px' }}>
                            Go to Home Page
                        </CustomButton>
                </Card>
            </div> : */}
            <>
                <ToastContainer />
                <Box sx={{ width: '100%' }}>
                    <BreadcrumbHeader title="Submit Feedback" shouldGoBack={true} />
                </Box>
                {(isUserFeedbackLoading || isUserFeedbackRefetching || formikValues?.template_name == null)
                    ? <Loading /> : <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
                            <Card style={{ paddingBottom: 1 }}>
                                <Typography variant='h2'>
                                    {formik.values.template_name} - {formik.values?.startup_detail?.company_name}
                                </Typography>
                                <Typography variant='h4' style={{ marginTop: 10 }}>
                                    {hasPitchRecord && pitchRecord && pitchRecord != "" && <div style={{display:"flex", alignItems: "center"}}><LinkIcon sx={{color: "#0000FF", marginRight: 1}} />  <a style={{ textDecoration: 'none', color: "#0000FF" }} target='_blank' href={`${pitchRecord}`}>Pitch Deck</a> </div>}
                                </Typography>
                                <Typography variant='h4' style={{ marginTop: 10 }}>
                                    {hasPitchVideo && pitchVideo && pitchVideo != "" && <div style={{display:"flex", alignItems: "center"}}><LinkIcon sx={{color: "#0000FF", marginRight: 1}} /> <a style={{ textDecoration: 'none', color: "#0000FF" }} target='_blank' href={`${pitchVideo}`}>Call Recording (Shared by Startup)</a></div>}
                                </Typography>
                                <Typography variant='h4' display={"grid"}>
                                    {formik.values?.more_links?.map(currentLink => {
                                        return <div style={{display:"flex", alignItems: "center",marginTop: 10 }}> <LinkIcon sx={{color: "#0000FF", marginRight: 1}} /> <a style={{ textDecoration: 'none', color: "#0000FF" }} target='_blank' href={currentLink?.url} > {currentLink?.title}</a></div>
                                    })}
                                </Typography>
                            </Card>
                            {
                                formik.values?.questions?.map(
                                    (question, index) => {
                                        return <Card
                                            // title={question?.question_value}
                                            key={index}>
                                            <Typography variant='h4'>
                                                {question?.question_value} {question?.required && <span style={{ color: 'red' }}>*</span>}
                                            </Typography>
                                            {renderInputs(question.option_type, index)}
                                        </Card>
                                    }
                                )
                            }
                            <CustomButton type="submit">
                                Submit
                            </CustomButton>
                        </Form>
                    </FormikProvider>
                }
            </>
            {/* } */}
        </>
    )
}

export default SubmitFeedback