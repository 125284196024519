
import React, { useState } from 'react';
import { Box } from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { FetchVenturePartner,UpdateVenturePercentage } from 'api';
import {useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useFormik, Form,Formik ,FormikProvider } from "formik";


export default function VpFees({ startupDealId,id }) {
    const [title, setTitle] = useState('VP Fees');
    const [updatedData, setUpdatedData] = useState([]);
    const {
        isLoading: isCompletedDealsLoading,
        isRefetching: isCompletedDealsRefetching,
        data: venturePartnerData,
        // refetch: refetchListing,
        refetch: refetchVenturePartnerData,
        status: dealStatus
    } = FetchVenturePartner(startupDealId);
    const { mutateAsync: updatePercentages, status: updatePercentagesStatus, error: updatePercentagesError,reset: updatePercentagesReset } = UpdateVenturePercentage(id);
    
    const formik = useFormik({
        // initialValues: {
        //     fees_percentage: venturePartnerData?.map(partner => partner.fees_percentage) || []
        // },
        initialValues: {
            fees_percentage: startupDealId
              ? venturePartnerData?.map(partner => partner.deal_fees_percentage) || []
              : venturePartnerData?.map(partner => partner.fees_percentage) || []
          },
        enableReinitialize: true,
        onSubmit: async (values,{ setValues }) => {
            if (venturePartnerData) {
                const updatedData = venturePartnerData.map((partner, index) => ({
                    investor_id: partner._id,
                    // partner_name:partner.name,
                    fees_percentage: values.fees_percentage[index] || 0
                }));
                // if (startupDealId) {
                //     updatedData.forEach((data, index) => {
                //       data.fees_percentage = values.deal_fees_percentage[index] || 0;
                //     });
                //   }
                updatePercentages({ payload: updatedData });
                if (updatePercentagesStatus === 'success') {
                    // setValues({
                    //     ...values,
                    //     fees_percentage: updatedData.map(item => item.fees_percentage)
                    //   });
                    refetchVenturePartnerData();
                   
                }
            }
        }
    });
    // useEffect(() => {
    //     setTitle(' VP Fees');
    //   }, []);
    useEffect(() => {
        // window.location.reload();
        if (updatePercentagesStatus == 'success') {
          toast.success('Percentage Added', {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
        }, 1000);
        }
        if (updatePercentagesStatus == 'error') {
          toast.error(updatePercentagesError?.response?.data?.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
    
        }
      }, [updatePercentagesStatus])

  return (
    <>
    <ToastContainer />
    {/* {(!id && !startupDealId) && ( // Hide BreadcrumbHeader if id or startupDealId is present
        <Box sx={{ width: '100%' }}>
          <BreadcrumbHeader title={title} />
        </Box>
      )} */}
        {/* <div className='custom-menu-block view-investor-block'> */}
            {/* <div className='d-flex mb-3'>
                <div>
                    <input type='text' className='form-control' placeholder='Search'/>
                </div>
                <div className='ms-3'>
                    <button className='button-79' type='button'>Search</button>
                </div>
            </div> */}
            <div  className='custom-table mt-3'>
            <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <table style={{borderRadius: '8px',overflow: 'hidden'}} className='w-100'>
                    <thead>
                        <tr>
                            <th style={{width: "15%"}}>LP Code</th>
                            <th style={{width: "25%"}}>Name</th>
                            <th style={{width: "25%"}}>Email</th>
                            <th style={{width: "15%"}}>Type</th>
                            <th style={{width: "20%"}}>Fee %</th>
                        </tr>
                    </thead>
                    <tbody>
                    {venturePartnerData ? (
                            venturePartnerData.map((partner, index) => (
                        <tr key={index}>
                            <td>{partner.lp_code}</td>
                            <td>{partner.name}</td>
                            <td>{partner.email}</td>
                            <td>{partner.user_type}</td>
                            <td>
                                <input className='form-control text-center w-70' 
                                type='text'
                                name={`fees_percentage[${index}]`} 
                                // value={formik.values.fees_percentage[index]}
                                value={
                                    startupDealId && formik.values.deal_fees_percentage
                                      ? formik.values.deal_fees_percentage[index]
                                      : formik.values.fees_percentage[index]
                                  }
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^\d*\.?\d*$/.test(inputValue)) {
                                      // Update formik value only if the input is a valid number/decimal
                                      formik.handleChange(e);
                                  }
                              }}
                                min={0}
                                max={100} 
                                // step={0.0001}
                                /> 
                                %
                            </td>
                        </tr>
                        ))
                        ) : (
                            <tr>
                                <td colSpan="5">Loading...</td>
                            </tr>
                        )}
                    </tbody>
                      </table>
                      <div className='mt-2 d-flex'>
                    <div style={{width: "80%"}}></div>
                    <div style={{width: "20%", paddingLeft:"15px"}}>
                        <button className='button-79' type='submit' >Save All</button>
                    </div>
                      </div>
                    </Form>
            </FormikProvider>
            </div>
        {/* </div> */}
        
    </>
  )
}