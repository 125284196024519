// assets
import { IconFileDollar } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const fees = {
    id: 'fees1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'fees11',
            title: 'Fees',
            type: 'item',
            url: `/fees`,
            icon: IconFileDollar,
            breadcrumbs: false
        }
    ]
};

export default fees;
