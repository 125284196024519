import React from 'react'
import { useTheme } from '@emotion/react';
import { Box, Grid, Paper, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { AiFillCloseCircle } from 'react-icons/ai';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import CustomAutoComplete from 'ui-component/formik/CustomAutoComplete';
import CustomField from 'ui-component/formik/CustomField';
import SelectField from 'ui-component/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { IoAddCircleSharp } from 'react-icons/io5';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import AsyncButton from 'ui-component/AsyncButton'
import Loading from 'views/Loading';
import { UploadTransaction } from 'api';
import { toast } from 'react-toastify';
import CustomButton from 'ui-component/CustomButton';

const AddTransactionsPopup = ({ refetchTransactions, refetchApprovedInvestorsData, ApprovedInvestorsData, LeadInvestorListData, dealDetailData, dealId, dealsModule, handleAddTransactionPopupClose }) => {
    const {
        mutateAsync: uploadTransactions,
        // isLoading: isUploadingTransactions,
        status: uploadTransactionStatus,
        error: uploadTransactionError
    } = UploadTransaction();
    const formik = useFormik({
        initialValues: {
            investors: [
                {
                    investor_id: '',
                    unit_class_id: '',
                    lead_investor_id: [],
                    startup_deal_id: dealId,
                    investment_interest_amount: '',
                    funds_transferred_amount: '',
                    investment_interest_date: null,
                    // funds_transferred_date: null,
                    is_unallocated: false,
                    is_allocation_email: false,
                    is_email_sent: false,
                    // utr_number: '',
                    remark: '',
                    // payments: {
                    //     payment_mode: '',
                    //     utr_number: ''
                    // }
                }
            ]
        },
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            const payload = values.investors.map((investor) => {
                const {
                    investment_interest_date,
                    // funds_transferred_date,
                    investment_interest_amount,
                    funds_transferred_amount,
                    ...newValues
                } = investor;
                const payload = {
                    ...newValues,
                    unit_class_id: investor.unit_class_id,
                    investor_id: investor.investor_id._id,
                    lead_investor_id: investor.lead_investor_id?.map(item => item?._id),
                    // is_unallocated: investor.funds_transferred_amount ? 'Yes' : 'No',
                    is_allocation_email: !!investor?.is_allocation_email,
                    is_email_sent: !!investor?.is_email_sent
                };
                if (investor.investment_interest_date) {
                    payload['investment_interest_date'] = investor.investment_interest_date;
                } else {
                    payload['investment_interest_date'] = '';
                }
                // if (investor.funds_transferred_date) {
                //     payload['funds_transferred_date'] = investor.funds_transferred_date;
                // }
                if (investor.investment_interest_amount) {
                    payload['investment_interest_amount'] = investor.investment_interest_amount;
                } else {
                    payload['investment_interest_amount'] = '';
                }
                if (investor.funds_transferred_amount) {
                    payload['funds_transferred_amount'] = investor.funds_transferred_amount;
                    payload['is_unallocated'] = 'Yes';
                }
                else {
                    payload['is_unallocated'] = 'No';
                }
                // if (typeof values.payments == 'object' && investor.payments?.value) {
                //     payload['payments[payment_mode]'] = investor.payment_mode?.value;
                // } else {
                //     if (typeof values.payments == 'string') {
                //         payload['payments[payment_mode]'] = values.payment_mode;
                //     }
                // }
                return payload;
            });
            // console.log(payload,"payload")
            uploadTransactions(payload, {
                onSuccess: (succ) => {
                    setTimeout(() => {
                        refetchApprovedInvestorsData()
                        refetchTransactions()
                    }, 200);
                    toast.success(values?.investors[0]?.is_email_sent ? `Test Email is sent` : JSON.parse(succ.request.response).message, {
                        position: 'top-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored'
                    },5000);
                    handleAddTransactionPopupClose();
                },
                onError: (err) => {
                    // console.log(JSON.parse(err.request.response).message);
                    toast.error(uploadTransactionError.response.data.message, {
                        position: 'top-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored'
                    },5000);
                    // handleClose();
                }
            });
        }
    });
    const theme = useTheme();
    let unitClassOptions = dealDetailData?.unit_class != undefined ? [{ label: 'Select Unit Class', value: '' }, ...dealDetailData?.unit_class?.map(item => ({ label: item?.label, value: item?.unit_class_id }))] : [{ label: 'Select Unit Class', value: '' }]
    // console.log(ApprovedInvestorsData,"ApprovedInvestorsData")
    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '16px', marginTop: '14px' }}>
                {ApprovedInvestorsData == undefined ? <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div> : <FormikProvider value={formik}>
                    <h2 style={{ margin: '16px 0px 16px 8px' }}>Add Transaction</h2>
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid item xs={12}>
                            <FieldArray
                                name="investors"
                                render={(arrayHelpers) => (
                                    <Grid item xs={12}>
                                        {formik.values.investors && formik.values.investors.length > 0 && (
                                            formik.values.investors.map((_, index) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    direction="row"
                                                    columnSpacing={2}
                                                    rowSpacing={2}
                                                    key={index}
                                                    mb={formik.values.investors.length > 1 ? 3 : 0}
                                                >

                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <div className="custom-card card box-shadow-none">
                                                            <div className="card-body">
                                                                <Grid
                                                                    xs={12}
                                                                    container
                                                                    direction="row"
                                                                    columnSpacing={1}
                                                                    rowSpacing={1}
                                                                >

                                                                    <Grid item md={4} sm={6} xs={12}>
                                                                        <CustomAutoComplete
                                                                            label="Investors"
                                                                            name={`investors.${index}.investor_id`}
                                                                            value={formik.values.investors[index].investor_id}
                                                                            options={[...ApprovedInvestorsData]}
                                                                            getOptionLabel={(option) => {
                                                                                return `${option?.name && option?._id ? option.name : ''
                                                                                    } ${option?.lp_code
                                                                                        ? `(${option.lp_code})`
                                                                                        : option.kyc_status != undefined
                                                                                            ? `(${option.kyc_status})`
                                                                                            : ''
                                                                                    }`;
                                                                            }}
                                                                            renderOption={(props, option) => (
                                                                                <Box
                                                                                    component="li"
                                                                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                                                    {...props}
                                                                                >
                                                                                    {option.name}
                                                                                    {option.lp_code
                                                                                        ? ` (${option.lp_code})`
                                                                                        : ` (${option.kyc_status})`}
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={4} sm={6} xs={12}>
                                                                        <SelectField
                                                                            name={`investors.${index}.unit_class_id`}
                                                                            label="Unit Class"
                                                                            // className={`${classes.customSelectBox}`}
                                                                            value={formik.values.investors[index].unit_class_id}
                                                                            onChange={(e) => { formik.setFieldValue(`investors.${index}.unit_class_id`, e.target.value) }}
                                                                            options={unitClassOptions}
                                                                        />
                                                                    </Grid>
                                                                    {formik.values.investors[index].unit_class_id != '' && dealDetailData?.unit_class?.find(item => item?.unit_class_id == formik.values.investors[index].unit_class_id)?.type != 'Lead Investor' && LeadInvestorListData?.length > 0 && <Grid item md={4} sm={6} xs={12}>
                                                                        <CustomAutoComplete
                                                                            label="Lead Investor"
                                                                            multiple
                                                                            name={`investors.${index}.lead_investor_id`}
                                                                            value={formik.values.investors[index]?.lead_investor_id}
                                                                            options={LeadInvestorListData}
                                                                            getOptionLabel={(option) => {
                                                                                return `${option?.name && option?._id ? option.name : ''
                                                                                    } ${option?.lp_code
                                                                                        ? `(${option.lp_code})`
                                                                                        : option.kyc_status != undefined
                                                                                            ? `(${option.kyc_status})`
                                                                                            : ''
                                                                                    }`;
                                                                            }}
                                                                            renderOption={(props, option) => (
                                                                                <Box
                                                                                    component="li"
                                                                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                                                    {...props}
                                                                                >
                                                                                    {option.name}
                                                                                    {option.lp_code
                                                                                        ? ` (${option.lp_code})`
                                                                                        : ` (${option.kyc_status})`}
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </Grid>}
                                                                    <Grid item md={4} sm={6} xs={12}>
                                                                        <Tooltip
                                                                            title={convertToIndianCurrencyWords(
                                                                                formik.values.investors[index]
                                                                                    .investment_interest_amount
                                                                            )}
                                                                        >
                                                                            <CustomField
                                                                                label="Investment Interest Amount"
                                                                                name={`investors.${index}.investment_interest_amount`}
                                                                                // type="number"
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    onKeyDown: (event) => {
                                                                                        // Prevent the minus sign (-) from being entered
                                                                                        if (event.key === '-') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    },
                                                                                    onKeyPress: (event) => {
                                                                                        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                                                                        if (event.key === '-') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    },
                                                                                    inputMode: 'numeric',
                                                                                    // pattern: '[0-9]*',
                                                                                    pattern: '[0-9]*(.[0-9]+)?',
                                                                                    onKeyDown: (event) => {
                                                                                        // Prevent the input of minus sign (key code 189)
                                                                                        if (
                                                                                            event.keyCode === 189 ||
                                                                                            event.key === '-'
                                                                                        ) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item md={4} sm={6} xs={12}>
                                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                            <DatePicker
                                                                                label="Investment Interest Date"
                                                                                value={
                                                                                    formik.values.investors[index]
                                                                                        .investment_interest_date
                                                                                }
                                                                                onChange={(newValue) =>
                                                                                    formik.setFieldValue(
                                                                                        `investors.${index}.investment_interest_date`,
                                                                                        moment(newValue).format('YYYY-MM-DD')
                                                                                    )
                                                                                }
                                                                                renderInput={(params) => (
                                                                                    <TextField fullWidth {...params} />
                                                                                )}
                                                                                inputFormat="DD/MM/YYYY"
                                                                            />
                                                                        </LocalizationProvider>
                                                                    </Grid>
                                                                    {formik.values.investors[index].investor_id?.investor_status ==
                                                                        'approved' && dealsModule?.received_amount_add_edit && (
                                                                            <>
                                                                                <Grid item md={4} sm={6} xs={12}>
                                                                                    <Tooltip
                                                                                        title={convertToIndianCurrencyWords(
                                                                                            formik.values.investors[index]
                                                                                                .funds_transferred_amount
                                                                                        )}
                                                                                    >
                                                                                        <CustomField
                                                                                            label="Received Amount"
                                                                                            name={`investors.${index}.funds_transferred_amount`}
                                                                                            // type="number"
                                                                                            inputProps={{
                                                                                                min: 0,
                                                                                                onKeyDown: (event) => {
                                                                                                    // Prevent the minus sign (-) from being entered
                                                                                                    if (event.key === '-') {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                },
                                                                                                onKeyPress: (event) => {
                                                                                                    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                                                                                    if (event.key === '-') {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                },
                                                                                                inputMode: 'numeric',
                                                                                                // pattern: '[0-9]*',
                                                                                                pattern: '[0-9]*(.[0-9]+)?',
                                                                                                onKeyDown: (event) => {
                                                                                                    // Prevent the input of minus sign (key code 189)
                                                                                                    if (
                                                                                                        event.keyCode === 189 ||
                                                                                                        event.key === '-'
                                                                                                    ) {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                                {/* {dealsModule?.received_amount_add_edit && <Grid item md={4} sm={6} xs={12}>
                                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                                        <DatePicker
                                                                                            label="Funds Received Date"
                                                                                            value={
                                                                                                formik.values.investors[index]
                                                                                                    .funds_transferred_date
                                                                                            }
                                                                                            onChange={(newValue) =>
                                                                                                formik.setFieldValue(
                                                                                                    `investors.${index}.funds_transferred_date`,
                                                                                                    moment(newValue).format(
                                                                                                        'YYYY-MM-DD'
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                            renderInput={(params) => (
                                                                                                <TextField fullWidth {...params} />
                                                                                            )}
                                                                                            inputFormat="DD/MM/YYYY"
                                                                                        // openTo="year"
                                                                                        />
                                                                                    </LocalizationProvider>
                                                                                </Grid>}
                                                                                <Grid item md={4} sm={6} xs={12}>
                                                                                    <CustomField
                                                                                        label="UTR Number"
                                                                                        name={`investors.${index}.payments.utr_number`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item md={4} sm={6} xs={12}>
                                                                                    <Autocomplete
                                                                                        value={value}
                                                                                        onChange={(event, newValue) => {
                                                                                            // setValue(newValue);
                                                                                            const selectedValue =
                                                                                                newValue && typeof newValue === 'object'
                                                                                                    ? newValue.value
                                                                                                    : newValue;
                                                                                            formik.setFieldValue(
                                                                                                `investors.${index}.payments.payment_mode`,
                                                                                                selectedValue
                                                                                            );
                                                                                        }}
                                                                                        onBlur={(event) => {
                                                                                            const newValue = event.target.value;
                                                                                            formik.setFieldValue(
                                                                                                `investors.${index}.payments.payment_mode`,
                                                                                                newValue
                                                                                            );
                                                                                        }}
                                                                                        name="Payment_Mode"
                                                                                        options={paymentModes}
                                                                                        getOptionLabel={(option) => option.label}
                                                                                        freeSolo // Add this prop to allow free text input
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                label="Payment Mode"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid> */}
                                                                            </>
                                                                        )}

                                                                    <Grid item xs={12}>
                                                                        <Typography required variant="subtitle1" mb={1} mt={2}>
                                                                            Remark
                                                                        </Typography>
                                                                        <CustomTextArea
                                                                            name={`investors.${index}.remark`}
                                                                            multiline={true}
                                                                            minRows={2}
                                                                            sx={{ borderWidth: 2 }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item container xs={12} alignItems={'center'} style={{ paddingTop: '0px', marginLeft: '-12px' }}>
                                                                    {/* {(formik.values.investors[index].investor_id?.unallocated_amount ||
                                                                        0) > 0 && (
                                                                            <Grid item marginLeft={1}>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Checkbox
                                                                                        id="current"
                                                                                        checked={
                                                                                            formik.values.investors[index].is_unallocated
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            formik.setFieldValue(
                                                                                                `investors.${index}.is_unallocated`,
                                                                                                e.target.checked
                                                                                            );
                                                                                        }}
                                                                                    // sx={{ paddingLeft: '0px' }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="current"
                                                                                        variant="h5"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        Use Balance Amount (₹{' '}
                                                                                        {
                                                                                            formik.values.investors[index].investor_id
                                                                                                ?.unallocated_amount
                                                                                        }
                                                                                        )
                                                                                    </label>
                                                                                </Box>
                                                                            </Grid>
                                                                        )} */}
                                                                    {(formik.values.investors[index].investor_id?.unallocated_amount ||
                                                                        0) > 0 && <Grid item marginLeft={1.8} marginY={1}>
                                                                            <label
                                                                                // htmlFor="current"
                                                                                variant="h5"
                                                                                style={{
                                                                                    fontWeight: '500',
                                                                                    fontSize: '0.9rem',
                                                                                }}
                                                                            >
                                                                                Balance Amount: (₹{' '}
                                                                                {
                                                                                    formik.values.investors[index].investor_id
                                                                                        ?.unallocated_amount
                                                                                }
                                                                                )
                                                                            </label>
                                                                        </Grid>}
                                                                    {
                                                                        // formik.values.investors[index].investor_id?.investor_status == 'approved' &&
                                                                        dealsModule?.received_amount_add_edit && formik.values?.investors[index]?.funds_transferred_amount != null && formik.values?.investors[index]?.funds_transferred_amount != '' && <> <Grid item marginLeft={1}>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Checkbox
                                                                                    id="is_allocation_email"
                                                                                    checked={formik.values.investors[index].is_allocation_email}
                                                                                    onChange={(e) => {
                                                                                        const isChecked = e.target.checked;
                                                                                        formik.setFieldValue(`investors.${index}.is_allocation_email`, isChecked);
                                                                                        // If "Send Allocation Email" checkbox is checked, uncheck the other checkbox
                                                                                        if (isChecked) {
                                                                                            formik.setFieldValue(`investors.${index}.is_email_sent`, false);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    htmlFor="is_allocation_email"
                                                                                    variant="h5"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    Send Allocation Email
                                                                                </label>
                                                                            </Box>
                                                                        </Grid>
                                                                            <Grid item marginLeft={2}>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Checkbox
                                                                                        id="is_email_sent"
                                                                                        checked={formik.values.investors[index].is_email_sent}
                                                                                        onChange={(e) => {
                                                                                            const isChecked = e.target.checked;
                                                                                            formik.setFieldValue(`investors.${index}.is_email_sent`, isChecked);
                                                                                            // If "Send Test Allocation Email" checkbox is checked, uncheck the other checkbox
                                                                                            if (isChecked) {
                                                                                                formik.setFieldValue(`investors.${index}.is_allocation_email`, false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="is_email_sent"
                                                                                        variant="h5"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        Send Test Allocation Email
                                                                                    </label>
                                                                                </Box>
                                                                            </Grid>
                                                                        </>}
                                                                </Grid>


                                                                {parseFloat(
                                                                    formik.values.investors[index].investor_id?.percentage
                                                                ) > 90 && (
                                                                        <p
                                                                            style={{
                                                                                margin: '4px 0 0',
                                                                                fontWeight: '700',
                                                                                fontSize: '0.9rem',
                                                                                color: 'rgb(248, 144, 97)'
                                                                            }}
                                                                        >
                                                                            Warning: Investor commitment exceeded 90%. Proceed with new
                                                                            contribution agreement or increase commitment amount.
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                )}
                            />
                        </Grid>
                        {formik.values.investors.length > 0 && (
                            <Grid item xs={12} mt={2} >
                                <CustomButton style={{ marginLeft: 'auto' }} type="submit">
                                    Submit
                                </CustomButton>
                            </Grid>
                        )}
                    </Form>
                </FormikProvider>}
            </Paper>
        </>
    )
}

export default AddTransactionsPopup