import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
    Box,
    Chip,
    FormControl,
    Grid,
    Input,
    InputBase,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Tab,
    Table,
    TableBody,
    TableHead,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CustomButton from 'ui-component/CustomButton';
import { StyledTableCell, StyledTableRow } from 'ui-component/TableStyles';
import { AddStartupReportData, FetchAllMasterReportGraphs } from 'api';
import { useParams, useNavigate } from 'react-router';
import { IoAddCircleSharp } from 'react-icons/io5';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MISBarGraph from 'views/startup/MISBarGraph';
import Loading from 'views/Loading';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        '& .MuiInputBase-root .MuiInputBase-input': {
            padding: '0.5rem 0.4rem'
        }
    },
    customSelectBox: {
        '& div#demo-simple-select': { padding: '10px 14px !important' }
    }
}));

const inputProps = {
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    inputMode: 'numeric',
    pattern: '[0-9.]*',
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
            'Delete',
            'Tab',
            '.'
        ];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9.]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === '-') {
            event.preventDefault();
        }
    },
    style: { textAlign: 'center', padding: '0.1rem 0.8rem' }
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const DynamicReport = (props) => {
    const classes = useStyles();
    let { report } = props;
    const { id } = useParams();
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'year').format('YYYY-MM'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM'));
    const [graphData, setGraphData] = useState([]);
    const [searchStatus, setSearchStatus] = useState(false);
    const { mutateAsync: uploadReportStartup, status: startupReportStatus, error: startupError } = AddStartupReportData();

    const {
        mutateAsync: fetchMasterReportGraph,
        isLoading: isMasterReportGraphLoading,
        isRefetching: isMasterReportGraphRefetching,
        status: masterReportGraphStatus,
        error: masterReportGraphError
    } = FetchAllMasterReportGraphs(fromDate,toDate);

    // console.log(masterReportGraphStatus,"masterReportGraphStatus",masterReportGraphError,"masterReportGraphError")

    if (report !== null || report !== undefined) {
    }

    let columns = [
        {
            label: 'Parameter',
            minWidth: 450,
            align: 'left',
            type: 'parameter'
        },
        {
            label: 'Unit',
            minWidth: 100,
            align: 'left',
            type: 'units'
        }
    ];

    const initailValues = {
        reportData: report.report_data && report.report_data.length > 0 ? report.report_data : [{ month: null }]
    };

    const formik = useFormik({
        initialValues: initailValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payload = { report_id: report._id, report_data: values.reportData };
            uploadReportStartup({ payload: payload, id: id });
        }
    });

    useEffect(() => {
        if (startupReportStatus == 'success') {
            toast.success('Report Updated!', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
                //   onClose: () => {
                //     navigate('/startups')
                //   }
            });
        } else if (startupReportStatus == 'error') {
            toast.error('Somethig went wrog', {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            // formik.resetForm()
        }
    }, [startupReportStatus,masterReportGraphStatus]);

    function handleAddDate() {
        formik.setFieldValue(`reportData.${formik.values.reportData.length}`, { month: null });
    }

    const toolTip = (formula) => {
        let formulaArray1 = formula?.split(/[-+*/()]/).filter((item) => item !== '' && item !== ' ');
        let formulaArray2 = formula?.split(/[a-zA-Z0-9]/g).filter((item) => item !== '' && item !== ' ');
        let _data_columns = (report !== null || report !== undefined) && report?.data_columns;
        let currentFormula = [];

        // console.log(formulaArray1,formulaArray2,"fSSS")
        
        formulaArray1.forEach((item,index) => {
            let temp = _data_columns?.filter((curr) => item?.includes(curr?.cell_name))[0]?.column_name;
            // console.log(temp, index);
            // formulaArray1[index] = temp;
            temp !== undefined && currentFormula?.push(temp);
            currentFormula.push(formulaArray2[index])
        });

        // console.log(formula,formulaArray1,currentFormula)

        // currentFormula == formula;
        // for (let index = 0; index < formulaArray1.length; index++) {
        //     currentFormula.push();
        //     let temp =
        //         _data_columns?.filter((item) => item?.cell_name === formulaArray1[index].trim())[0].column_name !== undefined
        //             ? _data_columns?.filter((item) => item?.cell_name === formulaArray1[index].trim())[0].column_name
        //             : '';
        //     if (formulaArray2[index] !== undefined) {
        //         currentFormula.push(formulaArray2[index]);
        //     }
        // }

        // return currentFormula.join('');
        // console.log(currentFormula,"currentFormula")
        return currentFormula?.join('');
    };

    const theme = useTheme();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFromDateChange = (newValue) => {
        setFromDate(moment(newValue).format('YYYY-MM'));
        // setToDate(moment(newValue).add(1, 'year').format('YYYY'));
        // setSearchStatus(false);
    };
    const handleToDateChange = (newValue) => {
        // setSearchStatus(false);
        // setFromDate(moment(newValue).subtract(1, 'year').format('YYYY'));
        setToDate(moment(newValue).format('YYYY-MM'));
    };

    // console.log(isSelectedReportLoading, selectedReportData, 'selectedReportDataselectedReportDataselectedReportData');
    // console.log('From Date:', fromDate, 'To Date: ', toDate);

    const [selectedValues, setSelectedValues] = useState([]);
    const [updatedValues, setUpdatedValues] = useState([]);

    const handleValueChange = (event) => {
        // const hasAllDeals = event.target.value.includes('All Deals');
        // if (hasAllDeals) {
        //     event.target.value = event.target.value.filter((item) => item === 'All Deals');
        // }
        // setSelectedValues(event.target.value);
        setUpdatedValues(event.target.value);
    };

    // console.log(selectedValues, 'selectedValues');

    let parameters =
        (report !== null || report !== undefined) &&
        report?.data_columns.map((item, index) => {
            return { label: item.column_name, value: item.column_name };
        });

    useEffect(() => {
            let consolidateGraphData = [];
            if (selectedValues.length > 0) {
                selectedValues.forEach((value, index) => {
                    fetchMasterReportGraph({
                        startupId: id,
                        reportId: report._id,
                        graphType: value
                    }).then(({ data }) => {
                        // console.log(data,selectedValues[1])
                        consolidateGraphData[index] =(data?.data?.report_data);
                    });
                });
                setGraphData(consolidateGraphData);
        }
        // console.log(searchStatus,"searchStatus")
    }, [searchStatus]);
    // console.log(fromDate,toDate,graphData,"graphData")
    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        style={{ flex: 1 }}
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Statement" {...a11yProps(0)} />
                        <Tab label="Graphical View" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <div style={{ borderRadius: '8px', maxHeight: '70vh' }} className="custom-table table-responsive mt-3">
                                <PerfectScrollbar>
                                    <Table className="w-100 table add-border">
                                        <TableHead>
                                            <StyledTableRow>
                                                {columns.map((item) => {
                                                    return (
                                                        <>
                                                            <th style={{ minWidth: item.minWidth }}>{item.label}</th>
                                                        </>
                                                    );
                                                })}
                                                {Object.keys(formik.values.reportData)?.map((item, key) => {
                                                    return (
                                                        <th
                                                            style={{
                                                                minWidth:
                                                                    Object.keys(formik.values.reportData).length - 1 === key ? 250 : 200,
                                                                maxWidth: 250
                                                            }}
                                                        >
                                                            <Grid display={'flex'} justifyContent={'center'} item gap={2}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DatePicker
                                                                        fullWidth
                                                                        views={['year', 'month']}
                                                                        // label="Select Date"
                                                                        size="small"
                                                                        className={classes.datePicker}
                                                                        value={formik.values.reportData[key].month}
                                                                        disableFuture={true}
                                                                        onChange={(newValue) => {
                                                                            formik.setFieldValue(
                                                                                `reportData[${key}].month`,
                                                                                moment(newValue).format('YYYY-MM')
                                                                            );
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField fullWidth sx={{ maxWidth: 220 }} {...params} />
                                                                        )}
                                                                        inputFormat="MM/YYYY"
                                                                        autoComplete="off"

                                                                        // disabled={isEditMode}
                                                                    />
                                                                </LocalizationProvider>
                                                                {Object.keys(formik.values.reportData).length - 1 === key && (
                                                                    <button
                                                                        className="button-79"
                                                                        type="button"
                                                                        style={{
                                                                            backgroundColor: 'transparent',
                                                                            padding: '0px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            borderRadius: '50%'
                                                                        }}
                                                                        onClick={() => handleAddDate()}
                                                                    >
                                                                        <IoAddCircleSharp size={25} color={theme.palette.success.main} />
                                                                    </button>
                                                                )}
                                                            </Grid>
                                                        </th>
                                                    );
                                                })}
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(report !== null || report !== undefined) &&
                                                report?.data_columns.map((item, index) => {
                                                    return (
                                                        <StyledTableRow>
                                                            {/* <td>{index + 1}</td> */}
                                                            <StyledTableCell sx={{ borderInline: '1px solid !important' }}>
                                                                {item.column_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell sx={{ borderInline: '1px solid !important' }}>
                                                                {item.column_type === 'percentage' ? '%' : 'INR'}
                                                            </StyledTableCell>

                                                            {Object.keys(formik.values.reportData).map((currentKey, inx) => {
                                                                return (
                                                                    <StyledTableCell sx={{ borderInline: '1px solid !important' }}>
                                                                        <Tooltip title={toolTip(item.formula)}>
                                                                            <Input
                                                                                fullWidth
                                                                                inputProps={inputProps}
                                                                                name={`reportData[${inx}].${item.column_name}`}
                                                                                value={formik.values.reportData[inx][item.column_name]}
                                                                                disabled={item.formula != '' ? true : false}
                                                                                onChange={(e) => {
                                                                                    // evaluateByFormula();
                                                                                    formik.setFieldValue(
                                                                                        `reportData[${inx}].${item.column_name}`,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                autoComplete="false"
                                                                                onBlur={(e) => {
                                                                                    const filteredData = report?.data_columns.filter(
                                                                                        (obj) => obj.formula !== ''
                                                                                    );
                                                                                    filteredData.forEach((element) => {
                                                                                        const fieldNames =
                                                                                            element.formula.match(/[A-Za-z]\d+/g);
                                                                                        const uniqueFieldNames = fieldNames
                                                                                            ? [
                                                                                                  ...new Set(
                                                                                                      fieldNames.map((name) =>
                                                                                                          name.replace('A', '')
                                                                                                      )
                                                                                                  )
                                                                                              ]
                                                                                            : [];
                                                                                        const columns_names = [];
                                                                                        uniqueFieldNames.forEach((element, index) => {
                                                                                            const column_name =
                                                                                                report?.data_columns[Number(element) - 1]
                                                                                                    .column_name;
                                                                                            columns_names[index] =
                                                                                                column_name != item.column_name
                                                                                                    ? formik.values.reportData[inx][
                                                                                                          column_name
                                                                                                      ] == undefined
                                                                                                        ? 0
                                                                                                        : formik.values.reportData[inx][
                                                                                                              column_name
                                                                                                          ]
                                                                                                    : e.target.value;
                                                                                        });
                                                                                        // console.log(uniqueFieldNames,columns_names, formik.values.reportData,"uniqueFieldNamesuniqueFieldNamesuniqueFieldNames")

                                                                                        const formatted_formula = element.formula.replace(
                                                                                            /([+\-*/])/g,
                                                                                            ' $1 '
                                                                                        );
                                                                                        const terms = formatted_formula
                                                                                            .split(/(\+|\-|\*|\/|\%)/)
                                                                                            .map((term) => term.trim());

                                                                                        // Create a new expression with A1, A2, A3, A4
                                                                                        const convertedExpression = terms
                                                                                            .map((term, index) => {
                                                                                                // Use A1, A2, A3, A4 for the first four terms, and keep the rest unchanged
                                                                                                if (index % 2 === 0) {
                                                                                                    return `A${Math.floor(index / 2) + 1}`;
                                                                                                }
                                                                                                return term;
                                                                                            })
                                                                                            .join('');

                                                                                        const replacedFormula = convertedExpression.replace(
                                                                                            /A\d+/g,
                                                                                            (match) => {
                                                                                                const index = parseInt(match.slice(1)); // Extract the number after 'A'
                                                                                                return columns_names[index - 1] || 0; // Use the corresponding value or 0 if not found
                                                                                            }
                                                                                        );

                                                                                        const result = eval(
                                                                                            replacedFormula.replace(/\s*/g, '')
                                                                                        );
                                                                                        formik.setFieldValue(
                                                                                            `reportData[${inx}].${element.column_name}`,
                                                                                            '' + result
                                                                                        );
                                                                                    });
                                                                                }}
                                                                                // sx={{
                                                                                //     '::before': { border: '0 !important' },
                                                                                //     '::after': { border: 0 }
                                                                                // }}
                                                                            />
                                                                        </Tooltip>
                                                                    </StyledTableCell>
                                                                );
                                                            })}
                                                        </StyledTableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </PerfectScrollbar>
                            </div>
                            <CustomButton sx={{ marginTop: 5 }} type="submit">
                                Save
                            </CustomButton>
                        </Form>
                    </FormikProvider>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                        <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                            <Grid item xs={12} sm={3} md={3}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        views={['year']}
                                        label="Select From FY"
                                        size="small"
                                        value={fromDate}
                                        disableFuture={true}
                                        onChange={handleFromDateChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputFormat="MM-YYYY"
                                        autoComplete="off"
                                        // defaultCalendarMonth={'04'}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        views={['year']}
                                        label="Select To FY"
                                        size="small"
                                        value={toDate}
                                        disableFuture={true}
                                        onChange={handleToDateChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        inputFormat="MM-YYYY"
                                        autoComplete="off"
                                        // defaultCalendarMonth={'03'}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Parameter</InputLabel>
                                    <Select
                                        multiple
                                        sx={{ height: '3.2rem' }}
                                        className={`${classes.customSelectBox}`}
                                        value={updatedValues}
                                        onChange={handleValueChange}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Parameter"
                                        renderValue={(selected) => (
                                            <>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={parameters.find((option) => option.value === value)?.label} />
                                                ))}
                                            </>
                                        )}
                                    >
                                        {parameters &&
                                            parameters.map((option, key) => (
                                                <MenuItem key={option.value} value={option.value} onClick={() => setSearchStatus(false)}>
                                                    {/* <Checkbox checked={field.value.includes(option.value)} /> */}
                                                    <ListItemText primary={option.label} />
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} alignItems={'center'} display={'flex'}>
                                <CustomButton onClick={() => {setSearchStatus(!searchStatus); setSelectedValues(updatedValues)}}>Fetch Data</CustomButton>
                            </Grid>
                        </Grid>
                        {!isMasterReportGraphLoading && (
                            <>
                                {graphData?.length > 0 && selectedValues?.length > 0 ? (
                                    graphData?.map((item, index) => (
                                        <MISBarGraph
                                            legend={selectedValues[index]}
                                            string={''}
                                            data={item?.map((current, inx) => {
                                                let Key = Object.keys(current)[0] !== undefined ? Object.keys(current)[0] : '';
                                                let Value =
                                                    Object.values(current)[0] !== undefined
                                                        ? Object.values(current)[0][selectedValues[index]] !== undefined
                                                            ? Number(Object.values(current)[0][selectedValues[index]])
                                                            : 0
                                                        : 0;

                                                return { x: Key, y: Value };
                                                // return Object.values(current)[0][selectedValues[index]];
                                            })}
                                        />
                                    ))
                                ) : (
                                    <Grid item xs={12} textAlign={'center'}>
                                        <Typography variant="h4" bgcolor={'#f3f4fa'} padding={'10px 16px'} borderRadius={2}>
                                            Apply Suitable Filters
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        )}
                        {isMasterReportGraphLoading && (
                            <Grid item xs={12}>
                                <Loading />
                            </Grid>
                        )}
                    </Grid>
                </CustomTabPanel>
            </Box>
        </div>
    );
};

export default DynamicReport;
