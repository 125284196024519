import React, { useState,useEffect} from 'react';
import { Paper, Box,Typography} from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import { FetchOnboarding,ExportInvestorsOnboarded,ExportDeals,DealsTargetFees } from 'api';
import moment from 'moment';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Card from 'ui-component/cards/Card';
import SelectField from 'ui-component/SelectField';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
const mode = ""
const TargetAmountFees = () => {
  const excel_report = "excel_report";
  const [title, setTitle] = useState('Partner Target Fees ');
  const theme = useTheme();
  const [stage, setStage] = useState('');
  const [searchStatus, setSearchStatus] = useState(false);
  const [reset, setreset] = useState(false)
 const [investorData, setInvestorData] = useState([]);
 const { isLoading: isStageLoading, isRefetching: isStageRefetching, data: stages } = ExportDeals();
    const { mutateAsync: fetchOnbardingInvestor, isLoading: isInvestorLoading, isRefetching: isSyndicateRefetching ,status: targetFeesStatus, error: targetFeesError} = DealsTargetFees()

    useEffect(() => {
      // console.log(updateInvestorStatus);
      
        if (targetFeesStatus == 'error') {
          setTimeout(()=>{
          toast.error(targetFeesError.response.data.message, {
            position: "top-left",
            // autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }, 400)
        }
      
    }, [targetFeesStatus, targetFeesError])

    useEffect(() => {
      if (investorData?.data?.length  > 0 ) {
        setTitle(`Partner Target Fees (${investorData?.data?.length})`)
      } else {
        setTitle(`Partner Target Fees`)
      }
    }, [investorData])
    const captureAndCopyScreenshot = () => {
      const tableElement = document.getElementById('target'); // Replace 'your-table-id' with the ID of your table element
      html2canvas(tableElement).then(canvas => {
        canvas.toBlob(blob => {
          navigator.clipboard.write([
            new ClipboardItem({ [blob.type]: blob }),
          ]);
          toast.success('Screenshot copied!', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      });
    };
    useEffect(() => {
        if (searchStatus) {
         fetchOnbardingInvestor({ "stage":stage,})
        .then(({ data }) => {
          setInvestorData(data)
        })
    }
      }, [searchStatus,reset])
    return(
        <>
        <ToastContainer />
           <Box sx={{ width: '100%' }}>
             <BreadcrumbHeader title={title} />
           </Box>  
          <Card style={{ paddingLeft: '16px', paddingRight: '16px' }}> 
            <Grid container direction="row" columnSpacing={3} rowSpacing={2} mb={2} >
            <Grid item sm={4} xs={6}>
             <SelectField
              size="small"
              onChange={(event) => {
               setStage(event.target.value)
               setSearchStatus(false)
              }}
              label="Deals"
              value={stage}
              options={stages}
            />
            </Grid>
              <Grid item sm={1} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <button
                className="button-79"
                style={{ padding: '0.4rem 1rem', display: 'flex', alignItems: 'center', height: '40px', background: (searchStatus && stage) ? "#c62828" : "#283593" }}
                type='button'
                onClick={() => {
                    if (searchStatus && stage !== '') {
                     setStage('')
                    setreset(!reset)
                    }
                    setSearchStatus((oldVal) => !oldVal)
                }}
                fullWidth
                // sx={{height:'100%'}}
                >
                {(searchStatus && stage !== '') ? "Reset" : "Search"}
                </button>
              </Grid>
            </Grid>            
           <>
            <div style={{borderRadius: '8px'}} className='custom-table table-responsive lp-summary-table investor-onboarding-table'>
              <table className='w-100 table add-border'id="target">
                {investorData?.data?.length > 0 && searchStatus && stage && targetFeesStatus=='success' && (
                   <thead>
                   <tr>
                    <th>Name</th>
                    {/* <th>Target Commitment Amount</th> */}
                    <th>Target Amount Percentage</th>
                    <th>Target Amount</th>
                    <th>Achieved Target Amount</th>
                    <th>Remaining Amount</th>
                   </tr>
                  </thead>
                )}
              <tbody>
            {investorData?.data?.length > 0 && searchStatus && stage && targetFeesStatus=='success' ? (
              investorData.data.map((partner, index) => (
                
               <tr key={index}>
                 <td>{partner.name}</td>
                 {/* <td>{partner._ref_target_commitment_amount}</td> */}
                 <td>{partner.target_amount_percentage}</td>
                 <td>{partner.ref_target_amount}</td>
                 <td>{partner.ref_achieved_target_amount}</td>
                 <td>{partner.ref_remaining_amount}</td>
                </tr>
              )  )
              
            ):(
              <tr>
               <td className='text-center' colSpan={5}><Typography variant="h4">Select Deal First</Typography></td>
              </tr>
             )}
              {investorData?.data?.length > 0 && searchStatus && stage  && targetFeesStatus=='success' &&  (
                <tr >
                 <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>Total</td>
                 {/* <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetCommitmentAmount}</td> */}
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetAmountPercentage}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalTargetAmount}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalAchievedTargetAmount}</td>
               <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{investorData.total.totalRemainingAmount}</td>
                </tr>
              )}
            </tbody>
              </table>
            </div>  
            <div className='text-end'>
            {investorData?.data?.length > 0 && searchStatus && stage  &&  targetFeesStatus=='success' && (
              <CustomButton style={{marginTop:"20px"}}
                onClick={captureAndCopyScreenshot}
               size="medium"
               sx={{ height: '40px' }} 
              >
               Copy As Image
              </CustomButton>
            )}
            </div>
           </>
         </Card>
    </>
  )
}
export default TargetAmountFees