  // actions.js
export const setPermissions = (permissions) => ({
  type: 'SET_PERMISSIONS',
  payload: permissions,
});

// reducers.js
const initialState = {
  permissions: [],
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PERMISSIONS':
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
};

export default permissionsReducer;