import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { FetchTargetAmount, UpdateVenturePercentage, UpdateTargetAmount } from 'api';
import { toast, ToastContainer } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';

export default function TargetFees({ setTitle }) {
  const {
    isLoading: isCompletedDealsLoading,
    data: venturePartnerData,
    refetch: refetchTargetAmountData,
  } = FetchTargetAmount();
  const {
    mutateAsync: updatePercentages,
    status: updateAmountsStatus,
    error: updateAmountError,
  } = UpdateTargetAmount();

  const calculateTotalTargetAmount = () => {
    if (formik.values) {
      return formik.values.reduce((total, partner) => total + Number(partner.target_commitment_amount || 0), 0);
    }
    return 0;
  };

  const formik = useFormik({
    initialValues: venturePartnerData,
    enableReinitialize: true,
    // onSubmit: async (values) => {
    //     console.log(values,"valuesvaluesvaluesvaluesvaluesvaluesvaluesvalues")
    //     updatePercentages({ payload: values });
    //     if (updateAmountsStatus === 'success') {
    //       refetchTargetAmountData();
    //     }
    // },
    onSubmit: async () => {
        // Extract only the required fields from the formik values
        const updated_data = formik.values.map((partner, index) => ({
            _id:partner._id,
          target_commitment_amount: partner.target_commitment_amount,
          target_amount_percentage: partner.target_amount_percentage,
        }));
    
        // Send the payload to the server
         updatePercentages({ payload:updated_data });
    
        // Check the status and refetch data if successful
        if (updateAmountsStatus === 'success') {
          refetchTargetAmountData();
        }
      },
  });

  // useEffect(() => {
  //   setTitle('Target Fees');
  // }, []);
  useEffect(() => {
    // window.location.reload();
    if (updateAmountsStatus == 'success') {
      toast.success('Percentage Added', {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        window.location.reload();
    }, 1000);
    }
    if (updateAmountsStatus == 'error') {
      toast.error(updateAmountError?.response?.data?.message, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    }
  }, [updateAmountsStatus])
  // console.log(formik.values,"formik.valuesformik.valuesformik.valuesformik.valuesformik.valuesformik.values")

  return (
    <>
      <ToastContainer />
      <div className="custom-table mt-3">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <table style={{borderRadius: '8px',overflow: 'hidden'}} className="w-100">
              <thead>
                <tr>
                  {/* <th style={{ width: '15%' }}>LP Code</th> */}
                  <th style={{ width: '25%' }}>Name</th>
                  <th style={{ width: '25%' }}>Email</th>
                  {/* <th style={{ width: '15%' }}>Type</th> */}
                  <th colSpan={2} style={{ width: '30%' }}>Target Commitment Amount(In Crore)</th>
                  <th colSpan={2} style={{ width: '20%' }}>Target Amount Percentage</th>
                </tr>
              </thead>
              <tbody>
                {formik.values ? (
                  formik.values.map((partner,index) => (
                    <tr key={partner._id}>
                      {/* <td>{partner.lp_code}</td> */}
                      <td>{partner.name}</td>
                      <td>{partner.email}</td>
                      {/* <td>{partner.user_type}</td> */}
                      <td colSpan={2}>
                        <input
                          className="form-control text-center w-70"
                          type="text"
                          
                          name={`data.${index}.target_commitment_amount`}
                          defaultValue={partner.target_commitment_amount}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^[0-9]*[.]?[0-9]+$/.test(inputValue) || inputValue === '') {
                                    formik.values[index].target_commitment_amount = inputValue;
                                    const totalTargetAmount = formik.values.reduce((total, partner) => total + Number(partner.target_commitment_amount || 0), 0);
                                    formik.values.forEach((partner, i) => {
                                        const targetAmount = Number(partner.target_commitment_amount || 0);
                                        const targetAmountPercentage = totalTargetAmount !== 0 ? ((targetAmount / totalTargetAmount) * 100).toFixed(2) : 0;
                                        formik.values[i].target_amount_percentage = targetAmountPercentage;
                                        formik.setFieldValue(`${i}.target_amount_percentage`, formik.values[i].target_amount_percentage);
                                    });
                                  }
                                }}    
                               min={0}
                               max={100}
                            />
                         </td>
                      <td colSpan={2}>
                      {formik.values[index].target_amount_percentage} 
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Loading...</td>
                  </tr>
                )}
              </tbody>
              <tbody>
                <tr>
                  <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}} colSpan={2}>Total</td>
                  <td style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}} colSpan={2}>{calculateTotalTargetAmount()}</td>
                  <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}} colSpan={2}>{calculateTotalTargetAmount() > 0 ? "100%" : "0%"}</td>
                </tr>
              </tbody>
            </table>
            <div className="mt-2 text-end">
                <button className="button-79" type="submit">
                  Save All
                </button>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
}
