import { useTheme } from '@emotion/react'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { FetchUserFeedbackDetails, SubmitFeedbackForm, ViewUserFeedbackResponse } from 'api'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import Cookies from 'js-cookie'
import React from 'react'
import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader'
import CustomButton from 'ui-component/CustomButton'
import Card from 'ui-component/cards/Card'
import CustomField from 'ui-component/formik/CustomField'
import Loading from 'views/Loading'
import PerfectScrollbar from "react-perfect-scrollbar"
import Clickable from 'utils/Clickable'

const ViewFeedbackResponse = ({ partner_notification_sent_id,handleClose }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    let useHistory = window.history;
    // console.log(feedbackSelected,"feedbackSelected")
    const [formikValues, setFormikValues] = React.useState(
        {
            "master_startup_id": "",
            "feedback_master_id": "",
            "partner_notification_sent_id": partner_notification_sent_id,
            "template_name": null,
            "questions": [],
            "pitch_deck": [],
            "submitted_by": "",
            "submitted_date": "",
            "admin_partner_id": ""
        }
    )
    const {
        mutateAsync: fetchUserFeedbackDetails,
        isLoading: isUserFeedbackLoading,
        isRefetching: isUserFeedbackRefetching,
        status: userFeedbackLoadingStatus,
        error: userFeedbackLoadingError
    } = ViewUserFeedbackResponse();

    React.useEffect(() => {
        fetchUserFeedbackDetails({
            "partner_notification_sent_id": partner_notification_sent_id
        }).then(({ data }) => {
            // console.log(data?.data,"data")
            setFormikValues({
                ...data?.data,
                "master_startup_id": data?.data?.master_startup_id,
                "feedback_master_id": data?.data?.feedback_master_id,
                template_name: data?.data?.template_name, questions: data?.data?.questions?.map(item => {
                    return {
                        ...item,
                        answer: item?.answer != undefined ? item?.answer : [""]
                    }
                })
            })
        }).catch((err) => {
            console.log("err", err)
        });
    }, [partner_notification_sent_id])

    const formik = useFormik({
        initialValues: formikValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true
    })

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const handleCheckboxChange = (event, questionIndex, answerIndex) => {
        const { value, checked } = event.target;
        // formik.values.que((prev) =>
        //   checked ? [...prev, value] : prev.filter((v) => v !== value)
        // );
        formik.setFieldValue(`questions.${questionIndex}.answer`, checked ? [...formik.values.questions[questionIndex].answer, value] : formik.values.questions[questionIndex].answer?.filter((v) => v !== value))
    };

    const renderInputs = (type, currentIndex) => {
        const numberOfOptions = formik.values.questions[currentIndex].options?.length;
        switch (type) {
            case 'single_select':
                return (
                    <Grid container mt={2} >
                        <RadioGroup
                            style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-start',gap: "2.4rem" }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={formik.values.questions[currentIndex]?.answer[0]}
                            onChange={(e) => {
                                formik.setFieldValue(`questions.${currentIndex}.answer.${0}`, e.target.value)
                            }}
                        >{
                                formik.values.questions[currentIndex].options &&
                                formik.values.questions[currentIndex].options.length > 0 &&
                                formik.values.questions[currentIndex].options.map((currentItem, index) => (<>
                                    <FormControlLabel disabled required={formik.values.questions[currentIndex]?.required} className='form-label' sx={{
                                        "&.form-label > .MuiStack-root > .MuiFormControlLabel-asterisk ": {
                                            display: 'none'
                                        }
                                    }} value={currentItem} control={<Radio disabled />} label={currentItem} />
                                    {/* <CustomField name={`questions.${currentIndex}.options.${index}`} label={`Option ${index + 1}`} value={formik.values.questions[currentIndex]?.options[index]} onChange={(e) => {
                                    formik.setFieldValue(`questions.${currentIndex}.options.${index}`, e.target.value)
                                }} /> */}
                                </>))
                            }
                        </RadioGroup>
                    </Grid>)

            case 'single_line_text':
                return <>
                    <TextField disabled fullWidth sx={{ marginBlock: 3 }} required={formik.values.questions[currentIndex]?.required} name={`questions.${currentIndex}.answer.${0}`} value={formik.values.questions[currentIndex]?.answer[0]} onChange={(e) => {
                        formik.setFieldValue(`questions.${currentIndex}.answer.${0}`, e.target.value)
                    }} label={'Answer'} placeholder="Your Answer" />
                </>


            case 'multi_select':
                return (
                    <Grid container mt={2} >
                        <FormGroup sx={{flexDirection: 'row', justifyContent: 'flex-start', gap: 5 }}>
                            {formik.values.questions[currentIndex].options &&
                                formik.values.questions[currentIndex].options.length > 0 &&
                                formik.values.questions[currentIndex].options.map((option, index) => (
                                    <FormControlLabel
                                        disabled
                                        required={formik.values.questions[currentIndex]?.required}
                                        key={option}
                                        control={
                                            <Checkbox
                                                disabled
                                                value={option}
                                                defaultChecked={formik.values.questions[currentIndex].answer?.includes(option)}
                                                onChange={(e) => handleCheckboxChange(e, currentIndex, index)}
                                            />
                                        }
                                        label={option}
                                    />
                                ))}
                        </FormGroup>
                    </Grid>)

            case 'multi_line_text':
                return <>
                    <TextField disabled fullWidth minRows={5} required={formik.values.questions[currentIndex]?.required} sx={{ marginBlock: 3 }} value={formik.values.questions[currentIndex]?.answer[0]} name={`questions.${currentIndex}.answer.${0}`} onChange={(e) => {
                        formik.setFieldValue(`questions.${currentIndex}.answer.${0}`, e.target.value)
                    }} label={'Answer'} placeholder="Your Answer" multiline />
                </>

            default:
                return <></>
        }
    }
    let hasPitchRecord = formik.values?.pitch_deck?.length > 0 && formik.values?.pitch_deck?.find(item => item?.name == "Pitch Deck")?.url;
    let pitchRecord = formik.values?.pitch_deck?.find(item => item?.name == "Pitch Deck")?.url;
    let hasPitchVideo = formik.values?.startup_detail && formik.values?.startup_detail?.videos?.length > 0;
    let pitchVideo = formik.values?.startup_detail?.videos[0]?.url;

    // console.log(formik?.values, "Values")
    return (
        <>
            {/* {userFeedbackLoadingStatus == "error" ? <div>
                <Card style={{ width: '100%',height: '70vh', padding: '20px', textAlign: 'center' }}>
                   
                        <Typography variant="h4" color="error" gutterBottom>
                            Access Denied
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            You do not have the necessary permissions to access this page.
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Please check your credentials or contact support if you believe this is an error.
                        </Typography>
                        <CustomButton variant="contained" color="primary" onClick={() => {}} style={{ marginTop: '20px' }}>
                            Go to Home Page
                        </CustomButton>
                </Card>
            </div> : */}
            <>
                {(isUserFeedbackLoading || isUserFeedbackRefetching || formikValues?.template_name == null)
                    ? <Loading /> :
                    <Card>
                        <Box style={{ paddingBottom: 1, border: 2, borderBottom: 'divide'}}>
                            <Typography variant='h2'sx={{marginBottom: 3,display:'flex', alignItems: 'center',gap:"5px"}} >
                            <span>{formik.values.template_name} - </span> <span style={{cursor: 'pointer', width: 'max-content', marginTop: 5}}  className='dhf-company-name'>{formik.values?.startup_detail?.company_name}</span>
                            </Typography>
                            {/* //onClick={() => {handleClose();navigate(`/applicationListing/startUpView/${formik.values?.master_startup_id}`)}} */}
                            <Typography variant='h4' style={{ marginTop: 10 }}>
                                {hasPitchRecord && pitchRecord && pitchRecord != "" && <a style={{ textDecoration: 'none', color: "#0000FF" }} target='_blank' href={`${pitchRecord}`}>Pitch Deck</a>}
                            </Typography>
                            <Typography variant='h4' style={{ marginBlock: 10 }}>
                                {hasPitchVideo && pitchVideo && pitchVideo != "" && <a style={{ textDecoration: 'none', color: "#0000FF" }} target='_blank' href={`${pitchVideo}`}>Call Recording</a>}
                            </Typography>
                        </Box>
                        <Box>
                        <PerfectScrollbar style={{ height: '100%', maxHeight: '65vh', overflowX: 'hidden' }}>
                            {
                                formik.values?.questions?.map(
                                    (question, index) => {
                                        const numberOfQuestions = formik.values?.questions?.length
                                        return <Box sx={{marginBottom: (index == numberOfQuestions - 1 ? 0 : 2), paddingRight: 2}}>
                                            <Typography variant='h4'>
                                                Q{index + 1}: {question?.question_value}
                                            </Typography>
                                            {/* <Typography variant='subtitle1' marginY={2}>
                                            {question?.answer}
                                        </Typography> */}
                                            {renderInputs(question.option_type, index)}
                                        </Box>
                                    }
                                )
                            }
                            </PerfectScrollbar>
                        </Box>
                    </Card>
                }
            </>
            {/* } */}
        </>
    )
}

export default ViewFeedbackResponse