import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';
export const AddBankTransactions = (setProgress) => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/statement`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                onUploadProgress: (data) => {
                    setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
  };
  
  export const GetAllReceipts = ({ page, search, sort = 1, limit = 20 }) => {
    return useQuery(
        ['fetch-statatement-details', page, sort, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/statement?page=${page}&searchText=${search}${
                    sort != 0 ? `&sort=${sort}` : ''
                }&limit=${limit}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                }
            });
        },
        {
            select: (data) => (data.data),
            refetchOnWindowFocus: true,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
  };

  export const GetReceiptDetail = (id,page, sort=1, search, limit) => {
    return useQuery(
      ['fetch-statement-evbweibguiewbvibeuibv'+id,page, sort, limit], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/statement/${id}?page=${page}&searchText=${search}&limit=${limit}${sort!=0 ? `&sort=${sort}`:""}&field=_id`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        }) 
    },{
      select: (data)=>{
        return data.data
      },
      refetchOnWindowFocus:false,
      enabled: !!id,
      retry: (failureCount, error)=>{
        return RetryOnError(failureCount, error)
      },
      onError: (err)=>{
        return verifyToken(err)
      },
    })
  }

  export const UpdateTransactions = () => {
    return useMutation(({payload}) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/statement`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          "Content-type": "application/json"
        },
        data:payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }

  export const AppoveTransaction = () => {
    return useMutation(({payload}) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/statement/update-status`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          "Content-type": "application/json"
        },
        data:payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }