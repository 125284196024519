import React from 'react'
import { useEffect, useState,lazy } from 'react';
import { useQuery } from 'react-query';
import {FetchActiveInactiveInvestorData,ExportActiveInactive} from 'api';
import Loading from 'views/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton from 'ui-component/CustomButton';
import html2canvas from 'html2canvas';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import Clickable from 'utils/Clickable';
import { Tooltip} from '@mui/material';
import { Box} from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';

export default function InactiveActiveInvestor(){
    const [title, setTitle] = useState('Active / Inactive Investors');
    const { isLoading: isActiveDataLoading, data: activeData } = FetchActiveInactiveInvestorData();
    const { mutateAsync: refetchExportActive, isLoading: isExportLplistersLoading,isRefetching: isExportLplistersRefetching } = ExportActiveInactive();
    const datacount = activeData?.headers?.length
    const datashow = datacount-1

    // useEffect(() => {
    //     if (activeData?.data?.length > 0 ) {
    //       setTitle(`Active / Inactive Investors (${activeData?.data?.length})`)
    //     } else {
    //       setTitle(`Active / Inactive Investors`)
    //     }
    //   }, [activeData])
    // console.log(activeData,"activeDataactiveData34444")
    if (isActiveDataLoading) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
      }
      function download(investor_partner_id) {
        // setIsExportLoading(true);
        // console.log(investor_partner_id,"99999999999")
        refetchExportActive({investor_partner_id})
        .then(({ data }) => {
            // setIsExportLoading(false);
      
            const link = document.createElement('a');
            link.href = data.data.url;
            link.target = '_blank';   
            link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
            link.click();
          })
      }
    const captureAndCopyScreenshot = () => {
        const tableElement = document.getElementById('active_inactive_table'); // Replace 'your-table-id' with the ID of your table element
        html2canvas(tableElement).then(canvas => {
          canvas.toBlob(blob => {
            navigator.clipboard.write([
              new ClipboardItem({ [blob.type]: blob }),
            ]);
            toast.success('Screenshot copied!', {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        });
      };
    return (
        <>
        <Box sx={{ width: '100%' }}>
             <BreadcrumbHeader title={title} />
           </Box> 
    <div className='custom-card card'>
     <ToastContainer /> 
      {/* <div className='card-header'>
      </div> */}
      <div className='card-body'>
            <div style={{borderRadius: '8px'}} className='custom-table table-responsive lp-summary-table'>
                <div className='col-lg-9 col-12 offset-lg-1' >
                    <div className='row cpcs-cards' >
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>Total Investors:</div>
                                <div className='value-text text-center'> {activeData?.totalInvestor?.totalInvestor}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>Active Investors:</div>
                                <div className='value-text text-center'> {activeData?.totalInvestor?.totalActiveInvestor}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>Inactive Investors:</div>
                                <div className='value-text text-center'>  {activeData?.totalInvestor?.totalInactiveInvestor}</div>
                            </div>
                        </div> 
                    </div>
                </div>
               <table style={{borderRadius: '8px', overflow: 'hidden'}} className='w-100 table add-border' id="active_inactive_table">
            <thead>
                {/* <tr >
                    <th className='text-start' colSpan={2} style={{ fontWeight: "900" }}>Total Investor: {activeData?.totalInvestor?.totalInvestor}</th>
                    <th colSpan={2} style={{ fontWeight: "900" }}>Active Investor: {activeData?.totalInvestor?.totalInactiveInvestor}</th>
                    <th colSpan={2} style={{ fontWeight: "900" }}>Inactive Investor: {activeData?.totalInvestor?.totalActiveInvestor}</th>
                    <th colSpan={datacount} className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}></th>
                </tr> */}
              {/* <tr>
                <th colSpan={2} className='text-start' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>COUNT Of Partner</th>
                <th colSpan={2} className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>Syndicate Name</th>
                <th colSpan={datashow} className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}></th>
              </tr> */}
              <tr>
              <th className='text-start' style={{ fontWeight: '900', backgroundColor: '#0000FF', color: '#fff' }}>Partners</th>
              {activeData?.headers.map((header, index) => (
                <th key={index} className='text-center' style={{ fontWeight: '900', backgroundColor: '#0000FF', color: '#fff' }}>{header}</th>
               ))}
               {/* <th className='text-center' style={{ fontWeight: '900', backgroundColor: '#0000FF', color: '#fff' }}>Grand Total</th> */}
               <th className='text-center' style={{ fontWeight: '900', backgroundColor: '#0000FF', color: '#fff' }}>Export</th>
              </tr>
            </thead>
            <tbody>
              {activeData?.data.map((partner, partnerIndex) => (
                <tr key={partnerIndex}>
                 <td>{partner?.name}</td>
                 {partner?.syndicateData.map((syndicateValue, syndicateIndex) => (
                   <td key={syndicateIndex}>{syndicateValue}</td>
                 ))}
                 <td>{partner?.totalInvestorCount}</td>
                 <td>
                 <Clickable style={{display: 'flex', alignItems:'center'}} onClick={() => download(partner?.investor_partner_id)}><Tooltip title="Export As Excel"><div style={{display: 'flex', alignItems:'center'}}>
                   <FileDownloadRoundedIcon className='icon-size' stroke={1.3} size="2rem"/>
                 </div></Tooltip></Clickable>
                  {/* <button >Your Button</button> */}
                 </td>
                </tr>
              ))}
              <tr className='total-row'>
                <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>Total</td>
                {activeData?.totalHeader.map((headerTotal, index) => (
                    <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}} key={index}>{headerTotal}</td>
                    ))}
                    <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}></td>
              </tr>
            </tbody>
               </table>
            </div>
           <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right',marginTop: '10px' }}>
            <CustomButton
             onClick={captureAndCopyScreenshot}
             size="medium"
             sx={{ height: '40px' }} 
            >
            Copy As Image
           </CustomButton>
           </div>
        </div>
    </div>
    </>
    )
}