import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// CSS
import 'react-toastify/dist/ReactToastify.css';
import "react-awesome-lightbox/build/style.css";
import 'assets/scss/custom.css';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        
        <StyledEngineProvider injectFirst>
            {process.env.REACT_APP_SERVER && process.env.REACT_APP_SERVER == "demo" ? <div className='demo-server-block'>YOU ARE USING BACKRR SANDBOX ENVIRONMENT</div> : "" }
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                 <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
