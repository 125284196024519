import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';

const CustomButton = ({color=[false, false], size="large", variant="contained", loading=false, children,...props}) => {
  const customization = useSelector((state) => state.customization);
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    color: 'white',
    backgroundColor: color[0] || theme.palette.secondary[800],
    borderRadius: `${customization.borderRadius}px`,
    '&:hover': {
      backgroundColor: color[1] || theme.palette.secondary.main,
    },
  }));

  return (
    <ColorButton variant={variant} size={size} loading={loading} {...props}>{children}</ColorButton>
  );
}
CustomButton.propTypes = {
  color: PropTypes.array,
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};
export default CustomButton;
