import { useState, useEffect, useRef } from 'react';
import { Paper,TextField,Chip} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import {AddDocument ,DocumentTagsList} from 'api';
import { toast, ToastContainer } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';

const initialValues = {
    name: '',
}

const NewFolderCreate = ({onClose,parent_id}) => {
    const theme = useTheme();
    const [tags, setTags] = useState([]);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const { data: tagData, isLoading: isTagsLoading } = DocumentTagsList();
    const { mutateAsync: uploadFileFolder, status: uploadFileFolderStatus, error: uploadFileFolderError } = AddDocument();


    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = { id: inputValue, text: inputValue };
            setTags([...tags, newTag]);
            setInputValue('');
        }
    };

    const handleDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
      };
    
      const handleAddition = (newTags) => {
    
        const uniqueNewTags = newTags.filter((newTag) =>
          !tags.some((tag) => tag.text === newTag.text)
        );
        
        setTags((prevTags) => [...prevTags, ...uniqueNewTags]);
      };
    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            // console.log("Folder Name", values)
            const formData = new FormData();
            const tagData = tags.map(tag => ({ name: tag.text }));
            if (tagData?.length > 0) {
                const arrayOfTags = JSON.stringify(tagData);
                formData.append(`tagsData`, arrayOfTags);
            }
            if(parent_id != ""){
              formData.append("parent_id", parent_id)
            }

            formData.append("name", values.name)
            uploadFileFolder({ payload: formData })
        }
    })  

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      }
      useEffect(() => {
        if (tagData) {
          setTagSuggestions(tagData);
        //   const idArray = investor.tags.split(',');
        //   const filteredData = tagData.filter(item => idArray.includes(item.id));
        //   if(filteredData.length > 0){
        //     setTags(filteredData);
        //   }
        }
      }, [tagData]);


    useEffect(() => {
        if (uploadFileFolderStatus == 'success') {
          toast.success('Folder Created!', {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
                onClose()
            }
          });
        }
        if (uploadFileFolderStatus == 'error') {
          toast.error(uploadFileFolderError?.response?.data?.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
    
        }
      }, [uploadFileFolderStatus])
     
    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px 30px 20px', flex: 1 }}>
                <h3 style={{ textAlign: 'left', }}>New Folder</h3>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}  style={{ paddingTop: '10px', paddingBottom: '15px', margin: 0 }}>
                        <div className='custom-form mt-4'>
                            <div className='mb-3'>
                              <CustomField 
                                  name="name" 
                                  label="Folder Name" 
                                  isFocusedprops={true}
                              />
                            </div>

                            <div>
                              <Autocomplete className='crossdisable'
                                multiple
                                id="tag-input"
                                name="tagsData"
                                options={tagSuggestions}
                                getOptionLabel={(option) => option.text}
                                value={tags}
                                onChange={(_, newValue) =>   handleAddition(newValue)}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={option.id}
                                            label={option.text}
                                            {...getTagProps({ index })}
                                            onDelete={() => handleDelete(option)}
                                        />
                                    ))
                                }
                                inputValue={inputValue}
                                onInputChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tags" placeholder="Enter tags" />
                                )}
                                />
                                </div>
                            <div className='mt-4 text-end'>
                                <button type="submit" className='button-79 transparent-btn me-2' onClick={onClose}>Cancel</button>
                                <button type="submit" className='button-79'>Create</button>
                            </div>
                        </div>
                    </Form>

                </FormikProvider>
            </Paper>
        </>
    )
}

export default NewFolderCreate