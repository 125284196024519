
import { useState, useRef } from 'react'
import { TextField, Paper, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/SelectField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Clickable from 'utils/Clickable';
import pdfIcon from '../../assets/images/icons/pdf.png';
import xlsxIcon from '../../assets/images/icons/xlsx.png';
import pngIcon from '../../assets/images/icons/png.png';
import doc2Icon from '../../assets/images/icons/doc2.png';
import jpgIcon from '../../assets/images/icons/jpg.png';

const StartupChecklistDocumentPopup = ({ taskDocument,handleClose2,setOpen2 }) => {
    const theme = useTheme();
    const initialValues = {
    }
    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {

        }
    });

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    const CustomTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px',
                    borderRightWidth: '0px'
                },
            },
        },
    })(TextField);

    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px',overflowY: "auto", maxHeight: "90vh"}} >
                <h3 style={{ textAlign: 'center' }}>Documents</h3>
                <ul className="document-list mt-2">
                    {taskDocument?.map((item, index) => (
                        <>
                            <li className="d-flex align-items-center">
                                <Clickable className="icon" onClick={()=>window.open(item.url, 'blank')}>
                                <img 
                                // src={pdfIcon} 
                                className="ud-icon"
                                src={
                                    item?.filename.split('.').pop() == 'pdf'
                                        ? pdfIcon
                                        : item?.filename.split('.').pop() == 'png'
                                        ? pngIcon
                                        : item?.filename.split('.').pop() == 'xlsx'
                                        ? xlsxIcon
                                        : item?.filename.split('.').pop() == 'jpg' || item?.filename.split('.').pop() == 'JPEG'
                                        ? jpgIcon
                                        : doc2Icon
                                }
                                alt="icon"
                                />
                                </Clickable>
                                <Clickable className="ms-2" onClick={()=>window.open(item.url, 'blank')}>
                                 <div className="xs-title font-16 text-start">{item.filename}</div>
                                 {/* <div className="sm-title font-12 text-start mt-1">{item.date_format}</div> */}
                               </Clickable>
                            </li>
                        </>
                    ))}
                </ul>
            </Paper>
        </>
    );
}

export default StartupChecklistDocumentPopup;
