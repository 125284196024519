import { useState, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Paper, Box, Stack, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import AsyncButton from 'ui-component/AsyncButton';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'views/Report/MissingInvestorLising';
import { ExportInvestors,FetchInvestorsFilters } from 'api';
import Clickable from 'utils/Clickable';
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { IconEdit } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from 'ui-component/Modal';
import PartnerPopup from 'views/investor/kyc/PartnerPopup'
import Loading from 'views/Loading';
import filterIcon from '../../assets/images/icons/filter.png'
import filterFillIcon from '../../assets/images/icons/filter_fill.png'
import MissingPopup from './MissingPopup'
const columns = [
  {
    label: 'Name',
    minWidth: 170,
    align: 'left',
    type: 'name',
    image: false
    //   link: true,
  },
  {
    label: 'Email',
    minWidth: 250,
    align: 'left',
    type: 'email'
  },
  {
    label: 'Mobile',
    minWidth: 150,
    align: 'left',
    type: 'mobile_number'
  },
  {
    label: 'Syndicate Name',
    minWidth: 150,
    align: 'left',
    type: 'syndicate_name'
  },
  {
    label: 'Referral Partner',
    minWidth: 150,
    align: 'center',
    type: 'referral_partner'
  },
  {
    label: 'Registered',
    minWidth: 170,
    align: 'left',
    type: 'createdAt',
    sort: true,
  },
  {
    label: 'Actions',
    minWidth: 150,
    align: 'right',
    type: 'actions'
  },
];
// const MissingNominee = [
//   { value: 'Yes', label: 'Yes' },
//   { value: 'No', label: 'No' },
// ];

// const MissingAddress = [
//   { value: 'Yes', label: 'Yes' },
//   { value: 'No', label: 'No' },
// ];
// const MissingBank = [
//   { value: 'Yes', label: 'Yes' },
//   { value: 'No', label: 'No' },
// ]
// const Transaction = [
//   { value: 'Yes', label: 'Yes' },
//   { value: 'No', label: 'No' },
// ]
// const LoggedIn = [
//   { value: 'Yes', label: 'Yes' },
//   { value: 'No', label: 'No' },
// ]
const mode = "approved"
const MissingInvestor = () => {
  const [title, setTitle] = useState('Missing Investor Data');
  const theme = useTheme();
  const [page, setPage] = useState(10000000);
  const [sort, setSort] = useState(-1);
  const [limit, setLimit] = useState(20);
  const [queryString, setQueryString] = useState('');
  const [search, setSearch] = useState('');
  const [reset, setreset] = useState(false)
  const [searchStatus, setSearchStatus] = useState(false);
  const [investorId, setInvestorId] = useState('')
  const [formikValues, setFormaikValues] = useState('')
  const { isLoading: isInvestorLoading, data: investorData, refetch: refetchListing, status: investorStatus } = FetchInvestorsFilters(page, search,mode ,sort, limit, queryString)

  // just for download 
  const { refetch: refetchExportInvestors, isLoading: isExportInvestorLoading, isRefetching: isExportInvestorRefetching } = ExportInvestors(page, search, mode, sort, limit, queryString)
  useEffect(() => {
    refetchListing()
  }, [queryString]);

  useEffect(() => {
    if (investorStatus == 'success' && queryString != "" ) {
      setTitle(`Missing Investor Data (${investorData.count})`)
    } else {
      setTitle(`Missing Investor Data`)
    }
  }, [investorData])
  const [open2, setOpen2] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen2 = (id) => {
    setInvestorId(id)
    setOpen2(true)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose2 = () => setOpen2(false);
  const handleClose = () => setOpen(false);
  const InvestorActions = ({ row }) => {
    const navigate = useNavigate();
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '6px' }}>
        <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => navigate(`/investors/viewInvestor/${row._id}`)}
        >
          <Tooltip title="View">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <VisibilityIcon size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>
         <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => navigate(`/investors/editInvestor/${row._id}`)}
        >
          <Tooltip title="Edit">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconEdit stroke={1.5} size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>

        <Clickable
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => handleOpen2(row._id)}
        >
          <Tooltip title="Settings">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SettingsIcon stroke={1.5} size="1.4rem" />
            </div>
          </Tooltip>
        </Clickable>
      </div>
    );
  }
  // const resetForm = () => {
  //   formik.resetForm(initialValues); // Reset the form to initial values    
  //   setQueryString("")
  //   setPage(33333333);
  // };

  function downloadMissinginvestorsData(){
    setLoader(true)
    refetchExportInvestors()?.then(({ data }) => {
      if ({ data }) {
        const link = document.createElement('a');
        link.href = data;
        link.target = '_blank';
        link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
        link.click();
        setLoader(false)
      } else {
        console.error('Data is missing in the response:', data);
      }
    });
  }
  return (
    // <div>
    <>
      <Box sx={{ width: '100%' }}>
          <BreadcrumbHeader title={title} />
      </Box>
      <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', flex: 1 }}>
          <Modal open={open2} onClose={handleClose2} closeIcon={true} style={{ width: '900px' }}>
            <PartnerPopup handleClose={handleClose2} investorId={investorId} setSearchStatus={setSearchStatus} setSearch={setSearch} refetchListing={refetchListing} />
          </Modal>
          <Modal open={open} onClose={handleClose} closeIcon={true} style={{ width: '900px' }}>
            <MissingPopup handleClose={handleClose} setQueryString={setQueryString} setreset={setreset} refetchListing={refetchListing} setPage={setPage} investorData={investorData} formikValues={formikValues} setFormaikValues={setFormaikValues}/>
          </Modal>
                <Grid container direction="row"  columnSpacing={1} rowSpacing={2} mb={1}>
                  <Grid item md={3} xs={8}>
                    <TextField
                      label="Search"
                      value={search}
                      autoComplete='off'
                      fullWidth
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          // alert(e.target.value);
                          setPage(1)
                          refetchListing()
                          setSearchStatus(true)
                        }
                      }}
                      onChange={(event) => {
                        setSearch(event.target.value)
                        setSearchStatus(false)
                      }}
                      size="small"
                      sx={{
                        '& input': {
                          background: theme.palette.background.paper,
                          // background: '#f9f9fc8e'
                        },
                        '& input:valid + fieldset': {
                          borderColor: theme.palette.secondary[200],
                          borderWidth: 0.5,
                        },
                        '& input:valid:hover + fieldset': {
                          borderColor: '#4050b5',
                          borderWidth: 1,
                        },
                        '& input:valid:focus + fieldset': {
                          borderColor: '#4050b5',
                          padding: '4px !important',
                          borderWidth: 1,
                          borderLeftWidth: 6,
                        },
                      }}
                    />
                  </Grid>
                  {/* <Grid item lg={1} sm={1} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}> */}
                    {/* <CustomButton size="large" onClick={()=>navigate('/startups/addstartup')} fullWidth sx={{height:'100%'}}>Add startup</CustomButton> */}
                    {/* <CustomButton
                      // size="small" 
                      color={(searchStatus && search !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                      onClick={() => {
                        setPage(1)
                        if (searchStatus && search !== '') {
                          setSearch('')
                          setreset(!reset)
                        }
                        else {
                          refetchListing()
                        }
                        setSearchStatus((oldVal) => !oldVal)
                      }}
                      fullWidth
                      // sx={{height:'100%'}}
                      sx={{ height: '40px' }}
                    >
                      {(searchStatus && search !== '') ? "Reset" : "Search"}
                    </CustomButton> */}
                    {/* <IconButton onClick={() => handleOpen()} sx={{ marginLeft: '10px' }}>
              <TuneIcon fontSize="medium" />
            </IconButton> */}

                  {/* </Grid> */}
                  <Grid item md={3} xs={8} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <CustomButton
                      // size="small" 
                      color={(searchStatus && search !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                      onClick={() => {
                        setPage(1)
                        if (searchStatus && search !== '') {
                          setSearch('')
                          setreset(!reset)
                        }
                        else {
                          refetchListing()
                        }
                        setSearchStatus((oldVal) => !oldVal)
                      }}
                      fullWidth
                      // sx={{height:'100%'}}
                      sx={{ height: '40px' }}
                    >
                      {(searchStatus && search !== '') ? "Reset" : "Search"}
                    </CustomButton>
                    <IconButton onClick={() => handleOpen()} sx={{ marginLeft: '10px' }}>
                    <Tooltip title="Filter">
                      {formikValues==''? <img src={filterIcon} alt="Filter" style={{ width: '24px', height: '24px' }} /> : <img src={filterFillIcon} alt="Filter" style={{ width: '24px', height: '24px' }}  />}  
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  <Grid item md={6} display={'flex'} flexDirection={'row-reverse'} xs={4}>
                    <AsyncButton loading={loader} onClick={() => {
                      downloadMissinginvestorsData()
                    }}>Export</AsyncButton>
                  </Grid>
                </Grid>
                {isInvestorLoading ? <Loading />
                : <AsyncTableListing
                   columns={columns}
                   data={investorData.data}
                   actions={InvestorActions}
                   page={investorData.data.length > 0 ? page : 1}
                   setPage={setPage}
                   count={investorData.count}
                   // cutomLink={InvestorLink}
                   sort={sort}
                   setSort={setSort}
                   limit={limit}
                   setLimit={setLimit}
                   search={search}
                   formikValues={formikValues}
                  />}
      </Paper> 
    </>
  );
}
export default MissingInvestor