
function Card({ children, title, IconRight=<></>,style={} }) {
  return (
    <div className='custom-card card mb-3' style={style}>
      {title && <div className='card-header d-flex align-items-center justify-content-between'>
        <div>{title}</div>
        {IconRight}
      </div>}
      <div className='card-body'>
        {children}
      </div>
    </div>
  )
}

export default Card