import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchPermissionData = () => {
  return useQuery(
    ['permissoin-data'],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/permissions/data`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
        },
      })
    }, {
    select: (data) => data.data,
    refetchOnWindowFocus: false
  })
}


// attachRolePermission

export const AttachRolePermission = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/permissions`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}


export const FetchAllPermission = (search) => {
  return useQuery(
    ['all-permission-fetch'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/permissions?searchText=${search}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
      }) 
  },{
    select: (data)=>({data: data.data, count: data.data.length}),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}
export const FetchAllSelectedPermission = () => {
  return useQuery(
    ['all-permission-selected-fetch'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/permissions`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
      }) 
  },{
    select: (data) => {
      // console.log(data.data.data);
        return data.data.map(role => ({ value: role._id, label: role?.role_name }))
      },
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}




export const FetchRoleDetails = (id) => {

  return useQuery(
    ['fetch-role-details',id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/permissions/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data}),
    enabled: !!id,
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const UpdateRolePermissionForm = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/permissions/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}