import React from 'react'
import { useEffect, useState,lazy } from 'react';
import { useQuery } from 'react-query';
import { FetchLpListingData } from 'api';
import { ExportLplisters} from 'api';
import Loading from 'views/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton from 'ui-component/CustomButton';
import html2canvas from 'html2canvas';
import Cookies from 'js-cookie';
import Loadable from 'ui-component/Loadable';

const excel_report = "excel_report"
export default function LpSummary(){
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [reportModule, setReportModule] = useState(null); // Define the state
  const Error404 = Loadable(lazy(() => import('views/error/Error404')));
  const { isLoading: isDashDataLoading, data: dashData } = FetchLpListingData();
  const { refetch: refetchExportLplisters, isLoading: isExportLplistersLoading,isRefetching: isExportLplistersRefetching } = ExportLplisters(excel_report);
  useEffect(() => {
    const permission_values = localStorage.getItem('permission');
    const permissions = permission_values != undefined ?  JSON.parse(permission_values) : [];
    // console.log(permissions,"say");
    const module = permissions.find(
      (module) => module.module_name === 'reports' && module.permisson === true
    );
    setReportModule(module); // Set the value in the state
  }, []);
  if (isDashDataLoading) {
    return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
  }
  function download() {
    setIsExportLoading(true);
    refetchExportLplisters()
    .then(({ data }) => {
        setIsExportLoading(false);
  
        const link = document.createElement('a');
        link.href = data.url;
        link.target = '_blank';   
        link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
        link.click();
      })
  } 
  const captureAndCopyScreenshot = () => {
    const tableElement = document.getElementById('lptable'); // Replace 'your-table-id' with the ID of your table element
    html2canvas(tableElement).then(canvas => {
      canvas.toBlob(blob => {
        navigator.clipboard.write([
          new ClipboardItem({ [blob.type]: blob }),
        ]);
        toast.success('Screenshot copied!', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    });
  };
  
  
  return (
    (reportModule != undefined  && reportModule.lp_summary == true) ?
    <>
    <div className='custom-card card'>
    <ToastContainer /> {/* Render the ToastContainer component */}
      <div className='card-header'>
        LP Summary
      </div>
      <div className='card-body'>
        <div className='custom-table table-responsive lp-summary-table' style={{borderRadius: '8px'}} id="lptable">
          <table className='w-100 table add-border'>
            <thead>
              <tr>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>Partners</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>SUM of Total <br/>Commitment</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>COUNT Of LP</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>Commitment through <br/>Venture Partner</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>COUNT Of Venture <br/>Partner LP</th>
                <th className='text-start' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>Net Commitment By <br/>Partner</th>
              </tr>
              <tr>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}></th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>A</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>B</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>C</th>
                <th className='text-center' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>D</th>
                <th className='text-start' style={{fontWeight:'900', backgroundColor:'#0000FF', color: '#fff'}}>E=A-(50% of C)</th>
              </tr>
            </thead>
            <tbody>
            {dashData.partnerArray.map((el,index)=>(<tr key={index}>
                <td >{el?.name}</td>
                <td className='text-end' >{el?.sumCommetmentAmount}</td>
                <td className='text-end' >{el?.sumtotalCountLp}</td>
                <td className='text-center' >{el?.sumVentureCommetmentAmount}</td>
                <td className='text-end' >{el?.sumVentureLpCount}</td>
                <td >{Math.round((el?.sumCommetmentAmount - (50 * el?.sumVentureCommetmentAmount) / 100) * 100) / 100}</td>
              </tr>))}
              <tr className='total-row'>
                <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{dashData?.sumObj.name}</td>
                <td className='text-end'  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{dashData?.sumObj?.commitmentPartnerAmount}</td>
                <td className='text-end'  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{dashData?.sumObj?.sumtotalCountLp}</td>
                <td className='text-center'  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{dashData?.sumObj?.sumVentureCommetmentAmount}</td>
                <td className='text-end'  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{dashData?.sumObj?.sumVentureLpCount}</td>
                <td  style={{backgroundColor:'#0000FF', color: '#fff', fontWeight: '900'}}>{dashData?.sumObj?.sumNetCommitedByPartner}</td>
              </tr>
              <tr>
                <td >{dashData?.synbdicateSum.name}</td>
                <td className='text-end' >{dashData?.synbdicateSum?.commitmentSyndicateAmount}</td>
                <td className='text-end' >{dashData?.synbdicateSum?.sumtotalCountLp}</td>
                <td className='text-center' >{dashData?.synbdicateSum?.sumVentureCommetmentAmount}</td>
                <td className='text-end' >{dashData?.synbdicateSum?.sumVentureLpCount}</td>
                <td >{dashData?.synbdicateSum?.sumNetCommitedByPartner}</td>
              </tr>
              <tr className='grand-row'>
                <td style={{backgroundColor:'#D9D9D9', fontWeight: '900'}}>{dashData?.finalSum.name}</td>
                <td className='text-end' style={{backgroundColor:'#D9D9D9', fontWeight: '900'}}>{dashData?.finalSum?.commitmentSyndicateAmount}</td>
                <td className='text-end' style={{backgroundColor:'#D9D9D9', fontWeight: '900'}}>{dashData?.finalSum?.sumtotalCountLp}</td>
                <td className='text-center' style={{backgroundColor:'#D9D9D9', fontWeight: '900'}}>{dashData?.finalSum?.sumVentureCommetmentAmount}</td>
                <td className='text-end' style={{backgroundColor:'#D9D9D9', fontWeight: '900'}}>{dashData?.finalSum?.sumVentureLpCount}</td>
                <td style={{backgroundColor:'#D9D9D9', fontWeight: '900'}}>{dashData?.finalSum?.sumNetCommitedByPartner}</td>
              </tr>
            </tbody>
          </table>
          <div className='amount-crore-text'>*Amount In Crore</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right',marginTop: '10px' }}>
         <CustomButton
           onClick={download}
           size="medium"
           loading={isExportLoading}
           sx={{ height: '40px', marginRight: '10px' }} 
         >
           Export As Excel
         </CustomButton>
         <CustomButton
           onClick={captureAndCopyScreenshot}
           size="medium"
           sx={{ height: '40px' }} 
         >
          Copy As Image
         </CustomButton>
        </div>
      </div>
    </div>
    </>
    :    <Error404 />
  )
}