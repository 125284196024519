import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Articles from 'views/resources/articles/Articles';
import Videos from 'views/resources/videos';
import FAQ from 'views/resources/faq';
import ArticleForm from 'views/resources/articles/ArticleForm';
import TabDetails from 'views/investor/TabDetails';
import LpSummary from 'views/lpsummary';
import ViewDeal from 'views/deal/ViewDeal';
import ChecklistMaster from 'views/deal/ChecklistMaster';
import ChecklistListing from 'views/deal/ChecklistListing';
import AttachChecklist from 'views/deal/AttachChecklist';
import ChecklistView from 'views/deal/ChecklistView';
import DealTemplate from 'views/deal/DealTemplate';
import RoleListing from 'views/users/RoleListing';
import RolePermissionForm from 'views/users/RolePermissionForm';
import AddBankAccount from 'views/master/addBankAccount';
import AddInvoiceAccount from 'views/master/addInvoiceAccount';
// import VpFees from 'views/master/VpFees';
import VPInvestmentDetails from 'views/Report/VPInvestmentDetails';
import LiveDealsSummary from 'views/Report/LiveDealsSummary';
import UploadDocuments from 'views/UploadDocuments/UploadDocuments';
import UploadFolderId from 'views/UploadDocuments/UploadFolderId';
import MissingInvestor from 'views/Report/MissingInvestor';
import InvestorsOnboarding from 'views/Report/InvestorsOnboarding';
import VenturePartner from 'views/investor/VenturePartner';
import InactiveActiveInvestor from 'views/Report/InactiveActiveInvestor';
import TargetAmountFees from 'views/Report/TargetAmountFees';
import FeesManagement from 'views/fees/index';
import DealDocument from 'views/deal/DealDocument';
import ViewStartup from 'views/startup/ViewStartup';
import UpdateStartupTemplateChecklist from 'views/startup/UpdateStartupTemplateChecklist';
import DealChecklistReport from 'views/Report/DealChecklistReport';
import StartupChecklistReport from 'views/Report/StartupChecklistReport';
import AddMISReport from 'views/startup/AddMISReport';
import AllReports from 'views/master/reports/AllReports';
import AllExcelReports from 'views/master/excel_report/ReportListing';
import CreateReportMaster from 'views/master/reports/CreateReportMaster';
import CreateExcelReportMaster from 'views/master/excel_report/CreateExcelReportMaster';
import ChangePassword from 'views/users/ChangePassword';
import AllInvoices from 'views/startup/invoices/AllInvoices';
import SubmitFeedback from 'views/startup/feedbacks/SubmitFeedback';
import AllFeedbacksListing from 'views/startup/feedbacks/AllFeedbacksListing';
import LeadInvestorListing from 'views/investor/LeadInvestorListing';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const Error404 = Loadable(lazy(() => import('views/error/Error404')));

// Startup routing
const StartupListing = Loadable(lazy(() => import('views/startup/startupListing')));
const AddStartup = Loadable(lazy(() => import('views/startup/AddStartup')));
const StartUpView = Loadable(lazy(() => import('views/startup/StartUpView')));
const ApplicationListing = Loadable(lazy(() => import('views/startup/ApplicationListing')));
const ApplicatonListEditView = Loadable(lazy(() => import('views/startup/ApplicatonListEditView')));

// Investor routing
const Investors = Loadable(lazy(() => import('views/investor')));
const AddInvestor = Loadable(lazy(() => import('views/investor/addInvestor')));
const ViewInvestor = Loadable(lazy(() => import('views/investor/viewInvestor')));

const VerifyKycDetails = Loadable(lazy(() => import('views/investor/verify_kyc_details')));

const SoaDetails = Loadable(lazy(() => import('views/investor/soa_details')));
const SoaZipDetails = Loadable(lazy(() => import('views/investor/soa_zip_details')));
const StampZipUpload = Loadable(lazy(() => import('views/investor/StampZipUpload')));

const Feedbacks = Loadable(lazy(() => import('views/feedback/Feedbacks')));
const ReplyFeedbacks = Loadable(lazy(() => import('views/feedback/ReplyFeedbacks')));

//Syndicates routing
const SyndicateListing = Loadable(lazy(() => import('views/syndicate')));
const SyndicateForm = Loadable(lazy(() => import('views/syndicate/Create')));
const SyndicateView = Loadable(lazy(() => import('views/syndicate/SyndicateView')));

// Master routing
const Master = Loadable(lazy(() => import('views/master')));
// const MasterBankAccountForm = Loadable(lazy(() => import('views/master/addBankAccount')));

// const AddAccountForm = Loadable(lazy(() => import('views/master/addAccountForm')));
// const AddAppQuestion = Loadable(lazy(() => import('views/master/addQuestionForm')));

//users routing
const UsersListing = Loadable(lazy(() => import('views/users')));
const UsersForm = Loadable(lazy(() => import('views/users/Create')));

// Deal routing
const Deals = Loadable(lazy(() => import('views/deal')));
const IntrestedInvestors = Loadable(lazy(() => import('views/deal/IntrestedInvestors')));
const ConfirmationList = Loadable(lazy(() => import('views/deal/ConfirmationList')));
const AddDeal = Loadable(lazy(() => import('views/deal/AddDeal')));
const AddFullDeal = Loadable(lazy(() => import('views/deal/AddFullDeal')));
const TransactionForm = Loadable(lazy(() => import('views/deal/TransactionPopup')));
const Transactions = Loadable(lazy(() => import('views/deal/Transactions')));
const Fees = Loadable(lazy(() => import('views/fees')));
const CustomReport = Loadable(lazy(() => import('views/master/reports/CustomReport')));
const AllNotifications = Loadable(lazy(() => import('views/dashboard/Default/AllNotifications')));
const GenerateInvoice = Loadable(lazy(() => import('views/startup/invoices/GenerateInvoice')));

// Bank Routing
const BankReceipts = Loadable(lazy(() => import('views/bank/BankReceipts')));
const SelectedReceiptDetail = Loadable(lazy(() => import('views/bank/SelectedReceiptDetail')));

// Reports Routing
const QarReports = Loadable(lazy(()=>import('views/Report/QarReports')))
const FeesReport = Loadable(lazy(()=>import('views/Report/FeesReport')))

// Master Feedback Forms Routing
const MasterFeedbackForms = Loadable(lazy(()=>import('views/master/application_feedbacks/MasterFeedbackForms')))
const CreateMasterFeedbackForm = Loadable(lazy(()=>import('views/master/application_feedbacks/CreateMasterFeedbackForm')))

const MainRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <DashboardDefault />
        },
        {
            path: 'startups',
            children: [
                {
                    path: '',
                    element: <StartupListing />
                },
                {
                    path: 'selected',
                    element: <StartupListing />
                },
                {
                    path: 'addStartup',
                    element: <AddStartup />
                },

                {
                    path: 'editStartup/:id',
                    element: <AddStartup />
                },
                {
                    path: ':startupId/addDeal',
                    element: <AddDeal />
                },
                {
                    path: 'view-startup/:id',
                    element: <ViewStartup/>
                },
                {
                    path: 'startup-settings/:id',
                    element: <ViewStartup view_type={'setting'} />
                },
                {
                    path: 'startup-misreport/:id',
                    element: <ViewStartup view_type={'misreport'} />
                },
                {
                    path: 'startup-template-checklist/:id',
                    element: <UpdateStartupTemplateChecklist />
                },
                {
                    path: 'addMISReport/:id',
                    element: <AddMISReport/>
                }
                ,{
                    path: 'editMISReport/:id',
                    element: <AddMISReport />
                },
            ]
        },

        {
            path: 'applicationListing',
            children: [
                { path: '', element: <ApplicationListing /> },
                {
                    path: 'startUpView/:id',
                    element: <StartUpView view_type="details" />
                },
                {
                    path: 'feedbacksView/:id',
                    element: <StartUpView view_type="feedbacks"  />
                },
                {
                    path: 'startupSettings/:id',
                    element: <StartUpView view_type="settings" />
                },
                {
                    path: 'applicationEditView/:id',
                    element: <ApplicatonListEditView />
                },
                {
                    path: 'addApplication',
                    element: <ApplicatonListEditView />
                }
            ]
        },
        {
            path: 'feedbacks',
            children: [
                {
                    path: '',
                    element: <AllFeedbacksListing />
                },
                {
                    path: ':id',
                    element: <SubmitFeedback />
                }
            ]
        },
        {
            path: 'investors',
            children: [
                {
                    path: '',
                    element: <Investors />
                },
                {
                    path: 'add_investor',
                    element: <AddInvestor />
                },
                {
                    path: 'editInvestor/:id',
                    element: <AddInvestor />
                },
                {
                    path: 'viewInvestor/:id',
                    element: <ViewInvestor view_type={'personalDetail'} />
                },
                {
                    path: 'verify_kyc_details',
                    element: <VerifyKycDetails />
                },
                {
                    path: 'soa_uploads',
                    element: <SoaDetails />
                },
                // {
                //     path: 'tabdetails',
                //     element: <TabDetails />
                // },
                {
                    path: 'soa_uploads/:id',
                    element: <SoaZipDetails />
                },
                {
                    path: 'stamp_uploads/',
                    element: <StampZipUpload />
                }

                // {
                //     path: 'checklist-view',
                //     element: <ChecklistView />
                // },
            ]
        },
        {
            path: 'venture_Partner',
            children: [
                {
                    path: '',
                    element: <VenturePartner />
                }
            ]
        },
        {
            path: 'lead_investors',
            children: [
                {
                    path: '',
                    element: <LeadInvestorListing />
                }
            ]
        },

        // {
        //     path: 'notifications',
        //     children: [
        //         {
        //             path: '',
        //             element: <AllNotifications />
        //         }
        //     ]
        // },

        {
            path: 'syndicates',
            children: [
                {
                    path: '',
                    element: <SyndicateListing />
                },
                {
                    path: 'addSyndicate',
                    element: <SyndicateForm />
                },
                {
                    path: 'editSyndicate/:id',
                    element: <SyndicateForm />
                },
                {
                    path: 'view-syndicate/:id',
                    element: <SyndicateView />
                }
            ]
        },
        {
            path: 'change-password',
            element: <ChangePassword />
        },
        {
            path: 'users',
            children: [
                {
                    path: '',
                    element: <UsersListing />
                },
                {
                    path: 'addUser',
                    element: <UsersForm />
                },
                {
                    path: 'editUser/:id',
                    element: <UsersForm />
                },
                {
                    path: 'role',
                    element: <RoleListing />
                },
                {
                    path: 'addRole',
                    element: <RolePermissionForm />
                },
                {
                    path: 'editRole/:id',
                    element: <RolePermissionForm />
                }
            ]
        },

        {
            path: 'deals',
            children: [
                {
                    path: '',
                    element: <Deals />
                },
                {
                    path: 'addDeal',
                    element: <AddFullDeal />
                },
                {
                    path: ':id/addDeal',
                    element: <AddFullDeal />
                },
                {
                    path: 'transactions/:id',
                    element: <ViewDeal view_type={'transaction'} />
                },
                {
                    path: 'investments/:id',
                    element: <ViewDeal view_type={'investments'} />
                },
                {
                    path: ':id/transactionForm',
                    element: <TransactionForm />
                },
                {
                    path: 'intrestedInvestors/:id',
                    element: <IntrestedInvestors />
                },
                {
                    path: 'confirmationList/:id',
                    element: <ConfirmationList />
                },
                {
                    path: 'view-deal/:id',
                    element: <ViewDeal />
                },
                {
                    path: 'deal-template',
                    element: <DealTemplate />
                },
                {
                    path: 'attach-checklist/:id',
                    element: <AttachChecklist />
                },
                {
                    path: 'setting/:id',
                    element: <ViewDeal view_type={'setting'} />
                },
                {
                    path: 'dealdocument/:id',
                    element: <ViewDeal view_type={'dealdocument'} />
                },
                {
                    path: 'excel_sheet/:id',
                    element: <ViewDeal view_type={'excel_sheet'} />
                }
            ]
        },
        {
            path: '/invoices',
            children: [
                {
                    path: '',
                    element: <AllInvoices/>
                },
                {
                    path: 'create-invoice',
                    element: <GenerateInvoice/>
                }
            ]
        },
        {
            path: '/bank-receipts',
            children: [
                {
                    path: '',
                    element: <BankReceipts />
                },
                {
                    path: ':id',
                    element: <SelectedReceiptDetail />
                },
            ]
        },
        {
            path: 'master',
            children: [
                {
                    path: '',
                    element: <Master />
                },
                {
                    path: 'addBankAccount',
                    element: <AddBankAccount />
                },
                {
                    path: 'editBankAccount/:id',
                    element: <AddBankAccount />
                },
                {
                    path: 'addBillingInfo',
                    element: <AddInvoiceAccount />
                },
                {
                    path: 'editBillingInfo/:id',
                    element: <AddInvoiceAccount />
                },
                // {
                //     path: 'vp-fees',
                //     element: <VpFees />
                // }
            ]
        },
        {
            path: 'fees',
            children: [
                {
                    path: '',
                    element: <Fees/>
                }
            ]
        },
        // {
        //     path: 'reports',
        //     children: [
        //         {
        //             path: '',
        //             element: <AllReports/>
        //         },
        //         {
        //             path: 'master-report-create',
        //             element: <CreateReportMaster/>
        //         },
        //         {
        //             path: 'master-report-edit/:id',
        //             element: <CreateReportMaster/>
        //         },
        //         // {
        //         //     path: 'master-excel-report-create',
        //         //     element: <CreateExcelReportMaster/>
        //         // },
        //         // {
        //         //     path: 'master-report-edit',
        //         //     element: <CreateReportMaster/>
        //         // }
        //     ]
        // },
        {
            path: 'reports',
            children: [
                {
                    path: '',
                    element: <AllExcelReports/>
                },
                {
                    path: 'master-excel-report-create',
                    element: <CreateExcelReportMaster/>
                },
                {
                    path: 'master-excel-report-edit/:id',
                    element: <CreateExcelReportMaster/>
                },
                // {
                //     path: 'master-excel-report-create',
                //     element: <CreateExcelReportMaster/>
                // },
                // {
                //     path: 'master-report-edit',
                //     element: <CreateReportMaster/>
                // }
            ]
        },
        {
            path: 'checklist',
            children: [
                {
                    path: '',
                    element: <ChecklistListing />
                },
                {
                    path: 'checklist-create',
                    element: <ChecklistMaster />
                },
                {
                    path: 'checklist-edit/:id',
                    element: <ChecklistMaster />
                }

                // {
                //     path: 'addAccount',
                //     element: <AddAccountForm />
                // },
                // {
                //     path: 'addQuestion',
                //     element: <AddAppQuestion />
                // },
            ]
        },
        {
            path: 'feedback_master',
            children: [
                {
                    path: '',
                    element: <MasterFeedbackForms />
                },
                {
                    path: 'feedback-create',
                    element: <CreateMasterFeedbackForm />
                },
                {
                    path: ':id',
                    element: <CreateMasterFeedbackForm />
                }

                // {
                //     path: 'addAccount',
                //     element: <AddAccountForm />
                // },
                // {
                //     path: 'addQuestion',
                //     element: <AddAppQuestion />
                // },
            ]
        },
        {
            path: 'lp-summary',
            children: [
                {
                    path: '',
                    element: <LpSummary />
                }
            ]
        },
        {
            path: 'vp-investment-details',
            children: [
                {
                    path: '',
                    element: <VPInvestmentDetails />
                }
            ]
        },
        {
            path: 'live-deals-summary',
            children: [
                {
                    path: '',
                    element: <LiveDealsSummary />
                }
            ]
        },
        {
            path: 'missing-investor-data',
            children: [
                {
                    path: '',
                    element: <MissingInvestor />
                }
            ]
        },
        {
            path: 'investor-onboarding-data',
            children: [
                {
                    path: '',
                    element: <InvestorsOnboarding />
                }
            ]
        },
        {
            path: 'investor-inactive-active-data',
            children: [
                {
                    path: '',
                    element: <InactiveActiveInvestor />
                }
            ]
        },
        {
            path: 'qar-reports-data',
            children: [
                {
                    path: '',
                    element: <QarReports />
                }
            ]
        },
        {
            path: 'fees-reports-data',
            children: [
                {
                    path: '',
                    element: <FeesReport />
                }
            ]
        },
        {
            path: 'target-amount-fees',
            children: [
                {
                    path: '',
                    element: <TargetAmountFees />
                }
            ]
        },
        {
            path: 'deal-document-checklist',
            children: [
                {
                    path: '',
                    element: <DealChecklistReport />
                }
            ]
        },
        {
            path: 'startup-document-checklist',
            children: [
                {
                    path: '',
                    element: <StartupChecklistReport />
                }
            ]
        },

        {
            path: 'upload-documents',
            children: [
                {
                    path: '',
                    element: <UploadDocuments />
                },
                {
                    path: 'folderid',
                    element: <UploadFolderId />
                }
            ]
        },
        {
            path: 'resources',
            children: [
                {
                    path: 'articles',
                    element: <Articles />
                },
                {
                    path: 'articles/articleForm',
                    element: <ArticleForm />
                },
                {
                    path: 'articles/:id/articleForm',
                    element: <ArticleForm />
                },
                {
                    path: 'videos',
                    element: <Videos />
                },
                {
                    path: 'faq',
                    element: <FAQ />
                },
                {
                    path: 'feedbacks',
                    children: [
                        {
                            path: '',
                            element: <Feedbacks />
                        },
                        {
                            path: ':investorId/:id',
                            element: <ReplyFeedbacks />
                        }
                    ]
                },
            ]
        },
        {
            path: '*',
            element: <Error404 />
        }
    ]
};

export default MainRoutes;
