import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

// FetchStartupsQuery
import axios from 'axios';
import Cookies from 'js-cookie';
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchStartupsQuery = (page, stage, search, searchStatus, sort = 1, limit = 20, status, selectedTags = '') => {
    return useQuery(
        ['startup-listing', page, sort, limit, selectedTags],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup?page=${page}&searchText=${search}&syndicate_id=${stage}&startup_flow_status=${status}${sort != 0 ? `&sort=${sort}` : ''
                    }&limit=${limit}&tags=${selectedTags}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => ({
                startups: data.data.data.map((startup) => {
                    const newFounder = startup?.founders
                        ?.reduce((acc, founder) => {
                            return acc + ',' + founder.name;
                        }, '')
                        .split(',');
                    if (newFounder?.length > 1) {
                        return { ...startup, founders: newFounder.slice(1).join(', ') };
                    } else {
                        return { ...startup, founders: '' };
                    }
                }),
                count: data.data.count,
                limit: data.data.limit
            }),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupsApplicationQuery = (page, stage, search, searchStatus, sort = 1, limit = 20, status, selectedTags = '') => {
    return useQuery(
        ['application-listing', page, sort, limit, selectedTags],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup?page=${page}&searchText=${search}&stage=${stage}&startup_flow_status=${status}${sort != 0 ? `&sort=${sort}` : ''
                    }&limit=${limit}&tags=${selectedTags}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => ({
                startups: data.data.data.map((startup) => {
                    const newFounder = startup?.founders
                        ?.reduce((acc, founder) => {
                            return acc + ',' + founder.name;
                        }, '')
                        .split(',');
                    if (newFounder?.length > 1) {
                        return { ...startup, founders: newFounder.slice(1).join(', ') };
                    } else {
                        return { ...startup, founders: '' };
                    }
                }),
                count: data.data.count,
                limit: data.data.limit
            }),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
export const FetchAllStartup = (mode = '') => {
    return useQuery(
        ['fetch-all-startups'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (mode == 'options') {
                    return data.data.data.map((startup) => ({ label: startup.company_name, value: startup._id }));
                }
                return data.data.data;
            },
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupDetails = (id, mode) => {
    return useQuery(
        ['startup-details', id, mode],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                // console.log("data", data.data.data)
                // console.log((data.data.data[0].startup_details.find(detail => detail.key === "Product")).value,"11111")
                if (mode === 'edit') {
                    const savedData = data.data.data[0];
                    // const Product = savedData?.startup_details?.find((detail) => detail.key === 'Product');
                    // const investmentThesis = savedData?.startup_details?.find((detail) => detail.key === 'Investment thesis');
                    // console.log("savedData", savedData)
                    const defaultDetails = [
                        { key: 'Product', value: '' },
                        { key: 'Investment thesis', value: '' },
                        { key: 'CIN Number', value: '' },
                        // { key: 'Deal Type', value: '' },
                        { key: 'Competition', value: '' },
                        { key: 'Revenue Model', value: '' },
                        { key: 'Exit-option', value: '' },
                        { key: 'Co investors(Ex:-Vaibhav Chawla, Jeevan Prakash )', value: '' },
                        // { key: 'Convertible Securities', value: '' }
                    ];
                    if (savedData.startup_details) {
                        const mergedDetailsMap = new Map();

                        // Add details from savedData.startup_details to the map
                        savedData.startup_details.forEach((detail) => {
                            mergedDetailsMap.set(detail.key, detail.value);
                        });

                        // Merge details from defaultDetails into the map
                        defaultDetails.forEach((detail) => {
                            if (!mergedDetailsMap.has(detail.key)) {
                                // If the key doesn't exist, add it
                                mergedDetailsMap.set(detail.key, detail.value);
                            } else {
                                // If the key exists, check if the values are different
                                const existingValue = mergedDetailsMap.get(detail.key);
                                if (existingValue !== detail.value) {
                                    // Handle the conflict (e.g., log a message or choose one value)
                                    console.log(`Key "${detail.key}" has conflicting values:`, existingValue, detail.value);
                                }
                            }
                        });

                        // Convert the map back to an array
                        savedData.startup_details = Array.from(mergedDetailsMap, ([key, value]) => ({ key, value }));
                    } else {
                        // If savedData.startup_details doesn't exist, set it to defaultDetails
                        savedData.startup_details = defaultDetails;
                    }
                    const savedValues = {
                        description: savedData.description || '',
                        email: savedData.email || '',
                        mobileNumber: savedData.mobile_number || '',
                        phone_code: savedData?.phone_code || '',
                        linkedInURL: savedData.linkedln_page_url || '',
                        facebookURL: savedData.facebook_page_url || '',
                        instagramURL: savedData.instagram_page_url || '',
                        twitterURL: savedData.twitter_page_url || '',
                        website: savedData.website_url || '',
                        startupValuation: savedData.startup_valuation || '',
                        formation_date: savedData.formation_date || null,
                        companyName: savedData.company_name || '',
                        overview: savedData.overview || '',
                        gst_certificate: { name: savedData?.gst_certificate || '', url: savedData?.gstCertificateUrl || '' },
                        pan_number: savedData?.pan_number || "",
                        // status: savedData?.startup_status?._id || '',
                        // comment: savedData.comment || '',
                        stage: savedData?.stage[0]?._id || '',
                        sector: savedData.sector?._id || '',
                        videos: savedData.videos
                            ? savedData.videos.map((video) => ({ heading: video.heading, url: video.url }))
                            : [{ heading: '', url: '' }],
                        leadInvestors: savedData.lead_investors
                            ? savedData.lead_investors.map((lead_investor) => ({ name: lead_investor.name }))
                            : [{ name: '' }],
                        founders: savedData.founders
                            ? savedData.founders.map((founder) => ({
                                name: founder.name,
                                linkedln_profile_url: founder.linkedln_profile_url
                            }))
                            : [{ name: '', linkedln_profile_url: '' }],
                        companyLogo: { name: savedData.image_name || '', url: savedData.image || '' },
                        documents: savedData.document
                            ? savedData.document.map((doc) => ({
                                name: doc.name,
                                file: { name: doc.file_name, url: doc.url, doc_id: doc.document_id }
                            }))
                            : [{ name: '', file: null }],
                        city_name: savedData.city_name || '',
                        // lead_investor_id: (savedData?.lead_investor_id && savedData.lead_investor_id.length) ? savedData.lead_investor_id : []
                        lead_investor_id: savedData?.lead_investor_id || '',
                        syndicate_id: savedData?.syndicate_id || '',
                        addresses: savedData?.startup_addresses != undefined && savedData?.startup_addresses?.length > 0 ? savedData?.startup_addresses[0] : '',
                        gst_number: savedData?.gst_number || '',
                        // startup_details: savedData.startup_details ? savedData.startup_details : [
                        //   { key: "CIN Number", value: ""  },
                        //   { key: "Deal Type", value: ""},
                        //   { key: "Competition", value: ""},
                        //   { key: "Revenue Model", value: ""},
                        //   { key: "Exit-option", value: ""},
                        // ],
                        // startup_details: savedData.startup_details
                        //     ? savedData.startup_details.filter((detail) => detail.key !== 'Product' && detail.key !== 'Investment thesis')
                        //     : [
                        //           { key: 'CIN Number', value: '' },
                        //           { key: 'Deal Type', value: '' },
                        //           { key: 'Competition', value: '' },
                        //           { key: 'Revenue Model', value: '' },
                        //           { key: 'Exit-option', value: '' },
                        //           { key: 'Co investors(Ex:-Vaibhav Chawla, Jeevan Prakash )', value: '' },
                        //           { key: 'Convertible Securities', value: '' }
                        //       ],

                        // Product: Product ? Product.value : '',
                        // investmentThesis: investmentThesis ? investmentThesis.value : ''
                        startup_details: savedData.startup_details,
                        entity_name: savedData.entity_name,
                        tags: savedData?.tags !== undefined ? savedData?.tags.map(option => {
                            return {
                                id: option?._id,
                                text: option?.name
                            }
                        }) : []
                    };

                    // console.log("data", data.data.data[0])
                    return savedValues;
                }
                return data.data.data[0];
            },
            refetchOnWindowFocus: false,
            enabled: !!id,
            // refetchIntervalInBackground:false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UploadStartupDocs = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/upload/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload
            });
        },
        {
            // onSuccess: ()=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UpdateStartup = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const AddStartup = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UpdateStartupStatus = () => {
    return useMutation(
        ({ id, status }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/status/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: { status }
            });
        },
        {
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UpdateStartupFlowStatus = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/change-flow-status/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupStatusLogs = (id) => {
    return useQuery(
        ['fetch-startup-status-logs', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/startup_stage_logs/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data[0],
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FatchStartUpList = (id, mode) => {
    return useQuery(
        ['fetch-startup-applicationview-list', id, mode],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (mode === 'edit') {
                    const savedData = data.data.data[0];

                    const entityNameParts = savedData.entity_name.split(' ');
                    const first_name = entityNameParts[0] || '';
                    const last_name = entityNameParts.slice(1).join(' ') || '';
                    // console.log('entityNameParts', last_name);

                    const savedValues = {
                        problem_solving: savedData.problem_solving || '',
                        email: savedData.email || '',
                        mobile_number: savedData.mobile_number || '',
                        linkedln_page_url: savedData.linkedln_page_url || '',
                        first_name: first_name,
                        last_name: last_name,
                        website_url: savedData.website_url || '',
                        company_name: savedData.company_name || '',
                        referral_name: savedData.referral_name,
                        referral_through: savedData.referral_through,
                        stage: savedData?.stage[0]?._id || '',
                        videos: savedData.videos
                            ? savedData.videos.map((video) => ({ heading: video.heading, url: video.url }))
                            : [{ heading: '', url: '' }],
                        leadInvestors: savedData.lead_investors
                            ? savedData.lead_investors.map((lead_investor) => ({ name: lead_investor.name }))
                            : [{ name: '' }],
                        founders: savedData.founders
                            ? savedData.founders.map((founder) => ({
                                name: founder.name,
                                linkedln_profile_url: founder.linkedln_profile_url
                            }))
                            : [{ name: '', linkedln_profile_url: '' }],

                        document: savedData.document
                            ? savedData.document.map((doc) => ({
                                name: doc.name,
                                file: { name: doc.file_name, url: doc.url, doc_id: doc.document_id }
                            }))
                            : [{ name: '', file: null }],

                        city_name: savedData.city_name || '',
                        valuation_type: savedData?.valuation_type || '',
                        valuation_value: savedData?.valuation_value || '',
                        valuation_annual_revenue: savedData?.valuation_annual_revenue || '',
                        tags: savedData.tags !== undefined ? savedData.tags?.map(option => {
                            return {
                                id: option?._id,
                                text: option?.name
                            }
                        }) : []
                    };

                    return savedValues;
                }
                return data.data.data[0];
            },
            refetchOnWindowFocus: false,
            enabled: !!id,
            // refetchIntervalInBackground:false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
export const FetchStartupStageOption = () => {
    return useQuery(
        ['sector_types-options'],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/startup_flow_status`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                return data.data.data.map((startup) => ({ label: startup.values ? startup.values[0].name : '', value: startup._id }));
            },
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
export const UpdateApplication = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const AddApplicationDocument = () => {
    //edit application view document
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup/upload/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                toast.error(err);
            }
        }
    );
};
export const AddApplication = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup/add`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
export const UpdateApplicationStartupFlowStatus = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup/change-flow-status/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchApplicationStartupStatusLogs = (id) => {
    return useQuery(
        ['fetch-application-startup-status-logs', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master-startup/startup_stage_logs/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data[0],
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FatchSelectedStartUp = (id, mode) => {
    return useQuery(
        ['startup-details', id, mode],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (mode === 'edit') {
                    const savedData = data.data.data[0];
                    // const Product = savedData?.startup_details?.find((detail) => detail.key === 'Product');
                    // const investmentThesis = savedData?.startup_details?.find((detail) => detail.key === 'Investment thesis');
                    // console.log("savedData", savedData)
                    const defaultDetails = [
                        { key: 'Product', value: '' },
                        { key: 'Investment thesis', value: '' },
                        { key: 'CIN Number', value: '' },
                        { key: 'Deal Type', value: '' },
                        { key: 'Competition', value: '' },
                        { key: 'Revenue Model', value: '' },
                        { key: 'Exit-option', value: '' },
                        { key: 'Co investors(Ex:-Vaibhav Chawla, Jeevan Prakash )', value: '' },
                        { key: 'Convertible Securities', value: '' }
                    ];
                    if (savedData.startup_details) {
                        const mergedDetailsMap = new Map();

                        // Add details from savedData.startup_details to the map
                        savedData.startup_details.forEach((detail) => {
                            mergedDetailsMap.set(detail.key, detail.value);
                        });

                        // Merge details from defaultDetails into the map
                        defaultDetails.forEach((detail) => {
                            if (!mergedDetailsMap.has(detail.key)) {
                                // If the key doesn't exist, add it
                                mergedDetailsMap.set(detail.key, detail.value);
                            } else {
                                // If the key exists, check if the values are different
                                const existingValue = mergedDetailsMap.get(detail.key);
                                if (existingValue !== detail.value) {
                                    // Handle the conflict (e.g., log a message or choose one value)
                                    console.log(`Key "${detail.key}" has conflicting values:`, existingValue, detail.value);
                                }
                            }
                        });

                        // Convert the map back to an array
                        savedData.startup_details = Array.from(mergedDetailsMap, ([key, value]) => ({ key, value }));
                    } else {
                        // If savedData.startup_details doesn't exist, set it to defaultDetails
                        savedData.startup_details = defaultDetails;
                    }
                    const savedValues = {
                        description: savedData.description || '',
                        email: savedData.email || '',
                        mobileNumber: savedData.mobile_number || '',
                        phone_code: savedData?.phone_code || '',
                        linkedInURL: savedData.linkedln_page_url || '',
                        facebookURL: savedData.facebook_page_url || '',
                        instagramURL: savedData.instagram_page_url || '',
                        twitterURL: savedData.twitter_page_url || '',
                        website: savedData.website_url || '',
                        startupValuation: savedData.startup_valuation || '',
                        formation_date: savedData.formation_date || null,
                        companyName: savedData.company_name || '',
                        overview: savedData.overview || '',
                        // status: savedData?.startup_status?._id || '',
                        // comment: savedData.comment || '',
                        stage: savedData?.stage[0]?._id || '',
                        sector: savedData.sector?._id || '',
                        videos: savedData.videos
                            ? savedData.videos.map((video) => ({ heading: video.heading, url: video.url }))
                            : [{ heading: '', url: '' }],
                        leadInvestors: savedData.lead_investors
                            ? savedData.lead_investors.map((lead_investor) => ({ name: lead_investor.name }))
                            : [{ name: '' }],
                        founders: savedData.founders
                            ? savedData.founders.map((founder) => ({
                                name: founder.name,
                                linkedln_profile_url: founder.linkedln_profile_url
                            }))
                            : [{ name: '', linkedln_profile_url: '' }],
                        companyLogo: { name: savedData.image_name || '', url: savedData.image || '' },
                        documents: savedData.document
                            ? savedData.document.map((doc) => ({
                                name: doc.name,
                                file: { name: doc.file_name, url: doc.url, doc_id: doc.document_id }
                            }))
                            : [{ name: '', file: null }],
                        city_name: savedData.city_name || '',
                        // lead_investor_id: (savedData?.lead_investor_id && savedData.lead_investor_id.length) ? savedData.lead_investor_id : []
                        lead_investor_id: savedData?.lead_investor_id || '',
                        syndicate_id: savedData?.syndicate_id || '',
                        startup_details: savedData.startup_details,
                        entity_name: savedData.entity_name,
                    };

                    return savedValues;
                }
                return data.data.data[0];
            },
            refetchOnWindowFocus: false,
            enabled: !!id,
            // refetchIntervalInBackground:false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const AttachCheckListStartup = () => {
    return useMutation(({ payload, id }) => {
        return axios({
            method: 'put',
            url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/startup/${id}`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                "Content-type": "application/json"
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const UpdateStartupTask = () => {
    return useMutation(({ payload, id }) => {
        return axios({
            method: 'put',
            url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/startup/update-task-status/${id}`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                "Content-type": "multipart/form-data"
            },
            data: payload
        })
    }, {
        onSuccess: (data) => console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchTaskDataStartup = (id) => {
    return useQuery(['task-startup-data', id],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/startup/detail/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => {
            return data.data.data
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchMasterTemplateStartupDetails = (id, template_id) => {

    return useQuery(
        ['startup-details-master', id, template_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/startup/${id}?checklist_master_id=${template_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => {
            return data.data.data
        },
        refetchOnWindowFocus: true,
        enabled: !!id,
        // refetchIntervalInBackground:false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const UpdateStartupChecklistTask = () => {
    return useMutation(({ payload, id }) => {
        return axios({
            method: 'put',
            url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/startup/${id}`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                "Content-type": "application/json"
            },
            data: payload
        })
    }, {
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const DeleteStartupChecklistDocument = (id) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/detached-documents/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};


export const StartupMonthlyDocument = (id, startup_id, from_date, to_date) => {
    return useQuery(['deal_checklist-strssssssssssssssstup-data', id],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/report?startup_id=${startup_id}&checklist_master_id=${id}&checklist_type=monthly&from_date=${from_date}&to_date=${to_date}&api_type=startup`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => {
            return data.data
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        staleTime: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const AddMISReportAPI = (id) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/mis-report/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload.payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const FetchSelectedMISReport = (id, selected_date) => {
    return useQuery(['FetchSelectedMISReportFetchSelectedMISReport----', id, selected_date],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/mis-report/${id}?misreport_month_year=${selected_date}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => {
            return data?.data?.data
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        staleTime: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllMISReportGraphs = (id, fromDate, toDate, graphType) => {
    return useQuery(['FetchSelectedMISReportFetchSelectedMISReport----', id, fromDate, toDate, graphType],
        async () => {
            // const fromDate = payload?.from_date || "";
            // const toDate = payload?.to_date || "";
            // const graphType = payload?.graph_type || "";
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/mis-report/report/${id}?from_date=${fromDate}-04-01&to_date=${toDate}-03-01&graph_type=${graphType}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => {
            return data?.data?.data
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        staleTime: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const StartupTagsList = () => {
    return useQuery(
        ['tags-list'],
        async () => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_URL}/admin/setting/tags?type=startup`,
                    headers: {
                        Authorization: `Bearer ${Cookies.get('jwt')}`
                    }
                });

                // console.log(response.data.data,"response.data.data")

                const tagList = response.data.data?.map((tag) => ({
                    id: tag._id, // Use the appropriate property for the id
                    text: tag.name
                }));

                return tagList;
            } catch (error) {
                throw error;
            }
        },
        {
            refetchOnWindowFocus: false
        }
    );
};

export const RemoveSelectedDocument = (startup_id) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/startup/remove-documents/${startup_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error removing documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const ExportStartupsFromAllStartups = (stage, search, searchStatus, sort = 1, limit = 20, status, selectedTags = '') => {
    return useQuery(
        ['export-startup-dfsefaegwrg', sort, search, limit, selectedTags, status],
        async () => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_URL}/admin/startup?type=excel_report&searchText=${search}&syndicate_id=${stage}&startup_flow_status=${status}${sort != 0 ? `&sort=${sort}` : ''
                        }&limit=${limit}&tags=${selectedTags}`,
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('jwt')}`
                    },
                });
                return response.data;
            } catch (error) {
                // Handle error
                console.error(error);
                throw error;
            }
        },
        {
            enabled: false,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            staleTime: 0,
            onError: (err) => {
                return verifyToken(err);
            },
        }
    );
};

export const FetchAllInvoices = ({ page, sort = -1, limit, search,billing_to = "" }) => {
    return useQuery(
        ['all-invoices-s-data', page, sort, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/invoice?page=${page}&searchText=${search}${sort != 0 ? `&sort=${sort}` : ''
                    }&limit=${limit}&billing_to=${billing_to}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => {
            let selectedData = data.data.data?.length > 0 ? data.data.data?.map((item) => {
                return {
                    ...item,
                    invoice_type: item?.invoice_data[0]?.invoice_type,
                    invoice_no: item?.invoice_data[0]?.invoice_no,
                    startup_status: item?.stage_name,
                }
            }) : [];
            // console.log({data: selectedData,count: data.data.count},"selectederfef")
            return { data: selectedData, count: data.data.count }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchInvoiceStatusLogs = (id) => {
  return useQuery(
    ['fetch-dealss-status-logs', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/invoice/invoice_stage_logs/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => data.data.data[0]?.invoice_stage_changes,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const FetchAllStatusForInvoice = () => {
  return useQuery(
    ['fetch-dealss-status-invoicesddaswfwef'],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/invoice_status`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
        let selectValues = data.data.data?.map(currentEl => ({value: currentEl["_id"],label: currentEl?.values[0]?.name}));
        return selectValues
    },
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}



export const AttachFeedbackFormStartup = () => {
    return useMutation(({ payload, id }) => {
        return axios({
            method: 'put',
            url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/startup/${id}`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                "Content-type": "application/json"
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllFeedbacksListing = ({ page, sort = 1, status = "", search, limit, master_startup_id = "" }) => {
    return useQuery(
        ['all-deal-listing', page, sort, limit, status, master_startup_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/feeddbacks/user-feedbacks?page=${page}&status=${status}${master_startup_id != "" ? '&master_startup_id=' + master_startup_id : ""}&searchText=${search}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ""}${status == "Pending" ? "&field=feedBackMaster.partnerNotificationSent.updated_date" : status = "Completed" ? "&field=feedBackMaster.partnerNotificationSent.submitted_date" : ""}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        select: (data) => ({ data: data.data.data, count: data.data.count, limit: data.data.limit }),
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchUserFeedbackDetails = () => {
    return useMutation(
        async (payload) => {
            const master_startup_id = payload?.master_startup_id ;
            const feedback_master_id = payload?.feedback_master_id ;

            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/questions-answer/detail/${master_startup_id}/${feedback_master_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        },
        {
            select: (data) => ({

                data: data.data.data,
            }),
            refetchOnWindowFocus: true,
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const ViewUserFeedbackResponse = () => {
    return useMutation(
        async (payload) => {
            const partner_notification_sent_id = payload?.partner_notification_sent_id || "";

            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/questions-answer/view/${partner_notification_sent_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        },
        {
            select: (data) => ({

                data: data.data.data,
            }),
            refetchOnWindowFocus: true,
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const SubmitFeedbackForm = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/save-user-feedback`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                "Content-type": "application/json"
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const UpdateInvoiceStatus = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/admin/invoice/save_invoice_stage_logs`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                "Content-type": "application/json"
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull"),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const MISDownloadDocument = () => {
    return useMutation(({payload}) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/setting/document/get-secure-url`,
        headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`,
            "Content-type": "application/json"
        },
        data: payload
      })
    }, {
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        toast.error(err);
        // return verifyToken(err)
      },
    }) 
  }
