import { useState, useEffect, useRef } from 'react';
import { Paper, Skeleton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from 'react-icons/im';
import { UpdateDealStatusUnit, FetchUnitDealLogs, SendCallForMoneyMail } from 'api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Checkbox from '@mui/material/Checkbox';
import { useParams, useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { formatNumberWithCommas } from 'ui-component/FormatNumberWithCommas';
const validationSchema = yup.object({
    // min_investment_amount: yup
    // .string('Enter Min Investment Amount')
    // .required('Min Investment Amount is required')
    // .trim(),
    // max_investment_amount: yup
    // .string('Enter Max Investment Amount')
    // .required('Max Investment Amount is required')
    // .trim(),
    // closing_date: yup
    // .string('Enter Closing Date')
    // .required('Closing Date is required')
    // .trim(),
    // pitch_recording: yup
    // .string('Enter Pitch Recording')
    // .required('Pitch Recording is required')
    // .trim(),
    comment: yup.string('Comment Recording').required('Comment is required').trim()
});

const columns = [
    {
        label: 'Stage',
        minWidth: 0,
        align: 'left',
        type: 'stage_name'
    },
    {
        label: 'Comment',
        minWidth: 0,
        align: 'left',
        type: 'comment'
    },
    {
        label: 'Updated At',
        minWidth: 0,
        align: 'left',
        type: 'created_date'
    },
    {
        label: 'Updated  By',
        minWidth: 0,
        align: 'left',
        type: 'admin_name'
    },

    {
        label: 'Type',
        minWidth: 0,
        align: 'left',
        type: 'type'
    }
];

const CallForMoneyMailPopup = ({
    handleClose,
    dealId,
    mode,
    deal_completed_date,
    deal_current_value,
    scemeName,
    setCheckValues,
    refetchTransactions,
    refetchTranches,
    CheckValuesCount,
    ref_amount_total,
    mailType
}) => {

    const [logPage, setLogPage] = useState(1);
    const theme = useTheme();
    const { id: deal_id } = useParams();
    const navigate = useNavigate();
    //   const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = UpdateDealStatusUnit();
    const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = SendCallForMoneyMail();
    //   const isCompletedMode = mode === "completed";
    const today = new Date();
    const formattedDate = today.toISOString();
    const formik = useFormik({
        initialValues: {
            // deal_status: deal_status,
            // unit_value: 1000,
            // deal_completed_date: formattedDate,
            comment: '',
            transaction_ids: setCheckValues,
            // tranch_name:''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const payload = {
                // deal_status: "completed",
                // current_unit_value: values.unit_value.toString(),
                comment: values.comment,
                // unit_alloted_date: values.deal_completed_date,
                transaction_ids: setCheckValues,
                mail_type: mailType
                // tranch_name:values.tranch_name
            };
            UpdateDealStatus(
                { payload, id: dealId },
                {
                    onSuccess: (succ) => {
                        setTimeout(() => {
                            refetchTransactions();
                            refetchTranches();
                        }, 200);
                        toast.success(JSON.parse(succ.request.response).message, {
                            position: 'top-left',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                        handleClose();
                    },
                    onError: (err) => {
                        // console.log(JSON.parse(err.request.response).message);
                        toast.error(JSON.parse(err.request.response).message, {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                        handleClose();
                    }
                }
            );
        }
    });

    return (
        <div>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10 }}>
                        <h3 style={{ textAlign: 'center' }}>Are You Sure You Want to Send Call For Money Email</h3>
                        <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                            {/* <Grid item xs={12}>
                              <CustomField name="tranch_name" label="Tranch Name"/>
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <CustomField
                                    name="unit_value"
                                    label="Unit Value"
                                    compulsory={true}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        onKeyDown: (event) => {
                                            // Prevent the minus sign (-) from being entered
                                            if (event.key === '-') {
                                                event.preventDefault();
                                            }
                                        },
                                        onKeyPress: (event) => {
                                            // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                            if (event.key === '-') {
                                                event.preventDefault();
                                            }
                                        },
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        onKeyDown: (event) => {
                                            // Prevent the input of minus sign (key code 189)
                                            if (event.keyCode === 189 || event.key === '-') {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Fund Transfer Date"
                                        // disablePast={true}

                                        value={formik.values.deal_completed_date}
                                        onChange={(newValue) => formik.setFieldValue('deal_completed_date', moment(newValue).toISOString())}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={Boolean(formik.touched.deal_completed_date && formik.errors.deal_completed_date)}
                                                helperText={formik.touched.deal_completed_date && formik.errors.deal_completed_date}
                                                name="deal_completed_date"
                                            />
                                        )}
                                        inputFormat="DD/MM/YYYY"
                                    />
                                </LocalizationProvider>
                            </Grid> */}
                            <Grid item xs={12}>
                                <CustomTextArea name="comment" label="Comment" multiline={true} minRows={1} sx={{ borderWidth: 2 }} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={2}>

                            <CustomButton type="submit">Submit</CustomButton>
                            
                        </Grid>
                    </Form>
                </FormikProvider>
            </Paper>
        </div>
    );
};

export default CallForMoneyMailPopup;
