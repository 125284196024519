import { useEffect } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
// import {MenuItems} from 'menu-items';
import Cookies from 'js-cookie';
import { useState } from 'react';
import dashboard from './dashboard';
import startup from './startup';
import investor from './investor';
import admin_investor from './admin_investor';
import report from './report';
import deal from './deal';
import feedback from './feedback';
import resource from './resource';
import portfolio from './portfolio';
import master from './master';
import support from './support';
import articles from './article';
import syndicates from './syndicates'
import users from './users'
import venture_partner from './venture_partner'
import { IconReport, IconNotes, IconGraph, IconBuildingSkyscraper, IconRocket, IconUsers, IconUserPlus, IconUpload, IconCheckupList, IconSettings, IconMessage2, IconFileInvoice } from '@tabler/icons';
import UploadDocument from './UploadDocument';
import { useLocation } from 'react-router-dom';
import UserGroup from 'assets/svg/userGroup'
import fees from './fees';
import content from './content';
import bank from './bank';
// assets
import { IconFileDollar, IconHomeDollar } from '@tabler/icons';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { Feedback } from '@mui/icons-material';
const icons = { IconCheckupList, IconSettings };
// ==============================|| SIDEBAR MENU LIST ||============================== //


const MenuList = () => {
    const [menuItems, setmenuItems] = useState([])
    const location = useLocation();
    const parentPath = location.pathname.split('/').slice(1, 2).join('/');
    useEffect(() => {
        Cookies.remove("search")
        Cookies.remove("searchStatus")
        if (parentPath != "venture_Partner" && parentPath != "investors") {
            Cookies.remove("venturPartnerSearch")
            Cookies.remove("venturPartnersearchStatus")
        }
    }, [parentPath]);

    useEffect(() => {
        const permission_values = localStorage.getItem('permission');
        const is_super_admin = Cookies.get("is_super_admin");
        const permissions = permission_values != undefined ? JSON.parse(permission_values) : [];
        const array_items = [dashboard];
        const array_reports = [];
        report.children[0].children = []
        startup.children[0].children = []
        content.children[0].children = []
        admin_investor.children[0].children = []
        investor.children[0].children = []
        master.children[0].children = []
        if (permissions && permissions.length > 0) {
            permissions.forEach((val, key) => {
                // val.module_name == "dashboard" && val.permisson && val.permisson == true ? array_items.push(dashboard) : "";
                // if(val.module_name == "SOA_upload" ||  val.module_name == "Investors" ){

                // }
                if (val.module_name == "Investors" && val.permisson && val.permisson == true) {
                    // if(val.soa_upload == true){
                    admin_investor.children[0].children.push({
                        id: 'investor111',
                        title: 'All Investors',
                        type: 'item',
                        icon: IconUsers,
                        url: `/investors`,
                        breadcrumbs: false
                    })
                    // if (val?.lead_investors == true) {
                        admin_investor.children[0].children.push({
                            id: 'investor114',
                            title: 'Lead Investors',
                            type: 'item',
                            icon: IconUsers,
                            url: `/lead_investors`,
                            breadcrumbs: false
                        })
                    // }

                    if (permissions.find(item => item.module_name == "syndicates" && item.permisson && item.permisson == true)) {
                        admin_investor.children[0].children.push({
                            id: 'syndicates111',
                            title: 'Syndicates',
                            type: 'item',
                            icon: UserGroup,
                            url: `/syndicates`,
                            breadcrumbs: false
                        })
                    }
                    if (val.venture_partner == true) {
                        admin_investor.children[0].children.push(
                            {
                                id: 'veture_partner11',
                                title: 'Venture Partners',
                                type: 'item',
                                url: `/venture_Partner`,
                                icon: IconUserPlus,
                                breadcrumbs: false
                            })
                    }
                    if (val?.soa == true) {
                        admin_investor.children[0].children.push({
                            id: 'investor112',
                            title: 'SOA',
                            type: 'item',
                            icon: IconUpload,
                            url: `/investors/soa_uploads`,
                            breadcrumbs: false
                        })
                    }
                    if (val?.stamps == true) {
                        admin_investor.children[0].children.push({
                            id: 'investor113',
                            title: 'Stamps',
                            type: 'item',
                            icon: IconUpload,
                            url: `/investors/stamp_uploads`,
                            breadcrumbs: false
                        })
                    }
                    array_items.push(admin_investor)
                    // }
                    // else{
                    //     array_items.push(investor)
                    // }
                }
                (key == 4 && permissions?.find(item => item.module_name == 'bank' && item.permisson && item.permisson == true)) && array_items.push(bank)
                key == 5 && is_super_admin == "true" ? array_items.push(UploadDocument) : ""
                //    is_super_admin  == "true" && val.module_name == "Investors" && val.permisson && val.permisson == true  &&  val.venture_partner == true ? array_items.push(venture_partner) : "";

                // val.module_name == "syndicates" && val.permisson && val.permisson == true  ? array_items.push(syndicates) : "";
                if (val.module_name == "reports" && val.permisson && val.permisson == true) {
                    // if (val.lp_summary) {
                    //     report.children[0].children.push({
                    //         id: 'report111',
                    //         title: 'LP Summary',
                    //         type: 'item',
                    //         icon: IconNotes,
                    //         url: `/lp-summary`,
                    //         breadcrumbs: false
                    //     });
                    // }
                    if (val.vp_investment) {
                        report.children[0].children.push({
                            id: 'report112',
                            title: 'VP Investment Details',
                            type: 'item',
                            icon: IconGraph,
                            url: `/vp-investment-details`,
                            breadcrumbs: false
                        });
                    }
                    if (val.live_deal_summary) {
                        report.children[0].children.push({
                            id: 'report113',
                            title: 'Live Deals Summary',
                            type: 'item',
                            icon: IconGraph,
                            url: `/live-deals-summary`,
                            breadcrumbs: false
                        });
                    }
                    if (val.missing_investor_data) {
                        report.children[0].children.push({
                            id: 'report114',
                            title: 'Missing Investors Data',
                            type: 'item',
                            icon: IconGraph,
                            url: `/missing-investor-data`,
                            breadcrumbs: false
                        });
                    }
                    if (val.investor_onboarding_summery) {
                        report.children[0].children.push({
                            id: 'report115',
                            title: 'Investors Onboarding Summary',
                            type: 'item',
                            icon: IconGraph,
                            url: `/investor-onboarding-data`,
                            breadcrumbs: false
                        });
                    }
                    if (val.active_invactive_investors) {
                        report.children[0].children.push({
                            id: 'report116',
                            title: 'Active/Inactive Investors',
                            type: 'item',
                            icon: IconGraph,
                            url: `/investor-inactive-active-data`,
                            breadcrumbs: false
                        });
                    }
                    // if (val.partner_target_fees) {
                    //     report.children[0].children.push({
                    //         id: 'report117',
                    //         title: 'Partner Target Fees',
                    //         type: 'item',
                    //         icon: IconGraph,
                    //         url: `/target-amount-fees`,
                    //         breadcrumbs: false
                    //     });
                    // }
                    if (val.deal_document_checklist) {
                        report.children[0].children.push({
                            id: 'report118',
                            title: 'Deal Document Checklist ',
                            type: 'item',
                            icon: IconGraph,
                            url: `/deal-document-checklist`,
                            breadcrumbs: false
                        });
                    }
                    if (val.startup_document_checklist) {
                        report.children[0].children.push({
                            id: 'report119',
                            title: 'Startup Document Checklist ',
                            type: 'item',
                            icon: IconGraph,
                            url: `/startup-document-checklist`,
                            breadcrumbs: false
                        });
                    }
                    if (val.qar_report) {
                        report.children[0].children.push({
                            id: 'report220',
                            title: 'QAR',
                            type: 'item',
                            icon: IconGraph,
                            url: `/qar-reports-data`,
                            breadcrumbs: false
                        });
                    }
                    if (val.fees_report) {
                        report.children[0].children.push({
                            id: 'report221',
                            title: 'Partner Payout',
                            type: 'item',
                            icon: IconGraph,
                            url: `/fees-reports-data`,
                            breadcrumbs: false
                        });
                    }
                    array_items.push(report)
                }
                if (val.module_name == "startup" && val.permisson && val.permisson == true) {
                    if (permissions.find(item => item.module_name == "deals" && item.permisson && item.permisson == true)) { startup.children[0].children.push(deal.children[0]); }

                    if (val.listing) {
                        startup.children[0].children.push({
                            id: 'startup111',
                            title: 'Selected',
                            type: 'item',
                            url: `/startups/selected`,
                            icon: IconBuildingSkyscraper,
                            breadcrumbs: false
                        });

                    }
                    if (val.application_listing) {
                        startup.children[0].children.push({
                            id: 'startup112',
                            title: 'Applications',
                            type: 'item',
                            url: `/applicationListing`,
                            icon: IconBuildingSkyscraper,
                            breadcrumbs: false
                        });
                    }

                    if (val.feedbacks) {
                        startup.children[0].children.push({
                            id: 'startup113',
                            title: 'Feedbacks',
                            type: 'item',
                            url: `/feedbacks`,
                            icon: IconMessage2,
                            breadcrumbs: false
                        })
                    };

                    if (val.invoices) {
                        startup.children[0].children.push({
                            id: 'startup114',
                            title: 'Invoices',
                            type: 'item',
                            url: `/invoices`,
                            icon: IconFileInvoice,
                            breadcrumbs: false
                        })
                    }

                    array_items.push(startup)
                };
                // val.module_name == "articles" && val.permisson && val.permisson == true ? array_items.push(articles) : "";
                // val.module_name == "deals" && val.permisson && val.permisson == true ? array_items.push(deal) : "";
                // val.module_name == "Lp_summary" && val.permisson && val.permisson == true ? array_items.push(report) : "";
                // val.module_name == "feedback" && val.permisson && val.permisson == true ? array_items.push(feedback) : "";
                // val.module_name == "dashboard" && val.permisson && val.permisson == true ? array_items.push(master) : "";
                if (val.module_name == "users" && val.permisson && val.permisson == true) {
                    array_items.push(users)
                }
                if (val.module_name == "settings" && val.permisson && val.permisson == true) {
                    if (val?.lists) {
                        master.children[0].children.push({
                            id: 'master111',
                            title: 'Lists',
                            type: 'item',
                            url: `/master`,
                            icon: icons.IconSettings,
                            breadcrumbs: false
                        })
                    }
                    if (val?.checklists) {
                        master.children[0].children.push(
                            {
                                id: 'master112',
                                title: 'Checklists',
                                type: 'item',
                                url: `/checklist`,
                                icon: icons.IconCheckupList,
                                breadcrumbs: false
                            }
                        )
                    }
                    if (val?.fees) {
                        master.children[0].children.push(
                            {
                                id: 'fees11',
                                title: 'Fees',
                                type: 'item',
                                url: `/fees`,
                                icon: IconFileDollar,
                                breadcrumbs: false
                            }
                        )
                    }
                    if (val?.reports) {
                        master.children[0].children.push(
                            {
                                id: 'master113',
                                title: 'Reports',
                                type: 'item',
                                url: `/reports`,
                                icon: IconReport,
                                breadcrumbs: false
                            },
                        )
                    }
                    if (val?.feedbacks) {
                        master.children[0].children.push({
                            id: 'master114',
                            title: 'Feedbacks',
                            type: 'item',
                            url: `/feedback_master`,
                            icon: IconMessage2,
                            breadcrumbs: false
                        })
                    }

                    array_items.push(master);
                };
            });

        }
        if (is_super_admin == "true" || is_super_admin == true) {
            // array_items.push(venture_partner)
            // array_items.push(fees)

            // array_items.push(master)
            // array_items.push(articles)
            // array_items.push(feedback)
            // array_items.push(UploadDocument)
            content.children[0].children.push(articles.children[0])
            content.children[0].children.push(feedback.children[0])
            array_items.push(content)
        }
        // else {
        //     if (permissions.find(item => item.module_name == "settings" && item.permisson && item.permisson == true && item.checklists && item.checklists == true)) {
        //         master.children[0].children.push(
        //             {
        //                 id: 'master112',
        //                 title: 'Checklists',
        //                 type: 'item',
        //                 url: `/checklist`,
        //                 icon: icons.IconCheckupList,
        //                 breadcrumbs: false
        //             }
        //         )
        //         array_items.push(master);
        //     }
        // }
        setmenuItems(array_items);

    }, [Cookies])
    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;