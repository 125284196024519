// assets
import { IconUserPlus} from '@tabler/icons';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const venture_partner = {
    id: 'veture_partner1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'veture_partner11',
            title: 'Venture Partners',
            type: 'item',
            url: `/venture_Partner`,
            icon: IconUserPlus,
            breadcrumbs: false
        }
    ]
};

export default venture_partner;
