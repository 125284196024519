import { TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';

const CustomSelect = withStyles((theme)=>({
  root: {
    '& .MuiSelect-select.MuiSelect-outlined': {
      backgroundColor: "#fff",
      borderRadius: '10px'
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary[200],
      borderWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary[200],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary[200],
        borderWidth: 0.5,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[800],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[800],
        borderWidth: 1,
        borderLeftWidth: 6,
        padding: '4px !important',
      },
    },
  },
}))(TextField);

const configSelect = {
  select: true,
  variant: 'outlined',
  fullWidth: true,
  label: 'Stage'
}


function SelectField({options, ...props}) {
  return (
    <CustomSelect {...configSelect} {...props}>
    {options?.map((item, pos) => {
      return (
        <MenuItem key={pos} value={item.value}>
          {item.label}
        </MenuItem>
      )
    })}
  </CustomSelect>
  )
}

export default SelectField