import { useQuery ,useMutation} from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchLpListingData = () => {
  return useQuery(['lp-listing'], async () => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/partners`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`
      },
    })
  }, 
  {
    select: (data) => {
      return data.data.data
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    // staleTime: 1000 * 60 * 5,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const ExportLplisters = (excel_report) => {
  return useQuery(
    ['export-partners'],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/partners?type=${excel_report}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};
export const FetchVenturePartners = () => {
  return useQuery(['venture-partner'], async () => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/venture-partners`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`
      },
    })
  }, 
  {
    select: (data) => {
      return data.data.data
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    // staleTime: 1000 * 60 * 5,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const ExportVenturePartners = (excel_report) => {
  return useQuery(
    ['export-venturepartners'],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/venture-partners?type=${excel_report}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const ExportLiveDeals = (excel_report) => {
  return useQuery(
    ['export-livedeals'],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/deals?type=${excel_report}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const FetchActiveInactiveInvestorData = () => {
  return useQuery(['active-inactive-investors'], async () => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/inactive-investor-summary`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`
      },
    })
  }, 
  {
    select: (data)=>({data: data.data.data, headers: data.data.headers,totalHeader:data.data.totalHeader,totalInvestor:data.data.totalInvestor}),
    // select: (data) => {
    //   return data.data.data
    // },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    // staleTime: 1000 * 60 * 5,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const ExportActiveInactive = () => {
  return useMutation((payload) => {
        // console.log(payload,"payloadpayloadpayloadpayload");
          return axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/inactive-investor-report?investor_partner_id=${payload?.investor_partner_id}`,
            headers: {
              'Authorization': `Bearer ${Cookies.get('jwt')}`
            },
          });
      }, {
    select: (data) => ({ data: data.data.data, count: data.data.count, limit: data.data.limit,kycCountData:data.data.kycCountData }),
    refetchOnWindowFocus: true,
    retry: 0,
    onError: (err) => {
      // Handle errors or perform actions on error if needed
      console.error('Error generating documents', err);
      throw err; // Re-throw the error for further handling if necessary
    },
  })
}

export const ExportDeals = (queryString) => {
  return useQuery(
    ['all-deals'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/listing/deals${queryString}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((stage)=>{
      return ({label: stage.scheme_name, value:stage['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}
export const AllDealListing = ({mode = ""}) => {
  return useQuery(
    ['all-deals',mode], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal?deal_status=${mode}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((stage)=>{
      return ({label: stage.scheme_name, value:stage['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const DealsTargetFees = () => {
  return useMutation(
    (payload) => {
        // console.log(payload, 'payloadpayloadpayloadpayload');
        return axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/partner-target-report/${payload?.stage}`,
            headers: {
                Authorization: `Bearer ${Cookies.get('jwt')}`
            }
        });
    },
    {
        select: (data) => ({
            data: data.data.data,
            total: data.data.total,
            // limit: data.data.limit,
            // kycCountData: data.data.kycCountData
        }),
        refetchOnWindowFocus: true,
        retry: 0,
        onError: (err) => {
            // Handle errors or perform actions on error if needed
            console.error('Error generating documents', err);
            throw err; // Re-throw the error for further handling if necessary
        }
    }
);
};

export const DealsDocumentChecklist = () => {
  return useMutation(
    (payload) => {
      const fromDate = payload?.from_date || "";
        const toDate = payload?.to_date || "";
        const apiType = "deal";
        const checklist_type = payload?.checklist_type || "";
        const startup_deal_id = payload?.startup_deal_id == 'All Deals' ? "" : payload?.startup_deal_id || "";
        const checklist_master_id = payload?.checklist_master_id || ""; 
        const taskStatus = payload?.task_status; 
        
        return axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/report?api_type=${apiType}&checklist_master_id=${checklist_master_id}&startup_deal_id=${startup_deal_id}&checklist_type=${checklist_type}&from_date=${fromDate}&to_date=${toDate}&task_status=${checklist_type !== 'monthly' ? taskStatus : ''}`,
            headers: {
                Authorization: `Bearer ${Cookies.get('jwt')}`
            }
        });
    },
    {
        select: (data) => ({
          
            data: data.data.data,
        }),
        refetchOnWindowFocus: true,
        retry: 0,
        onError: (err) => {
            // Handle errors or perform actions on error if needed
            console.error('Error generating documents', err);
            throw err; // Re-throw the error for further handling if necessary
        }
    }
);
};

export const StartupDocumentChecklist = () => {
  return useMutation(
    (payload) => {
      const fromDate = payload?.from_date || "";
        const toDate = payload?.to_date || "";
        const apiType = "startup";
        const checklist_type = payload?.checklist_type || "";
        const startup_deal_id = payload?.startup_deal_id == 'All Startups' ? "" : payload?.startup_deal_id || "";
        const checklist_master_id = payload?.checklist_master_id || "";
        const taskStatus = payload?.task_status; 
        return axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/report?api_type=${apiType}&checklist_master_id=${checklist_master_id}&startup_id=${startup_deal_id}&checklist_type=${checklist_type}&from_date=${fromDate}&to_date=${toDate}&task_status=${checklist_type !== 'monthly' ? taskStatus : ''}`,
            headers: {
                Authorization: `Bearer ${Cookies.get('jwt')}`
            }
        });
    },
    {
        select: (data) => ({
          
            data: data.data.data,
        }),
        refetchOnWindowFocus: true,
        retry: 0,
        onError: (err) => {
            // Handle errors or perform actions on error if needed
            console.error('Error generating documents', err);
            throw err; // Re-throw the error for further handling if necessary
        }
    }
);
};

export const FetchFeesReport = (value,investor_partner_id,investor_venture_partner_id,search = "",queryString = '') => {
  return useQuery(
    ['all-compeleted-deals-fees-data'+ value, value,investor_partner_id,investor_venture_partner_id], 
    () => {
      let investorPartnerID = investor_partner_id || "";
      let investorVenturePartnerID = investor_venture_partner_id || "";
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/${value == 'one'? 'report-venture-partner': value == 'two' ? 'lead-investor-report' : "" }?${value == "two" ? `investor_partner_id=${investorPartnerID}&investor_venture_partner_id=${investorVenturePartnerID}` : ""}${queryString}&searchText=${search}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>(data.data),
    refetchOnWindowFocus:true,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const ExportFetchFeesReport = (value,investor_partner_id,investor_venture_partner_id,search = "",queryString = '') => {
  return useMutation(() => {
    let investorPartnerID = investor_partner_id || "";
      let investorVenturePartnerID = investor_venture_partner_id || "";
        // console.log(payload,"payloadpayloadpayloadpayload");
          return axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/${value == 'one'? 'report-venture-partner': value == 'two' ? 'lead-investor-report' : "" }?${value == "two" ? `investor_partner_id=${investorPartnerID}&investor_venture_partner_id=${investorVenturePartnerID}` : ""}${queryString}&searchText=${search}`,
            headers: {
              'Authorization': `Bearer ${Cookies.get('jwt')}`
            },
          });
      }, {
        select: (data)=>(data.data),
        refetchOnWindowFocus:true,
        retry: (failureCount, error)=>{
          return RetryOnError(failureCount, error)
        },
        onError: (err)=>{
          return verifyToken(err)
        },
  })
}
// export const ExportFetchFeesReport = (value,investor_partner_id,investor_venture_partner_id,search = "",queryString = '') => {
//   return useQuery(
//     ['all-compeleted-deals-fees-data'+ value, value,investor_partner_id,investor_venture_partner_id], 
//     () => {
//       let investorPartnerID = investor_partner_id || "";
//       let investorVenturePartnerID = investor_venture_partner_id || "";
//       return axios({
//         method: 'get',
//         url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/${value == 'one'? 'report-venture-partner': value == 'two' ? 'lead-investor-report' : "" }?${value == "two" ? `investor_partner_id=${investorPartnerID}&investor_venture_partner_id=${investorVenturePartnerID}` : ""}${queryString}&searchText=${search}`,
//         headers: { 
//           'Authorization': `Bearer ${Cookies.get('jwt')}`
//         },
//       }) 
//   },{
//     select: (data)=>(data.data),
//     refetchOnWindowFocus:true,
//     retry: (failureCount, error)=>{
//       return RetryOnError(failureCount, error)
//     },
//     onError: (err)=>{
//       return verifyToken(err)
//     },
//   })
// }

export const FetchInvoiceReport = () => {
  return useMutation((payload) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/invoice/generate`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const SendInvoiceAttachedEmail = () => {
  return useMutation((payload) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/invoice/send-invoice-email`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}