
export const formatNumberWithCommas = (input) => {
    if (input === undefined || input === null) {
        return '';
    }

    if (typeof input === 'string') {
        const number = parseFloat(input);
        if (!isNaN(number)) {
            return number.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
        }
    } else if (typeof input === 'number') {
        return input.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
    }

    return input;
}
