import { useState } from 'react';
import { useSelector } from 'react-redux';
// import Logo from 'assets/images/backrr1.png';
import Logo from 'assets/images/logortaf.png';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import Cookies from 'js-cookie';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [checked, setChecked] = useState(true);
    const currentLocation = window.location?.pathname + window.location?.search;
    // console.log(currentLocation,"currentLocation")

    const googleHandler = async () => {
        console.error('Login');
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <ToastContainer />
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    {/* <Box sx={{ mb: 2 }}> */}
                    <img src={Logo} alt="Company Logo" style={{ width: '40%' }} />
                    {/* </Box> */}
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <h1>Admin Login</h1>
                    </Box>
                </Grid>
            </Grid>
            
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        axios
                            .post(`${process.env.REACT_APP_BASE_URL}/admin/login`, values)
                            .then((response) => {
                                toast.success(response.data.data);
                                // console.log(response.data.data,"response.data.data")
                                Cookies.set('jwt', response.data.data.token, { path: '/' });
                                Cookies.set('name', response.data.data.name, { path: '/' });
                                Cookies.set('user_id', response.data.data._id, { path: '/' });
                                Cookies.set('slug', response.data.data.slug, { path: '/' });
                                Cookies.set('profile_image', response.data.data.image, { path: '/' });
                                Cookies.set('email', response.data.data.email, { path: '/' });
                                Cookies.set('deal-index', 0, { path: '/' });
                                Cookies.set('investor-index', 0, { path: '/' });
                                Cookies.set('master-index', 0, { path: '/' });
                                const jsonArrayString = JSON.stringify(response.data.data.permission);
                                // Cookies.set('permission', jsonArrayString, { path: '/' });
                                localStorage.setItem('permission',jsonArrayString);
                                // console.log(response.data.data.permission,"response.data.data.permission")
                                Cookies.set('permission_id', response.data.data.permission_id, { path: '/' });
                                Cookies.set('investor-search', ['', '', '', '', ''], { path: '/' });
                                Cookies.set('user_role', response.data.data.role, { path: '/' });
                                Cookies.set('is_super_admin', response.data.data.is_super_admin, { path: '/' });
                                Cookies.set('fees-index', 0, { path: '/' });
                                Cookies.set('syndicate_id', JSON.stringify(response.data.data?.syndicate_id), { path: '/' });
                                if(currentLocation?.includes('feedbacks')){
                                    // console.log()
                                    navigate(currentLocation);
                                    Cookies.set('feedback_link', true);
                                }
                                else {
                                    navigate('/');
                                }
                            })
                            .catch((err) => {
                                setStatus({ success: false });
                                setErrors({ submit: err.response.data.message });
                                setSubmitting(false);
                            });
                        setStatus({ success: true });
                        setSubmitting(false);
                    } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.response.data.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Email Address / Username"
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                        </Stack>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="secondary">
                                    Sign in
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FirebaseLogin;
