// project imports
import config from 'config';
// action - state management
import * as actionTypes from './actions';
import Cookies from 'js-cookie';


const tabs = ["pending", "submitted", "review", "approved","request_sent","cancelled" ,"all","lead_investors"]
export const initialState = {
    pending: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
    submitted: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,

    },
    review: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
    approved: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
    request_sent: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
    cancelled: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
    all: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
    lead_investors: {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        search: '',
        master_user_type_id: '',
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        isModelOpen: false,
        isSearching: false,
        syndicate_id:[],
        tags:'',
        from_on_board_date:null,
        to_on_board_date:null,
        gender:'',
        user_individual_type:'',
        // individual_type:'',
        account_type_id:[],
        country_cityzenship_id:[],
        from_created_date:null,
        to_created_date:null,
    },
};

// ==============================|| INVESTOR REDUCER ||============================== //

const InvestorReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.OPEN_MODAL:
			return { 
                ...state, 
                [tabs[Cookies.get("investor-index")]]: {
                    ...state[tabs[Cookies.get("investor-index")]], 
                    isModelOpen: true
                } 
            };
		case actionTypes.CLOSE_MODAL:
			return { 
                ...state, 
                [tabs[Cookies.get("investor-index")]]: {
                    ...state[tabs[Cookies.get("investor-index")]], 
                    isModelOpen: false
                } 
            };
        case actionTypes.UPDATE_FILTERS:
            return { 
                ...state, 
                [tabs[Cookies.get("investor-index")]]: {
                    ...state[tabs[Cookies.get("investor-index")]], 
                    // isPartner: action.payload.isPartner,
                    // isVenturePartner: action.payload.isVenturePartner, 
                    master_user_type_id: action.payload.master_user_type_id, 
                    partner: action.payload.partner, 
                    venturePartner: action.payload.venturePartner, 
                    representative: action.payload.representative,
                    syndicate_id:action.payload.syndicate_id,
                    tags:action.payload.tags,
                    from_on_board_date:action.payload.from_on_board_date,
                    to_on_board_date:action.payload.to_on_board_date,
                    gender:action.payload.gender,
                    user_individual_type:action.payload.user_individual_type,
                    // individual_type:action.payload.individual_type,
                    account_type_id:action.payload.account_type_id,
                    country_cityzenship_id:action.payload.country_cityzenship_id,
                    from_created_date:action.payload.from_created_date,
                    to_created_date:action.payload.to_created_date,
                } 
            };
        case actionTypes.RESET_FILTERS:
            return { 
                ...state, 
                [tabs[Cookies.get("investor-index")]]: {
                    ...state[tabs[Cookies.get("investor-index")]], 
                    // isPartner: 'No', 
                    // isVenturePartner: 'No',
                    master_user_type_id: '',
                    partner: { _id: '', lp_code: '', name: '' }, 
                    venturePartner: { _id: '', lp_code: '', name: '' }, 
                    representative: { _id: '', lp_code: '', name: '' },
                    syndicate_id:[],
                    tags:'',
                    from_on_board_date:null,
                    to_on_board_date:null,
                    gender:'',
                    user_individual_type:'',
                    // individual_type:'',
                    account_type_id:[],
                    country_cityzenship_id:[],
                    from_created_date:null,
                    to_created_date:null,
                } 
            };
		default:
			return state;
	}
};

export default InvestorReducer;