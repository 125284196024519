import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchDealFlow = (mode = "") => {
  return useQuery(['dealflow-listing'],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/home`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      if (mode == "options") {
        return data.data.data.map((dealflow) => ({ label: dealflow.values[dealflow.values.length - 1].name, value: dealflow._id }))
      }
      return data.data.data
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const AllDealsOfInvestor = ({ id = "" }) => {
  return useQuery(['all-deals-of-investor', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/list/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => ({
      deals: data.data.data,
      count: data.data.dealCount,
      limit: data.data.limit,
      totalInterestedAmount: data.data.interstedAmountSum,
      totalAmount: data.data.investmentAmountSum,
      realInterstedAmountSum: data.data.realInterstedAmountSum,
      realInvestmentAmountSum: data.data.realInvestmentAmountSum,
      interstedAmountSumComma: data.data.interstedAmountSumComma,
      investmentAmountSumComma: data.data.investmentAmountSumComma
    }),
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const AllDealsOfInvestorExcel = (id) => {
  return useQuery(
    ['export-dealtransactions-export'],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/deal/list/${id}?type=excel_report`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const FetchDealDetail = (id, mode = "") => {
  return useQuery(
    ['fetch-deal-detail323' + id, id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/detail/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      if (mode == 'edit') {

        const savedData = data.data.data[0];
        const object1 = [
          // { key: "Entity Name", value: "" },
          // { key: "Scheme Name", value: "" ,disabled : "disabled" ,addMore:false},
          // { key: "", value: "" },
          { key: "Round Size (INR)", value: "", name: "Round Size (INR)" },
          { key: "Valuation", value: "", name: "Valuation (Pre-Money INR)" },
          // { key: "Securities Type", value: "", name: "Securities Type" },
          { key: "Fund Byte (INR)", value: "", name: "Fund Byte (INR)" },
          { key: "Other Investors", value: "", name: "Other Investors" },
          // { key: "Transaction Fee", value: "", name: "Transaction Fee" },
          { key: "Carry %", value: "", name: "Carry %" },
          { key: "Valuation Highlights", value: "", name: "Valuation Highlights" }
        ]

        // Iterate through object 2
        savedData.deal_details.forEach(item2 => {
          // Find the corresponding item in object 1
          const matchingItem = object1.find(item1 => item1.key === item2.key);

          // If a match is found, update the value in object 1
          if (matchingItem) {
            matchingItem.value = item2.value;
          }



        });


        const updatedObject1 = savedData.deal_details.filter(item1 => !object1.some(item2 => item2.key === item1.key));


        const initialvalue = {
          scheme_name: savedData.scheme_name || '',
          serial_number: savedData.serial_number || '',
          deal_flow_id: savedData.deal_flow_id || '',
          startup_id: savedData.startup_id || '',
          unit_allotment_date: savedData?.deal_flow_dates?.unit_allotment_date || null,
          closing_deal_date: savedData?.deal_flow_dates?.call_money_closing_date || null,
          closing_date: savedData?.deal_flow_dates?.investment_closing_date || null,
          investment_started_date: savedData?.deal_flow_dates?.investment_started_date || null,
          call_started_date: savedData?.deal_flow_dates?.call_money_started_date || null,
          deal_completed_date: savedData?.deal_flow_dates?.deal_completed_date || null,
          createdAt: savedData.createdAt || null,
          bank_account_id: savedData.bank_account_id || '',
          min_investment_amount: savedData.min_investment_amount || '',
          max_investment_amount: savedData.max_investment_amount || '',
          deal_type: savedData.deal_type || null,
          convertible_securities: savedData.convertible_securities || null,
          number_of_securities: savedData.number_of_securities || null,
          // scheme_letter: { name: savedData.scheme_letter_name || '', url: savedData.scheme_letter[0] || '' } || '',
          show_in_app: savedData.show_in_app || "Yes",
          fund_checklist: savedData.fund_checklist || "",
          meetings: savedData.meetings && savedData.meetings.length > 0 ? savedData.meetings.map((meeting) => ({
            title: meeting.title || "",
            url: meeting.url || "",
            date: meeting.date || null,
            pitch_recording: meeting.pitch_recording || '',
          })) : [
            {
              title: "",
              url: "",
              date: null,
              pitch_recording: '',
            }
          ],
          deal_details: updatedObject1,
          deal_details_constant: object1,
          faq: savedData.faq ? savedData.faq : [
            { question: "", answer: "" }
          ],
          deal_share_link: savedData.deal_share_link || '',
          // deal_status: savedData.deal_status || '',
          round_name: savedData.round_name || '',
          real_time_analysis: savedData.real_time_analysis ? savedData.real_time_analysis.map((el) => ({ label: el.name, value: el['_id'], rating: el.rating })) : [{ label: '', value: '', rating: '' }],
          lead_investor_id: (savedData?.lead_investor_id && savedData?.lead_investor_id.length && savedData?.lead_investor_id) || null,
          syndicate_id: (savedData?.syndicate_id && savedData?.syndicate_id.length && savedData?.syndicate_id) || null,
          syndicate_fees: savedData.syndicate_fees || '',
          investor_partner_id: String(savedData?.investor_partner_id) || null,
          fund_bite_amount: savedData.fund_bite_amount || '',
          sha_signing_date: savedData?.sha_signing_date || null,
          unit_class: savedData?.unit_class != undefined ? savedData?.unit_class : [],

          // nature_investment: {
          //   nature_investment_id: savedData?.nature_investment?.nature_investment_id || '',
          //   nature_investment_name: savedData?.nature_investment?.nature_investment_name || '',
          //   price: savedData?.nature_investment?.price || '',
          // }
          nature_investment_option: savedData?.nature_investment?.nature_investment_id,
          nature_investment_name: savedData?.nature_investment?.nature_investment_name,
          nature_investment_price: savedData?.nature_investment?.price,
          // fees_management:savedData?.fees_management ? savedData?.fees_management :
          //  {
          //   management_fee: {
          //     fee: "",
          //     gst_fee: "",
          //     final_fee: "",
          //   },
          //   placement_fee: {
          //     fee: "",
          //     gst_fee: "",
          //     final_fee: "",
          //   },
          //   operating_fee: {
          //     fee: "",
          //     gst_fee: "",
          //     final_fee: "",
          //   },
          // }
          fees_management: savedData?.fees_management ? {
            transaction_fee: {
              fee: savedData?.fees_management?.transaction_fee?.fee || '',
              gst_fee: savedData?.fees_management?.transaction_fee?.gst_fee || '',
              final_fee: savedData?.fees_management?.transaction_fee?.final_fee || '',
            },
            management_fee: {
              fee: savedData?.fees_management?.management_fee?.fee || '',
              gst_fee: savedData?.fees_management?.management_fee?.gst_fee || '',
              final_fee: savedData?.fees_management?.management_fee?.final_fee || '',
            },
            distribution_fee: {
              fee: savedData?.fees_management?.distribution_fee?.fee || '',
              gst_fee: savedData?.fees_management?.distribution_fee?.gst_fee || '',
              final_fee: savedData?.fees_management?.distribution_fee?.final_fee || '',
            },
            operating_fee: {
              fee: savedData?.fees_management?.operating_fee?.fee || '',
              gst_fee: savedData?.fees_management?.operating_fee?.gst_fee || '',
              final_fee: savedData?.fees_management?.operating_fee?.final_fee || '',
            },
            // gst_type:savedData?.fees_management.gst_type,
          } : {
            management_fee: {
              fee: "",
              gst_fee: "",
              final_fee: "",
            },
            distribution_fee: {
              fee: "",
              gst_fee: "",
              final_fee: "",
            },
            operating_fee: {
              fee: "",
              gst_fee: "",
              final_fee: "",
            },
            // gst_type:'exclude'
          },
          startup_fees_management: savedData?.startup_fees_management ? {
            transaction_fee: {
              fee: savedData?.startup_fees_management?.transaction_fee?.fee || '',
              gst_fee: savedData?.startup_fees_management?.transaction_fee?.gst_fee || '',
              final_fee: savedData?.startup_fees_management?.transaction_fee?.final_fee || '',
            }
          } : {
            transaction_fee: {
              fee: "",
              gst_fee: "",
              final_fee: "",
            }
          },
          gst_type: savedData?.fees_management?.gst_type || '',
          warning_message: savedData?.warning_message || '',
          validation: savedData.validation,
          document: savedData.deal_document
            ? savedData.deal_document.map((doc) => ({
              name: doc.name,
              file: { name: doc.file_name, url: doc.url, doc_id: doc.document_id, docxUrl: doc.docxUrl, docxFileName: doc.docxFileName }
            }))
            : [{ name: '', file: null }],
        }
        // console.log(initialvalue,"ppppppppp");

        return initialvalue
      }
      return data.data.data[0]
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const FetchTransactionDetail = (id, mode = "") => {
  return useQuery(
    ['fetch-transaction-detail' + id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/payment/detail/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    },
    {
      select: (data) => {
        // console.log(data,"dam")
        if (mode == 'edit') {
          const savedData = data.data.data[0]
          // console.log(savedData,"savedDataSavedDatapiosrjgoirsnonesrogo")
          const initialvalue = {
            investment_interest_amount: savedData?.interested_amount || '',
            funds_transferred_amount: savedData?.remaining_amount || null,
            investment_interest_date: savedData?.investment_interest_date || null,
            funds_transferred_date: savedData?.funds_transferred_date || null,
            allocated_amount: savedData?.allocated_amount || null,
            investor_name: savedData?.investor_name || "",
            is_trans_added: savedData?.is_trans_added,
            is_unallocated: false,
            is_allocation_email: false,
            is_email_sent: false,
            unallocated_amount: parseFloat(savedData?.unallocated_amount) || 0,
            percentage: savedData?.percentage ? parseFloat(savedData.percentage) : 0.00,
            payments: {
              payment_mode: savedData.payments?.payment_mode || "",
              utr_number: savedData.payments?.utr_number || ""
            },
            investor_id: savedData?.investor_id || "",
            remark: savedData?.remark || "",
            received_amount: savedData?.received_amount || '',
            remaining_amount: savedData?.remaining_amount || '',
            lead_investor_id: String(savedData?.lead_investor_ids) ||[],
            unit_class_id: savedData?.unit_class_id != undefined ? savedData?.unit_class_id : '',
          }
          return initialvalue
        }
        return data.data.data[0]
      },
      refetchOnWindowFocus: false,
      enabled: !!id,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error)
      },
      onError: (err) => {
        return verifyToken(err)
      },
    })
}

export const FetchCompletedDeals = (page, sort = 1, stage, search, limit) => {
  return useQuery(
    ['completed-listing', page, sort, limit],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal?page=${page}&deal_status=completed&stage=${stage}&searchText=${search}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ""}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => ({ deals: data.data.data, count: data.data.count, limit: data.data.limit }),
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchAllDeals = (page, sort = 1, stage, search, limit) => {
  return useQuery(
    ['all-deal-listing', page, sort, limit],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal?page=${page}&stage=${stage}&searchText=${search}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ""}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => ({ deals: data.data.data, count: data.data.count, limit: data.data.limit }),
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchDeals = ({ page, sort = 1, stage, search, limit, mode, deal_flow_status = "", queryString }) => {
  return useQuery(
    ['deal-listing', page, sort, limit, mode, deal_flow_status],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal?page=${page}&deal_status=${mode}&searchText=${search}${limit != " " ? "&limit=" + limit : ""}${queryString}${sort != 0 ? `&sort=${sort}` : ""}${deal_flow_status != "" ? `&deal_flow_status=${deal_flow_status}` : ""}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => ({ deals: data.data.data, count: data.data.count, limit: data.data.limit }),
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchShowcaseQuery = (page, type, sort = 1, stage, search, limit) => {

  let id = ''
  const { data: home } = useQuery(['dealflow-listing-for-id', page, type],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/home`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
  if (home) {
    id = home.data.data.filter((el) => el.value == type)[0]["_id"] || '-'
  }

  return useQuery(
    ['showcase-listing', page, sort, id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/${id}?page=${page}&stage=${stage}&searchText=${search}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ""}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    enabled: !!home,
    select: (data) => ({ deals: data.data.data, count: data.data.count, limit: data.data.limit }),
    refetchOnWindowFocus: false,
    // onSuccess: (data)=>console.log(data),
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const AddIntrestedInvestors = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/save-interest`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const MoveToInvestmentInterest = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/move-to-interest/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const MoveToCallForMoney = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/move-to-call/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const AddAllDealDetail = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/detail/add`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const AddDealQuery = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const AddCallForMoneyInvestors = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const UpdateAllDealDetail = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/detail/edit/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'multipart/form-data'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const SendEmailToKratos = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/kratos/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const UploadTransaction = () => {
  return useMutation((payload) => {
    // console.log(payload,"paytransaction");
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/detail/save-interest`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const DeleteTransaction = () => {
  return useMutation((id) => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: id
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const ConfirmTransaction = () => {
  return useMutation((payload) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const AttachCheckListDeal = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/deal/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const UpdateDealTask = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/deal/update-task-status/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const UpdateDealChecklistTask = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/deal/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    refetchOnWindowFocus: false,
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const UpdateDealStatusUnit = () => {
  return useMutation(({ payload, id }) => {
    // console.log(payload,"payloadN");
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/change-status/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const FetchUnitDealLogs = (id) => {
  return useQuery(['task-deal-data', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/deal_unit_logs/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      // console.log(data.data.data[0],"data.data.data")
      return data.data.data[0]
    },
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchTaskDataDeal = (id) => {
  return useQuery(['task-deal-data', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/deal/detail/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      return data.data.data
    },
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const ExportDealTransaction = (id, excel_report, setCheckValues) => {
  return useQuery(
    ['export-dealtransactions'],
    async () => {
      try {
        if (setCheckValues.length > 0) {
          setCheckValues = setCheckValues.join(',')
        } else {
          setCheckValues = ""

        }
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/payment/${id}?type=${excel_report}&transaction_ids=${setCheckValues}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const GetPaymentMode = () => {
  return useQuery(
    ['get-payment-mode'],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/master/payment_mode`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      // enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);

      },
    }
  );
};

export const UpdateDealFlowStatus = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/change-deal-flow-status/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchDealStatusLogs = (id) => {
  return useQuery(
    ['fetch-dealss-status-logs', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/deal_stage_logs/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => data.data.data[0],
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchDealStatusLogsOptions = (id) => {
  return useQuery(
    ['fetch-dealss-status-option', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/deal_stage_logs/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => data.data.data[0],
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const DeleteDeal = () => {
  return useMutation(({ id, payload }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/deal/cancelled/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload,
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const FetchDealCancelLogs = (id) => {
  return useQuery(
    ['fetch-deals-cancel-logs', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/deal_cancellation_log/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => data.data,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const CreateTrans = () => {
  return useMutation(({ payload, id }) => {
    // console.log(payload,"payloadN");
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/trans/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const CreateMultiTrans = () => {
  return useMutation(({ payload, id }) => {
    // console.log(payload,"payloadN");
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/multi-trans/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}



export const UndoTrans = () => {
  return useMutation(({ payload, id }) => {
    // console.log(payload,"payloadN");
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/undu-trans/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const FetchAllNatureInvester = (mode = "") => {
  return useQuery(
    ['fetch-all-nature-investor'],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/nature_investment`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      if (mode === "options") {
        return data.data.data.map((obj) => ({ label: obj.values[0].name, value: obj._id }))
      }
      return data.data.data
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const GenerateDocuments = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/documentgenerate/deal`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  }, {
    retry: 0,
    onError: (err) => {
      // Handle errors or perform actions on error if needed
      console.error('Error generating documents', err);
      throw err; // Re-throw the error for further handling if necessary
    },
  });
};

export const FetchTranchesLog = (id) => {
  return useQuery(['fetch-tranches', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/deal_unit_logs/${id}?type=Tranch Created`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      // console.log(data.data.data[0],"data.data.data")
      return data.data.data[0]
    },
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const FetchUnitDealLogsNav = (id) => {
  return useQuery(['nav-data', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/deal_unit_logs/${id}?type=updated`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      return data.data.data[0]
    },
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const DeleteDChecklistDocument = (id) => {
  return useMutation(
    (payload) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/detached-documents/${id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
        data: payload
      });
    },
    {
      retry: 0,
      onError: (err) => {
        // Handle errors or perform actions on error if needed
        console.error('Error generating documents', err);
        throw err; // Re-throw the error for further handling if necessary
      }
    }
  );
};
export const GenerateInvestorDocuments = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/documentgenerate/deal-lead-investor`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  }, {
    retry: 0,
    onError: (err) => {
      // Handle errors or perform actions on error if needed
      console.error('Error generating documents', err);
      throw err; // Re-throw the error for further handling if necessary
    },
  });
};

export const GenerateAddendumDocuments = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/documentgenerate/deal-lead-investor-eddendum`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  }, {
    retry: 0,
    onError: (err) => {
      // Handle errors or perform actions on error if needed
      console.error('Error generating documents', err);
      throw err; // Re-throw the error for further handling if necessary
    },
  });
};
export const GenerateEngagementLetterDocument = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/documentgenerate/enagagement-agreement/${payload?.id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json',
      },
      data: {},
    });
  }, {
    retry: 0,
    onError: (err) => {
      // Handle errors or perform actions on error if needed
      console.error('Error generating documents', err);
      throw err; // Re-throw the error for further handling if necessary
    },
  });
};

export const DeleteDocuments = (id) => {
  return useMutation(
    (payload) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/deal/delete-document/${id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
        data: payload
      });
    },
    {
      retry: 0,
      onError: (err) => {
        // Handle errors or perform actions on error if needed
        console.error('Error generating documents', err);
        throw err; // Re-throw the error for further handling if necessary
      }
    }
  );
};

export const SendCallForMoneyMail = () => {
  return useMutation(({ payload, id }) => {
    // console.log(payload,"payloadN");
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/call-money-mail/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    onSuccess: (data) => console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const DealsMonthlyDocument = (id, dealId, from_date, to_date) => {
  return useQuery(['deal_checklist-document-data', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/report?startup_deal_id=${dealId}&checklist_master_id=${id}&checklist_type=monthly&from_date=${from_date}&to_date=${to_date}&api_type=deal`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      return data.data
    },
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    staleTime: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const UpdateDealUnitClassStatus = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/update-unit-class/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const UpdateDealMultipleUnitClassStatus = () => {
  return useMutation(({ payload }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/multi/update-unit-class`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
export const UpdateTransferUnits = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/payment/transfer-unit`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchLeadInvestorsListByDealID = (id) => {
  return useQuery(
    ['approved-investors', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/payment/get-lead-investor/${id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`
        }
      });
    },
    {
      select: (data) => data.data.data,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      }
    }
  );
};


export const FetchTransferUnits = (id , search) => {
  return useQuery(
    ['FetchTransferUnitsFetchTransferUnits', id],
    async () => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/payment/transfer-unit/${id}?page=1&searchText=${search}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`
        }
      });
    },
    {
       select: (data) => {
        return data.data.data.map((obj) => ({ createdAt: obj.createdAt, filename: obj.filename, receive_investor_name: obj.receive_investor_name +" ("+ obj.recived_lp_code +" )" ,_id:obj._id, url:obj.url,send_investor_name:obj.send_investor_name + " (" + obj.send_lp_code +" )",transfer_unit:obj.transfer_unit,startup_deal_id:obj.startup_deal_id}))
      },
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      }
    }
  );
};



export const ExportDealsFromAllDeals = ({ sort = 1, stage, search, limit, mode, deal_flow_status = "", queryString }) => {
  return useQuery(
    ['export-deals', sort, limit, mode, deal_flow_status],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/deal/?sort=-1&type=excel_report&deal_status=${mode}&searchText=${search}&limit=${limit}${queryString}${sort != 0 ? `&sort=${sort}` : ""}${deal_flow_status != "" ? `&deal_flow_status=${deal_flow_status}` : ""}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const ExportDealInvestments = (id) => {
  return useQuery(
    ['export-deals', id],
    async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/deal-investors/${id}?type=excel_report`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        });
        return response.data;
      } catch (error) {
        // Handle error
        console.error(error);
        throw error;
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      staleTime: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};