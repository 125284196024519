import { Box, Paper, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AiFillHome, AiFillCaretRight } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Card from '../ui-component/cards/Card';

const BreadcrumbHeader = ({ title, shouldGoBack = false, breadcrumbTitle = '' }) => {
    const routes = [
        { path: '/', breadcrumb: '' },
        // { path: '', breadcrumb: null },
        {
            path: 'startups',
            breadcrumb: 'Startups',
            children: [
                { path: 'addStartup', breadcrumb: 'Add Startup' },
                { path: 'editStartup', breadcrumb: null },
                { path: 'editStartup/:id', breadcrumb: 'Startup Form' },
                { path: ':id', breadcrumb: null },
                { path: ':id/addDeal', breadcrumb: 'Add Deal' },
                { path: 'view-startup/:id', breadcrumb: 'View Startup' },
                { path: 'startup-settings/:id', breadcrumb: 'Setting' },
                { path: 'startup-settings/:id', breadcrumb: breadcrumbTitle },
                { path: 'startup-misreport/:id', breadcrumb: 'View MIS Report' },
                { path: 'startup-misreport/:id', breadcrumb: breadcrumbTitle },
                { path: 'addMISReport/:id', breadcrumb: title },
                { path: 'startup-template-checklist/:id', breadcrumb: 'Tasks' },
            ]
        },
        {
            path: 'reports',
            breadcrumb: 'Master Reports',
            children: [
                { path: 'master-excel-report-edit/:id', breadcrumb: 'Edit Master Report' },
                { path: ':id', breadcrumb: null },
                { path: 'master-excel-report-create', breadcrumb: 'Create Master Report' }
            ]
        },
        {
            path: 'applicationListing',
            breadcrumb: 'Applications',
            children: [
                { path: 'startUpView/:id', breadcrumb: 'Application View' },
                { path: 'feedbacksView/:id', breadcrumb: "Application View" },
                { path: 'startupSettings/:id', breadcrumb: "Application View"},
                { path: ':id', breadcrumb: null },
                { path: 'applicationEditView/:id', breadcrumb: 'Edit Application' },
                { path: ':id', breadcrumb: null },
                { path: 'addApplication', breadcrumb: 'Add Application' }
            ]
        },
        // {
        //   path: 'master',
        //   breadcrumb: 'Master',
        //   children: [
        //     { path: "checklist-edit", breadcrumb: 'Edit Checklist',},
        //   ],
        // },
        {
            path: 'checklist',
            breadcrumb: 'checklists',
            children: [
                {
                    path: 'checklist-create',
                    breadcrumb: 'Create Checklist'
                },
                {
                    path: 'checklist-edit/:id',
                    breadcrumb: 'Edit Checklist'
                },
                {
                    path: ':id',
                    breadcrumb: null
                }
            ]
        },
        {
            path: 'investors',
            breadcrumb: 'Investors',
            children: [
                { path: 'soa_uploads', breadcrumb: 'SOA Uploads' },
                { path: 'soa_uploads/:id', breadcrumb: 'SOA Zip Details' },
                { path: 'add_investor', breadcrumb: 'Add Investor' },
                { path: 'editInvestor', breadcrumb: null },
                { path: 'editInvestor/:id', breadcrumb: 'Edit Investor' },
                { path: ':id', breadcrumb: null },
                { path: 'viewInvestor', breadcrumb: null },
                { path: 'viewInvestor/:id', breadcrumb: 'View Investor' },
                { path: 'stamp_uploads', breadcrumb: 'Stamp Uploads' }
            ]
        },
        {
            path: 'bank-receipts',
            breadcrumb: 'Bank Receipts',
            children: [{ path: ':id', breadcrumb: 'Bank Receipt Detail' }]
        },
        {
            path: 'lead-investors',
            breadcrumb: 'Lead Investors',
            children: [{ path: ':id', breadcrumb: 'Edit Form' }]
        },
        {
            path: '/master',
            breadcrumb: 'Master',
            children: [
                { path: 'vp-fees', breadcrumb: 'VP Fees' },
                { path: 'addBankAccount', breadcrumb: 'Add Bank Account' },
                { path: 'editBankAccount/:id', breadcrumb: 'Edit Bank Account' },
                { path: 'addBillingInfo', breadcrumb: 'Add Billing Information' },
                { path: 'editBillingInfo/:id', breadcrumb: 'Edit Billing Information' },
                { path: ':id', breadcrumb: null }
            ]
        },
        {
            path: '/deals',
            breadcrumb: 'Deals',
            children: [
                { path: 'intrestedInvestors', breadcrumb: null },
                { path: 'addDeal', breadcrumb: 'Add Deal' },
                { path: ':id/addDeal', breadcrumb: 'Edit Deal' },
                { path: 'view-deal/:id', breadcrumb: 'View Deal' },
                { path: 'transactions/:id', breadcrumb: 'Transaction' },
                { path: 'investments/:id', breadcrumb: 'Investments' },
                { path: 'setting/:id', breadcrumb: 'Setting' },
                { path: 'dealdocument/:id', breadcrumb: 'Documents' },
                { path: 'intrestedInvestors/:id', breadcrumb: 'Interested Investors' },
                { path: 'confirmationList', breadcrumb: null },
                { path: 'confirmationList/:id', breadcrumb: 'Confirmation List' },
                { path: 'transactions/:id', breadcrumb: breadcrumbTitle },
                { path: 'setting/:id', breadcrumb: breadcrumbTitle },
                { path: 'attach-checklist/:id', breadcrumb: 'Attach Checklist' },
                { path: ':id', breadcrumb: null }
            ]
        },
        {
            path: 'feedbacks',
            children: [
                {
                    path: '',
                    breadcrumb: "Feedbacks"
                },
                {
                    path: ':id',
                    breadcrumb: "Submit Feedback"
                }
            ]
        },
        {
            path: 'resources/feedbacks',
            breadcrumb: 'Feedbacks',
            children: [
                { path: ':investorId', breadcrumb: null },
                { path: ':investorId/:id', breadcrumb: 'Reply' }
            ]
        },
        {
            path: 'feedback_master',
            breadcrumb: 'Feedbacks',
            children: [
                { path: 'feedback-create', breadcrumb: "Create Feedback Form" },
                { path: ':id', breadcrumb: 'Edit Feedback Form' }
            ]
        },
        {
            path: 'resources/articles',
            breadcrumb: 'Articles',
            children: [
                { path: 'articleForm', breadcrumb: 'Add Article' },
                { path: ':id', breadcrumb: null },
                { path: ':id/articleForm', breadcrumb: 'Edit Article' }
            ]
        },
        {
            path: 'syndicates',
            breadcrumb: 'Syndicates',
            children: [
                { path: 'addSyndicate', breadcrumb: 'Add Syndicate' },
                { path: 'editSyndicate', breadcrumb: null },
                { path: 'editSyndicate/:id', breadcrumb: 'Edit Syndicate' },
                // { path: ":id/articleForm", breadcrumb: "Edit Article" },
                { path: ':id', breadcrumb: null },
                { path: 'view-syndicate/:id', breadcrumb: 'View Syndicate' }
            ]
        },



        {
            path: 'users',
            breadcrumb: 'Admin users',
            children: [
                { path: 'addUser', breadcrumb: 'Add User' },
                { path: 'editUser', breadcrumb: null },
                { path: 'editUser/:id', breadcrumb: 'Edit User' },
                { path: 'editRole', breadcrumb: null },
                { path: 'editRole/:id', breadcrumb: 'Edit Role' }

                // { path: ":id/articleForm", breadcrumb: "Edit Article" },
            ]
        },
        {
            path: 'investor-inactive-active-data',
            breadcrumb: 'Active/Inactive Investors'
            // children: [
            //     { path: 'addUser', breadcrumb: 'Add User' },
            //     { path: 'editUser', breadcrumb: null },
            //     { path: 'editUser/:id', breadcrumb: 'Edit User' },
            //     { path: 'editRole', breadcrumb: null },
            //     { path: 'editRole/:id', breadcrumb: 'Edit Role' }

            //     // { path: ":id/articleForm", breadcrumb: "Edit Article" },
            // ]
        },
        {
            path: 'target-amount-fees',
            breadcrumb: 'Partner Target Fees'
        },
        {
            path: 'lead_investors',
            breadcrumb: 'Lead Investors'
        },
        {
            path: 'deal-document-checklist',
            breadcrumb: 'Deal Document Checklist'
        },
        {
            path: 'startup-document-checklist',
            breadcrumb: 'Startup Document Checklist'
        },
        {
            path: 'qar-reports-data',
            breadcrumb: 'QAR'
        },
        {
            path: 'fees-reports-data',
            breadcrumb: 'Partner Payout'
        }
    ];
    const theme = useTheme();
    const navigate = useNavigate();
    const breadcrumbs = useBreadcrumbs(routes, {
        excludePaths: ['/deals/confirmationList']
    });
    return (
        <>
            {/* {shouldGoBack && <IconButton sx={{backgroundColor: '#fff', boxShadow: 3}} onClick={()=>console.log(navigate(-1))}>
        <KeyboardBackspaceIcon />
      </IconButton>} */}
            <Card style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1
                    }}
                    className="breadcrumb-flex-wrap"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                        {shouldGoBack && (
                            <IconButton
                                sx={{ backgroundColor: '#fff', border: '1px solid #222' }}
                                onClick={() => console.log(navigate(-1))}
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        )}
                        <span
                            style={{
                                margin: 0,
                                fontFamily: 'Inter, sans-serif',
                                display: 'block',
                                fontSize: '1.17em',
                                marginBlockStart: '1em',
                                marginBlockEnd: '1em',
                                marginInlineStart: '0px',
                                marginInlineEnd: '0px',
                                fontWeight: 'bold',
                                marginBottom: 0,
                                marginTop: 0
                            }}
                        >
                            {title}
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }} className="breadcrumb-box">
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <AiFillHome
                                style={{ position: 'relative', top: '2px', fontSize: '16px' }}
                                color={theme.palette.secondary[800]}
                            />
                        </Link>
                        {breadcrumbs.map(({ match, breadcrumb, key, location }) => (
                            <Box key={key}>
                                {location.pathname !== key ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Link
                                            to={match.pathname}
                                            style={{ textDecoration: 'none', fontSize: '14px', color: theme.palette.secondary.main }}
                                        >
                                            {breadcrumb}
                                        </Link>
                                        <AiFillCaretRight
                                            style={{ marginLeft: '6px', marginRight: '6px' }}
                                            color={theme.palette.secondary[800]}
                                        />
                                    </Box>
                                ) : (
                                    <p
                                        style={{
                                            textDecoration: 'none',
                                            fontSize: '14px',
                                            margin: 0,
                                            color: theme.palette.secondary[800],
                                            fontWeight: 700
                                        }}
                                    >
                                        {breadcrumb}
                                    </p>
                                )}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Card>
        </>
    );
};

export default BreadcrumbHeader;
