import Clickable from "utils/Clickable"
function Col({label, value, col=3, customClass, onClick}) {
  const className = {
    1: "col-md-12 col-6 mt-2 mb-2",
    2: "col-md-6 col-6 mt-2 mb-2",
    3: "col-md-4 col-6 mt-2 mb-2",
    4: "col-md-3 col-6 mt-2 mb-2",
    6: "col-md-2 col-6 mt-2 mb-2",
  }
  return (
    <>
      {onClick ? <Clickable onClick={onClick} className={customClass || className[col]}>
        <div>
          <div className='v-label'>{label}</div>
          <div className='v-value'>{value}</div>
        </div>
      </Clickable>:<div className={customClass || className[col]} >
          <div className='v-label'>{label}</div>
          <div className='v-value'>{value}</div>
        </div>}
    </>
  )
}

export default Col