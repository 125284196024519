import { useState,useRef,lazy } from 'react'
import {Chip} from '@mui/material'
import React from 'react'
import {  IconCheckbox, IconCircleCheck, IconEdit, IconRocket } from '@tabler/icons';
import UpdateTemplateChecklist from './UpdateTemplateChecklist';
import Modal from 'ui-component/Modal';
import pdfIcon from '../../assets/images/pdf.svg'
import { CheckCircle } from '@mui/icons-material';
import Loadable from 'ui-component/Loadable';
import { DescriptionOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import ChecklistDocumentViewPopup from './ChecklistDocumentViewPopup';
import MonthlyDealTemplate from './MonthlyDealTemplate';


export default function DealTemplate({ tamplate_data, refetchListing,dealsModule,dealId }) {
    const sections = document.querySelectorAll("section[id]");
    window.addEventListener("scroll", navHighlighter);
    function navHighlighter() {
        let scrollY = window.pageYOffset;

        sections.forEach(current => {
            const sectionHeight = current.offsetHeight;
            const sectionTop = current.offsetTop - 50;
            let sectionId = current.getAttribute("id");

            if (
                scrollY > sectionTop &&
                scrollY <= sectionTop + sectionHeight
            ) {
                //document.querySelector(".navigation a[href*=" + sectionId + "]").classList.add("active")
                document.getElementById(sectionId).classList.add("active")

            } else {
                //document.querySelector(".navigation a[href*=" + sectionId + "]").classList.remove("active")
                document.getElementById(sectionId).classList.remove("active")
            }
        });
    }
    const DealDetailsRef = useRef(null)
    const DealA = useRef(null)

    const scrollToDiv = (yOffset, myRef) => {
        // const yOffset = -160; // Adjust this value according to your header's height
        const y = myRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };
    const [open, setOpen] = useState(false);
    const [taskId, setTaskId] = useState('')
    const [taskDocument, setTaskDocument] = useState('')
    const [open2, setOpen2] = useState(false);
    const handleOpen = (id) => {
        setTaskId(id)
        setOpen(true)
    }
    const handleOpen2 = (document) => {
        setTaskDocument(document)
        setOpen2(true)
    }
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    return (
        (dealsModule != undefined  && dealsModule?.deal_checklist == true) ?
        <>
            <Modal open={open} onClose={handleClose} closeIcon={true} style={{ width: '900px' }} disableScrollLock={true}>
                <UpdateTemplateChecklist taskId={taskId} setOpen={setOpen} refetchListing={refetchListing} />
            </Modal>
            <Modal open={open2} onClose={handleClose2} closeIcon={true} style={{ width: '900px' }} disableScrollLock={true}>
                <ChecklistDocumentViewPopup taskDocument={taskDocument} setOpen2={setOpen2}/>
            </Modal>
            {tamplate_data.checklist_period == 'monthly' ? (
                 <MonthlyDealTemplate id={tamplate_data} dealId={dealId} refetchListing={refetchListing}/>
            ): (
            //    <></>

            <div className='custom-card card'>
                <div className='card-body'>
                    <div className='custom-table table-responsive mt-3'>
                        <table className='w-100 table add-border'>
                            <thead>
                                <tr >
                                    <th>&nbsp;</th>
                                    <th className='text-start text-nowrap' >Task Name</th>
                                    <th className='text-start text-nowrap'>Task Description</th>
                                    <th className='text-center text-nowrap'>Required</th>
                                    <th className='text-center text-nowrap'>ETA Date</th>
                                    {/* <th className='text-center text-nowrap'>Document Date</th> */}
                                    <th className='text-center text-nowrap'>Completed Date</th>
                                    <th className='text-center text-nowrap'>Updated</th>
                                    <th className='text-center text-nowrap'>Updated By</th>
                                    <th className='text-center text-nowrap'>Status</th>
                                    <th className='text-center text-nowrap'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tamplate_data.tasks && tamplate_data.tasks.length > 0 ? (
                                    tamplate_data.tasks.map((task, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    {task.task_status == "Completed" ? <div style={{color: '#60cb5e'}}><CheckCircle/></div> : ""}
                                                    {task.task_status == "not_required" ? <div style={{color: '#60cb5e'}}><CheckCircle/></div> : ""}
                                                    {task.task_status == "pending" ? <div style={{color: '#616161'}}><CheckCircle/></div> : ""}
                                                    {task.task_status == "inProgress" ? <div style={{color: '#d4a72d'}}><CheckCircle/></div> : ""}
                                                    {task.task_status == "in_review" ? <div style={{color: '#CC5500'}}><CheckCircle/></div> : ""}
                                                    {task.task_status == "ready_for_approve" ? <div style={{color: '#A0522D'}}><CheckCircle/></div> : ""}
                                                    {(!task.task_status) || task.task_status == "undefined" ? <div style={{color: '#616161'}}><CheckCircle/></div> : ""}
                                                </td>
                                                <td className='text-start'>{task.task_name}</td>
                                                <td className='text-start'>{task.task_description}</td>
                                                <td className='text-center' >{task.required ? "Yes" : "No"}</td>
                                                <td className='text-center'style={{whiteSpace:"nowrap"}} >{task.eta_date_format}</td>
                                                {/* <td className='text-center'style={{whiteSpace:"nowrap"}} >{task.document_date}</td> */}
                                                <td className='text-center' >{task.task_status == "Completed" ? task.completed_date_format : ""}</td>
                                                <td className='text-center'style={{whiteSpace:"nowrap"}} >{task.updatedAt != "" ? task.updatedAt : ""}</td>
                                                <td className='text-center' style={{whiteSpace:"nowrap"}}>{task.last_updated_by != "" ? task.last_updated_by : ""}</td>
                                                <td className='text-center'>
                                                {task.task_status == "not_required" ? <Chip
                                                    size="small"
                                                    label="Not Required"
                                                    sx={{
                                                        backgroundColor: '#ebfaeb',
                                                        color: '#60cb5e',
                                                        border: 'none',
                                                        minWidth: '100px',
                                                        fontSize: '12px',
                                                        borderRadius: '4px',
                                                        fontWeight: 700,
                                                        // borderWidth: '0.5px',
                                                        // borderStyle: 'solid',
                                                        // borderColor: '#60cb5e'
                                                    }}
                                                /> : ""}
                                                     {task.task_status == "Completed" ? <Chip
                                                    size="small"
                                                    label="Completed"
                                                    sx={{
                                                        backgroundColor: '#ebfaeb',
                                                        color: '#60cb5e',
                                                        border: 'none',
                                                        minWidth: '100px',
                                                        fontSize: '12px',
                                                        borderRadius: '4px',
                                                        fontWeight: 700,
                                                        // borderWidth: '0.5px',
                                                        // borderStyle: 'solid',
                                                        // borderColor: '#60cb5e'
                                                    }}
                                                /> : ""}
                                                    {task.task_status == "pending" ? <Chip
                                                        size="small"
                                                        label="Pending"
                                                        sx={{
                                                            backgroundColor: '#efefef',
                                                            color: '#616161',
                                                            border: 'none',
                                                            minWidth: '100px',
                                                            fontSize: '12px',
                                                            borderRadius: '4px',
                                                            fontWeight: 700,
                                                            // borderWidth: '0.5px',
                                                            // borderStyle: 'solid',
                                                            // borderColor: '#d4a72d'
                                                        }}
                                                    /> : ""}
                                                    {task.task_status == "in_review" ? <Chip
                                                        size="small"
                                                        label="In Review"
                                                        sx={{
                                                            backgroundColor: '#efefef',
                                                            color: '#CC5500',
                                                            border: 'none',
                                                            minWidth: '100px',
                                                            fontSize: '12px',
                                                            borderRadius: '4px',
                                                            fontWeight: 700,
                                                            // borderWidth: '0.5px',
                                                            // borderStyle: 'solid',
                                                            // borderColor: '#d4a72d'
                                                        }}
                                                    /> : ""}
                                                    {task.task_status == "ready_for_approve" ? <Chip
                                                        size="small"
                                                        label="Ready For Approve"
                                                        sx={{
                                                            backgroundColor: '#efefef',
                                                            color: '#A0522D',
                                                            border: 'none',
                                                            minWidth: '100px',
                                                            fontSize: '12px',
                                                            borderRadius: '4px',
                                                            fontWeight: 700,
                                                            // borderWidth: '0.5px',
                                                            // borderStyle: 'solid',
                                                            // borderColor: '#d4a72d'
                                                        }}
                                                    /> : ""}

                                                    {task.task_status == "inProgress" ? <Chip
                                                        size="small"
                                                        label="In Progress"
                                                        sx={{
                                                            backgroundColor: '#fef8e8',
                                                            color: '#d4a72d',
                                                            border: 'none',
                                                            minWidth: '100px',
                                                            fontSize: '12px',
                                                            borderRadius: '4px',
                                                            fontWeight: 700,
                                                            // borderWidth: '0.5px',
                                                            // borderStyle: 'solid',
                                                            // borderColor: '#39739d'
                                                        }}
                                                    /> : ""}

                                                    {(!task.task_status) || task.task_status == "undefined" ? <Chip
                                                        size="small"
                                                        label="Pending"
                                                        sx={{
                                                            backgroundColor: '#efefef',
                                                            color: '#616161',
                                                            border: 'none',
                                                            minWidth: '100px',
                                                            fontSize: '12px',
                                                            borderRadius: '4px',
                                                            fontWeight: 700,
                                                            // borderWidth: '0.5px',
                                                            // borderStyle: 'solid',
                                                            // borderColor: '#d4a72d'
                                                        }}
                                                    /> : ""}
                                                </td>
                                                <td  className='text-center'>
                                                    <div style={{display:"flex"}}>
                                                    <button type='button' className='icon-btn'
                                                        onClick={() => handleOpen(task.task_id)}><IconEdit />
                                                    </button>
                                                    {task.document && task.document.length > 0 && 
                                                     <Tooltip title="Documents">
                                                     <button type="button" className="icon-btn" onClick={() => handleOpen2(task.document)}>
                                                         <DescriptionOutlined />
                                                     </button>
                                                 </Tooltip>}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                ) : (
                                    <>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
             </div>
            )
           }
        </>
        : <Error404 />
    )
}