// assets
import { IconArrowAutofitContent, IconBuildingSkyscraper } from '@tabler/icons';
import contentMaster from '../../../../assets/svg/contentMaster'
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const content = {
    id: 'content1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'content11',
            title: 'Content',
            type: 'collapse',
            // url: `/contents`,
            icon: contentMaster,
            breadcrumbs: false,

            children: [
                // {
                //     id: 'content111',
                //     title: 'Selected',
                //     type: 'item',
                //     url: `/contents/selected`,
                //     icon: IconBuildingSkyscraper,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'content112',
                //     title: 'Applications',
                //     type: 'item',
                //     url: `/applicationListing`,
                //     icon: IconBuildingSkyscraper,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default content;
