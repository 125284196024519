import { useTheme } from '@emotion/react';
import { Cancel, Check, Close, Delete, Edit, Refresh, Save } from '@mui/icons-material';
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Tooltip,
    Box,
    TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { useFormik, Form, FieldArray, FormikProvider, Field, FastField } from 'formik';
import React from 'react';
import { useState } from 'react';
import CustomButton from 'ui-component/CustomButton';
import { StyledTableCell, StyledTableRow } from 'ui-component/TableStyles';
import CustomField from 'ui-component/formik/CustomField';
import Card from 'ui-component/cards/Card';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { AddMasterReportAPI, EditMasterReportAPI, FetchSelectedMasterReport } from 'api';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'views/Loading';

const CreateReportMaster = () => {
    const useStyles = makeStyles({
        customTable: {
            '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
            '& .MuiTableCell-head': {
                color: 'white',
                backgroundColor: '#0000FF'
            }
        }
    });
    const classes = useStyles();
    const { id: selectedID } = useParams();
    // console.log(selectedID,"selectedID")
    let isEditMode = !!selectedID;
    // const navigate = useNavigate()
    const [validateType, setValidateType] = useState(false);
    const [dataColumnValues, setDataColumnValues] = useState({
        cell_id: '',
        cell_name: '',
        column_name: '',
        column_type: '',
        formula: '',
        is_editmode: false
    });
    const [columns, setColumns] = useState([]);

    const initialValues = {
        report_name: '',
        report_description: '',
        report_type: '',
        data_columns: []
    };

    const theme = useTheme();
    const navigate = useNavigate();
    // const [numberOfrows, setNumberOfRows] = useState(0);

    const {
        isLoading: isSelectedReportLoading,
        data: selectedReportData,
        isRefetching: isSelectedReportRefetching,
        status: selectedReportStatus,
        refetch: refetchListing
    } = FetchSelectedMasterReport(selectedID);
    const { mutateAsync: uploadMasterReport, status: MasterReportStatus, error: MasterReportError } = AddMasterReportAPI();
    const { mutateAsync: updatedMasterReport, status: updatedMasterReportStatus, error: updatedMasterReportError } = EditMasterReportAPI();

    React.useEffect(() => {
        if (MasterReportStatus == 'success' || updatedMasterReportStatus == 'success') {
            toast.success( isEditMode ? "Report Update": "Report Created" ,{
                    position: 'top-left',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                      onClose: () => {
                      navigate('/reports')
                }
                });
        } else if (MasterReportStatus == 'error' || updatedMasterReportStatus == 'error') {
            toast.error(updatedMasterReportError.response.data.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [MasterReportStatus, updatedMasterReportStatus, updatedMasterReportError, MasterReportError]);

    const formik = useFormik({
        initialValues: selectedID !== undefined && !isSelectedReportLoading ? selectedReportData?.data : initialValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payload = {
                report_name: values.report_name,
                report_description: values.report_description,
                report_type: values.report_type,
                data_columns: values.data_columns
            };
            {
                isEditMode
                    ? updatedMasterReport(
                          { payload: payload, id: selectedID },
                         
                      )
                    : uploadMasterReport(
                          { payload },
                         
                      );
            }
        }
    });

    // console.log(selectedReportData,"selectedReportDataselectedReportDataselectedReportDataselectedReportData",selectedID)

    const handleUpdateColumns = () => {
        // rows.push({})
        dataColumnValues['cell_name'] = `A${columns.length + 1}`;
        dataColumnValues['cell_id'] = `${Math.random() * 100}`;
        columns.push(dataColumnValues);
        // console.log(dataColumnValues, 'dataColumnValues');
        formik.setFieldValue('data_columns', columns);
        setDataColumnValues({
            cell_name: '',
            cell_id: '',
            column_name: '',
            column_type: '',
            formula: '',
            is_editmode: false
        });
    };
    // console.log(Math.random()*10)

    const handleDelete = (cellID) => {
        // formik.values.data_columns.splice(index);
        // console.log(columns,"spliced columns")
       let  modified_data_column = formik.values.data_columns.filter((item) => item.cell_id !== cellID.cell_id)

        formik.setFieldValue(
            'data_columns',
            modified_data_column
        );
        modified_data_column.forEach((element,index) => {
            if(element.formula != ""){
                const terms = element.formula.split(/(\+|\-|\*|\/|\%)/).map(term => term.trim());
                const indexOfVariableToRemove = terms.indexOf(cellID.cell_name);
                if (indexOfVariableToRemove !== -1 ) {
                      formik.setFieldValue(
                         `data_columns.${index}.formula`, ""
                     );
                }
               
            }
            formik.setFieldValue(
                `data_columns.${index}.cell_name`,`A${index + 1}`
            );
        });
    };

    // console.log(formik.values.data_columns, 'data_columns');

    const renderColumns = () => {
        return formik.values.data_columns.map((item, index) => {
            return (
                <StyledTableRow key={index}>
                    {item.is_editmode ? (
                        <>
                            <StyledTableCell sx={{maxWidth:40}}>
                                <TextField
                                    variant="standard"
                                    name={`data_columns.${index}.cell_name`}
                                    value={item.cell_name}
                                    fullWidth
                                    // sx={{maxWidth: 100}}
                                    onChange={(e) => formik.setFieldValue(`data_columns.${index}.cell_name`, e.target.value)}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name={`data_columns.${index}.column_name`}
                                    value={item.column_name}
                                    onChange={(e) => formik.setFieldValue(`data_columns.${index}.column_name`, e.target.value)}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <FormControl fullWidth>
                                    {/* <InputLabel id="demo-simple-select-label">Type</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // label="Type"
                                        name={`data_columns.${index}.column_type`}
                                        variant="standard"
                                        value={formik.values.data_columns[index].column_type}
                                        sx={{ ':focus': { backgroundColor: 'transparent !important' } }}
                                        onChange={(e) => formik.setFieldValue(`data_columns.${index}.column_type`, e.target.value)}
                                    >
                                        <MenuItem value={'Number'}>Number</MenuItem>
                                        <MenuItem value={'Percentage'}>Percentage</MenuItem>
                                        {/* <MenuItem value={'Comment'}>Comment</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name={`data_columns.${index}.formula`}
                                    value={item.formula}
                                    onChange={(e) => formik.setFieldValue(`data_columns.${index}.formula`, e.target.value)}
                                />
                            </StyledTableCell>
                        </>
                    ) : (
                        <>
                            <StyledTableCell>{item.cell_name}</StyledTableCell>
                            <StyledTableCell>{item.column_name}</StyledTableCell>
                            <StyledTableCell>{item.column_type}</StyledTableCell>
                            <StyledTableCell>{item.formula}</StyledTableCell>
                        </>
                    )}
                    <StyledTableCell>
                        {item.is_editmode ? (
                            <Grid item display={'flex'} alignItems={'center'} gap={'1rem'}>
                                <Tooltip title="Save">
                                    <Check
                                        onClick={() => {
                                            formik.setFieldValue(`data_columns.${index}.is_editmode`, false);
                                            // formik.submitForm();
                                        }}
                                        sx={{ color: '#777', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <Close
                                        onClick={() => {
                                            formik.setFieldValue(`data_columns.${index}.is_editmode`, false);
                                        }}
                                        sx={{ color: '#777', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Grid>
                        ) : (
                            <Grid item display={'flex'} alignItems={'center'} gap={'1rem'}>
                                <Tooltip title="Edit">
                                    <Edit
                                        onClick={() => {
                                            formik.setFieldValue(`data_columns.${index}.is_editmode`, true);
                                        }}
                                        sx={{ color: '#777', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Delete
                                        onClick={() => {
                                            handleDelete(item);
                                            // formik.submitForm();
                                        }}
                                        sx={{ color: '#777', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Grid>
                        )}
                    </StyledTableCell>
                </StyledTableRow>
            );
        });
    };

    return (
        <div>
            <ToastContainer />
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={isEditMode ? 'Edit Master Report' : 'Create Master Report'} shouldGoBack={true} />
            </Box>
            <Card>
                {isSelectedReportLoading && isEditMode ? (
                    <Loading />
                ) : (
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack item container xs={12} rowGap={2}>
                                <Grid alignItems={'center'} xs={12} gap={2} item container>
                                    <Grid item xs={12} md={5.9}>
                                        <CustomField
                                            label={'Report Name'}
                                            name={'report_name'}
                                            value={formik.values.report_name}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" required>
                                                Report Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Report Type"
                                                value={formik.values.report_type}
                                                // displayEmpty={true}
                                                required
                                                onChange={(e) => {
                                                    formik.setFieldValue('report_type', e.target.value);
                                                }}
                                            >
                                                <MenuItem value={'deal'}>Deals</MenuItem>
                                                <MenuItem value={'startup'}>Startups</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <CustomField
                                            multiline
                                            label={'Report Description'}
                                            name={'report_description'}
                                            value={formik.values.report_description}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={2}>
                                <CustomButton onClick={() => setNumberOfRows((prev) => prev + 1)}>Add rows</CustomButton>
                            </Grid> */}
                                </Grid>

                                <Grid xs={12} item container>
                                    <TableContainer sx={{ borderRadius: 2, overflow: 'hidden' }}>
                                        <Table
                                            sx={{ minWidth: 700 }}
                                            className={`${classes.customTable} add-border deal-table`}
                                            aria-label="customized table"
                                            stickyHeader
                                        >
                                            <TableHead sx={{ position: 'sticky', top: '-1px', zIndex: 1 }}>
                                                <StyledTableRow>
                                                    <StyledTableCell sx={{maxWidth: 50}}>Cell</StyledTableCell>
                                                    <StyledTableCell>Parameter</StyledTableCell>
                                                    <StyledTableCell>Type</StyledTableCell>
                                                    <StyledTableCell>Formula</StyledTableCell>
                                                    <StyledTableCell>Actions</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {renderColumns()}
                                                <StyledTableRow>
                                                    <StyledTableCell>A{formik.values.data_columns.length + 1}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextField
                                                            className="row_input"
                                                            fullWidth
                                                            // label={'Parameter'}
                                                            name="row_name"
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                setDataColumnValues({ ...dataColumnValues, column_name: e.target.value });
                                                                setValidateType(false);
                                                            }}
                                                            value={dataColumnValues.column_name}
                                                        />
                                                        {/* {validateType && dataColumnValues.column_name === '' && (
                                                        <span style={{ color: 'red' }}>Please Fill Out this field</span>
                                                    )} */}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <FormControl fullWidth>
                                                            {/* <InputLabel id="demo-simple-select-label">Type</InputLabel> */}
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Type"
                                                                value={dataColumnValues.column_type}
                                                                // displayEmpty={true}
                                                                variant="standard"
                                                                onChange={(e) => {
                                                                    setDataColumnValues({
                                                                        ...dataColumnValues,
                                                                        column_type: e.target.value
                                                                    });
                                                                    setValidateType(false);
                                                                }}
                                                            >
                                                                <MenuItem value={'Number'}>Number</MenuItem>
                                                                <MenuItem value={'Percentage'}>Percentage</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* {validateType && dataColumnValues.column_type === '' && (
                                                        <span style={{ color: 'red' }}>Please Fill Out this field</span>
                                                    )} */}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextField
                                                            fullWidth
                                                            name="formula"
                                                            // label={'Formula'}
                                                            value={dataColumnValues.formula}
                                                            variant="standard"
                                                            onChange={(e) =>
                                                                setDataColumnValues({ ...dataColumnValues, formula: e.target.value })
                                                            }
                                                        />
                                                        {/* {validateType && <span>&nbsp;</span>} */}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid item display={'flex'} alignItems={'center'} gap={'1rem'}>
                                                            <Tooltip title="Save">
                                                                <Check
                                                                    onClick={() => {
                                                                        dataColumnValues.column_name !== '' &&
                                                                        dataColumnValues.column_type !== ''
                                                                            ? handleUpdateColumns()
                                                                            : setValidateType(true);
                                                                            // formik.submitForm()
                                                                    }}
                                                                    sx={{ color: '#777', cursor: 'pointer' }}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Reset">
                                                                <Refresh
                                                                    onClick={() =>
                                                                        setDataColumnValues({
                                                                            cell_name: '',
                                                                            cell_id: '',
                                                                            row_name: '',
                                                                            row_type: '',
                                                                            formula: '',
                                                                            is_editmode: false
                                                                        })
                                                                    }
                                                                    sx={{ color: '#777', cursor: 'pointer' }}
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                        {/* {validateType && <span>&nbsp;</span>} */}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item display={'flex'} flexDirection={'row-reverse'} container sx={12}>
                                    <Grid item>
                                        <CustomButton type="submit">Save</CustomButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Form>
                    </FormikProvider>
                )}
            </Card>
        </div>
    );
};

export default CreateReportMaster;
