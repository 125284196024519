import { useState, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Paper, Box, Stack, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FetchInvestorsFilters } from 'api';
import ListingFilters from 'views/investor/ListingFilters';
import Clickable from 'utils/Clickable';
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { IconEdit } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from 'ui-component/Modal';
import PartnerPopup from 'views/investor/kyc/PartnerPopup'
import Loading from 'views/Loading';
import filterIcon from '../../assets/images/icons/filter.png'


const MissingNominee = [
  { value: '', label: 'Select Option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const MissingAddress = [
  { value: '', label: 'Select Option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];
const MissingBank = [
  { value: '', label: 'Select Option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]
const Transaction = [
  { value: '', label: 'Select Option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]
const LoggedIn = [
  { value: '', label: 'Select Option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]
const CkycAdded = [
  { value: '', label: 'Select Option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

const mode = ''
const MissingPopup = ({ handleClose, setQueryString, setreset, setPage, refetchListing, investorData, formikValues, setFormaikValues }) => {
  const theme = useTheme();
  // const { isLoading: isInvestorLoading, data: investorData, refetch: refetchListing, status: investorStatus } = FetchInvestorsFilters(page, search, mode, sort, limit, queryString)
  const initialValues = {
    is_nominee_added: '',
    is_address_added: '',
    is_bank_added: '',
    is_transaction_added: '',
    is_loggedin: '',
    is_demat_added: '',
    is_ckyc_added: '',
    is_demat_document_added: '',
    is_ledger_added: '',
    is_ppm_added: '',
    is_contribution_added: ''
  }

  const formik = useFormik({
    initialValues: formikValues != "" ? formikValues : initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      let params = {}
      setFormaikValues(values)

      //   console.log(values,"valuesvaluesvaluesvaluesvaluesvalues")
      if (values.is_nominee_added && values.is_nominee_added != "") {
        params["is_nominee_added"] = values.is_nominee_added
      }
      if (values.is_address_added) {
        params["is_address_added"] = values.is_address_added
      }
      if (values.is_bank_added) {
        params["is_bank_added"] = values.is_bank_added
      }
      if (values.is_transaction_added) {
        params["is_transaction_added"] = values.is_transaction_added
      }
      if (values.is_loggedin) {
        params["is_loggedin"] = values.is_loggedin
      }
      if (values.is_demat_added) {
        params["is_demat_added"] = values.is_demat_added
      }
      if (values.is_ckyc_added) {
        params["is_ckyc_added"] = values.is_ckyc_added
      }
      if (values.is_demat_document_added) {
        params["is_demat_document_added"] = values.is_demat_document_added
      }
      if (values.is_ledger_added) {
        params["is_ledger_added"] = values.is_ledger_added
      }
      if (values.is_ppm_added) {
        params["is_ppm_added"] = values.is_ppm_added
      }
      if (values.is_contribution_added) {
        params["is_contribution_added"] = values.is_contribution_added
      }
      const queryString = Object.keys(params).length > 0 ? '&' + new URLSearchParams(params).toString() : '';
      if (queryString != "") {
        setQueryString(queryString);
        setPage(1)
      } else {
        setQueryString(queryString);
        setFormaikValues('')
      }
      handleClose();

    },
  });
  const resetForm = () => {
    formik.resetForm(initialValues); // Reset the form to initial values    
    setQueryString("")
    setPage(33333333);
    setFormaikValues('');
  };

  return (
    <>
      <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', flex: 1, overflowY: "auto", maxHeight: "90vh" }} style={{ overflow: 'auto' }} >
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 0, flex: 1, width: "100%" }}>
            <h3 style={{ textAlign: 'center' }}>Filter</h3>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <SelectField
                  name="is_nominee_added"
                  label="Missing Nominee"
                  options={MissingNominee}
                />
              </div>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <SelectField
                  name="is_address_added"
                  label="Missing Address"
                  options={MissingAddress}
                />
              </div>
              <div style={{ flex: 1 }}>
                <SelectField
                  name="is_bank_added"
                  label="Missing Bank Details"
                  options={MissingBank}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between',marginTop: '10px'  }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <SelectField
                  name="is_loggedin"
                  label="Not Loggedin Yet"
                  options={LoggedIn}
                />
              </div>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <SelectField
                  name="is_transaction_added"
                  label="Not investment Yet"
                  options={Transaction}
                />
              </div>
              <div style={{ flex: 1 }}>
                <SelectField
                  name="is_demat_added"
                  label="Missing Demat Accounts"
                  options={MissingBank}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between',marginTop: '10px'  }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
              <SelectField
                  name="is_ckyc_added"
                  label="Missing CKYC Document"
                  options={CkycAdded}
                />
              </div>
              <div style={{ flex: 1, marginRight: '10px' }}>
              <SelectField
                  name="is_demat_document_added"
                  label="Missing CMR Document"
                  options={CkycAdded}
                />
              </div>
              <div style={{ flex: 1}}>
              <SelectField
                  name="is_ledger_added"
                  label="Missing Ledger"
                  options={CkycAdded}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between',marginTop: '10px'  }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
              <SelectField
                  name="is_ppm_added"
                  label="Missing PPM Agreement"
                  options={CkycAdded}
                />
              </div>
              <div style={{ flex: 1, marginRight: '10px' }}>
              <SelectField
                  name="is_contribution_added"
                  label="Missing Contribution Agreement"
                  options={CkycAdded}
                />
              </div>
              <div style={{ flex: 1}}>
              
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div style={{ marginRight: '10px' }}>
                <CustomButton style={{ marginLeft: '180px' }} type="submit">
                  Submit
                </CustomButton>
              </div>
              <div >
                <CustomButton type="button" onClick={resetForm}>
                  Reset
                </CustomButton>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Paper>
    </>
  );
}
export default MissingPopup;