// assets
import { IconSettingsAutomation, IconUser } from '@tabler/icons';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const Syndicates = {
    id: 'users1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    // children: [
    //     {
    //         id: 'users11',
    //         title: 'Users',
    //         type: 'item',
    //         icon: IconUser,
    //         url: `/users`,
    //         breadcrumbs: false`
    //     }
    // ]
    children: [
        {
            id: 'users11',
            title: 'Users',
            type: 'collapse',
            icon: IconUser,
            // url: `/investors`,
            breadcrumbs: false,
            children: [
                {
                    id: 'users111',
                    title: 'Users',
                    type: 'item',
                    url: `/users`,
                    icon: IconUser,
                    breadcrumbs: false
                }
                    ,
                    {
                        id: 'users112',
                        title: 'Role',
                        type: 'item',
                        icon: IconSettingsAutomation,
                        url: `/users/role`,
                        breadcrumbs: false
                    },
            ]
        }
    ]
};

export default Syndicates;
