const ones = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen '
];
const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export const convertToIndianCurrencyWords = (number) => {
    // if (number != undefined) {
        if (typeof number === 'number') {
            number = number.toString();
        }
        if (number === undefined || typeof number !== 'string') {
            return 'Please provide a valid input';
        }
        number = number.replace(/,/g, '');
        const parts = number.split('.');
        let integerPart = parts[0];
        let decimalPart = parts[1];
        number = integerPart;
        // console.log(parts[1], "parts[1]")

        if ((number = number.toString()).length > 13) return '';
        const n = ('0000000000000' + number).substr(-13).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

        if (!n) return '';


        let str = '';
        str += n[1] !== '00' ? (
            (ones[Number(n[1])] || tens[n[1][0]] + ' ' + ones[n[1][1]]) +
            (n[2] === "00" ? 'Thousand Crore ' : 'Thousand ')
        ) : '';


        str += n[2] !== '00' ? (
            (ones[Number(n[2])] || tens[n[2][0]] + ' ' + ones[n[2][1]]) +
            (n[3] === "00" ? 'Hundred Crore ' : 'Hundred ')
        ) : '';

        str += n[3] !== '00' ? (ones[Number(n[3])] || tens[n[3][0]] + ' ' + ones[n[3][1]]) + 'Crore ' : '';
        str += n[4] !== '00' ? (ones[Number(n[4])] || tens[n[4][0]] + ' ' + ones[n[4][1]]) + 'Lakh ' : '';
        str += n[5] !== '00' ? (ones[Number(n[5])] || tens[n[5][0]] + ' ' + ones[n[5][1]]) + 'Thousand ' : '';
        str += n[6] !== '0' ? (ones[Number(n[6])] || tens[n[6][0]] + ' ' + ones[n[6][1]]) + 'Hundred ' : '';
        str += n[7] !== '00' ? (str !== '' ? ' ' : '') + (ones[Number(n[7])] || tens[n[7][0]] + ' ' + ones[n[7][1]]) : '';

        return str.trim() + (decimalPart != "00" && decimalPart != undefined ? " Point " + convertToIndianCurrencyWords(decimalPart) : "") || null;
    // }
    // else{
    //     return ""
    // }
};


export const formatDate = (value) => {
    if (value) {
        const date = new Date(value);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    // return ''; // Handle null or empty dates
};
