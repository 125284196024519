import { Button, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FetchTransactionDetail } from 'api';

const ConfirmTransactionPopup = ({ handleClose, activeRow, refetchTransactions, handleConfirm }) => {
  const theme = useTheme();
  const { isLoading: isTransactionDetailLoading, isRefetching: isTransactionDetailRefetching, data: transactionDetail } = FetchTransactionDetail(activeRow["_id"]);
  // console.log(transactionDetail, "activeRow")
  return (
    <>{
      isTransactionDetailLoading ? <></> : <>
        <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '40px 10px 10px 10px', flex: 1 }}>
          <b style={{ margin: 0, fontSize: '21px', margin: '16px' }}>Delete Transaction</b>
          <div className="modal-body">
            <p>Are you sure you want to delete this transaction by <b>{transactionDetail?.investor_name}</b>? </p>
            {transactionDetail?.used_unallocated_sum > 0 && <p>When a transaction is deleted, an additional <b>{transactionDetail?.ref_used_unallocated_sum}</b> is allocated automatically.</p>}
          </div>
          <div className="modal-footer">
            <Button sx={{ backgroundColor: theme.palette.success.main, color: '#fff', ":hover": { backgroundColor: theme.palette.success.dark } }} onClick={() => handleConfirm(transactionDetail["_id"])}>Confirm</Button>
            <Button sx={{ backgroundColor: theme.palette.error.main, color: '#fff', ":hover": { backgroundColor: "#ee3535" } }} onClick={handleClose}>Cancel</Button>
          </div>
        </Paper>
      </>}
    </>
  );
}
export default ConfirmTransactionPopup;