import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import { FetchInvestors, FetchAllKycStatus } from '../../api';
import { useTheme } from '@mui/material/styles';
import { IconEdit } from '@tabler/icons';
import Clickable from 'utils/Clickable';
import { Tooltip } from '@mui/material';
import { Grid, Box } from '@mui/material';
import CustomButton from '../../ui-component/CustomButton';
import TextField from '../../ui-component/TextField';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ApproveKycPopup from './kyc/ApproveKycPopup';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PartnerPopup from './kyc/PartnerPopup';
import Loading from 'views/Loading';
import Modal from 'ui-component/Modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import Cookies from 'js-cookie';
import Card from 'ui-component/cards/Card';
import Typography from '@mui/material/Typography';

// import VentureListing from './VentureListing';
const columns = [

    {
        label: 'Name',
        minWidth: 250,
        align: 'left',
        type: 'name',
        image: true,
        link: true ,fixed:true,
        fixed_row_index:0,
        fixed_column_index:1
    },
    {
        label: 'LP Code',
        minWidth: 100,
        align: 'left',
        type: 'lp_code',
        link: true
    },
    {
        label: 'Email',
        minWidth: 300,
        align: 'left',
        type: 'email'
    },
    {
        label: 'Mobile',
        minWidth: 150,
        align: 'left',
        type: 'mobile_number'
    },
    {
        label: 'Syndicate Name',
        minWidth: 150,
        align: 'left',
        type: 'syndicate_name'
    },
    {
        label: 'Referral Partner',
        minWidth: 150,
        align: 'center',
        type: 'referral_partner'
    },

    {
        label: 'KYC Status',
        minWidth: 210,
        align: 'center',
        type: 'kyc_status',
        status: 'true',
        clickable: false
    },
    {
        label: 'Registered',
        minWidth: 170,
        align: 'left',
        type: 'createdAt',
        sort: true
    },
    {
        label: 'Onboarded',
        minWidth: 170,
        align: 'center',
        type: 'on_board_date'
    },
    {
        label: 'Actions',
        minWidth: 150,
        align: 'right',
        type: 'actions'
    }
];
const mode = '';
const VenturePartner = () => {
    const initialSearch = Cookies.get('venturPartnerSearch') || '';
    const initialsearchStatus = Cookies.get('venturPartnersearchStatus') || false;
    const [title, setTitle] = useState('Venture Partner');
    const [InvestorsModule, setInvestorsModule] = useState(null); // Define the state
    const theme = useTheme();
    const navigate = useNavigate();
    const [search, setSearch] = useState(initialSearch);
    const [searchStatus, setSearchStatus] = useState(initialsearchStatus);
    const [page, setPage] = useState(1);
    const [reset, setreset] = useState(false);
    const [investorId, setInvestorId] = useState('');
    const [open, setOpen] = useState(false);
    const [sort, setSort] = useState(-1);
    const [limit, setLimit] = useState(20);
    const handleOpen = (id) => {
        setInvestorId(id);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = (id) => {
        setInvestorId(id);
        setOpen2(true);
    };
    const handleClose2 = () => setOpen2(false);
    useEffect(() => {
        const permission_values = localStorage.getItem('permission');
        const permissions = permission_values != undefined ? JSON.parse(permission_values) : [];
        const module = permissions.find((module) => module.module_name === 'Investors' && module.permisson === true);
        setInvestorsModule(module); // Set the value in the state
    }, []);

    const InvestorLink = ({ row, children }) => {
        return (
            <Link to={`/investors/viewInvestor/${row._id}`} style={{ textDecoration: 'none', color: '#616161', fontWeight: 700 }}>
                {children}
            </Link>
        );
    };

    const {
        isLoading: isInvestorLoading,
        isRefetching: isInvestorRefetching,
        data: investorData,
        refetch: refetchListing,
        status: investorStatus
    } = FetchInvestors(page, search, mode, sort, limit, '', 'venture_partner');
const { isLoading: isKycLoading, isRefetching: isKycRefetching, data: KycOptions, refetch: refetchKyc } = FetchAllKycStatus('options');

    useEffect(() => {
        refetchListing();
    }, [reset]);
    useEffect(() => {
        if (investorStatus == 'success') {
            setTitle(`Venture Partner(${investorData.count})`);
        } else {
            setTitle(`Venture Partner`);
        }
    }, [investorData]);

    const totalCount = investorData?.count || 0; // Total count of completed deals
    const startCount = (page - 1) * limit + 1; // Starting count of completed deals on the current page
    const endCount = Math.min(page * limit, totalCount);

    useEffect(() => {
        Cookies.set('venturPartnerSearch', search);
    Cookies.set('venturPartnersearchStatus', searchStatus);
      }, [search]);

    const InvestorActions = ({ row }) => {
        const navigate = useNavigate();
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '6px' }}>
                {InvestorsModule?.view_investment && (
                    <Clickable
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => navigate(`/investors/viewInvestor/${row._id}`)}
                    >
                        <Tooltip title="View">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <VisibilityIcon size="1.4rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
                {InvestorsModule?.kyc_status_change && (
                    <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOpen(row._id)}>
                        <Tooltip title="KYC status">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <HowToRegIcon size="1.4rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
                {InvestorsModule?.edit && (
                    <Clickable
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => navigate(`/investors/editInvestor/${row._id}`)}
                    >
                        <Tooltip title="Edit">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconEdit stroke={1.5} size="1.4rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
                {InvestorsModule?.setting_update && (
                    <Clickable style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOpen2(row._id)}>
                        <Tooltip title="Settings">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SettingsIcon stroke={1.5} size="1.4rem" />
                            </div>
                        </Tooltip>
                    </Clickable>
                )}
            </div>
        );
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={title} />
            </Box>

            {/* <VentureListing setTitle={setTitle} InvestorsModule={investorsModule} /> */}
            <ToastContainer />
            {!isKycLoading && (
                <Modal open={open} onClose={handleClose} closeIcon={true}>
                    <ApproveKycPopup
                        handleClose={handleClose}
                        investorId={investorId}
                        KycOptions={KycOptions}
                        setSearchStatus={setSearchStatus}
                        setSearch={setSearch}
                        refetchListing={refetchListing}
                    />
                </Modal>
            )}
            <Modal open={open2} onClose={handleClose2} closeIcon={true} style={{ width: '900px' }}>
                <PartnerPopup
                    handleClose={handleClose2}
                    investorId={investorId}
                    setSearchStatus={setSearchStatus}
                    setSearch={setSearch}
                    refetchListing={refetchListing}
                />
            </Modal>
            <Card style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                <Grid container direction="row" columnSpacing={1} rowSpacing={2} mb={1} className="filter-block">
                    <Grid item xs={3}>
                        <TextField
                            label="Search"
                            value={search}
                            autoComplete="off"
                            fullWidth
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    // alert(e.target.value);
                                    setPage(1);
                                    refetchListing();
                                    setSearchStatus(true);
                                }
                            }}
                            onChange={(event) => {
                                setSearch(event.target.value);
                                setSearchStatus(false);
                            }}
                            size="small"
                            sx={{
                                '& input': {
                                    background: theme.palette.background.paper
                                    // background: '#f9f9fc8e'
                                },
                                '& input:valid + fieldset': {
                                    borderColor: theme.palette.secondary[200],
                                    borderWidth: 0.5
                                },
                                '& input:valid:hover + fieldset': {
                                    borderColor: '#4050b5',
                                    borderWidth: 1
                                },
                                '& input:valid:focus + fieldset': {
                                    borderColor: '#4050b5',
                                    padding: '4px !important',
                                    borderWidth: 1,
                                    borderLeftWidth: 6
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {/* <CustomButton size="large" onClick={()=>navigate('/startups/addstartup')} fullWidth sx={{height:'100%'}}>Add startup</CustomButton> */}
                        <CustomButton
                            // size="small"
                            color={
                                searchStatus && search !== ''
                                    ? [theme.palette.error.dark, theme.palette.error.main]
                                    : [theme.palette.secondary[800], theme.palette.secondary.main]
                            }
                            onClick={() => {
                                setPage(1);
                                if (searchStatus && search !== '') {
                                    setSearch('');
                                    setreset(!reset);
                                } else {
                                    refetchListing();
                                }
                                setSearchStatus((oldVal) => !oldVal);
                            }}
                            fullWidth
                            // sx={{height:'100%'}}
                            sx={{ height: '40px' }}
                        >
                            {searchStatus && search !== '' ? 'Reset' : 'Search'}
                        </CustomButton>
                    </Grid>
                    <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomButton onClick={() => navigate(`/investors/add_investor`)} sx={{ height: '40px', marginRight: '10px' }}>
                            Add Investor
                        </CustomButton>
                    </Grid>
                </Grid>

                {isInvestorLoading ? (
                    <Loading />
                ) : (
                    <AsyncTableListing
                        columns={columns}
                        data={investorData.investors}
                        actions={InvestorActions}
                        page={page}
                        setPage={setPage}
                        count={investorData.count}
                        cutomLink={InvestorLink}
                        sort={sort}
                        setSort={setSort}
                        limit={limit}
                        setLimit={setLimit}
                    />
                )}
                <Typography>{`Showing ${startCount} to ${endCount} of ${totalCount} Venture Partners`}</Typography>
            </Card>
        </>
    );
};

export default VenturePartner;
