import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useField, useFormikContext } from 'formik';
import { withStyles } from '@mui/styles';

const CssTextField = withStyles((theme)=>({
  root: {
    '& .MuiSelect-select.MuiSelect-outlined': {
      backgroundColor: "#fff",
      borderRadius: '10px'
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary[200],
      borderWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary[200],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary[200],
        borderWidth: 0.5,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[800],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[800],
        borderLeftWidth: 6,
        padding: '4px !important',
        borderWidth: 1,
      },
    },
  },
}))(TextField);

const SelectWrapper = ({
  name,
  options,
  onChange,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const wrapper = React.createRef()

  // const onChange = evt => {
  //   const { value } = evt.target;
  //   setFieldValue(name, value);
  // };

  const configSelect = {
    select: true,
    variant: 'outlined',
    fullWidth: true,
    // onChange: onChange,
    ...field,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <CssTextField {...configSelect} ref={wrapper}
      SelectProps={{
        MenuProps: {
          sx: { maxHeight: '40%' }
        }
      }}
    >
        
        {options?.map((item, pos) => {
          return (
            <MenuItem key={pos} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
    </CssTextField>
  );
};
// SelectWrapper.propTypes = {
//   name: PropTypes.string,
//   options: PropTypes.array,
// };
export default SelectWrapper;