import { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, TextField, Typography, Box, IconButton, Popover, Rating, Switch } from '@mui/material'
import { useFormik, Form, Formik, FormikProvider, FieldArray, Field } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import SelectField from 'ui-component/SelectField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import topArrow from '../../assets/images/up-arrow.svg'
import downArrow from '../../assets/images/down-arrow.svg'
import { useParams, useNavigate } from 'react-router';
import { FetchTemplateMenu, FetchMasterTemplateDetails, AttachCheckListDeal } from '../../api'
import Loading from 'views/Loading';
import { toast, ToastContainer } from 'react-toastify';
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Cancel, CloseRounded } from '@mui/icons-material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';

const initialValues = {
    checklist_master_id: "",
    tasks: [],
    checklist_period:"",
}

const AttachChecklist = () => {
    const { id: deal_id } = useParams()
    const [template_id, templateId] = useState(null);
    const [selected, setSelected] = useState(null);
    const [fromik_values, formikValues] = useState(initialValues);
    const [selected_template_id, setTemplateId] = useState(null);
    const navigate = useNavigate();
    const docRef = useRef()
    const { isLoading: templateMenuLoading, data: templateMenuData, status: templateMenuStatus } = FetchTemplateMenu(deal_id)
    // const { mutate } = AttachCheckListDeal()
    const { mutateAsync: attachChecklist, status: attachChecklistStatus, error: attachChecklistError } = AttachCheckListDeal();

    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const updatedTasks = values.tasks.map((task, index) => ({
                ...task,
                display_order: index + 1,
            }));
            values.tasks = updatedTasks;
            attachChecklist({ payload: { ...values }, id: deal_id }
                // onSuccess: () => {
                //     toast.success('Template Create!', {
                //         position: "top-left",
                //         autoClose: 1000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "colored",
                //         onClose: () => {
                //             navigate('/deals')
                //         }
                //     });
                // },
                // onError: (err) => {
                //     toast.error(JSON.parse(err.request.response).message, {
                //         position: "top-left",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "colored",
                //     })
                // }
            )
        }
    })
    useEffect(() => {
        if (attachChecklistStatus == 'success') {
            formik.resetForm()
            toast.success('Checklist Attached', {
              position: "top-left",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
              onClose: () => {
                navigate('/deals')
              }
            });
        }
        if (attachChecklistStatus == 'error') {
            toast.error(attachChecklistError?.response?.data?.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
    
        }
      }, [attachChecklistStatus])
    const { isLoading: ismasterTemplateLoading, data: masterTemplateData, status: masterTemplateStatus } = FetchMasterTemplateDetails(selected_template_id)
    const [newValue, setValue] = useState("22/06/2023");
    const [attachChecklistAction, setAttachChecklistAction] = useState(true);
    const checklistTypeMaster = (event) => {
        setTemplateId(selected)
    };

    const routes = [
        { path: '/', breadcrumb: '' },

    ];
    const theme = useTheme();
    const breadcrumbs = useBreadcrumbs(routes, {
        excludePaths: ["/deals/confirmationList"],
    });

    useEffect(() => {
        if (masterTemplateStatus === 'success' && masterTemplateData) {
            formikValues({
                checklist_master_id: masterTemplateData._id,
                tasks: masterTemplateData.tasks,
                checklist_period:masterTemplateData.checklist_period
            });
        }
    }, [masterTemplateStatus, masterTemplateData]);


    if (templateMenuLoading) {
        return <Loading />
    }

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    const moveTaskUp = (index) => {
        if (index > 0) {
            const newTasks = [...formik.values.tasks];
            [newTasks[index - 1], newTasks[index]] = [newTasks[index], newTasks[index - 1]];
            formik.setFieldValue('tasks', newTasks);
        }
    };
    const moveTaskDown = (index) => {
        const newTasks = [...formik.values.tasks];
        [newTasks[index + 1], newTasks[index]] = [newTasks[index], newTasks[index + 1]];
        formik.setFieldValue('tasks', newTasks);
    };
    return (
        <>
            {/* <BreadcrumbHeader title={"Attach Checklist"} shouldGoBack /> */}
            <div  style={{ marginTop: "25px" }}>
            <FormikProvider value={formik} >
                <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown} >
                        {/* <div className='deal-head-flex d-flex align-items-center mb-4'>
                            {templateMenuData?.imageURl ? <div className="circle-box">
                                <img src={templateMenuData?.imageURl} alt='deal' />
                            </div> : ""}
                            <div className='dhf-right'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className="xs-title font-16">{templateMenuData?.scheme_name}</div>
                                </div>
                            </div>
                        </div> */}
                        <div className='row mt-2' >
                            <div className='col-sm-6 col-12'>
                                <SelectField
                                    name="checklist_master_id"
                                    label="Select Checklist Type"
                                    options={templateMenuData?.selectedData}
                                    onChange={(evt) => {
                                        setSelected(evt.target.value);
                                    }}

                                />
                            </div>
                            <div className='col-sm-3 col-12'>
                                <button type="button" className='button-79 text-center theme-btn h-50' onClick={checklistTypeMaster}>Fetch Tasks</button>
                            </div>
                            <div className='col-sm-3 col-12 text-end'>
                                {masterTemplateData &&
                                <div
                                        className="v-value font-13 fw-600 me-3 deal-status-value"
                                        style={{
                                                color:'#60cb5e',
                                                backgroundColor:'#ebfaeb',
                                                border: 'none',
                                                minWidth: '100px',
                                                fontSize: '12px',
                                                borderRadius: '4px',
                                                fontWeight: 700,
                                                borderWidth: '0.5px',
                                                borderStyle: 'solid',
                                                borderColor:'#60cb5e',
                                                textAlign:'center',
                                                display:"inline-block",
                                                marginTop:'0.5rem'
                                            }}
                                        >
                                            {
                                               masterTemplateData.checklist_period == 'monthly' ? "Monthly Task" : masterTemplateData.checklist_period == 'onetime' ?  " Onetime task" : "Quaterly Task"
                                            }
                                    </div>
                                }
                            </div>
                        </div>
                        {attachChecklistAction ? (
                            <>
                                 <div className="position-relative mt-4">
                            <FieldArray
                                name="tasks"
                                render={(arrayHelpers) => {
                                    docRef.current = arrayHelpers;
                                    return (
                                        <DragDropContext
                                            onDragEnd={(result) => {
                                                if (!result.destination) {
                                                    return  toast.error('please drop inside the list',{
                                                        position: 'top-left',
                                                        autoClose: 1000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: false,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: 'colored',
                                                    })
                                                }
                                                const items = [...formik.values.tasks];
                                                const [reorderedItem] = items.splice(result.source.index, 1);
                                                items.splice(result.destination.index, 0, reorderedItem);
                                                formik.setFieldValue('tasks', items);
                                            }}
                                        >
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {formik.values.tasks &&
                                                            formik.values.tasks.length > 0 &&
                                                            formik.values.tasks.map((_, index) => (
                                                                <Draggable
                                                                    key={`task-${index}`}
                                                                    draggableId={`task-${index}`}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            isDragging={snapshot.isDragging}
                                                                        >
                                                                            <div className={`custom-card card mb-3 checklist-master-card pt-2 ${snapshot.isDragging ? 'background shadow-sm dragging' : 'bg-white shadow-none'}`}>
                                                                                {/* Card content */}
                                                                                <div className="cs-close close-pos-right">
                                                                                    <Cancel onClick={() => arrayHelpers.remove(index)} />
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-md-4 mb-3 d-flex align-items-center">
                                                                                            <div className='pe-3'>#{index + 1}</div>
                                                                                            <CustomField
                                                                                                name={`tasks.${index}.task_name`}
                                                                                                label="Task Name"
                                                                                            />
                                                                                        </div>
                                                                                        <div className='col-md-4 mb-3'>
                                                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                                                        <DatePicker
                                                                                                            label="ETA Date"
                                                                                                            name={`tasks.${index}.eta_date`}
                                                                                                            value={formik.values.tasks[index].eta_date ? formik.values.tasks[index].eta_date : formik.setFieldValue(`tasks.${index}.eta_date`, moment().toISOString())}
                                                                                                            onChange={(newValue) => formik.setFieldValue(`tasks.${index}.eta_date`, moment(newValue).toISOString())}
                                                                                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                                                                                            inputFormat="DD/MM/YYYY"
                                                                                                        />
                                                                                                    </LocalizationProvider>
                                                                                                </div>
                                                                                        <div className="col-md-3 mb-3 pt-1">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <div className="me-2">Required</div>
                                                                                                <div className="custom-switch">
                                                                                                    <Switch
                                                                                                        name={`tasks.${index}.required`}
                                                                                                        checked={
                                                                                                            formik.values.tasks[index]
                                                                                                                .required
                                                                                                        }
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.checked) {
                                                                                                                formik.setFieldValue(
                                                                                                                    `tasks.${index}.required`,
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                            } else {
                                                                                                                formik.setFieldValue(
                                                                                                                    `tasks.${index}.required`,
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'controlled'
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <Tooltip title="Enabling this means the task depends on completing prior tasks. It ensures a step-by-step completion in the checklist.">
                                                                                                    <InfoOutlinedIcon
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                            marginLeft: 5,
                                                                                                            fontSize: 'large'
                                                                                                        }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div  className="col-md-1 mb-3 mt-2" >
                                                                                            <div
                                                                                                className="drag-btns"
                                                                                                {...provided.dragHandleProps}
                                                                                            >
                                                                                                 <Tooltip title="By tap on this you can drag and drop the task anywhere in the list according to you">
                                                                                                <MenuIcon />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-12 mb-3">
                                                                                            <CustomField
                                                                                                name={`tasks.${index}.task_description`}
                                                                                                label="Task Description"
                                                                                            />
                                                                                        </div>

                                                                                        <input
                                                                                            type="hidden"
                                                                                            name={`tasks.${index}.display_order`}
                                                                                            value={index + 1}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    );
                                }}
                            />
                        </div>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <button type='submit' className='button-79 text-center theme-btn'>Attach Checklist</button>
                                    </div>
                                    {(!ismasterTemplateLoading) && <div className='col-6 text-end'>
                                        <button type='button' onClick={() => docRef.current.push({ required: false, task_name: '', task_description: '', display_order: formik.values.tasks.length + 1, task_date: '' })} className='button-79 text-center btn-success'>Add More +</button>
                                    </div>}
                                </div>
                            </>)
                            : ""}
                </Form>
            </FormikProvider>
            </div>
        </>
    )
}

export default AttachChecklist
