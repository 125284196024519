import React, { useState } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import fileIcon from '../../assets/images/icons/folder.png';
import pdfIcon from '../../assets/images/icons/pdf.png';
import xlsxIcon from '../../assets/images/icons/xlsx.png';
import imageIcon from '../../assets/images/icons/image.png';
import docIcon from '../../assets/images/icons/doc.png';
import wordIcon from '../../assets/images/icons/word.png';
import pngIcon from '../../assets/images/icons/png.png';
import doc2Icon from '../../assets/images/icons/doc2.png';
import pdf2Icon from '../../assets/images/icons/pdf-file.png';
import jpgIcon from '../../assets/images/icons/jpg.png';

const UploadDocumentPope = ({ listViewType, item, handleItemClick, handleFileClick }) => {
    const [selectedItem, setSelectedItem] = useState([]);

    const isItemSelected = (itemId) => selectedItem.includes(itemId);

    const toggleItemSelection = (itemId) => {
        // console.log('selectedItem', isItemSelected);
        if (isItemSelected(itemId)) {
            setSelectedItem(selectedItem.filter((id) => id !== itemId));
        } else {
            setSelectedItem([...selectedItem, itemId]);
        }
    };

    return (
        <>
            <div
                className={`upload-document-row d-flex ${isItemSelected(item.id) ? 'selected-item' : ''}`}
                onClick={() => {
                    {
                        {
                            item.is_file === 'false' ? handleItemClick(item) : (handleFileClick(item), toggleItemSelection(item.id));
                        }
                    }
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        {
                            item.is_file === 'false' ? handleItemClick(item) : (handleFileClick(item), toggleItemSelection(item.id));
                        }
                    }
                }}
                role="button"
                tabIndex="0"
            >
                <div className="col">
                    {item.is_file === 'false' ? (
                        <div
                            className="ud-box ud-box2  w-100"
                            role="button"
                            onClick={(e) => handleItemClick(item)}
                            tabindex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleItemClick(item);
                                }
                            }}
                        >
                            <div className="xs-title d-flex align-items-center">
                                <img className="ud-icon" src={fileIcon} alt="icon" />
                                <Tooltip title={item.name} arrow placement="top">
                                    <div className="ps-2 text-ellipsis">{item.name} </div>
                                </Tooltip>
                            </div>
                        </div>
                    ) : (
                        // </Link>
                        <div
                            className="ud-box ud-box2"
                            role="button"
                            // onClick={(e) => handleFileClick(item)}
                            tabindex={0}
                            // onKeyDown={(e) => {
                            //     if (e.key === 'Enter') {
                            //         handleFileClick(item);
                            //     }
                            // }}
                        >
                            <div className="xs-title d-flex align-items-center">
                                <img
                                    className="ud-icon"
                                    src={
                                        item.name.split('.').pop() == 'pdf'
                                            ? pdfIcon
                                            : item.name.split('.').pop() == 'png'
                                            ? pngIcon
                                            : item.name.split('.').pop() == 'xlsx'
                                            ? xlsxIcon
                                            : item.name.split('.').pop() == 'jpg' || item.name.split('.').pop() == 'JPEG'
                                            ? jpgIcon
                                            : doc2Icon
                                    }
                                    alt="icon"
                                />
                                <Tooltip title={item.name} arrow placement="top">
                                    <div className="ps-2 text-ellipsis">{item.name}</div>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>
                {!listViewType && (
                    <>
                        <div className="col">
                            {item.type === 'folder' ? (
                                // <Link to="folderid" className='ud-link'>
                                <div className="ud-box ud-box2">
                                    <div className="xs-title">{item.uploaded_by}</div>
                                </div>
                            ) : (
                                // </Link>
                                <div className="ud-box ">
                                    <div className="xs-title">{item.uploaded_by}</div>
                                </div>
                            )}
                        </div>
                        <div className="col">
                            {item.type === 'folder' ? (
                                // <Link to="folderid" className='ud-link'>
                                <div className="ud-box ">
                                    <div className="ud-tag">{item?.tagData}</div>
                                </div>
                            ) : (
                                // </Link>
                                <div className="ud-box">
                                    <div className="ud-tag">{item?.tagData}</div>
                                </div>
                            )}
                        </div>
                        <div className="col">
                            <div className="ud-box">
                            {item.is_file === "false" ? "Folder"  :  "File" }                            </div>
                        </div>
                        <div className="col">
                            <div className="ud-box">
                                <div className="ud-date">{item.createdAt}</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default UploadDocumentPope;
