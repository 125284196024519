import { Grid, Box, Typography, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { AiFillCloseCircle } from 'react-icons/ai';

function Card({ handleClose, title, children, style = {} , cardActions}) {
	const theme = useTheme();
	return (
		<MainCard
			border={false}
			elevation={16}
			content={false}
			boxShadow
			shadow={theme.shadows[16]}
			sx={{
				width: '100%',
				marginBottom: '16px',
				borderRadius: '8px'
			}}
			handleClose={handleClose}
			>
			<Grid container item xs={12} direction="column" spacing={2} id="1">
				{title && <Grid item xs={12} id="2">
					<Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
						<Grid item>
							<Stack direction="row" spacing={2}>
								<Typography variant="subtitle1">{title}</Typography>
							</Stack>
						</Grid>
						<Grid item>
							{cardActions && <>{cardActions}</>}
						</Grid>
					</Grid>
				</Grid>}
				<Grid item xs={12} id="3" sx={{ width: '100%' }}>
					<Box>
						<Grid container direction="column" spacing={2}>
							<Grid item xs={12} p={0} id="4">
								<Divider sx={{ my: 0 }} />
							</Grid>
						</Grid>
						<Box sx={{ padding: '10px 20px 20px 20px', marginBottom: '16px', mt: 1.5, ...style }}>
							{children}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</MainCard>
	)
}

export default Card