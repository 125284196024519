
import React, { useState } from 'react';
import { Box } from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { FetchDealPartner,UpdatePartnerFees } from 'api';
import {useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useFormik, Form,Formik ,FormikProvider } from "formik";


export default function PartnerFees({ startupDealId,id }) {
    const [title, setTitle] = useState('Partner Fees');
    const [updatedData, setUpdatedData] = useState([]);
    const {
        isLoading: isPartnerFeesDataLoading,
        isRefetching: isPartnerFeesDataRefetching,
        data: partnerFeesData,
        // refetch: refetchListing,
        refetch: refetchPartnerFeesData,
        status: dealpartnerStatus
    } = FetchDealPartner(startupDealId);
    const { mutateAsync: updatePercentages, status: updatePercentagesStatus, error: updatePercentagesError,reset: updatePercentagesReset } = UpdatePartnerFees(id);
    
    const formik = useFormik({
        initialValues: {
            fees_percentage: partnerFeesData?.map(partner => partner.deal_partner_fees_percentage) || []
          },
        enableReinitialize: true,
        onSubmit: async (values,{ setValues }) => {
            if (partnerFeesData) {
                const updatedData = partnerFeesData.map((partner, index) => ({
                    investor_id: partner._id,
                    fees_percentage: values.fees_percentage[index] || 0
                }));
                updatePercentages({ payload: updatedData });
                if (updatePercentagesStatus === 'success') {
                    refetchPartnerFeesData();
                }
            }
        }
    });
    // useEffect(() => {
    //     setTitle(' VP Fees');
    //   }, []);
    useEffect(() => {
        // window.location.reload();
        if (updatePercentagesStatus == 'success') {
          toast.success('Percentage Added', {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
        }, 1000);
        }
        if (updatePercentagesStatus == 'error') {
          toast.error(updatePercentagesError?.response?.data?.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
    
        }
      }, [updatePercentagesStatus])

  return (
    <>
    <ToastContainer />
    {/* {(!id && !startupDealId) && ( // Hide BreadcrumbHeader if id or startupDealId is present
        <Box sx={{ width: '100%' }}>
          <BreadcrumbHeader title={title} />
        </Box>
      )} */}
        {/* <div className='custom-menu-block view-investor-block'> */}
            {/* <div className='d-flex mb-3'>
                <div>
                    <input type='text' className='form-control' placeholder='Search'/>
                </div>
                <div className='ms-3'>
                    <button className='button-79' type='button'>Search</button>
                </div>
            </div> */}
            <div  className='custom-table mt-3'>
            <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <table style={{borderRadius: '8px',overflow: 'hidden'}} className='w-100'>
                    <thead>
                        <tr>
                            <th style={{width: "15%"}}>LP Code</th>
                            <th style={{width: "25%"}}>Name</th>
                            <th style={{width: "25%"}}>Email</th>
                            <th style={{width: "15%"}}>Type</th>
                            <th style={{width: "20%"}}>Fee %</th>
                        </tr>
                    </thead>
                    <tbody>
                    {partnerFeesData ? (
                            partnerFeesData.map((partner, index) => (
                        <tr key={index}>
                            <td>{partner.lp_code}</td>
                            <td>{partner.name}</td>
                            <td>{partner.email}</td>
                            <td>{partner.user_type}</td>
                            <td>
                                <input className='form-control text-center w-70' 
                                type='text'
                                name={`fees_percentage[${index}]`} 
                                value={
                                    formik.values.fees_percentage[index]
                                  }
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^\d*\.?\d*$/.test(inputValue)) {
                                      // Update formik value only if the input is a valid number/decimal
                                      formik.handleChange(e);
                                  }
                              }}
                                min={0}
                                max={100} 
                                // step={0.0001}
                                /> 
                                %
                            </td>
                        </tr>
                        ))
                        ) : (
                            <tr>
                                <td colSpan="5">Loading...</td>
                            </tr>
                        )}
                    </tbody>
                      </table>
                      <div className='mt-2 d-flex'>
                    <div style={{width: "80%"}}></div>
                    <div style={{width: "20%", paddingLeft:"15px"}}>
                        <button className='button-79' type='submit' >Save All</button>
                    </div>
                      </div>
                    </Form>
            </FormikProvider>
            </div>
        {/* </div> */}
        
    </>
  )
}