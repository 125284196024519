import { styled } from '@mui/material/styles';
import {TableRow} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    // maxWidth: '100px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.light,
    
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));