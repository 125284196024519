
import { useState, useRef } from 'react'
import { TextField, Paper, Box, Button, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/SelectField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Clickable from 'utils/Clickable';
import fileIcon from '../../assets/images/icons/folder.png'
import pdfIcon from '../../assets/images/icons/pdf.png'
import xlsxIcon from '../../assets/images/icons/xlsx.png'
import imageIcon from '../../assets/images/icons/image.png'
import docIcon from '../../assets/images/icons/doc.png'
import wordIcon from '../../assets/images/icons/word.png'
import pngIcon from '../../assets/images/icons/png.png'
import doc2Icon from '../../assets/images/icons/doc2.png'
import pdf2Icon from '../../assets/images/icons/pdf-file.png'
import jpgIcon from '../../assets/images/icons/jpg.png'
import { DeleteDocumentForInvestor } from 'api';
import { Delete } from '@mui/icons-material';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import ConfirmDeleteDocumentPopup from 'views/startup/ConfirmDeleteDocumentPopup';
const DocumentViewPopup = ({ data, transactionID, refetchInvestorData, refetchAllDealsofInvestor, setViewDocumentOpen, setSelectedDocumentData }) => {
    // console.log(data,"data89")
    const [openComfirmationPop, setOpenComfirmationPop] = useState(false)
    const [documentId, setDocumentId] = useState(null)
    const { mutateAsync: deleteDocumentForInvestor, status: deletedocumentForInvestorStatus, error: deletedocumentForInvestorError } = DeleteDocumentForInvestor();
    const theme = useTheme();
    const initialValues = {
    }
    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {

        }
    });

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    const CustomTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px',
                    borderRightWidth: '0px'
                },
            },
        },
    })(TextField);

    const fileExtensionSwitch = (value) => {
        switch (value) {
            case 'docx': return doc2Icon
            case 'excel': return xlsxIcon
            case 'png': return pngIcon
            case 'jpeg': return jpgIcon
            case 'jpg': return jpgIcon
            case 'pdf': return pdfIcon
            default: return pdfIcon
        }
    }
    const handleCloseConfirmationPopup = () => { setOpenComfirmationPop(false); setDocumentId(null) };

    const handleDeleteDocument = (selectedDriveID) => {
        deleteDocumentForInvestor({
            payload: {
                "drive_id": selectedDriveID
            },
            id: transactionID
        });
        setSelectedDocumentData(data?.filter(item => item?.drive_id !== selectedDriveID))
        handleCloseConfirmationPopup()
    }

    useEffect(() => {
        if (deletedocumentForInvestorStatus === 'success') {
            toast.success("Document Deleted", {
                position: "top-left",
                // autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            }, 2000);
            setTimeout(() => {
                refetchInvestorData();
                refetchAllDealsofInvestor();
            }, 0)
        }
        else if (deletedocumentForInvestorStatus === 'error') {
            setTimeout(() => {
                toast.error(deletedocumentForInvestorError, {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                }, 2000)
            })
        }

        // if(documentsData?.length === 0) {
        //     setViewDocumentOpen(false)
        // }
    }, [deletedocumentForInvestorStatus, deletedocumentForInvestorError]);

    return (
        <>
            <Modal open={openComfirmationPop} onClose={handleCloseConfirmationPopup}>
                <Box
                    sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '700px' }}
                    className="delete-transaction-modal"
                >
                    <ConfirmDeleteDocumentPopup
                        document_id={documentId}
                        handleClose={handleCloseConfirmationPopup}
                        handleConfirm={handleDeleteDocument}
                    />
                </Box>
            </Modal>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', overflowY: "auto", maxHeight: "90vh" }} >
                <h3 style={{ textAlign: 'center' }}>Documents</h3>
                <ul className="document-list mt-2">
                    {data?.map((item, index) => (
                        <>
                            <li className="d-flex align-items-center justify-content-between">
                                <div className='d-flex align-items-center'>
                                    <Clickable className="icon" onClick={() => window.open(item?.url, 'blank')}>
                                        <img className='ud-icon'
                                            // src={pdfIcon} 
                                            src={fileExtensionSwitch(item?.type)}
                                            alt="file"
                                        />
                                    </Clickable>
                                    <Clickable className="ms-2" onClick={() => window.open(item?.url, 'blank')}>
                                        <div className="xs-title font-16 text-start">{item?.name}</div>
                                        <div className="sm-title font-12 text-start mt-1">{item?.date_format}</div>
                                    </Clickable>
                                </div>
                                {!(document.URL).includes('deal') && <Clickable onClick={() => {
                                    setDocumentId(item?.drive_id);
                                    setOpenComfirmationPop(true)
                                }}>
                                    <Delete />
                                </Clickable>}
                            </li>
                        </>
                    ))}
                    {/* <li className="d-flex align-items-center">
                        <div className="icon">
                            <img src={pdfIcon} alt="pdf"/>
                        </div>
                        <div className="ms-2">
                            <div className="xs-title font-16 text-start">Document 2</div>
                            <div className="sm-title font-12 text-start">5 Nov 2022</div>
                        </div>
                    </li>
                    <li className="d-flex align-items-center">
                        <div className="icon">
                            <img src={pdfIcon} alt="pdf"/>
                        </div>
                        <div className="ms-2">
                            <div className="xs-title font-16 text-start">Document 3</div>
                            <div className="sm-title font-12 text-start">5 Nov 2022</div>
                        </div>
                    </li>
                    <li className="d-flex align-items-center">
                        <div className="icon">
                            <img src={pdfIcon} alt="pdf"/>
                        </div>
                        <div className="ms-2">
                            <div className="xs-title font-16 text-start">Document 4</div>
                            <div className="sm-title font-12 text-start">5 Nov 2022</div>
                        </div>
                    </li> */}
                </ul>
            </Paper>
        </>
    );
}

export default DocumentViewPopup;
