// assets
import { IconBuildingSkyscraper } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const startup = {
    id: 'startup1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'startup11',
            title: 'Startups',
            type: 'collapse',
            // url: `/startups`,
            icon: IconBuildingSkyscraper,
            breadcrumbs: false,

            children: [
                // {
                //     id: 'startup111',
                //     title: 'Selected',
                //     type: 'item',
                //     url: `/startups/selected`,
                //     icon: IconBuildingSkyscraper,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'startup112',
                //     title: 'Applications',
                //     type: 'item',
                //     url: `/applicationListing`,
                //     icon: IconBuildingSkyscraper,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default startup;
