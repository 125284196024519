import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';


export const FetchAllUsers = ({page, search, sort=1, limit=20,stage,role}) => {
    return useQuery(
      ['user-listing', page, sort, limit], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/list/admin?page=${page}&v_no=1&searchText=${search}&syndicate_id=${stage}&permission_id=${role}${sort!=0 ? `&sort=${sort}`:""}&limit=${limit}`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwt')}`,
            'Content-Type': 'application/json'
          },
        }) 
    },{
      select: (data)=>({data: data.data.data.map(row =>{
        const profile_column = {
          profile: row.logo,
          profile_name: row.name
        } 
       return {...row, profile_column  }
      }), count: data.data.count}),
      refetchOnWindowFocus:false,
      retry: (failureCount, error)=>{
        return RetryOnError(failureCount, error)
      },
      onError: (err)=>{
        return verifyToken(err)
      },
    })
  }



export const FetchUserDetails = (id, mode) => {
  
  return useQuery(
    ['admin-user-details', id, mode], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/detail/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode==='edit'){
        const savedData = data.data.data[0]
        const savedValues = {
          name: savedData.name || '',
          email: savedData.email || '',
          admin_image: savedData.admin_image || null,
          role:savedData.role,
          password: '1234',
          permission:savedData?.permission,
          permission_id:savedData?.permission_id,
          is_super_admin:savedData?.is_super_admin,
          syndicate_id:savedData?.syndicate_id,
          is_email_sent:savedData?.is_email_sent == "Yes" ? true :false,

        }

        return savedValues
      }
      
      return data.data.data[0]
    },
    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AddUser = () => {
  return useMutation((payload) => {

    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/register`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'multipart/form-data'
      },
      data:payload.payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const UpdateUser = () => {
  return useMutation(({payload, id}) => {
// Display the key/value pairs
    // for (var pair of payload.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/update/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const DeleteUser = () => {
  return useMutation((id) => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/admin/article/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: id
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const UpdatePassword = () => {
  return useMutation((payload) => {

    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/change-pin`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}