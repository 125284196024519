import { useState, useRef } from 'react';
import { Paper, Box, Button, Chip, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from 'react-icons/im';
import {
    ChangeKycStatus,
    FetchInvestorPartners,
    FetchInvestorVenturePartners,
    FetchReferredPartners,
    FetchInvestorDetails,
    UpdatePartnerSettings,
    FetchInvestorTypes,
    FetchReferralList,
    FetchContributerList,
    FetchAllSyndicatesOptions,
    TagsList,
    FetchAllUnitClassTypes
} from 'api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField2';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CustomAutoComplete from 'ui-component/formik/CustomAutoComplete2';
import Loading from 'views/Loading';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import CustomMultiSelect from 'ui-component/formik/CustomMultiSelect';
import { AiFillCloseCircle } from 'react-icons/ai';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { Tooltip } from '@mui/material';

const validationSchema = yup.object({
    min_investment_amount: yup.string('Enter Min Investment Amount').required('Min Investment Amount is required').trim(),
    max_investment_amount: yup.string('Enter Max Investment Amount').required('Max Investment Amount is required').trim(),
    closing_date: yup.string('Enter Closing Date').required('Closing Date is required').trim(),
    pitch_recording: yup.string('Enter Pitch Recording').required('Pitch Recording is required').trim()
});
const columns = [
    {
        label: 'Date',
        minWidth: 0,
        align: 'left',
        type: 'date'
    },
    {
        label: 'Description',
        minWidth: 0,
        align: 'left',
        type: 'description'
    },
    {
        label: 'KYC Status',
        minWidth: 0,
        align: 'left',
        type: 'kyc_status',
        clickable: false
    },
    {
        label: 'Updated By',
        minWidth: 0,
        align: 'left',
        type: 'updated_by'
    }
];
const PartnerPopup = ({ handleClose, investorId, setSearchStatus, setSearch, refetchListing }) => {
    const [logPage, setLogPage] = useState(1);
    const theme = useTheme();

    const { mutateAsync: updatePartnerSettings } = UpdatePartnerSettings();
    const {
        isLoading: isInvestorDetailsLoading,
        data: investorData,
        status: investorDataStatus,
        refetch: investorDataRefetch
    } = FetchInvestorDetails(investorId, 'settings');
    const { isLoading: isInvestorCastlerDetailsLoading, data: currentInvestorDetail, refetch: currentInvestorDataRefetch } = FetchInvestorDetails(
        investorId,
        'castlerBankAccount'
    );
    // console.log(investorData,'investorData', investorId);
    // const { data: investorPartners, isLoading: isInvestorPartnersLoading } = FetchInvestorPartners({investorId})
    // const { data: investorVenturePartners, isLoading: isInvestorVenturePartnersLoading } = FetchInvestorVenturePartners({investorId})
    // const { data: investorReferredPartners, isLoading: isInvestorReferredPartnersLoading } = FetchReferredPartners({investorId})
    const { isLoading: isInvestorTypesLoading, data: investorTypes, status: investorTypeStatus } = FetchInvestorTypes({ mode: 'select' });
    const { isLoading: isPartnerListLoading, data: partnerList } = FetchReferralList({ investorType: 'partner' });
    const { isLoading: isVenturePartnerListLoading, data: venturePartnerList } = FetchReferralList({ investorType: 'venture_partner' });
    const { isLoading: isLeadInvestorListLoading, data: leadInvestorList } = FetchAllSyndicatesOptions();
    const { isLoading: isUnitClassListLoading, data: unitClassList } = FetchAllUnitClassTypes();
    // const { isLoading: isContributerListLoading, data: contributerList } = FetchReferralList({ investorType: "contributor" })
    const { data: tagData, isLoading: isTagsLoading } = TagsList();
    const [tags, setTags] = useState([]);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [checked, setChecked] = useState(currentInvestorDetail?.castler_status === 'Yes' ? true : false);
    // console.log(unitClassList,"unitClassList")
    // Set tag suggestions when tagData is available
    useEffect(() => {
        if (tagData) {
            setTagSuggestions(tagData);
        }
    }, [tagData]);
    const handleAddition = (newTags) => {
        const uniqueNewTags = newTags.filter((newTag) => !tags.some((tag) => tag.text === newTag.text));

        setTags((prevTags) => [...prevTags, ...uniqueNewTags]);
    };
    const handleDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = { id: inputValue, text: inputValue };
            setTags([...tags, newTag]);
            setInputValue('');
        }
    };

    const [isDisabled, setIsDisabled] = useState(true);

    const initialValues = {
        // isPartner: 'No',
        // isVenturePartner: 'No',
        is_referral: {
            master_user_type_id: ''
        },
        partner: { _id: '', lp_code: '', name: '' },
        venturePartner: { _id: '', lp_code: '', name: '' },
        representative: { _id: '', lp_code: '', name: '' },
        unallocated_amount: '',
        // leadInvestor: { _id: '', lp_code: '', name: '' },
        syndicate_id: [],
        agreement_date: null,
        signature_date: null,
        ckyc: '',
        unit_class_id: '',
        ppm_number: '',
        stamp_number: '',
        tagsData: [],
        castler_status: '',
        // commitment_amount_temp:'',
        commitment_amount: '',
        is_lead_investor: 'No'
    };
    const formik = useFormik({
        initialValues: !isInvestorDetailsLoading ? investorData : initialValues,
        // validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const payload = {
                investor_id: investorId,
                is_referral: values.is_referral.master_user_type_id ? values.is_referral : {},
                investor_referral: {},
                agreement_date: values.agreement_date,
                signature_date: values.signature_date,
                ckyc: values.ckyc,
                unit_class_id: String(values.unit_class_id)?.trim(),
                ppm_number: values.ppm_number,
                stamp_number: values.stamp_number,
                tagsData: tags.map((tag) => ({ name: tag.text })),
                castler_status: values.castler_status,
                // commitment_amount_temp:values.commitment_amount_temp,
                commitment_amount: values.commitment_amount,
                is_lead_investor: values.is_lead_investor
            };
            // if(values.agreement_date){
            //   payload["agreement_date"]= values.agreement_date
            // }
            // if(values.signature_date){
            //   payload["signature_date"]= values.signature_date
            // }
            // if(values.ckyc){
            //   payload["ckyc"]= values.ckyc
            // }
            if (values.unit_class_id == '') {
                delete payload.unit_class_id
            }
            // if(values.ppm_number){
            //   payload["ppm_number"]= values.ppm_number
            // }
            // if(values.stamp_number){
            //   payload["stamp_number"]= values.stamp_number
            // }
            if (values.partner?._id) {
                payload.investor_referral['investor_partner_id'] = values.partner?._id;
            }
            if (values.venturePartner?._id) {
                payload.investor_referral['investor_venture_partner_id'] = values.venturePartner?._id;
            }
            // if (values.leadInvestor?._id) {
            //   payload.investor_referral["lead_investor_id"] = values.leadInvestor?._id
            // }
            if (values.syndicate_id.length > 0) {
                payload.investor_referral['syndicate_id'] = values.syndicate_id;
            }
            // if (values.representative?._id) {
            //   payload.investor_referral["investor_representative_id"] = values.representative?._id
            // }
            if (values.unallocated_amount) {
                payload['unallocated_amount'] = values.unallocated_amount;
            }
            if (typeof values.representative == 'object' && values.representative?.name) {
                payload['representative_name'] = values.representative?.name;
            } else {
                if (typeof values.representative == 'string') {
                    payload['representative_name'] = values.representative;
                }
            }

            updatePartnerSettings(
                { payload },
                {
                    onSuccess: () => {
                        setSearchStatus(false);
                        setSearch('');
                        handleClose();
                        toast.success('Investor Settings Updated', {
                            position: 'top-left',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                        setIsDisabled(true);
                        investorDataRefetch();
                        currentInvestorDataRefetch()
                    },
                    onError: (err) => {
                        toast.error(JSON.parse(err.request.response).message, {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                    }
                }
            );
            setTimeout(() => {
                refetchListing();
            }, 1000);
        }
    });

    // console.log(formik.values.agreement_date);
    const { isLoading: isContributerListLoading, data: contributerList } = FetchContributerList({
        syndicate_id: formik.values.syndicate_id
    });
    // console.log(contributerList,"cont");

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    useEffect(() => {
        if (investorTypeStatus == 'success' && investorDataStatus == 'success') {
            // console.log("investorTypes", investorTypes);
            // console.log("master_user_type_id", investorData.is_referral.master_user_type_id);
            // console.log((investorTypes.filter(type => type.value == investorData.is_referral.master_user_type_id)[0]?.label == "Partner") || (investorTypes.filter(type => type.value == investorData.is_referral.master_user_type_id)[0]?.label == "Venture Partner"))
            setIsDisabled(
                investorTypes.filter((type) => type.value == investorData.is_referral.master_user_type_id)[0]?.label == 'Partner' ||
                investorTypes.filter((type) => type.value == investorData.is_referral.master_user_type_id)[0]?.label ==
                'Venture Partner'
            );
            setTags(investorData.tags);
        }
    }, [investorTypeStatus, investorDataStatus, investorData]);
    // useEffect(()=>{
    //   if(tagData){
    //    setTags(investorData.tags)
    //   }
    // }, [investorData]);

    let handleChange = (event) => {
        setChecked(event.target.checked);
        formik.setFieldValue('castler_status', event.target.checked === true ? 'Yes' : 'No');
    };

    // console.log(String(formik.values.unit_class_id).replaceAll(" ","@"),"unit_class_id")
    // console.log(formik.values,"values")
    return (
        <>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
                style={{ overflow: 'auto' }}
            >
                {isContributerListLoading ||
                    isVenturePartnerListLoading ||
                    isPartnerListLoading ||
                    isInvestorDetailsLoading ||
                    (Cookies.get('is_super_admin') == 'true' && isLeadInvestorListLoading) ? (
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loading />
                    </div>
                ) : (
                    <FormikProvider value={formik}>
                        {/* {console.log(formik.values)} */}
                        <Form
                            onSubmit={formik.handleSubmit}
                            onKeyDown={onKeyDown}
                            style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10, flex: 1 }}
                        >
                            <h3 style={{ textAlign: 'center' }}>Settings</h3>
                            {!isInvestorDetailsLoading && investorData?.lp_code && (
                                <p style={{ margin: 0, marginBottom: '10px' }}>
                                    LP Code: <b>{investorData?.lp_code}</b>
                                </p>
                            )}
                            {!isInvestorDetailsLoading && investorData?.fullName && (
                                <p style={{ margin: 0, marginBottom: '30px' }}>
                                    Investor Name: <b>{investorData?.fullName}</b>
                                </p>
                            )}
                            <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                                {!isInvestorTypesLoading && (
                                    <Grid item sm={6} xs={12}>
                                        <SelectField
                                            onChange={(evt) => {
                                                setIsDisabled(false);
                                                formik.setFieldValue('is_referral.master_user_type_id', evt.target.value);
                                            }}
                                            disabled={isDisabled}
                                            name="is_referral.master_user_type_id"
                                            label="User Type"
                                            options={investorTypes}
                                        />
                                    </Grid>
                                )}
                                {Cookies.get('is_super_admin') == 'true' && !isLeadInvestorListLoading && !isInvestorDetailsLoading && (
                                    <Grid item sm={6} xs={12}>
                                        <CustomMultiSelect
                                            label="Syndicates"
                                            name="syndicate_id"
                                            value={formik.values.syndicate_id}
                                            options={leadInvestorList}
                                            placeholder="Syndicates"
                                        />
                                        {/* <CustomAutoComplete
                  label="Lead Investors"
                  name="leadInvestor"
                  value={formik.values?.leadInvestor || { _id: '', lp_code: '', name: '' }}
                  options={leadInvestorList}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("leadInvestor", newValue);
                  }}
                  getOptionLabel={(option) => { return `${(option?.name && option?._id) ? option.name : ''} ${option?.lp_code ? `(${option.lp_code})` : ''}` }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.name}{option.lp_code && ` (${option.lp_code})`}
                    </Box>
                  )}
                /> */}
                                    </Grid>
                                )}
                                {!isPartnerListLoading && !isInvestorDetailsLoading && (
                                    <Grid item sm={6} xs={12}>
                                        <CustomAutoComplete
                                            label="Partner"
                                            name="partner"
                                            value={formik.values?.partner || { _id: '', lp_code: '', name: '' }}
                                            options={partnerList}
                                            onChange={(event, newValue) => {
                                                // console.log(event, newValue);
                                                formik.setFieldValue('partner', newValue);
                                                formik.setFieldValue('venturePartner', { _id: '', lp_code: '', name: '' });
                                            }}
                                            getOptionLabel={(option) => {
                                                return `${option?.name && option?._id ? option.name : ''} ${option?.lp_code ? `(${option.lp_code})` : ''
                                                    }`;
                                            }}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.name}
                                                    {option.lp_code && ` (${option.lp_code})`}
                                                </Box>
                                            )}
                                        />
                                    </Grid>
                                )}
                                {!isVenturePartnerListLoading && !isInvestorDetailsLoading && (
                                    <Grid item sm={6} xs={12}>
                                        <CustomAutoComplete
                                            label="Venture Partner"
                                            name="venturePartner"
                                            value={formik.values?.venturePartner || { _id: '', lp_code: '', name: '' }}
                                            options={venturePartnerList}
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue('venturePartner', newValue);
                                            }}
                                            getOptionLabel={(option) => {
                                                return `${option?.name && option?._id ? option.name : ''} ${option?.lp_code ? `(${option.lp_code})` : ''
                                                    }`;
                                            }}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.name}
                                                    {option.lp_code && ` (${option.lp_code})`}
                                                </Box>
                                            )}
                                        />
                                    </Grid>
                                )}
                                {!isContributerListLoading && !isInvestorDetailsLoading && (
                                    <Grid item sm={6} xs={12}>
                                        <CustomAutoComplete
                                            label="Representative"
                                            name="representative"
                                            onBlur={(event) => {
                                                const newValue = event.target.value;
                                                const investor = contributerList.filter((el) => el.name.trim() == newValue.trim());
                                                if (!investor.length) {
                                                    formik.setFieldValue('representative', { _id: newValue, lp_code: '', name: newValue });
                                                }
                                            }}
                                            value={formik.values?.representative || { _id: '', lp_code: '', name: '' }}
                                            options={contributerList}
                                            onChange={(event, newValue) => {
                                                if (typeof newValue == 'string') {
                                                    formik.setFieldValue('representative', { _id: newValue, lp_code: '', name: newValue });
                                                } else {
                                                    formik.setFieldValue('representative', newValue);
                                                }
                                            }}
                                            freeSolo={true}
                                            getOptionLabel={(option) => {
                                                return `${option?.name && option?._id ? option.name : ''} ${option?.lp_code ? `(${option.lp_code})` : ''
                                                    }`;
                                            }}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.name}
                                                    {option.lp_code && ` (${option.lp_code})`}
                                                </Box>
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid item sm={6} xs={12}>
                                    <Tooltip title={convertToIndianCurrencyWords(formik.values?.commitment_amount)}>
                                        <CustomField name="commitment_amount" label="Commitment Amount" autoComplete="off" />
                                    </Tooltip>
                                </Grid>
                                {/* <Grid item sm={6} xs={12}>
                                    <CustomField name="unallocated_amount" label="Balance Amount" />
                                </Grid> */}
                                <Grid item sm={6} xs={12}>
                                    <SelectField
                                        name="unit_class_id"
                                        label="Unit Class"
                                        options={[
                                            { label: 'Select Option', value: '' },
                                            ...unitClassList.data?.map(item => {
                                                return {
                                                    label: item?.label,
                                                    value: item?.unit_class_id
                                                }
                                            })
                                        ]}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomField name="stamp_number" label="Stamp Number" />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomField name="ppm_number" label="PPM No" />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Signature Date"
                                            value={formik.values.signature_date}
                                            // disableFuture={true}
                                            onChange={(newValue) => formik.setFieldValue('signature_date', moment(newValue).toISOString())}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputFormat="DD/MM/YYYY"
                                        // openTo="year"
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Agreement Date"
                                            value={formik.values.agreement_date}
                                            // disableFuture={true}
                                            onChange={(newValue) => formik.setFieldValue('agreement_date', moment(newValue).toISOString())}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                            inputFormat="DD/MM/YYYY"
                                        // openTo="year"
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomField name="ckyc" label="CKYC" />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Autocomplete
                                        className="crossdisable"
                                        multiple
                                        id="tag-input"
                                        name="tagsData"
                                        options={tagSuggestions}
                                        getOptionLabel={(option) => option.text}
                                        value={tags}
                                        onChange={(_, newValue) => handleAddition(newValue)}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    key={option.id}
                                                    label={option.text}
                                                    {...getTagProps({ index })}
                                                    onDelete={() => handleDelete(option)}
                                                />
                                            ))
                                        }
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                        onKeyDown={handleInputKeyDown}
                                        renderInput={(params) => <TextField {...params} label="Tags" placeholder="Enter tags" />}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <SelectField
                                        onChange={(evt) => {
                                            // setIsDisabled(false);
                                            formik.setFieldValue('is_lead_investor', evt.target.value);
                                        }}
                                        // disabled={isDisabled}
                                        value={formik.values?.is_lead_investor}
                                        name="is_lead_investor"
                                        label="Lead Investor"
                                        options={[{ label: 'No', value: 'No' }, { label: 'Yes', value: 'Yes' }]}
                                    />
                                </Grid>
                                {/* Pls Comment Out this line after pushing the code*/}
                                {/* <Grid item sm={6} xs={12}>
                                    Switch to: Bank
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    Escrow
                                </Grid> */}
                                {/* <Grid item sm={6} xs={12}>
                <Tooltip title={convertToIndianCurrencyWords(formik.values.commitment_amount_temp)}>
                  <CustomField name="commitment_amount_temp" label="Commitment Amount for Contribution Agreement" autoComplete="off" />
                </Tooltip>
              </Grid> */}
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <CustomButton type="submit">Submit</CustomButton>
                            </Grid>
                        </Form>
                    </FormikProvider>
                )}
            </Paper>
        </>
    );
};

export default PartnerPopup;
