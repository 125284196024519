import { Autocomplete, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, InputLabel, Paper, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles({
    customInput: {
        '&  > div.MuiInputBase-root': {
            padding: 0
        }
    }
});

const DealTranchConfirmationPopup = ({ formik, handleClose, activeRow, refetchTransactions, handleConfirm }) => {
    const theme = useTheme();
    const [emails, setEmails] = useState([]);
    const [isTestEmail, setIsTestEmail] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const classes = useStyles();

    const handleAddition = (newEmails) => {
        const uniqueNewEmails = newEmails?.filter((newEmail) => !emails?.some((email) => email === newEmail));
        setEmails((prevEmails) => [...prevEmails, ...uniqueNewEmails]);
        // console.log('done')
    };

    const handleDelete = (emailToDelete) => {
        setEmails(emails?.filter((email) => email !== emailToDelete));
        formik.setFieldValue('test_emails', emails?.filter((email) => email !== emailToDelete));
        // console.log('delete')
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        //    console.log('value')
    };

    const handleInputKeyDown = (event) => {
        if ((event.key === 'Enter' || event.key === 'Tab') && inputValue.trim() !== '') {
            const newEmail = inputValue;
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailPattern?.test(newEmail)) {
                setEmails([...emails, newEmail]);
                formik.setFieldValue('test_emails', [...emails, newEmail])
            }
            setInputValue('');
            // console.log('add')
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter key press
        }
    };

    const handleCheckChange = (event) => {
        event.target.checked == false && setEmails([]);
        setIsTestEmail(event.target.checked);
        formik.setFieldValue('is_email_sent', event.target.checked)
    };

    const EmptyPopper = () => null;

    return (
        <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '40px 10px 10px 10px', flex: 1 }}>
            <b style={{ margin: 0, fontSize: '21px', margin: '16px' }}>Create Tranche </b>
            <div className="modal-body" style={{ padding: '0 1rem',marginTop:'2rem' }}>
                <p>Are you sure you want to create this tranche?</p>
                <p><b>Note:</b> Investors will be notified by email.</p>
                <Grid container spacing={2} paddingTop={0}>
                    <Grid item xs={3} sx={{ paddingTop: '0px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isTestEmail}
                                    id='isTestEmail'
                                    onChange={handleCheckChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Test Email"
                        />
                    </Grid>
                </Grid>
                {isTestEmail && (
                    <Grid item xs={9} marginY={1}>
                        <Autocomplete
                            className={`crossdisable email_input ${classes.customInput}`}
                            multiple
                            fullWidth
                            id="email-input"
                            autoComplete="off"
                            name="emails"
                            getOptionLabel={(option) => option}
                            value={emails}
                            options={[]}
                            PopperComponent={EmptyPopper}
                            onChange={(_, newValue) => handleAddition(newValue)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={option}
                                        label={option}
                                        {...getTagProps({ index })}
                                        onDelete={() => handleDelete(option)}
                                    />
                                ))
                            }
                            inputValue={inputValue}
                            onInputChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            renderInput={(params) => <TextField type='email' {...params} label="Email" placeholder="Enter Email" />}
                        />
                    </Grid>
                )}
            </div>
            <div className="modal-footer">
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <Button sx={{ backgroundColor: theme.palette.success.main, color: '#fff', ":hover": { backgroundColor: theme.palette.success.dark } }} onClick={() => handleConfirm(activeRow)}>
                            {isTestEmail ? 'Send Test Email' : 'Confirm'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button sx={{ backgroundColor: theme.palette.error.main, color: '#fff', ":hover": { backgroundColor: "#ee3535" } }} onClick={handleClose}>Cancel</Button>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}

export default DealTranchConfirmationPopup;
