// assets
import { IconHelp } from '@tabler/icons';

// constant
const icons = { IconHelp };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const support = {
    id: 'support1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'support11',
            title: 'Support',
            type: 'item',
            url: `/support`,
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};

export default support;
