// assets
import { IconCoin, IconUsers, IconUpload } from '@tabler/icons';


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

//If any common change do in the admin_investor.js as well//
const investor = {
    id: 'investor1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'investor11',
            title: 'Investors',
            type: 'collapse',
            icon: IconUsers,
            // url: `/investors`,
            breadcrumbs: false,
            children: [
                // {
                //     id: 'investor111',
                //     title: 'Investor Listing',
                //     type: 'item',
                //     icon: IconUsers,
                //     url: `/investors`,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default investor;
