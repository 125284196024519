import { createRoot } from 'react-dom/client';
import './assets/scss/bootstrap.css'
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import 'assets/scss/responsive.scss';
import config from './config';
import { PermissionsProvider } from 'store/PermissionsContext';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const queryClient = new QueryClient()

root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <PermissionsProvider>
                <BrowserRouter basename={config.basename}>
                    <App />
                </BrowserRouter>
            </PermissionsProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
    </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
