// assets
import { IconExchange, IconArrowsExchange, IconArrowsExchange2, IconRocket } from '@tabler/icons';


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const deal = {
    id: 'deal1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'deal11',
            title: 'Deals',
            type: 'item',
            icon: IconRocket,
            url: `/deals`,
            breadcrumbs: false
        }
    ]
};

export default deal;
