import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import { Grid, Box, InputAdornment, IconButton, Paper  } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import SelectField from 'ui-component/SelectField';
import TextField from 'ui-component/TextField';
import CustomButton from 'ui-component/CustomButton';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Pagination from '@mui/material/Pagination';
import { FetchAllArticles } from 'api';
import { useTheme } from '@mui/material/styles';
import { IconEdit, IconThumbUp, IconFileSearch } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import Clickable from 'utils/Clickable';
import { Tooltip } from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import Card from 'ui-component/cards/Card';
import Loading from 'views/Loading';

const columns = [
  // {
  //   label: 'Startup ID',
  //   minWidth: 100,
  //   align: 'left',
  //   type: '_id'
  // },
  {
    label: 'Short Title',
    minWidth: 200,
    align: 'left',
    type: 'short_heading',
    trim: 60,
  },
  {
    label: 'Long Title',
    minWidth: 200,
    align: 'left',
    type: 'heading',
    link: true,
    trim: 60,
  },
  {
    label: 'Featured',
    minWidth: 90,
    align: 'center',
    type: 'is_featured'
  },
  // {
  //   label: 'Status',
  //   minWidth: 100,
  //   align: 'left',
  //   type: 'status'
  // },
  {
    label: 'Publish Date',
    minWidth: 170,
    align: 'left',
    type: 'publish_date_format',
    sort: true,
  },
  {
    label: 'Actions',
    minWidth: 160,
    align: 'center',
    type: 'actions'
  },
];

export default function StartListing() {
  const [search, setSearch] = useState('');
  const [stage, setStage] = useState('');
  // const [status, setStatus] = useState('');
  const [searchStatus, setSearchStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [reset, setreset] = useState(false)
  const navigate = useNavigate();
  const theme = useTheme();
  const [sort, setSort] = useState(-1);
  const [limit, setLimit] = useState(20);

  const { isLoading: isStartupLoading, isRefetching:isStartupRefetching , data: startupsData, refetch:refetchListing } = FetchAllArticles({page, search: search.trim(), sort, limit})
  useEffect(() => {
    refetchListing()
  }, [reset])
  
  const InvestorLink = ({row, children}) => {
    return <Link to={`/resources/articles/${row.article_id}/articleForm`} style={{textDecoration: "none", color: '#616161', fontWeight:700}}>
      {children}
    </Link>
  }

  const StartupActions = ({row}) => {
    // console.log("row", row);
    return (
      <div style={{display: 'flex', justifyContent:'center', columnGap:"12px"}}>
        {/* <CustomButton size='small' onClick={()=>navigate(`/startups/${row._id}/addDeal`)}>Create Deal</CustomButton> */}
        <Clickable style={{display: 'flex', alignItems:'center'}} onClick={()=>navigate(`/resources/articles/${row.article_id}/articleForm`)}><Tooltip title="Edit"><div style={{display: 'flex', alignItems:'center'}}><IconEdit stroke={1.5} size="1.4rem"/></div></Tooltip></Clickable>
        {/* {row.status=="active" ? <Clickable 
          style={{display: 'flex', alignItems:'center'}} 
          onClick={()=>{
            updateStartupStatus({id: row._id, status:'inactive'}, {
              onSuccess:()=>refetchListing()
            })
          }}
        >
          <Tooltip title="Soft Delete">
            <div style={{display: 'flex', alignItems:'center'}}>
              <DeleteIcon stroke={1.5} size="1.4rem" color='error'/>
            </div>
          </Tooltip>
        </Clickable>:<Clickable 
          style={{display: 'flex', alignItems:'center'}} 
          onClick={()=>{
            updateStartupStatus({id: row._id, status:'active'}, {
              onSuccess:()=>refetchListing()
            })
          }}
        >
          <Tooltip title="Soft Delete">
            <div style={{display: 'flex', alignItems:'center'}}>
              <SettingsBackupRestoreIcon stroke={1.5} size="1.4rem" color='primary'/>
            </div>
          </Tooltip>
        </Clickable>} */}
      </div>
    );
  }



  return (
    <>
      <Box sx={{marginBottom: '12px'}}>
        <BreadcrumbHeader title={`Articles ${startupsData?.count ? `(${startupsData?.count})` : ''}`} />
      </Box>
      <Card style={{ paddingLeft: '16px', paddingRight: '16px'}}>
        <Grid container direction="row" columnSpacing={1} rowSpacing={2} mb={1}>
          <Grid item sm={3} xs={8}>
            <TextField 
              label="Search" 
              value={search}
              autoComplete='off'
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  // alert(e.target.value);
                  refetchListing()
                  if(search){
                    setSearchStatus(true)
                  }
                }
              }}
              onChange={(event)=> {
                setSearch(event.target.value)
                setSearchStatus(false)
              }} 
              size="small"
              sx={{
                '& input': {
                  background: theme.palette.background.paper,
                  // background: '#f9f9fc8e'
                },
                '& input:valid + fieldset': {
                  borderColor: theme.palette.secondary[200],
                  borderWidth: 0.5,
                },
                '& input:valid:hover + fieldset': {
                  borderColor: '#4050b5',
                  borderWidth: 1,
                },
                '& input:valid:focus + fieldset': {
                  borderColor: '#4050b5',
                  padding: '4px !important', 
                  borderWidth: 1,
                  borderLeftWidth: 6,
                },
              }}
            />
          </Grid>
          <Grid item sm={1} xs={4} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} className='search-btn'>
            {/* <CustomButton size="large" onClick={()=>navigate('/startups/addstartup')} fullWidth sx={{height:'100%'}}>Add startup</CustomButton> */}
            <CustomButton 
              size="medium" 
              sx={{height:'40px'}}
              color={ (searchStatus && search!=='') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main] }
              onClick={()=>{
                if(searchStatus && search!==''){
                  setSearch('')
                  setStage('')
                  // setStatus('')
                  setreset(!reset)
                }else{
                  refetchListing()
                }
                setSearchStatus((oldVal)=>!oldVal)
                
              }} 
              fullWidth 
            >
              {(searchStatus && search!=='') ? "Reset" : "Search"}
            </CustomButton>
          </Grid>
          <Grid item sm={8} xs={12} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <CustomButton size="medium" onClick={()=>navigate(`/resources/articles/articleForm`)} sx={{height:'40px'}}>Add Article</CustomButton>
          </Grid>
          </Grid>
        {isStartupLoading ? <Loading />
        :<AsyncTableListing 
        title="" 
        // titleContainer={<CustomButton size="medium" onClick={()=>navigate(`/startups/addStartup`)} sx={{height:'50px'}}>Add startup</CustomButton>}
        columns = {columns}
        data={startupsData.data}
        actions={StartupActions}
        // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(startupsData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
        page={page}
        setPage={setPage}
        count={startupsData.count}
        // limit={startupsData.limit}
        cutomLink={InvestorLink}
        sort={sort}
        setSort={setSort}
        limit={limit}
        setLimit={setLimit}
        />}
      </Card>
    </>
  );
}