
import { Paper, Box, Typography, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { UploadTransaction, FetchTransactionDetail, FetchAllPaymentModesTypes, ApprovedInvestors, FetchLeadInvestorsListByDealID } from '../../api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import Loading from 'views/Loading';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { Tooltip } from '@mui/material';
import CustomAutoComplete from 'ui-component/formik/CustomAutoComplete';
import SelectField from 'ui-component/SelectField';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useState } from 'react';
import AsyncButton from 'ui-component/AsyncButton'
import { values } from 'lodash';


const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '10px 0 0 10px',
        borderRightWidth: '0px'
      },
    },
  },
})(TextField);

const validationSchema = yup.object({
  investment_interest_amount: yup
    .string('Enter Min Investment Amount')
    .required('Min Investment Amount is required')
    .trim(),
  funds_transferred_amount: yup
    .string('Enter Max Investment Amount')
    .required('Max Investment Amount is required')
    .trim(),
  investment_interest_date: yup
    .string('Enter Closing Date')
    .required('Closing Date is required')
    .trim(),
  funds_transferred_date: yup
    .string('Enter Closing Date')
    .required('Closing Date is required')
    .trim(),
  pitch_recording: yup
    .string('Enter Pitch Recording')
    .required('Pitch Recording is required')
    .trim(),
});

const inputProps = {
  min: 0,
  onKeyDown: (event) => {
    // Prevent the minus sign (-) from being entered
    if (event.key === '-') {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
    if (event.key === '-') {
      event.preventDefault();
    }
  },
  inputMode: 'numeric',
  pattern: '[0-9.]*',
  onKeyPress: (event) => {
    // Get the input value
    const inputValue = event.key;

    // Allow only numeric characters (0-9) and certain control keys
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(inputValue)) {
      // If not, prevent the key from being entered
      event.preventDefault();
    }
  },
  onInput: (event) => {
    // Get the input value
    const inputValue = event.target.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = inputValue.replace(/[^0-9.]/g, '');

    // Update the input field value with the filtered numeric value
    event.target.value = numericValue;
  },
  onKeyDown: (event) => {
    // Prevent the input of minus sign (key code 189)
    if (event.keyCode === 189 || event.key === "-") {
      event.preventDefault();
    }
  },
}

const initialValues = {
  investment_interest_amount: '',
  funds_transferred_amount: '',
  received_amount: '',
  investment_interest_date: null,
  funds_transferred_date: null,
  investor_name: "",
  allocated_amount: "",
  is_unallocated: false,
  unallocated_amount: 0,
  percentage: 0.00,
  payments: {
    payment_mode: '',
    utr_number: ''
  },
  remark: '',
  investor_id: '',
  lead_investor: [],
  is_allocation_email: false,
  is_email_sent: false
}

const TransactionPopup = ({open, dealId, handleClose, activeRow, refetchTransactions, refetchApprovedInvestorsData, dealsModule, dealDetailData }) => {
  const theme = useTheme();
  //   const {
  //     isLoading: isApprovedInvestorsLoading,
  //     isRefetching: isApprovedInvestorsRefetching,
  //     data: ApprovedInvestorsData,
  //     // refetch: refetchApprovedInvestorsData
  // } = ApprovedInvestors({});
  const { isLoading: isTransactionDetailLoading, isRefetching: isTransactionDetailRefetching, data: transactionDetail } = FetchTransactionDetail(activeRow.transactionId, 'edit');
  const { mutateAsync: uploadTransaction, isLoading: isUploadingTransactions } = UploadTransaction();
  const {
    isLoading: isCompletedDealsLoading,
    isRefetching: isCompletedDealsRefetching,
    data: completedDealsData,
    refetch: refetchListing,
    status: dealStatus
  } = FetchAllPaymentModesTypes();
  const {
    isLoading: isLeadInvestorListLoading,
    isRefetching: isLeadInvestorListRefetching,
    data: LeadInvestorListData,
    refetch: refetchLeadInvestorListData
  } = FetchLeadInvestorsListByDealID(dealId);
  const paymentModes = Array.isArray(completedDealsData) ? completedDealsData : [];
  const [leadInvestorDetails, setLeadInvestorDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  // console.log(paymentModes,"do");
  // const [image, setImage] = useState(null);
  // const fileRef = useRef(null);
  // const customization = useSelector((state) => state.customization);
  // const {refetch} = FetchShowcaseQuery(page, "startup_showcase");
  // const {mutateAsync} = MoveToInvestmentInterest();
  // console.log(activeRow)
  const formik = useFormik({
    initialValues: !isTransactionDetailLoading ? transactionDetail : initialValues,
    // validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { investment_interest_date, funds_transferred_date, investor_name, unallocated_amount, investment_interest_amount, funds_transferred_amount, is_trans_added, received_amount, remaining_amount, percentage, allocated_amount, ...newValues } = values;
      const payload = {
        ...newValues,
        startup_deal_id: dealId,
        is_unallocated: values.is_unallocated ? "Yes" : "No",
        is_allocation_email: !!values?.is_allocation_email,
        is_email_sent: !!values?.is_email_sent
      }
      if (values.investment_interest_date) {
        payload['investment_interest_date'] = values.investment_interest_date
      } else {
        payload['investment_interest_date'] = ""
      }
      // if (values?.funds_transferred_date) {
      //   payload['funds_transferred_date'] = values?.funds_transferred_date
      // }
      // if (values.allocated_amount) {
      //   payload['allocated_amount'] = values.allocated_amount
      // }
      if (values.investment_interest_amount) {
        payload['investment_interest_amount'] = values.investment_interest_amount
      } else {
        payload['investment_interest_amount'] = ""
      }
      if (values.unit_class_id) {
        payload['unit_class_id'] = values?.unit_class_id
      }
      if (values.received_amount) {
        payload['funds_transferred_amount'] = values.received_amount
        delete payload.received_amount
      }
      if (values?.remaining_amount) {
        delete payload?.remaining_amount
      }
      if (values?.is_trans_added) {
        delete payload?.is_trans_added
      }
      if (dealsModule?.received_amount_add_edit == undefined || dealsModule?.received_amount_add_edit == null || dealsModule?.received_amount_add_edit == false) {
        delete payload?.funds_transferred_amount;
        // delete payload?.funds_transferred_date
      }
      if (values.lead_investor || leadInvestorDetails || values.lead_investor_id) {
        let temp = values.lead_investor != undefined ? values?.lead_investor?.map(item => item?._id) : (leadInvestorDetails != null ? leadInvestorDetails?.map(item => item?._id) : transactionDetail?.lead_investor_id != undefined ? transactionDetail?.lead_investor_id : null)
        // temp.push()
        payload['lead_investor_id'] = temp;
        temp = [];
        delete payload?.lead_investor;
      }
      if (!values.lead_investor && !leadInvestorDetails && !values.lead_investor_id) {
        payload['lead_investor_id'] = []
        delete payload?.lead_investor;
      }
      if (!dealsModule?.received_amount_add_edit) {
        delete payload.funds_transferred_amount;
      }
      delete payload.funds_transferred_date;
      // if (values.payments.utr_number) {
      //   payload['payments.utr_number'] = values.payments.utr_number
      // }
      // if (typeof (values.payments) == 'object' && values?.payments) {
      //   payload["payments[payment_mode]"] = values.payments?.payment_mode
      // } else {
      //   if (typeof (values.payments) == 'string') {
      //     payload["payments[payment_mode]"] = values.payments.payment_mode
      //   }
      // }

      // console.log("payload", [payload])
      uploadTransaction([payload], {
        onSuccess: () => {
          refetchTransactions();
          refetchApprovedInvestorsData()
          toast.success(payload?.is_email_sent ? "Test Email is sent" : 'Transaction Updated', {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          },5000)
          handleClose()
        },
        onError: (err) => {
          toast.error(JSON.parse(err.request.response).message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          },5000)
        }
      })
    },
  });

  useEffect(() => {
    if (!isTransactionDetailLoading && !isLeadInvestorListLoading && transactionDetail?.lead_investor_id) {
      setIsLoading(true);
      const selectedInvestors = LeadInvestorListData?.filter(item =>
        transactionDetail?.lead_investor_id?.includes(item?._id)
      );
      setLeadInvestorDetails(selectedInvestors);
      formik.setFieldValue('lead_investor', selectedInvestors);
      setTimeout(() => {
        setIsLoading(false)
      },1000)
    }
  }, [isTransactionDetailLoading, isLeadInvestorListLoading, transactionDetail, LeadInvestorListData, activeRow?.transactionId, open]);

  if (isTransactionDetailLoading || isLeadInvestorListLoading) {
    return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
  }
  let newLeadInvestorListData = [null, ...LeadInvestorListData]
  let unitClassOptions = dealDetailData?.unit_class != undefined ? [{ label: 'Select Unit Class', value: '' }, ...dealDetailData?.unit_class?.map(item => ({ label: item?.label, value: item?.unit_class_id }))] : [{ label: 'Select Unit Class', value: '' }]
  // console.log("newLeadInvestorListData",formik.values)
  return (
    <div>
      <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', flex: 1 }}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10 }}>
            <div style={{ marginBottom: '30px' }}>
              <h3 style={{ textAlign: 'center', }}>Save Transaction</h3>
              {!isTransactionDetailLoading && transactionDetail?.lp_code && <p style={{ margin: 0, marginBottom: '10px' }}>LP Code: <b>{transactionDetail?.lp_code}</b></p>}
              {!isTransactionDetailLoading && transactionDetail?.investor_name && <p style={{ margin: 0, marginBottom: '0px' }}>Investor Name: <b>{transactionDetail?.investor_name}</b></p>}
              <Grid item container gap={1} sx={{ marginTop: '10px', marginLeft: '-12px' }}>
                <Grid item>
                  {formik.values?.unallocated_amount > 0 && (formik.values?.is_trans_added === 'Yes' ? false : true) && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox id="current" checked={formik.values?.is_unallocated} onChange={(e) => formik.setFieldValue(`is_unallocated`, e.target.checked)} />
                    <label htmlFor='current' variant="h5" style={{ cursor: 'pointer' }}>Use Balance Amount (₹  {formik.values.unallocated_amount})</label>
                  </Box>}
                </Grid>
                {
                // transactionDetail?.investor_status == 'approved' &&
                 dealsModule?.received_amount_add_edit && (formik.values?.received_amount != null && formik.values?.received_amount != '') && <> <Grid item marginLeft={1}>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Checkbox
                                                                                    id="is_allocation_email"
                                                                                    checked={formik.values?.is_allocation_email}
                                                                                    onChange={(e) => {
                                                                                        const isChecked = e.target.checked;
                                                                                        formik.setFieldValue(`is_allocation_email`, isChecked);
                                                                                        // If "Send Allocation Email" checkbox is checked, uncheck the other checkbox
                                                                                        if (isChecked) {
                                                                                            formik.setFieldValue(`is_email_sent`, false);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    htmlFor="is_allocation_email"
                                                                                    variant="h5"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    Send Allocation Email
                                                                                </label>
                                                                            </Box>
                                                                        </Grid>
                                                                            <Grid item marginLeft={2}>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Checkbox
                                                                                        id="is_email_sent"
                                                                                        checked={formik.values?.is_email_sent}
                                                                                        onChange={(e) => {
                                                                                            const isChecked = e.target.checked;
                                                                                            formik.setFieldValue(`is_email_sent`, isChecked);
                                                                                            // If "Send Test Allocation Email" checkbox is checked, uncheck the other checkbox
                                                                                            if (isChecked) {
                                                                                                formik.setFieldValue(`is_allocation_email`, false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="is_email_sent"
                                                                                        variant="h5"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        Send Test Allocation Email
                                                                                    </label>
                                                                                </Box>
                                                                            </Grid>
                                                                        </>}
              </Grid>
              {(parseFloat(formik.values?.percentage) > 90) && <p style={{ margin: '8px 0px', fontWeight: '700', fontSize: '0.9rem', color: 'rgb(248, 144, 97)' }}>Warning: Investor commitment exceeded 90%. Proceed with new contribution agreement or increase commitment amount.</p>}
            </div>
            <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
              <Grid item sm={6} xs={12}>
                <Tooltip title={convertToIndianCurrencyWords(formik.values?.investment_interest_amount)}>
                  <CustomField inputProps={inputProps} name="investment_interest_amount" label="Investment Interest Amount" />
                </Tooltip>
              </Grid>
              {dealsModule?.received_amount_add_edit && <Grid item sm={6} xs={12}>
                <Tooltip title={convertToIndianCurrencyWords(formik.values?.received_amount)}>
                  <CustomField inputProps={inputProps} name="received_amount" label="Received Amount" onChange={(e) => formik.setFieldValue('received_amount', e.target.value)} disabled={formik.values?.is_trans_added === 'Yes' ? true : false} value={formik.values?.received_amount} />
                </Tooltip>
              </Grid>}
              <Grid item sm={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Investment Interest Date"
                    value={formik.values?.investment_interest_date}
                    // disablePast={true}
                    onChange={(newValue) => formik.setFieldValue('investment_interest_date', moment(newValue).format('YYYY-MM-DD'))}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    inputFormat="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              {/* {dealsModule?.received_amount_add_edit &&<Grid item sm={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Funds Received Date"
                    value={formik.values?.funds_transferred_date}
                    // disablePast={true}
                    onChange={(newValue) => formik.setFieldValue('funds_transferred_date', newValue ? moment(newValue).format('YYYY-MM-DD') : null)}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    inputFormat="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>}
              <Grid item sm={6} xs={12}>
                <CustomField
                  // inputProps={inputProps}
                  label="UTR Number"
                  name="payments.utr_number"
                  value={formik.values.payments?.utr_number || ''}
                  />
              </Grid>

          
              <Grid item  sm={6} xs={12}>
                <Autocomplete
                  value={paymentModes.find((option) => option.value === formik.values.payments?.payment_mode) || null}
                  onChange={(event, newValue) => {
                    // setValue(newValue);
                     const selectedValue = newValue && typeof newValue === 'object' ? newValue.value : newValue;                                            
                        formik.setFieldValue(
                          'payments.payment_mode',
                          selectedValue
                          )
                        }}
                        onBlur={(event) => {
                          const newValue = event.target.value;
                            formik.setFieldValue(
                              'payments.payment_mode',
                              newValue
                              )
                        }}
                        name='payments.payment_mode'
                        options={paymentModes}
                        getOptionLabel={(option) => option.label}
                        freeSolo  // Add this prop to allow free text input
                        renderInput={(params) => (
                        <TextField {...params} label="Payment Mode" />
                  )}
                />
              </Grid> */}
              {/* <Grid item sm={6} xs={12}>
                <Tooltip title={convertToIndianCurrencyWords(formik.values?.allocated_amount)}>
                  <CustomField inputProps={inputProps} name="allocated_amount" label="Allocated Amount" value={formik.values?.allocated_amount} />
                </Tooltip>
              </Grid> */}

<Grid item sm={6} xs={12}>
                {!isTransactionDetailLoading && transactionDetail && <SelectField
                  name={`unit_class_id`}
                  label="Unit Class"
                  // className={`${classes.customSelectBox}`}
                  defaultValue={transactionDetail?.unit_class_id}
                  value={formik.values?.unit_class_id}
                  onChange={(e) => { formik.setFieldValue(`unit_class_id`, e.target.value) }}
                  options={unitClassOptions}
                />}
              </Grid>
              {!isTransactionDetailLoading && transactionDetail != undefined && !isLoading &&
     dealDetailData?.unit_class?.find(item => item?.unit_class_id == transactionDetail.unit_class_id)?.type !== 'Lead Investor' && 
     LeadInvestorListData?.length > 0 && (
      <Grid item sm={6} xs={12}>
        <CustomAutoComplete
          label="Lead Investor"
          name="lead_investor"
          multiple
          value={formik.values?.lead_investor_id?.length > 0 ? leadInvestorDetails : formik.values.lead_investor}
          options={LeadInvestorListData}
          getOptionLabel={(option) => {
            if (option != null) {
              return `${option?.name ?? ''} ${
                option?.lp_code
                  ? `(${option?.lp_code})`
                  : option?.kyc_status != undefined
                  ? `(${option?.kyc_status})`
                  : ''
              }`;
            }
          }}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option?.name ?? 'Select Lead Investor'}
              {option?.lp_code ? `(${option?.lp_code})` : option?.kyc_status != undefined ? `(${option?.kyc_status})` : ''}
            </Box>
          )}
          onChange={(e, newValue) => {
            // console.log(newValue,"newValue")
            setLeadInvestorDetails(newValue);
            formik.setFieldValue('lead_investor', newValue);
          }}
        />
      </Grid>
    )}
              <Grid item xs={12}>
                <Typography required variant='subtitle1' mb={1} mt={2}>Remark</Typography>
                <CustomTextArea name="remark" multiline={true} minRows={2} sx={{ borderWidth: 2 }} />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <AsyncButton loading={isUploadingTransactions} type="submit">Submit</AsyncButton>
            </Grid>
          </Form>
        </FormikProvider>
      </Paper>
    </div>
  );
}

export default TransactionPopup;
