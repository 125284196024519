import { Autocomplete, Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, Paper, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import Loading from 'views/Loading';
const useStyles = makeStyles({
    customInput: {
        '&  > div.MuiInputBase-root': {
            padding: 0
        }
    }
});

const SendPartnerEmailPopup = ({ handleClose, title = "Title", loading = false, content = "", note = "", sendTestEmail = false, emailArr = false, activeRow, refetchTransactions, handleConfirm }) => {
    const theme = useTheme();
    const [emails, setEmails] = useState([]);
    const [isTestEmail, setIsTestEmail] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const classes = useStyles();
    const handleAddition = (newEmails) => {
        const uniqueNewEmails = newEmails?.filter((newEmail) => !emails?.some((email) => email === newEmail));
        setEmails((prevEmails) => [...prevEmails, ...uniqueNewEmails]);
    };
    const handleDelete = (emailToDelete) => {
        setEmails(emails?.filter((email) => email !== emailToDelete));
    };
    const handleInputChange = (event, newInputValue) => {
        // console.log(newInputValue)
        setInputValue(newInputValue);
    };
    const handleInputKeyDown = (event) => {
        if ((event.key === 'Enter' || event.key === 'Tab') && inputValue.trim() !== '') {
            const newEmail = inputValue;
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailPattern?.test(newEmail)) {
                setEmails([...emails, newEmail]);
            }
            setInputValue('');
            // console.log('add')
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter key press
        }
    };
    const handleCheckChange = (event) => {
        event.target.checked == false && setEmails([]);
        setIsTestEmail(event.target.checked);
    };
    // console.log(activeRow, "ActiveRow")
    const EmptyPopper = () => null;
    return (
        <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '40px 10px 10px 10px', flex: 1 }}>
            {/* {loading ? <Loading /> : <> */}
                <b style={{ margin: 0, fontSize: '21px', margin: '16px' }}>{title}</b>
                <div className="modal-body" style={{ padding: '0 1rem', marginTop: '2rem' }}>
                    <p>{content}</p>
                    {note != "" && <p><b>Note:</b> Investors will be notified by email.</p>}
                    {sendTestEmail && <>
                        <Grid container spacing={2} paddingTop={0}>
                            <Grid item xs={12} sx={{ paddingTop: '0px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isTestEmail}
                                            id='isTestEmail'
                                            onChange={handleCheckChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Send Test Email"
                                />
                            </Grid>
                        </Grid>
                        {isTestEmail && emailArr && (
                            <Grid item xs={9} marginY={1}>
                                <Autocomplete
                                    className={`crossdisable email_input ${classes.customInput}`}
                                    multiple
                                    autoComplete="off"
                                    fullWidth
                                    id="email-input"
                                    name="emails"
                                    getOptionLabel={(option) => option}
                                    value={emails}
                                    options={[]}
                                    PopperComponent={EmptyPopper}
                                    onChange={(_, newValue) => handleAddition(newValue)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option}
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={() => handleDelete(option)}
                                            />
                                        ))
                                    }
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onKeyDown={handleInputKeyDown}
                                    renderInput={(params) => <TextField type='email' {...params} label="Email" placeholder="Enter Email" />}
                                />
                            </Grid>
                        )}
                    </>}
                </div>
                <div className="modal-footer">
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <Button disabled={loading} sx={{ backgroundColor: theme.palette.success.main, color: '#fff', ":hover": { backgroundColor: theme.palette.success.dark } }} onClick={() => handleConfirm(activeRow, isTestEmail, emails)}>
                                {loading ? <CircularProgress size={"1.5rem"} sx={{color: 'white'}} /> : <> {isTestEmail && sendTestEmail ? 'Send Test Email' : 'Confirm'} </>}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{ backgroundColor: theme.palette.error.main, color: '#fff', ":hover": { backgroundColor: "#ee3535" } }} onClick={handleClose}>Cancel</Button>
                        </Grid>
                    </Grid>
                </div>
            {/* </>} */}
        </Paper>
    );
}
export default SendPartnerEmailPopup;