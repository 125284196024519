// assets
import { IconDeviceDesktop, IconCategory } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard11',
            title: 'Dashboard',
            type: 'item',
            url: ``,
            icon: IconCategory,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
