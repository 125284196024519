// assets
import { IconMessage2 } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const feedback = {
    id: 'feedback1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'feedback11',
            title: 'Feedbacks',
            type: 'item',
            url: `/resources/feedbacks`,
            icon: IconMessage2,
            breadcrumbs: false
        }
    ]
};

export default feedback;
