import React from 'react'

const VpFeesData = () => {
  return (
    <>
        
        <div className='mt-3'>
            <div className='d-flex mb-3'>
                <div>
                    <input type='text' className='form-control' placeholder='Search'/>
                </div>
                <div className='ms-3'>
                    <button className='button-79' type='button'>Search</button>
                </div>
            </div>
            <div className='custom-table'>
                <table className='w-100'>
                    <thead>
                        <tr>
                            <th style={{width: "15%"}}>LP Code</th>
                            <th style={{width: "22%"}}>Name</th>
                            <th style={{width: "25%"}}>Email</th>
                            <th style={{width: "15%"}}>Type</th>
                            <th style={{width: "20%"}}>Fee %</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>LP-213</td>
                            <td>Kushal Tandon</td>
                            <td>kushal02@mailinator.com</td>
                            <td>VP</td>
                            <td>
                                <input type='text' className='form-control text-center w-70'/> %
                            </td>
                        </tr>
                        <tr>
                            <td>LP-213</td>
                            <td>Kushal Tandon</td>
                            <td>kushal02@mailinator.com</td>
                            <td>Partner</td>
                            <td>
                                <input type='text' className='form-control text-center w-70'/> %
                            </td>
                        </tr>
                        <tr>
                            <td>LP-213</td>
                            <td>Kushal Tandon</td>
                            <td>kushal02@mailinator.com</td>
                            <td>VP</td>
                            <td>
                                <input type='text' className='form-control text-center w-70'/> %
                            </td>
                        </tr>
                        <tr>
                            <td>LP-213</td>
                            <td>Kushal Tandon</td>
                            <td>kushal02@mailinator.com</td>
                            <td>Partner</td>
                            <td>
                                <input type='text' className='form-control text-center w-70'/> %
                            </td>
                        </tr>
                        <tr>
                            <td>LP-213</td>
                            <td>Kushal Tandon</td>
                            <td>kushal02@mailinator.com</td>
                            <td>VP</td>
                            <td>
                                <input type='text' className='form-control text-center w-70'/> %
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='mt-2 d-flex'>
                    <div style={{width: "80%"}}></div>
                    <div style={{width: "20%", paddingLeft:"15px"}}>
                        <button className='button-79' type='button'>Save All</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default VpFeesData