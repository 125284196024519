import { useState, useEffect, useRef,lazy } from 'react';
import { useTheme } from '@mui/material/styles';
import React from 'react'
import { Grid, TextField, Typography, Box, IconButton, Popover, Rating, Switch } from '@mui/material'
import { useFormik, Form, Formik, FormikProvider, FieldArray, Field } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import { IconEdit, IconRocket } from '@tabler/icons';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useParams, useNavigate } from 'react-router';
import {FetchMasterTemplateDealDetails, UpdateDealChecklistTask } from '../../api'
import topArrow from '../../assets/images/up-arrow.svg'
import downArrow from '../../assets/images/down-arrow.svg'
import { toast, ToastContainer } from 'react-toastify';
import { Cancel, CloseRounded } from '@mui/icons-material';
import moment from 'moment';
import AttachChecklist from './AttachChecklist';
import VpFeesData from './VpFeesData';
import VpFees from 'views/fees/VpFees';
import Loadable from 'ui-component/Loadable';
import TargetFees from 'views/fees/TargetFees';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';
import PartnerFees from 'views/fees/PartnerFees';
import MessageTemplatePreview from './MessageTemplatePreview';


const initialValues = {
    checklist_master_id: "",
    tasks: [],
    checklist_period:"",
}


export default function Messages() {
    const { id: deal_id } = useParams()
    const [template_id, templateId] = useState(null);
    const [template_name, setTemplateName] = useState("");
    const [fromik_values, formikValues] = useState(initialValues);
    const [selected_template_id, setTemplateId] = useState(null);
    const navigate = useNavigate();
    const docRef = useRef()
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    const { mutateAsync: UpdateDealChecklist, status: UpdateDealChecklistTaskStatus, error: UpdateDealChecklistTaskError } = UpdateDealChecklistTask();
    const { isLoading: ismasterTemplateLoading, data: masterTemplateData, status: masterTemplateStatus } = FetchMasterTemplateDealDetails(deal_id, selected_template_id)

    // console.log(masterTemplateData,"masterTemplateData")

    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const updatedTasks = values.tasks.map((task, index) => ({
                ...task,
                display_order: index + 1,
            }));
            values.tasks = updatedTasks;

            // console.log(values);
            // UpdateMasterTemplate({ payload: values, id: master_id })

            UpdateDealChecklist({ payload: values, id: deal_id }
                // onSuccess: () => {
                //     toast.success('Template Create!', {
                //         position: "top-left",
                //         autoClose: 1000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "colored",
                //         onClose: () => {
                //             navigate('/deals')
                //         }
                //     });
                // },
                // onError: (err) => {
                //     toast.error(JSON.parse(err.request.response).message, {
                //         position: "top-left",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "colored",
                //     })
                // }
            )
        }
    })


    // useEffect(() => {
    //     if (masterTemplateStatus === 'success' && masterTemplateData) {
    //         formikValues({
    //             checklist_master_id: selected_template_id,
    //             tasks: masterTemplateData?.tasks,
    //             checklist_period: masterTemplateData?.checklist_period
    //         });
    //     }
    // }, [masterTemplateStatus, masterTemplateData]);

    // useEffect(() => {
    //     if (UpdateDealChecklistTaskStatus == 'success') {
    //         formik.resetForm()
    //         toast.success('Checklist Updated', {
    //             position: "top-left",
    //             autoClose: 4000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //             onClose: () => {
    //                 navigate('/deals')
    //             }
    //         });
    //     }
    //     if (UpdateDealChecklistTaskStatus == 'error') {
    //         toast.error(UpdateDealChecklistTaskError?.response?.data?.message, {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //         });

    //     }
    // }, [UpdateDealChecklistTaskStatus])

    return (

        <>
            <div className='custom-card card'>
                <div className='card-body'>
                    <div>
                        <div class="nav nav-tabs custom-nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-add" type="button" role="tab" aria-controls="nav-add" aria-selected="true">Message Template Preview</button>

                            {/* <button class="nav-link" id="target-partnerfees-tab" data-bs-toggle="tab" data-bs-target="#target-partnerfees" type="button" role="tab" aria-controls="target-partnerfees" aria-selected="false">Target Partner Fees </button> */}
                        </div>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-add" role="tabpanel" aria-labelledby="nav-add-tab">
                               <MessageTemplatePreview />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        //  :    <Error404 />
    )
}