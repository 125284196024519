import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';


export const FetchAllSyndicates = ({ page, search, sort = 1, limit = 20 }) => {
  return useQuery(
    ['syndicate-listing', page, sort, limit],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/dasboard/syndicate/list?field=_id&page=${page}&v_no=1&searchText=${search}${sort != 0 ? `&sort=${sort}` : ""}&limit=${limit}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
      })
    }, {
    select: (data) => {
      // console.log(data.data.data.fundSummary,"datatatatt")
      return { data: data?.data?.data?.fundSummary, count: data?.data?.count }
    },
    refetchOnWindowFocus: true,
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const FetchAllSyndicatesOptions = () => {
  return useQuery(
    ['syndicate-option'],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/syndicate`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
      })
    }, {
    select: (data) => {
      // console.log(data.data.data);
      return data.data.data.map(syndicate => ({ value: syndicate._id, label: syndicate?.syndicate_name }))
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}
//   /admin/syndicate?page=1&searchText=anupam

//   export const AddSyndicates = () => {
//     return useMutation((payload) => {
//       return axios({
//         method: 'post',
//         url: `${process.env.REACT_APP_BASE_URL}/admin/startup`,
//         headers: { 
//           'Authorization': `Bearer ${Cookies.get('jwt')}`,
//           'Content-Type': 'application/json'
//         },
//         data:payload
//       })
//     }, {
//       // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
//       // retry: (failureCount, error)=>{
//       //   return RetryOnError(failureCount, error)
//       // },
//       retry: 0,
//       onError: (err)=>{
//         return verifyToken(err)
//       },
//     }) 
//   }

export const AddSyndicates = () => {
  return useMutation(({ payload }) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/syndicate`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'multipart/form-data'
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}


export const FetchSyndicatesDetails = (id, mode) => {

  return useQuery(
    ['startup-details', id, mode],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/syndicate/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      if (mode === 'edit') {
        const savedData = data.data.data[0]
        const savedValues = {
          name: savedData.name || '',
          syndicate_name: savedData.syndicate_name || '',
          share_url: savedData.share_url || '',
          email: savedData.email || '',
          slug: savedData.slug || '',
          logo: savedData.logo || null,
          password: '1234',
          pan_number: savedData?.pan_number,
          gst_number: savedData?.gst_number,
          gst_certificate: savedData?.gst_certificate || null,
          phone: savedData?.phone || '',
          phone_code: savedData?.phone_code || null,
          // syndicate_fees: savedData.syndicate_fees || '',
          investor_partner_id: !!savedData.investor_partner ? { _id: savedData.investor_partner.investor_partner_id || "", lp_code: savedData.investor_partner.lp_code || '', name: savedData.investor_partner.name || '' } : { _id: '', lp_code: '', name: '' },
        }

        return savedValues
      }
      return data.data.data[0]
    },
    refetchOnWindowFocus: true,
    enabled: !!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}




export const FetchSyndicatesInverstorAllDetails = (id) => {

  return useQuery(
    ['startup-details-investor-all', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/syndicate/investors/list?is_lead_investor=Yes&syndicate_id_str=${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      if (data.data.data.length > 0) {
        return data.data.data.map(investor => ({ value: investor._id, label: `${investor.name}${investor?.lp_code ? ` (${investor?.lp_code})` : ""}` }))

      }
    },
    refetchOnWindowFocus: true,
    enabled: !!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}



export const FetchSyndicatesInvestorDetails = (id) => {
  // console.log(id,"dsdsdddddddddddddddddddddddddddddddddddd")
  return useQuery(
    ['startup-syndicate-investor-detal', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/syndicate/investor/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      console.log("data.data.data.length", data.data.data.length);
      if (data.data.data.length > 0) {
        return data.data.data.map(investor => ({ value: investor._id, label: `${investor.name}${investor?.lp_code ? ` (${investor?.lp_code})` : ""}` }))

      }
      // console.log("data", data.data.data)
      // if(mode==='edit'){
      // const savedData = data.data.data[0]
      // console.log("savedData", savedData)
      //   const savedValues = {
      //     description: savedData.description || '',
      //     email: savedData.email || '',
      //     mobileNumber: savedData.mobile_number || '',
      //     phone_code: savedData?.phone_code || '',
      //     linkedInURL: savedData.linkedln_page_url || '',
      //     facebookURL: savedData.facebook_page_url || '',
      //     instagramURL: savedData.instagram_page_url || '',
      //     twitterURL: savedData.twitter_page_url || '',
      //     website: savedData.website_url || '',
      //     startupValuation: savedData.startup_valuation || '',
      //     companyName: savedData.company_name || '',
      //     overview: savedData.overview || '',
      //     // status: savedData?.startup_status?._id || '',
      //     // comment: savedData.comment || '',
      //     stage: savedData?.stage[0]?._id || '',
      //     sector: savedData.sector?._id || '',
      //     videos: savedData.videos ? savedData.videos.map((video)=>({heading: video.heading, url: video.url})) : [{heading: '', url: ''}],
      //     leadInvestors: savedData.lead_investors ? savedData.lead_investors.map((lead_investor)=>({name: lead_investor.name})) : [{name: ''}],
      //     founders: savedData.founders ? savedData.founders.map((founder)=>({name: founder.name, linkedln_profile_url: founder.linkedln_profile_url})) : [{name: '', linkedln_profile_url: ''}],
      //     companyLogo: {name: savedData.image_name || '', url: savedData.image || ''},
      //     documents: savedData.document ? savedData.document.map((doc)=>({name: doc.name, file: {name: doc.file_name, url: doc.url, doc_id: doc.document_id}})) : [{name:'', file: null}],
      //     city_name: savedData.city_name || '',
      //     lead_investor_id: (savedData?.lead_investor_id && savedData.lead_investor_id.length) ? savedData.lead_investor_id : []
      //   }
      // console.log("data", data.data.data[0])
      // console.log("savedValues", savedValues)

      //   return savedValues
      // }
      return data.data.data
    },
    refetchOnWindowFocus: true,
    enabled: !!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}



// {{inv_api_url}}/admin/syndicate/investor/64786e15e993b478223d91a3



// export const UpdateSyndicateForm = () => {
//     return useMutation(({payload}) => {
//       return axios({
//         method: 'put',
//         url: `${process.env.REACT_APP_BASE_URL}/admin/investor/detail/splash_screen`,
//         headers: { 
//           'Authorization': `Bearer ${Cookies.get('jwt')}`,
//           'Content-Type': 'multipart/form-data'
//         },
//         data:payload
//       })
//     }, {
//       // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
//       // retry: (failureCount, error)=>{
//       //   return RetryOnError(failureCount, error)
//       // },
//       retry: 0,
//       onError: (err)=>{
//         return verifyToken(err)
//       },
//     }) 
//   }


export const UpdateSyndicateForm = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/syndicate/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data: payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchSyndicatesViewDetails = (id) => {

  return useQuery(
    ['syndicate-details', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/detail/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
    select: (data) => {
      return data.data.data[0]
    },
    refetchOnWindowFocus: true,
    enabled: !!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error) => {
      return RetryOnError(failureCount, error)
    },
    onError: (err) => {
      return verifyToken(err)
    },
  })
}

export const FetchSyndicatesInvestorsReport = ({id,page,field = "name",search="", sort=1, limit=20,queryString = ""}) => {
  
  return useQuery(
    ['fetch-syndicates-investors-report', id, sort, limit,page],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/syndicate-investors-report/${id}?page=${page}&field=${field}&limit=${limit}&sort=${sort}&searchText=${search}${queryString}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      return data.data
    },
    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const ChangeSyndicateInvestorDetailsUpdate = () => {
  return useMutation(
      ({payload,id}) => {
          return axios({
              method: 'put',
              url: `${process.env.REACT_APP_BASE_URL}/admin/investor/syndicate-details-update/${id}`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`,
                  'Content-Type': 'application/json'
              },
              data: payload
          });
      },
      {
          retry: 0,
          onError: (err) => {
              // Handle errors or perform actions on error if needed
              console.error('Error generating documents', err);
              throw err; // Re-throw the error for further handling if necessary
          }
      }
  );
};