
import { useState, useRef } from 'react'
import { TextField, Paper, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/SelectField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Clickable from 'utils/Clickable';
import doc2Icon from '../../assets/images/icons/doc2.png'


import pdfIcon from '../../assets/images/pdf.svg'
const DocumentViewPopup = ({ data }) => {
    const theme = useTheme();

    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px',  overflowY: "auto", maxHeight: "90vh" }} >
                <h3 style={{ textAlign: 'center' }}>Documents</h3>
                <ul className="document-list mt-2">
                    {data?.map((item, index) => (
                        <>
                            <li className="d-flex align-items-center">
                                <Clickable className="icon" onClick={()=>window.open(item.url, 'blank')}>
                                <img className='ud-icon'
                                // src={pdfIcon} 
                                src={ item?.type?.split('.').pop()  == "docx" ?  doc2Icon :pdfIcon }
                                alt="pdf"
                                />
                                </Clickable>
                                <Clickable className="ms-2" onClick={()=>window.open(item.url, 'blank')}>
                                 <div className="xs-title font-16 text-start">{item.name}</div>
                                 <div className="sm-title font-12 text-start mt-1">{item.date_format}</div>
                               </Clickable>
                            </li>
                        </>
                    ))}
                </ul>
            </Paper>
        </>
    );
}

export default DocumentViewPopup;
