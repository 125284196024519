import { useState, useEffect, useRef } from 'react';
import { TextField, Paper, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router';
import { UpdateDealTask, FetchTaskDataDeal ,DeleteDChecklistDocument} from '../../api';
import { toast, ToastContainer } from 'react-toastify';
import Loading from 'views/Loading';
import SelectField from 'ui-component/formik/SelectField';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Autocomplete from '@mui/material/Autocomplete';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoAddCircleSharp } from 'react-icons/io5';
import Modal from 'ui-component/Modal';
import UploadMenuPopUp from './../UploadDocuments/UploadMenuPopUp';
import fileIcon from '../../assets/images/icons/folder.png'
import pdfIcon from '../../assets/images/icons/pdf.png'
import xlsxIcon from '../../assets/images/icons/xlsx.png'
import imageIcon from '../../assets/images/icons/image.png'
import docIcon from '../../assets/images/icons/doc.png'
import wordIcon from '../../assets/images/icons/word.png'
import pngIcon from '../../assets/images/icons/png.png'
import doc2Icon from '../../assets/images/icons/doc2.png'
import pdf2Icon from '../../assets/images/icons/pdf-file.png'
import jpgIcon from '../../assets/images/icons/jpg.png'
import Clickable from 'utils/Clickable';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import DeleteChecklistDocument from './DeleteChecklistDocument';
import MaterialModal from '@mui/material/Modal';

const initialValues = {
    startup_deal_id: null,
    notes: '',
    current_status: '',
    task_status: '',
    date: '',
    file_url: [''],
    completed_date: moment().format('YYYY-MM-DD'),
    document: null,
    required: false,
    display_order: ''
};

const UpdateTemplateMonthlyChecklist = ({date, taskId, setOpen, refetchListing,isSyndicateRefetching }) => {
    const [fromik_values, formikValues] = useState(initialValues);
    const [selected, setSelected] = useState(null);
    const [doc, setDoc] = useState([]);
    // console.log('SSsssssssssssssssssssssssssssssssssss', date);
    useState(() => {
        // console.log('RRR', doc);
    }, [doc]);

    const { isLoading: istaskDataDealLoading, data: taskDataDeal, status: FetchTaskDataDealStatus, refetch: refetchListingData } = FetchTaskDataDeal(taskId);
    // console.log(taskDataDeal,"hhhhh")
    const { mutateAsync: deletechecklistDocument, status: deletechecklistDocumentStatus, error: deletechecklistDocumentError } = DeleteDChecklistDocument(taskId);
    const {
        mutateAsync: updateTask,
        isLoading: isLoadingUpdateDealTask,
        status: UpdateDealTaskStatus,
        error: UpdateDealTaskError
    } = UpdateDealTask();
    const theme = useTheme();
    const { id: deal_id } = useParams();
    const [open, setOpenDocument] = useState(false);
    const handleClose = () => setOpenDocument(false);
    const [open4, setOpen4] = useState(false);
    const [activeRow3, setActiveRow3] = useState({});
    const handleOpen3 = (row) => {
        // console.log(row,",,,,,,,,,,,,")
        setActiveRow3(row);
        setOpen4(true);
    };
    const handleClose3 = () => setOpen4(false);
    const handleDeleteDocument = (values) => {
        if(values.drive_id != undefined){
            values = {drive_id: values.drive_id  }
        }else{
            values = {filename: values.filename  }
        }
        deletechecklistDocument(values,{
            onSuccess: (succ) => {
              refetchListingData()
              isSyndicateRefetching();
              setTimeout(()=>{
                toast.success(JSON.parse(succ.request.response).message, {
                  position: "top-left",
                  // autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  },2000);
              })
            },
            onError: (err) => {
              setTimeout(()=>{
              toast.error(JSON.parse(err.request.response).message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              },2000)
            })
            }
          });
          setOpen4(false);
    };
    const handleConfirm = () => {
        // UpdateDealStatus({ payload: {"deal_status":formik.values.deal_status,
        // "current_unit_value":formik.values.unit_value,
        // "comment":formik.values.comment,
        // "deal_completed_date":formik.values.deal_completed_date}, id: deal_id })
    };
    const [tags, setTags] = useState([]);

    const handleTagChange = (event, value) => {
        setTags(value);
    };

    const formik = useFormik({
        initialValues: fromik_values,
        // validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append('startup_deal_id', deal_id);
            formData.append('notes', values.notes);
            formData.append('task_status', values.task_status);
            formData.append('current_status', values.current_status);
            formData.append('date', values.date);
            values.file_url.forEach((value, index) => {
                formData.append(`file_url[${index}]`, value);
            });

            formData.append('required', values.required);
            formData.append('required', values.required);
            formData.append('display_order', values.display_order);
            if(taskDataDeal?.checklist_period !== 'monthly'){
                formData.append('completed_date', values.completed_date != 'undefined' ? values.completed_date : moment().toISOString());
            }
            // formData.append('completed_date', values.completed_date != 'undefined' ? values.completed_date : moment().toISOString());
            if (values?.document?.length > 0) {
                values.document.forEach((file, index) => {
                    formData.append(`document`, file);
                });
                if(taskDataDeal?.checklist_period == 'monthly'){

                    values.document_date.forEach((file, index) => {
                        const formented_date = file.format('YYYY-MM');
                        formData.append(`document_date[${index}]`, formented_date);
                    });


                    // values.drive_document_date.forEach((file, index) => {
                    //     const drive_formented_date = file.format('YYYY-MM');
                    //     formData.append(`drive_document_date[${index}]`, drive_formented_date);
                    // });


                    // formData.append('document_date', values.document_date != 'undefined' ? values.document_date : moment().toISOString());
                }
            }

            if(doc.length > 0){
                doc.forEach((value, index) => {
                formData.append(`drive_id[${index}]`, value._id);
                })
                if(taskDataDeal?.checklist_period == 'monthly'){

                    values.drive_document_date.forEach((file, index) => {
                        const drive_formented_date = file.format('YYYY-MM');
                        formData.append(`drive_document_date[${index}]`, drive_formented_date);
                    });
                    // formData.append('document_date', values.document_date != 'undefined' ? values.document_date : moment().toISOString());
                }
            }
            // console.log(formData);
            updateTask({ payload: formData, id: taskId });
        }
    });
    useEffect(() => {
        if (UpdateDealTaskStatus == 'success') {
            isSyndicateRefetching();
            refetchListing();
            refetchListingData();
            formik.setFieldValue('document', '');
            setDoc([]);
            setOpen(false)
            toast.success('Task Update!', {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                // onClose: () => {
                //     setTimeout(() => {
                //         setOpen(false);
                //     }, 100);
                // }
            });
            
        }
        if (UpdateDealTaskStatus == 'error') {
            toast.error(UpdateDealTaskError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [UpdateDealTaskStatus]);
    // console.log(formik.values.document,"dsfsdfadfdafsdf")
    useEffect(() => {
        // console.log(taskDataDeal, 'taskDataDeal');
        if (FetchTaskDataDealStatus === 'success' && taskDataDeal) {
            formikValues({
                startup_deal_id: null,
                notes: taskDataDeal.notes != 'undefined' ? taskDataDeal.notes : '',
                current_status: taskDataDeal.current_status != 'undefined' ? taskDataDeal.current_status : '',
                date: taskDataDeal.date,
                file_url: taskDataDeal.file_url != undefined && taskDataDeal.file_url != '' ? taskDataDeal.file_url : [''],
                task_status: taskDataDeal.task_status,
                completed_date: taskDataDeal.completed_date != 'undefined' ? taskDataDeal.completed_date : moment().toISOString(),
                document_date: [],
                drive_document_date:[],
                document: null,
                required: taskDataDeal.required,
                display_order: taskDataDeal.display_order
            });
        }
    }, [FetchTaskDataDealStatus, taskDataDeal]);

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    const CustomTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px',
                    borderRightWidth: '0px'
                }
            }
        }
    })(TextField);

    const handleAddInput = () => {
        const inputs = [...formik.values.file_url, ''];
        formik.setFieldValue('file_url', inputs);
    };

    const handleRemoveInput = (index) => {
        const inputs = [...formik.values.file_url];
        inputs.splice(index, 1);
        formik.setFieldValue('file_url', inputs);
    };
    const handleDocumentRemove = (index) => {
        delete formik.values.document[index];  
        delete formik.values.document_date[index];  
        const filteredDocumentData =  formik.values.document.filter(item => item !== null);
        const filtereddocument_dateData =  formik.values.document_date.filter(item => item !== null);

        formik.setFieldValue('document', filteredDocumentData);
        formik.setFieldValue('document_date', filtereddocument_dateData);

        // console.log(index,formik.values.document,formik.values.document_date,"handleDocumentRemove")
    };
    const handleDriveRemove = (index) => {

        delete doc[index];  
        delete formik.values.drive_document_date[index]; 
        setDoc(doc.filter(item => item !== null));
        const filtereddrive_document_dateData =  formik.values.drive_document_date.filter(item => item !== null);

        // formik.setFieldValue('document', filteredDocumentData);
        formik.setFieldValue('drive_document_date', filtereddrive_document_dateData);

   
        // console.log(index,"handleDocumentRemove")

    };

    if (istaskDataDealLoading) {
        return (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loading />
            </div>
        );
    }
    return (
        <>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
                style={{ overflow: 'auto' }}
            >
                <MaterialModal open={open4} onClose={handleClose3}>
                    <Box
                        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '700px' }}
                        className="delete-transaction-modal"
                    >
                        <DeleteChecklistDocument
                            // dealId={dealId}
                            activeRow={activeRow3}
                            handleClose={handleClose3}
                            refetchListing={refetchListing}
                            handleConfirm={handleDeleteDocument}
                        />
                    </Box>
            </MaterialModal>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10, flex: 1 }}>
                        <h3 style={{ textAlign: 'center' }}> Monthly {taskDataDeal?.task_name} ( {date} )</h3>
                        {/* <div className="mb-3">
                            <SelectField
                                name="task_status"
                                label="Task Status"
                                options={[
                                    { label: 'Completed', value: 'Completed' },
                                    { label: 'In Progress', value: 'inProgress' },
                                    { label: 'Pending', value: 'pending' }
                                ]}
                            />
                        </div> */}
                        {/* <div className="mb-3">
                            <CustomField name="current_status" label="Current Status" />
                        </div> */}
                        <div className="mb-3">
                            <CustomField name="notes" label="Notes" />
                        </div>

                        {Array.isArray(formik.values.file_url) &&
                            formik.values.file_url.map((input, index) => (
                                <div key={index}>
                                    <div className="mb-3 d-flex">
                                        <CustomField name={`file_url[${index}]`} label="Add File URL" />
                                        {index > 0 && (
                                            <button
                                                className="button-79"
                                                type="button"
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    padding: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderRadius: '50%'
                                                }}
                                                onClick={() => handleRemoveInput(index)}
                                            >
                                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                                            </button>
                                        )}
                                        <button
                                            className="button-79"
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                padding: '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: '50%'
                                            }}
                                            onClick={handleAddInput}
                                        >
                                            <IoAddCircleSharp size={22} color={theme.palette.success.main} />
                                        </button>
                                    </div>
                                </div>
                            ))}

                        
                        {/* <div className="mb-3 d-flex">
                            <CustomTextField value={formik.values.document?.name} fullWidth disabled label="Upload File" />
                            <Button
                                variant="contained"
                                component="label"
                                disableElevation
                                style={{ borderRadius: '0px 4px 4px 0px', height: '51px', whiteSpace: 'nowrap' }}
                            >
                                Upload file
                                <input
                                    hidden
                                    accept="application/pdf, image/*"
                                    type="file"
                                    id="doc"
                                    multiple
                                    onChange={(e) => {
                                        if (e.target.files.length > 0) {
                                            formik.setFieldValue('document', Array.from(e.target.files));
                                        } else {
                                            formik.setFieldValue('document', null);
                                        }
                                    }}
                                />
                            </Button>
                        </div> */}
                        {/* {FetchTaskDataDealStatus == 'success' &&
                            taskDataDeal.document &&
                            taskDataDeal.document.length > 0 && <h4 className='mt-4'>Attachments</h4>} */}
                            {(formik.values?.document  || doc.length > 0) &&
                                (doc.length > 0 || formik.values.document.length > 0 ) && <h4 className='mt-4'>Attachments</h4>}
                     
                              
                              
                          {/* {FetchTaskDataDealStatus == 'success' &&
                                    taskDataDeal.document &&
                                    taskDataDeal.document.length > 0 && <div className='document-list-table custom-table table-responsive'>
                            <table className='w-100'>
                            <thead>
                                <tr>
                                <th scope="col" className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>File Name</th>
                                <th  className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Created By</th>
                                <th className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Created Date</th>
                                <th className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Action</th>
                            </tr>
                            </thead>
                                <tbody>
                                {taskDataDeal.document.map((input, index) => (
                                        <>
                                    <tr>
                                        <td>{<Link
                                        href={input.url}
                                        target="_blank"
                                        underline="none">
                                    <div className="xs-title d-flex align-items-center mb-2">
                                        <img className="ud-icon" style={{maxWidth:'20px'}} src= { input.filename.split('.').pop()  == "pdf" ?  pdfIcon :  input.filename.split('.').pop()  == "png" ? pngIcon : input.filename.split('.').pop()  == "xlsx" ? xlsxIcon :(input.filename.split('.').pop()  == "jpg" ||  input.filename.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                        <div className="ps-2 text-ellipsis" 
                                            style={{
                                            fontSize: '12px',
                                            display: 'block'
                                            }}>
                                            {input.filename && input.filename}
                                        </div>
                                    </div>
                                </Link>}</td>
                                        <td>
                                        {input.uploaded_by && input.uploaded_by} 
                                        </td>
                                            <td> {input.uploadedAt && input.uploadedAt} 
                                      </td>
                                        <td>     
                                        <Clickable onClick={() => handleOpen3(input)}>
                                            <Tooltip title="Delete">
                                                    <DeleteIcon stroke={1.5} size="1.4rem" />
                                            </Tooltip>
                                        </Clickable>
                                        </td>
                                    </tr>
                                    </>
                            ))}
                                </tbody>
                            </table>
                          </div>} */}
                          {(formik.values?.document  || doc.length > 0) &&
                                (doc.length > 0 || formik.values.document.length > 0 ) && <div className='document-list-table custom-table table-responsive'>
                                <table className='w-100'>
                                <thead>
                                    <tr>
                                    <th scope="col" className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>File Name</th>
                                    {/* <th  className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Created By</th> */}
                                    {taskDataDeal?.checklist_period == 'monthly' ?  <th className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Document Date</th> : ""}
                                    <th className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Action</th>
                                </tr>
                                </thead>
                                    <tbody>
                                    {formik.values.document && formik.values?.document.map((input, index) => (
                                            <>
                                        <tr>
                                            <td>{ 

                                        <div className="xs-title d-flex align-items-center mb-2">
                                            <img className="ud-icon" style={{maxWidth:'20px'}} src= { input.name.split('.').pop()  == "pdf" ?  pdfIcon :  input.name.split('.').pop()  == "png" ? pngIcon : input.name.split('.').pop()  == "xlsx" ? xlsxIcon :(input.name.split('.').pop()  == "jpg" ||  input.name.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                            <div className="ps-2 text-ellipsis" 
                                                style={{
                                                fontSize: '12px',
                                                display: 'block'
                                                }}>
                                                {input.name && input.name}
                                            </div>
                                        </div>
                                        // </Link>
                                        }</td>
                                            {/* <td>
                                            {input.uploaded_by && input.uploaded_by} 
                                            </td> */}
                                              {taskDataDeal?.checklist_period == 'monthly' &&  <td> 
                                                
                                                    <div className="mb-3">
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                            views={['year', 'month']}
                                                                label="Document  Date"
                                                                name={`document_date.${index}`}
                                                                value={formik.values.document_date[index] ||    formik.setFieldValue(`document_date.${index}`,moment(date))  } // Default to current date if not set
                                                                onChange={(newValue) => {
                                                                    // console.log('DatePicker onChange:',  moment(newValue));
                                                                    formik.setFieldValue(`document_date.${index}`, moment(newValue));
                                                                }}

                                                                // onChange={(newValue) => formik.setFieldValue(`document_date.${index}`, moment(newValue).format('MM/YYYY'))}
                                                                renderInput={(params) => <TextField fullWidth {...params}  />}
                                                                // inputFormat="MM/YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                  
                                        </td>  }
                                            <td>     
                                            <Clickable onClick={() => handleDocumentRemove(index)}>
                                                <Tooltip title="Delete">
                                                        <DeleteIcon stroke={1.5} size="1.4rem" />
                                                </Tooltip>
                                            </Clickable>
                                            </td>
                                        </tr>
                                        </>
                                    ))}

                                {doc && doc.map((input, index) => (
                                            <>
                                        <tr>
                                            <td>{ 

                                        <div className="xs-title d-flex align-items-center mb-2">
                                            <img className="ud-icon" style={{maxWidth:'20px'}} src= { input.name.split('.').pop()  == "pdf" ?  pdfIcon :  input.name.split('.').pop()  == "png" ? pngIcon : input.name.split('.').pop()  == "xlsx" ? xlsxIcon :(input.name.split('.').pop()  == "jpg" ||  input.name.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                            <div className="ps-2 text-ellipsis" 
                                                style={{
                                                fontSize: '12px',
                                                display: 'block'
                                                }}>
                                                {input.name && input.name}
                                            </div>
                                        </div>
                                        // </Link>
                                        }</td>
                                            {/* <td>
                                            {input.uploaded_by && input.uploaded_by} 
                                            </td> */}
                                                <td> 
                                                {taskDataDeal?.checklist_period == 'monthly' &&
                                                    <div className="mb-3">
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                            views={['year', 'month']}
                                                                label="Document  Date"
                                                                name={`drive_document_date.${index}`}
                                                                value={formik.values.drive_document_date[index] || formik.setFieldValue(`drive_document_date.${index}`,moment(date))  } // Default to current date if not set
                                                                onChange={(newValue) => {
                                                                    // console.log('DatePicker onChange:',  moment(newValue));
                                                                    formik.setFieldValue(`drive_document_date.${index}`, moment(newValue));
                                                                }}

                                                                // onChange={(newValue) => formik.setFieldValue(`document_date.${index}`, moment(newValue).format('MM/YYYY'))}
                                                                renderInput={(params) => <TextField fullWidth {...params}  />}
                                                                // inputFormat="MM/YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    }
                                        </td>
                                            <td>     
                                            <Clickable onClick={() => handleDriveRemove(index)}>
                                                <Tooltip title="Delete">
                                                        <DeleteIcon stroke={1.5} size="1.4rem" />
                                                </Tooltip>
                                            </Clickable>
                                            </td>
                                        </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                          </div>}
                
                        {/* {FetchTaskDataDealStatus == 'success' &&
                            formik.values?.file_url &&
                            formik.values.file_url.length > 0 &&   <h4 className='mt-4'>Links</h4>}
                        {FetchTaskDataDealStatus == 'success' &&
                            formik.values?.file_url &&
                            formik.values.file_url.length > 0 &&
                            formik.values.file_url.map((input, index) => (
                                <Link
                                        href={input}
                                        target="_blank"
                                        underline="none">
                                    <div className="xs-title d-flex align-items-center mb-2">
                                        <div className="ps-2 text-ellipsis" 
                                            style={{
                                            fontSize: '12px',
                                            display: 'block'
                                            }}>
                                            {input && input}
                                        </div>
                                    </div>
                                </Link>
                            ))} */}


                        {/* {FetchTaskDataDealStatus == 'success' && ( (taskDataDeal.document &&   taskDataDeal.document.length > 0) ||   (doc && doc.length > 0 ))  &&  <h4 className='mt-4'>Attach Files</h4>}
                        {FetchTaskDataDealStatus == 'success' &&
                            taskDataDeal.document &&
                            taskDataDeal.document.length > 0 &&
                            taskDataDeal.document.map((input, index) => (
                                <>
                                {input.type == "drive" && <Link
                                        href={input.url}
                                        target="_blank"
                                        underline="none">
                                    <div className="xs-title d-flex align-items-center mb-2">
                                        <img className="ud-icon" style={{maxWidth:'20px'}} src= { input.filename.split('.').pop()  == "pdf" ?  pdfIcon :  input.filename.split('.').pop()  == "png" ? pngIcon : input.filename.split('.').pop()  == "xlsx" ? xlsxIcon :(input.filename.split('.').pop()  == "jpg" ||  input.filename.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                        <div className="ps-2 text-ellipsis" 
                                            style={{
                                            fontSize: '12px',
                                            display: 'block'
                                            }}>
                                            {input.filename && input.filename}
                                        </div>
                                    </div>
                                </Link>}
                                </>
                            ))}
                            {FetchTaskDataDealStatus == 'success' &&
                            doc &&
                            doc.length > 0 &&
                            doc.map((input, index) => (
                                    <div className="xs-title d-flex align-items-center mb-2">
                                  <img className="ud-icon" style={{maxWidth:'20px'}} src= { input.name.split('.').pop()  == "pdf" ?  pdfIcon :  input.name.split('.').pop()  == "png" ? pngIcon : input.name.split('.').pop()  == "xlsx" ? xlsxIcon :(input.name.split('.').pop()  == "jpg" || input.name.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                        <div className="ps-2 text-ellipsis" 
                                            style={{
                                            fontSize: '12px',
                                            display: 'block'
                                            }}>
                                            {input.name && input.name}
                                        </div>
                                    </div>
                            ))}  */}


                                {/* {formik.values.document != null && formik.values.document.length > 0 && 
                                        formik.values.document.map((file, index) => {
                                            {console.log(file.name ," formik.values.document formik.values.document formik.values.document")}
                                            <div className="xs-title d-flex align-items-center mb-2">
                                                <img className="ud-icon" style={{maxWidth:'20px'}} src= { file.name.split('.').pop()  == "pdf" ?  pdfIcon :  file.name.split('.').pop()  == "png" ? pngIcon : file.name.split('.').pop()  == "xlsx" ? xlsxIcon :(file.name.split('.').pop()  == "jpg" ||  file.name.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                                <div className="ps-2 text-ellipsis" 
                                                    style={{
                                                    fontSize: '12px',
                                                    display: 'block'
                                                    }}>
                                                    {file.name && file.name}
                                                </div>
                                            </div>
                                    
                                        })
                                } */}
                        {/* {FetchTaskDataDealStatus == 'success' && 
                             <h4 className='mt-4'>Attach Files</h4>}  
                        {FetchTaskDataDealStatus == 'success' &&
                            doc &&
                            doc.length > 0 &&
                            doc.map((input, index) => (
                                <Link
                                        href={input}
                                        target="_blank"
                                        underline="none">
                                    <div className="xs-title d-flex align-items-center mb-2">
                                        <div className="ps-2 text-ellipsis" 
                                            style={{
                                            fontSize: '12px',
                                            display: 'block'
                                            }}>
                                            {input.name && input.name}
                                        </div>
                                    </div>
                                </Link>
                            ))} */}

                        {/* <div className="mb-3 text-center">
                            <span className="circle-box or-cbox d-inline-block">OR</span>
                        </div> */}
                        {/* {Array.isArray(formik.values.file_url) &&
                            formik.values.file_url.map((input, index) => (
                                <div key={index}>
                                    <div className="mb-3 d-flex">
                                        <CustomField name={`file_url[${index}]`} label="Add File URL" />
                                        {index > 0 && (
                                            <button
                                                className="button-79"
                                                type="button"
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    padding: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderRadius: '50%'
                                                }}
                                                onClick={() => handleRemoveInput(index)}
                                            >
                                                <AiFillCloseCircle size={22} color={theme.palette.error.main} />
                                            </button>
                                        )}
                                        <button
                                            className="button-79"
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                padding: '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: '50%'
                                            }}
                                            onClick={handleAddInput}
                                        >
                                            <IoAddCircleSharp size={22} color={theme.palette.success.main} />
                                        </button>
                                    </div>
                                </div>
                            ))} */}

                        {/* <div className="mb-3 text-center">
                            <span className="circle-box or-cbox d-inline-block">OR</span>
                        </div> */}
                          {/* {doc.map((item) => (
                            <span>{item.name}</span>
                        ))} */}


                         

                        <button className="button-79 action-btn  my-4" onClick={() => setOpenDocument(true)} type="button">
                         Attach Files
                        </button>
                        {/* //sss */}

                      
                        <Modal open={open} onClose={handleClose} closeIcon={true}>
                            <UploadMenuPopUp
                                // activeRow={activeRow}
                                setDoc={setDoc}
                                setOpenDocument={setOpenDocument}
                                handleClose={handleClose}
                                handleConfirm={handleConfirm}
                                formik={formik}
                            />
                        </Modal>
                        {taskDataDeal?.checklist_period !== 'monthly' &&
                          <div className="mb-3">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Completed Date"
                                    name="completed_date"
                                    value={
                                        formik.values.completed_date != undefined
                                            ? formik.values.completed_date
                                            : formik.setFieldValue('completed_date', moment().toISOString())
                                    }
                                    onChange={(newValue) => formik.setFieldValue('completed_date', moment(newValue).toISOString())}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                    inputFormat="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                          </div>
                        }
                        <div className="mb-3">
                           
                            <button className="button-79" type="submit">
                                Update Checklist
                            </button>
                        </div>
                    </Form>
                </FormikProvider>
                {FetchTaskDataDealStatus == 'success' &&
                            taskDataDeal.document &&
                            taskDataDeal.document.length > 0 && <h4 className='mt-4'> {taskDataDeal?.checklist_period == 'monthly' ? "Monthly" : "" } Documents</h4>}
                     
                    
                          {FetchTaskDataDealStatus == 'success' &&
                                    taskDataDeal.document &&
                                    taskDataDeal.document.length > 0 && <div className='document-list-table custom-table table-responsive'>
                            <table className='w-100'>
                            <thead>
                                <tr>
                                <th scope="col" className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>File Name</th>
                                <th  className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Created By</th>
                                <th className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Document Date</th>
                                <th className='text-start' style={{ fontWeight: '600', backgroundColor: '#0000FF', color: '#fff' }}>Action</th>
                            </tr>
                            </thead>
                                <tbody>
                                {taskDataDeal.document.map((input, index) => (
                                        <>
                                    <tr>
                                        <td>{<Link
                                        href={input.url}
                                        target="_blank"
                                        underline="none">
                                    <div className="xs-title d-flex align-items-center mb-2">
                                        <img className="ud-icon" style={{maxWidth:'20px'}} src= { input.filename.split('.').pop()  == "pdf" ?  pdfIcon :  input.filename.split('.').pop()  == "png" ? pngIcon : input.filename.split('.').pop()  == "xlsx" ? xlsxIcon :(input.filename.split('.').pop()  == "jpg" ||  input.filename.split('.').pop()  == "JPEG") ? jpgIcon: doc2Icon }  alt="icon"/>
                                        <div className="ps-2 text-ellipsis" 
                                            style={{
                                            fontSize: '12px',
                                            display: 'block'
                                            }}>
                                            {input.filename && input.filename}
                                        </div>
                                    </div>
                                </Link>}</td>
                                        <td>
                                        {input.uploaded_by && input.uploaded_by} 
                                        </td>
                                            <td> {input.document_date && input.document_date} 
                                      </td>
                                        <td>     
                                        <Clickable onClick={() => handleOpen3(input)}>
                                            <Tooltip title="Delete">
                                                    <DeleteIcon stroke={1.5} size="1.4rem" />
                                            </Tooltip>
                                        </Clickable>
                                        </td>
                                    </tr>
                                    </>
                            ))}
                                </tbody>
                            </table>
                          </div>}
            </Paper>
        </>
    );
};

export default UpdateTemplateMonthlyChecklist;
