import React, { useState } from 'react';
import { Box, } from '@mui/material';
import Modal from 'ui-component/Modal';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import NewFolderCreate from './NewFolderCreate';
import { Link } from 'react-router-dom';
import UploadDocumentBox from './UploadDocumentBox';
import UploadDocumentHeader from './UploadDocumentHeader';
import { KeyboardArrowRight } from '@mui/icons-material';
const documentList = [
    {
        id: "folder1",
        name: "Folder 1",
        owner: "Kriti Sharma",
        tags: " ",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "folder",
        Child: [
            {
                id: "Folder11",
                name: "Folder 11",
                owner: "Kriti Sharma",
                tags: " ",
                uploaded_at: "07-Aug-2023 12:03PM",
                type: "folder"
            },
            {
                id: "Folder12",
                name: "Folder 12",
                owner: "Kriti Sharma",
                tags: " ",
                uploaded_at: "07-Aug-2023 12:03PM",
                type: "folder"
            },
            {
                id: "Folder13",
                name: "Folder 13",
                owner: "Kriti Sharma",
                tags: " ",
                uploaded_at: "07-Aug-2023 12:03PM",
                type: "folder"
            },
            {
                id: "Folder14",
                name: "Folder 14",
                owner: "Kriti Sharma",
                tags: " ",
                uploaded_at: "07-Aug-2023 12:03PM",
                type: "folder"
            },
            {
                id: "Folder15",
                name: "Folder 15",
                owner: "Kriti Sharma",
                tags: " ",
                uploaded_at: "07-Aug-2023 12:03PM",
                type: "folder"
            },
        ]
    },
    {
        id: "Folder2",
        name: "Folder 2",
        owner: "Kriti Sharma",
        tags: "",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "folder"
    },
    {
        id: "Folder3",
        name: "Folder 3",
        owner: "Kriti Sharma",
        tags: "",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "folder"
    },
    {
        id: "Folder4",
        name: "Folder 4",
        owner: "Kriti Sharma",
        tags: " ",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "folder"
    },
    {
        id: "Folder5",
        name: "Folder 5",
        owner: "Kriti Sharma",
        tags: "",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "folder"
    },
    {
        id: "File1",
        name: "File 1",
        owner: "Kriti Sharma",
        tags: "Legal, Financial",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "file"
    },
    {
        id: "File2",
        name: "File 2",
        owner: "Kriti Sharma",
        tags: "Legal, Financial",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "file"
    },
    {
        id: "File3",
        name: "File 3",
        owner: "Kriti Sharma",
        tags: "Legal, Financial",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "file"
    },
    {
        id: "File4",
        name: "File 4",
        owner: "Kriti Sharma",
        tags: "Legal, Financial",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "file"
    },
    {
        id: "File5",
        name: "File 5",
        owner: "Kriti Sharma",
        tags: "Legal, Financial",
        uploaded_at: "07-Aug-2023 12:03PM",
        type: "file"
    },
]
const UploadFolderId = () => {
    const [title, setTitle] = useState('Documents');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [open2, setOpen2] = useState(false);
    const openFolderCreateModal = () => {
        setOpen2(true)
        setAnchorEl(null);
    }
    const handleClose = () => {
        setAnchorEl(null);
        setOpen2(false)
    };
    const [listViewType, setListViewType] = useState(false);
    const gridView = () => {
        setListViewType(true)
    }
    const listView = () => {
        setListViewType(false)
    }
    return (
        <>

            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={title} />
            </Box>
            <div className='custom-menu-block view-investor-block upload-document-block'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <div className='sm-title text-black cd-breadcrumb'>
                        <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> MY Drive</Link> 
                        <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> Drive 1</Link> 
                        <Link to="/upload-documents" className='ud-link dot-link'>...</Link> 
                        <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> Drive 4</Link> 
                        <span><KeyboardArrowRight/> Folder Name </span> 
                    </div>
                    <UploadDocumentHeader
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        openFolderCreateModal={openFolderCreateModal}
                        handleClick={handleClick}
                        handleClose={handleClose}
                        listViewType={listViewType}
                        gridView={gridView}
                        listView={listView}
                        open={open}
                    />
                </div>
                <div className='cs-border'></div>
                <div className='d-flex mt-3'>
                    <div className='w-40'>
                        <input type='text' className='form-control w-100' placeholder='Search' />
                    </div>
                    <div className='ms-3'>
                        <button className='button-79' type='button'>Search</button>
                    </div>
                </div>
                <div className={`upload-document-list mt-3 ${listViewType? "grid-view" : "list-view"}`}>
                    {!listViewType && 
                    <div className='upload-document-row ud-header-row d-flex'>
                        <div className='col'>
                            Name
                        </div>
                        <div className='col'>
                            Owner
                        </div>
                        <div className='col'>
                            Tags
                        </div>
                        <div className='col'>
                            Uploaded At
                        </div>
                    </div>}
                    {/* <UploadDocumentBox listViewType={listViewType}/> */}
                    {/* {console.log(documentList[0].Child)} */}
                    {documentList[0].Child.map((item) => (
                        <UploadDocumentBox listViewType={listViewType} item={item} />
                    ))}
                </div>
            </div>
            <Modal open={open2} onClose={handleClose} closeIcon={true} mxclass="mxw450">
               <NewFolderCreate  onClose={handleClose}/>
            </Modal>
        </>
    )
}

export default UploadFolderId