// assets
import { AccountBalance, AccountBalanceOutlined, Receipt, ReceiptOutlined } from '@mui/icons-material';
import { IconCoin, IconUsers, IconUpload } from '@tabler/icons';


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

//If any common change do in the admin_bank.js as well//
const bank = {
    id: 'bank1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'bank11',
            title: 'Bank',
            type: 'collapse',
            icon: AccountBalanceOutlined,
            // url: `/banks`,
            breadcrumbs: false,
            children: [
                {
                    id: 'bank111',
                    title: 'Bank Receipts',
                    type: 'item',
                    icon: ReceiptOutlined,
                    url: `/bank-receipts`,
                    breadcrumbs: false
                },
                // {
                //     id: 'bank112',
                //     title: 'Bank Reconciliation',
                //     type: 'item',
                //     icon: IconUsers,
                //     url: `/bank-reconciliation`,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default bank;
