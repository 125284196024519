import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import { styled, useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

//declare the const and add the material UI style
const styles = (theme) => ({
    multilineColor: {
        color: 'red'
    }
});
const CssTextField = withStyles((theme) => ({
    root: {
        '& input': {
            background: theme.palette.background.paper
        },
        '& label.Mui-focused': {
            // color: theme.palette.secondary[200],
            borderWidth: 1
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.secondary[200],
            borderWidth: 1
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.secondary[200],
                borderWidth: 0.5
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary[800]
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary[800],
                borderLeftWidth: 6,
                padding: '4px !important',
                borderWidth: 1
            }
        }
    }
}))(TextField);

const TextfieldWrapper = ({ name, ...otherProps }) => {
    const [field, mata] = useField(name);

    const configTextfield = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined'
    };

    if (mata && mata.touched && mata.error) {
        // configTextfield.error = true;
        configTextfield.helperText = mata.error;
        configTextfield.helperText = <span style={{ color: '#ef5350' }}>{mata.error}</span>;
    }

    return <CssTextField {...configTextfield} inputProps={{ style: { background: '#fff' } }} />;
};

export default TextfieldWrapper;
