// assets
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import { IconReport,IconNotes, IconGraph } from '@tabler/icons';


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const report = {
    id: 'report1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'report11',
            title: 'Reports',
            type: 'collapse',
            icon: IconReport,
            // url: `/investors`,
            breadcrumbs: false,
            children: [
                // {
                //     id: 'report111',
                //     title: 'LP Summary',
                //     type: 'item',
                //     icon: IconNotes,
                //     url: `/lp-summary`,
                //     breadcrumbs: false
                // }
                // ,
                // {
                //     id: 'report112',
                //     title: 'VP Investment Details',
                //     type: 'item',
                //     icon: IconGraph,
                //     url: `/vp-investment-details`,
                //     breadcrumbs: false
                // },
            ]
        }
    ]
};

export default report;
