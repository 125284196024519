import React from 'react';
import { useTheme } from '@emotion/react';
import { Autocomplete, Box, Chip, CircularProgress, Grid, Paper, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { AiFillCloseCircle } from 'react-icons/ai';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import fileIcon from 'assets/images/icons/folder.png';
import pdfIcon from 'assets/images/icons/pdf.png';
import xlsxIcon from 'assets/images/icons/xlsx.png';
import imageIcon from 'assets/images/icons/image.png';
import docIcon from 'assets/images/icons/doc.png';
import wordIcon from 'assets/images/icons/word.png';
import pngIcon from 'assets/images/icons/png.png';
import doc2Icon from 'assets/images/icons/doc2.png';
import pdf2Icon from 'assets/images/icons/pdf-file.png';
import jpgIcon from 'assets/images/icons/jpg.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SendInvestorsListEmailToKratos } from 'api';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const getCurrentDate = () => {
    const date = new Date();
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const [day, month, year] = formattedDate.split(' ');
    return `${day}-${month}-${year}`;
};

const SendEmailPopupKratos = ({ handleClose, downloadKratos, search ,queryString ,mode }) => {
    // console.log(mode,"mode")
    const theme = useTheme();
    const currentMode = mode;
    const currentQuery = queryString;
    const searchText = search;
    // const { id: deal_id } = useParams();
    const { mutateAsync: SendEmail, status: sendEmailStatus, error: sendEmailError, isLoading: sending } = SendInvestorsListEmailToKratos();
    const formik = useFormik({
        initialValues: {
            send_to: ['contact@kratosfunds.com'],
            send_to_input: '',
            send_cc: ['mahesh@kratosfunds.com'],
            send_cc_input: '',
            send_bcc: [],
            send_bcc_input: '',
            subject: `Investors Updated Data till ${getCurrentDate()}`,
            content: `Hello Team, \n\nPlease find below the attached Updated sheet link of investors details \n\n `,
        },
        onSubmit: async (values) => {
            const { send_to_input, send_cc_input, send_bcc_input, content, ...currentValues } = values;

            const payload = {
                document_id: document[0]?.document_id,
                ...currentValues,
                content: content?.replaceAll(/\n/g, '<br>'),
            };
            SendEmail({ payload: { ...payload }, search :searchText , queryString: currentQuery ,mode : currentMode});
        }
    });

    useEffect(() => {
        if (sendEmailStatus === 'success') {
            toast.success("Email Sent", {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose();

        }
        if (sendEmailStatus === 'error') {
            toast.error(sendEmailError.response.data.message, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }, [sendEmailStatus, sendEmailError]);

    const handleAddition = (newEmails, fieldName) => {
        const uniqueNewEmails = newEmails?.filter((newEmail) => !formik.values[fieldName]?.some((email) => email === newEmail));
        formik.setFieldValue(fieldName, [...formik.values[fieldName], ...uniqueNewEmails]);
    };

    const handleDelete = (emailToDelete, fieldName) => {
        formik.setFieldValue(fieldName, formik.values[fieldName]?.filter((email) => email !== emailToDelete));
    };

    const handleInputChange = (event, newInputValue, fieldName) => {
        formik.setFieldValue(`${fieldName}_input`, newInputValue);
    };

    const handleInputBlur = (event,fieldName) => {
        if (formik.values[`${fieldName}_input`].trim() !== '') {
            const newEmail = formik.values[`${fieldName}_input`];
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailPattern?.test(newEmail)) {
                formik.setFieldValue(fieldName, [...formik.values[fieldName], newEmail]);
            }
            formik.setFieldValue(`${fieldName}_input`, '');
        }
    }

    const handleInputKeyDown = (event, fieldName) => {
        if ((event.key === 'Enter' || event.key === 'Tab') && formik.values[`${fieldName}_input`].trim() !== '') {
            const newEmail = formik.values[`${fieldName}_input`];
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailPattern?.test(newEmail)) {
                formik.setFieldValue(fieldName, [...formik.values[fieldName], newEmail]);
            }
            formik.setFieldValue(`${fieldName}_input`, '');
        }
    };

    const EmptyPopper = () => null;

    const fileExtensionSwitch = (value) => {
        switch (value) {
            case 'docx': return doc2Icon;
            case 'excel': return xlsxIcon;
            case 'png': return pngIcon;
            case 'jpeg': return jpgIcon;
            case 'jpg': return jpgIcon;
            case 'pdf': return pdfIcon;
            default: return pdfIcon;
        }
    };
    return (
        <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, scale: { sm: '0.8', md: '0.8', lg: "1" }, padding: '16px', marginTop: '14px', height: "100%", overflow: 'hidden' }}>
            <h2 style={{ margin: '16px 0px 16px 8px' }}>Send Email to Kratos</h2>
            <PerfectScrollbar style={{ maxHeight: '80%', paddingInline: 10, overflow: 'hidden' }}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container xs={12} sx={{ display: 'flex', gap: '0.8rem' }}>
                            <Grid item xs={12} pt={1}>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    autoComplete="off"
                                    id="email-input-to"
                                    name="send_to"
                                    getOptionLabel={(option) => option}
                                    value={formik.values.send_to}
                                    options={[]}
                                    PopperComponent={EmptyPopper}
                                    onChange={(_, newValue) => handleAddition(newValue, 'send_to')}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option}
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={() => handleDelete(option, 'send_to')}
                                            />
                                        ))
                                    }
                                    clearIcon={false}
                                    popupIcon={false}
                                    inputValue={formik.values.send_to_input}
                                    onInputChange={(e, newValue) => handleInputChange(e, newValue, 'send_to')}
                                    onBlur={(e) => handleInputBlur(e, 'send_to')}
                                    onKeyDown={(e) => handleInputKeyDown(e, 'send_to')}
                                    renderInput={(params) => <TextField type='email' {...params} label={'To'} placeholder="Enter Email" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    autoComplete="off"
                                    id="email-input-cc"
                                    name="send_cc"
                                    getOptionLabel={(option) => option}
                                    value={formik.values.send_cc}
                                    options={[]}
                                    popupIcon={false}
                                    clearIcon={false}
                                    PopperComponent={EmptyPopper}
                                    onChange={(_, newValue) => handleAddition(newValue, 'send_cc')}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option}
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={() => handleDelete(option, 'send_cc')}
                                            />
                                        ))
                                    }
                                    inputValue={formik.values.send_cc_input}
                                    onInputChange={(e, newValue) => handleInputChange(e, newValue, 'send_cc')}
                                    onBlur={(e) => handleInputBlur(e, 'send_cc')}
                                    onKeyDown={(e) => handleInputKeyDown(e, 'send_cc')}
                                    renderInput={(params) => <TextField type='email' {...params} label={'Cc'} placeholder="Enter Email" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    autoComplete="off"
                                    id="email-input-bcc"
                                    name="send_bcc"
                                    getOptionLabel={(option) => option}
                                    value={formik.values.send_bcc}
                                    options={[]}
                                    popupIcon={false}
                                    clearIcon={false}
                                    PopperComponent={EmptyPopper}
                                    onChange={(_, newValue) => handleAddition(newValue, 'send_bcc')}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option}
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={() => handleDelete(option, 'send_bcc')}
                                            />
                                        ))
                                    }
                                    inputValue={formik.values.send_bcc_input}
                                    onInputChange={(e, newValue) => handleInputChange(e, newValue, 'send_bcc')}
                                    onBlur={(e) => handleInputBlur(e, 'send_bcc')}
                                    onKeyDown={(e) => handleInputKeyDown(e, 'send_bcc')}
                                    renderInput={(params) => <TextField type='email' {...params} label={'Bcc'} placeholder="Enter Email" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomField name={"subject"} label={'Subject'} value={formik.values.subject} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomField name={"content"} label={'Content'} value={formik.values.content} multiline fullWidth minRows={7} />
                            </Grid>
                            <Grid item xs={12}>
                                {/* {document?.map((doc) => (
                                    <a href={doc?.url} download={doc?.name} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Chip avatar={<img src={fileExtensionSwitch(doc?.type)} alt="type" />} label={doc.name} />
                                    </a>
                                ))} */}
                                
                                <Chip avatar={<img src={fileExtensionSwitch("excel")} alt="type" />} label={"Investor Data (Kratos)"} onClick ={downloadKratos}/>
                                
                            </Grid>

                        </Grid>
                    </Form>
                </FormikProvider>
            </PerfectScrollbar>
            <Grid item xs={12} pt={1}>
                <CustomButton disabled={sending} sx={{ width: '5rem', justifyContent: 'center', color: 'white' }} type="submit" onClick={() => formik.handleSubmit()}> {sending ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : 'Send'}</CustomButton>
            </Grid>
            <Box sx={{ position: 'absolute', top: '8px', right: '0px', padding: '15px', cursor: 'pointer', zIndex: 1 }} onClick={handleClose} className='cs-close'>
                <AiFillCloseCircle size={25} />
            </Box>
        </Paper>
    );
};

export default SendEmailPopupKratos;