

import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-settings" width="20.8" height="20.8" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
  <path d="M12 10.5v1.5" />
  <path d="M12 16v1.5" />
  <path d="M15.031 12.25l-1.299 .75" />
  <path d="M10.268 15l-1.3 .75" />
  <path d="M15 15.803l-1.285 -.773" />
  <path d="M10.285 12.97l-1.285 -.773" />
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
</svg>
  );
}

export default Icon;