import {useState, useEffect,useRef} from 'react'
import { Paper, Skeleton,Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form,FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from "react-icons/im";
import {UndoTrans } from 'api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Checkbox from '@mui/material/Checkbox';
import { useParams, useNavigate } from 'react-router';
import { Tooltip} from '@mui/material';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
const validationSchema = yup.object({ 
    comment: yup
    .string('Comment Recording')
    .required('Comment is required')
    .trim(),
});


const TranchUndoPopup = ({ handleClose, dealId,setCheckValues,refetchTranches,refetchTransactions,refetchLogListing}) => {
  // console.log(setCheckValues,"checkedValues34")
  const [logPage, setLogPage] = useState(1)
  const theme = useTheme();
  const { id: deal_id } = useParams()
  const navigate = useNavigate()
//   const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = UpdateDealStatusUnit();
  const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = UndoTrans();
  const formik = useFormik({
    initialValues: {
        comment:"",
        deal_unit_change_ids:[setCheckValues],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        const payload = {
            comment :values.comment,
            deal_unit_change_ids:[setCheckValues],
          }
          UpdateDealStatus({payload , id: dealId}, {
            onSuccess:(succ)=>{
              setTimeout(()=>{
                refetchTranches();
                refetchTransactions();
                refetchLogListing();
              }, 200)
              handleClose()
              toast.success(JSON.parse(succ.request.response).message, {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
              })
            },
              onError: (err)=>{
                // console.log(JSON.parse(err.request.response).message)
                toast.error(JSON.parse(err.request.response).message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                })
                handleClose();
              }
          })
    },
  });

  return (
    <div>
      <Paper sx={{backgroundColor: theme.palette.background.paper, boxShadow: 3, padding:'10px', flex:1, overflowY: "auto", maxHeight: "90vh"}}>
          <FormikProvider value={formik}>
            <Form  onSubmit={formik.handleSubmit} style={{paddingTop:'10px', paddingBottom:'15px', margin:10}}>
            <h3 style={{textAlign:'center'}}>Are you Sure You want to Undo Tranch?</h3>
              <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12}>
                  <CustomTextArea name="comment" label="Comment" multiline={true} minRows={1} sx={{borderWidth:2}} />
                </Grid>
              </Grid> 
              <Grid item xs={12} mt={2}>
                  <CustomButton type="submit">
                    Submit
                  </CustomButton>
              </Grid>
          </Form>
        </FormikProvider>
      </Paper>
    </div>
  );
}

export default TranchUndoPopup;
