import { useState, lazy, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Rating, Tab, Tabs } from '@mui/material';
import React from 'react';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import Transactions from './Transactions';
import Setting from './Setting';
import UnitStatus from './UnitStatus';
import ExampleFile from './ExampleFile';

import DealTemplate from './DealTemplate';
import DealView from './DealView';
import { FetchDealDetail, FetchTransactions, FetchTranches, ExportDealInvestments } from '../../api';
import { useParams, useNavigate } from 'react-router';
import Loading from 'views/Loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { DescriptionOutlined, FileDownload, Language, Mail } from '@mui/icons-material';
import DocumentViewPopup from './DocumentViewPopup';
import Modal from 'ui-component/Modal';
import { toast, ToastContainer } from 'react-toastify';
import Clickable from 'utils/Clickable';
import { Tooltip, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { AiFillHome, AiFillCaretRight } from 'react-icons/ai';
import ModeIcon from '@mui/icons-material/Mode';
import Loadable from 'ui-component/Loadable';
import Cookies from 'js-cookie';
import DealDocument from './DealDocument';
import MISReports from './MISReports';
import { TabContext, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import CustomButton from 'ui-component/CustomButton';
import { height, lineHeight, textAlign } from '@mui/system';
import SendEmailPopup from './Popups/SendEmailPopup';
import Messages from './Messages';

// import ExcelSheet from './ExcelSheet';

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));

export default function ViewDeal({ view_type = 'dealDetail' }) {
    const { id } = useParams();
    const isEditMode = !!id;
    const {
        isLoading: isDealDetailLoading,
        isRefetching: isDealDetailRefetching,
        data: dealDetailData,
        status: dealDetailStatus,
        refetch: refetchListing
    } = FetchDealDetail(id);
    const {
        refetch: refetchExportDealInvestments,
        isLoading: isExportDealInvestmentsLoading,
        isRefetching: isExportDealInvestmentsRefetching,
        status: ExportDealInvestmentsStatus
    } = ExportDealInvestments(id);
    const {
        isLoading: isTransactionsLoading,
        isRefetching: isTransactionsRefetching,
        data: transactionsInvestorsData,
        refetch: refetchTransactions,
        status: TransactionStatus
    } = FetchTransactions(id);
    const {
        isLoading: isTranchesLoading,
        data: tranchesInvestorsData,
        refetch: refetchTranches,
        status: TranchesStatus
    } = FetchTranches(id);
    const routes = [
        { path: '/', breadcrumb: '' },

        {
            path: 'investors',
            breadcrumb: 'Investors',
            children: [
                { path: 'soa_uploads', breadcrumb: 'SOA Uploads' },
                { path: 'soa_uploads/:id', breadcrumb: 'SOA Zip Details' },
                { path: 'add_investor', breadcrumb: 'Add Investor' },
                { path: 'editInvestor', breadcrumb: null },
                { path: 'editInvestor/:id', breadcrumb: 'Edit Investor' },
                { path: ':id', breadcrumb: null },
                { path: 'viewInvestor', breadcrumb: null },
                { path: 'viewInvestor/:id', breadcrumb: 'View Investor' }
            ]
        }
    ];


    function downloadDealInvestments() {
        setTimeout(() => {
            refetchExportDealInvestments().then(({ data }) => {
                if ({ data }) {
                    const link = document.createElement('a');
                    link.href = data.url;
                    link.target = '_blank';
                    link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
                    link.click();
                } else {
                    console.error('Data is missing in the response:', data);
                }
            });
        }, 1000);
    }
    const [dealsModule, setDealModule] = useState(null);
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    const theme = useTheme();
    const breadcrumbs = useBreadcrumbs(routes, {
        excludePaths: ['/deals/confirmationList']
    });
    // Document Modal Open
    const [documentOpen, setDocumentOpen] = useState(false);
    const handleDocumentOpen = () => {
        setDocumentOpen(true);
    };
    useEffect(() => {
        const permission_values = localStorage.getItem('permission');
        const permissions = permission_values != undefined ? JSON.parse(permission_values) : [];
        const module = permissions.find((module) => module.module_name === 'deals' && module.permisson === true);

        setDealModule(module); // Set the value in the state
    }, []);
    const navigate = useNavigate();

    // const [isExampleFileVisible, setIsExampleFileVisible] = useState(false);
    // const handleShowExampleFile = () => {
    //     setIsExampleFileVisible(true);
    //   };

    const documentHandleClose = () => setDocumentOpen(false);

    // Mail Model open
    const [mailOpen, setMailOpen] = useState(false);
    const handleMailOpen = () => {
        setMailOpen(true);
    };
    const handleMailClose = () => setMailOpen(false);

    // console.log(dealDetailData,"dealDetailDatadealDetailDatadealDetailDatadealDetailData")
    const [value, setValue] = React.useState(view_type);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const schemeDataFile = dealDetailData?.startups?.document && dealDetailData?.startups?.document?.length > 0 ? dealDetailData?.startups?.document?.filter(item => item?.name == `${dealDetailData?.scheme_name} - Scheme Data`) : [];

    const classes = useStyles()
    return (
        <>
            <Modal open={documentOpen} onClose={documentHandleClose} closeIcon={true} style={{ width: '600px' }} disableScrollLock={true}>
                <DocumentViewPopup data={dealDetailData?.startups?.document} />
            </Modal>
            <Modal open={mailOpen} style={{ width: '600px',height: 550 }} onClose={handleMailClose} closeIcon={false}>
                <SendEmailPopup handleClose={handleMailClose} document={schemeDataFile} scheme_name={dealDetailData?.scheme_name} />
            </Modal> 
            <ToastContainer />
            {isDealDetailLoading ? (
                <Loading />
            ) : (
                <>
                    <BreadcrumbHeader title="View Deal" shouldGoBack />
                    <div className="custom-card card mb-3">
                        <div className="card-body px-2 py-2 text-center">
                            {/* <BreadcrumbHeader title="View Deal" shouldGoBack /> */}
                            <div class="deal-flow-block d-inline-flex">
                                <div class={dealDetailData.created_date != '' ? 'deal-flow-item completed' : 'deal-flow-item'}>
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Deal Created</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.created_date || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>
                                <div class={dealDetailData.meetings[0] ? 'deal-flow-item completed' : 'deal-flow-item'}>
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Pitch Call</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.meetings[0]?.meeting_date != 'Invalid date' && dealDetailData?.meetings[0]?.meeting_date || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class={
                                        dealDetailData?.deal_flow_date_format?.investment_started_date_format != undefined
                                            ? 'deal-flow-item completed'
                                            : 'deal-flow-item'
                                    }
                                >
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Investment Interest Started</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.deal_flow_date_format?.investment_started_date_format || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class={
                                        dealDetailData?.deal_flow_date_format?.investment_closing_date_format != undefined
                                            ? 'deal-flow-item completed'
                                            : 'deal-flow-item'
                                    }
                                >
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Investment Interest Closed</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.deal_flow_date_format?.investment_closing_date_format || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class={
                                        dealDetailData?.deal_flow_date_format?.call_money_started_date_format != undefined
                                            ? 'deal-flow-item completed'
                                            : 'deal-flow-item'
                                    }
                                >
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Call for Money Started</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.deal_flow_date_format?.call_money_started_date_format || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class={
                                        dealDetailData?.deal_flow_date_format?.call_money_closing_date_format != undefined
                                            ? 'deal-flow-item completed'
                                            : 'deal-flow-item'
                                    }
                                >
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Call for Money Closed</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.deal_flow_date_format?.call_money_closing_date_format || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class={
                                        dealDetailData?.deal_flow_date_format?.deal_completed_date_format != undefined
                                            ? 'deal-flow-item completed'
                                            : 'deal-flow-item'
                                    }
                                >
                                    <div class="circle-dot">
                                        <div class="v-value mb-0 font-12 fw-600 d-block">Invested</div>
                                        <div class="v-label text-gray font-10 fw-600 mb-0">
                                            {dealDetailData?.deal_flow_date_format?.deal_completed_date_format || <>&nbsp;</>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <Box sx={{ display: 'flex', alignItems: 'center' }} className='breadcrumb-box'>
                                <Link to='/' style={{ textDecoration: 'none' }}>
                                <AiFillHome style={{ position: 'relative', top: '2px', fontSize: '16px' }} color={theme.palette.secondary[800]} />
                                </Link>
                                {breadcrumbs.map(({ match, breadcrumb, key, location }) => (
                                <Box key={key}>
                                    {
                                    location.pathname !== key ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Link to={match.pathname} style={{ textDecoration: 'none', fontSize: '14px', color: theme.palette.secondary.main }}>
                                            {breadcrumb}
                                        </Link>
                                        <AiFillCaretRight style={{ marginLeft: '6px', marginRight: '6px', }} color={theme.palette.secondary[800]} />
                                        </Box>
                                    ) :
                                        <p style={{ textDecoration: 'none', fontSize: '14px', margin: 0, color: theme.palette.secondary[800], fontWeight: 700 }}>
                                        {breadcrumb}
                                        </p>
                                    }
                                </Box>
                                ))}
                            </Box> */}
                        </div>
                    </div>
                    <div className="custom-menu-block view-investor-block">
                        <TabContext value={value}>
                            <div className="deal-head-flex d-flex align-items-center mb-4">
                                <Clickable
                                    className="circle-box"
                                    onClick={() => navigate(`/startups/view-startup/${dealDetailData?.startups._id}`)}
                                >
                                    {dealDetailData?.startups?.image &&
                                        <img src={dealDetailData?.startups?.image} alt=""

                                        />}
                                </Clickable>
                                <div className="dhf-right">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Clickable
                                            className="xs-title font-16"
                                            onClick={() => navigate(`/startups/view-startup/${dealDetailData?.startups._id}`)}
                                        >
                                            {dealDetailData?.scheme_name} -{' '}
                                            <span className="dhf-company-name">{dealDetailData?.startups?.company_name}</span>
                                            <span>{dealDetailData?.serial_number != undefined && dealDetailData?.serial_number != "" && ` (${dealDetailData?.serial_number})`}</span>
                                        </Clickable>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                {/* <div className='d-flex align-items-center'>
                                                    <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Deal Status</div>
                                                    <div className="v-value font-13 fw-600 ps-2">{dealDetailData?.deal_status_view}</div>
                                                </div> */}

                                                <div
                                                    className="v-value font-13 fw-600 me-3 deal-status-value"
                                                    style={{
                                                        color: dealDetailData?.deal_status_view == 'In Process' ? '#d4a72d' : '#60cb5e',
                                                        backgroundColor:
                                                            dealDetailData?.deal_status_view === 'In Process' ? '#fef8e8' : '#ebfaeb',
                                                        border: 'none',
                                                        minWidth: '100px',
                                                        fontSize: '12px',
                                                        borderRadius: '4px',
                                                        fontWeight: 700,
                                                        borderWidth: '0.5px',
                                                        borderStyle: 'solid',
                                                        borderColor: dealDetailData?.deal_status_view === 'In Process' ? '#d4a72d' : '#60cb5e'
                                                    }}
                                                >
                                                    {dealDetailData?.deal_status_view}
                                                </div>
                                                {Cookies.get('is_super_admin') == 'true' && dealDetailData?.deal_status_view == 'Completed' && <Clickable
                                                    onClick={() => { handleMailOpen() }}
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Send Email to Kratos">
                                                        <Mail />
                                                    </Tooltip>
                                                </Clickable>}
                                                {Cookies.get('is_super_admin') == 'true' && dealDetailData?.deal_status_view == 'Completed' && <Clickable
                                                    onClick={() => { downloadDealInvestments() }}
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Export Investments">
                                                        <FileDownload />
                                                    </Tooltip>
                                                </Clickable>}
                                                <Clickable
                                                    onClick={() => window.open(dealDetailData?.startups?.website_url, 'blank')}
                                                    className="text-decoration-none icon text-center me-3"
                                                >
                                                    <Tooltip title="Website">
                                                        <Language />
                                                    </Tooltip>
                                                </Clickable>
                                                <Tooltip title="Documents">
                                                    <button
                                                        type="button"
                                                        className="text-decoration-none  icon text-center me-3"
                                                        onClick={handleDocumentOpen}
                                                    >
                                                        <DescriptionOutlined />
                                                    </button>
                                                </Tooltip>
                                                {dealsModule?.edit && (
                                                    <Tooltip title="Edit">
                                                        <div className="cursor-pointer">
                                                            <ModeIcon sx={{ color: '#777' }} onClick={() => navigate(`/deals/${id}/addDeal`)} />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div> */}
                                    <Box sx={{ maxWidth: { xs: '100%' }, bgcolor: 'background.paper', display: 'flex' }}>
                                        <Tabs
                                            sx={{ borderBottom: '1px solid rgba(0,0,0,.12)' }}
                                            className={classes.scrollTabs}
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={'auto'}
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            {dealsModule?.view && (<Tab label="Deal Details" value={'dealDetail'} />)}
                                            {dealDetailData?.checkListMaster && dealDetailData?.checkListMaster?.length > 0 && (
                                                dealDetailData?.checkListMaster?.map((template, index) => (
                                                    <Tab label={template.template_name} tabIndex={index} key={index} value={'TemplateA-tab' + index} />)
                                                ))
                                            }
                                            {dealsModule?.deal_investors && (<Tab label="Investors" value={'transaction'} />)}
                                            {dealsModule?.deal_nav && (<Tab label="Investments" value={'investments'} />)}
                                            {dealsModule?.deal_setting && (<Tab label="Settings" value={'setting'} />)}
                                            {(<Tab label="Documents" value={'dealdocument'} />)}
                                            {(<Tab label="Messages" value={'message_template'} />)}
                                            {dealsModule?.mis_report && dealDetailData?.startups?.misDocuments != undefined && dealDetailData?.startups?.misDocuments?.length > 0 && (<Tab label="Reports" value={'misreports'}/>)}
                                        </Tabs>
                                    </Box>
                                    {/* </div> */}
                                </div>
                            </div>

                            <TabPanel sx={{ padding: 0 }} value="dealDetail">
                                <DealView dealDetailData={dealDetailData} dealsModule={dealsModule} />
                            </TabPanel>
                            {dealDetailData.checkListMaster && dealDetailData.checkListMaster.length > 0 ? (
                                dealDetailData.checkListMaster.map((template, index) => (
                                    <TabPanel sx={{ padding: 0 }} value={'TemplateA-tab' + index}>
                                        <DealTemplate
                                            tamplate_data={template}
                                            refetchListing={refetchListing}
                                            dealsModule={dealsModule}
                                            dealId={id}
                                        />
                                    </TabPanel>)))
                                : <></>}
                            <TabPanel sx={{ padding: 0 }} value="transaction">
                                <Transactions
                                    setHeader={true}
                                    dealsModule={dealsModule}
                                    transactionsInvestorsData={transactionsInvestorsData}
                                    refetchTransactions={refetchTransactions}
                                    isTransactionsLoading={isTransactionsLoading}
                                    refetchTranches={refetchTranches}
                                    fees={dealDetailData.fees_management}
                                    lead_investorList={dealDetailData?.lead_investors}
                                />
                            </TabPanel>
                            <TabPanel sx={{ padding: 0 }} value="investments">
                                <ExampleFile
                                    deal_completed_date={dealDetailData?.deal_flow_date_format?.deal_completed_date_format}
                                    deal_status={dealDetailData?.deal_status}
                                    deal_current_value={dealDetailData?.current_unit_value}
                                    refetchListing={refetchListing}
                                    dealsModule={dealsModule}
                                    isTranchesLoading={isTranchesLoading}
                                    tranchesInvestorsData={tranchesInvestorsData}
                                    refetchTranches={refetchTranches}
                                    TranchesStatus={TranchesStatus}
                                    refetchTransactions={refetchTransactions}
                                    is_refech_calling={view_type == 'investments' ? 1 : 0}
                                />
                            </TabPanel>
                            <TabPanel sx={{ padding: 0 }} value="setting"><Setting checklistData={dealDetailData?.checkListMaster} dealsModule={dealsModule} /></TabPanel>
                            <TabPanel sx={{ padding: 0 }} value="dealdocument"><DealDocument data={dealDetailData?.startups?.document} /></TabPanel>
                            <TabPanel sx={{padding: 0}} value="misreports"><MISReports data={dealDetailData?.startups?.misDocuments != undefined && dealDetailData?.startups?.misDocuments?.length > 0 ? dealDetailData?.startups?.misDocuments : []}/></TabPanel>
                            <TabPanel sx={{ padding: 0 }} value="message_template"><Messages  /></TabPanel>
                        </TabContext>
                    </div>
                </>
            )}
        </>
    );
}
