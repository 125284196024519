import { useState, useEffect,useRef } from 'react';
import { Paper ,Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Button, Box } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import CustomAutoComplete from 'ui-component/formik/CustomAutoComplete';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from 'react-icons/im';
import {UpdateTransferUnits } from 'api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles, withStyles } from '@mui/styles';
import React from 'react';
import { height, padding, width } from '@mui/system';
import { Upload } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import investor from 'layout/MainLayout/Sidebar/MenuList/investor';


const useStyles = makeStyles({

});
const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '10px 0 0 10px',
                borderRightWidth: '0px',
                borderColor:'red'
            }
        }
    }
    
})(TextField);

const TransferTranchPopup = ({
    activeRow,
    ApprovedInvestorsData,
    check_investor_values,
    handleClose,
    scheme_name,
    refetchTransactions,
    refetchTransferUnitData,
    unitClassData,
}) => {
    const [logPage, setLogPage] = useState(1);
    const theme = useTheme();
    const classes = useStyles();
    const [unit_class_obj, setUnitClassObject] = useState([]);
    const [change_input, setChangeInput] = React.useState(false);
    const {
        isLoading: isTransferUnitDetailLoading,
        mutateAsync: UpdateMasterTemplate,
    } = UpdateTransferUnits(activeRow.transactionId);
    
 

    console.log(check_investor_values,"check_investor_values")
    // console.log(dealId,"deal")
 
    const formik = useFormik({
        initialValues: {
            selectedInvestor: activeRow
                ? [
                    {
                        investor_name: activeRow?.investor_name || "",
                        transaction_id: activeRow?._id || "",
                        investor_id: null,
                        transfer_unit: "",
                        document: [{ file: null }],
                    },
                ]
                : [],
            
            // unit_class_id:null,
            // transfer_unit:null,
        },
        onSubmit: async (values) => {
            const formData = new FormData();
            console.log('Form Submitted', values);
            
            values?.selectedInvestor?.map((investor) => {
                formData.append('transaction_id' ,investor?.transaction_id)
                formData.append('investor_id' ,investor?.investor_id?._id)
                formData.append('transfer_unit' ,String(investor?.transfer_unit));
                formData.append('document', investor?.document[0]?.file);
            });
           
            UpdateMasterTemplate(
                {payload: formData},
                {
                    onSuccess: () => {
                        setTimeout(() => {
                            refetchTransactions();
                            refetchTransferUnitData();
                        }, 200);
                        handleClose();
                        toast.success('Transfer Unit Updated', {
                            position: 'top-left',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                    },
                    onError: (err) => {
                        // console.log(JSON.parse(err.request.response).message);
                        toast.error(JSON.parse(err.request.response).message, {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        });
                    }
                }
            );
        // }
        }
    });
    const handleTransferUnitChange = (e, index) => {
        const value = e.target.value;
        const availableUnits = activeRow?.unit_alloted || 0;
        if (Number(value) <= availableUnits) {
            formik.setFieldValue(`selectedInvestor[${index}].transfer_unit`, value);
        }
    };

    
    return (
        <div>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh',
                    
                }}
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10 }}>
                        <h2 style={{ textAlign: 'center' }}>Transfer Units</h2>
                        {formik.values?.selectedInvestor && formik.values?.selectedInvestor?.length > 0 && activeRow &&(
                        <Grid container spacing={2}>
                        <Grid item md={4}>
                            <h4>Deal Name : {scheme_name}</h4>
                        </Grid>
                        <Grid item md={4}>
                            <h4>Investor : {activeRow?.investor_name}</h4>
                        </Grid>
                        <Grid item md={4}>
                            <h4>Available Units : {activeRow?.unit_alloted}</h4>
                        </Grid>
                        </Grid>
                        )}
                        {formik.values?.selectedInvestor && formik.values?.selectedInvestor?.map((investor,index)=>(

                        <Grid item md={12} sm={12} xs={12} key={index}>
                        <div className="custom-card card box-shadow-none">
                         <div className="card-body">
                         <Grid
                            xs={12}
                            container
                            direction="row"
                            columnSpacing={1}
                            rowSpacing={1}
                            // key={index}
                        >
                        <Grid item md={3} sm={6} xs={12}>
                        <CustomField label="Transfer unit" 
                            name={`selectedInvestor[${index}].transfer_unit`}
                            // value={formik.values.selectedInvestor[index].transfer_unit}
                            onChange={(e) => handleTransferUnitChange(e, index)}
                            
                            />
                            </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <CustomAutoComplete
                                label="Investors"
                                name={`selectedInvestor.${index}.investor_id`}
                                value={formik.values.selectedInvestor[index].investor_id}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '8px',
                                    },
                                }}
                                options={[...ApprovedInvestorsData]}
                                getOptionLabel={(option) => {
                                    return `${option.name} ${option?.lp_code ? `(${option.lp_code})` : ``} `
                                }}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        {option.name}{option.lp_code ? ` (${option.lp_code})` : ` `}
                                    </Box>
                                )}
                            /> 
                            </Grid>
                        <Grid item md={5} sm={6} xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center', height:'51px' }}>
                    <CustomTextField
                        fullWidth
                        disabled
                        label="Upload File"
                        name={ `selectedInvestor.${index}.document.[0].file`}
                        value={
                            formik.values.selectedInvestor[index]?.document[0]?.file  ? formik.values.selectedInvestor[index].document[0].file.name : ''
                            }
                        />
                        <Button
                        variant="contained"
                        component="label"
                        disableElevation
                        style={{
                            borderRadius: '0px 10px 10px 0px',
                            height: '51px'
                        }}
                        >
                        Upload
                        <input
                            hidden
                            accept="application/pdf, image/*"
                            type="file"
                            // id={`dynamic-file-${index}`}
                             onChange={(e) => {
                                        if (e.target.files.length > 0) {
                                            formik.setFieldValue(
                                                `selectedInvestor.${index}.document.[0].file`,
                                                e.target.files[0]
                                            );
                                        } else {
                                            formik.setFieldValue(
                                                `selectedInvestor.${index}.document.[0].file`,
                                                null
                                            );
                                        }
                                    }}
                                    // onChange={(e) => {handleFileChange()}}
                                     />
                                    </Button>
                            </div>
                            </Grid>

                         </Grid>   
                         </div>   
                         </div>
                        
                        </Grid>
                        ))}


                        <Grid item container xs={12} gap={4} mt={2}>
                            <Grid item xs={1}>
                                    <CustomButton type="submit">Submit</CustomButton>
                            </Grid>
                        </Grid>
                    </Form>
                   
                </FormikProvider>
            
            </Paper>
        </div>
    );
};

export default TransferTranchPopup;
