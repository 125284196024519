import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';



export const FetchDocument= ({page, search, sort=1, limit=20 , parent_id=""}) => {
  return useQuery(
    ['document-listing', page, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/drive?page=${page}&searchText=${search}&sort=${sort}&limit=${limit}&parent_id=${parent_id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
        },
      }) 
  },{
    select: (data)=>({documents: data.data.data, parent_data:data.data.parent_data, count: data.data.count, limit:data.data.limit}),
    refetchOnWindowFocus:false,
    refetchOnMount:true,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}


export const AddDocument = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/drive`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'multipart/form-data'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const DownloadFile = () => {
  return useMutation(({id}) => {
    return axios({
      method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/drive/download?drive_id=${id}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
      // data:payload
    })
  }, {
    enabled: false,
    refetchOnWindowFocus: false,
    // retry: (failureCount, error) => {
    //   return RetryOnError(failureCount, error);
    // },
    // staleTime: 0,
    // onError: (err) => {
    //   return verifyToken(err);
    // },
  }) 
}

export const DownloadStampFile = () => {
  return useMutation(({id}) => {
    return axios({
      method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/stamp/${id}`,
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
      // data:payload
    })
  }, {
    enabled: false,
    refetchOnWindowFocus: false,
    // retry: (failureCount, error) => {
    //   return RetryOnError(failureCount, error);
    // },
    // staleTime: 0,
    // onError: (err) => {
    //   return verifyToken(err);
    // },
  }) 
}


// export const DownloadFile = (id) => {
//   return useQuery(
//     ['download-aws-file'],
//     async () => {
//       try {
//         const response = await axios({
//           method: 'get',
//           url: `${process.env.REACT_APP_BASE_URL}/admin/drive/download?id=${id}`,
//           headers: {
//             'Authorization': `Bearer ${Cookies.get('jwt')}`
//           },
//         });
//         return response.data;
//       } catch (error) {
//         // Handle error
//         console.error(error);
//         throw error;
//       }
//     },
//     {
//       enabled: false,
//       refetchOnWindowFocus: false,
//       retry: (failureCount, error) => {
//         return RetryOnError(failureCount, error);
//       },
//       staleTime: 0,
//       onError: (err) => {
//         return verifyToken(err);
//       },
//     }
//   );
// };

export const DeleteDriveDocument = () => {
  return useMutation((id) => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/admin/drive/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const DocumentTagsList = () => {
  return useQuery(
      ['tags-list'],
      async () => {
          try {
              const response = await axios({
                  method: 'get',
                  url: `${process.env.REACT_APP_BASE_URL}/admin/setting/tags?type=drive`,
                  headers: {
                      Authorization: `Bearer ${Cookies.get('jwt')}`
                  }
              });

              const tagList = response.data.data.map((tag) => ({
                  id: tag._id, // Use the appropriate property for the id
                  text: tag.name
              }));

              return tagList;
          } catch (error) {
              throw error;
          }
      },
      {
          refetchOnWindowFocus: false
      }
  );
};