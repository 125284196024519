import { useState, useEffect, useRef } from 'react';
import { Paper, TextField, Chip } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import Autocomplete from '@mui/material/Autocomplete';
import shortid from "shortid";
import {DocumentTagsList} from 'api';
import {AddDocument } from 'api';
import { toast, ToastContainer } from 'react-toastify';

const initialValues = {
    drive_document: null,
    document:null,
    tagsData:[],
}
const NewFileUpload = ({ onClose,parent_id }) => {
    const theme = useTheme();
    const [tags, setTags] = useState([]);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const { data: tagData, isLoading: isTagsLoading } = DocumentTagsList();

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = { id: inputValue, text: inputValue };
            setTags([...tags, newTag]);
            setInputValue('');
        }
    };
    const handleDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
      };

      const handleAddition = (newTags) => {
    
        const uniqueNewTags = newTags.filter((newTag) =>
          !tags.some((tag) => tag.text === newTag.text)
        );
        
        setTags((prevTags) => [...prevTags, ...uniqueNewTags]);
      };

    const { mutateAsync: uploadFileFolder, status: uploadFileFolderStatus, error: uploadFileFolderError } = AddDocument();
    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);

    useEffect(() => {
        if (tagData) {
          setTagSuggestions(tagData);
        //   const idArray = investor.tags.split(',');
        //   const filteredData = tagData.filter(item => idArray.includes(item.id));
        //   if(filteredData.length > 0){
        //     setTags(filteredData);
        //   }
        }
      }, [tagData]);


      const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();
            const tagData = tags.map(tag => ({ name: tag.text }));
              if (values?.document?.length > 0) {
                values.document.forEach((file, index) => {
                  formData.append(`drive_document`, file);
                });
              }
              if (tagData?.length > 0) {
                    const arrayOfTags = JSON.stringify(tagData);
                    formData.append(`tagsData`, arrayOfTags);
              }
              if(parent_id != ""){
                formData.append("parent_id", parent_id)
              }
            uploadFileFolder({ payload: formData })
            }
    })  
    useEffect(() => {
        if (uploadFileFolderStatus == 'success') {
          toast.success('File Upload!', {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
                onClose()
            }
          });
        }
        if (uploadFileFolderStatus == 'error') {
          toast.error(uploadFileFolderError?.response?.data?.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
    
        }
      }, [uploadFileFolderStatus])

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
            if (e.target.files.length > 0) {
                let document_values =  formik.values.document;
                if(document_values != null){                
                    formik.setFieldValue("document",[...document_values,...Array.from(e.target.files)])
                }else{
                    formik.setFieldValue("document",Array.from(e.target.files) )
                }
            } else {
              formik.setFieldValue("document", null)
            }
        // --For Multiple File Input
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: shortid.generate(),
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }
    
    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      }

    const DeleteSelectFile = (id,filename) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
            let document_values =  formik.values.document;
            const filteredFiles = document_values.filter(file => file["name"] !==  filename);
            formik.setFieldValue("document", filteredFiles)
        } else {
            // alert('No');
        }


    
    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit 
        e.target.reset();
        if (selectedfile.length > 0) {
            for (let index = 0; index < selectedfile.length; index++) {
                SetFiles((preValue) => {
                    return [
                        ...preValue,
                        selectedfile[index]
                    ]
                })
            }
            SetSelectedFile([]);
        } else {
            alert('Please select file')
        }
    }


    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = Files.filter((data) => data.id !== id);
            SetFiles(result);
        } else {
            // alert('No');
        }
    }

    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px 30px 20px', flex: 1, maxHeight: "calc(100vh - 70px)", overflowY:"auto" }}>
                <h3 style={{ textAlign: 'left', }}>New File Upload</h3>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}  style={{ paddingTop: '10px', paddingBottom: '15px', margin: 0 }}>
                        <div className='custom-form'>
                            <div className="fileupload-view mb-4">
                                <div className="kb-data-box">
                                    <div className="kb-file-upload">
                                        <div className="file-upload-box">
                                            <input type="file" id="fileupload"  name="document" className="file-upload-input" onChange={InputChange} multiple />
                                            <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                                        </div>
                                    </div>
                                    <div className="kb-attach-box mb-3 mt-2">
                                        {
                                            selectedfile.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={id}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p></p>
                                                            <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id,filename)}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {Files.length > 0 ?
                                        <div className="kb-attach-box">
                                            <hr />
                                            {
                                                Files.map((data, index) => {
                                                    const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                    return (
                                                        <div className="file-atc-box" key={index}>
                                                            {
                                                                filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                    <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                    <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                            }
                                                            <div className="file-detail">
                                                                <h6>{filename}</h6>
                                                                <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                                                <div className="file-actions">
                                                                    <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
                                                                    <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <Autocomplete className='crossdisable'
                                multiple
                                id="tag-input"
                                name="tagsData"
                                options={tagSuggestions}
                                getOptionLabel={(option) => option.text}
                                value={tags}
                                onChange={(_, newValue) => handleAddition(newValue)}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={option.id}
                                            label={option.text}
                                            {...getTagProps({ index })}
                                            onDelete={() => handleDelete(option)}
                                        />
                                    ))
                                }
                                inputValue={inputValue}
                                onInputChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tags" placeholder="Enter tags" />
                                )}
                            />
                            <div className='mt-4 text-end'>
                                <button type="submit" className='button-79 transparent-btn me-2' onClick={onClose}>Cancel</button>
                                <button type="submit" className='button-79'>Upload</button>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </Paper>
        </>
    )
}

export default NewFileUpload