import React, { useState,useEffect} from 'react';
import { Paper, Box,Typography} from '@mui/material';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import { FetchOnboarding,ExportInvestorsOnboarded } from 'api';
import moment from 'moment';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Card from 'ui-component/cards/Card';
// const columns = [
//     {
//       label: 'Investor Name',
//       minWidth: 170,
//       align: 'left',
//       type: 'name',
//       //   link: true,
//     },
//     {
//       label: 'Representative',
//       minWidth: 250,
//       align: 'left',
//       type: 'representative_name'
//     },
//     {
//       label: 'On-Boarding Start Date',
//       minWidth: 150,
//       align: 'left',
//       type: 'createdAt'
//     },
//     {
//       label: 'On-Boarding End Date',
//       minWidth: 150,
//       align: 'left',
//       type: 'on_board_date'
//     },
//     {
//       label: 'Remark',
//       minWidth: 150,
//       align: 'center',
//       type: 'kyc_status'
//     },
//     // {
//     //   label: 'Actions',
//     //   minWidth: 150,
//     //   align: 'right',
//     //   type: 'actions'
//     // },
//   ];
const mode = ""
const InvestorOnboarding = () => {
  const excel_report = "excel_report";
  const [title, setTitle] = useState('Investors Onboarding Summary ');
  const theme = useTheme();
  const [page, setPage] = useState();
  const [sort, setSort] = useState(-1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [from_on_board_date, setFromDate] = useState(null);
  const [to_on_board_date, setToDate] = useState(null);
  const [searchStatus, setSearchStatus] = useState(false);
  const [reset, setreset] = useState(false)
  const [currentOnboardedDate, setCurrentOnboardedDate] = useState(moment().format('YYYY-MM-DD'));
 const [isExportLoading, setIsExportLoading] = useState(false);
 const [investorData, setInvestorData] = useState([]);
 useEffect(() => {
  if (investorData?.count > 0 ) {
    setTitle(`Investors Onboarding Summary (${investorData?.count})`)
  } else {
    setTitle(`Investors Onboarding Summary`)
  }
}, [investorData])

 
    const { mutateAsync: fetchOnbardingInvestor, isLoading: isInvestorLoading, isRefetching: isSyndicateRefetching } = FetchOnboarding()
    const { refetch: refetchExportVenturePartners, isLoading: isExportVenturePartners,isRefetching: isExportVenturePartnersRefetching } = ExportInvestorsOnboarded(from_on_board_date,to_on_board_date,excel_report);
    useEffect(() => {
      fetchOnbardingInvestor({ "from_on_board_date":from_on_board_date,"to_on_board_date":to_on_board_date
      })
        .then(({ data }) => {
          setInvestorData(data)
        })
      }, [reset])

      useEffect(() => {
        if (searchStatus) {
          fetchOnbardingInvestor({ "from_on_board_date":from_on_board_date,"to_on_board_date":to_on_board_date
                    })
                      .then(({ data }) => {
                        setInvestorData(data)
            })
        }
      }, [searchStatus]);
      
      const handleOnboardedFromDateChange = (newValue) => {
        const formattedDate = moment(newValue).format('YYYY-MM-DD');
        setFromDate(formattedDate);
        // If "Registered To Date" is empty, set it to the current date
        if (!to_on_board_date) {
            setToDate(currentOnboardedDate)
        }
        setSearchStatus(false);
      };
      const handleOnboardedToDateChange = (newValue) => {
        const formattedDate = moment(newValue).format('YYYY-MM-DD');
        setToDate(formattedDate);
        // If "Registered To Date" is empty, set it to the current date
        if (!from_on_board_date) {
          setFromDate(formattedDate)
        }
        setSearchStatus(false);
      };
      function download() {
        setIsExportLoading(true);
        refetchExportVenturePartners()
          .then(({ data }) => {
            setIsExportLoading(false);
      
            const link = document.createElement('a');
            link.href = data.url;
            link.target = '_blank';   
            link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
            link.click();
          })
      } 
    return(
        <>
           <Box sx={{ width: '100%' }}>
             <BreadcrumbHeader title={title} />
           </Box>  
          <Card style={{ paddingLeft: '16px', paddingRight: '16px' }}> 
            <Grid container direction="row" columnSpacing={3} rowSpacing={2} mb={2} >
              <Grid item sm={4} xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Onboarding From Date"
                    value={from_on_board_date}
                    disableFuture={true}
                    // onChange={(newValue) => formik.setFieldValue("from_on_board_date", moment(newValue).format('YYYY-MM-DD'))}
                    onChange={handleOnboardedFromDateChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    inputFormat="DD/MM/YYYY"
                    autoComplete="off"
                        // openTo="year"
                        />
                  </LocalizationProvider>
              </Grid>
              <Grid item sm={4} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Onboarding To Date"
                          value={to_on_board_date}
                          disableFuture={true}
                          onChange={handleOnboardedToDateChange}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          inputFormat="DD/MM/YYYY"
                          autoComplete="off"
                        // openTo="year"
                        />
                    </LocalizationProvider>
              </Grid>
              <Grid item sm={1} xs={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <button
                className="button-79"
                style={{ padding: '0.4rem 1rem', display: 'flex', alignItems: 'center', height: '40px', background: (searchStatus && (from_on_board_date !== '' || to_on_board_date !== '')) ? "#c62828" : "#283593" }}
                type='button'
                onClick={() => {
                    if (searchStatus && (from_on_board_date !== '' || to_on_board_date !== '')) {
                    setFromDate(null)
                    setToDate(null)
                    // setStatus('')
                    setreset(!reset)
                    }
                    setSearchStatus((oldVal) => !oldVal)

                }}
                fullWidth
                // sx={{height:'100%'}}
                >
                {(searchStatus && (from_on_board_date !== '' || to_on_board_date !== '')) ? "Reset" : "Search"}
                </button>
                {investorData?.count > 0 &&
                <CustomButton
                    onClick={download}
                    size="medium"
                    // loading={isExportLoading}
                    sx={{ height: '40px', marginLeft: '20px' }} 
                    >
                    Export
                </CustomButton>
            }
              </Grid>
            </Grid>            
           {/* {investorData?.data.length < 0 ? <Loading /> */}
              {/* :  */}
           <>
            <div className='custom-table table-responsive lp-summary-table investor-onboarding-table' style={{maxHeight: '70vh'}}>
            {investorData.count > 0 && (
                <div className='col-lg-9 col-9 offset-lg-1' >
                    <div className='row cpcs-cards' >
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>Approved</div>
                                <div className='value-text text-center'> {investorData?.kycCountData?.approved}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>KYC Cancelled</div>
                                <div className='value-text text-center'> {investorData?.kycCountData?.kyc_cancelled}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>KYC In-Progress</div>
                                <div className='value-text text-center'>  {investorData?.kycCountData?.kyc_in_review}</div>
                            </div>
                        </div> 
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>KYC Pending</div>
                                <div className='value-text text-center'>  {investorData?.kycCountData?.pending}</div>
                            </div>
                        </div> 
                        <div className='col-md-4 col-sm-6 col-6'>
                            <div className='cs-card'>
                                <div className='sm-title text-center'>Total</div>
                                <div className='value-text text-center'>  {investorData?.count}</div>
                            </div>
                        </div> 
                    </div>
                </div>
            )}
              <table className='w-100 table add-border'>
                  {/* <thead>
                    <tr>
                      <th style={{fontWeight: "900"}}>Approved: {investorData?.kycCountData?.approved}</th>
                      <th style={{fontWeight: "900"}}>KYC Cancelled: {investorData?.kycCountData?.kyc_cancelled}</th>
                      <th style={{fontWeight: "900"}}>KYC In-Progress: {investorData?.kycCountData?.kyc_in_review}</th>
                      <th style={{fontWeight: "900"}}>Investor KYC Pending: {investorData?.kycCountData?.pending}</th>
                      <th style={{fontWeight: "900"}}>Total: {investorData?.kycCountData?.pending}</th>
                    </tr>
                    <tr>
                      <th>Investor Name</th>
                      <th>Representative</th>
                      <th>On-Boarding Start Date</th>
                      <th>On-Boarding End Date</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                  {investorData.count > 0 ? (
                       investorData.data.map((partner, index) => (
                    <tr>
                      <td>{partner.name}</td>
                      <td>{partner.representative_name}</td>
                      <td>{partner.createdAt}</td>
                      <td>{partner.on_board_date}</td>
                      <td>{partner.kyc_status}</td>
                    </tr>
                    ))
                    ) : (
                      <tr>
                          <td colSpan="5" align="center">
                              
                               <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Records Found!</div>
                          
                          </td>
                      </tr>
                  )}
                    
                  </tbody> */}
                {investorData.count > 0 && (
                   <thead style={{position: 'sticky', top: '-1px',borderRadius: '8px !important'}}>
                   {/* <tr >
                    <th style={{ fontWeight: "900" }}>Approved: {investorData?.kycCountData?.approved}</th>
                    <th style={{ fontWeight: "900" }}>KYC Cancelled: {investorData?.kycCountData?.kyc_cancelled}</th>
                    <th style={{ fontWeight: "900" }}>KYC In-Progress: {investorData?.kycCountData?.kyc_in_review}</th>
                    <th style={{ fontWeight: "900" }}>Investor KYC Pending: {investorData?.kycCountData?.pending}</th>
                    <th style={{ fontWeight: "900" }} colSpan={2}>Total: {investorData?.count}</th>
                   </tr> */}
                   <tr>
                    <th>Investor Name</th>
                    <th>Partner</th>
                    <th>Representative</th>
                    <th>On-Boarding Start Date</th>
                    <th>On-Boarding End Date</th>
                    <th>Status</th>
                   </tr>
                  </thead>
                )}
              <tbody>
            {investorData.count > 0 ? (
              investorData.data.map((partner, index) => (
               <tr key={index}>
                 <td>{partner.name}</td>
                 <td>{partner.partner_name}</td>
                 <td>{partner.representative_name}</td>
                 <td>{partner.createdAt}</td>
                 <td>{partner.on_board_date}</td>
                  <td>{partner.kyc_status}</td>
                </tr>
              ))
            ) : (
              <tr>
               <td className='text-center' colSpan={5}><Typography variant="h4">Select a Valid Date Range</Typography></td>
              </tr>
             )}
            </tbody>
              </table>
            </div>  
           </>
         </Card>
    </>
  )
}
export default InvestorOnboarding