// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - investor reducer
export const OPEN_MODAL = '@investor/OPEN_MODAL';
export const CLOSE_MODAL = '@investor/CLOSE_MODAL';
export const UPDATE_FILTERS = '@investor/UPDATE_FILTERS';
export const RESET_FILTERS = '@investor/RESET_FILTERS';


//