import { IconFile, IconFolderPlus, IconGridDots, IconList, IconPlus } from '@tabler/icons';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown, } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const UploadDocumentHeader = ({
        anchorEl, 
        setAnchorEl, 
        openFolderCreateModal, 
        openFileUploadModal,
        handleClick, 
        handleClose, 
        listViewType, 
        gridView, 
        listView,
        open
    }) => {
    return (
        <>
                <div className='d-flex align-items-center'>
                    <div>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDown />}
                            className='theme-btn'
                        >
                            <IconPlus />&nbsp;&nbsp; New
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={openFolderCreateModal} >
                                <IconFolderPlus /> &nbsp;&nbsp;New Folder
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem onClick={openFileUploadModal} >
                                <IconFile /> &nbsp;&nbsp;File Upload
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose} className='file-upload-menu'>
                                <IconFile /> &nbsp;&nbsp;File Upload
                                <input id='input-file' type='file' />
                            </MenuItem> */}
                        </StyledMenu>
                    </div>
                    <div className='view-type ms-3'>
                        {!listViewType ?
                            <button className='transparent-btn' type='button' onClick={gridView}><IconGridDots /></button> :
                            <button className='transparent-btn' type='button' onClick={listView}><IconList /></button>
                        }

                    </div>
                </div>
        </>
    )
}

export default UploadDocumentHeader