import * as React from 'react';
import { Button, Paper, Skeleton, Box, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { useSelector } from 'react-redux';
import { ImCloudUpload } from 'react-icons/im';
import { UpdateDealStatusUnit, FetchUnitDealLogs, CreateMultiTrans, FetchUnitDealLogsNav, FetchTranchesLog } from 'api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Loading from 'views/Loading';
import Checkbox from '@mui/material/Checkbox';
import { useParams, useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { formatNumberWithCommas } from 'ui-component/FormatNumberWithCommas';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Modal from 'ui-component/Modal';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import DealTranchConfirmationPopup from './Popups/DealTranchConfirmationPopup';

const validationSchema = yup.object({
    // min_investment_amount: yup
    // .string('Enter Min Investment Amount')
    // .required('Min Investment Amount is required')
    // .trim(),
    // max_investment_amount: yup
    // .string('Enter Max Investment Amount')
    // .required('Max Investment Amount is required')
    // .trim(),
    // closing_date: yup
    // .string('Enter Closing Date')
    // .required('Closing Date is required')
    // .trim(),
    // pitch_recording: yup
    // .string('Enter Pitch Recording')
    // .required('Pitch Recording is required')
    // .trim(),
    comment: yup.string('Comment Recording').required('Comment is required').trim()
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const columns = [
    {
        label: 'Stage',
        minWidth: 0,
        align: 'left',
        type: 'stage_name'
    },
    {
        label: 'Comment',
        minWidth: 0,
        align: 'left',
        type: 'comment'
    },
    {
        label: 'Updated At',
        minWidth: 0,
        align: 'left',
        type: 'created_date'
    },
    {
        label: 'Updated  By',
        minWidth: 0,
        align: 'left',
        type: 'admin_name'
    },

    {
        label: 'Type',
        minWidth: 0,
        align: 'left',
        type: 'type'
    }
];

const useStyles = makeStyles({
    customTable: {
        '& td.MuiTableCell-root': { padding: '10px 16px 10px 16px' },
        '& .MuiTableCell-head': {
            color: 'white',
            backgroundColor: '#0000FF'
        }
    }
});

const DealTranchePopup = ({
    handleClose,
    dealId,
    mode,
    deal_status,
    deal_completed_date,
    deal_current_value,
    scemeName,
    setCheckValues,
    refetchTransactions,
    refetchTranches,
    CheckValuesCount,
    ref_amount_total,
    checkInvestor,
    check_investor_values,
    investor_invest_value,
}) => {

    const [logPage, setLogPage] = React.useState(1);
    const theme = useTheme();
    const classes = useStyles();


    const { id: deal_id } = useParams();
    const { data: tranches, isLoading: isTranchesLogLoading, refetch: refetchTranchesListing } = FetchTranchesLog(deal_id);
    const navigate = useNavigate();
    //   const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = UpdateDealStatusUnit();
    const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = CreateMultiTrans();
    //   const isCompletedMode = mode === "completed";
    const today = new Date();
    const formattedDate = today.toISOString();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    // const [model_apear, setModelOpen] = React.useState(true);
    const [change_input, setChangeInput] = React.useState(false);

    const handleCloseModel = () => { setOpen(false) }
    const handleOpenModel = () => { setOpen(true) }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const formik = useFormik({
        initialValues: {
            // deal_status: deal_status,
            unit_value: 1000,
            deal_completed_date: formattedDate,
            comment: '',
            transaction_ids: setCheckValues,
            tranch_name: '',
            perchange_value: 100,
            investor_transactions: investor_invest_value,
            is_email_sent: false,
            test_emails: []
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let tranch_values = [];
            setCheckValues.forEach((checkbox, index) => {
                tranch_values.push({
                    "transaction_id": checkbox,
                    "invested_amount": values.investor_transactions[index].toString(),
                    "remaining_amount": (Number(investor_invest_value[index]) - Number(values.investor_transactions[index])).toString(),
                })
            })

            const payload = {

                // deal_status: "completed",
                current_unit_value: values.unit_value.toString(),
                comment: values.comment,
                unit_alloted_date: values.deal_completed_date,
                tranch_name: values.tranch_name,
                investor_transactions: tranch_values,
                is_email_sent: values?.is_email_sent,
                test_emails: values?.is_email_sent ? values?.test_emails : []
            };

            UpdateDealStatus(
                { payload, id: dealId },
                {
                    onSuccess: (succ) => {
                        setTimeout(() => {
                            refetchTransactions();
                            refetchTranches();
                        }, 200);
                        toast.success(payload?.is_email_sent ? `Test Email is sent` : JSON.parse(succ.request.response).message, {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        },5000);
                        closeConfirmationPopup()
                        !payload?.is_email_sent && handleClose();
                    },
                    onError: (err) => {
                        // console.log(JSON.parse(err.request.response).message);
                        toast.error(JSON.parse(err.request.response).message, {
                            position: 'top-left',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        },5000);
                        // handleClose();
                    }
                }
            );
        }
    });

    const openConfirmationPopup = () => {
        formik.values?.tranch_name != '' && formik.values?.comment != '' && setOpen1(true);
        let value = formik.values?.tranch_name == '' ? 0 : formik.values?.comment == '' ? 1 : 2;
        switch (value) {
            case 0: toast.error('Tranch Name is Required', {
                position: 'top-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            }, 5000);
                break;
            case 1: toast.error('Comment is Required', {
                position: 'top-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            }, 5000);
                break;
        }
    }

    const handleCloseConfirmModel = () => {
        investor_invest_value.forEach((checkbox, index) => {
            formik.setFieldValue(`investor_transactions.${index}`, (checkbox * formik.values.perchange_value) / 100);
        });
        setOpen(false);
        setChangeInput(false);
    }

    const closeConfirmationPopup = () => {
        formik.setFieldValue('is_email_sent', false);
        formik.setFieldValue('test_emails', []);
        setOpen1(false)
    }
    const handleConfirm = (activeRow) => {
        // console.log(formik.values, 'formik.values')
        formik.handleSubmit()
        setOpen1(false)
    }

    // console.log(tranches?.deal_unit_changes?.length,"newLogsssss")
    return (
        <div>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
            >
                {<Modal open={open} onClose={handleCloseModel} closeIcon={true}>
                    <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '40px 10px 10px 10px', flex: 1 }}>
                        <b style={{ margin: 0, fontSize: '21px', margin: '16px' }}>Alert</b>
                        <div className="modal-body">
                            <p>If you change the percentage value all remaining amounts change accordingly!</p>
                        </div>
                        <div className="modal-footer">
                            <Button sx={{ backgroundColor: theme.palette.success.main, color: '#fff', ":hover": { backgroundColor: theme.palette.success.dark } }} onClick={() => handleCloseConfirmModel()}>Confirm</Button>
                            <Button sx={{ backgroundColor: theme.palette.error.main, color: '#fff', ":hover": { backgroundColor: "#ee3535" } }} onClick={handleCloseModel}>Close</Button>
                        </div>
                    </Paper>
                </Modal>}

                <Modal style={{ width: '600px' }} open={open1} onClose={closeConfirmationPopup} closeIcon={true}>
                    <DealTranchConfirmationPopup formik={formik} handleConfirm={handleConfirm} handleClose={closeConfirmationPopup} />
                </Modal>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs textColor="secondary"
                            indicatorColor="secondary" value={value} onChange={handleChange} aria-label="tabs">
                            <Tab label="Create Tranch" {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', margin: 10, marginBlock: 0 }}>
                                <Grid xs={12} item container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xs={12}>
                                        <CustomField name="tranch_name" label="Tranch Name" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Tooltip title={convertToIndianCurrencyWords(formik.values.unit_value)}> */}
                                        <CustomField
                                            name="unit_value"
                                            label="Unit Value"
                                            compulsory={true}
                                            type="text"
                                            disabled={(tranches?.deal_unit_changes?.length > 0) ? true : false}
                                            inputProps={{
                                                min: 0,
                                                onKeyDown: (event) => {
                                                    // Prevent the minus sign (-) from being entered
                                                    if (event.key === '-') {
                                                        event.preventDefault();
                                                    }
                                                },
                                                onKeyPress: (event) => {
                                                    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                                    if (event.key === '-') {
                                                        event.preventDefault();
                                                    }
                                                },
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                onKeyPress: (event) => {
                                                    // Get the input value
                                                    const inputValue = event.key;

                                                    // Allow only numeric characters (0-9) and certain control keys
                                                    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];

                                                    // Check if the pressed key is in the allowedKeys array
                                                    if (!allowedKeys.includes(inputValue)) {
                                                        // If not, prevent the key from being entered
                                                        event.preventDefault();
                                                    }
                                                },
                                                onInput: (event) => {
                                                    // Get the input value
                                                    const inputValue = event.target.value;

                                                    // Remove non-numeric characters using a regular expression
                                                    const numericValue = inputValue.replace(/[^0-9]/g, '');

                                                    // Update the input field value with the filtered numeric value
                                                    event.target.value = numericValue;
                                                },
                                                onKeyDown: (event) => {
                                                    // Prevent the input of minus sign (key code 189)
                                                    if (event.keyCode === 189 || event.key === "-") {
                                                        event.preventDefault();
                                                    }
                                                },
                                            }}
                                        />
                                        {/* </Tooltip>   */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DesktopDatePicker
                                                label="Fund Transfer Date"
                                                // disablePast={true}

                                                value={formik.values.deal_completed_date}
                                                onChange={(newValue) => formik.setFieldValue('deal_completed_date', moment(newValue).toISOString())}
                                                // renderInput={(params) => <TextField fullWidth {...params} />}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={Boolean(formik.touched.deal_completed_date && formik.errors.deal_completed_date)}
                                                        helperText={formik.touched.deal_completed_date && formik.errors.deal_completed_date}
                                                        name="deal_completed_date"
                                                    />
                                                )}
                                                inputFormat="DD/MM/YYYY"
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomTextArea name="comment" label="Comment" multiline={true} minRows={1} sx={{ borderWidth: 2 }} />
                                    </Grid>
                                    <Grid item xs={12} sx={{ position: 'relative' }}>
                                        <CustomField
                                            name="perchange_value"
                                            label="Investment(%)"
                                            compulsory={false}
                                            type="text"
                                            inputProps={{
                                                min: 0,  // Minimum value allowed
                                                max: 100,  // Maximum value allowed
                                                onKeyDown: (event) => {
                                                    // Prevent the minus sign (-) from being entered
                                                    if (event.key === '-') {
                                                        event.preventDefault();
                                                    }
                                                },
                                                onKeyPress: (event) => {
                                                    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                                    if (event.key === '-') {
                                                        event.preventDefault();
                                                    }
                                                },
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                onKeyPress: (event) => {
                                                    // Get the input value
                                                    const inputValue = event.key;

                                                    // Allow only numeric characters (0-9) and certain control keys
                                                    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];

                                                    // Check if the pressed key is in the allowedKeys array
                                                    if (!allowedKeys.includes(inputValue)) {
                                                        // If not, prevent the key from being entered
                                                        event.preventDefault();
                                                    }
                                                },
                                                onInput: (event) => {
                                                    if (event.target.value.trim() == '') {
                                                        event.target.value = 0
                                                    }
                                                    // Get the input value
                                                    const inputValue = event.target.value;

                                                    // Remove non-numeric characters using a regular expression
                                                    const numericValue = inputValue.replace(/[^0-9]/g, '');

                                                    // Update the input field value with the filtered numeric value
                                                    event.target.value = numericValue;

                                                    // Ensure the numeric value is within the desired range
                                                    const min = 0;
                                                    const max = 100;
                                                    const clampedValue = Math.min(Math.max(parseInt(numericValue, 10), min), max);

                                                    // Update the input field value with the clamped numeric value
                                                    event.target.value = clampedValue;




                                                },
                                                onKeyDown: (event) => {
                                                    // Prevent the input of minus sign (key code 189)
                                                    if (event.keyCode === 189 || event.key === "-") {
                                                        event.preventDefault();
                                                    }
                                                },
                                            }}

                                        />
                                        <Button
                                            // ref={fileRef}
                                            variant="contained"
                                            component="label"
                                            disableElevation
                                            style={{
                                                borderRadius: '0px 4px 4px 0px',
                                                height: '51px',
                                                position: 'absolute',
                                                right: 0
                                            }}
                                            onClick={(e) => {
                                                if (change_input != true) {
                                                    investor_invest_value.forEach((checkbox, index) => {
                                                        formik.setFieldValue(`investor_transactions.${index}`, (checkbox * formik.values.perchange_value) / 100);
                                                    });
                                                } else {
                                                    handleOpenModel()
                                                }

                                                // if (e.target.checked) {
                                                //     formik.setFieldValue(`tasks.${index}.required`, e.target.checked)
                                                // }
                                                // else {
                                                //     formik.setFieldValue(`tasks.${index}.required`, e.target.checked)
                                                // }
                                            }}
                                        >
                                            Update

                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} mt={2}>
                                    {/* {isCompletedMode ? ( */}
                                    {/* <>
                    <CustomButton type="submit" disabled>
                      Submit
                    </CustomButton>
                    <p style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                      Note: Deal completed. Stage update not allowed.
                    </p>
                  </> */}
                                    {/* ) : ( */}
                                    {/* <div className='row'>
                   <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Investor Count</div>
                                <div className="v-value font-13 fw-600 ps-2">{CheckValuesCount}</div>
                            </div>
                    </div>
                    <div className="col-md-4 col-6 mt-2 mb-1">
                            <div className='d-flex align-items-center'>
                                <div className="v-label text-gray w-40 mb-0 font-13 fw-600">Total Amount</div>
                                <div className="v-value font-13 fw-600 ps-2">{formatNumberWithCommas(ref_amount_total)}</div>
                            </div>
                    </div>
                  </div> */}
                                    <div className="custom-table vp-investment-dtl-table">
                                        <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
                                            {/* , border: 1, borderColor: 'rgba(224, 224, 224, 1)' */}
                                            <Table
                                                className={`${classes.customTable} add-border remove-border-collapse`}
                                                aria-label="customized table"
                                            >
                                                <TableHead sx={{ position: 'sticky', top: '-1px' }}>
                                                    {/* <TableRow
                                    sx={{
                                        '& .MuiTableCell-sizeMedium': {
                                            padding: '10px 16px 10px 16px' // <-- arbitrary value
                                        }
                                    }}
                                >
                                    <TableCell colSpan={10} className='text-center'  style={{ top: 1, borderBottom: "1px solid rgb(213 213 213 / 30%)"}}>Live Deal Summary</TableCell>
                                </TableRow> */}
                                                    <TableRow
                                                        sx={{
                                                            '& .MuiTableCell-sizeMedium': {
                                                                padding: '10px 16px 10px 16px' // <-- arbitrary value
                                                            }
                                                        }}
                                                    >
                                                        <TableCell style={{ top: 52 }}>Investor Name</TableCell>
                                                        <TableCell style={{ top: 52 }}>Received Amount</TableCell>
                                                        <TableCell style={{ top: 52 }}> Remaining Amount</TableCell>
                                                        <TableCell style={{ top: 52 }}>Invested Amount</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {check_investor_values ? (
                                                        check_investor_values.map((partner, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {partner.investor_name}({partner.lp_code})
                                                                </td>
                                                                <td>{partner.ref_received_amount}</td>
                                                                <td>{partner.ref_remaining_amount}</td>
                                                                <td>
                                                                    <Tooltip
                                                                        title={convertToIndianCurrencyWords(
                                                                            formik.values.investor_transactions[index]
                                                                        )}
                                                                    >
                                                                        <CustomField name={`investor_transactions.${index}`} label="Amount"
                                                                            onChange={(e) => {
                                                                                if (change_input == false) {
                                                                                    setChangeInput(true)
                                                                                }
                                                                                formik.setFieldValue(`investor_transactions.${index}`, e.target.value);

                                                                            }}
                                                                        />{' '}
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="10" align="center">
                                                                <div
                                                                    style={{
                                                                        height: '100%',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <Loading />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <Box className="table-bottom-block" sx={{ display: 'flex', columnGap: '20px' }}>
                                        <p style={{ marginBottom: '10px' }}>
                                            <b>Total Investors:</b> {CheckValuesCount}
                                        </p>
                                        <p style={{ marginBottom: '10px' }}>
                                            <Tooltip title={convertToIndianCurrencyWords(ref_amount_total)}>
                                                <b>Total Amount: ₹</b>{' '}
                                                {formatNumberWithCommas(
                                                    formik.values.investor_transactions.reduce(
                                                        (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
                                                        0
                                                    )
                                                )}
                                            </Tooltip>
                                        </p>
                                    </Box>
                                    {/* <CustomButton type="button" onClick={ () => handleClose()}>Cancel</CustomButton> */}
                                </Grid>
                            </Form>
                        </FormikProvider>
                        <Grid item container gap={2} xs={12} >
                            <CustomButton onClick={() => openConfirmationPopup()}>Submit</CustomButton>
                            <button className="button-80" type="button" onClick={handleClose}>Cancel </button>
                        </Grid>
                    </CustomTabPanel>
                </Box>


                {/* {isLogsLoading ? <Loading />:<AsyncTableListing 
        // title="Logs" 
        columns = {columns}
        // actions={CallForMoneyActions}
        data={logs?.deal_stage_changes}
        // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(callForMoneyData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
        page={logPage}
        setPage={setLogPage}
        count={logs.length}
        limit={5}
        pagination={false}
        tableStyle={{minWidth: 200}}
        />} */}
            </Paper>
        </div>
    );
};

export default DealTranchePopup;
