import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import backrrLogo from '../../../assets/images/backrr1.png'
// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import Logo from '../../../assets/images/backrr.png';
import Cookies from 'js-cookie';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath} className='main-logo'>
        {process.env.REACT_APP_SERVER && process.env.REACT_APP_SERVER == "demo" ?  <img src={backrrLogo} alt='Backrr Logo'/> : <img src={`https://realtimeinvestmentappprod.s3.ap-south-1.amazonaws.com/fund/${Cookies.get('slug')}/logo.png?time=`+Date.now()} alt="Logo"/>}
        
    </ButtonBase>
);

export default LogoSection;
