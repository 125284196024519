import { useState, useEffect, useRef,lazy } from 'react';
import { useTheme } from '@mui/material/styles';
import React from 'react'
import { Grid, TextField, Typography, Box, IconButton, Popover, Rating, Switch } from '@mui/material'
import { useFormik, Form, Formik, FormikProvider, FieldArray, Field } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import { IconEdit, IconRocket } from '@tabler/icons';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useParams, useNavigate } from 'react-router';
import {FetchMasterTemplateDealDetails, UpdateDealChecklistTask } from '../../api'
import topArrow from '../../assets/images/up-arrow.svg'
import downArrow from '../../assets/images/down-arrow.svg'
import { toast, ToastContainer } from 'react-toastify';
import { Cancel, CloseRounded } from '@mui/icons-material';
import moment from 'moment';
import AttachChecklist from './AttachChecklist';
import VpFeesData from './VpFeesData';
import VpFees from 'views/fees/VpFees';
import Loadable from 'ui-component/Loadable';
import TargetFees from 'views/fees/TargetFees';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';
import PartnerFees from 'views/fees/PartnerFees';


const initialValues = {
    checklist_master_id: "",
    tasks: [],
    checklist_period:"",
}


export default function Setting({ checklistData,dealsModule }) {
    const { id: deal_id } = useParams()
    const [template_id, templateId] = useState(null);
    const [template_name, setTemplateName] = useState("");
    const [fromik_values, formikValues] = useState(initialValues);
    const [selected_template_id, setTemplateId] = useState(null);
    const navigate = useNavigate();
    const docRef = useRef()
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    const { mutateAsync: UpdateDealChecklist, status: UpdateDealChecklistTaskStatus, error: UpdateDealChecklistTaskError } = UpdateDealChecklistTask();
    const { isLoading: ismasterTemplateLoading, data: masterTemplateData, status: masterTemplateStatus } = FetchMasterTemplateDealDetails(deal_id, selected_template_id)

    // console.log(masterTemplateData,"masterTemplateData")

    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const updatedTasks = values.tasks.map((task, index) => ({
                ...task,
                display_order: index + 1,
            }));
            values.tasks = updatedTasks;

            // console.log(values);
            // UpdateMasterTemplate({ payload: values, id: master_id })

            UpdateDealChecklist({ payload: values, id: deal_id }
                // onSuccess: () => {
                //     toast.success('Template Create!', {
                //         position: "top-left",
                //         autoClose: 1000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "colored",
                //         onClose: () => {
                //             navigate('/deals')
                //         }
                //     });
                // },
                // onError: (err) => {
                //     toast.error(JSON.parse(err.request.response).message, {
                //         position: "top-left",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "colored",
                //     })
                // }
            )
        }
    })


    useEffect(() => {
        if (masterTemplateStatus === 'success' && masterTemplateData) {
            // console.log(masterTemplateData, "masterTemplateData", selected_template_id, "selected_template_id");

            formikValues({
                checklist_master_id: selected_template_id,
                tasks: masterTemplateData?.tasks,
                checklist_period: masterTemplateData?.checklist_period
            });
        }
    }, [masterTemplateStatus, masterTemplateData]);

    useEffect(() => {
        if (UpdateDealChecklistTaskStatus == 'success') {
            formik.resetForm()
            toast.success('Checklist Updated', {
                position: "top-left",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                onClose: () => {
                    navigate('/deals')
                }
            });
        }
        if (UpdateDealChecklistTaskStatus == 'error') {
            toast.error(UpdateDealChecklistTaskError?.response?.data?.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        }
    }, [UpdateDealChecklistTaskStatus])

    const [attachChecklistAction, setAttachChecklistAction] = useState(true);

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }


    const moveTaskUp = (index) => {
        if (index > 0) {
            const newTasks = [...formik.values.tasks];
            [newTasks[index - 1], newTasks[index]] = [newTasks[index], newTasks[index - 1]];
            formik.setFieldValue('tasks', newTasks);
        }
    };
    const moveTaskDown = (index) => {
        const newTasks = [...formik.values.tasks];
        [newTasks[index + 1], newTasks[index]] = [newTasks[index], newTasks[index + 1]];
        formik.setFieldValue('tasks', newTasks);
    };

    return (
        (dealsModule != undefined  && dealsModule?.deal_setting == true) ?
        <>
            <div className='custom-card card'>
                <div className='card-body'>
                    <div>
                        <div class="nav nav-tabs custom-nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-add" type="button" role="tab" aria-controls="nav-add" aria-selected="true">Attach Checklist</button>
                            {checklistData && checklistData.length > 0 && <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-update" type="button" role="tab" aria-controls="nav-update" aria-selected="false">Update Checklist</button>}
                            <button class="nav-link" id="nav-partnerfees-tab" data-bs-toggle="tab" data-bs-target="#nav-partnerfees" type="button" role="tab" aria-controls="nav-partnerfees" aria-selected="false">Partner Fees </button>
                            <button class="nav-link" id="nav-vpfees-tab" data-bs-toggle="tab" data-bs-target="#nav-vpfees" type="button" role="tab" aria-controls="nav-vpfees" aria-selected="false">VP Fees </button>
                            {/* <button class="nav-link" id="target-partnerfees-tab" data-bs-toggle="tab" data-bs-target="#target-partnerfees" type="button" role="tab" aria-controls="target-partnerfees" aria-selected="false">Target Partner Fees </button> */}
                        </div>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-add" role="tabpanel" aria-labelledby="nav-add-tab">
                               <AttachChecklist />
                            </div>
                            {checklistData && checklistData.length > 0 &&  <div class="tab-pane fade " id="nav-update" role="tabpanel" aria-labelledby="nav-update-tab">
                                <div className='custom-table table-responsive mt-3'>
                                    <table className='w-100 table add-border'>
                                        <thead>
                                            <tr>
                                                <th>Attached Checklist</th>
                                                <th>Checklist Type</th>
                                                <th>No of task</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checklistData && checklistData.length > 0 ? (
                                                checklistData.map((task, index) => (
                                                    <>
                                                        <tr>
                                                            <td>{task.template_name}</td>
                                                            <td>{task.checklist_period == 'monthly' ? "Monthly Task" :  "Onetime Task"}</td>
                                                            <td>{task.tasks.length}</td>
                                                            <td className='text-center'>
                                                                <button type='button' className='icon-btn'
                                                                    onClick={() => {
                                                                        setTemplateId(task.checklist_master_id)   
                                                                        setTemplateName(task.template_name)
                                                                    }}><IconEdit />
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        {/* <tr>
                                                        <td>{task.task_name}</td>
                                                        <td>{task.task_description}</td>
                                                        <td className='text-center' >{task.required ? "Yes" : "No"}</td>
                                                        <td className='left' >{task.eta_date_format}</td>
                                                        <td className='left' >{task.task_status == "Completed" ? task.completed_date_format : ""}</td>
                                                        <td className='left' >{task.updatedAt != "" ? task.updatedAt : ""}</td>
                                                        <td className='left' >{task.last_updated_by != "" ? task.last_updated_by : ""}</td>
                                                        <td className='text-center'>
                                                            <button type='button' className='icon-btn'
                                                                onClick={() => handleOpen(task.task_id)}><IconEdit />
                                                            </button>
                                                        </td>
                                                    </tr> */}
                                                    </>
                                                ))
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                    {masterTemplateData  && <FormikProvider value={formik} >
                                        <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown} >
                                            <div className='custom-card card box-shadow-none mt-3'>
                                                <div className='card-header'>{template_name}</div>
                                                <div className='card-body'>
                                                    {masterTemplateData ? (
                                                        <>
                                                  <div className="position-relative mt-4">
                            <FieldArray
                                name="tasks"
                                render={(arrayHelpers) => {
                                    docRef.current = arrayHelpers;
                                    return (
                                        <DragDropContext
                                            onDragEnd={(result) => {
                                                if (!result.destination) {
                                                    return  toast.error('please drop inside the list',{
                                                        position: 'top-left',
                                                        autoClose: 1000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: false,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: 'colored',
                                                    })
                                                }
                                                const items = [...formik.values.tasks];
                                                const [reorderedItem] = items.splice(result.source.index, 1);
                                                items.splice(result.destination.index, 0, reorderedItem);
                                                formik.setFieldValue('tasks', items);
                                            }}
                                        >
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {formik.values.tasks &&
                                                            formik.values.tasks.length > 0 &&
                                                            formik.values.tasks.map((_, index) => (
                                                                <Draggable
                                                                    key={`task-${index}`}
                                                                    draggableId={`task-${index}`}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            isDragging={snapshot.isDragging}
                                                                        >
                                                                            <div className={`custom-card card mb-3 checklist-master-card pt-2 ${snapshot.isDragging ? 'background shadow-sm dragging' : 'bg-white shadow-none'}`}>
                                                                                {/* Card content */}
                                                                                <div className="cs-close close-pos-right">
                                                                                    <Cancel onClick={() => arrayHelpers.remove(index)} />
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-md-4 mb-3 d-flex align-items-center">
                                                                                            <div className='pe-3'>#{index + 1}</div>
                                                                                            <CustomField
                                                                                                name={`tasks.${index}.task_name`}
                                                                                                label="Task Name"
                                                                                            />
                                                                                        </div>
                                                                                        <div className='col-md-4 mb-3'>
                                                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                                                        <DatePicker
                                                                                                            label="ETA Date"
                                                                                                            name={`tasks.${index}.eta_date`}
                                                                                                            value={formik.values.tasks[index].eta_date ? formik.values.tasks[index].eta_date : formik.setFieldValue(`tasks.${index}.eta_date`, moment().toISOString())}
                                                                                                            onChange={(newValue) => formik.setFieldValue(`tasks.${index}.eta_date`, moment(newValue).toISOString())}
                                                                                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                                                                                            inputFormat="DD/MM/YYYY"
                                                                                                        />
                                                                                                    </LocalizationProvider>
                                                                                                </div>
                                                                                        <div className="col-md-3 mb-3 pt-1">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <div className="me-2">Required</div>
                                                                                                <div className="custom-switch">
                                                                                                    <Switch
                                                                                                        name={`tasks.${index}.required`}
                                                                                                        checked={
                                                                                                            formik.values.tasks[index]
                                                                                                                .required
                                                                                                        }
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.checked) {
                                                                                                                formik.setFieldValue(
                                                                                                                    `tasks.${index}.required`,
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                            } else {
                                                                                                                formik.setFieldValue(
                                                                                                                    `tasks.${index}.required`,
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'controlled'
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <Tooltip title="Enabling this means the task depends on completing prior tasks. It ensures a step-by-step completion in the checklist.">
                                                                                                    <InfoOutlinedIcon
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                            marginLeft: 5,
                                                                                                            fontSize: 'large'
                                                                                                        }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div  className="col-md-1 mb-3 mt-2" >
                                                                                            <div
                                                                                                className="drag-btns"
                                                                                                {...provided.dragHandleProps}
                                                                                            >
                                                                                                 <Tooltip title="By tap on this you can drag and drop the task anywhere in the list according to you">
                                                                                                <MenuIcon />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-12 mb-3">
                                                                                            <CustomField
                                                                                                name={`tasks.${index}.task_description`}
                                                                                                label="Task Description"
                                                                                            />
                                                                                        </div>

                                                                                        <input
                                                                                            type="hidden"
                                                                                            name={`tasks.${index}.display_order`}
                                                                                            value={index + 1}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    );
                                }}
                            />
                        </div>
                                                            <div className='row mt-4'>
                                                                <div className='col-6'>
                                                                    <button type='submit' className='button-79 text-center theme-btn'>Update Checklist</button>
                                                                </div>
                                                                {(!ismasterTemplateLoading) && <div className='col-6 text-end'>
                                                                    <button type='button' onClick={() => docRef.current.push({ required: false, task_name: '', task_description: '', display_order: formik.values.tasks.length + 1, task_date: '' })} className='button-79 text-center btn-success'>Add More +</button>
                                                                </div>}
                                                            </div>
                                                        </>)
                                                        : ""}
                                                </div>
                                            </div>
                                        </Form>
                                    </FormikProvider>}
                                </div>
                            </div>}
                            <div class="tab-pane fade" id="nav-partnerfees" role="tabpanel" aria-labelledby="nav-partnerfees-tab">
                               <PartnerFees startupDealId={deal_id} id={deal_id} />
                            </div>
                            <div class="tab-pane fade" id="nav-vpfees" role="tabpanel" aria-labelledby="nav-vpfees-tab">
                               <VpFees startupDealId={deal_id} id={deal_id} />
                            </div>
                            {/* <div class="tab-pane fade" id="target-partnerfees" role="tabpanel" aria-labelledby="target-partnerfees-tab">
                               <TargetFees startupDealId={deal_id} id={deal_id} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
         :    <Error404 />
    )
}