
import { useState, useRef } from 'react'
import { TextField, Paper, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import { withStyles } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/SelectField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Clickable from 'utils/Clickable';
import doc2Icon from '../../assets/images/icons/doc2.png'
import pdfIcon from '../../assets/images/pdf.svg'
import xlsxIcon from '../../assets/images/icons/xlsx.png'
const DealDocument = ({ data }) => {
// console.log(data,"data89")
    const theme = useTheme();
    const CustomTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '4px 0 0 4px',
                    borderRightWidth: '0px'
                },
            },
        },
    })(TextField);

    return (
        <>
            <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px' }} >
                <h3 style={{ textAlign: 'center' }}>{data?.length < 1 || data == undefined ? 'No Document Found' : ''}</h3>
                <ul className="document-list mt-2">
                    {data?.map((item, index) => (
                        <>
                            <li className="d-flex align-items-center">
                                <Clickable className="icon" onClick={()=>window.open(item.url, 'blank')}>
                                <img className='ud-icon'
                                width={25}
                                // src={pdfIcon} 
                                src={ item?.type?.split('.').pop()  == "docx"  ?  doc2Icon : item?.file_name?.split('.').pop()  == "csv" || item?.file_name?.split('.').pop()  == "xlsx"  ? xlsxIcon  : pdfIcon }
                                alt="pdf"
                                />
                                    </Clickable>
                                <Clickable className="ms-2" onClick={()=>window.open(item.url, 'blank')}>
                                 <div className="xs-title font-16 text-start">{item.name}</div>
                                 <div className="sm-title font-12 text-start mt-1">{item.date_format}</div>
                               </Clickable>
                            </li>
                        </>
                    ))}
                </ul>
            </Paper>
        </>
    );
}

export default DealDocument;
