import { useState, useEffect, useRef } from 'react';
import { Paper, TextField, Button, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider, Formik, FieldArray } from 'formik';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@mui/styles';
import { useParams, useNavigate } from 'react-router';
import CustomField from 'ui-component/formik/CustomField';
import { FetchPermissionData, AttachRolePermission, FetchRoleDetails, UpdateRolePermissionForm } from 'api';
import Loading from 'views/Loading';
import SelectField from 'ui-component/SelectField';
import { IconCheck, IconChecks, IconEdit, IconEye, IconInfoCircle, IconTrash } from '@tabler/icons';

// FetchPermissionData
const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required').trim(),
    email: yup.string().required('Email is required').email('Must be a valid email'),
    password: yup.string().required('Password is required').trim(),
    role: yup.string().required('Role is required').trim()
});

const initialValues = {
    role_name: "",
    is_super_admin:false,
    permission: [],
}

const RolePermissionForm = () => {
    const theme = useTheme();
    const { id: role_id } = useParams();
    const isEditMode = !!role_id;
    const docRef = useRef()
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [fromik_values, formikValues] = useState(initialValues);
    const { mutateAsync: attachRole, status: attachRoleStatus, error: attachRoleError } = AttachRolePermission();
    const { isLoading: isRoleDataLoading, data: roleData, status: roleDataStatus } = FetchRoleDetails(role_id);
    const { mutateAsync: updateRolePermission, isLoading: isLoadingUpdateRolePermission, status: UpdateRolePermissionStatus, error: UpdateRolePermissionError } = UpdateRolePermissionForm()
    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!isEditMode) {
                attachRole({ payload: values })
            } else {
                const value_data = {
                    role_name: values.role_name,
                    is_super_admin: values.is_super_admin,
                    permission: values.permission,
                }
                updateRolePermission({ payload: value_data, id: role_id })

            }

        }
    });
    const { isLoading: isPermissionDataLoading, data: permissionData } = FetchPermissionData();

    useEffect(() => {

        if (permissionData && !isEditMode) {
            formikValues({
                role_name: "",
                is_super_admin: false,
                permission: permissionData,
            });
        } else {
            if (roleData && permissionData) {
             const mergedArray = roleData.data.permission.map(obj1 => {
                const correspondingObj = permissionData.find(obj2 => obj2.module_name === obj1.module_name);
                return {
                    ...(correspondingObj || {}),  
                    ...obj1
                };

            });
                formikValues({
                    role_name: roleData.data.role_name,

                    role_name: roleData.data.role_name,

                    is_super_admin: roleData.data?.is_super_admin == "true" ?true :false, 
                    permission: mergedArray,
                });
            }
        }
    }, [isRoleDataLoading, permissionData]);

    useEffect(() => {
        if (attachRoleStatus == 'success') {
            toast.success('Role Created!', {
                position: 'top-left',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    navigate('/users/role');
                }
            });
        }
        if (attachRoleStatus == 'error') {
            toast.error(attachRoleError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [attachRoleStatus]);


    useEffect(() => {
        if (UpdateRolePermissionStatus == 'success') {
            // formik.resetForm();
            toast.success('Role Updated!', {
                position: 'top-left',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    navigate('/users/role');
                }
            });
        }
        if (UpdateRolePermissionStatus == 'error') {
            toast.error(UpdateRolePermissionError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [UpdateRolePermissionStatus]);


    // const handleSelectAll = () => {
    //     setSelectAllChecked(!Select_all_checked);
    //     // formik.setFieldValue(`permission.3.view`, !Select_all_checked);
    //     // formik.setFieldValue(`permission.0.view`, !Select_all_checked);
    // }
    // const handleChangeView = () => {
    //     setViewChecked(!view_checked);
    //     formik.setFieldValue(`permission.3.view`, !view_checked);
    //     formik.setFieldValue(`permission.0.view`, !view_checked);
    // }

    // const handleChangeCreate = () => {
    //     setCreateChecked(!create_checked);
    //     formik.setFieldValue(`permission.1.create`, !create_checked);
    //     formik.setFieldValue(`permission.2.create`, !create_checked);
    //     formik.setFieldValue(`permission.3.create`, !create_checked);
    //     formik.setFieldValue(`permission.4.create`, !create_checked);
    //     formik.setFieldValue(`permission.5.create`, !create_checked);
    //     formik.setFieldValue(`permission.9.create`, !create_checked);
    //     formik.setFieldValue(`permission.10.create`, !create_checked);

    // }

    // const handleChangeEdit = () => {
    //     setEditChecked(!edit_checked);
    //     formik.setFieldValue(`permission.1.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.2.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.3.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.4.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.5.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.7.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.8.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.9.edit`, !edit_checked);
    //     formik.setFieldValue(`permission.10.edit`, !edit_checked);

    // }
    // const handleChangeExport = () => {
    //     setExportChecked(!export_checked);
    //     formik.setFieldValue(`permission.1.export`, !export_checked);
    //     formik.setFieldValue(`permission.6.export`, !export_checked);
    // }
    // const handleChangeKyc = () => {
    //     setKYCChecked(!kyc_checked);
    //     formik.setFieldValue(`permission.1.kyc_status_change`, !kyc_checked);
    // }
    // const handleChangeSetting = () => {
    //     setSettingChecked(!setting_checked);
    //     formik.setFieldValue(`permission.1.setting_update`, !setting_checked);
    //     formik.setFieldValue(`permission.3.setting_update`, !setting_checked);
    // }
    // const handleChangeMail = () => {
    //     setMailChecked(!mail_checked);
    //     formik.setFieldValue(`permission.2.mail_send`, !mail_checked);
    // }
    // const handleChangeTransaction = () => {
    //     setTransactionChecked(!transaction_checked);
    //     formik.setFieldValue(`permission.3.transaction`, !transaction_checked);
    // }
    if ( (isRoleDataLoading && isEditMode)) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
    }

    return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <ToastContainer />
                <BreadcrumbHeader title={isEditMode ? 'Edit Role Permission' : 'Add Role Permission'} shouldGoBack />
                <Box>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px' }}>
                            {/* <div className='custom-menu-block view-investor-block'> */}
                                <div className='custom-card card mb-3'>
                                    {/* <div className='card-header'>{isEditMode ? 'Edit Role Permission' : 'Add Role Permission'}</div> */}
                                    <div className='card-body ps-4 pe-4'>
                                        <div className='row mb-3 mt-3'>
                                            <div className='col-md-6 mb-3'>
                                                <CustomField name="role_name" label="Role Name" />
                                            </div>
                                        </div>
                                    <div className='mb-4'>
                                        <div className='row'>
                                            <div className='col-12 mb-2 main-checkbox'>
                                                
                                            <div className='d-flex align-items-center'>
                                                                    <div>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                                defaultChecked={checked}
                                                                checked={formik.values.is_super_admin}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        formik.setFieldValue(`is_super_admin`, e.target.checked)
                                                                    }
                                                                    else {
                                                                        formik.setFieldValue(`is_super_admin`, e.target.checked)
                                                                    }
                                                                }}  
                                                                color="primary"
                                                                name="is_super_admin"
                                                            />
                                                            }
                                                                label={
                                                                    <Typography variant="subtitle1">
                                                                        Super Admin
                                                                    </Typography>
                                                                }
                                                            />

                                                                    </div>
                                                                    <div>
                                                                        <span className='info-box info-right cs-width'>
                                                                            <IconInfoCircle className='iconinfo' />
                                                                            <span className='info-pos'>
                                                                            <div>Access to all Investors, Deals, and Startups across syndicates.<br />
                                                                                 Manage system configurations for Master Lists, Checklist Master, Customer Feedbacks, and Articles.</div>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <FieldArray
                                            name="permission"
                                            render={arrayHelpers => {
                                                docRef.current = arrayHelpers
                                                return <div className='mb-4'>
                                                    {formik.values.permission && formik.values.permission.length > 0 ? (
                                                        formik.values.permission.map((value, index) => (
                                                            <>
                                                                <div className='row'>
                                                                    <div className='col-12 mb-2 main-checkbox'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={checked}
                                                                                    checked={formik.values.permission[index].permisson}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.permisson`, e.target.checked)
                                                                                            if (value.listing != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.listing`, e.target.checked)
                                                                                            }
                                                                                            if (value.view != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.view`, e.target.checked)
                                                                                            }
                                                                                            if (value.create != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.create`, e.target.checked)
                                                                                            }
                                                                                            if (value.edit != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.edit`, e.target.checked)
                                                                                            }

                                                                                            if (value.application_listing != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_listing`, e.target.checked)
                                                                                            }
                                                                                   
                                                                                            if (value.application_create != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_create`, e.target.checked)
                                                                                            }
                                                                                            if (value.application_edit != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_edit`, e.target.checked)
                                                                                            }
                                                                                            if (value.mis_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.mis_report`, e.target.checked)
                                                                                            }
                                                                                            if (value.master_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.master_report`, e.target.checked)
                                                                                            }
                                                                                            if (value.mis_notification != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.mis_notification`, e.target.checked)
                                                                                            }

                                                                                            if (value.kyc_status_change != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.kyc_status_change`, e.target.checked)
                                                                                            }
                                                                                            if (value.setting_update != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.setting_update`, e.target.checked)
                                                                                            }
                                                                                            if (value.soa_upload_mail_sand != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.soa_upload_mail_sand`, e.target.checked)
                                                                                            }
                                                                                            if (value.venture_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.venture_partner`, e.target.checked)
                                                                                            }
                                                                                            if (value.send_SOA != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.send_SOA`, e.target.checked)
                                                                                            }
                                                                                            if (value.soa_upload != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.soa_upload`, e.target.checked)
                                                                                            }
                                                                                            if(value.generate_agreements != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.generate_agreements`, e.target.checked)
                                                                                            }
                                                                                            if(value.get_ckyc != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.get_ckyc`, e.target.checked)
                                                                                            }
                                                                                            if(value.soa != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.soa`, e.target.checked)
                                                                                            }
                                                                                             if(value.stamps != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.stamps`, e.target.checked)
                                                                                            }
                                                                                            if (value.view_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.view_investment`, e.target.checked)
                                                                                            }
                                                                                            if (value.export_master_data != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.export_master_data`, e.target.checked)
                                                                                            }
                                                                                            if (value.export_transaction != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.export_transaction`, e.target.checked)
                                                                                            }
                                                                                            if (value.export_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.export_investment`, e.target.checked)
                                                                                            }
                                                                                            if (value.lp_summary != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.lp_summary`, e.target.checked)
                                                                                            }
                                                                                            if (value.live_deal_summary != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.live_deal_summary`, e.target.checked)
                                                                                            }
                                                                                            if (value.missing_investor_data != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.missing_investor_data`, e.target.checked)
                                                                                            }
                                                                                            if (value.investor_onboarding_summery != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.investor_onboarding_summery`, e.target.checked)
                                                                                            }
                                                                                            if (value.active_invactive_investors != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.active_invactive_investors`, e.target.checked)
                                                                                            }
                                                                                            if (value.partner_target_fees != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.partner_target_fees`, e.target.checked)
                                                                                            }
                                                                                            if (value.deal_document_checklist != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_document_checklist`, e.target.checked)
                                                                                            }
                                                                                            if (value.startup_document_checklist != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.startup_document_checklist`, e.target.checked)
                                                                                            }
                                                                                            if (value.qar_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.qar_report`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report_lead_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_lead_partner`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report_venture_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_venture_partner`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_partner`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report_export != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_export`, e.target.checked)
                                                                                            }
                                                                                            if (value.vp_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.vp_investment`, e.target.checked)
                                                                                            }
                                                                                            if (value.deal_checklist != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_checklist`, e.target.checked)
                                                                                            }
                                                                                            if (value.deal_stage_update != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_stage_update`, e.target.checked)
                                                                                            }
                                                                                            if (value.deal_investors != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_investors`, e.target.checked)
                                                                                            }
                                                                                            if (value.deal_nav != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_nav`, e.target.checked)
                                                                                            }
                                                                                            if (value.deal_setting != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_setting`, e.target.checked)
                                                                                            }
                                                                                            if (value.generate_addendum != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.generate_addendum`, e.target.checked)
                                                                                            }
                                                                                            if (value.remove_tranch != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.remove_tranch`, e.target.checked)
                                                                                            }
                                                                                            if (value.generate_lead_investor_agreement != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.generate_lead_investor_agreement`, e.target.checked)
                                                                                            }
                                                                                            if (value.generate_icnotes_documents != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.generate_icnotes_documents`, e.target.checked)
                                                                                            }
                                                                                            if(value.received_amount_add_edit != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.received_amount_add_edit`, e.target.checked)
                                                                                            }
                                                                                            if (value.vp_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.vp_investment`, e.target.checked)
                                                                                            }
                                                                                            if (value.checklists != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.checklists`, e.target.checked)
                                                                                            }
                                                                                            if (value.review != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.review`, e.target.checked)
                                                                                            }
                                                                                            if (value.feedbacks != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.feedbacks`, e.target.checked)
                                                                                            }
                                                                                            if (value.lists != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.lists`, e.target.checked)
                                                                                            }
                                                                                            if (value.reports != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.reports`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees`, e.target.checked)
                                                                                            }
                                                                                            if (value.settings != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.settings`, e.target.checked)
                                                                                            }
                                                                                            if (value.application_settings != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_settings`, e.target.checked)
                                                                                            }
                                                                                            if (value.invoices != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.invoices`, e.target.checked)
                                                                                            }
                                                                                            if (value.lead_investors != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.lead_investors`, e.target.checked)
                                                                                            }
                                                                                            if (value.invoice_email_cc != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.invoice_email_cc`, e.target.checked)
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.permisson`, false)
                                                                                            if (value.listing != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.listing`, false)
                                                                                            }
                                                                                            if (value.view != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.view`, false)
                                                                                            }
                                                                                            if (value.create != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.create`, false)
                                                                                            }
                                                                                            if (value.edit != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.edit`, false)
                                                                                            }

                                                                                            if (value.application_listing != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_listing`, false)
                                                                                            }
                                                                                            if (value.application_create != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_create`, false)
                                                                                            }
                                                                                            if (value.application_edit != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_edit`, false)
                                                                                            }

                                                                                            if (value.mis_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.mis_report`, false)
                                                                                            }
                                                                                            if (value.master_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.master_report`, false)
                                                                                            }
                                                                                            if (value.mis_notification != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.mis_notification`, false)
                                                                                            }

                                                                                            if (value.kyc_status_change != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.kyc_status_change`, false)
                                                                                            }
                                                                                            if (value.setting_update != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.setting_update`, false)
                                                                                            }
                                                                                            if (value.soa_upload_mail_sand != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.soa_upload_mail_sand`, false)
                                                                                            }
                                                                                            if (value.venture_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.venture_partner`, false)
                                                                                            }
                                                                                            if (value.send_SOA != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.send_SOA`, false)
                                                                                            }
                                                                                            if (value.soa_upload != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.soa_upload`, false)
                                                                                            }
                                                                                            if(value.generate_agreements != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.generate_agreements`, false)
                                                                                            }
                                                                                            if(value.get_ckyc != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.get_ckyc`, false)
                                                                                            }
                                                                                             if(value.soa != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.soa`, false)
                                                                                            }
                                                                                             if(value.stamps != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.stamps`, false)
                                                                                            }
                                                                                            if (value.view_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.view_investment`, false)
                                                                                            }
                                                                                            if (value.export_master_data != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.export_master_data`, false)
                                                                                            }
                                                                                            if (value.export_transaction != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.export_transaction`, false)
                                                                                            }
                                                                                            if (value.export_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.export_investment`, false)
                                                                                            }
                                                                                            if (value.lp_summary != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.lp_summary`, false)
                                                                                            }
                                                                                            if (value.live_deal_summary != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.live_deal_summary`, false)
                                                                                            }
                                                                                            if (value.missing_investor_data != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.missing_investor_data`, false)
                                                                                            }
                                                                                            if (value.investor_onboarding_summery != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.investor_onboarding_summery`,false)
                                                                                            }
                                                                                            if (value.active_invactive_investors != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.active_invactive_investors`,false)
                                                                                            }

                                                                                            if (value.partner_target_fees != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.partner_target_fees`, false)
                                                                                            }
                                                                                            if (value.deal_document_checklist != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_document_checklist`, false)
                                                                                            }
                                                                                            if (value.startup_document_checklist != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.startup_document_checklist`, false)
                                                                                            }
                                                                                            if (value.qar_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.qar_report`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report`, e.target.checked)
                                                                                            }
                                                                                            if (value.fees_report_lead_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_lead_partner`, false)
                                                                                            }
                                                                                            if (value.fees_report_venture_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_venture_partner`, false)
                                                                                            }
                                                                                            if (value.fees_report_partner != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_partner`, false)
                                                                                            }
                                                                                            if (value.fees_report_export != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees_report_export`, false)
                                                                                            }
                                                                                            if (value.vp_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.vp_investment`, false)
                                                                                            }
                                                                                            if (value.deal_checklist != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_checklist`, false)
                                                                                            }
                                                                                            if (value.deal_stage_update != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_stage_update`, false)
                                                                                            }
                                                                                            if (value.deal_investors != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_investors`, false)
                                                                                            }
                                                                                            if (value.deal_nav != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_nav`, false)
                                                                                            }
                                                                                            if (value.deal_setting != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.deal_setting`, false)
                                                                                            }
                                                                                            if (value.generate_addendum != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.generate_addendum`, false)
                                                                                            }
                                                                                            if (value.remove_tranch != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.remove_tranch`, false)
                                                                                            }
                                                                                            if (value.generate_lead_investor_agreement != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.generate_lead_investor_agreement`, false)
                                                                                            }
                                                                                            if (value.generate_icnotes_documents != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.generate_icnotes_documents`, false)
                                                                                            }
                                                                                            if(value.received_amount_add_edit != undefined){
                                                                                                formik.setFieldValue(`permission.${index}.received_amount_add_edit`, false)
                                                                                            }
                                                                                            if (value.vp_investment != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.vp_investment`, false)
                                                                                            }
                                                                                            if (value.checklists != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.checklists`, false)
                                                                                            }
                                                                                            if (value.review != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.review`, false)
                                                                                            }
                                                                                            if (value.feedbacks != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.feedbacks`, false)
                                                                                            }
                                                                                            if (value.lists != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.lists`, false)
                                                                                            }
                                                                                            if (value.reports != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.reports`, false)
                                                                                            }
                                                                                            if (value.fees != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.fees`, false)
                                                                                            }
                                                                                            if (value.settings != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.settings`, false)
                                                                                            }
                                                                                            if (value.application_settings != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.application_settings`, false)
                                                                                            }
                                                                                            if (value.invoices != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.invoices`, false)
                                                                                            }
                                                                                            if (value.lead_investors != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.lead_investors`, false)
                                                                                            }
                                                                                            if (value.invoice_email_cc != undefined) {
                                                                                                formik.setFieldValue(`permission.${index}.invoice_email_cc`, false)
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    color="primary"
                                                                                    name={`permission.${index}.module_name`}
                                                                                    value={value.module_name}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    {value.name}
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='row ps-30'>
                                                                    {/* {value.view &&  */}

                                                                    {formik.values.permission[index].listing != undefined && <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.listing}
                                                                                    checked={formik.values.permission[index].listing}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.listing`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.listing`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.listing`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Listing
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].view != undefined  && <div className='col-md-3'>
                                                                        
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.view}
                                                                                    checked={formik.values.permission[index].view}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.view`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.view`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.view`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    View
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                        
                                                                    </div>}
                                                                    {formik.values.permission[index].create != undefined && <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.create}
                                                                                    checked={formik.values.permission[index].create}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.create`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.create`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.create`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Create
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {/* }
                                                                    {value.edit &&  */}
                                                                {formik.values.permission[index].edit != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.edit}
                                                                                    checked={formik.values.permission[index].edit}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.edit`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.edit`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.edit`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Edit
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {/* }
                                                                    {value.export &&  */}
                                                                    {formik.values.permission[index].export_investment != undefined && <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.export_investment}
                                                                                    checked={formik.values.permission[index].export_investment}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.export_investment`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.export_investment`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.export_investment`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Export Investment
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {/* }
                                                                    {value.kyc_status_change &&  */}
                                                                {formik.values.permission[index].export_transaction != undefined &&  <div className='col-md-3'>
                                                                        
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.export_transaction}
                                                                                    checked={formik.values.permission[index].export_transaction}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.export_transaction`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.export_transaction`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.export_transaction`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Export Transaction
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                        
                                                                    </div>}
                                                                    {/* }
                                                                    {value.setting_update &&  */}
                                                                    {formik.values.permission[index].export_master_data != undefined && <div className='col-md-3'>
                                                                        
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.export_master_data}
                                                                                    checked={formik.values.permission[index].export_master_data}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.export_master_data`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.export_master_data`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.export_master_data`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Export Master Data
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {/* }
                                                                    {value.mail_send &&  */}
                                                                
                                                                    {/* }
                                                                    {value.transaction &&  */}
                                                                {formik.values.permission[index].kyc_status_change != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.kyc_status_change}
                                                                                    checked={formik.values.permission[index].kyc_status_change}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.kyc_status_change`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.kyc_status_change`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.kyc_status_change`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    KYC Status Changes
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].setting_update != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.setting_update}
                                                                                    checked={formik.values.permission[index].setting_update}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.setting_update`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.setting_update`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.setting_update`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Setting Update
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].view_investment != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.view_investment}
                                                                                    checked={formik.values.permission[index].view_investment}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.view_investment`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.view_investment`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.view_investment`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    View Investment
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].soa_upload != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.soa_upload}
                                                                                    checked={formik.values.permission[index].soa_upload}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.soa_upload`, e.target.checked)
                                                                                            formik.setFieldValue(`permission.${index}.soa`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.soa_upload`, e.target.checked)
                                                                                            formik.setFieldValue(`permission.${index}.soa`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.soa_upload`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    SOA
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].soa_upload_mail_sand != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.soa_upload_mail_sand}
                                                                                    checked={formik.values.permission[index].soa_upload_mail_sand}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.soa_upload_mail_sand`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.soa_upload_mail_sand`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.soa_upload_mail_sand`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    SOA Upload Mail Send
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].venture_partner != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.venture_partner}
                                                                                    checked={formik.values.permission[index].venture_partner}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.venture_partner`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.venture_partner`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.venture_partner`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Venture Partner
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].send_SOA != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.send_SOA}
                                                                                    checked={formik.values.permission[index].send_SOA}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.send_SOA`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.send_SOA`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.send_SOA`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Send SOA (INVESTOR)
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    
                                                                    {formik.values.permission[index].generate_agreements != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.generate_agreements}
                                                                                    checked={formik.values.permission[index].generate_agreements}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.generate_agreements`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.generate_agreements`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.generate_agreements`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Generate Agreements
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    
                                                                    {formik.values.permission[index].get_ckyc != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.get_ckyc}
                                                                                    checked={formik.values.permission[index].get_ckyc}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.get_ckyc`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.get_ckyc`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.get_ckyc`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Get CKYC
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    
                                                                    {/* {formik.values.permission[index].soa != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.soa}
                                                                                    checked={formik.values.permission[index].soa}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.soa`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.soa`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.soa`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    SOA
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>} */}
                                                                    
                                                                    {formik.values.permission[index].stamps != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.stamps}
                                                                                    checked={formik.values.permission[index].stamps}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.stamps`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.stamps`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.stamps`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Stamps
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].deal_checklist != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.deal_checklist}
                                                                                    checked={formik.values.permission[index].deal_checklist}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.deal_checklist`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.deal_checklist`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.deal_checklist`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Deal Checklist
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].deal_stage_update != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.deal_stage_update}
                                                                                    checked={formik.values.permission[index].deal_stage_update}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.deal_stage_update`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.deal_stage_update`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.deal_stage_update`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Deal Stage Update
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].deal_investors != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.deal_investors}
                                                                                    checked={formik.values.permission[index].deal_investors}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.deal_investors`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.deal_investors`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.deal_investors`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Deal Investors
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].deal_nav != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.deal_nav}
                                                                                    checked={formik.values.permission[index].deal_nav}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.deal_nav`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.deal_nav`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.deal_nav`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Deal NAV
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].deal_setting != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.deal_setting}
                                                                                    checked={formik.values.permission[index].deal_setting}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.deal_setting`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.deal_setting`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.deal_setting`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Deal Setting
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].generate_addendum != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.generate_addendum}
                                                                                    checked={formik.values.permission[index].generate_addendum}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.generate_addendum`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.generate_addendum`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.generate_addendum`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Generate Addendum
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].remove_tranch != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.remove_tranch}
                                                                                    checked={formik.values.permission[index].remove_tranch}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.remove_tranch`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.remove_tranch`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.remove_tranch`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Remove Tranch
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].generate_lead_investor_agreement != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.generate_lead_investor_agreement}
                                                                                    checked={formik.values.permission[index].generate_lead_investor_agreement}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.generate_lead_investor_agreement`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.generate_lead_investor_agreement`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.generate_lead_investor_agreement`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Generate Lead Investor Agreement
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].generate_icnotes_documents != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.generate_icnotes_documents}
                                                                                    checked={formik.values.permission[index].generate_icnotes_documents}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.generate_icnotes_documents`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.generate_icnotes_documents`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.generate_icnotes_documents`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Generate IC Notes and Other Documents
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                                                                                        
                                                                    {formik.values.permission[index].received_amount_add_edit != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.received_amount_add_edit}
                                                                                    checked={formik.values.permission[index].received_amount_add_edit}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.received_amount_add_edit`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.received_amount_add_edit`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.received_amount_add_edit`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Received Amount Add & Edit
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}


                                                                    {formik.values.permission[index].application_listing != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.application_listing}
                                                                                    checked={formik.values.permission[index].application_listing}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.application_listing`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.application_listing`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.application_listing`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Application Listing
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].application_create != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.application_create}
                                                                                    checked={formik.values.permission[index].application_create}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.application_create`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.application_create`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.application_create`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Application Create
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].application_edit != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.application_edit}
                                                                                    checked={formik.values.permission[index].application_edit}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.application_edit`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.application_edit`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.application_edit`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Application Edit
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}



                                                                    {formik.values.permission[index].mis_report != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.mis_report}
                                                                                    checked={formik.values.permission[index].mis_report}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.mis_report`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.mis_report`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.mis_report`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    MIS Report
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].master_report != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.master_report}
                                                                                    checked={formik.values.permission[index].master_report}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.master_report`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.master_report`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.master_report`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Master Report
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}



                                                                    {formik.values.permission[index].lp_summary != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.lp_summary}
                                                                                    checked={formik.values.permission[index].lp_summary}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.lp_summary`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.lp_summary`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.lp_summary`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    LP Summary
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].vp_investment != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.vp_investment}
                                                                                    checked={formik.values.permission[index].vp_investment}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.vp_investment`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.vp_investment`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.vp_investment`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    VP Investment
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].live_deal_summary != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.live_deal_summary}
                                                                                    checked={formik.values.permission[index].live_deal_summary}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.live_deal_summary`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.live_deal_summary`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.live_deal_summary`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Live Deal Summary
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].missing_investor_data != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.missing_investor_data}
                                                                                    checked={formik.values.permission[index].missing_investor_data}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.missing_investor_data`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.missing_investor_data`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.missing_investor_data`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Missing Investor Data
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].investor_onboarding_summery != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.investor_onboarding_summery}
                                                                                    checked={formik.values.permission[index].investor_onboarding_summery}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.investor_onboarding_summery`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.investor_onboarding_summery`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.investor_onboarding_summery`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Investor Onboarding Summary
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].active_invactive_investors != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.active_invactive_investors}
                                                                                    checked={formik.values.permission[index].active_invactive_investors}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.active_invactive_investors`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.active_invactive_investors`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.active_invactive_investors`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Active/Inactive Investors
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    
                                                                    {formik.values.permission[index].partner_target_fees != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.partner_target_fees}
                                                                                    checked={formik.values.permission[index].partner_target_fees}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.partner_target_fees`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.partner_target_fees`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.partner_target_fees`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Partner Target Fees
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].startup_document_checklist != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.startup_document_checklist}
                                                                                    checked={formik.values.permission[index].startup_document_checklist}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.startup_document_checklist`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.startup_document_checklist`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.startup_document_checklist`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Startup Document Checklist
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}

                                                                    {formik.values.permission[index].deal_document_checklist != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.deal_document_checklist}
                                                                                    checked={formik.values.permission[index].deal_document_checklist}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.deal_document_checklist`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.deal_document_checklist`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.deal_document_checklist`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Deal Document Checklist
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].qar_report != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.qar_report}
                                                                                    checked={formik.values.permission[index].qar_report}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.qar_report`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.qar_report`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.qar_report`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    QAR
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].fees_report != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.fees_report}
                                                                                    checked={formik.values.permission[index].fees_report}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.fees_report`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_venture_partner`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_lead_partner`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_partner`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_export`, e.target.checked);
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.fees_report`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_venture_partner`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_lead_partner`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_partner`, e.target.checked);
                                                                                            formik.setFieldValue(`permission.${index}.fees_report_export`, e.target.checked);
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.fees_report`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Payouts
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].fees_report_lead_partner != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.fees_report_lead_partner}
                                                                                    checked={formik.values.permission[index].fees_report_lead_partner}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            (formik.values.permission[index].fees_report_venture_partner || formik.values.permission[index].fees_report_partner) && formik.setFieldValue(`permission.${index}.fees_report_lead_partner`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            (formik.values.permission[index].fees_report_venture_partner || formik.values.permission[index].fees_report_partner) && formik.setFieldValue(`permission.${index}.fees_report_lead_partner`, e.target.checked)
                                                                                        }
                                                                                        formik.setFieldValue(`permission.${index}.fees_report`,true)
                                                                                    }}
                                                                                    name={`permission.${index}.fees_report_lead_partner`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Lead Partner Payouts 
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].fees_report_venture_partner != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.fees_report_venture_partner}
                                                                                    checked={formik.values.permission[index].fees_report_venture_partner}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            (formik.values.permission[index].fees_report_lead_partner||formik.values.permission[index].fees_report_partner) && formik.setFieldValue(`permission.${index}.fees_report_venture_partner`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            (formik.values.permission[index].fees_report_lead_partner||formik.values.permission[index].fees_report_partner) && formik.setFieldValue(`permission.${index}.fees_report_venture_partner`, e.target.checked)
                                                                                        }
                                                                                        formik.setFieldValue(`permission.${index}.fees_report`,true)
                                                                                    }}
                                                                                    name={`permission.${index}.fees_report_venture_partner`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Venture Partner Payouts 
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].fees_report_partner != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.fees_report_partner}
                                                                                    checked={formik.values.permission[index].fees_report_partner}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            (formik.values.permission[index].fees_report_lead_partner || formik.values.permission[index].fees_report_venture_partner) && formik.setFieldValue(`permission.${index}.fees_report_partner`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            (formik.values.permission[index].fees_report_lead_partner || formik.values.permission[index].fees_report_venture_partner) && formik.setFieldValue(`permission.${index}.fees_report_partner`, e.target.checked)
                                                                                        }
                                                                                        formik.setFieldValue(`permission.${index}.fees_report`,true)
                                                                                    }}
                                                                                    name={`permission.${index}.fees_report_partner`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Partner Payouts 
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].fees_report_export != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.fees_report_export}
                                                                                    checked={formik.values.permission[index].fees_report_export}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.values.permission[index].fees_report && formik.setFieldValue(`permission.${index}.fees_report_export`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.values.permission[index].fees_report && formik.setFieldValue(`permission.${index}.fees_report_export`, e.target.checked)
                                                                                        }
                                                                                        formik.setFieldValue(`permission.${index}.fees_report`,true)
                                                                                    }}
                                                                                    name={`permission.${index}.fees_report_export`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Payouts Export
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].review != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.review}
                                                                                    checked={formik.values.permission[index].review}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.review`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.review`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.review`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Review
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].checklists != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.checklists}
                                                                                    checked={formik.values.permission[index].checklists}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.checklists`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.checklists`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.checklists`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Checklists
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].feedbacks != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.feedbacks}
                                                                                    checked={formik.values.permission[index].feedbacks}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.feedbacks`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.feedbacks`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.feedbacks`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Feedbacks
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].lists != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.lists}
                                                                                    checked={formik.values.permission[index].lists}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.lists`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.lists`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.lists`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Lists
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].fees != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.fees}
                                                                                    checked={formik.values.permission[index].fees}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.fees`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.fees`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.fees`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Fees
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].reports != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.reports}
                                                                                    checked={formik.values.permission[index].reports}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.reports`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.reports`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.reports`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Reports
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].settings != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.settings}
                                                                                    checked={formik.values.permission[index].settings}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.settings`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.settings`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.settings`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Startup Settings
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].application_settings != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.application_settings}
                                                                                    checked={formik.values.permission[index].application_settings}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.application_settings`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.application_settings`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.application_settings`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Application Settings
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].invoices != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.invoices}
                                                                                    checked={formik.values.permission[index].invoices}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.invoices`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.invoices`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.invoices`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Invoices
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].lead_investors != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.lead_investors}
                                                                                    checked={formik.values.permission[index].lead_investors}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.lead_investors`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.lead_investors`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.lead_investors`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Lead Investors
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].mis_notification != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.mis_notification}
                                                                                    checked={formik.values.permission[index].mis_notification}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.mis_notification`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.mis_notification`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.mis_notification`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    MIS Notifications
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                    {formik.values.permission[index].invoice_email_cc != undefined &&  <div className='col-md-3'>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    defaultChecked={value.invoice_email_cc}
                                                                                    checked={formik.values.permission[index].invoice_email_cc}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            formik.setFieldValue(`permission.${index}.invoice_email_cc`, e.target.checked)
                                                                                        }
                                                                                        else {
                                                                                            formik.setFieldValue(`permission.${index}.invoice_email_cc`, e.target.checked)
                                                                                        }
                                                                                    }}
                                                                                    name={`permission.${index}.invoice_email_cc`}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography variant="subtitle1">
                                                                                    Send CC email of invoice
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </div>}
                                                                </div>
                                                                <div className='custom-hr'/>
                                                            </>
                                                        ))
                                                    ) : (
                                                        <>
                                                        </>
                                                    )}</div>
                                            }}
                                        />
                                    </div>
                                </div>
                            {/* </div> */}
                            <Grid item xs={12} mt={2}>
                                <button className="button-79" type="submit">
                                    Add Permission
                                </button>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </Box>
            </div>
    );
};

export default RolePermissionForm;
