import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { RoundupBigNumbers } from 'utils/Functions';
import { verifyToken, RetryOnError } from 'utils/VerifyToken';
import { formatDate } from 'ui-component/conversionUtils';

  

export const FetchInvestorDetails = (id, mode) => {
    const { data: personalisedData } = useQuery(
        ['fetch-personalised-questions'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/personalize_profile`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            staleTime: Infinity,
            select: (data) =>
                data.data.data.map((question) => {
                    if (question.values[0].type == 'checkbox') {
                        question.values[0]['answer'] = { answer: [] };
                    } else {
                        question.values[0]['answer'] = { answer: '' };
                    }
                    return question;
                }),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
    const { data: moreAboutYouData } = useQuery(
        ['fetch-moreAboutYou-questions'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/more_about_you`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            staleTime: Infinity,
            select: (data) =>
                data.data.data.map((question) => {
                    if (question.values[0].type == 'checkbox') {
                        question.values[0]['answer'] = { answer: [] };
                    } else {
                        question.values[0]['answer'] = { answer: '' };
                    }
                    return question;
                }),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );

    return useQuery(
        ['investor-details', id, mode],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            enabled: !!personalisedData && !!moreAboutYouData && !!id,
            select: (data) => {
                if (mode === 'edit') {
                    const savedData = data.data.data[0];
                    // console.log(savedData,"")
                    const residentialdata = savedData.address.filter(
                        (ad) => ad.address_type === 'residential' && ad.user_type === 'investor'
                    );
                    const nomineeResidentialData = savedData.address.filter(
                        (ad) => ad.address_type === 'residential' && ad.user_type === 'nominee'
                    );
                    const guardianResidentialData = savedData.address.filter(
                        (ad) => ad.address_type === 'residential' && ad.user_type === 'gaurdian'
                    );
                    const currentdata = savedData.address.filter((ad) => ad.address_type === 'current' && ad.user_type === 'investor');
                    const investorBankDetailData = savedData.bankAccounts.filter((bankAccount) => bankAccount.user_type === 'investor');
                    // console.log(investorBankDetailData,"savedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedDatasavedData")

                    let residentialAddress = null;
                    if (residentialdata.length > 0) {
                        residentialAddress = {
                            country: residentialdata[0].country_id || '',
                            state: residentialdata[0].state_id || '',
                            city: residentialdata[0].city_id || '',
                            addressLine1: residentialdata[0].address_line_1 || '',
                            addressLine2: residentialdata[0].address_line_2 || '',
                            zipCode: residentialdata[0].zip_code || '',
                            city_name: residentialdata[0].city_name || '',
                            country_name: residentialdata[0].country_name || '',
                            state_name: residentialdata[0].state_name || ''
                        };
                    }
                    // console.log("residentialAddress", residentialAddress);
                    let currentAddress = null;
                    if (currentdata.length > 0) {
                        currentAddress = {
                            country: currentdata[0].country_id || '',
                            state: currentdata[0].state_id || '',
                            city: currentdata[0].city_id || '',
                            addressLine1: currentdata[0].address_line_1 || '',
                            addressLine2: currentdata[0].address_line_2 || '',
                            zipCode: currentdata[0].zip_code || ''
                        };
                    }

                    let bank_accounts = null;
                    let joint_account = null;
                    let demat_account = null;
                    if (investorBankDetailData.length > 0) {
                        bank_accounts = {
                            bank_id: investorBankDetailData[0]?.bank_id?._id || '',
                            bank_name: investorBankDetailData[0]?.bank_name || '',
                            branch_name: investorBankDetailData[0].branch_name || '',
                            ifsc_code: investorBankDetailData[0].ifsc_code || '',
                            account_holder_name: investorBankDetailData[0].account_holder_name || '',
                            bank_account_number: investorBankDetailData[0].bank_account_number || '',
                            user_type: 'investor',
                            account_type_id: investorBankDetailData[0].account_type_id || '',
                            swift_code: investorBankDetailData[0].swift_code || '',
                            account_type: investorBankDetailData[0].account_type || '',
                            bank_name_view: investorBankDetailData[0].bank_name_view || '',
                        };
                        // console.log("bank_accounts", bank_accounts);
                        if (investorBankDetailData[0].accounts.length > 0) {
                            joint_account = {
                                holder_name: investorBankDetailData[0].accounts[0].joint_holder_name || '',
                                pan: investorBankDetailData[0].accounts[0].joint_holder_pan_number || '',
                                email: investorBankDetailData[0].accounts[0].joint_holder_email || '',
                                contact_number: investorBankDetailData[0].accounts[0].joint_holder_mobile_number || ''
                            };
                        }

                        if (investorBankDetailData[0].demat_account) {
                            demat_account = {
                                id_name: investorBankDetailData[0].demat_account.id_name || '',
                                dp_id: investorBankDetailData[0].demat_account.dp_id || '',
                                customer_id: investorBankDetailData[0].demat_account.customer_id || '',
                                client_id: investorBankDetailData[0].demat_account.client_id || '',
                                account_details_type: investorBankDetailData[0].demat_account.account_details_type || '',
                                demat_name: investorBankDetailData[0].demat_account.demat_name || '',
                            };
                        }

                    }

                    let nominee_details = null;

                    if (savedData.nominee_details.length > 0) {
                        nominee_details = {
                            first_name: savedData.nominee_details[0].first_name || '',
                            middle_name: savedData.nominee_details[0].middle_name || '',
                            last_name: savedData.nominee_details[0].last_name || '',
                            dob: savedData.nominee_details[0].dob || null,
                            relation_with_investor: savedData.nominee_details[0].relation_of_nominee || '',
                            gurdian_name: savedData.nominee_details[0].gurdian_name || '',
                            nominee_pan_number: savedData.nominee_details[0].nominee_pan_number || '',
                            gurdian_first_name: savedData.nominee_details[0].gurdian_first_name || '',
                            gurdian_last_name: savedData.nominee_details[0].gurdian_last_name || '',
                            is_nominee_minor: savedData.nominee_details[0]?.is_nominee_minor || false
                        };
                        let address = null;
                        if (nomineeResidentialData.length > 0) {
                            address = {
                                country: nomineeResidentialData[0].country_id || '',
                                state: nomineeResidentialData[0].state_id || '',
                                city: nomineeResidentialData[0].city_id || '',
                                addressLine1: nomineeResidentialData[0].address_line_1 || '',
                                addressLine2: nomineeResidentialData[0].address_line_2 || '',
                                zipCode: nomineeResidentialData[0].zip_code || ''
                            };
                        } else {
                            address = {
                                country: '',
                                state: '',
                                city: '',
                                addressLine1: '',
                                addressLine2: '',
                                zipCode: ''
                            };
                        }

                        let guardian_address = null;
                        if (guardianResidentialData.length > 0) {
                            guardian_address = {
                                country: guardianResidentialData[0].country_id || '',
                                state: guardianResidentialData[0].state_id || '',
                                city: guardianResidentialData[0].city_id || '',
                                addressLine1: guardianResidentialData[0].address_line_1 || '',
                                addressLine2: guardianResidentialData[0].address_line_2 || '',
                                zipCode: guardianResidentialData[0].zip_code || ''
                            };
                        } else {
                            guardian_address = {
                                country: '',
                                state: '',
                                city: '',
                                addressLine1: '',
                                addressLine2: '',
                                zipCode: ''
                            };
                        }

                        nominee_details = { ...nominee_details, address, guardian_address };
                    }

                    const savedValues = {
                        lp_code: savedData.lp_code || '',
                        first_name: savedData.first_name || '',
                        middle_name: savedData.middle_name || '',
                        last_name: savedData.last_name || '',
                        email: savedData.email || '',
                        mobile_number: savedData.mobile_number || '',
                        phone_code: savedData?.phone_code || '',
                        user_individual_type: savedData?.user_individual_type || 'indian_citizen',

                        country_cityzenship_id: savedData.country_cityzenship_id || '',
                        occupation: savedData.occupation || '',
                        // individual_type: savedData.individual_type || '',
                        pan_number: savedData.pan_number || '',

                        gender: savedData.gender || 'Male',
                        cityzenship_name: savedData.cityzenship_name || '',
                        dob: savedData.dob || null,
                        designation: savedData.designation || '',
                        authorize_personal_email: savedData.authorize_personal_email || '',
                        legal_entity_name: savedData.legal_entity_name || '',
                        official_email: savedData.official_email || '',
                        official_contact: savedData.official_contact || '',
                        referral_partner: savedData.referral_partner || '',
                        demat_file: null,
                        residentialAddress: residentialAddress || {
                            country: '',
                            state: '',
                            city: '',
                            addressLine1: '',
                            addressLine2: '',
                            zipCode: ''
                        },
                        currentAddress: currentAddress || {
                            country: '',
                            state: '',
                            city: '',
                            addressLine1: '',
                            addressLine2: '',
                            zipCode: ''
                        },
                        bank_accounts: bank_accounts || {
                            bank_id: '',
                            bank_name: '',
                            branch_name: '',
                            ifsc_code: '',
                            account_holder_name: '',
                            bank_account_number: '',
                            user_type: 'investor',
                            account_type_id: '',
                            swift_code: ''
                            // account_type:"",
                        },
                        joint_account: joint_account || {
                            holder_name: '',
                            pan: '',
                            email: '',
                            contact_number: ''
                        },

                        demat_account: demat_account || {
                            id_name:  '',
                            dp_id:  '',
                            customer_id:'',
                            client_id: '',
                            account_details_type: '',
                            demat_name: '',
                        },
                        nominee_details: nominee_details || {
                            first_name: '',
                            middle_name: '',
                            last_name: '',
                            dob: null,
                            relation_with_investor: '',
                            gurdian_name: '',
                            address: {
                                country: '',
                                state: '',
                                city: '',
                                addressLine1: '',
                                addressLine2: '',
                                zipCode: ''
                            },
                            gurdian_first_name: '',
                            gurdian_last_name: '',
                            is_nominee_minor: false
                        },

                        documents: {
                            pan: savedData.pan_number || '',
                            panDoc: { name: savedData.pancard_image || '', url: savedData.pancard_image_url || '' } || null,
                            addressProof:
                                { name: savedData.address_proof_image || '', url: savedData.address_proof_image_url || '' } || null
                        },
                        docs: savedData.documents.map((doc) => {
                            return {
                                name: doc.documents[0].name || '',
                                group_id: doc.group_id || '',
                                upload_type: doc.documents[0].upload_type || '',
                                onboard_flag: doc.documents[0].onboard_flag,
                                date:formatDate(doc?.documents[0]?.date ),
                                file: {
                                    name: doc.documents[0].filename || '',
                                    url: doc.documents[0].url || '',
                                    doc_id: doc.documents[0].document_id || '',
                                    docxFileUrl: doc.documents[0].docxFileUrl || '',
                                    docxFileName: doc.documents[0].docxFileName || '',
                                    manualFileURL: doc.documents[0].manualFileURL || '',
                                }
                            };
                        }),
                        // docs: [{ name: savedData, group_id: '', file: null }],
                        personalisedQuestions: savedData?.personalize_profile
                            ? savedData?.personalize_profile.length > 0
                                ? savedData.personalize_profile
                                : personalisedData
                            : personalisedData,
                        moreAboutYouQuestions: savedData?.more_about_you
                            ? savedData?.more_about_you.length > 0
                                ? savedData.more_about_you
                                : moreAboutYouData
                            : moreAboutYouData,
                        // isPartner: savedData?.is_referral?.is_partner || 'No',
                        // isVenturePartner: savedData?.is_referral?.is_venture_partner || 'No',
                        // investor_type: savedData?.investor_type || '',
                        is_referral: savedData?.is_referral
                            ? Object.values(savedData?.is_referral).length > 0
                                ? {
                                      master_user_type_id: savedData?.is_referral?.master_user_type_id.length
                                          ? savedData?.is_referral?.master_user_type_id[0]
                                          : ''
                                  }
                                : { master_user_type_id: '' }
                            : { master_user_type_id: '' },
                        partner: !!savedData.investor_partner
                            ? {
                                  _id: savedData.investor_partner.investor_partner_id || '',
                                  lp_code: savedData.investor_partner.lp_code || '',
                                  name: savedData.investor_partner.name || ''
                              }
                            : { _id: '', lp_code: '', name: '' },
                        venturePartner: !!savedData.investor_venture
                            ? {
                                  _id: savedData.investor_venture.investor_venture_venture_id || '',
                                  lp_code: savedData.investor_venture.lp_code || '',
                                  name: savedData.investor_venture.name || ''
                              }
                            : { _id: '', lp_code: '', name: '' },
                        // leadInvestor: !!savedData.lead_investor ? { _id: savedData.lead_investor.lead_investor_id || "", lp_code: savedData.lead_investor.lp_code || '', name: savedData.lead_investor.name || '' } : { _id: '', lp_code: '', name: '' },
                        representative: !!savedData.investor_representative
                            ? {
                                  _id: savedData.investor_representative.investor_representative_id || '',
                                  lp_code: savedData.investor_representative.lp_code || '',
                                  name: savedData.investor_representative.name || ''
                              }
                            : { _id: '', lp_code: '', name: '' },
                        fullName: savedData.full_name || '',
                        lp_code: savedData.lp_code || '',
                        commitment_amount: savedData.commitment_amount || '',
                        is_lead_investor: savedData.is_lead_investor || 'No',
                        unallocated_amount: savedData.unallocated_amount || '',
                        expertiseInSectors:
                            savedData.personalize_profile_static && savedData.personalize_profile_static.length > 0
                                ? savedData.personalize_profile_static[0].expertiseInSectors || []
                                : [],
                        investInSectors:
                            savedData.personalize_profile_static && savedData.personalize_profile_static.length > 0
                                ? savedData.personalize_profile_static[0].investInSectors || []
                                : [],
                        investInStartUps:
                            savedData.personalize_profile_static && savedData.personalize_profile_static.length > 0
                                ? savedData.personalize_profile_static[0].investInStartUps || []
                                : [],
                        howPreferInvest:
                            savedData.personalize_profile_static && savedData.personalize_profile_static.length > 0
                                ? savedData.personalize_profile_static[0].howPreferInvest || []
                                : [],
                        linkedInProfileUrl:
                            savedData.personalize_profile_static && savedData.personalize_profile_static.length > 0
                                ? savedData.personalize_profile_static[0].linkedInProfileUrl || ''
                                : [],
                        confirmation:
                            savedData.personalize_profile_static && savedData.personalize_profile_static.length > 0
                                ? savedData.personalize_profile_static[0].confirmation || []
                                : [],
                        haveInvestedBefore:
                            savedData.more_about_you_static && savedData.more_about_you_static.length > 0
                                ? savedData.more_about_you_static[0].haveInvestedBefore || ''
                                : '',
                        howHearAboutUs:
                            savedData.more_about_you_static && savedData.more_about_you_static.length > 0
                                ? savedData.more_about_you_static[0].howHearAboutUs || ''
                                : '',
                        referralName:
                            savedData.more_about_you_static && savedData.more_about_you_static.length > 0
                                ? savedData.more_about_you_static[0].referralName || ''
                                : '',
                        moreAboutYou:
                            savedData.more_about_you_static && savedData.more_about_you_static.length > 0
                                ? savedData.more_about_you_static[0].moreAboutYou || ''
                                : '',
                        syndicate_id:
                            savedData?.investor_referral?.syndicate_id && savedData.investor_referral.syndicate_id.length
                                ? savedData.investor_referral.syndicate_id
                                : [],
                        agreement_date: savedData.agreement_date || null,
                        signature_date: savedData.signature_date || null,
                        ckyc: savedData.ckyc || '',
                        unit_class_id: savedData.unit_class_id || '',
                        ppm_number: savedData.ppm_number || '',
                        stamp_number: savedData.stamp_number || '',
                        tags:
                            savedData.tags != null
                                ? savedData.tags.map((tag) => {
                                      return { id: tag || '', text: tag || '' };
                                  })
                                : [],
                        tin_number: savedData.tin_number || '',
                        // commitment_amount_temp: savedData.commitment_amount_temp || '',
                        show_ckyc_button: savedData?.show_ckyc_button,
                        castler_status:savedData?.castler_status
                    };

                    return savedValues;
                } else if (mode === 'settings') {
                    const savedData = data.data.data[0];
                    // console.log("savedData", savedData);
                    const savedValues = {
                        // investor_type: savedData?.investor_type || '',
                        // isPartner: savedData?.is_referral?.is_partner || 'No',
                        // isVenturePartner: savedData?.is_referral?.is_venture_partner || 'No',
                        is_referral: savedData?.is_referral
                            ? Object.values(savedData?.is_referral).length > 0
                                ? {
                                      master_user_type_id: savedData?.is_referral?.master_user_type_id.length
                                          ? savedData?.is_referral?.master_user_type_id[0]
                                          : ''
                                  }
                                : { master_user_type_id: '' }
                            : { master_user_type_id: '' },
                        // is_referral: {
                        //   master_user_type_id: ''
                        // },
                        partner: !!savedData.investor_partner
                            ? {
                                  _id: savedData.investor_partner.investor_partner_id || '',
                                  lp_code: savedData.investor_partner.lp_code || '',
                                  name: savedData.investor_partner.name || ''
                              }
                            : { _id: '', lp_code: '', name: '' },
                        venturePartner: !!savedData.investor_venture
                            ? {
                                  _id: savedData.investor_venture.investor_venture_venture_id || '',
                                  lp_code: savedData.investor_venture.lp_code || '',
                                  name: savedData.investor_venture.name || ''
                              }
                            : { _id: '', lp_code: '', name: '' },
                        // leadInvestor: !!savedData.lead_investor ? { _id: savedData.lead_investor.lead_investor_id || "", lp_code: savedData.lead_investor.lp_code || '', name: savedData.lead_investor.name || '' } : { _id: '', lp_code: '', name: '' },
                        representative: !!savedData.investor_representative
                            ? {
                                  _id: savedData.investor_representative.investor_representative_id || '',
                                  lp_code: savedData.investor_representative.lp_code || '',
                                  name: savedData.investor_representative.name || ''
                              }
                            : { _id: '', lp_code: '', name: '' },
                        fullName: savedData.full_name || '',
                        lp_code: savedData.lp_code || '',
                        unallocated_amount: savedData.unallocated_amount || '',
                        syndicate_id:
                            savedData?.investor_referral?.syndicate_id && savedData.investor_referral.syndicate_id.length
                                ? savedData.investor_referral.syndicate_id
                                : [],
                        agreement_date: savedData.agreement_date || null,
                        signature_date: savedData.signature_date || null,
                        ckyc: savedData.ckyc || '',
                        unit_class_id: savedData.unit_class_id || '',
                        ppm_number: savedData.ppm_number || '',
                        stamp_number: savedData.stamp_number || '',
                        tags: savedData.tags.map((tag) => {
                            return { id: tag || '', text: tag || '' };
                        }),

                        // commitment_amount_temp:savedData.commitment_amount_temp || '',
                        commitment_amount: savedData.commitment_amount || '',
                        is_lead_investor: savedData.is_lead_investor || 'No'
                    };
                    // console.log("savedValues", savedValues);
                    return savedValues;
                } else if (mode === 'lead-investors') {
                    const data1 = data.data.data[0];
                    const savedData = data1?.splash_screen_details
                        ? {
                              company_name: (data1.splash_screen_details.length && data1.splash_screen_details[0].company_name) || '',
                              code: (data1.splash_screen_details.length && data1.splash_screen_details[0].code) || '',
                              slug: (data1.splash_screen_details.length && data1.splash_screen_details[0].slug) || '',
                              logo:
                                  data1.splash_screen_details.length && data1.splash_screen_details[0].logo_url
                                      ? {
                                            name: data1.splash_screen_details[0].logo || '',
                                            url: data1.splash_screen_details[0].logo_url || ''
                                        }
                                      : null
                          }
                        : {
                              company_name: '',
                              code: '',
                              slug: '',
                              logo: null
                          };

                    return savedData;
                }
                return data.data.data[0];
            },
            refetchOnWindowFocus: false,
            // refetchIntervalInBackground:false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestors = (page, search, mode = '', sort = 1, limit = 20, queryString, is_user_type = '',slugApp = false) => {
    return useQuery(
        ['investor-listing', page, mode, sort, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor?page=${page}&searchText=${search}&kyc_status=${mode}${
                    sort != 0 ? `&sort=${sort}` : ''
                }&limit=${limit}${queryString}&is_user_type=${is_user_type}${slugApp ? `&syndicate_slug=app` : ''}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => ({ investors: data.data.data, count: data.data.count, limit: data.data.limit }),
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchLeadInvestors = (page, search, mode = '', sort = 1, limit = 20, queryString) => {
    return useQuery(
        ['lead-investor-listing', page, mode, sort, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/list/lead-investors?page=${page}&searchText=${search}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => ({ investors: data.data.data, count: data.data.count, limit: data.data.limit }),
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchAllInvestors = (mode = '') => {
    let id = '';
    const home = useQuery(
        ['fetch-kyc-id', mode],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/kyc`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
    if (home.data) {
        id = home.data.data.data.filter((el) => el.value == 'approved')[0]?._id || '-';
    }

    return useQuery(
        ['all-investors'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor?master_status_id=63c52cba3ee2bd1a40f41a72`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (mode == 'settings') {
                    return data.data.data.map((investor) => {
                        return {
                            _id: investor._id,
                            value: investor.lp_code,
                            name: investor.full_name,
                            lp_code: investor.lp_code,
                            unallocated_amount: investor.unallocated_amount || 0
                        };
                    });
                }
                const newInvestorListing = data.data.data.map((investor) => ({
                    value: investor['lp_code'],
                    label: `${investor.name} (${investor['lp_code']})`
                }));
                return { investors: newInvestorListing, count: data.data.count, limit: data.data.limit };
            },
            refetchOnWindowFocus: false,
            enabled: !!id,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchIntrestedInvestors = (page, id) => {
    return useQuery(
        ['fetch-intrested-investors', page, id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/interested/investors/${id}?page=${page}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => ({ investors: data.data.data, count: data.data.count, limit: data.data.limit }),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchDocumentTypes = () => {
    return useQuery(
        ['fetch-document-types'],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/document`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) =>
                data.data.data.map((doc) => {
                    return { label: doc.values[0].name, value: doc._id };
                }),
            staleTime: Infinity,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const ApprovedInvestors = ({ mode = '' }) => {
    return useQuery(
        ['approved-investors', mode],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/approved/list?investor_status=${mode}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorStatusLogs = (id) => {
    return useQuery(
        ['fetch-investor-status-logs', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/kyc/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchConfirmationListInvestors = (page, id) => {
    return useQuery(
        ['fetch-confirmation-list-investors', page],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/payment/${id}?page=${page}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                // const newInvestors = data.data.data.map((investor)=>{
                //   return {...investor, amount: RoundupBigNumbers(investor.amount)}
                // })
                return { investors: data.data.data, count: data.data.count, limit: data.data.limit, totalAmount: data.data.totalAmount };
            },
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchTransactions = (id) => {
    return useQuery(
        ['fetch-all-transactions', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/payment/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            refetchOnWindowFocus: false,
            select: (data) => {
                return {
                    investors: data.data.data,
                    count: data.data.count,
                    limit: data.data.limit,
                    totalInterestedAmount: data.data.totalInterestedAmount,
                    totalAmount: data.data.totalAmount,
                    totalRemainingAmount: data.data.totalRemainingAmount,
                    totalReceivedAmount: data.data.totalReceivedAmount,
                    realTotalAmount: data.data.realTotalAmount,
                    realTotalInterestedAmount: data.data.realTotalInterestedAmount,
                    realTotalRemainingAmount: data.data.realTotalRemainingAmount,
                    realTotalReceivedAmount: data.data.realTotalReceivedAmount,
                };
            },
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorTypes = ({ mode = '' }) => {
    return useQuery(
        ['fetch-investor-types'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/user_type`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            refetchOnWindowFocus: true,
            select: (data) => {
                if (mode == 'select') {
                    return data.data.data.map((investor) => {
                        return { label: investor.values[0].name, value: investor._id };
                    });
                }
                return data.data.data;
            },
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchReferralList = ({ investorType, outputFormat = '', id: investorId = '', syndicate_id }) => {
    // console.log("id", id);
    const { data: partners } = useQuery(
        ['fetch-partners'],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/user_type`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) =>
                data.data.data.map((investor) => ({ _id: investor._id, value: investor.value, name: investor.values[0].name })),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            },
            refetchOnWindowFocus: false
        }
    );

    const partnerID = partners?.length && partners.filter((partner) => partner.value === investorType)[0]?._id;

    return useQuery(
        ['fetch-referral-list', partnerID, investorId],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/referral/list?master_user_type_id=${partnerID}${
                    investorId ? `&investor_id=${investorId}` : ''
                }`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            enabled: !!partnerID,
            select: (data) => {
                // console.log(data.data.data);
                if (outputFormat === 'select') {
                    return data.data.data.map((investor) => ({
                        value: investor._id,
                        label: `${investor.name}${investor?.lp_code ? ` (${investor?.lp_code})` : ''}`
                    }));
                }
                return data.data.data;
            },
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            },
            refetchOnWindowFocus: false
        }
    );
};

export const FetchContributerList = ({ syndicate_id = [] }) => {
    return useQuery(
        ['fetch-contri  buter-investors', syndicate_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/reprenstative/list?syndicate_id=${syndicate_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchReferredPartners = ({ investorId = '' }) => {
    return useQuery(
        ['fetch-referred-partners', investorId],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/referral/list?is_representative_partner=Yes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (investorId) {
                    return data.data.data.filter((investor) => investor._id !== investorId);
                }
                return data.data.data;
            },
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorPartners = ({ investorId = '' }) => {
    return useQuery(
        ['fetch-investor-partners', investorId],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/referral/list?is_partner=Yes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (investorId) {
                    return data.data.data.filter((investor) => investor._id !== investorId);
                }
                return data.data.data;
            },
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorVenturePartners = ({ investorId = '' }) => {
    return useQuery(
        ['fetch-investor-venture-partners', investorId],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/referral/list?is_venture_partner=Yes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => {
                if (investorId) {
                    return data.data.data.filter((investor) => investor._id !== investorId);
                }
                return data.data.data;
            },
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UploadInvestorDocs = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/documents/upload/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const AddInvestorQuery = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UpdateInvestor = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const ChangeKycStatus = () => {
    return useMutation(
        ({ id, payload }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/kyc/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload,
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    percent = Math.floor((loaded * 100) / total);
                    console.log(`${loaded}kb of ${total}kb | ${percent}%`); // just to see whats happening in the console

                    if (percent <= 100) {
                        setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
                    }
                }
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UpdatePartnerSettings = () => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/setting/save`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,

            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const DeleteDocument = () => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/documents`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UpdateLeadInvestorData = () => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/detail/splash_screen`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: payload
            });
        },
        {
            // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            retry: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchAllIndustries = () => {
    return useQuery(
        ['fetch-all-corporates-types'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/industries/get_all`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data.map((returnData) => ({ value: returnData.name, label: returnData.name })),
            refetchOnWindowFocus: false
        }
    );
};

export const ExportInvestors = (page, search, mode = '', sort = 1, limit = 20, queryString) => {
    return useQuery(
        ['export-investors', page, mode, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/excel/list?page=${page}&searchText=${search}&kyc_status=${mode}${
                    sort != 0 ? `&sort=${sort}` : ''
                }${queryString}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            enabled: false,
            select: (data) => data.data.data.url,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            staleTime: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
export const InvestorDetails = ({ id }) => {
    return useQuery(
        ['investor-details-v2', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/view/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => data.data.data,
            refetchOnWindowFocus: false
        }
    );
};
export const FetchTemporaryInvestorDetails = ({ id }) => {
    return useQuery(
        ['temp-sevjosrg-investor-details-v5y34y54', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/get-temp-investor/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => { 
                // console.log(data.data,"datat")
                return data.data?.temp_investor_data
            },
            refetchOnWindowFocus: false
        }
    );
};

export const AddSoaFile = (setProgress) => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/zip/upload`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                onUploadProgress: (data) => {
                    setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const GetSoaDetails = ({ page, search, sort = 1, limit = 20 }) => {
    return useQuery(
        ['fetch-soa-details', page, sort, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/zip?page=${page}&searchText=${search}${
                    sort != 0 ? `&sort=${sort}` : ''
                }&limit=${limit}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                }
            });
        },
        {
            select: (data) => ({ data: data.data.data, count: data.data.count }),
            refetchOnWindowFocus: true,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const GetSoaZipDetails = ({ page, search, sort = 1, limit = 20, soa_zip_upload_id }) => {
    return useQuery(
        ['fetch-soa-zip-details', page, sort, limit, soa_zip_upload_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/zip/logs?page=${page}&searchText=${search}${
                    sort != 0 ? `&sort=${sort}` : ''
                }&limit=${limit}&soa_zip_upload_id=${soa_zip_upload_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                }
            });
        },
        {
            select: (data) => ({
                data: data.data.data,
                count: data.data.count,
                soaZipLogPending: data.data.soaZipLogPending,
                soaZipLogFailed: data.data.soaZipLogFailed,
                soaZipLogUploaded: data.data.soaZipLogUploaded
            }),
            refetchOnWindowFocus: true,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const SendMailFromSOA = () => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/zip/send-soa-email`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            select: (data) => ({ data: data.data.data, count: data.data.count }),

            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const ExportTransaction = (page, search, mode = '', sort = 1, limit = 20, queryString) => {
    return useQuery(
        ['export-transactions', page, mode, sort],
        () => {
            return axios({
                method: 'get',
                url: `${
                    process.env.REACT_APP_BASE_URL
                }/admin/report-export/investor-deals?page=${page}&searchText=${search}&kyc_status=${mode}${
                    sort != 0 ? `&sort=${sort}` : ''
                }${queryString}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            // enabled: false,
            select: (data) => data.data.data.url,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            staleTime: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const TagsList = () => {
    return useQuery(
        ['tags-list'],
        async () => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_URL}/admin/setting/tags?type=investor`,
                    headers: {
                        Authorization: `Bearer ${Cookies.get('jwt')}`
                    }
                });

                const tagList = response.data.data.map((tag) => ({
                    id: tag._id, // Use the appropriate property for the id
                    text: tag.name
                }));

                return tagList;
            } catch (error) {
                throw error;
            }
        },
        {
            refetchOnWindowFocus: false
        }
    );
};

export const AddStampFile = (setProgress) => {
  return useMutation(({ payload }) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/stamp/upload`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "multipart/form-data"
      },
      data: payload,
      onUploadProgress: (data) => {
        setProgress(Math.round((data.loaded / data.total) * 100) == 100 ?  99     : Math.round((data.loaded / data.total) * 100) );
      },
    })
  }, {
    retry: 0,
    // onSuccess: ()=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    onError: (err) => {
      return verifyToken(err)
    },
  })

}
export const GetStampDetails = ({ page, search, sort = 1, limit = 20, status }) => {
    return useQuery(
        ['fetch-stamp-details', page, sort, limit, status],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/stamp?page=${page}&searchText=${search}&status=${status}${
                    sort != 0 ? `&sort=${sort}` : ''
                }&limit=${limit},`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                }
            });
        },
        {
            select: (data) => ({
                data: data.data.data,
                count: data.data.count,
                unAssignedStampCount: data.data.unAssignedStampCount,
                assignedStampCount: data.data.assignedStampCount,
                totalStampCount:data.data.totalStampCount
            }),
            refetchOnWindowFocus: true,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const GenerateContributionAgreementAndPPM = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/documentgenerate/upload`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const StampStatus = () => {
    return useMutation(
        ({ stamp_document_id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/stamp/change-status/${stamp_document_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                }
                // data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorsFilters = (page, search, mode, sort = 1, limit = 20, queryString) => {
    return useQuery(
        ['investor-listing-filters', page, mode, sort, limit,queryString],
        () => {
            // Check if queryString has a value
            // if (queryString ) {
            if (queryString == '') {
                page = 10000;
            }
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor?page=${page}&searchText=${search}&kyc_status=${mode}${
                    sort != 0 ? `&sort=${sort}` : ''
                }&limit=${limit}${queryString}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
            // }
        },
        {
            select: (data) => ({ data: data.data.data, count: data.data.count, limit: data.data.limit }),
            refetchOnWindowFocus: true,
            refetchOnMount: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
export const SendSoamail = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/zip/send-soa-doc-email`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};
export const ChangeKycUpdateOption = (id) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/kyc-request-updated/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};
export const SendPPMmail = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/documents/send-document-email`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const FetchOnboarding = () => {
    return useMutation(
        (payload) => {
            // console.log(payload, 'payloadpayloadpayloadpayload');
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/onboarding/get-all?from_on_board_date=${payload?.from_on_board_date}&to_on_board_date=${payload?.to_on_board_date}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => ({
                data: data.data.data,
                count: data.data.count,
                limit: data.data.limit,
                kycCountData: data.data.kycCountData
            }),
            refetchOnWindowFocus: true,
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

// export const ExportInvestorsOnboarded = (queryString,excel_report) => {

//   return useQuery(
//     ['export-investor-onboarding'],
//     () => {
//       // Check if queryString has a value
//       // if (queryString ) {
//         // if(queryString == ""){
//         //   page =10000
//         // }
//         return axios({
//           method: 'get',
//           url: `${process.env.REACT_APP_BASE_URL}/admin/investor/onboarding/get-all?${queryString}&type=${excel_report}`,
//           headers: {
//             'Authorization': `Bearer ${Cookies.get('jwt')}`
//           },
//         });
//       // }
//     },
//      {
//     select: (data) => ({ data: data.data.data, count: data.data.count, limit: data.data.limit,kycCountData:data.data.kycCountData }),
//     refetchOnWindowFocus: true,
//     refetchOnMount: false,
//     retry: (failureCount, error) => {
//       return RetryOnError(failureCount, error)
//     },
//     onError: (err) => {
//       return verifyToken(err)
//     },
//   })
// }

export const ExportInvestorsOnboarded = (from_on_board_date, to_on_board_date, excel_report) => {
    return useQuery(
        ['export-investor-onboarding'],
        async () => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_URL}/admin/investor/onboarding/get-all?from_on_board_date=${from_on_board_date}&to_on_board_date=${to_on_board_date}&type=${excel_report}`,
                    headers: {
                        Authorization: `Bearer ${Cookies.get('jwt')}`
                    }
                });
                // console.log("repsonseData" , response.data)
                return response.data;
            } catch (error) {
                // Handle error
                console.error(error);
                throw error;
            }
        },
        {
            enabled: false,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            staleTime: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchTranches = (id) => {
    return useQuery(
        ['fetch-all-tranches', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/payment/${id}?is_trans_added=Yes`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            refetchOnWindowFocus: true,
            select: (data) => {
                return {
                    investors: data.data.data,
                    count: data.data.count,
                    limit: data.data.limit,
                    totalInterestedAmount: data.data.totalInterestedAmount,
                    totalAmount: data.data.totalAmount
                };
            },
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const UnassignOnboardingDoc = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/admin/investor/documents`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};
export const GenerateCKYC = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/admin/documentgenerate/kyc`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};

export const SyndicateSoaemail = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/zip/send-syndicate-soa-email `,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-Type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            onError: (err) => {
                // Handle errors or perform actions on error if needed
                console.error('Error generating documents', err);
                throw err; // Re-throw the error for further handling if necessary
            }
        }
    );
};
export const UpdateCastlerStatus = () => {
    return useMutation(({castler_status, investorId}) => {
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/investor/castler/${investorId}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          "Content-type": "application/json"
        },
        data: {
            castler_status: castler_status ? 'Yes' : 'No'
        }
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    })  
  }

  export const UploadDocumentForInvestor = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/payment/upload-document/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const DeleteDocumentForInvestor = () => {
    return useMutation(
        ({ payload, id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/admin/payment/delete-document/${id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorLedger = (id,page, sort = 1, limit = 20) => {
    // page=${page}
    return useQuery(
        ['investor-listing', id,page, sort, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/statement/ledger/${id}?sort=${sort}&limit=${limit}&field=name`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            select: (data) => (data.data.data),
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const ExportKratos = (page, search, mode = '', sort = 1, limit = 20, queryString) => {
    return useQuery(
        ['export-transactions', page, mode, sort],
        () => {
            return axios({
                method: 'get',
                url: `${
                    process.env.REACT_APP_BASE_URL
                }/admin/investor/excel-limited-field/list?master_status_id=&is_partner=Yes&page=${page}&searchText=${search}&kyc_status=${mode}${
                    sort != 0 ? `&sort=${sort}` : ''
                }${queryString}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
            // enabled: false,
            select: (data) => data.data.data,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            staleTime: 0,
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};



export const FetchQARReports = () => {
    return useMutation(
      (payload) => {
          return axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/report-export/fund-investment-qar?from_completed_date=${payload?.from_date}&to_completed_date=${payload?.to_date}&investment_type=${payload?.type}`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
          select: (data) => ({
              data: data.data,
          }),
          refetchOnWindowFocus: true,
          retry: 0,
          onError: (err) => {
              // Handle errors or perform actions on error if needed
              console.error('Error generating documents', err);
              throw err; // Re-throw the error for further handling if necessary
          }
      }
  );
  };

  export const UpdateInvestorProfile = () => {
    return useMutation((payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/admin/investor/update-profile`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          "Content-type": "multipart/form-data"
        },
        data:payload
      })
    }, {
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }
  
  export const AddTransactionToInvestorLedger = () => {
    return useMutation(({payload}) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/admin/statement/add-ledger`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          "Content-type": "application/json"
        },
        data:payload
      })
    }, {
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }

  export const SendInvestorsListEmailToKratos = () => {
    return useMutation(({ payload, search ="", queryString="" ,mode=""}) => {
        console.log(payload,search,queryString,mode,"kyc")
      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}/admin/investor/kratos/send-kratos-email?searchText=${search}&kyc_status=${mode}${queryString}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
        data: payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err) => {
        return verifyToken(err)
      },
    })
  }