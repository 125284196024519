import {useState,useEffect,lazy} from 'react'
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import { UpdateDealStatusUnit,FetchUnitDealLogs,FetchTranches,FetchUnitDealLogsNav, FetchTranchesLog } from 'api'
import 'react-toastify/dist/ReactToastify.css';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import AsyncTableListings from 'ui-component/TranchesTable';
import Loading from 'views/Loading';
import { useParams, useNavigate } from 'react-router';
import { useFormik, Form, Formik, FormikProvider } from "formik";
import ConfirmStatusPopup from './ConfirmStatusPopup';
import Modal from 'ui-component/Modal';
import { Grid, Box,Paper } from '@mui/material'
import CustomField from 'ui-component/formik/CustomField';
import { toast } from 'react-toastify';
import Loadable from 'ui-component/Loadable';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { Tooltip} from '@mui/material';
import CustomButton from 'ui-component/CustomButton';
import TranchUndoPopup from './TranchUndoPopup'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import Clickable from 'utils/Clickable';

const columns = [
  // {
  //   label: 'Name', 
  //   minWidth: 100,
  //   align: 'left',
  //   type: 'admin_name'
  // },
  {
    label: 'Unit Value', 
    minWidth: 120,
    align: 'left',
    type: 'unit_value'
  },
  // {
  //   label: 'Type', 
  //   minWidth: 0,
  //   align: 'left',
  //   type: 'type'
  // },
  // {
  //   label: 'Total Investors', 
  //   minWidth: 150,
  //   align: 'left',
  //   // type: 'investment_count'
  // },
  // {
  //   label: 'Total Amount', 
  //   minWidth: 150,
  //   align: 'left',
  //   // type: 'investment_amount'
  // },
  {
    label: 'Comment', 
    minWidth: 90,
    align: 'left',
    type: 'comment'
  },
  {
    label: 'Updated By', 
    minWidth: 120,
    align: 'left',
    type: 'admin_name'
  },
  {
    label: 'Updated At', 
    minWidth: 170,
    align: 'left',
    type: 'created_date'
  },
  // {
  //   label: 'Action', 
  //   minWidth: 120,
  //   align: 'right',
  //   type: 'actions'
  // },
  

  
];

const AllotUnit = ({deal_status,deal_current_value,refetchListing})=> {
    const { id: deal_id } = useParams()
    const navigate = useNavigate();
    const initialValues = {
        deal_status: deal_status,
        unit_value:deal_current_value,
        // deal_completed_date :  deal_completed_date,
        comment:"",
      }
    const [fromik_values, formikValues] = useState(initialValues);
    const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = UpdateDealStatusUnit();
    const { data:logs, isLoading:isLogsLoading,refetch: refetchLogListing } = FetchUnitDealLogsNav(deal_id)
    const { data: tranches, isLoading: isTranchesLogLoading, refetch: refetchTranchesListing } = FetchTranchesLog(deal_id);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            
    
            // UpdateMasterTemplate({ payload: values, id: master_id })
        }
    })
    const handleConfirm = () =>{
        if(deal_status != "completed"){
          UpdateDealStatus({ payload: {"deal_status":formik.values.deal_status,
          "current_unit_value":formik.values.unit_value,
          "comment":formik.values.comment,
          // "deal_completed_date":formik.values.deal_completed_date
        }, 
          id: deal_id
         })
        }else{
          UpdateDealStatus({ payload: {"current_unit_value":formik.values.unit_value,
          "comment":formik.values.comment },id: deal_id })
        }
   
  
        }
      useEffect(() => {
          if (UpdateDealStatusUnitStatus == 'success') {
            refetchLogListing()
            //   refetchListing()
              setOpen(false);
              toast.success('Unit Updated!', {
                  position: "top-left",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  // onClose: () => {
                  //     navigate('/deals')
                  // }
  
              });
          }
          if (UpdateDealStatusUnitStatus == 'error') {
              toast.error(UpdateDealStatusUnitError?.response?.data?.message, {
                  position: "top-left",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
              handleClose();
  
          }
      }, [UpdateDealStatusUnitStatus])
      const openModel = () => {
        setOpen(true);
      }
    return (

        <div className='custom-card card mt-3' style={{ marginBottom: '20px' }}>
          {<Modal open={open} onClose={handleClose} closeIcon={true}>
           <ConfirmStatusPopup
          // activeRow={activeRow}
           handleClose={handleClose}
           handleConfirm={handleConfirm}
          />
          </Modal>}
          <h3 style={{ marginLeft: '20px' }}>Allot Units</h3>
            <div className='card-body'>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                        <Box>
                        <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                            <Grid item  xs={12}>
                            <Tooltip title={convertToIndianCurrencyWords(formik.values.unit_value)}>
                                 <CustomField name="unit_value" label="Updated Unit Value" disabled={tranches?.deal_unit_changes?.length > 0 && (deal_status)?.toLowerCase() != "completed"} compulsory={true} type="number"
                                 inputProps={{
                                  min: 0,
                                  onKeyDown: (event) => {
                                    // Prevent the minus sign (-) from being entered
                                    if (event.key === '-') {
                                      event.preventDefault();
                                    }
                                  },
                                  onKeyPress: (event) => {
                                    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
                                    if (event.key === '-') {
                                      event.preventDefault();
                                    }
                                  },
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  onKeyDown: (event) => {
                                    // Prevent the input of minus sign (key code 189)
                                    if (event.keyCode === 189 || event.key === "-") {
                                      event.preventDefault();
                                    }
                                  },
                                }}
                                 /> 
                                 </Tooltip>               
                            </Grid>
                            <Grid item xs={12}>
                                <CustomField name="comment" label="Comment"/>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                            <button type="button"  onClick={openModel} className='button-79 text-center theme-btn h-50' >Update Unit</button>
                            </Grid>
                        </Grid>
                        {isLogsLoading ? <Loading />:
          <div class="mt-3">
          <AsyncTableListing 
        title="Logs" 
          columns = {columns}
        // actions={TranchesActions}
        data={logs?.deal_unit_changes}
        // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(callForMoneyData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
        // page={logPage}
        // setPage={setLogPage}
        count={logs?.deal_unit_changes.length}
        limit={5}
        pagination={false}
          tableStyle={{minWidth: 200}}
          />
          </div>
          }
                         </Box>
                        </Form>
                    </FormikProvider>
            </div>
        </div>
    );
}
export default AllotUnit;
