import { useState, useEffect, lazy } from 'react';
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import { UpdateDealStatusUnit, FetchUnitDealLogs, FetchTranches, FetchTranchesLog, ExportDealTransaction } from 'api';
import 'react-toastify/dist/ReactToastify.css';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import AsyncTableListings from 'ui-component/TranchesTable';
import Loading from 'views/Loading';
import { useParams, useNavigate } from 'react-router';
import { useFormik, Form, Formik, FormikProvider } from 'formik';
import ConfirmStatusPopup from './ConfirmStatusPopup';
import Modal from 'ui-component/Modal';
import { Grid, Box, Paper } from '@mui/material';
import CustomField from 'ui-component/formik/CustomField';
import { toast } from 'react-toastify';
import Loadable from 'ui-component/Loadable';
import { convertToIndianCurrencyWords } from 'ui-component/conversionUtils';
import { Tooltip } from '@mui/material';
import CustomButton from 'ui-component/CustomButton';
import TranchUndoPopup from './TranchUndoPopup';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import Clickable from 'utils/Clickable';
import AllotUnit from 'views/deal/AllotUnit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExportIcon from '@mui/icons-material/FileDownload';
const validationSchema = yup.object({
    min_investment_amount: yup.string('Enter Min Investment Amount').required('Min Investment Amount is required').trim(),
    max_investment_amount: yup.string('Enter Max Investment Amount').required('Max Investment Amount is required').trim(),
    closing_date: yup.string('Enter Closing Date').required('Closing Date is required').trim(),
    pitch_recording: yup.string('Enter Pitch Recording').required('Pitch Recording is required').trim()
});
const columns = [
    {
        label: 'Tranch ID',
        minWidth: 100,
        align: 'left',
        type: 'tranch_id'
    },
    {
        label: 'Tranch Name',
        minWidth: 130,
        align: 'left',
        type: 'tranch_name'
    },
    {
        label: 'Unit Value',
        minWidth: 120,
        align: 'left',
        type: 'unit_value'
    },
    // {
    //   label: 'Type',
    //   minWidth: 0,
    //   align: 'left',
    //   type: 'type'
    // },
    {
        label: 'Total Investors',
        minWidth: 150,
        align: 'left',
        type: 'investment_count'
    },
    {
        label: 'Total Amount',
        minWidth: 150,
        align: 'left',
        type: 'investment_amount',
        price_tooltip: true,
        tooltip_amount: 'real_investment_amount'
    },
    {
        label: 'Comment',
        minWidth: 90,
        align: 'left',
        type: 'comment'
    },
    {
        label: 'Creator',
        minWidth: 120,
        align: 'left',
        type: 'admin_name'
    },
    {
        label: 'Fund Transfer Date',
        minWidth: 170,
        align: 'left',
        type: 'created_date'
    },
    {
        label: 'Action',
        minWidth: 120,
        align: 'right',
        type: 'actions'
    }
];

const columns2 = [
    {
        label: 'LP Code',
        minWidth: 130,
        align: 'left',
        type: 'lp_code'
    },
    {
        label: 'Investor Name',
        minWidth: 130,
        align: 'left',
        type: 'investor_name'
    },
    {
        label: 'Email',
        minWidth: 230,
        align: 'left',
        type: 'email'
    },
    {
        label: 'Partner',
        minWidth: 130,
        align: 'left',
        type: 'investor_partner'
    },
    {
        label: 'Syndicate(Partner)',
        minWidth: 130,
        align: 'left',
        type: 'syndicate_partner_name'
    },

    // {
    //   label: 'Transaction ID',
    //   minWidth: 130,
    //   align: 'left',
    //   type:'transcationID'
    // },
    {
        label: 'Interest Amount',
        minWidth: 150,
        align: 'left',
        type: 'ref_interested_amount'
    },
    {
        label: 'Interest Date',
        minWidth: 130,
        align: 'left',
        type: 'investment_interest_date_ref'
    },
    {
        label: 'Fund Received Amount',
        minWidth: 200,
        align: 'left',
        type: 'ref_amount',
        highlight: true
    },
    {
        label: 'Fund Received Date',
        minWidth: 170,
        align: 'left',
        type: 'funds_transferred_date_ref'
    },
    {
        label: 'Unit Allocated',
        minWidth: 150,
        align: 'left',
        type: 'unit_alloted'
    },
    {
        label: 'Remark',
        minWidth: 130,
        align: 'left',
        type: 'remark',
        trim: 60
    },
    {
        label: 'Checkbox',
        minWidth: 60,
        align: 'center',
        type: 'checkbox'
    }
    // {
    //   label: 'Screenshot',
    //   minWidth: 130,
    //   align: 'left',
    //   type:'document_url'
    // },
    // {
    //   label: 'Interested Amount',
    //   minWidth: 130,
    //   align: 'left',
    //   type:'ref_interested_amount'
    // },
    // {
    //   label: 'Confirmation ID',
    //   minWidth: 130,
    //   align: 'left',
    //   type:'confirmationID'
    // },
    // {
    //   label: 'Created',
    //   minWidth: 120,
    //   align: 'left',
    //   type: 'created'
    // },
    // dealDetailData.deal_status === 'completed'
    //   ? {
    //       label: 'Unit Allocated',
    //       minWidth: 150,
    //       align: 'left',
    //       type: 'unit_alloted',
    //     }
    //   : null,
];
const ExampleFile = ({
    deal_completed_date,
    deal_status,
    deal_current_value,
    refetchListing,
    dealsModule,
    isTranchesLoading,
    tranchesInvestorsData,
    refetchTranches,
    TranchesStatus,
    refetchTransactions,
    is_refech_calling
}) => {
    const initialValues = {
        deal_status: deal_status,
        unit_value: deal_current_value,
        // deal_completed_date :  deal_completed_date,
        comment: ''
    };
    const checkedValues = [];
    const [fromik_values, formikValues] = useState(initialValues);
    const [logPage, setLogPage] = useState(1);
    const theme = useTheme();
    const [set_mail, setIsMail] = useState(false);
    const { id: deal_id } = useParams();
    const { mutateAsync: UpdateDealStatus, status: UpdateDealStatusUnitStatus, error: UpdateDealStatusUnitError } = UpdateDealStatusUnit();
    // const { isLoading: isTranchesLoading,data: tranchesInvestorsData, refetch: refetchTranches, status: TranchesStatus } = FetchTranches(deal_id);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [transactionIDs,setTransactionIDs] = useState([])
    const navigate = useNavigate();
    const Error404 = Loadable(lazy(() => import('views/error/Error404')));
    // const { data:logs, isLoading:isLogsLoading,refetch: refetchLogListing } = FetchUnitDealLogs(deal_id)
    const { data: tranches, isLoading: isTranchesLogLoading, refetch: refetchTranchesListing } = FetchTranchesLog(deal_id);
    const {
        refetch: refetchExportDealTransaction,
        data: transData,
        isLoading: isExportDealTransactionLoading,
        isRefetching: isExportDealTransactionRefetching,
        status: exportDealTransactionStatus
    } = ExportDealTransaction(deal_id, 'excel_report', transactionIDs);
    // console.log(tranches,"tranches5666")
    const [setCheckValues, setCheckedValues] = useState([]);
    const [actionColumns, setActionColumns] = useState([]);
    const handleClose = () => setOpen(false);
    useEffect(() => {
    if(is_refech_calling == 1){
        refetchTranchesListing()
    }
    }, [is_refech_calling]);

    const openModel2 = (tranch_id) => {
        // const checkboxes = document.getElementsByClassName('ElementCheck2');
        // let checkedCount = 0;
        // for (let i = 0; i < checkboxes.length; i++) {
        //   if (checkboxes[i].checked) {
        //     checkedValues.push(checkboxes[i].value);
        //   }
        // }
        setCheckedValues(tranch_id);
        // for (let i = 0; i < checkboxes.length; i++) {
        //   if (checkboxes[i].checked) {
        //     checkedCount++;
        //   }
        // }
        setOpen2(true);
        // if(checkedCount>0){
        // setOpen2(true);
        // }else{
        //   setOpen2(false);
        // }
    };

    // console.log(transData,isExportDealTransactionRefetching,isExportDealTransactionLoading)
    const handleClose2 = () => setOpen2(false);
    const formik = useFormik({
        initialValues: fromik_values,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            // UpdateMasterTemplate({ payload: values, id: master_id })
        }
    });
    // refetchTranches()

    const handleConfirm = () => {
        if (deal_status != 'completed') {
            UpdateDealStatus({
                payload: {
                    deal_status: formik.values.deal_status,
                    current_unit_value: formik.values.unit_value,
                    comment: formik.values.comment
                    // "deal_completed_date":formik.values.deal_completed_date
                },
                id: deal_id
            });
        } else {
            UpdateDealStatus({ payload: { current_unit_value: formik.values.unit_value, comment: formik.values.comment }, id: deal_id });
        }
    };
    useEffect(() => {
        if (UpdateDealStatusUnitStatus == 'success') {
            refetchLogListing();
            refetchListing();
            setOpen(false);
            toast.success('Unit Updated!', {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                onClose: () => {
                    navigate('/deals');
                }
            });
        }
        if (UpdateDealStatusUnitStatus == 'error') {
            toast.error(UpdateDealStatusUnitError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [UpdateDealStatusUnitStatus]);
    const openModel = () => {
        setOpen(true);
    };

    useEffect(() => {
        if(deal_status !== 'cancelled'){
            setActionColumns([
                {
                    label: 'Actions',
                    minWidth: 130,
                    align: 'right',
                    type: 'actions'
                }
            ]);
        }
    }, [deal_status]);
    function handleExportInvestment(row) {
        // console.log(row?.transaction_ids
        //     ,"export row")
        setTransactionIDs(row?.transaction_ids)
        setTimeout(() => {
            refetchExportDealTransaction().then(({ data }) => {
                if ({ data }) {
                    const link = document.createElement('a');
                    link.href = data.url;
                    link.target = '_blank';
                    link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
                    link.click();
                } else {
                    console.error('Data is missing in the response:', data);
                }
            });
        }, 1000);
    }
    const TranchesActions = ({ row }) => {
        if (row.show_undo_button == 'Yes') {
            return (
                <>
                <Grid display={'flex'} alignItems={'center'} justifyContent={'end'} columnGap={1} flexDirection={'row'}>
                        
                    {dealsModule != undefined && dealsModule?.remove_tranch == true && (
                        <Clickable
                            style={{ display: 'flex' }}
                            onClick={() => {
                                openModel2(row._id);
                            }}
                        >
                            <Tooltip title="Remove Tranch" className="text-end">
                                <div >
                                    <DeleteIcon className="icon-size" stroke={1.5} size="1.4rem" />
                                </div>
                            </Tooltip>
                        </Clickable>
                        
                    )}
                    <Clickable
                            style={{ display: 'flex'}}
                            onClick={() => {
                                handleExportInvestment(row)
                            }}
                        >
                             <Tooltip title="Export Investment" className="text-end">
                                <div >
                                    <ExportIcon className="icon-size" stroke={1.5} size="1.4rem" />
                                </div>
                            </Tooltip>
                        </Clickable>
                        </Grid>
                </>
            );
        }
    };

    const filteredStaticColumns = columns.filter((column) => column.type !== 'actions');
    const combinedColumns = [
        ...filteredStaticColumns,
        ...actionColumns
        // ...columns.filter((column) => column.type === 'actions')
    ];
    return dealsModule != undefined && dealsModule?.deal_nav == true ? (
        <div>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    padding: '10px',
                    flex: 1,
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
            >
                {/* {<Modal open={open} onClose={handleClose} closeIcon={true}>
        <ConfirmStatusPopup
          // activeRow={activeRow}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      </Modal>} */}
                <Modal open={open2} onClose={handleClose2} closeIcon={true}>
                    <TranchUndoPopup
                        handleClose={handleClose2}
                        dealId={deal_id}
                        setCheckValues={setCheckValues}
                        refetchTranches={refetchTranches}
                        refetchTransactions={refetchTransactions}
                        refetchLogListing={refetchTranchesListing}
                    />
                </Modal>
                {/* <div className='custom-card card' style={{ marginBottom: '20px' }}>
        
          <div className='card-body'>
            <div class="d-flex align-items-center justify-content-between">
                <h3 style={{ marginLeft: '20px' }}>Invested Investors</h3>
               {tranchesInvestorsData?.count > 0 && <CustomButton size="small" onClick={openModel2} sx={{display: 'inline-block' }} >Undo Tracnches</CustomButton>}
            </div>
          {isTranchesLoading ? <Loading />:<AsyncTableListings 
        // title="Logs" 
        columns = {columns2}
        // actions={CallForMoneyActions}
        data={tranchesInvestorsData?.investors}
        // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(callForMoneyData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
        // page={logPage}
        // setPage={setLogPage}
        // count={logs?.deal_unit_changes.length}
        limit={5}
        pagination={false}
        tableStyle={{minWidth: 200}}
        />}
          </div>
      </div> */}
                <div class="nav nav-tabs custom-nav-tabs" id="nav-tab" role="tablist">
                    <button
                        class="nav-link active"
                        id="nav-home-tab-investor"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-add-investor"
                        type="button"
                        role="tab"
                        aria-controls="nav-add-investor"
                        aria-selected="true"
                    >
                        Tranches
                    </button>
                    <button
                        class="nav-link"
                        id="nav-alotunit-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-alotunit"
                        type="button"
                        role="tab"
                        aria-controls="nav-alotunit"
                        aria-selected="false"
                    >
                        Update NAV
                    </button>
                </div>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-add-investor" role="tabpanel" aria-labelledby="nav-add-tab-investor">
                        {isTranchesLogLoading ? (
                            <Loading />
                        ) : (
                            <div class="mt-3">
                                <AsyncTableListing
                                    // title="Logs"
                                    columns={combinedColumns}
                                    actions={TranchesActions}
                                    data={tranches?.deal_unit_changes}
                                    // pagination={()=>(<Box style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '10px'}}><Pagination page={page} count={Math.ceil(callForMoneyData.count/10)} onChange={(e,value)=>setPage(value)} /></Box>)}
                                    page={logPage}
                                    setPage={setLogPage}
                                    count={tranches?.deal_unit_changes.length}
                                    limit={5}
                                    pagination={false}
                                    tableStyle={{ minWidth: 200 }}
                                />
                            </div>
                        )}
                    </div>
                    <div class="tab-pane fade" id="nav-alotunit" role="tabpanel" aria-labelledby="nav-alotunit-tab">
                        <AllotUnit deal_status={deal_status} deal_current_value={deal_current_value} refetchListing={refetchListing} />
                    </div>
                </div>
            </Paper>
        </div>
    ) : (
        <Error404 />
    );
};

export default ExampleFile;
