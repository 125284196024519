// assets
import { IconCoin, IconUsers, IconUpload } from '@tabler/icons';
import UserGroup from 'assets/svg/userGroup'


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

//If any common change do in the ainvestor.js as well//

const admin_investor = {
    id: 'investor1',
    title: '',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'investor11',
            title: 'Investors',
            type: 'collapse',
            icon: IconUsers,
            // url: `/investors`,
            breadcrumbs: false,
            children: [
                // {
                //     id: 'investor111',
                //     title: 'All Investors',
                //     type: 'item',
                //     icon: IconUsers,
                //     url: `/investors`,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'investor112',
                //     title: 'SOA Uploads',
                //     type: 'item',
                //     icon: IconUpload,
                //     url: `/investors/soa_uploads`,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'investor113',
                //     title: 'Stamps Uploads',
                //     type: 'item',
                //     icon: IconUpload,
                //     url: `/investors/stamp_uploads`,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default admin_investor;
