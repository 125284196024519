import { Box, Grid, Input, TextField } from '@mui/material'
import CustomField from 'ui-component/formik/CustomField';
import Card from 'ui-component/cards/Card';
import React from 'react'
import { useState } from 'react';

const CustomValuePop = ({handleCustomValue ,setOpenCustomValue}) => {
    const [customValue, setCustomValue] = useState('')
    return (
        <Box minWidth={100}>
                <Card title={'Add Custom Entry'}>
                <Grid display={'grid'} item container xs={12} rowGap={2}>
                    <Grid item xs={12}>
                        <TextField sx={{width: '100%'}} id="outlined-basic" label="Field Name" variant="outlined" value={customValue} onChange={(e)=> setCustomValue(e.target.value)} />
                    </Grid>
                    <Grid item>
                        <button className="button-79" onClick={() => {handleCustomValue(customValue);setOpenCustomValue()}}>
                            Add
                        </button>
                    </Grid>
                </Grid>
                </Card>
            </Box>
  )
}

export default CustomValuePop