const data = {
    "name": "Sheet1",
    // "config": {
    //     "borderInfo": [],
    //     "rowlen": {
    //         "0": 20,
    //         "1": 20,
    //         "2": 20,
    //         "3": 20,
    //         "4": 20,
    //         "5": 20,
    //         "6": 20,
    //         "7": 20,
    //         "8": 20,
    //         "9": 20,
    //         "10": 20,
    //         "11": 20,
    //         "12": 20,
    //         "13": 20,
    //         "14": 20,
    //         "15": 20,
    //         "16": 20,
    //         "17": 31,
    //         "18": 20,
    //         "19": 20,
    //         "20": 20,
    //         "21": 20,
    //         "22": 20,
    //         "23": 20,
    //         "24": 20,
    //         "25": 79,
    //         "26": 20,
    //         "27": 20,
    //         "28": 80,
    //         "29": 36
    //     },
    //     "columnlen": {
    //         "0": 131,
    //         "2": 153,
    //         "3": 128,
    //         "4": 136,
    //         "5": 122,
    //         "6": 138,
    //         "7": 131,
    //         "8": 128,
    //         "9": 140,
    //         "10": 144
    //     },
    //     "rowhidden": {
    //         "30": 0,
    //         "31": 0
    //     },
    //     "customHeight": {
    //         "29": 1
    //     },
    //     "customWidth": {
    //         "2": 1
    //     }
    // },
    // "id": "0",
    // "zoomRatio": 1,
    // "order": "0",
    // "column": 15,
    // "row": 40,
    // "status": 1,
    // "ch_width": 2361,
    // "rh_height": 936,
    // "luckysheet_select_save": [
    //     {
    //         "left": 741,
    //         "width": 138,
    //         "top": 796,
    //         "height": 19,
    //         "left_move": 741,
    //         "width_move": 138,
    //         "top_move": 796,
    //         "height_move": 19,
    //         "row": [
    //             33,
    //             33
    //         ],
    //         "column": [
    //             6,
    //             6
    //         ],
    //         "row_focus": 33,
    //         "column_focus": 6
    //     }
    // ],
    // "calcChain": [
    //     {
    //         "r": 0,
    //         "c": 3,
    //         "id": "0",
    //         "func": [
    //             true,
    //             3,
    //             "=Formula!A1+Formula!B1"
    //         ],
    //         "color": "w",
    //         "parent": null,
    //         "chidren": {},
    //         "times": 0
    //     },
    //     {
    //         "r": 2,
    //         "c": 5,
    //         "id": "0"
    //     }
    // ],
    // "scrollLeft": 0,
    // "scrollTop": 0,
    // "celldata": [
    //     {
    //         "r": 0,
    //         "c": 0,
    //         "v": {
    //             "m": "Parameter",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Parameter",
    //             "bg": "rgb(106, 168, 79)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "fc": "rgb(255, 255, 255)",
    //             "ht": 0,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 1,
    //         "c": 0,
    //         "v": {
    //             "m": "GMV (Excluding GST)",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "GMV (Excluding GST)",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 2,
    //         "c": 0,
    //         "v": {
    //             "m": "GMV (Excluding GST) MoM Growth",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "GMV (Excluding GST) MoM Growth",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 3,
    //         "c": 0,
    //         "v": {
    //             "m": "Cancellation",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Cancellation",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 4,
    //         "c": 0,
    //         "v": {
    //             "m": "Cancellation as a % of GMV",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "Cancellation as a % of GMV",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 5,
    //         "c": 0,
    //         "v": {
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Discount Punit",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0,
    //             "m": "Discount Punit"
    //         }
    //     },
    //     {
    //         "r": 6,
    //         "c": 0,
    //         "v": {
    //             "m": "Discount as a % of GMV",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "Discount as a % of GMV",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 7,
    //         "c": 0,
    //         "v": {
    //             "m": "Returns",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Returns",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 8,
    //         "c": 0,
    //         "v": {
    //             "m": "Returns as a % of GMV",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "Returns as a % of GMV",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 9,
    //         "c": 0,
    //         "v": {
    //             "m": "Net Revenue (Net of Cancellation, Discounts, Returns & GST)",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Net Revenue (Net of Cancellation, Discounts, Returns & GST)",
    //             "bg": "rgb(254, 242, 203)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 10,
    //         "c": 0,
    //         "v": {
    //             "m": "Net Revenue MoM Growth",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Net Revenue MoM Growth",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 11,
    //         "c": 0,
    //         "v": {
    //             "m": "Cost of Goods Sold",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Cost of Goods Sold",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 12,
    //         "c": 0,
    //         "v": {
    //             "m": "CM1",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "CM1",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 13,
    //         "c": 0,
    //         "v": {
    //             "m": "Gross Margin",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Gross Margin",
    //             "bg": "rgb(254, 242, 203)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 14,
    //         "c": 0,
    //         "v": {
    //             "m": "Packaging",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Packaging",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 15,
    //         "c": 0,
    //         "v": {
    //             "m": "Marketplace Commissions",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Marketplace Commissions",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 16,
    //         "c": 0,
    //         "v": {
    //             "m": "Offline Commissions",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Offline Commissions",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 17,
    //         "c": 0,
    //         "v": {
    //             "m": "Logistics Charges",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Logistics Charges",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 18,
    //         "c": 0,
    //         "v": {
    //             "m": "Payment Gateway Charges",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Payment Gateway Charges",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 19,
    //         "c": 0,
    //         "v": {
    //             "m": "Other Operating Variable Costs",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Other Operating Variable Costs",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 20,
    //         "c": 0,
    //         "v": {
    //             "m": "CM2",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "CM2",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 21,
    //         "c": 0,
    //         "v": {
    //             "m": "CM1 %",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "CM1 %",
    //             "bg": "rgb(254, 242, 203)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 22,
    //         "c": 0,
    //         "v": {
    //             "m": "Marketing spends (Google, FB, Other Social Media Channels)",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Marketing spends (Google, FB, Other Social Media Channels)",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 23,
    //         "c": 0,
    //         "v": {
    //             "m": "Promotion Cost on Marketplaces",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Promotion Cost on Marketplaces",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 24,
    //         "c": 0,
    //         "v": {
    //             "m": "Affiliate Spend",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Affiliate Spend",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 25,
    //         "c": 0,
    //         "v": {
    //             "m": "Other Marketing Cost",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Other Marketing Cost",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 26,
    //         "c": 0,
    //         "v": {
    //             "m": "CM3",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "CM3",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 27,
    //         "c": 0,
    //         "v": {
    //             "m": "CM2 %",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "CM2 %",
    //             "bg": "rgb(254, 242, 203)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 28,
    //         "c": 0,
    //         "v": {
    //             "m": "Agency Expenses",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Agency Expenses",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 29,
    //         "c": 0,
    //         "v": {
    //             "m": "Salary excluding Founders",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Salary excluding Founders",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 30,
    //         "c": 0,
    //         "v": {
    //             "m": "Founder Salary",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Founder Salary",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 31,
    //         "c": 0,
    //         "v": {
    //             "m": "Research & Development",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Research & Development",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 32,
    //         "c": 0,
    //         "v": {
    //             "m": "Rent",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Rent",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 33,
    //         "c": 0,
    //         "v": {
    //             "m": "Warehouse Expenses",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Warehouse Expenses",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 34,
    //         "c": 0,
    //         "v": {
    //             "m": "Electricity",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Electricity",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 35,
    //         "c": 0,
    //         "v": {
    //             "m": "IT Expenses",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "IT Expenses",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 36,
    //         "c": 0,
    //         "v": {
    //             "m": "Travelling Expenses",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Travelling Expenses",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 37,
    //         "c": 0,
    //         "v": {
    //             "m": "Other Operating Expenses",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Other Operating Expenses",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 38,
    //         "c": 0,
    //         "v": {
    //             "m": "EBITDA",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "EBITDA",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 39,
    //         "c": 0,
    //         "v": {
    //             "m": "EBITDA %",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "EBITDA %",
    //             "bg": "rgb(254, 242, 203)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 40,
    //         "c": 0,
    //         "v": {
    //             "m": "Interest Payments",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "Interest Payments",
    //             "bg": "rgb(255, 255, 0)",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 41,
    //         "c": 0,
    //         "v": {
    //             "m": "EBTDA",
    //             "ct": {
    //                 "fa": "General",
    //                 "t": "g"
    //             },
    //             "v": "EBTDA",
    //             "bl": 0,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     },
    //     {
    //         "r": 42,
    //         "c": 0,
    //         "v": {
    //             "m": "EBTDA %",
    //             "ct": {
    //                 "fa": "@",
    //                 "t": "s"
    //             },
    //             "v": "EBTDA %",
    //             "bg": "rgb(254, 242, 203)",
    //             "bl": 1,
    //             "it": 0,
    //             "ff": 0,
    //             "fs": 8,
    //             "ht": 1,
    //             "vt": 0
    //         }
    //     }
    // ]
}
export default data;
