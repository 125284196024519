import React, { useState } from 'react'
import { Grid, Box } from '@mui/material';
import CustomButton from 'ui-component/CustomButton';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import TextField from 'ui-component/TextField';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';
import FilterPopup from './FilterPopup'
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_MODAL, CLOSE_MODAL } from 'store/actions';
import Cookies from 'js-cookie';
import Modal from 'ui-component/Modal';
import Link from '@mui/material/Link';
import filterIcon from '../../assets/images/icons/filter.png'
import filterFillIcon from '../../assets/images/icons/filter_fill.png'
import { Tooltip } from '@mui/material';
import { IconFile, IconFolderPlus, IconGridDots, IconList, IconPlus } from '@tabler/icons';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown, Mail, MailLockOutlined, } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EmailIcon from '@mui/icons-material/Email';
import ConfirmMailPopup from './ConfirmMailPopup';
import { ExportKratos } from 'api';
import SendEmailPopupKratos from './popups/SendEmailPopUpKratos';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function ListingFilters({ search, setSearch, refetchListing, searchStatus, setSearchStatus, reset, setreset, page, setPage, handleExport, handleTransactionExport, isExportLoading = false, isLoader = false, isTransactionLoader = false, InvestorsModule = {}, queryString, sendSyndicateSoaMail, sendsoasyndicatemailLoading, mode, handleCloseMail, openMail, setOpenMail, sendSoaMail, sort, limit, filter = true }) {
  const { data: kratosData, refetch: refetchExportKratos, isLoading: isExportKratosLoading, isRefetching: isExportKratosRefetching, status: exportKratosStatus } = ExportKratos(page, search, mode, sort, limit, queryString)
  // console.log(kratosData,"mode")
  const navigate = useNavigate();
  const [isKratosLoad, setIsKratosLoad] = useState(false)
  const theme = useTheme();
  const tabs = ["pending", "submitted", "review", "approved","request_sent","cancelled", "all","lead_investors"]
  const investor = useSelector((state) => state.investor[tabs[Cookies.get("investor-index")]]);
  const dispatch = useDispatch();

  const handleOpen = () => dispatch({ type: OPEN_MODAL });
  const handleClose = () => { dispatch({ type: CLOSE_MODAL }) }

  const user_role = Cookies.get('user_role');
  const LoaderComponent = () => <div>Loading...</div>;
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => {
    setAnchorEl(null);
    setOpen2(false)
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [mailOpen, setMailOpen] = useState(false);
  const handleMailOpen = () => {
      setMailOpen(true);
  };
  const handleMailClose = () => setMailOpen(false);

  function downloadKratos() {
    console.log('working')
    setIsKratosLoad(true);
    refetchExportKratos().then(({ data }) => {
      if ({ data }) {
        const link = document.createElement('a');
        link.href = data.url;
        link.target = '_blank';
        link.setAttribute('download', 'filename.xlsx'); // Specify the desired filename here
        link.click();
      } else {
        console.error('Data is missing in the response:', data);
      }
      setIsKratosLoad(false);
    });
  }

  return (
    <>
      <Modal open={investor.isModelOpen} onClose={handleClose} closeIcon={true}>
        <FilterPopup refetchListing={refetchListing} setPage={setPage} />
      </Modal>
      <Modal open={openMail} onClose={handleCloseMail} closeIcon={true}>
        <ConfirmMailPopup
          // activeRow={activeRow}
          message={"Are you sure you want to send SOA attached email to All Approved Investors "}
          handleClose={handleCloseMail}
          handleConfirm={sendSyndicateSoaMail}
        />
      </Modal>
      <Modal open={mailOpen} style={{ width: '600px',height: 550 }} onClose={handleMailClose} closeIcon={false}>
          <SendEmailPopupKratos handleClose={handleMailClose} downloadKratos={downloadKratos} search={search} queryString={queryString} mode={mode} />
      </Modal> 

      <Grid container direction="row" columnSpacing={1} rowSpacing={2} mb={1} className='filter-block'>
        <Grid item xs={3}>
          <TextField
            label="Search"
            value={search}
            autoComplete='off'
            fullWidth
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                // alert(e.target.value);
                setPage(1)
                refetchListing()
                setSearchStatus(true)
              }
            }}
            onChange={(event) => {
              setSearch(event.target.value)
              setSearchStatus(false)
            }}
            size="small"
            sx={{
              '& input': {
                background: theme.palette.background.paper,
                // background: '#f9f9fc8e'
              },
              '& input:valid + fieldset': {
                borderColor: theme.palette.secondary[200],
                borderWidth: 0.5,
              },
              '& input:valid:hover + fieldset': {
                borderColor: '#4050b5',
                borderWidth: 1,
              },
              '& input:valid:focus + fieldset': {
                borderColor: '#4050b5',
                padding: '4px !important',
                borderWidth: 1,
                borderLeftWidth: 6,
              },
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {/* <CustomButton size="large" onClick={()=>navigate('/startups/addstartup')} fullWidth sx={{height:'100%'}}>Add startup</CustomButton> */}
          <CustomButton
            // size="small" 
            color={(searchStatus && search !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
            onClick={() => {
              setPage(1)
              if (searchStatus && search !== '') {
                setSearch('')
                setreset(!reset)
              }
              else {
                refetchListing()
              }
              setSearchStatus((oldVal) => !oldVal)
            }}
            fullWidth
            // sx={{height:'100%'}}
            sx={{ height: '40px' }}
          >
            {(searchStatus && search !== '') ? "Reset" : "Search"}
          </CustomButton>
          {filter && <IconButton onClick={() => handleOpen()} sx={{ marginLeft: '10px' }}>
            {/* <TuneIcon fontSize="medium" /> */}
            <Tooltip title="Filter">
              {queryString == '' ? <img src={filterIcon} alt="Filter" style={{ width: '24px', height: '24px' }} /> : <img src={filterFillIcon} alt="Filter" style={{ width: '24px', height: '24px' }} />}
            </Tooltip>
          </IconButton>}


        </Grid>
        <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {InvestorsModule?.create && <CustomButton onClick={() => navigate(`/investors/add_investor`)} sx={{ height: '40px', marginRight: "10px" }}>Add Investor</CustomButton>}
          {/* {InvestorsModule?.export_master_data ? (
                isLoader ? (
                  <CustomButton size="medium" sx={{ height: '40px' }}>
                  Loading....
                </CustomButton>
                ) : (
                  <CustomButton onClick={handleExport} size="medium" sx={{ height: '40px' }}>
                    Export Master Data
                  </CustomButton>
                )
              ) : (
                <></>
           )}
          {InvestorsModule?.export_transaction == true ? (isTransactionLoader?(
            <CustomButton size="medium" sx={{ height: '40px' }}>
            Loading....
          </CustomButton>
          ) : (
          <CustomButton  onClick={handleTransactionExport} size="medium" sx={{ height: '40px',marginLeft: '10px' }}>
            Export Transaction
            </CustomButton>
          )) :  <></>
        } */}
          <div>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDown />}
              className='action-btn '
            >
              {/* <IconPlus /> */}
              &nbsp;&nbsp; Actions
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose2}
            >
              {/* Additional Menu Items */}
              {InvestorsModule?.export_master_data && (
                <MenuItem onClick={() => downloadKratos()}>
                  {/* {isLoader ? (
                    <CustomButton size="medium" sx={{ height: '40px' }}>
                        Loading....
                    </CustomButton>
                  ) : (
                    <CustomButton onClick={handleExport} size="medium" sx={{ height: '40px', marginLeft: '10px' }}>
                        Export Master Data
                    </CustomButton>
                  )} */}
                  <ListItemIcon>
                    {isKratosLoad ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <FileDownloadIcon />
                    )}
                    <div style={{ marginRight: '10px' }} /> {/* Adjust the margin as needed */}
                    Export Master Data (Kratos)
                  </ListItemIcon>
                </MenuItem>
              )}
              {InvestorsModule?.export_master_data && (
                <MenuItem onClick={handleExport}>
                  {/* {isLoader ? (
                    <CustomButton size="medium" sx={{ height: '40px' }}>
                        Loading....
                    </CustomButton>
                  ) : (
                    <CustomButton onClick={handleExport} size="medium" sx={{ height: '40px', marginLeft: '10px' }}>
                        Export Master Data
                    </CustomButton>
                  )} */}
                  <ListItemIcon>
                    {isLoader ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <FileDownloadIcon />
                    )}
                    <div style={{ marginRight: '10px' }} /> {/* Adjust the margin as needed */}
                    Export Master Data
                  </ListItemIcon>
                </MenuItem>
              )}
              {InvestorsModule?.export_transaction && (
                <MenuItem onClick={handleTransactionExport} >
                  {/* {isTransactionLoader ? (
                    <CustomButton size="medium" sx={{ height: '40px' }}>
                        Loading....
                    </CustomButton>
                ) : (
                    <CustomButton onClick={handleTransactionExport} size="medium" sx={{ height: '40px', marginLeft: '10px' }}>
                        Export Transaction
                    </CustomButton>
                )} */}
                  <ListItemIcon>
                    {isTransactionLoader ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <FileDownloadIcon />
                    )}
                    <div style={{ marginRight: '10px' }} />
                    Export Transaction
                  </ListItemIcon>
                </MenuItem>
              )}
              {InvestorsModule && (
                <MenuItem onClick={()=>{
                  handleMailOpen();
                  handleClose2();
                }} >
                  <ListItemIcon>
                      <Mail/>
                    <div style={{ marginRight: '10px' }} />
                    Send Email to Kratos
                  </ListItemIcon>
                </MenuItem>
              )}
              {Cookies.get('user_role') == 'syndicate' && mode == 'approved' && (
                <MenuItem onClick={sendSoaMail}>
                  <ListItemIcon>
                    {sendsoasyndicatemailLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <EmailIcon />
                    )}
                    <div style={{ marginRight: '10px' }} />
                    Send SOA Email
                  </ListItemIcon>
                </MenuItem>
              )}
            </StyledMenu>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ListingFilters