import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Box } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
// constant
const headerSX = {
	'& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
	(
		{
			handleClose,
			border = true,
			boxShadow,
			children,
			content = true,
			contentClass = '',
			contentSX = {},
			darkTitle,
			secondary,
			shadow,
			sx = {},
			title,
			...others
		},
		ref
	) => {
		const theme = useTheme();

		return (
			<Box sx={{position: 'relative', width: '100%'}}>
				{handleClose && <Box sx={{ position: 'absolute', top: '-15px', right: '-15px', padding: '6px', cursor: 'pointer', zIndex: 1 }} onClick={()=>handleClose()}>
					<AiFillCloseCircle size={25} color={theme.palette.error.main} />
				</Box>}
				<Card
					ref={ref}
					{...others}
					sx={{
						border: border ? '1px solid' : 'none',
						borderColor: theme.palette.primary[200] + 75,
						':hover': {
							boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
						},
						...sx,
					}}
				>
					{/* card header and action */}
					{!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
					{darkTitle && title && (
						<CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
					)}

					{/* content & header divider */}
					{title && <Divider />}

					{/* card content */}
					{content && (
						<CardContent sx={contentSX} className={contentClass}>
							{children}
						</CardContent>
					)}
					{!content && children}
				</Card>
			</Box>
		);
	}
);

MainCard.propTypes = {
	border: PropTypes.bool,
	boxShadow: PropTypes.bool,
	children: PropTypes.node,
	content: PropTypes.bool,
	contentClass: PropTypes.string,
	contentSX: PropTypes.object,
	darkTitle: PropTypes.bool,
	secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
	shadow: PropTypes.string,
	sx: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
