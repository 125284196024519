import { useState, useEffect, useRef } from 'react';
import { Paper, TextField, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useFormik, Form, FormikProvider, FieldArray } from "formik";
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import CustomField from 'ui-component/formik/CustomField';
import { AddBank, FetchBankAccountDetails, UpdateBankAccountForm, FetchAcountTypes, FetchInvoiceAccountDetails, AddQuestionApi, EditQuestionApi } from 'api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'views/Loading';
import { useParams, useNavigate } from 'react-router';
import Card from 'ui-component/cards/Card';
import Select from 'ui-component/SelectField';
import SelectField from 'ui-component/SelectField';
import { CircularProgressbar } from 'react-circular-progressbar';


const AddInvoiceAccount = () => {
    const theme = useTheme();
    const { id } = useParams()
    const isEditMode = !!id
    const navigate = useNavigate()
    const { isLoading: isBankAccountDetailLoading, data: BankAccountData, status: BankAccountDataStatus } = FetchInvoiceAccountDetails(id, 'edit')
    const { mutateAsync: uploadMaster, status: uploadMasterStatus, error: uploadMasterError, isLoading: isLoadingUploadMasterError } = AddQuestionApi();
    const { mutateAsync: editMaster, status: editMasterStatus, error: editMasterError, isloading: isLoadingEditMasterError } = EditQuestionApi();
    // const { isLoading: isAcountTypesLoading, data: acountTypesData } = FetchAcountTypes({})
    const initialValues = {
        "name": "",
        "address": "",
        "state_name": "",
        "city_name": "",
        "code": "",
        "type": "MCS",
        "account_holder_name": "",
        "bank_name": "",
        "account_number": "",
        "branch": "",
        "ifsc_code": "",
        "gst_number": "",
        "pan_number": "",
        "email": ""
    }

    const formik = useFormik({
        initialValues: (!isBankAccountDetailLoading && isEditMode) ? BankAccountData : initialValues,
        validateOnChange: false,
        validateOnBlur: true,
        enableReinitialize: true,
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            // console.log(values, "values")
            let payload = {
                values: [
                    {
                        ...values
                    }
                ],
                key: "invoice",
                value: values.type
            }
            // console.log(payload, "payload")

            if (isEditMode) {
                editMaster({ payload: { ...payload }, id: id }, {
                    onSuccess: (res) => {
                        formik.resetForm()
                        toast.success('Billing Information Updated!', {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            onClose: () => {
                                navigate('/master')
                            }
                        });
                        setTimeout(() => {
                            navigate('/master')
                        }, 4000)
                    }, onError: () => {
                        toast.error(uploadMasterError?.response?.data?.message, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                })
            }
            else {
                uploadMaster({ ...payload }, {
                    onSuccess: (res) => {
                        toast.success('Billing Information Added!', {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            onClose: () => {
                                navigate('/master')
                            }
                        });
                        setTimeout(() => {
                            navigate('/master')
                        }, 4000)
                    }, onError: (res) => {
                        toast.error(editMasterError?.response?.data?.message, {
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                })
            }
        }
    });



    //   useEffect(() => {
    //     if (uploadBankAccountStatus == 'success') {
    //       formik.resetForm()
    //       toast.success('Bank Account Added!', {
    //         position: "top-left",
    //         autoClose: 1000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //         onClose: () => {
    //           navigate('/master')
    //         }
    //       });
    //     }
    //     if (uploadBankAccountStatus == 'error') {
    //       toast.error(uploadBankAccountError?.response?.data?.message, {
    //         position: "top-left",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //       });

    //     }
    //   }, [uploadBankAccountStatus])


    //   useEffect(() => {
    //     if (isEditMode) {
    //     if (updateBankAccountStatus == 'success') {
    //         toast.success('Bank Account Update!', {
    //             position: "top-left",
    //             autoClose: 1000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //             onClose: () => {
    //                 navigate('/master')
    //             }
    //         });
    //     }
    //     if (updateBankAccountStatus == 'error') {
    //         toast.error(updateBankAccountError?.response?.data?.message, {
    //             position: "top-left",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //         });

    //     }
    //   }
    // }, [updateBankAccountStatus])

    if ((isBankAccountDetailLoading && isEditMode)) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
    }

    // console.log(BankAccountData, "BankAccountData")

    return (
        <div style={{ display: "flex", flexDirection: 'column', height: '100%' }}>
            <ToastContainer />
            <BreadcrumbHeader title={isEditMode ? "Edit Billing Information" : "Add Billing Information"} shouldGoBack />
            <Box>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px' }}>
                        <Box>
                            <Card title="Billing Information">
                                <Grid xs={12} item container direction="row" columnSpacing={3} rowSpacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="name" label="Company Name" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <SelectField value={formik.values.type} name="type" label="Invoice Type" onChange={(e) => { formik.setFieldValue("type", e.target.value) }} options={[{ label: "MCS", value: "MCS" }, { label: "LLP", value: "LLP" }]} />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="bank_name" label="Bank Name" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="account_holder_name" label="Account Holder Name" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="account_number" label="Account Number" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="branch" label="Branch" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="ifsc_code" label="IFSC CODE" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="gst_number" label="GSTIN/UIN" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="pan_number" label="PAN Number" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="email" label="Official Email" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomField name="address" label="Address" sx={{
                                            "textarea": {
                                                paddingLeft: 0,
                                                backgroundColor: '#fff'
                                            }
                                        }} />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="country" label="Country"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    paddingLeft: 0,
                                                    backgroundColor: '#fff'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="state_name" label="State"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    paddingLeft: 0,
                                                    backgroundColor: '#fff'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="city_name" label="City"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    paddingLeft: 0,
                                                    backgroundColor: '#fff'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomField name="code" label="State Code" />
                                    </Grid>
                                </Grid>

                            </Card>
                        </Box>
                        <Grid item xs={12} mt={2}>
                            <button className="button-79" type="submit"> {isLoadingUploadMasterError || isLoadingEditMasterError ? <CircularProgressbar sx={{ color: 'white' }} /> : "Submit"}</button>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Box>
        </div>

    );
}

export default AddInvoiceAccount;
