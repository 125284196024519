import React, { useState, useEffect } from 'react';
import { Box, Pagination } from '@mui/material';
import { IconFile, IconFolderPlus, IconGridDots, IconList, IconPlus } from '@tabler/icons';
import Modal from 'ui-component/Modal';
import BreadcrumbHeader from 'ui-component/BreadcrumbHeader';
import NewFolderCreate from './NewFolderCreate';
import UploadDocumentBox from './UploadDocumentBox';
import UploadDocumentHeader from './UploadDocumentHeader';
import NewFileUpload from './NewFileUpload';
import { FetchDocument, AddDocument, DownloadFile, DeleteDriveDocument } from 'api';
import { useTheme } from '@mui/material/styles';
import Loading from 'views/Loading';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ElevatorSharp, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconArrowBack, IconArrowBackUp, IconArrowBadgeLeft } from '@tabler/icons';
import DeleteDocumentPopup from './DeleteDocumentPopup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CustomButton from 'ui-component/CustomButton';
import TextField from 'ui-component/TextField';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import IconButton from '@mui/material/IconButton';
import MaterialModal from '@mui/material/Modal';

const UploadDocuments = () => {
    const [search, setSearch] = useState('');
    const [stage, setStage] = useState('');
    const [status, setStatus] = useState('');
    const [searchStatus, setSearchStatus] = useState(false);
    const [page, setPage] = useState(1);
    const [reset, setreset] = useState(false)
    const theme = useTheme();
    const [sort, setSort] = useState(-1);
    const [limit, setLimit] = useState(20);
    const [title, setTitle] = useState('Documents');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [parent_id, setParentID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [lastParent, setLastParent] = useState('');
    const [activeRow3, setActiveRow3] = useState({});
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const { isLoading: isDocumentLoading, isRefetching: isDocumenteRefetching, data: documentData, refetch: refetchListing } = FetchDocument({ page, search: search.trim(), sort, limit,parent_id })

    console.log(documentData,"documentDatadocumentDatadocumentDatadocumentDatadocumentData")
    function handleSearch() {
        if (documentData && documentData?.documents && searchQuery !== null && searchQuery !== undefined) {
            const filteredData = documentData?.documents.filter((partner) =>
                (partner.name && partner.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (partner.tagData && partner.tagData.toLowerCase().includes(searchQuery.toLowerCase()))
            );
            return filteredData;
        }
        return [];
    }
    const totalCount = documentData?.count ? documentData?.count : 0; // Total count of completed deals
    const startCount = (page - 1) * limit + 1; // Starting count of completed deals on the current page
    const endCount = Math.min(page * limit, totalCount)
    const { mutateAsync: downloadFileData, refetch: refetchDocuments, isLoading: isDocumentssLoading, isRefetching: isDocoumetsRefetching } = DownloadFile();
    const { mutateAsync: deleteDriveDocument, status: deleteDriveDocumentStatus, error: deleteDriveDocumentError } = DeleteDriveDocument();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    useEffect(() => {
        refetchListing()
      }, [reset])
      
    useEffect(() => {
        refetchListing()
    }, [parent_id]);

    // useEffect(() => {
    //     if(documentData != undefined && documentData.parent_data != undefined){
    //         setLastParent(documentData.parent_data.pop())
    //     }
    // }, [documentData]);



    //   useEffect(() => {
    //     refetchDocuments()
    //   }, [download_id]);

    const [open2, setOpen2] = useState(false);
    const openFolderCreateModal = () => {
        setOpen2(true)
        setAnchorEl(null);
    }
    const [listViewType, setListViewType] = useState(false);
    const gridView = () => {
        setListViewType(true)
    }
    const listView = () => {
        setListViewType(false)
    }

    // File Upload modal
    const openFileUploadModal = () => {
        setOpen3(true)
    }
    const handleClose = () => {
        setAnchorEl(null);
        setOpen2(false)
        setOpen3(false)
        if(searchStatus && search !== ''){
            setParentID('');
        }
        refetchListing()

    };

    const handleItemClick = (item) => {
        setParentID(item._id);
        setSearch('')
        setStage('')
        // setStatus('')
        setreset(!reset)
    };

    const handlePreviousClick = (item) => {
        if (item.parent_id != undefined) {
            setParentID(item._id);

        } else {

            setParentID(item._id);
        }
    };

    const handleDeleteDocument = () => {
            deleteDriveDocument(
                activeRow3._id,
                {
                    onSuccess: () => setOpen4(false)
                }
            );
    };


    const handleFileClick = (item) => {
        // setIsExportLoading(true);
        downloadFileData({ id: item._id })
            .then(({ data }) => {
                const fileUrl = data.data.url;
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                const urlParts = fileUrl.split('/');
                const fileName = urlParts[urlParts.length - 1];
                const originalFileName = decodeURIComponent(fileName.split('?')[0]); // Remove any query parameters
                if (originalFileName) {
                    link.setAttribute('download', originalFileName);
                }
                link.click();
            })
    };

    const handleLastClick = () => {
            setParentID('');
        
    };  

    const handleOpen3 = (row) => {
        setActiveRow3(row);
        setOpen4(true);
    };
    const handleClose3 = () => setOpen4(false);

    useEffect(() => {
        if (deleteDriveDocumentStatus == 'success') {
            refetchListing();
            toast.success('Deleted', {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        } else if (deleteDriveDocumentStatus == 'error') {
            toast.error(deleteDriveDocumentError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [deleteDriveDocumentStatus]);



    if (isDocumentLoading) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>
    }



    return (
        <>

            <Box sx={{ width: '100%' }}>
                <BreadcrumbHeader title={title} />
            </Box>
            <ToastContainer />
            <div className='custom-menu-block view-investor-block upload-document-block'>
                {/* <div className='d-flex align-items-center justify-content-between mb-3'>

                    <div className='md-title upload-doc-title font-18'>
                        {documentData.parent_data.length > 0 && <KeyboardArrowLeft onClick={(e) => handlePreviousClick(documentData.parent_data.pop())} /> }  {lastParent?.name}
                    </div>
                    <UploadDocumentHeader
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        openFolderCreateModal={openFolderCreateModal}
                        openFileUploadModal={openFileUploadModal}
                        handleClick={handleClick}
                        handleClose={handleClose}
                        listViewType={listViewType}
                        gridView={gridView}
                        listView={listView}
                        open={open}
                    />
                </div>
                <div className='cs-border'></div> */}

                <div className='d-flex mt-3'>
                    {/* <div className='w-40'>
                        <input type='text' className='form-control w-100' placeholder='Search'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className='ms-3'>
                        <button className='button-79' type='button' onClick={handleSearch}>Search</button>
                    </div> */}


                    {/* <CustomButton size="large" onClick={()=>navigate('/startups/addstartup')} fullWidth sx={{height:'100%'}}>Add startup</CustomButton> */}
                    <div className='w-40'>
                        <TextField
                            label="Search"
                            value={search}
                            autoComplete='off'
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    setPage(1)
                                    // alert(e.target.value);
                                    refetchListing()
                                    if (search) {
                                        setSearchStatus(true)
                                    }
                                }
                            }}
                            onChange={(event) => {
                                setSearch(event.target.value)
                                setSearchStatus(false)
                            }}
                            size="small"
                            sx={{
                                '& input': {
                                    background: theme.palette.background.paper,
                                    // background: '#f9f9fc8e'
                                },
                                '& input:valid + fieldset': {
                                    borderColor: theme.palette.secondary[200],
                                    borderWidth: 0.5,
                                },
                                '& input:valid:hover + fieldset': {
                                    borderColor: '#4050b5',
                                    borderWidth: 1,
                                },
                                '& input:valid:focus + fieldset': {
                                    borderColor: '#4050b5',
                                    padding: '4px !important',
                                    borderWidth: 1,
                                    borderLeftWidth: 6,
                                },
                            }}
                        />
                    </div>
                    <div className='ms-3'>
                        <CustomButton
                            size="medium"
                            sx={{ height: '40px' }}
                            color={(searchStatus && search !== '') ? [theme.palette.error.dark, theme.palette.error.main] : [theme.palette.secondary[800], theme.palette.secondary.main]}
                            onClick={() => {
                                if (searchStatus && search !== '') {
                                    setSearch('')
                                    setStage('')
                                    // setStatus('')
                                    setreset(!reset)
                                    
                                } else {
                                    setPage(1)
                                    // console.log("clickclickclickclickclickclickclickclickclickclickclickclickclick")
                                    refetchListing()
                                    setParentID('');
                                }
                                setSearchStatus((oldVal) => !oldVal)

                            }}
                            fullWidth
                        >
                            {(searchStatus && search !== '') ? "Reset" : "Search"}
                        </CustomButton>
                    </div>
                    <div className='ml-auto'>
                        <UploadDocumentHeader
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            openFolderCreateModal={openFolderCreateModal}
                            openFileUploadModal={openFileUploadModal}
                            handleClick={handleClick}
                            handleClose={handleClose}
                            listViewType={listViewType}
                            gridView={gridView}
                            listView={listView}
                            open={open}
                        />
                    </div>
                </div>
                <div className='cs-border'></div>
                <div className='sm-title text-black cd-breadcrumb mt-3'>
                    {
                        documentData.parent_data.length > 0 &&  <Link to="/upload-documents" className='ud-link' onClick={(e) => handleLastClick()} ><KeyboardArrowRight />Documents</Link>

                    }
                    {documentData.parent_data?.map((item, pos) => {
                        return (
                            <Link to="/upload-documents" className='ud-link' onClick={(e) => handlePreviousClick(item)} ><KeyboardArrowRight />{item.name}</Link>
                        )
                    })}
                    {/* <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> Drive 1</Link>  */}

                    {/* <Link to="/upload-documents" className='ud-link dot-link'>...</Link> 
                        <Link to="/upload-documents" className='ud-link'><KeyboardArrowRight/> Drive 4</Link> 
                        <span><KeyboardArrowRight/> Folder Name </span>  */}
                </div>
                {isDocumentLoading ? <Loading />
                    : <div style={{borderRadius:'8px'}} className={`upload-document-list mt-3 ${listViewType ? "grid-view" : "list-view"}`}>
                        {!listViewType &&
                            <div className='upload-document-row ud-header-row d-flex'>
                                <div className='col'>
                                    Name
                                    <IconButton
                                            onClick={() => {
                                                setSort((oldSort) => {
                                                    if (oldSort == 1) return -1;
                                                    else return 1;
                                                });
                                                setPage(1);
                                            }}
                                            style={{ position: 'relative', top: '-2px' }}
                                        >
                                            {sort == -1 && <SouthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                            {/* descending sorting */}
                                            {sort == 1 && <NorthIcon style={{ fontSize: '14px', color: '#fff' }} />}{' '}
                                            {/* assending sorting */}
                                            {/* {sort==0 && <NorthIcon style={{fontSize: '14px', color: '#999'}} />}  */} {/* No sorting */}
                                        </IconButton>
                                </div>
                                <div className='col'>
                                    Owner
                                </div>
                                <div className='col'>
                                    Tags
                                </div>
                                <div className='col'>
                                    Type
                                </div>
                                <div className='col'>
                                    Uploaded At
                                </div>
                                <div className='col'>
                                    Action
                                </div>
                            </div>}
                        {searchQuery.trim() === '' && documentData?.documents.length > 0 ? (
                            documentData?.documents.map((item) => (
                                <UploadDocumentBox listViewType={listViewType} item={item} handleItemClick={handleItemClick} handleFileClick={handleFileClick} handleOpen3={handleOpen3} />
                            ))
                        ) : handleSearch().length > 0 ? (
                            // Render search results when there is a search query and results are found
                            handleSearch().map((result, index) => (
                                <UploadDocumentBox listViewType={listViewType} item={result} handleItemClick={handleItemClick} handleFileClick={handleFileClick} key={index} handleOpen3={handleOpen3} />
                            ))
                        ) : (
                            <>
                                <div className='w-100 text-center no-record-col'>
                                    <div className='w-100 text-center no-record-box'>
                                        <div className="no-search-results">No records found.</div>
                                        <div className='mt-2 mb-5 text-center'>
                                            <button type='button' className='button-79 upload-btn' onClick={openFolderCreateModal} >
                                                <IconFolderPlus /> &nbsp;&nbsp;New Folder
                                            </button>
                                            <button type='button' className='button-79 ms-2 upload-btn' onClick={openFileUploadModal} >
                                                <IconFile /> &nbsp;&nbsp;File Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>}
                <div className='d-flex justify-content-end'>
                    <Box
                        style={{
                            display: 'flex',
                            marginTop: '16px',
                            marginLeft: '10px'
                        }}
                    >

                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={limit}
                            label="Row per page"
                            size="small"
                            onChange={(event) => {
                                setLimit(event.target.value);
                                setPage(1);
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>

                        <Pagination page={page} count={Math.ceil(documentData?.count / limit)} onChange={(e, value) => setPage(value)} />
                    </Box>
                </div>

                <Typography >
                    {`Showing ${startCount} to ${endCount} of ${totalCount} Documents`}
                </Typography>
            </div>
            <Modal open={open2} onClose={handleClose} closeIcon={true} mxclass="mxw450">
                <NewFolderCreate onClose={handleClose} parent_id={parent_id} />
            </Modal>
            <Modal open={open3} onClose={handleClose} closeIcon={true} mxclass="mxw550">
                <NewFileUpload onClose={handleClose} parent_id={parent_id} />
            </Modal>
            <MaterialModal open={open4} onClose={handleClose3}>
                    <Box
                        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '700px' }}
                        className="delete-transaction-modal"
                    >
                        <DeleteDocumentPopup
                            // dealId={dealId}
                            activeRow={activeRow3}
                            handleClose={handleClose3}
                            refetchListing={refetchListing}
                            handleConfirm={handleDeleteDocument}
                        />
                    </Box>
            </MaterialModal>
        </>
    )
}

export default UploadDocuments