import { Button, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ConfirmDeleteDocumentPopup = ({ handleClose, document_id, handleConfirm }) => {
  const theme = useTheme();
  return (
    <Paper sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '40px 10px 10px 10px', flex: 1 }}>
      <b style={{ margin: 0, fontSize: '21px', margin: '16px' }}>Delete Document</b>
      <div className="modal-body">
        <p>Are you sure you want to delete this document? </p>
      </div>
      <div className="modal-footer">
        <Button sx={{ backgroundColor: theme.palette.success.main, color: '#fff', ":hover": { backgroundColor: theme.palette.success.dark } }} onClick={() => handleConfirm(document_id)}>Confirm</Button>
        <Button sx={{ backgroundColor: theme.palette.error.main, color: '#fff', ":hover": { backgroundColor: "#ee3535" } }} onClick={handleClose}>Cancel</Button>
      </div>
    </Paper>
  );
}
export default ConfirmDeleteDocumentPopup;