import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.8"
      height="20.8"
      fill="none"
      stroke="#2c3e50"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      className="icon icon-tabler icon-tabler-users-group"
      viewBox="0 0 24 24"
    >
      <path stroke="none" d="M0 0h24v24H0z"></path>
      <path d="M10 13a2 2 0 104 0 2 2 0 00-4 0M8 21v-1a2 2 0 012-2h4a2 2 0 012 2v1M15 5a2 2 0 104 0 2 2 0 00-4 0M17 10h2a2 2 0 012 2v1M5 5a2 2 0 104 0 2 2 0 00-4 0M3 13v-1a2 2 0 012-2h2"></path>
    </svg>
  );
}

export default Icon;
