// import React, { useEffect } from 'react';
// import dashboard from './dashboard';
// import startup from './startup';
// import investor from './investor';
// import admin_investor from '../layout/MainLayout/Sidebar/MenuList/admin_investor';
// import report from './report';
// import deal from './deal';
// import feedback from './feedback';
// import resource from './resource';
// import portfolio from './portfolio';
// import master from './master';
// import support from './support';
// import articles from '../layout/MainLayout/Sidebar/MenuList/article';
// import Cookies from 'js-cookie';
// import syndicates from './syndicates'
// import users from './users'








// ==============================|| MENU ITEMS ||============================== //

// const permission_values =Cookies.get("permission");


// const getCookieValue = async (cookieName) => {
//     return await Cookies.get(cookieName);
//   };
  
//   const getItemsBasedOnPermissions = (permissions) => {
//     const items = []
//     if(permissions && permissions.length > 0 ){
//         permissions.forEach((val) => {
//             val.module_name == "dashboard" && val.permisson && val.permisson == true ? items.push(dashboard) : "";
//             val.module_name == "Investors" && val.permisson && val.permisson == true ? items.push(investor) : "";
//             val.module_name == "dashboard" && val.permisson && val.permisson == true ? items.push(admin_investor) : "";
//             val.module_name == "startup" && val.permisson && val.permisson == true ? items.push(startup) : "";
//             val.module_name == "articles" && val.permisson && val.permisson == true ? items.push(articles) : "";
//             val.module_name == "deals" && val.permisson && val.permisson == true ? items.push(deal) : "";
//             val.module_name == "Lp_summary" && val.permisson && val.permisson == true ? items.push(report) : "";
//             val.module_name == "feedback" && val.permisson && val.permisson == true ? items.push(feedback) : "";
//             val.module_name == "dashboard" && val.permisson && val.permisson == true ? items.push(master) : "";
//             val.module_name == "users" && val.permisson && val.permisson == true ? items.push(users) : "";
//         }); 
        
//     }
//     return items;
//     // Same function implementation as before...
//   };




//   const MenuItems = () => {
//     const [items, setItems] = useState([]);
  
//     useEffect(() => {
//       const fetchPermissions = async () => {
//         try {
//           const permission_values = await getCookieValue('permission');
//           const permissions = JSON.parse(permission_values);
//           const updatedItems = getItemsBasedOnPermissions(permissions);
//           setItems(updatedItems);
//         } catch (error) {
//           console.error('Error fetching permissions:', error);
//         }
//       };
  
//       fetchPermissions();
//     }, []);
// }


// let permissions  = [];
// let items = [];
// useEffect(() => {
//     permissions =  JSON.parse(permission_values);     
//     if(permissions && permissions.length > 0 ){
//         permissions.forEach((val) => {
//             val.module_name == "dashboard" && val.permisson && val.permisson == true ? items.push(dashboard) : "";
//             val.module_name == "Investors" && val.permisson && val.permisson == true ? items.push(investor) : "";
//             val.module_name == "dashboard" && val.permisson && val.permisson == true ? items.push(admin_investor) : "";
//             val.module_name == "startup" && val.permisson && val.permisson == true ? items.push(startup) : "";
//             val.module_name == "articles" && val.permisson && val.permisson == true ? items.push(articles) : "";
//             val.module_name == "deals" && val.permisson && val.permisson == true ? items.push(deal) : "";
//             val.module_name == "Lp_summary" && val.permisson && val.permisson == true ? items.push(report) : "";
//             val.module_name == "feedback" && val.permisson && val.permisson == true ? items.push(feedback) : "";
//             val.module_name == "dashboard" && val.permisson && val.permisson == true ? items.push(master) : "";
//             val.module_name == "users" && val.permisson && val.permisson == true ? items.push(users) : "";
//         }); 
    
//     }
// })

//


// const getItemsBasedOnPermissions = (permissions) => {
//     const items = [];
  
//     if (permissions && permissions.length > 0) {
//       permissions.forEach((val) => {
//         if (val.module_name === 'dashboard' && val.permisson && val.permisson === true) {
//           items.push(dashboard);
//         }
//         if (val.module_name === 'Investors' && val.permisson && val.permisson === true) {
//           items.push(investor);
//         }
//         if (val.module_name === 'dashboard' && val.permisson && val.permisson === true) {
//           items.push(admin_investor);
//         }
//         if (val.module_name === 'startup' && val.permisson && val.permisson === true) {
//           items.push(startup);
//         }
//         if (val.module_name === 'articles' && val.permisson && val.permisson === true) {
//           items.push(articles);
//         }
//         if (val.module_name === 'deals' && val.permisson && val.permisson === true) {
//           items.push(deal);
//         }
//         if (val.module_name === 'Lp_summary' && val.permisson && val.permisson === true) {
//           items.push(report);
//         }
//         if (val.module_name === 'feedback' && val.permisson && val.permisson === true) {
//           items.push(feedback);
//         }
//         if (val.module_name === 'dashboard' && val.permisson && val.permisson === true) {
//           items.push(master);
//         }
//         if (val.module_name === 'users' && val.permisson && val.permisson === true) {
//           items.push(users);
//         }
//       });
//     }
  
//     return items;
//   };

// const permissions = permission_values != undefined ?  JSON.parse(permission_values) : [];
// // const items = getItemsBasedOnPermissions(permissions);
// console.log(MenuItems,"itemsitemsitemsitemsitemsitemsitems");

// export const LeadInvesterMenuItems = {
//     items: []
// };

export const MenuItems = {
  items: [
    
  ]
};