
import { useState, useRef, useEffect } from 'react'
import { Paper, Box, Stack,Chip,Select,InputLabel,MenuItem,ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik, Form, FormikProvider } from "formik";
import CustomField from 'ui-component/formik/CustomField';
import CustomButton from 'ui-component/CustomButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Clickable from 'utils/Clickable';
import { ImCloudUpload } from "react-icons/im";
import { ChangeKycStatus, FetchInvestorPartners, FetchInvestorVenturePartners, FetchReferredPartners, FetchInvestorDetails, UpdatePartnerSettings, FetchReferralList, FetchInvestorTypes, FetchContributerList,FetchAllSyndicatesOptions ,TagsList,FetchAcountTypes,FetchAllCountries} from 'api'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from 'ui-component/formik/SelectField';
import CustomTextArea from 'ui-component/formik/CustomTextArea';
import AsyncTableListing from 'ui-component/AsyncTableListing';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CustomAutoComplete from 'ui-component/formik/CustomAutoComplete'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_FILTERS, CLOSE_MODAL, RESET_FILTERS } from 'store/actions';
import Cookies from 'js-cookie';
import Loading from 'views/Loading';
import CustomMultiSelect from 'ui-component/formik/CustomMultiSelect';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles';

const Gender = [
  { value: '', label: 'Select Option' },
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

const User = [
  { value: '', label: 'Select Option' },
  { value: 'indian_citizen', label: 'Indian Citizen' },
  { value: 'huf', label: 'Indian HUF' },
  { value: 'foreign_citizen', label: 'Foreign Citizenship' },
  { value: 'indian_corporate', label: 'Indian Corporate' },
  { value: 'foreign_corporate', label: 'Foreign Corporate' },
];
const Individual =[
  {value:'individual' , label:'Personal'},
  {value:'huf' , label:'HUF'},
]
 
const useStyles = makeStyles({
  customSelectBox: {
      '& div#demo-multiple-name':{ padding: '10px 14px !important',height: 30}
  }
});


const FilterPopup = ({ refetchListing, setPage }) => {
  const theme = useTheme();
  const classes = useStyles();

  // const { data: investorPartners, isLoading: isInvestorPartnersLoading } = FetchInvestorPartners({investorId:""})
  // const { data: investorVenturePartners, isLoading: isInvestorVenturePartnersLoading } = FetchInvestorVenturePartners({investorId:""})
  // const { data: investorReferredPartners, isLoading: isInvestorReferredPartnersLoading } = FetchReferredPartners({investorId:""})
  const { isLoading: isInvestorTypesLoading, data: investorTypes } = FetchInvestorTypes({mode:"select"})
  const { isLoading: isPartnerListLoading, data: partnerList} = FetchReferralList({investorType: "partner"})
  const { isLoading: isLeadInvestorListLoading, data: leadInvestorList } = FetchAllSyndicatesOptions()
  const { data: tagData, isLoading: isTagsLoading } = TagsList();
  const [tags, setTags] = useState([]);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedStage, setSelectedStage] = useState();
  const { isLoading: isVenturePartnerListLoading, data: venturePartnerList} = FetchReferralList({investorType: "venture_partner"})
  // const { isLoading: isContributerListLoading, data: contributerList} = FetchReferralList({investorType: "contributor"})
  // const { isLoading: isContributerListLoading, data: contributerList } = FetchContributerList({})
  const { isLoading: isAcountTypesLoading, data: acountTypesData } = FetchAcountTypes({})

  const { data: countries, isLoading: isCountryLoading } = FetchAllCountries()
  const tabs = ["pending", "submitted", "review", "approved","request_sent","cancelled" ,"all","lead_investors"]
  const investor = useSelector((state) => state.investor[tabs[Cookies.get("investor-index")]]);
  // const store = useSelector((state) => state.investor);
  const dispatch = useDispatch();
  // console.log(store);

  const [currentDate, setCurrentDate] = useState('');
  const [currentOnboardedDate, setCurrentOnboardedDate] = useState('');

  useEffect(() => {
    // Set the current date when the component mounts
    setCurrentDate(moment().format('YYYY-MM-DD'));
    setCurrentOnboardedDate(moment().format('YYYY-MM-DD'));
  }, []);

  const initialValues = {
    // isPartner: investor.isPartner,
    // isVenturePartner: investor.isVenturePartner,
    master_user_type_id: investor.master_user_type_id,
    partner: investor.partner,

    venturePartner: investor.venturePartner,
    representative: investor.representative,
    syndicate_id: investor.syndicate_id,
    from_on_board_date:investor.from_on_board_date,
    to_on_board_date:investor.to_on_board_date,
    gender:investor.gender,
    user_individual_type:investor.user_individual_type,
    // individual_type:investor.individual_type,
    account_type_id:investor.account_type_id,
    country_cityzenship_id:investor.country_cityzenship_id,
    from_created_date:investor.from_created_date,
    to_created_date:investor.to_created_date,
    // tagsData:filteredData
  }

  useEffect(() => {
    if (tagData) {
      setTagSuggestions(tagData);
      const idArray = investor.tags.split(',');
      const filteredData = tagData.filter(item => idArray.includes(item.id));
      if(filteredData.length > 0){
        setTags(filteredData);
      }
    }
  }, [tagData]);


  const handleAddition = (newTags) => {
    
    const uniqueNewTags = newTags.filter((newTag) =>
      !tags.some((tag) => tag.text === newTag.text)
    );
    
    setTags((prevTags) => [...prevTags, ...uniqueNewTags]);
  };
  const handleDelete = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newTag = { id: inputValue, text: inputValue };
      setTags([...tags, newTag]);
      setInputValue('');
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const ids = tags.map(item => item.id).join(',');
      const updatedValues = {
        ...values,
        tags:ids
        };
      // console.log("values", values,ids);
      dispatch({ type: CLOSE_MODAL })
      dispatch({ type: UPDATE_FILTERS, payload: updatedValues })
      setTimeout(() => {
        setPage(1)
        refetchListing()
      }, 200);
    },
  });

  // console.log(formik.values.account_type_id,formik.values.country_cityzenship_id,"ibuiefbuebfuibui")

const handleFromDateChange = (newValue) => {
    const formattedDate = moment(newValue).format('YYYY-MM-DD');
    formik.setFieldValue('from_created_date', formattedDate);

    // If "Registered To Date" is empty, set it to the current date
    if (!formik.values.to_created_date) {
      formik.setFieldValue('to_created_date', currentDate);
    }
  };

  const handleOnboardedFromDateChange = (newValue) => {
    const formattedDate = moment(newValue).format('YYYY-MM-DD');
    formik.setFieldValue('from_on_board_date', formattedDate);

    // If "Registered To Date" is empty, set it to the current date
    if (!formik.values.to_on_board_date) {
      formik.setFieldValue('to_on_board_date', currentOnboardedDate);
    }
  };


  const { isLoading: isContributerListLoading, data: contributerList } = FetchContributerList({syndicate_id: formik.values.syndicate_id})

  const handleSelectChange = (e) => {
    formik.setFieldValue('account_type_id', e.target.value?.length > 0 ? e.target.value?.join(',') : [e.target.value])
  }
  const handleSelectCountryChange = (e) => {
    formik.setFieldValue('country_cityzenship_id', e.target.value?.length > 0 ? e.target.value?.join(',') : [e.target.value])
  }
  // console.log(formik.values.account_type_id,"dsdsddsd")

  return (
    <div>

      <Paper  sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '10px', flex: 1,overflowY: "auto",overflowX: "hidden", maxHeight: "90vh"  }}>
        {(isContributerListLoading || isVenturePartnerListLoading || isPartnerListLoading) ? <div style={{height:'100%', width: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>:<FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} style={{ paddingTop: '10px', paddingBottom: '15px', paddingLeft: '15px',paddingRight: '15px', margin: 10, flex:1, width:"100%" }}>
            <h3 style={{ textAlign: 'center' }}>Filters</h3>
            <Grid container direction="row" columnSpacing={3} rowSpacing={2} >
            {!isInvestorTypesLoading && <Grid item sm={6} xs={12}>
              <SelectField
                name="master_user_type_id"
                label="User Type"
                options={[
                  { value: '', label: 'Select Option' },
                  ...investorTypes]}
                />
            </Grid>}
            {Cookies.get("is_super_admin") == "true"  &&  !isLeadInvestorListLoading && <Grid item sm={6} xs={12}>
              <CustomMultiSelect
                  label="Syndicates"
                  name="syndicate_id"
                  value={formik.values.syndicate_id}
                  options={leadInvestorList}
                  placeholder="Syndicates"
                  className="mxw-196"
                />
            </Grid>}
            {!isPartnerListLoading && <Grid item sm={6} xs={12}>
              <CustomAutoComplete                           
                label="Partner"
                name="partner"
                disableClearable
                value={formik.values.partner}
                options={[
                  { _id: '', lp_code: '', name: 'Select Option', investor_partner_id: '', investor_venture_partner_id: '',},
                  ...partnerList]}
                getOptionLabel={(option) => {return `${(option?.name && option?._id) ? option.name:''} ${option?.lp_code ? `(${option.lp_code})`:''}`}}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name}{option.lp_code && ` (${option.lp_code})`}
                  </Box>
                )}
              />
            </Grid>}
            
            {!isVenturePartnerListLoading && <Grid item  sm={6} xs={12}>
              <CustomAutoComplete                           
                label="Venture Partner"
                name="venturePartner"
                disableClearable
                value={formik.values.venturePartner}
                options={[
                 { _id: '', name: 'Select Option', syndicate_id:''},
                  ...venturePartnerList]}
                getOptionLabel={(option) => {return `${(option?.name && option?._id) ? option.name:''} ${option?.lp_code ? `(${option.lp_code})`:''}`}}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name}{option.lp_code && ` (${option.lp_code})`}
                  </Box>
                )}
              />
            </Grid>}
            {!isContributerListLoading && <Grid item  sm={6} xs={12}>
              <CustomAutoComplete                           
                label="Representative"
                name="representative"
                disableClearable
                value={formik.values.representative}
                options={[
                  { _id: '', name: 'Select Option', syndicate_id:''},
                  ...contributerList]}
                getOptionLabel={(option) => {return `${(option?.name && option?._id) ? option.name:''} ${option?.lp_code ? `(${option.lp_code})`:''}`}}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                               {option.name}{option.lp_code && ` (${option.lp_code})`}
                  </Box>
                )}
              />
            </Grid>}
            <Grid item sm={6} xs={12}>
                     <Autocomplete
                      multiple
                      id="tag-input"
                      name="tagsData"
                      options={tagSuggestions}
                      getOptionLabel={(option) => option.text}
                      value={tags}
                      onChange={(_, newValue) => handleAddition(newValue)}
                      renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                      <Chip
                       key={option.id}
                       label={option.text}
                       {...getTagProps({ index })}
                       onDelete={() => handleDelete(option)}
                      />
                      ))
                      }
                      inputValue={inputValue}
                      onInputChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      renderInput={(params) => (
                        <TextField {...params} label="Tags" placeholder="Enter tags" />
                      )}
                    />
            </Grid>

            <Grid item sm={6} xs={12}>
               <SelectField
                 name="gender"
              // size="small"
               label="Gender"
                 options={Gender}
               />
           </Grid>
             <Grid item sm={6} xs={12}>
               <SelectField
                 name="user_individual_type"
                //  size="small"
                 label="User Individual Type"
                 options={User}
               />
             </Grid>
             {/* <Grid item sm={6} xs={12}>
               <SelectField
                 name="individual_type"
                //  size="small"
                 label="Individual Type"
                 options={Individual}
               />
             </Grid> */}
<Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                <CustomMultiSelect
                  label="Bank Account Type"
                  name="account_type_id"
                  value={formik.values.account_type_id}
                  options={acountTypesData}
                  placeholder="Syndicates"
                  className="mxw-196"
                />
                </FormControl>
              </Grid>
              {/* {console.log(formik.values.account_type_id)} */}
              <Grid item sm={6} xs={12}>
              
                <FormControl fullWidth>
                <CustomMultiSelect
                  label="Country"
                  name="country_cityzenship_id"
                  value={formik.values.country_cityzenship_id}
                  options={countries}
                  placeholder="Syndicates"
                  className="mxw-196"
                />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Onboarding From Date"
                    value={formik.values?.from_on_board_date}
                    disableFuture={true}
                    // onChange={(newValue) => formik.setFieldValue("from_on_board_date", moment(newValue).format('YYYY-MM-DD'))}
                    onChange={handleOnboardedFromDateChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    inputFormat="DD/MM/YYYY"
                    autoComplete="off"
                        // openTo="year"
                        />
                  </LocalizationProvider>
              </Grid>

             <Grid item sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Onboarding To Date"
                          value={formik.values?.to_on_board_date}
                          disableFuture={true}
                          onChange={(newValue) => formik.setFieldValue("to_on_board_date", moment(newValue).format('YYYY-MM-DD'))}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          inputFormat="DD/MM/YYYY"
                          autoComplete="off"
                        // openTo="year"
                        />
              </LocalizationProvider>
             </Grid>

             <Grid item sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Registered From Date"
                          value={formik.values?.from_created_date}
                          disableFuture={true}
                          // onChange={(newValue) => formik.setFieldValue("from_created_date", moment(newValue).format('YYYY-MM-DD'))}
                          onChange={handleFromDateChange}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          inputFormat="DD/MM/YYYY"
                          autoComplete="off"
                        // openTo="year"
                        />
              </LocalizationProvider>
             </Grid>

             <Grid item sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Registered To Date"
                          value={formik.values?.to_created_date}
                          disableFuture={true}
                          onChange={(newValue) => formik.setFieldValue("to_created_date", moment(newValue).format('YYYY-MM-DD'))}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          inputFormat="DD/MM/YYYY"
                          autoComplete="off"
                        // openTo="year"
                        />
              </LocalizationProvider>
             </Grid>

            </Grid>
            <Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <CustomButton type="submit">
                  Filter
                </CustomButton>
                <CustomButton
                  color={[theme.palette.error.dark, theme.palette.error.main]}
                  onClick={() => {
                    dispatch({ type: CLOSE_MODAL })
                    dispatch({ type: RESET_FILTERS })
                    setTimeout(() => {
                      setPage(1)
                      refetchListing()
                      // refetchExportInvestors()
                    }, 200);
                  }}
                >
                  Reset
                </CustomButton>
              </Stack>
            </Grid>
          </Form>
        </FormikProvider>}
      </Paper>
    </div>
  );
}

export default FilterPopup;