import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useField, useFormikContext } from 'formik';
import { withStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';

const CssTextField = withStyles((theme)=>({
  root: {
    '& .MuiSelect-select.MuiSelect-outlined': {
      backgroundColor: "#fff",
      borderRadius: '10px'
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary[200],
      borderWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary[200],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary[200],
        borderWidth: 0.5,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[800],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[800],
        borderLeftWidth: 6,
        padding: '4px !important',
        borderWidth: 1,
      },
    },
  },
}))(Autocomplete);

const SelectWrapper = ({
  name,
  options,
  label,
  value,
  ...otherProps
}) => {
  const { setFieldValue, } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = evt => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange
  };
  const properities = {

  }

  if (meta && meta.touched && meta.error) {
    properities.error = true;
    properities.helperText = meta.error;
  }

  return (
    <>
      <CssTextField
      value={value}
      onChange={(event, newValue) => {
          setFieldValue(name, newValue.value);
        }}
        // inputValue={inputValue}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        id="controllable-states-demo"
        options={options}
        // sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} {...properities}  label={label} />}
      />
    <p style={{margin: '3px 14px 0px 14px', fontSize:'0.75rem', color:'#f44336'}}>{configSelect?.helperText && `${label} is required`}</p>
    </>
  );
};
SelectWrapper.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};
export default SelectWrapper;